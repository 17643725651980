"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extendSyncDelta = void 0;
const utils_1 = require("@standardnotes/utils");
function extendSyncDelta(base, extendWith) {
    (0, utils_1.extendArray)(base.emits, extendWith.emits);
    if (extendWith.ignored) {
        if (!base.ignored) {
            base.ignored = [];
        }
        (0, utils_1.extendArray)(base.ignored, extendWith.ignored);
    }
}
exports.extendSyncDelta = extendSyncDelta;
