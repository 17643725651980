import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentType } from '@standardnotes/snjs';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PackageEntry from './PackageEntry';
import { useApplication } from '@/Components/ApplicationProvider';
const loadPlugins = (application) => application.items.getItems([
    ContentType.TYPES.ActionsExtension,
    ContentType.TYPES.Component,
    ContentType.TYPES.Theme,
]);
const ManagePlugins = ({ pluginsLatestVersions, className = '' }) => {
    const application = useApplication();
    const [plugins, setPlugins] = useState(loadPlugins(application));
    const reloadInstalledPlugins = useCallback(() => {
        const plugins = application.items.getItems([
            ContentType.TYPES.ActionsExtension,
            ContentType.TYPES.Component,
            ContentType.TYPES.Theme,
        ]);
        setPlugins(plugins);
    }, [application.items]);
    useEffect(() => {
        application.items.streamItems([ContentType.TYPES.Component, ContentType.TYPES.Theme, ContentType.TYPES.ActionsExtension], reloadInstalledPlugins);
    }, [application, reloadInstalledPlugins]);
    const visiblePlugins = plugins.filter((extension) => {
        const hasPackageInfo = extension.package_info != undefined;
        if (!hasPackageInfo) {
            return false;
        }
        return true;
    });
    return (_jsxs("div", { className: className, children: [visiblePlugins.length === 0 && _jsx("div", { className: "text-neutral", children: "No plugins installed." }), visiblePlugins.length > 0 && (_jsx("div", { className: "divide-y divide-border", children: visiblePlugins
                    .sort((e1, e2) => { var _a, _b; return (_a = e1.displayName) === null || _a === void 0 ? void 0 : _a.toLowerCase().localeCompare((_b = e2.displayName) === null || _b === void 0 ? void 0 : _b.toLowerCase()); })
                    .map((extension) => {
                    return (_jsx(PackageEntry, { plugin: extension, latestVersion: pluginsLatestVersions.getVersion(extension) }, extension.uuid));
                }) }))] }));
};
export default observer(ManagePlugins);
