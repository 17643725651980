"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaFileImport = void 0;
const Conflict_1 = require("./Conflict");
const Payload_1 = require("../../Abstract/Payload");
const DeltaEmit_1 = require("./Abstract/DeltaEmit");
const DirtyCounter_1 = require("../DirtyCounter/DirtyCounter");
class DeltaFileImport {
    constructor(baseCollection, applyPayloads, historyMap) {
        this.baseCollection = baseCollection;
        this.applyPayloads = applyPayloads;
        this.historyMap = historyMap;
    }
    result() {
        const result = {
            emits: [],
            ignored: [],
            source: Payload_1.PayloadEmitSource.FileImport,
        };
        for (const payload of this.applyPayloads) {
            const resolved = this.resolvePayload(payload, result);
            (0, DeltaEmit_1.extendSyncDelta)(result, resolved);
        }
        return result;
    }
    resolvePayload(payload, currentResults) {
        /**
         * Check to see if we've already processed a payload for this id.
         * If so, that would be the latest value, and not what's in the base collection.
         */
        /*
         * Find the most recently created conflict if available, as that
         * would contain the most recent value.
         */
        let current = currentResults.emits.find((candidate) => {
            return (0, Payload_1.isDecryptedPayload)(candidate) && candidate.content.conflict_of === payload.uuid;
        });
        /**
         * If no latest conflict, find by uuid directly.
         */
        if (!current) {
            current = currentResults.emits.find((candidate) => {
                return candidate.uuid === payload.uuid;
            });
        }
        /**
         * If not found in current results, use the base value.
         */
        if (!current) {
            const base = this.baseCollection.find(payload.uuid);
            if (base && (0, Payload_1.isDecryptedPayload)(base)) {
                current = base;
            }
        }
        /**
         * If the current doesn't exist, we're creating a new item from payload.
         */
        if (!current) {
            return {
                emits: [
                    payload.copyAsSyncResolved({
                        dirty: true,
                        dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
                        lastSyncEnd: new Date(0),
                    }),
                ],
                ignored: [],
            };
        }
        const delta = new Conflict_1.ConflictDelta(this.baseCollection, current, payload, this.historyMap);
        return delta.result();
    }
}
exports.DeltaFileImport = DeltaFileImport;
