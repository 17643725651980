import { ElementIds } from '@/Constants/ElementIDs';
export var AppPaneId;
(function (AppPaneId) {
    AppPaneId["Navigation"] = "NavigationColumn";
    AppPaneId["Items"] = "ItemsColumn";
    AppPaneId["Editor"] = "EditorColumn";
})(AppPaneId || (AppPaneId = {}));
export const AppPaneIdToDivId = {
    [AppPaneId.Navigation]: ElementIds.NavigationColumn,
    [AppPaneId.Items]: ElementIds.ItemsColumn,
    [AppPaneId.Editor]: ElementIds.EditorColumn,
};
