import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
export const useCaptcha = (captchaURL, callback) => {
    useEffect(() => {
        function handleCaptchaEvent(event) {
            var _a, _b;
            if (!captchaURL) {
                return;
            }
            if (event.origin !== new URL(captchaURL).origin) {
                return;
            }
            if ((_b = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.includes('captcha')) {
                callback(event.data.token);
            }
        }
        window.addEventListener('message', handleCaptchaEvent);
        return () => {
            window.removeEventListener('message', handleCaptchaEvent);
        };
    }, [callback]);
    if (!captchaURL) {
        return null;
    }
    return _jsx("iframe", { src: captchaURL, height: 480 });
};
