const isBackupRelatedFile = (item, application) => {
    var _a;
    const fileName = ((_a = item.getAsFile()) === null || _a === void 0 ? void 0 : _a.name) || '';
    const isBackupMetadataFile = application.files.isFileNameFileBackupRelated(fileName) !== false;
    return isBackupMetadataFile;
};
export const isHandlingFileDrag = (event, application) => {
    var _a;
    const items = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items;
    if (!items) {
        return false;
    }
    return Array.from(items).some((item) => {
        return item.kind === 'file' && !isBackupRelatedFile(item, application);
    });
};
export const isHandlingBackupDrag = (event, application) => {
    var _a;
    const items = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items;
    if (!items) {
        return false;
    }
    return Array.from(items).every((item) => {
        return item.kind === 'file' && isBackupRelatedFile(item, application);
    });
};
