import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NativeFeatureIdentifier, FeatureStatus } from '@standardnotes/snjs';
import { useEffect, useState } from 'react';
import Encryption from './Encryption';
import PasscodeLock from './PasscodeLock';
import Privacy from './Privacy';
import Protections from './Protections';
import ErroredItems from './ErroredItems';
import PreferencesPane from '@/Components/Preferences/PreferencesComponents/PreferencesPane';
import BiometricsLock from '@/Components/Preferences/Panes/Security/BiometricsLock';
import MultitaskingPrivacy from '@/Components/Preferences/Panes/Security/MultitaskingPrivacy';
import { TwoFactorAuth, is2FAEnabled as checkIf2FAIsEnabled } from './TwoFactorAuth/TwoFactorAuth';
import U2FView from './U2F/U2FView/U2FView';
import TwoFactorAuthView from './TwoFactorAuth/TwoFactorAuthView/TwoFactorAuthView';
const Security = (props) => {
    const isNativeMobileWeb = props.application.isNativeMobileWeb();
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [canDisable2FA, setCanDisable2FA] = useState(true);
    const [auth] = useState(() => new TwoFactorAuth(props.application.sessions, props.application.mfa, (status) => setIs2FAEnabled(checkIf2FAIsEnabled(status))));
    useEffect(() => {
        auth.fetchStatus();
    }, [auth]);
    const onU2FDevicesLoaded = (devices) => {
        setCanDisable2FA(devices.length === 0);
    };
    const isU2FFeatureAvailable = props.application.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.UniversalSecondFactor).getValue()) === FeatureStatus.Entitled && props.application.sessions.getUser() !== undefined;
    return (_jsxs(PreferencesPane, { children: [_jsx(Encryption, {}), props.application.items.invalidNonVaultedItems.length > 0 && _jsx(ErroredItems, {}), _jsx(Protections, { application: props.application }), _jsx(TwoFactorAuthView, { auth: auth, application: props.application, canDisable2FA: canDisable2FA }), isU2FFeatureAvailable && (_jsx(U2FView, { application: props.application, is2FAEnabled: is2FAEnabled, loadAuthenticatorsCallback: onU2FDevicesLoaded })), isNativeMobileWeb && _jsx(MultitaskingPrivacy, { application: props.application }), _jsx(PasscodeLock, { application: props.application }), isNativeMobileWeb && _jsx(BiometricsLock, { application: props.application }), props.application.sessions.getUser() && _jsx(Privacy, { application: props.application })] }));
};
export default Security;
