import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { isMobileScreen } from '@/Utils';
import { TOGGLE_FOCUS_MODE_COMMAND } from '@standardnotes/ui-services';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
const FocusModeSwitch = ({ application, onToggle, onClose, isEnabled }) => {
    const toggle = useCallback(() => {
        onToggle(!isEnabled);
        onClose();
    }, [onToggle, isEnabled, onClose]);
    const shortcut = useMemo(() => application.keyboardService.keyboardShortcutForCommand(TOGGLE_FOCUS_MODE_COMMAND), [application]);
    const isMobile = application.isNativeMobileWeb() || isMobileScreen();
    if (isMobile) {
        return null;
    }
    return (_jsx(MenuSwitchButtonItem, { onChange: toggle, shortcut: shortcut, checked: isEnabled, children: "Focus Mode" }));
};
export default FocusModeSwitch;
