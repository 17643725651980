import { FileNode } from './FileNode';
export function convertToFileElement(domNode) {
    const fileUuid = domNode.getAttribute('data-lexical-file-uuid');
    if (fileUuid) {
        const node = $createFileNode(fileUuid);
        return { node };
    }
    return null;
}
export function $createFileNode(fileUuid) {
    return new FileNode(fileUuid);
}
export function $isFileNode(node) {
    return node instanceof FileNode;
}
