import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Switch from '@/Components/Switch/Switch';
import { Subtitle, Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { PrefKey, PrefDefaults } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
const Tools = ({ application }) => {
    const [updateSavingIndicator, setUpdateSavingIndicator] = useState(() => application.getPreference(PrefKey.UpdateSavingStatusIndicator, PrefDefaults[PrefKey.UpdateSavingStatusIndicator]));
    const toggleSavingIndicatorUpdates = () => {
        setUpdateSavingIndicator(!updateSavingIndicator);
        application.setPreference(PrefKey.UpdateSavingStatusIndicator, !updateSavingIndicator).catch(console.error);
    };
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Tools" }), _jsx("div", { children: _jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Show note saving status while editing" }), _jsx(Text, { children: "Control whether the animated saving status is shown while editing. Error statuses are always shown regardless of preference." })] }), _jsx(Switch, { onChange: toggleSavingIndicatorUpdates, checked: updateSavingIndicator })] }) })] }) }));
};
export default observer(Tools);
