import { jsx as _jsx } from "react/jsx-runtime";
import { ElementIds } from '@/Constants/ElementIDs';
import { useMemo } from 'react';
const TextPreview = ({ bytes }) => {
    const text = useMemo(() => {
        const textDecoder = new TextDecoder();
        return textDecoder.decode(bytes);
    }, [bytes]);
    return (_jsx("textarea", { autoComplete: "off", className: "font-editor h-full w-full flex-grow focus:shadow-none focus:outline-none", dir: "auto", id: ElementIds.FileTextPreview, defaultValue: text, readOnly: true }));
};
export default TextPreview;
