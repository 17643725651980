import { useApplication } from '@/Components/ApplicationProvider';
import { ApplicationEvent, PrefDefaults } from '@standardnotes/snjs';
import { useCallback, useEffect, useState } from 'react';
export function useLocalPreference(preference) {
    const application = useApplication();
    const [value, setValue] = useState(application.preferences.getLocalValue(preference, PrefDefaults[preference]));
    const setNewValue = useCallback((newValue) => {
        application.preferences.setLocalValue(preference, newValue);
    }, [application, preference]);
    useEffect(() => {
        return application.addEventObserver(async () => {
            const latestValue = application.preferences.getLocalValue(preference, PrefDefaults[preference]);
            setValue(latestValue);
        }, ApplicationEvent.LocalPreferencesChanged);
    }, [application, preference]);
    return [value, setNewValue];
}
export default function usePreference(preference) {
    const application = useApplication();
    const [value, setValue] = useState(application.getPreference(preference, PrefDefaults[preference]));
    useEffect(() => {
        return application.addEventObserver(async () => {
            const latestValue = application.getPreference(preference, PrefDefaults[preference]);
            setValue(latestValue);
        }, ApplicationEvent.PreferencesChanged);
    }, [application, preference]);
    return value;
}
