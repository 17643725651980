import { AbstractService, ApplicationEvent, } from '@standardnotes/services';
import { RouteParser } from './RouteParser';
import { RouteServiceEvent } from './RouteServiceEvent';
export class RouteService extends AbstractService {
    constructor(application, internalEventBus) {
        super(internalEventBus);
        this.application = application;
        this.internalEventBus = internalEventBus;
        this.addAppEventObserver();
    }
    deinit() {
        super.deinit();
        this.application = undefined;
        this.unsubApp();
    }
    getRoute() {
        return new RouteParser(window.location.href);
    }
    get isDotOrg() {
        return window.location.host === 'app.standardnotes.org';
    }
    removeQueryParameterFromURL(param) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete(param);
        const newUrl = `${window.location.origin}${window.location.pathname}${urlSearchParams.toString()}`;
        window.history.replaceState(null, document.title, newUrl);
    }
    addAppEventObserver() {
        this.unsubApp = this.application.addEventObserver(async (event) => {
            if (event === ApplicationEvent.LocalDataLoaded) {
                void this.notifyRouteChange();
            }
        });
    }
    notifyRouteChange() {
        void this.notifyEvent(RouteServiceEvent.RouteChanged, this.getRoute());
    }
}
