"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptedItem = void 0;
const utils_1 = require("@standardnotes/utils");
const AppDataField_1 = require("../Types/AppDataField");
const DefaultAppDomain_1 = require("../Types/DefaultAppDomain");
const GenericItem_1 = require("./GenericItem");
const ItemContentsEqual_1 = require("../../../Utilities/Item/ItemContentsEqual");
class DecryptedItem extends GenericItem_1.GenericItem {
    constructor(payload) {
        super(payload);
        this.protected = false;
        this.trashed = false;
        this.pinned = false;
        this.archived = false;
        this.locked = false;
        this.starred = false;
        const userModVal = this.getAppDomainValueWithDefault(AppDataField_1.AppDataField.UserModifiedDate, this.serverUpdatedAt || 0);
        this.userModifiedDate = new Date(userModVal);
        this.conflictOf = payload.content.conflict_of;
        this.updatedAtString = (0, utils_1.dateToLocalizedString)(this.userModifiedDate);
        this.protected = (0, utils_1.useBoolean)(this.payload.content.protected, false);
        this.trashed = (0, utils_1.useBoolean)(this.payload.content.trashed, false);
        this.starred = (0, utils_1.useBoolean)(this.payload.content.starred, false);
        this.pinned = this.getAppDomainValueWithDefault(AppDataField_1.AppDataField.Pinned, false);
        this.archived = this.getAppDomainValueWithDefault(AppDataField_1.AppDataField.Archived, false);
        this.locked = this.getAppDomainValueWithDefault(AppDataField_1.AppDataField.Locked, false);
    }
    static DefaultAppDomain() {
        return DefaultAppDomain_1.DefaultAppDomain;
    }
    get content() {
        return this.payload.content;
    }
    get references() {
        return this.payload.content.references || [];
    }
    isReferencingItem(item) {
        return this.references.find((r) => r.uuid === item.uuid) != undefined;
    }
    /**
     * Inside of content is a record called `appData` (which should have been called `domainData`).
     * It was named `appData` as a way to indicate that it can house data for multiple apps.
     * Each key of appData is a domain string, which was originally designed
     * to allow for multiple 3rd party apps who share access to the same data to store data
     * in an isolated location. This design premise is antiquited and no longer pursued,
     * however we continue to use it as not to uncesesarily create a large data migration
     * that would require users to sync all their data.
     *
     * domainData[DomainKey] will give you another Record<string, any>.
     *
     * Currently appData['org.standardnotes.sn'] returns an object of type AppData.
     * And appData['org.standardnotes.sn.components] returns an object of type ComponentData
     */
    getDomainData(domain) {
        const domainData = this.payload.content.appData;
        if (!domainData) {
            return undefined;
        }
        const data = domainData[domain];
        return data;
    }
    getAppDomainValue(key) {
        const appData = this.getDomainData(DefaultAppDomain_1.DefaultAppDomain);
        return appData === null || appData === void 0 ? void 0 : appData[key];
    }
    getAppDomainValueWithDefault(key, defaultValue) {
        const appData = this.getDomainData(DefaultAppDomain_1.DefaultAppDomain);
        return (appData === null || appData === void 0 ? void 0 : appData[key]) || defaultValue;
    }
    payloadRepresentation(override) {
        return this.payload.copy(override);
    }
    /**
     * During sync conflicts, when determing whether to create a duplicate for an item,
     * we can omit keys that have no meaningful weight and can be ignored. For example,
     * if one component has active = true and another component has active = false,
     * it would be needless to duplicate them, so instead we ignore that value.
     */
    contentKeysToIgnoreWhenCheckingEquality() {
        return ['conflict_of'];
    }
    /** Same as `contentKeysToIgnoreWhenCheckingEquality`, but keys inside appData[Item.AppDomain] */
    appDataContentKeysToIgnoreWhenCheckingEquality() {
        return [AppDataField_1.AppDataField.UserModifiedDate];
    }
    getContentCopy() {
        return JSON.parse(JSON.stringify(this.content));
    }
    isItemContentEqualWith(otherItem) {
        return (0, ItemContentsEqual_1.ItemContentsEqual)(this.payload.content, otherItem.payload.content, this.contentKeysToIgnoreWhenCheckingEquality(), this.appDataContentKeysToIgnoreWhenCheckingEquality());
    }
}
exports.DecryptedItem = DecryptedItem;
