/* istanbul ignore file */
export var ButtonType;
(function (ButtonType) {
    ButtonType[ButtonType["Info"] = 0] = "Info";
    ButtonType[ButtonType["Danger"] = 1] = "Danger";
})(ButtonType || (ButtonType = {}));
export class AlertService {
    showErrorAlert(error) {
        return this.alert(error.text, error.title);
    }
}
