import { InternalFeature, InternalFeatureService } from '@standardnotes/snjs';
export var FeatureTrunkName;
(function (FeatureTrunkName) {
})(FeatureTrunkName || (FeatureTrunkName = {}));
const FeatureTrunkStatus = {};
export function featureTrunkEnabled(trunk) {
    return FeatureTrunkStatus[trunk];
}
export function featureTrunkVaultsEnabled() {
    return InternalFeatureService.get().isFeatureEnabled(InternalFeature.Vaults);
}
