"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Predicate = void 0;
const Operator_1 = require("./Operator");
/**
 * A local-only construct that defines a built query that
 * can be used to dynamically search items.
 */
class Predicate {
    constructor(keypath, operator, targetValue) {
        this.keypath = keypath;
        this.operator = operator;
        this.targetValue = targetValue;
        if (this.targetValue === 'true' || this.targetValue === 'false') {
            this.targetValue = JSON.parse(this.targetValue);
        }
    }
    keypathIncludesString(verb) {
        return this.keypath.includes(verb);
    }
    matchesItem(item) {
        const keyPathComponents = this.keypath.split('.');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const valueAtKeyPath = keyPathComponents.reduce((previous, current) => {
            return previous && previous[current];
        }, item);
        return (0, Operator_1.valueMatchesTargetValue)(valueAtKeyPath, this.operator, this.targetValue);
    }
    toJson() {
        return {
            keypath: this.keypath,
            operator: this.operator,
            value: this.targetValue,
        };
    }
}
exports.Predicate = Predicate;
