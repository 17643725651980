import { OPEN_NOTE_HISTORY_COMMAND } from '@standardnotes/ui-services';
import { action, makeObservable, observable } from 'mobx';
import { AbstractViewController } from '../Abstract/AbstractViewController';
export class HistoryModalController extends AbstractViewController {
    deinit() {
        super.deinit();
        this.note = undefined;
    }
    constructor(notesController, keyboardService, eventBus) {
        super(eventBus);
        this.note = undefined;
        this.setNote = (note) => {
            this.note = note;
        };
        this.openModal = (note) => {
            this.setNote(note);
        };
        this.dismissModal = () => {
            this.setNote(undefined);
        };
        makeObservable(this, {
            note: observable,
            setNote: action,
        });
        this.disposers.push(keyboardService.addCommandHandler({
            command: OPEN_NOTE_HISTORY_COMMAND,
            category: 'Current note',
            description: 'Open note history',
            onKeyDown: () => {
                this.openModal(notesController.firstSelectedNote);
                return true;
            },
        }));
    }
}
