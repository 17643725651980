import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
function randomNumber(min, max) {
    const r = Math.random() * (max - min) + min;
    return Math.floor(r);
}
export const EmptyPlaceholderBars = ({ rows }) => {
    const [barWidths, setBarWidths] = useState([]);
    const [animationInterval, setAnimationInterval] = useState(null);
    const reloadWidths = useCallback(() => {
        const widths = [];
        for (let i = 0; i < rows; i++) {
            const width = randomNumber(70, 100);
            widths.push(width);
        }
        setBarWidths(widths);
    }, [setBarWidths, rows]);
    useEffect(() => {
        reloadWidths();
    }, [rows, reloadWidths]);
    const onHoverEnter = useCallback(() => {
        reloadWidths();
        const interval = setInterval(() => {
            reloadWidths();
        }, 750);
        setAnimationInterval(interval);
    }, [setAnimationInterval, reloadWidths]);
    const onHoverExit = useCallback(() => {
        if (animationInterval) {
            clearInterval(animationInterval);
            setAnimationInterval(null);
        }
    }, [animationInterval, setAnimationInterval]);
    return (_jsx("div", { onMouseEnter: onHoverEnter, onMouseLeave: onHoverExit, className: "w-full", children: barWidths.map((width, index) => {
            return (_jsx("div", { style: { width: `${width}%` }, className: 'transition-slowest ease my-4 h-7 bg-passive-4-opacity-variant pb-3 transition-width duration-1000' }, index));
        }) }));
};
