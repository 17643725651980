import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from '@/Utils/ErrorBoundary';
import MarkdownPreviewPlugin from './Plugins/MarkdownPreviewPlugin/MarkdownPreviewPlugin';
import { copyTextToClipboard } from '../../Utils/copyTextToClipboard';
import Modal from '@/Components/Modal/Modal';
import { BlocksEditor } from './BlocksEditor';
import { BlocksEditorComposer } from './BlocksEditorComposer';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
export const SuperNoteMarkdownPreview = ({ note, closeDialog }) => {
    const [markdown, setMarkdown] = useState('');
    const [didCopy, setDidCopy] = useState(false);
    const copy = useCallback(() => {
        copyTextToClipboard(markdown);
        setDidCopy(true);
        setTimeout(() => {
            setDidCopy(false);
        }, 1500);
    }, [markdown]);
    const onMarkdown = useCallback((markdown) => {
        setMarkdown(markdown);
    }, []);
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const modalActions = useMemo(() => [
        {
            label: didCopy ? 'Copied' : 'Copy',
            type: 'primary',
            onClick: copy,
            mobileSlot: 'left',
        },
        {
            label: 'Done',
            type: 'cancel',
            onClick: closeDialog,
            mobileSlot: 'right',
            hidden: !isMobileScreen,
        },
    ], [closeDialog, copy, didCopy, isMobileScreen]);
    return (_jsx(Modal, { title: "Markdown Preview", close: closeDialog, actions: modalActions, children: _jsx("div", { className: "relative w-full px-4 py-4", children: _jsx(ErrorBoundary, { children: _jsx(BlocksEditorComposer, { readonly: true, initialValue: note.text, children: _jsx(BlocksEditor, { readonly: true, className: "relative resize-none text-base focus:shadow-none focus:outline-none", spellcheck: note.spellcheck, children: _jsx(MarkdownPreviewPlugin, { onMarkdown: onMarkdown }) }) }) }) }) }));
};
