import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import { Toast } from './Toast';
import { ToastType } from './enums';
import { dismissToast } from './toastStore';
const getDefaultForAutoClose = (hasActions, type) => {
    return !hasActions && ![ToastType.Loading, ToastType.Progress].includes(type);
};
const getDefaultToastDuration = (type) => (type === ToastType.Error ? 8000 : 4000);
export const ToastTimer = ({ toast, index }) => {
    var _a, _b, _c;
    const toastElementRef = useRef(null);
    const toastTimerIdRef = useRef();
    const hasActions = Boolean((_a = toast.actions) === null || _a === void 0 ? void 0 : _a.length);
    const shouldAutoClose = (_b = toast.autoClose) !== null && _b !== void 0 ? _b : getDefaultForAutoClose(hasActions, toast.type);
    const duration = (_c = toast.duration) !== null && _c !== void 0 ? _c : getDefaultToastDuration(toast.type);
    const startTimeRef = useRef(duration);
    const remainingTimeRef = useRef(duration);
    const dismissToastOnEnd = useCallback(() => {
        if (!shouldAutoClose) {
            return;
        }
        dismissToast(toast.id);
    }, [shouldAutoClose, toast.id]);
    const clearTimer = useCallback(() => {
        if (toastTimerIdRef.current) {
            clearTimeout(toastTimerIdRef.current);
        }
    }, []);
    const pauseTimer = useCallback(() => {
        clearTimer();
        remainingTimeRef.current -= Date.now() - startTimeRef.current;
    }, [clearTimer]);
    const resumeTimer = useCallback(() => {
        startTimeRef.current = Date.now();
        clearTimer();
        toastTimerIdRef.current = window.setTimeout(dismissToastOnEnd, remainingTimeRef.current);
    }, [clearTimer, dismissToastOnEnd]);
    const handleMouseEnter = useCallback(() => {
        pauseTimer();
    }, [pauseTimer]);
    const handleMouseLeave = useCallback(() => {
        resumeTimer();
    }, [resumeTimer]);
    const handlePageFocus = useCallback(() => {
        resumeTimer();
    }, [resumeTimer]);
    const handlePageBlur = useCallback(() => {
        pauseTimer();
    }, [pauseTimer]);
    useEffect(() => {
        clearTimer();
        if (shouldAutoClose) {
            resumeTimer();
        }
        const toastElement = toastElementRef.current;
        if (toastElement) {
            toastElement.addEventListener('mouseenter', handleMouseEnter);
            toastElement.addEventListener('mouseleave', handleMouseLeave);
        }
        if (toast.pauseOnWindowBlur) {
            window.addEventListener('focus', handlePageFocus);
            window.addEventListener('blur', handlePageBlur);
        }
        return () => {
            clearTimer();
            if (toastElement) {
                toastElement.removeEventListener('mouseenter', handleMouseEnter);
                toastElement.removeEventListener('mouseleave', handleMouseLeave);
            }
            if (toast.pauseOnWindowBlur) {
                window.removeEventListener('focus', handlePageFocus);
                window.removeEventListener('blur', handlePageBlur);
            }
        };
    }, [
        clearTimer,
        dismissToastOnEnd,
        duration,
        handleMouseEnter,
        handleMouseLeave,
        handlePageBlur,
        handlePageFocus,
        resumeTimer,
        shouldAutoClose,
        toast.id,
    ]);
    return _jsx(Toast, { toast: toast, index: index, ref: toastElementRef });
};
