"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAndCreateZippableFileName = exports.createZippableFileName = exports.truncateFileName = exports.sanitizeFileName = exports.parseFileName = void 0;
function parseFileName(fileName) {
    const pattern = /(?:\.([^.]+))$/;
    const extMatches = pattern.exec(fileName);
    const ext = (extMatches === null || extMatches === void 0 ? void 0 : extMatches[1]) || '';
    const name = fileName.includes('.') ? fileName.substring(0, fileName.lastIndexOf('.')) : fileName;
    return { name, ext };
}
exports.parseFileName = parseFileName;
function sanitizeFileName(name) {
    return name.trim().replace(/[.\\/:"?*|<>]/g, '_');
}
exports.sanitizeFileName = sanitizeFileName;
function truncateFileName(name, maxLength) {
    return name.length > maxLength ? name.slice(0, maxLength) : name;
}
exports.truncateFileName = truncateFileName;
const MaxFileNameLength = 100;
function createZippableFileName(name, suffix = '', format = 'txt', maxLength = MaxFileNameLength) {
    const sanitizedName = sanitizeFileName(name);
    const truncatedName = truncateFileName(sanitizedName, maxLength);
    const nameEnd = suffix + '.' + format;
    return truncatedName + nameEnd;
}
exports.createZippableFileName = createZippableFileName;
function parseAndCreateZippableFileName(name, suffix = '') {
    const { name: parsedName, ext } = parseFileName(name);
    return createZippableFileName(parsedName, suffix, ext);
}
exports.parseAndCreateZippableFileName = parseAndCreateZippableFileName;
