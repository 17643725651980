import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { AccountMenuPane } from './AccountMenuPane';
import ConfirmPassword from './ConfirmPassword';
import CreateAccount from './CreateAccount';
import GeneralAccountMenu from './GeneralAccountMenu';
import SignInPane from './SignIn';
const MenuPaneSelector = ({ menuPane, setMenuPane, closeMenu, mainApplicationGroup }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    switch (menuPane) {
        case AccountMenuPane.GeneralMenu:
            return (_jsx(GeneralAccountMenu, { mainApplicationGroup: mainApplicationGroup, setMenuPane: setMenuPane, closeMenu: closeMenu }));
        case AccountMenuPane.SignIn:
            return _jsx(SignInPane, { setMenuPane: setMenuPane });
        case AccountMenuPane.Register:
            return (_jsx(CreateAccount, { setMenuPane: setMenuPane, email: email, setEmail: setEmail, password: password, setPassword: setPassword }));
        case AccountMenuPane.ConfirmPassword:
            return _jsx(ConfirmPassword, { setMenuPane: setMenuPane, email: email, password: password });
    }
};
export default observer(MenuPaneSelector);
