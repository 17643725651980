import { useEffect, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNodeByKey } from 'lexical';
export function NodeObserverPlugin({ nodeType, onRemove }) {
    const [editor] = useLexicalComposerContext();
    const map = useRef(new Map());
    useEffect(() => {
        const removeMutationListener = editor.registerMutationListener(nodeType, (mutatedNodes) => {
            editor.getEditorState().read(() => {
                for (const [nodeKey, mutation] of mutatedNodes) {
                    if (mutation === 'updated' || mutation === 'created') {
                        const node = $getNodeByKey(nodeKey);
                        if (node) {
                            const uuid = node.getId();
                            map.current.set(nodeKey, uuid);
                        }
                    }
                    else if (mutation === 'destroyed') {
                        const uuid = map.current.get(nodeKey);
                        if (uuid) {
                            onRemove(uuid);
                        }
                    }
                }
            });
        });
        return () => {
            removeMutationListener();
        };
    });
    return null;
}
