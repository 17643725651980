import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getPlatformString } from '@/Utils';
import { classNames } from '@standardnotes/utils';
import { Dialog, useDialogStore } from '@ariakit/react';
import { forwardRef, useCallback } from 'react';
import { mergeRefs } from '@/Hooks/mergeRefs';
const HistoryModalDialog = forwardRef(({ children, onDismiss }, ref) => {
    const dialog = useDialogStore({
        open: true,
    });
    const addCloseMethod = useCallback((element) => {
        if (element) {
            ;
            element.close = onDismiss;
        }
    }, [onDismiss]);
    return (_jsxs(Dialog, { store: dialog, "aria-label": "Note revision history", ref: mergeRefs([addCloseMethod, ref]), className: "fixed left-0 top-0 z-modal h-full w-full", children: [_jsx("div", { className: "absolute z-0 h-full w-full bg-passive-5 opacity-0 md:opacity-75", role: "presentation", onClick: onDismiss }), _jsx("div", { className: classNames('absolute z-[1] my-0 flex h-full w-full flex-col rounded-md bg-[--modal-background-color]', 'p-0 pb-safe-bottom pt-safe-top shadow-lg md:max-h-[90%] md:w-[90%] md:max-w-[90%]', 'md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:transform', getPlatformString()), children: children })] }));
});
export default HistoryModalDialog;
