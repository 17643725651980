import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Menu from '../Menu/Menu';
import RadioButtonGroup from '@/Components/RadioButtonGroup/RadioButtonGroup';
import ManyVaultSelectionMenu from './ManyVaultSelectionMenu';
import SingleVaultSelectionMenu from './SingleVaultSelectionMenu';
import { useApplication } from '../ApplicationProvider';
import MenuItemSeparator from '../Menu/MenuItemSeparator';
import MenuItem from '../Menu/MenuItem';
const VaultSelectionMenu = () => {
    const application = useApplication();
    const [mode, setMode] = useState(application.vaultDisplayService.isInExclusiveDisplayMode() ? 'single' : 'many');
    const changeSelectionMode = (mode) => {
        setMode(mode);
        if (mode === 'many') {
            if (application.vaultDisplayService.exclusivelyShownVault) {
                application.vaultDisplayService.changeToMultipleVaultDisplayMode();
            }
        }
    };
    return (_jsxs(Menu, { a11yLabel: "Vault selection menu", children: [_jsx(RadioButtonGroup, { items: [
                    { label: 'Multiple', value: 'many' },
                    { label: 'One', value: 'single' },
                ], value: mode, onChange: (value) => changeSelectionMode(value), className: "m-3 mt-1" }), mode === 'many' && _jsx(ManyVaultSelectionMenu, {}), mode === 'single' && _jsx(SingleVaultSelectionMenu, {}), _jsx(MenuItemSeparator, {}), _jsx(MenuItem, { icon: "settings", onClick: () => {
                    application.preferencesController.openPreferences('vaults');
                }, children: "Open vault settings" })] }));
};
export default observer(VaultSelectionMenu);
