import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { classNames } from '@standardnotes/snjs';
import { ErrorCircle } from '@/Components/UIElements/ErrorCircle';
import CountBubble from './CountBubble';
const PreferencesMenuItem = ({ iconType, label, selected, onClick, bubbleCount, hasErrorIndicator, }) => (_jsxs("div", { className: `preferences-menu-item box-border flex h-auto w-auto min-w-42 cursor-pointer select-none flex-row items-center justify-start rounded border border-solid px-4 py-2 text-sm hover:border-border hover:bg-default ${selected ? 'selected border-info font-bold text-info' : 'border-transparent'}`, onClick: (e) => {
        e.preventDefault();
        onClick();
    }, children: [_jsxs("div", { className: "relative mr-1", children: [_jsx(Icon, { className: classNames('text-base', selected ? 'text-info' : 'text-neutral'), type: iconType }), _jsx(CountBubble, { position: "left", count: bubbleCount })] }), _jsx("div", { className: "min-w-1" }), label, hasErrorIndicator && (_jsx("span", { className: "ml-2", children: _jsx(ErrorCircle, {}) }))] }));
export default PreferencesMenuItem;
