import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import RoundIconButton from '../Button/RoundIconButton';
import Popover from '../Popover/Popover';
import ChangeEditorMultipleMenu from './ChangeEditorMultipleMenu';
const ChangeMultipleButton = ({ application, notesController }) => {
    const changeButtonRef = useRef(null);
    const [isChangeMenuOpen, setIsChangeMenuOpen] = useState(false);
    const toggleMenu = () => setIsChangeMenuOpen((open) => !open);
    const [disableClickOutside, setDisableClickOutside] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(RoundIconButton, { label: 'Change note type', onClick: toggleMenu, ref: changeButtonRef, icon: "plain-text" }), _jsx(Popover, { title: "Change note type", togglePopover: toggleMenu, disableClickOutside: disableClickOutside, anchorElement: changeButtonRef, open: isChangeMenuOpen, className: "md:pb-1", children: _jsx(ChangeEditorMultipleMenu, { application: application, notes: notesController.selectedNotes, setDisableClickOutside: setDisableClickOutside }) })] }));
};
export default ChangeMultipleButton;
