import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { useCallback, useRef, useState } from 'react';
import { PrefKey, PrefDefaults } from '@standardnotes/snjs';
import Icon from '../Icon/Icon';
import { useApplication } from '../ApplicationProvider';
import { observer } from 'mobx-react-lite';
import { VisuallyHidden } from '@ariakit/react';
import Button from '../Button/Button';
import Popover from '../Popover/Popover';
import { getRelativeTimeString } from '@/Utils/GetRelativeTimeString';
const IndicatorWithTooltip = ({ className, onClick, icon, isTooltipVisible, setIsTooltipVisible, children, animateIcon = false, }) => {
    const buttonRef = useRef(null);
    return (_jsxs("div", { className: "note-status-tooltip-container", children: [_jsxs("button", { className: classNames('peer flex h-5 w-5 cursor-pointer items-center justify-center rounded-full', className), onClick: onClick, ref: buttonRef, children: [_jsx(Icon, { className: animateIcon ? 'animate-spin' : '', type: icon, size: "small" }), _jsx(VisuallyHidden, { children: "Note sync status" })] }), _jsx(Popover, { title: "Note sync status", open: isTooltipVisible, togglePopover: () => setIsTooltipVisible((visible) => !visible), className: "px-3 py-2", containerClassName: "!min-w-0 !w-auto max-w-[90vw]", anchorElement: buttonRef, side: "bottom", align: "center", offset: 6, disableMobileFullscreenTakeover: true, disableApplyingMobileWidth: true, children: children })] }));
};
const NoteStatusIndicator = ({ note, status, syncTakingTooLong, updateSavingIndicator = PrefDefaults[PrefKey.UpdateSavingStatusIndicator], }) => {
    const application = useApplication();
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const toggleTooltip = useCallback(() => {
        setIsTooltipVisible((visible) => !visible);
    }, []);
    const toggleShowPreference = useCallback(() => {
        void application.setPreference(PrefKey.UpdateSavingStatusIndicator, !updateSavingIndicator);
    }, [application, updateSavingIndicator]);
    if (updateSavingIndicator && !status) {
        return null;
    }
    if (status && status.type === 'error') {
        return (_jsxs(IndicatorWithTooltip, { className: "bg-danger text-danger-contrast", onClick: toggleTooltip, icon: "warning", isTooltipVisible: isTooltipVisible, setIsTooltipVisible: setIsTooltipVisible, children: [_jsx("div", { className: "text-sm font-bold text-danger", children: status.message }), status.description && _jsx("div", { className: "mt-0.5", children: status.description })] }));
    }
    if (syncTakingTooLong) {
        return (_jsx(IndicatorWithTooltip, { className: "bg-warning text-warning-contrast", onClick: toggleTooltip, icon: status && status.type === 'saving' ? 'sync' : 'warning', isTooltipVisible: isTooltipVisible, setIsTooltipVisible: setIsTooltipVisible, children: status ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-sm font-bold text-warning", children: status.message }), status.description && _jsx("div", { className: "mt-0.5", children: status.description })] })) : (_jsx("div", { className: "text-sm font-bold text-warning", children: "Sync taking too long" })) }));
    }
    if (updateSavingIndicator && status) {
        return (_jsxs(IndicatorWithTooltip, { className: classNames(status.type === 'saving' && 'bg-contrast', status.type === 'saved' && 'bg-success text-success-contrast', status.type === 'waiting' && 'bg-warning text-warning-contrast'), onClick: toggleTooltip, icon: status.type === 'saving' ? 'sync' : status.type === 'waiting' ? 'clock' : 'check', animateIcon: status.type === 'saving', isTooltipVisible: isTooltipVisible, setIsTooltipVisible: setIsTooltipVisible, children: [_jsx("div", { className: "text-sm font-bold", children: status.message }), status.description && _jsx("div", { className: "mt-0.5", children: status.description }), status.type === 'waiting' && note.lastSyncEnd && (_jsxs("div", { className: "mt-0.5", children: ["Last synced ", getRelativeTimeString(note.lastSyncEnd)] })), status.type === 'waiting' ? (_jsx(Button, { small: true, className: "mt-1", onClick: () => {
                        application.sync.sync().catch(console.error);
                        toggleTooltip();
                    }, children: "Sync now" })) : (_jsx(Button, { small: true, className: "mt-1", onClick: toggleShowPreference, children: "Disable status updates" }))] }));
    }
    return (_jsxs(IndicatorWithTooltip, { className: "bg-contrast text-passive-1", onClick: toggleTooltip, icon: "info", isTooltipVisible: isTooltipVisible, setIsTooltipVisible: setIsTooltipVisible, children: [_jsx("div", { className: "text-sm font-bold", children: "Note status updates are disabled" }), _jsx(Button, { small: true, className: "mt-1", onClick: toggleShowPreference, children: "Enable status updates" })] }));
};
export default observer(NoteStatusIndicator);
