import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { iconClass } from './ClassNames';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
export const SpellcheckOptions = ({ editorForNote, notesController, note, disabled }) => {
    const spellcheckControllable = editorForNote.featureDescription.spellcheckControl;
    const noteSpellcheck = !spellcheckControllable
        ? true
        : note
            ? notesController.getSpellcheckStateForNote(note)
            : undefined;
    return (_jsxs("div", { className: "flex flex-col", children: [_jsxs(MenuSwitchButtonItem, { checked: Boolean(noteSpellcheck), onChange: () => {
                    notesController.toggleGlobalSpellcheckForNote(note).catch(console.error);
                }, disabled: disabled || !spellcheckControllable, children: [_jsx(Icon, { type: "notes", className: iconClass }), "Spellcheck"] }), !spellcheckControllable && (_jsx("p", { className: "px-3 py-1.5 text-xs", children: "Spellcheck cannot be controlled for this editor." }))] }));
};
