import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '@/Components/Modal/Modal';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import { useApplication } from '@/Components/ApplicationProvider';
import { ClientDisplayableError } from '@standardnotes/snjs';
const EditContactModal = ({ onCloseDialog, fromInvite, onAddContact, editContactUuid }) => {
    const application = useApplication();
    const [name, setName] = useState('');
    const [collaborationID, setCollaborationID] = useState('');
    const [editingContact, setEditingContact] = useState(undefined);
    const handleDialogClose = useCallback(() => {
        onCloseDialog();
    }, [onCloseDialog]);
    useEffect(() => {
        if (fromInvite) {
            setCollaborationID(application.contacts.getCollaborationIDFromInvite(fromInvite.invite));
        }
    }, [application.contacts, fromInvite]);
    useEffect(() => {
        if (editContactUuid) {
            const contact = application.contacts.findContact(editContactUuid);
            if (!contact) {
                throw new Error(`Contact with uuid ${editContactUuid} not found`);
            }
            setEditingContact(contact);
            setName(contact.name);
            setCollaborationID(application.contacts.getCollaborationIDForTrustedContact(contact));
        }
    }, [application.contacts, application.vaults, editContactUuid]);
    const handleSubmit = useCallback(async () => {
        if (editingContact) {
            void application.contacts.editTrustedContactFromCollaborationID(editingContact, { name, collaborationID });
            handleDialogClose();
        }
        else {
            try {
                const contact = await application.contacts.addTrustedContactFromCollaborationID(collaborationID, name);
                if (contact) {
                    onAddContact === null || onAddContact === void 0 ? void 0 : onAddContact(contact);
                    handleDialogClose();
                }
                else {
                    void application.alerts.alert('Unable to create contact. Please try again.');
                }
            }
            catch (error) {
                if (error instanceof ClientDisplayableError) {
                    application.alerts.showErrorAlert(error).catch(console.error);
                }
                console.error(error);
            }
        }
    }, [editingContact, application.contacts, application.alerts, name, collaborationID, handleDialogClose, onAddContact]);
    const modalActions = useMemo(() => [
        {
            label: editContactUuid ? 'Save Contact' : 'Add Contact',
            onClick: handleSubmit,
            type: 'primary',
            mobileSlot: 'right',
        },
        {
            label: 'Cancel',
            onClick: handleDialogClose,
            type: 'cancel',
            mobileSlot: 'left',
        },
    ], [editContactUuid, handleDialogClose, handleSubmit]);
    const focusInput = useCallback((input) => {
        if (input) {
            setTimeout(() => {
                input.focus();
            });
        }
    }, []);
    return (_jsx(Modal, { title: editContactUuid ? 'Edit Contact' : 'Add New Contact', close: handleDialogClose, actions: modalActions, children: _jsxs("div", { className: "mb-3 flex w-full flex-col gap-4 px-4.5 pb-1.5 pt-4", children: [_jsxs("label", { children: [_jsx("div", { className: "mb-1", children: "Contact Name" }), _jsx(DecoratedInput, { id: "invite-name-input", value: name, onChange: (value) => {
                                setName(value);
                            }, ref: focusInput, onEnter: handleSubmit })] }), !(editingContact === null || editingContact === void 0 ? void 0 : editingContact.isMe) && (_jsxs("label", { children: [_jsx("div", { className: "mb-1", children: "CollaborationID" }), _jsx(DecoratedInput, { id: "invite-email-input", value: collaborationID, onChange: (value) => {
                                setCollaborationID(value);
                            }, onEnter: handleSubmit })] })), !editContactUuid && (_jsx("p", { children: "Ask your contact for their Standard Notes CollaborationID via secure email or chat. Then, enter it here to add them as a contact." }))] }) }));
};
export default EditContactModal;
