import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import NotesOptions from '@/Components/NotesOptions/NotesOptions';
import { useCallback, useState } from 'react';
import Popover from '../Popover/Popover';
import Menu from '../Menu/Menu';
import { useApplication } from '../ApplicationProvider';
const NotesContextMenu = () => {
    const application = useApplication();
    const { contextMenuOpen, contextMenuClickLocation, setContextMenuOpen } = application.notesController;
    const closeMenu = () => setContextMenuOpen(!contextMenuOpen);
    const [disableClickOutside, setDisableClickOutside] = useState(false);
    const handleDisableClickOutsideRequest = useCallback((disabled) => {
        setDisableClickOutside(disabled);
    }, []);
    return (_jsx(Popover, { title: "Note options", align: "start", anchorPoint: {
            x: contextMenuClickLocation.x,
            y: contextMenuClickLocation.y,
        }, disableClickOutside: disableClickOutside, open: contextMenuOpen, togglePopover: closeMenu, children: _jsx(Menu, { className: "select-none", a11yLabel: "Note context menu", children: _jsx(NotesOptions, { notes: application.notesController.selectedNotes, requestDisableClickOutside: handleDisableClickOutsideRequest, closeMenu: closeMenu }) }) }));
};
export default observer(NotesContextMenu);
