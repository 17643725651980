"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmartView = exports.isSmartView = exports.isSystemView = exports.SMART_TAG_DSL_PREFIX = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const Generators_1 = require("../../Runtime/Predicate/Generators");
const SystemViewId_1 = require("./SystemViewId");
const SmartViewIcons_1 = require("./SmartViewIcons");
exports.SMART_TAG_DSL_PREFIX = '![';
function isSystemView(view) {
    return Object.values(SystemViewId_1.SystemViewId).includes(view.uuid);
}
exports.isSystemView = isSystemView;
const isSmartView = (x) => x.content_type === domain_core_1.ContentType.TYPES.SmartView;
exports.isSmartView = isSmartView;
class SmartView extends DecryptedItem_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.title = String(this.content.title || '');
        if (isSystemView(this)) {
            this.iconString = (0, SmartViewIcons_1.systemViewIcon)(this.uuid);
        }
        else {
            this.iconString = this.payload.content.iconString || SmartViewIcons_1.SmartViewDefaultIconName;
        }
        this.preferences = this.payload.content.preferences;
        try {
            this.predicate = this.content.predicate && (0, Generators_1.predicateFromJson)(this.content.predicate);
        }
        catch (error) {
            console.error(error);
        }
    }
}
exports.SmartView = SmartView;
