import { Platform } from '@standardnotes/snjs';
import { eventMatchesKeyAndModifiers } from './eventMatchesKeyAndModifiers';
import { KeyboardKeyEvent } from './KeyboardKeyEvent';
import { KeyboardModifier } from './KeyboardModifier';
import { getKeyboardShortcuts } from './getKeyboardShortcuts';
export class KeyboardService {
    constructor(platform, environment) {
        this.platform = platform;
        this.activeModifiers = new Set();
        this.commandHandlers = new Set();
        this.commandMap = new Map();
        this.keyboardShortcutHelpItems = new Set();
        this.addActiveModifier = (modifier) => {
            if (!modifier) {
                return;
            }
            switch (modifier) {
                case KeyboardModifier.Meta: {
                    if (this.isMac) {
                        this.activeModifiers.add(modifier);
                    }
                    break;
                }
                case KeyboardModifier.Ctrl: {
                    if (!this.isMac) {
                        this.activeModifiers.add(modifier);
                    }
                    break;
                }
                default: {
                    this.activeModifiers.add(modifier);
                    break;
                }
            }
        };
        this.removeActiveModifier = (modifier) => {
            if (!modifier) {
                return;
            }
            this.activeModifiers.delete(modifier);
        };
        this.cancelAllKeyboardModifiers = () => {
            this.activeModifiers.clear();
        };
        this.handleComponentKeyDown = (modifier) => {
            this.addActiveModifier(modifier);
        };
        this.handleComponentKeyUp = (modifier) => {
            this.removeActiveModifier(modifier);
        };
        this.handleKeyDown = (event) => {
            this.updateAllModifiersFromEvent(event);
            this.handleKeyboardEvent(event, KeyboardKeyEvent.Down);
        };
        this.handleKeyUp = (event) => {
            this.updateAllModifiersFromEvent(event);
            this.handleKeyboardEvent(event, KeyboardKeyEvent.Up);
        };
        this.handleWindowBlur = () => {
            for (const modifier of this.activeModifiers) {
                this.activeModifiers.delete(modifier);
            }
        };
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
        window.addEventListener('blur', this.handleWindowBlur);
        const shortcuts = getKeyboardShortcuts(platform, environment);
        for (const shortcut of shortcuts) {
            this.registerShortcut(shortcut);
        }
    }
    get isMac() {
        return this.platform === Platform.MacDesktop || this.platform === Platform.MacWeb;
    }
    deinit() {
        this.commandHandlers.clear();
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('blur', this.handleWindowBlur);
        this.handleKeyDown = undefined;
        this.handleKeyUp = undefined;
        this.handleWindowBlur = undefined;
    }
    updateAllModifiersFromEvent(event) {
        for (const modifier of Object.values(KeyboardModifier)) {
            if (event.getModifierState(modifier)) {
                this.addActiveModifier(modifier);
            }
            else {
                this.removeActiveModifier(modifier);
            }
        }
    }
    handleKeyboardEvent(event, keyEvent) {
        for (const command of this.commandMap.keys()) {
            const shortcut = this.commandMap.get(command);
            if (!shortcut) {
                continue;
            }
            if (eventMatchesKeyAndModifiers(event, shortcut)) {
                if (shortcut.preventDefault) {
                    event.preventDefault();
                }
                this.handleCommand(command, event, keyEvent);
            }
        }
    }
    handleCommand(command, event, keyEvent) {
        const target = event.target;
        for (const observer of Array.from(this.commandHandlers).reverse()) {
            if (observer.command !== command) {
                continue;
            }
            if (observer.element && event.target !== observer.element) {
                continue;
            }
            if (observer.elements && !observer.elements.includes(target)) {
                continue;
            }
            if (observer.notElement && observer.notElement === event.target) {
                continue;
            }
            if (observer.notElementIds && observer.notElementIds.includes(target.id)) {
                continue;
            }
            if (observer.notTags && observer.notTags.includes(target.tagName)) {
                continue;
            }
            const callback = keyEvent === KeyboardKeyEvent.Down ? observer.onKeyDown : observer.onKeyUp;
            if (callback) {
                const exclusive = callback(event);
                if (exclusive) {
                    return;
                }
            }
        }
    }
    triggerCommand(command, data) {
        for (const observer of Array.from(this.commandHandlers).reverse()) {
            if (observer.command !== command) {
                continue;
            }
            const callback = observer.onKeyDown || observer.onKeyUp;
            if (callback) {
                const exclusive = callback(new KeyboardEvent('command-trigger'), data);
                if (exclusive) {
                    return;
                }
            }
        }
    }
    registerShortcut(shortcut) {
        this.commandMap.set(shortcut.command, shortcut);
    }
    addCommandHandler(observer) {
        this.commandHandlers.add(observer);
        const helpItem = this.getKeyboardShortcutHelpItemForHandler(observer);
        if (helpItem) {
            const existingItem = Array.from(this.keyboardShortcutHelpItems).find((item) => item.command === helpItem.command);
            if (existingItem) {
                this.keyboardShortcutHelpItems.delete(existingItem);
            }
            this.keyboardShortcutHelpItems.add(helpItem);
        }
        return () => {
            observer.onKeyDown = undefined;
            observer.onKeyDown = undefined;
            this.commandHandlers.delete(observer);
            if (helpItem) {
                this.keyboardShortcutHelpItems.delete(helpItem);
            }
        };
    }
    addCommandHandlers(handlers) {
        const disposers = handlers.map((handler) => this.addCommandHandler(handler));
        return () => {
            for (const disposer of disposers) {
                disposer();
            }
        };
    }
    keyboardShortcutForCommand(command) {
        const shortcut = this.commandMap.get(command);
        if (!shortcut) {
            return undefined;
        }
        return {
            platform: this.platform,
            ...shortcut,
        };
    }
    getKeyboardShortcutHelpItemForHandler(handler) {
        const shortcut = this.keyboardShortcutForCommand(handler.command);
        if (!shortcut || !handler.category || !handler.description) {
            return undefined;
        }
        return {
            ...shortcut,
            category: handler.category,
            description: handler.description,
        };
    }
    /**
     * Register help item for a keyboard shortcut that is handled outside of the KeyboardService,
     * for example by a library like Lexical.
     */
    registerExternalKeyboardShortcutHelpItem(item) {
        this.keyboardShortcutHelpItems.add(item);
        return () => {
            this.keyboardShortcutHelpItems.delete(item);
        };
    }
    /**
     * Register help item for a keyboard shortcut that is handled outside of the KeyboardService,
     * for example by a library like Lexical.
     */
    registerExternalKeyboardShortcutHelpItems(items) {
        const disposers = items.map((item) => this.registerExternalKeyboardShortcutHelpItem(item));
        return () => {
            for (const disposer of disposers) {
                disposer();
            }
        };
    }
    getRegisteredKeyboardShorcutHelpItems() {
        return Array.from(this.keyboardShortcutHelpItems);
    }
}
