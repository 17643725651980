import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { ApplicationEvent, NativeFeatureIdentifier, FeatureStatus, FindNativeFeature, PrefKey, PrefDefaults, } from '@standardnotes/snjs';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import PreferencesGroup from '../../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../../PreferencesComponents/PreferencesSegment';
import LabsFeature from './LabsFeature';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import { featureTrunkVaultsEnabled } from '@/FeatureTrunk';
const LabsPane = ({ application }) => {
    const [experimentalFeatures, setExperimentalFeatures] = useState([]);
    const [isPaneGesturesEnabled, setIsPaneGesturesEnabled] = useState(() => application.getPreference(PrefKey.PaneGesturesEnabled, PrefDefaults[PrefKey.PaneGesturesEnabled]));
    useEffect(() => {
        return application.addSingleEventObserver(ApplicationEvent.PreferencesChanged, async () => {
            setIsPaneGesturesEnabled(application.getPreference(PrefKey.PaneGesturesEnabled, PrefDefaults[PrefKey.PaneGesturesEnabled]));
        });
    }, [application]);
    const reloadExperimentalFeatures = useCallback(() => {
        const experimentalFeatures = application.features
            .getExperimentalFeatures()
            .map((featureIdentifier) => {
            var _a, _b;
            const feature = FindNativeFeature(featureIdentifier);
            return {
                identifier: featureIdentifier,
                name: (_a = feature === null || feature === void 0 ? void 0 : feature.name) !== null && _a !== void 0 ? _a : featureIdentifier,
                description: (_b = feature === null || feature === void 0 ? void 0 : feature.description) !== null && _b !== void 0 ? _b : '',
                isEnabled: application.features.isExperimentalFeatureEnabled(featureIdentifier),
                isEntitled: application.features.getFeatureStatus(NativeFeatureIdentifier.create(featureIdentifier).getValue()) ===
                    FeatureStatus.Entitled,
            };
        })
            .filter((feature) => {
            if (feature.identifier !== NativeFeatureIdentifier.TYPES.Vaults) {
                return true;
            }
            return featureTrunkVaultsEnabled();
        });
        setExperimentalFeatures(experimentalFeatures);
    }, [application]);
    useEffect(() => {
        reloadExperimentalFeatures();
    }, [reloadExperimentalFeatures]);
    const premiumModal = usePremiumModal();
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const canShowPaneGesturesOption = isMobileScreen && typeof isPaneGesturesEnabled === 'boolean';
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Labs" }), _jsxs("div", { children: [canShowPaneGesturesOption && (_jsx(LabsFeature, { name: "Pane switch gestures", description: "Allows using gestures to navigate", isEnabled: isPaneGesturesEnabled, toggleFeature: () => {
                                void application.setPreference(PrefKey.PaneGesturesEnabled, !isPaneGesturesEnabled);
                            } })), experimentalFeatures.map(({ identifier, name, description, isEnabled, isEntitled }, index) => {
                            const toggleFeature = () => {
                                if (!isEntitled) {
                                    premiumModal.activate(name);
                                    return;
                                }
                                application.features.toggleExperimentalFeature(identifier);
                                reloadExperimentalFeatures();
                            };
                            const showHorizontalSeparator = experimentalFeatures.length > 1 && index !== experimentalFeatures.length - 1;
                            return (_jsxs(Fragment, { children: [_jsx(LabsFeature, { name: name, description: description, toggleFeature: toggleFeature, isEnabled: isEnabled }), showHorizontalSeparator && _jsx(HorizontalSeparator, { classes: "mt-2.5 mb-3" })] }, identifier));
                        }), experimentalFeatures.length === 0 && !canShowPaneGesturesOption && (_jsx("div", { className: "flex items-center justify-between", children: _jsx("div", { className: "flex flex-col", children: _jsx(Text, { children: "No experimental features available." }) }) }))] })] }) }));
};
export default LabsPane;
