import { Result } from '@standardnotes/domain-core';
import { Environment } from '@standardnotes/models';
export class IsNativeMobileWeb {
    constructor(environment) {
        this.environment = environment;
    }
    execute() {
        return Result.ok(this.environment === Environment.Mobile);
    }
}
