"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticatorApiService = void 0;
const ErrorMessage_1 = require("../../Error/ErrorMessage");
const ApiCallError_1 = require("../../Error/ApiCallError");
const AuthenticatorApiOperations_1 = require("./AuthenticatorApiOperations");
class AuthenticatorApiService {
    constructor(authenticatorServer) {
        this.authenticatorServer = authenticatorServer;
        this.operationsInProgress = new Map();
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthenticatorApiOperations_1.AuthenticatorApiOperations.List)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.List, true);
            try {
                const response = yield this.authenticatorServer.list({});
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.List, false);
            }
        });
    }
    delete(authenticatorId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthenticatorApiOperations_1.AuthenticatorApiOperations.Delete)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.Delete, true);
            try {
                const response = yield this.authenticatorServer.delete({
                    authenticatorId,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.Delete, false);
            }
        });
    }
    generateRegistrationOptions() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthenticatorApiOperations_1.AuthenticatorApiOperations.GenerateRegistrationOptions)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.GenerateRegistrationOptions, true);
            try {
                const response = yield this.authenticatorServer.generateRegistrationOptions();
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.GenerateRegistrationOptions, false);
            }
        });
    }
    verifyRegistrationResponse(userUuid, name, attestationResponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthenticatorApiOperations_1.AuthenticatorApiOperations.VerifyRegistrationResponse)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.VerifyRegistrationResponse, true);
            try {
                const response = yield this.authenticatorServer.verifyRegistrationResponse({
                    userUuid,
                    name,
                    attestationResponse,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.VerifyRegistrationResponse, false);
            }
        });
    }
    generateAuthenticationOptions(username) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthenticatorApiOperations_1.AuthenticatorApiOperations.GenerateAuthenticationOptions)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.GenerateAuthenticationOptions, true);
            try {
                const response = yield this.authenticatorServer.generateAuthenticationOptions({
                    username,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthenticatorApiOperations_1.AuthenticatorApiOperations.GenerateAuthenticationOptions, false);
            }
        });
    }
}
exports.AuthenticatorApiService = AuthenticatorApiService;
