"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPrefsMutator = void 0;
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
class UserPrefsMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    setPref(key, value) {
        this.setAppDataItem(key, value);
    }
}
exports.UserPrefsMutator = UserPrefsMutator;
