import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import { useApplication } from '../ApplicationProvider';
import { ContentType } from '@standardnotes/snjs';
import MenuRadioButtonItem from '../Menu/MenuRadioButtonItem';
import { observer } from 'mobx-react-lite';
import Icon from '../Icon/Icon';
import VaultSelectMenuItemWithOptions from './MenuItemWithVaultOption';
const SingleVaultSelectionMenu = () => {
    const application = useApplication();
    const [vaults, setVaults] = useState(() => application.vaults.getVaults());
    useEffect(() => {
        return application.items.streamItems(ContentType.TYPES.VaultListing, () => {
            setVaults(application.vaults.getVaults());
        });
    }, [application.items, application.vaults]);
    const isVaultVisible = useCallback((vault) => {
        return application.vaultDisplayService.isVaultExclusivelyShown(vault);
    }, [application]);
    const selectVault = useCallback((vault) => {
        application.vaultDisplayService.showOnlyVault(vault);
    }, [application]);
    return (_jsxs(Menu, { a11yLabel: "Vault selection menu", children: [!vaults.length && _jsx("div", { className: "py-1 text-center", children: "No vaults found" }), vaults.map((vault) => (_jsx(VaultSelectMenuItemWithOptions, { vault: vault, children: _jsxs(MenuRadioButtonItem, { className: "!px-0 focus:!bg-transparent md:!py-[0.455rem]", checked: isVaultVisible(vault), onClick: () => selectVault(vault), children: [vault.name, application.vaultLocks.isVaultLocked(vault) && _jsx(Icon, { className: "ml-1", type: "lock", size: 'small' })] }, vault.uuid) }, vault.uuid)))] }));
};
export default observer(SingleVaultSelectionMenu);
