import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { LinkButton, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import { useEffect, useState } from 'react';
import Spinner from '@/Components/Spinner/Spinner';
const ListedAccountItem = ({ account, showSeparator, application }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [accountInfo, setAccountInfo] = useState();
    useEffect(() => {
        const loadAccount = async () => {
            setIsLoading(true);
            const info = await application.listed.getListedAccountInfo(account);
            setAccountInfo(info);
            setIsLoading(false);
        };
        loadAccount().catch(console.error);
    }, [account, application]);
    return (_jsxs(_Fragment, { children: [_jsx(Subtitle, { className: "em", children: accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.display_name }), _jsx("div", { className: "mb-2" }), _jsxs("div", { className: "flex", children: [isLoading ? _jsx(Spinner, { className: "h-4 w-4" }) : null, accountInfo && (_jsxs(_Fragment, { children: [_jsx(LinkButton, { className: "mr-2", label: "Open Blog", link: accountInfo.author_url }), _jsx(LinkButton, { className: "mr-2", label: "Settings", link: accountInfo.settings_url })] }))] }), showSeparator && _jsx(HorizontalSeparator, { classes: "mt-2.5 mb-3" })] }));
};
export default ListedAccountItem;
