import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonType, ChallengeReason, ChallengeValidation, removeFromArray, } from '@standardnotes/snjs';
import { ProtectedIllustration } from '@standardnotes/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@/Components/Button/Button';
import Icon from '@/Components/Icon/Icon';
import ChallengeModalPrompt from './ChallengePrompt';
import LockscreenWorkspaceSwitcher from './LockscreenWorkspaceSwitcher';
import { classNames } from '@standardnotes/utils';
import ModalOverlay from '../Modal/ModalOverlay';
import Modal from '../Modal/Modal';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import { useAutoElementRect } from '@/Hooks/useElementRect';
const validateValues = (values, prompts) => {
    let hasInvalidValues = false;
    const validatedValues = { ...values };
    for (const prompt of prompts) {
        const value = validatedValues[prompt.id];
        if (typeof value.value === 'string' && value.value.length === 0) {
            validatedValues[prompt.id].invalid = true;
            hasInvalidValues = true;
        }
    }
    if (!hasInvalidValues) {
        return validatedValues;
    }
    return undefined;
};
const ChallengeModal = ({ application, mainApplicationGroup, challenge, onDismiss }) => {
    const promptsContainerRef = useRef(null);
    const [values, setValues] = useState(() => {
        var _a;
        const values = {};
        for (const prompt of challenge.prompts) {
            values[prompt.id] = {
                prompt,
                value: (_a = prompt.initialValue) !== null && _a !== void 0 ? _a : '',
                invalid: false,
            };
        }
        return values;
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [, setProcessingPrompts] = useState([]);
    const shouldShowForgotPasscode = [ChallengeReason.ApplicationUnlock, ChallengeReason.Migration].includes(challenge.reason);
    const shouldShowWorkspaceSwitcher = challenge.reason === ChallengeReason.ApplicationUnlock;
    const submit = useCallback(() => {
        const validatedValues = validateValues(values, challenge.prompts);
        if (!validatedValues) {
            return;
        }
        if (isSubmitting || isProcessing) {
            return;
        }
        setIsSubmitting(true);
        setIsProcessing(true);
        const valuesToProcess = [];
        for (const inputValue of Object.values(validatedValues)) {
            const rawValue = inputValue.value;
            const value = { prompt: inputValue.prompt, value: rawValue };
            valuesToProcess.push(value);
        }
        const processingPrompts = valuesToProcess.map((v) => v.prompt);
        setIsProcessing(processingPrompts.length > 0);
        setProcessingPrompts(processingPrompts);
        /**
         * Unfortunately neccessary to wait 50ms so that the above setState call completely
         * updates the UI to change processing state, before we enter into UI blocking operation
         * (crypto key generation)
         */
        setTimeout(() => {
            if (valuesToProcess.length > 0) {
                if (challenge.customHandler) {
                    void challenge.customHandler(challenge, valuesToProcess);
                }
                else {
                    application.submitValuesForChallenge(challenge, valuesToProcess).catch(console.error);
                }
            }
            else {
                setIsProcessing(false);
            }
            setIsSubmitting(false);
        }, 50);
    }, [application, challenge, isProcessing, isSubmitting, values]);
    const onValueChange = useCallback((value, prompt) => {
        const newValues = { ...values };
        newValues[prompt.id].invalid = false;
        newValues[prompt.id].value = value;
        setValues(newValues);
    }, [values]);
    const cancelChallenge = useCallback(() => {
        if (challenge.cancelable) {
            application.cancelChallenge(challenge);
            onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(challenge);
        }
    }, [application, challenge, onDismiss]);
    useEffect(() => {
        const removeChallengeObserver = application.addChallengeObserver(challenge, {
            onValidValue: (value) => {
                setValues((values) => {
                    const newValues = { ...values };
                    newValues[value.prompt.id].invalid = false;
                    return newValues;
                });
                setProcessingPrompts((currentlyProcessingPrompts) => {
                    const processingPrompts = currentlyProcessingPrompts.slice();
                    removeFromArray(processingPrompts, value.prompt);
                    setIsProcessing(processingPrompts.length > 0);
                    return processingPrompts;
                });
            },
            onInvalidValue: (value) => {
                setValues((values) => {
                    const newValues = { ...values };
                    newValues[value.prompt.id].invalid = true;
                    return newValues;
                });
                /** If custom validation, treat all values together and not individually */
                if (!value.prompt.validates) {
                    setProcessingPrompts([]);
                    setIsProcessing(false);
                }
                else {
                    setProcessingPrompts((currentlyProcessingPrompts) => {
                        const processingPrompts = currentlyProcessingPrompts.slice();
                        removeFromArray(processingPrompts, value.prompt);
                        setIsProcessing(processingPrompts.length > 0);
                        return processingPrompts;
                    });
                }
            },
            onComplete: () => {
                onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(challenge);
            },
            onCancel: () => {
                onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(challenge);
            },
        });
        return () => {
            removeChallengeObserver();
        };
    }, [application, challenge, onDismiss]);
    const biometricPrompt = challenge.prompts.find((prompt) => prompt.validation === ChallengeValidation.Biometric);
    const authenticatorPrompt = challenge.prompts.find((prompt) => prompt.validation === ChallengeValidation.Authenticator);
    const hasOnlyBiometricPrompt = challenge.prompts.length === 1 && !!biometricPrompt;
    const hasOnlyAuthenticatorPrompt = challenge.prompts.length === 1 && !!authenticatorPrompt;
    const wasBiometricInputSuccessful = !!biometricPrompt && !!values[biometricPrompt.id].value;
    const wasAuthenticatorInputSuccessful = !!authenticatorPrompt && !!values[authenticatorPrompt.id].value;
    const hasSecureTextPrompt = challenge.prompts.some((prompt) => prompt.secureTextEntry);
    const shouldShowSubmitButton = !(hasOnlyBiometricPrompt || hasOnlyAuthenticatorPrompt);
    useEffect(() => {
        var _a;
        const shouldAutoSubmit = (hasOnlyBiometricPrompt && wasBiometricInputSuccessful) ||
            (hasOnlyAuthenticatorPrompt && wasAuthenticatorInputSuccessful);
        const shouldFocusSecureTextPrompt = hasSecureTextPrompt && wasBiometricInputSuccessful;
        if (shouldAutoSubmit) {
            submit();
        }
        else if (shouldFocusSecureTextPrompt) {
            const secureTextEntry = (_a = promptsContainerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('input[type="password"]');
            secureTextEntry === null || secureTextEntry === void 0 ? void 0 : secureTextEntry.focus();
        }
    }, [
        wasBiometricInputSuccessful,
        hasOnlyBiometricPrompt,
        submit,
        hasSecureTextPrompt,
        hasOnlyAuthenticatorPrompt,
        wasAuthenticatorInputSuccessful,
    ]);
    useEffect(() => {
        const removeListener = application.addAndroidBackHandlerEventListener(() => {
            if (challenge.cancelable) {
                cancelChallenge();
            }
            return true;
        });
        return () => {
            if (removeListener) {
                removeListener();
            }
        };
    }, [application, cancelChallenge, challenge.cancelable]);
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const isFullScreenBlocker = challenge.reason === ChallengeReason.ApplicationUnlock;
    const [modalElement, setModalElement] = useState(null);
    const modalElementRect = useAutoElementRect(modalElement, {
        updateOnWindowResize: true,
    });
    return (_jsx(ModalOverlay, { isOpen: true, ref: setModalElement, close: cancelChallenge, hideOnInteractOutside: false, backdropClassName: isFullScreenBlocker ? 'bg-passive-5' : '', className: classNames('sn-component challenge-modal relative m-0 flex h-full w-full flex-col items-center rounded border-solid border-border bg-default p-0 md:h-auto md:!w-max', !isMobileScreen && 'shadow-overlay-light'), children: _jsxs(Modal, { title: "Authenticate", close: cancelChallenge, customHeader: _jsx(_Fragment, {}), customFooter: _jsx(_Fragment, {}), disableCustomHeader: isMobileScreen, actions: [
                {
                    label: 'Cancel',
                    onClick: cancelChallenge,
                    type: 'primary',
                    hidden: !challenge.cancelable,
                    mobileSlot: 'right',
                },
            ], children: [challenge.cancelable && (_jsx("button", { onClick: cancelChallenge, "aria-label": "Close modal", className: "absolute right-4 top-4 hidden cursor-pointer border-0 bg-transparent p-1 md:flex", children: _jsx(Icon, { type: "close", className: "text-neutral" }) })), _jsxs("div", { className: "flex min-h-0 w-full flex-grow flex-col items-center overflow-auto p-8", children: [_jsx(ProtectedIllustration, { className: classNames('mb-4 h-30 w-30 flex-shrink-0', modalElementRect && modalElementRect.height < 500 ? 'hidden md:block' : '') }), _jsx("div", { className: "mb-3 max-w-76 text-center text-lg font-bold", children: challenge.heading }), challenge.subheading && (_jsx("div", { className: "break-word mb-4 max-w-76 text-center text-sm", children: challenge.subheading })), _jsx("form", { className: "flex w-full max-w-76 flex-col items-center md:min-w-76", onSubmit: (e) => {
                                e.preventDefault();
                                submit();
                            }, ref: promptsContainerRef, children: challenge.prompts.map((prompt, index) => (_jsx(ChallengeModalPrompt, { application: application, prompt: prompt, values: values, index: index, onValueChange: onValueChange, isInvalid: values[prompt.id].invalid, contextData: prompt.contextData }, prompt.id))) }), shouldShowSubmitButton && (_jsx(Button, { primary: true, disabled: isProcessing, className: "mb-3.5 mt-1 min-w-76", onClick: submit, children: isProcessing ? 'Generating Keys...' : 'Submit' })), shouldShowForgotPasscode && (_jsxs(Button, { className: "flex min-w-76 items-center justify-center", onClick: () => {
                                application.alerts
                                    .confirm('If you forgot your local passcode, your only option is to clear your local data from this device and sign back in to your account.', 'Forgot passcode?', 'Delete local data', ButtonType.Danger)
                                    .then((shouldDeleteLocalData) => {
                                    if (shouldDeleteLocalData) {
                                        application.user.signOut().catch(console.error);
                                    }
                                })
                                    .catch(console.error);
                            }, children: [_jsx(Icon, { type: "help", className: "mr-2 text-neutral" }), "Forgot passcode?"] })), shouldShowWorkspaceSwitcher && _jsx(LockscreenWorkspaceSwitcher, { mainApplicationGroup: mainApplicationGroup })] })] }) }, challenge.id));
};
export default ChallengeModal;
