import { ContentType } from '@standardnotes/snjs';
import { action, makeObservable, observable } from 'mobx';
export class FilePreviewModalController {
    constructor(items) {
        this.isOpen = false;
        this.currentFile = undefined;
        this.otherFiles = [];
        this.eventObservers = [];
        this.deinit = () => {
            this.eventObservers.forEach((observer) => observer());
            this.currentFile = undefined;
            this.otherFiles = undefined;
        };
        this.setCurrentFile = (currentFile) => {
            this.currentFile = currentFile;
        };
        this.activate = (currentFile, otherFiles) => {
            this.currentFile = currentFile;
            if (otherFiles) {
                this.otherFiles = otherFiles;
            }
            this.isOpen = true;
        };
        this.dismiss = () => {
            this.isOpen = false;
        };
        makeObservable(this, {
            isOpen: observable,
            currentFile: observable,
            otherFiles: observable,
            activate: action,
            dismiss: action,
            setCurrentFile: action,
        });
        this.eventObservers.push(items.streamItems(ContentType.TYPES.File, ({ changed, removed }) => {
            if (!this.currentFile) {
                return;
            }
            const changedCurrentFile = changed.find((f) => { var _a; return f.uuid === ((_a = this.currentFile) === null || _a === void 0 ? void 0 : _a.uuid); });
            if (changedCurrentFile) {
                this.setCurrentFile(changedCurrentFile);
            }
            if (removed.find((f) => { var _a; return f.uuid === ((_a = this.currentFile) === null || _a === void 0 ? void 0 : _a.uuid); })) {
                if (!this.otherFiles.length) {
                    this.dismiss();
                    this.currentFile = undefined;
                    return;
                }
                const currentFileIndex = this.otherFiles.findIndex((file) => { var _a; return file.uuid === ((_a = this.currentFile) === null || _a === void 0 ? void 0 : _a.uuid); });
                const nextFileIndex = currentFileIndex + 1 < this.otherFiles.length ? currentFileIndex + 1 : 0;
                this.setCurrentFile(this.otherFiles[nextFileIndex]);
                this.otherFiles = this.otherFiles.filter((file) => { var _a; return file.uuid !== ((_a = this.currentFile) === null || _a === void 0 ? void 0 : _a.uuid); });
            }
        }));
    }
}
