export var RouteType;
(function (RouteType) {
    RouteType["Onboarding"] = "onboarding";
    RouteType["Settings"] = "settings";
    RouteType["Purchase"] = "purchase";
    RouteType["AcceptSubscriptionInvite"] = "accept-subscription-invite";
    RouteType["UserRequest"] = "user-request";
    RouteType["Demo"] = "demo";
    RouteType["AppViewRoute"] = "route";
    RouteType["None"] = "none";
})(RouteType || (RouteType = {}));
