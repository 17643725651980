"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.valueMatchesTargetValue = void 0;
const utils_1 = require("@standardnotes/utils");
const Utils_1 = require("./Utils");
function valueMatchesTargetValue(value, operator, targetValue) {
    if (targetValue == undefined) {
        return false;
    }
    if (typeof targetValue === 'string' && targetValue.includes('.ago')) {
        targetValue = (0, Utils_1.dateFromDSLDateString)(targetValue);
    }
    if (typeof targetValue === 'string') {
        targetValue = targetValue.toLowerCase();
    }
    if (typeof value === 'string') {
        value = value.toLowerCase();
    }
    if (value instanceof Date && typeof targetValue === 'string') {
        targetValue = new Date(targetValue);
    }
    if (operator === 'not') {
        return !valueMatchesTargetValue(value, '=', targetValue);
    }
    const falseyValues = [false, '', null, undefined, NaN];
    if (value == undefined) {
        const isExpectingFalseyValue = falseyValues.includes(targetValue);
        if (operator === '!=') {
            return !isExpectingFalseyValue;
        }
        else {
            return isExpectingFalseyValue;
        }
    }
    if (operator === '=') {
        if (Array.isArray(value)) {
            return JSON.stringify(value) === JSON.stringify(targetValue);
        }
        else {
            return value === targetValue;
        }
    }
    if (operator === '!=') {
        if (Array.isArray(value)) {
            return JSON.stringify(value) !== JSON.stringify(targetValue);
        }
        else {
            return value !== targetValue;
        }
    }
    if (operator === '<') {
        return value < targetValue;
    }
    if (operator === '>') {
        return value > targetValue;
    }
    if (operator === '<=') {
        return value <= targetValue;
    }
    if (operator === '>=') {
        return value >= targetValue;
    }
    if (operator === 'startsWith') {
        return value.startsWith(targetValue);
    }
    if (operator === 'in' && Array.isArray(targetValue)) {
        return targetValue.includes(value);
    }
    if (operator === 'includes') {
        if ((0, utils_1.isString)(value)) {
            return value.includes(targetValue);
        }
        if ((0, utils_1.isString)(targetValue) && ((0, utils_1.isString)(value) || Array.isArray(value))) {
            return value.includes(targetValue);
        }
    }
    if (operator === 'matches') {
        const regex = new RegExp(targetValue);
        return regex.test(value);
    }
    return false;
}
exports.valueMatchesTargetValue = valueMatchesTargetValue;
