import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PreferencesPane from '@/Components/Preferences/PreferencesComponents/PreferencesPane';
import DataBackups from './DataBackups';
import EmailBackups from './EmailBackups';
import FileBackupsCrossPlatform from './Files/FileBackupsCrossPlatform';
import { observer } from 'mobx-react-lite';
import TextBackupsCrossPlatform from './TextBackups/TextBackupsCrossPlatform';
import PlaintextBackupsCrossPlatform from './PlaintextBackups/PlaintextBackupsCrossPlatform';
const Backups = ({ application }) => {
    const isUsingThirdPartyServer = !application.sessions.isSignedIntoFirstPartyServer();
    return (_jsxs(PreferencesPane, { children: [_jsx(DataBackups, { application: application }), _jsx(TextBackupsCrossPlatform, { application: application }), _jsx(PlaintextBackupsCrossPlatform, {}), _jsx(FileBackupsCrossPlatform, { application: application }), !isUsingThirdPartyServer && _jsx(EmailBackups, { application: application })] }));
};
export default observer(Backups);
