/* istanbul ignore file */
export var SyncMode;
(function (SyncMode) {
    /**
     * Performs a standard sync, uploading any dirty items and retrieving items.
     */
    SyncMode["Default"] = "Default";
    /**
     * The first sync for an account, where we first want to download all remote items first
     * before uploading any dirty items. This allows a consumer, for example, to download
     * all data to see if user has an items key, and if not, only then create a new one.
     */
    SyncMode["DownloadFirst"] = "DownloadFirst";
    SyncMode["LocalOnly"] = "LocalOnly";
})(SyncMode || (SyncMode = {}));
