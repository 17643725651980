"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadCollection = void 0;
const Collection_1 = require("../Collection");
class PayloadCollection extends Collection_1.Collection {
    integrityPayloads() {
        const nondeletedElements = this.nondeletedElements();
        return nondeletedElements.map((item) => ({
            uuid: item.uuid,
            updated_at_timestamp: item.serverUpdatedAtTimestamp,
        }));
    }
}
exports.PayloadCollection = PayloadCollection;
