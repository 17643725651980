import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useApplication } from '@/Components/ApplicationProvider';
import Icon from '@/Components/Icon/Icon';
import Spinner from '@/Components/Spinner/Spinner';
import { isDesktopApplication } from '@/Utils';
import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { classNames, Platform } from '@standardnotes/snjs';
import { $getNodeByKey, CLICK_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { $createFileNode } from '../EncryptedFilePlugin/Nodes/FileUtils';
import { isIOS } from '@standardnotes/ui-services';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
const RemoteImageComponent = ({ className, src, alt, node, format, nodeKey }) => {
    const application = useApplication();
    const [editor] = useLexicalComposerContext();
    const [didImageLoad, setDidImageLoad] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const fetchAndUploadImage = useCallback(async () => {
        setIsSaving(true);
        try {
            const response = await fetch(src);
            if (!response.ok) {
                return;
            }
            const blob = await response.blob();
            const file = new File([blob], src, { type: blob.type });
            const { filesController, linkingController } = application;
            const uploadedFile = await filesController.uploadNewFile(file, { showToast: false });
            if (!uploadedFile) {
                return;
            }
            editor.update(() => {
                const fileNode = $createFileNode(uploadedFile.uuid);
                node.replace(fileNode);
            });
            void linkingController.linkItemToSelectedItem(uploadedFile);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsSaving(false);
        }
    }, [application, editor, node, src]);
    const isBase64OrDataUrl = src.startsWith('data:');
    const canShowSaveButton = application.isNativeMobileWeb() || isDesktopApplication() || isBase64OrDataUrl;
    const ref = useRef(null);
    const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey);
    useEffect(() => {
        return editor.registerCommand(CLICK_COMMAND, (event) => {
            var _a, _b;
            if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
                event.preventDefault();
                (_b = $getNodeByKey(nodeKey)) === null || _b === void 0 ? void 0 : _b.selectEnd();
                setTimeout(() => {
                    setSelected(!isSelected);
                });
                return true;
            }
            return false;
        }, COMMAND_PRIORITY_LOW);
    }, [editor, isSelected, nodeKey, setSelected]);
    return (_jsx(BlockWithAlignableContents, { className: className, format: format, nodeKey: nodeKey, children: _jsxs("div", { ref: ref, className: "relative flex min-h-[2rem] flex-col items-center gap-2.5", children: [_jsx("img", { alt: alt, src: src, onLoad: () => {
                        setDidImageLoad(true);
                    } }), didImageLoad && canShowSaveButton && (_jsx("button", { className: classNames('flex items-center gap-2.5 rounded border border-border bg-default px-2.5 py-1.5', !isSaving && 'hover:bg-info hover:text-info-contrast'), onClick: () => {
                        const isIOSPlatform = application.platform === Platform.Ios || isIOS();
                        if (isIOSPlatform && document.activeElement) {
                            ;
                            document.activeElement.blur();
                        }
                        fetchAndUploadImage().catch(console.error);
                    }, disabled: isSaving, children: isSaving ? (_jsxs(_Fragment, { children: [_jsx(Spinner, { className: "h-4 w-4" }), "Saving..."] })) : (_jsxs(_Fragment, { children: [_jsx(Icon, { type: "download" }), "Save image to Files"] })) }))] }) }));
};
export default RemoteImageComponent;
