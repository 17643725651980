"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payloadsByFinalizingSyncState = exports.payloadByFinalizingSyncState = void 0;
const DirtyCounter_1 = require("../../DirtyCounter/DirtyCounter");
function payloadByFinalizingSyncState(payload, baseCollection) {
    const basePayload = baseCollection.find(payload.uuid);
    if (!basePayload) {
        return payload.copyAsSyncResolved({
            dirty: false,
            lastSyncEnd: new Date(),
        });
    }
    const stillDirty = basePayload.dirtyIndex && basePayload.globalDirtyIndexAtLastSync
        ? basePayload.dirtyIndex > basePayload.globalDirtyIndexAtLastSync
        : false;
    return payload.copyAsSyncResolved({
        dirty: stillDirty,
        dirtyIndex: stillDirty ? (0, DirtyCounter_1.getIncrementedDirtyIndex)() : undefined,
        lastSyncEnd: new Date(),
    });
}
exports.payloadByFinalizingSyncState = payloadByFinalizingSyncState;
function payloadsByFinalizingSyncState(payloads, baseCollection) {
    return payloads.map((p) => payloadByFinalizingSyncState(p, baseCollection));
}
exports.payloadsByFinalizingSyncState = payloadsByFinalizingSyncState;
