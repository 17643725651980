import { jsx as _jsx } from "react/jsx-runtime";
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import { useAndroidBackHandler } from '@/NativeMobileWeb/useAndroidBackHandler';
import { createContext, useCallback, useContext, useEffect, useId, useMemo, useState } from 'react';
import MobilePopoverContent from './MobilePopoverContent';
import PositionedPopoverContent from './PositionedPopoverContent';
import { useLifecycleAnimation } from '@/Hooks/useLifecycleAnimation';
const PopoverContext = createContext(null);
const useRegisterPopoverToParent = (popoverId) => {
    const parentPopoverContext = useContext(PopoverContext);
    useEffect(() => {
        const currentId = popoverId;
        parentPopoverContext === null || parentPopoverContext === void 0 ? void 0 : parentPopoverContext.registerChildPopover(currentId);
        return () => {
            parentPopoverContext === null || parentPopoverContext === void 0 ? void 0 : parentPopoverContext.unregisterChildPopover(currentId);
        };
    }, [parentPopoverContext, popoverId]);
};
const PositionedPopoverContentWithAnimation = (props) => {
    const [isMounted, setElement] = useLifecycleAnimation({
        open: props.open,
        exit: {
            keyframes: [
                {
                    opacity: 0,
                    transform: 'scale(0.95)',
                },
            ],
            reducedMotionKeyframes: [
                {
                    opacity: 0,
                },
            ],
            options: {
                duration: 75,
            },
        },
    });
    return isMounted ? (_jsx(PositionedPopoverContent, { setAnimationElement: setElement, ...props, children: props.children })) : null;
};
const Popover = (props) => {
    const popoverId = useId();
    const addAndroidBackHandler = useAndroidBackHandler();
    useRegisterPopoverToParent(popoverId);
    const [childPopovers, setChildPopovers] = useState(new Set());
    const registerChildPopover = useCallback((id) => {
        setChildPopovers((childPopovers) => new Set(childPopovers.add(id)));
    }, []);
    const unregisterChildPopover = useCallback((id) => {
        setChildPopovers((childPopovers) => {
            childPopovers.delete(id);
            return new Set(childPopovers);
        });
    }, []);
    const contextValue = useMemo(() => ({
        registerChildPopover,
        unregisterChildPopover,
    }), [registerChildPopover, unregisterChildPopover]);
    useEffect(() => {
        let removeListener;
        if (props.open) {
            removeListener = addAndroidBackHandler(() => {
                var _a;
                (_a = props.togglePopover) === null || _a === void 0 ? void 0 : _a.call(props);
                return true;
            });
        }
        return () => {
            if (removeListener) {
                removeListener();
            }
        };
    }, [addAndroidBackHandler, props, props.open]);
    useEffect(() => {
        const anchorElement = props.anchorElement && 'current' in props.anchorElement ? props.anchorElement.current : props.anchorElement;
        if (anchorElement) {
            anchorElement.setAttribute('aria-haspopup', 'true');
            if (props.open) {
                anchorElement.setAttribute('aria-expanded', 'true');
            }
            else {
                anchorElement.removeAttribute('aria-expanded');
            }
        }
        return () => {
            if (anchorElement) {
                anchorElement.removeAttribute('aria-haspopup');
                anchorElement.removeAttribute('aria-expanded');
            }
        };
    }, [props.anchorElement, props.open]);
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    if (isMobileScreen && !props.disableMobileFullscreenTakeover) {
        return (_jsx(MobilePopoverContent, { open: props.open, requestClose: () => {
                var _a;
                (_a = props.togglePopover) === null || _a === void 0 ? void 0 : _a.call(props);
            }, title: props.title, className: props.className, id: popoverId, forceFullHeightOnMobile: props.forceFullHeightOnMobile, children: props.children }));
    }
    return (_jsx(PopoverContext.Provider, { value: contextValue, children: _jsx(PositionedPopoverContentWithAnimation, { ...props, childPopovers: childPopovers, id: popoverId }) }));
};
export default Popover;
