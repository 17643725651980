import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractComponent } from '@/Components/Abstract/PureComponent';
import MultipleSelectedNotes from '@/Components/MultipleSelectedNotes/MultipleSelectedNotes';
import MultipleSelectedFiles from '../MultipleSelectedFiles/MultipleSelectedFiles';
import FileView from '../FileView/FileView';
import NoteView from '../NoteView/NoteView';
import { NoteViewController } from '../NoteView/Controller/NoteViewController';
class NoteGroupView extends AbstractComponent {
    constructor(props) {
        super(props, props.application);
        this.state = {
            showMultipleSelectedNotes: false,
            showMultipleSelectedFiles: false,
            controllers: [],
            selectedFile: undefined,
        };
    }
    componentDidMount() {
        super.componentDidMount();
        const controllerGroup = this.application.itemControllerGroup;
        this.removeChangeObserver = this.application.itemControllerGroup.addActiveControllerChangeObserver(() => {
            const controllers = controllerGroup.itemControllers;
            this.setState({
                controllers: controllers,
            });
        });
        this.autorun(() => {
            if (this.application.notesController) {
                this.setState({
                    showMultipleSelectedNotes: this.application.notesController.selectedNotesCount > 1,
                });
            }
            if (this.application.itemListController) {
                this.setState({
                    showMultipleSelectedFiles: this.application.itemListController.selectedFilesCount > 1,
                });
            }
        });
        this.autorun(() => {
            if (this.application.itemListController) {
                this.setState({
                    selectedFile: this.application.itemListController.selectedFiles[0],
                });
            }
        });
        this.autorun(() => {
            if (this.application.paneController) {
                this.setState({
                    selectedPane: this.application.paneController.currentPane,
                    isInMobileView: this.application.paneController.isInMobileView,
                });
            }
        });
    }
    deinit() {
        var _a;
        (_a = this.removeChangeObserver) === null || _a === void 0 ? void 0 : _a.call(this);
        this.removeChangeObserver = undefined;
        super.deinit();
    }
    render() {
        const shouldNotShowMultipleSelectedItems = !this.state.showMultipleSelectedNotes && !this.state.showMultipleSelectedFiles;
        const hasControllers = this.state.controllers.length > 0;
        return (_jsxs(_Fragment, { children: [this.state.showMultipleSelectedNotes && _jsx(MultipleSelectedNotes, { application: this.application }), this.state.showMultipleSelectedFiles && (_jsx(MultipleSelectedFiles, { itemListController: this.application.itemListController })), shouldNotShowMultipleSelectedItems && hasControllers && (_jsx(_Fragment, { children: this.state.controllers.map((controller) => {
                        return controller instanceof NoteViewController ? (_jsx(NoteView, { application: this.application, controller: controller }, controller.runtimeId)) : (_jsx(FileView, { application: this.application, file: controller.item }, controller.runtimeId));
                    }) }))] }));
    }
}
export default NoteGroupView;
