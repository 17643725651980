import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { FileItemActionType } from '../AttachedFilesPopover/PopoverFileItemAction';
import Icon from '@/Components/Icon/Icon';
import { observer } from 'mobx-react-lite';
import { formatSizeToReadableString } from '@standardnotes/filepicker';
import { useResponsiveAppPane } from '../Panes/ResponsivePaneProvider';
import { AppPaneId } from '../Panes/AppPaneMetadata';
import MenuItem from '../Menu/MenuItem';
import { FileContextMenuBackupOption } from './FileContextMenuBackupOption';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
import AddTagOption from '../NotesOptions/AddTagOption';
import { MenuItemIconSize } from '@/Constants/TailwindClassNames';
import AddToVaultMenuOption from '../Vaults/AddToVaultMenuOption';
import { iconClass } from '../NotesOptions/ClassNames';
import { useApplication } from '../ApplicationProvider';
import MenuSection from '../Menu/MenuSection';
import { ToastType, addToast } from '@standardnotes/toast';
const FileMenuOptions = ({ closeMenu, isFileAttachedToNote, renameToggleCallback, shouldShowRenameOption, shouldShowAttachOption, selectedFiles, }) => {
    const application = useApplication();
    const { shouldUseStreamingAPI, handleFileAction } = application.filesController;
    const { toggleAppPane } = useResponsiveAppPane();
    const hasProtectedFiles = useMemo(() => selectedFiles.some((file) => file.protected), [selectedFiles]);
    const hasSelectedMultipleFiles = useMemo(() => selectedFiles.length > 1, [selectedFiles.length]);
    const canShowZipDownloadOption = shouldUseStreamingAPI && hasSelectedMultipleFiles;
    const totalFileSize = useMemo(() => selectedFiles.map((file) => file.decryptedSize).reduce((prev, next) => prev + next, 0), [selectedFiles]);
    const onDetach = useCallback(() => {
        const file = selectedFiles[0];
        void handleFileAction({
            type: FileItemActionType.DetachFileToNote,
            payload: { file },
        });
        closeMenu();
    }, [closeMenu, handleFileAction, selectedFiles]);
    const onAttach = useCallback(() => {
        const file = selectedFiles[0];
        void handleFileAction({
            type: FileItemActionType.AttachFileToNote,
            payload: { file },
        });
        closeMenu();
    }, [closeMenu, handleFileAction, selectedFiles]);
    const closeMenuAndToggleFilesList = useCallback(() => {
        toggleAppPane(AppPaneId.Items);
        closeMenu();
    }, [closeMenu, toggleAppPane]);
    const areSomeFilesInReadonlySharedVault = selectedFiles.some((file) => {
        const vault = application.vaults.getItemVault(file);
        return (vault === null || vault === void 0 ? void 0 : vault.isSharedVaultListing()) && application.vaultUsers.isCurrentUserReadonlyVaultMember(vault);
    });
    const hasAdminPermissionForAllSharedFiles = selectedFiles.every((file) => {
        const vault = application.vaults.getItemVault(file);
        if (!(vault === null || vault === void 0 ? void 0 : vault.isSharedVaultListing())) {
            return true;
        }
        return application.vaultUsers.isCurrentUserSharedVaultAdmin(vault);
    });
    if (selectedFiles.length === 0) {
        return _jsx("div", { className: "text-center", children: "No files selected" });
    }
    return (_jsxs(_Fragment, { children: [selectedFiles.length === 1 && (isFileAttachedToNote || shouldShowAttachOption) && (_jsx(MenuSection, { children: isFileAttachedToNote ? (_jsxs(MenuItem, { onClick: onDetach, children: [_jsx(Icon, { type: "link-off", className: "mr-2 text-neutral" }), "Detach from note"] })) : shouldShowAttachOption ? (_jsxs(MenuItem, { onClick: onAttach, children: [_jsx(Icon, { type: "link", className: "mr-2 text-neutral" }), "Attach to note"] })) : null })), _jsxs(MenuSection, { children: [application.featuresController.isVaultsEnabled() && (_jsx(AddToVaultMenuOption, { iconClassName: iconClass, items: selectedFiles, disabled: !hasAdminPermissionForAllSharedFiles })), _jsx(AddTagOption, { navigationController: application.navigationController, linkingController: application.linkingController, selectedItems: selectedFiles, iconClassName: `text-neutral mr-2 ${MenuItemIconSize}`, disabled: areSomeFilesInReadonlySharedVault }), _jsxs(MenuSwitchButtonItem, { checked: hasProtectedFiles, onChange: (hasProtectedFiles) => {
                            void application.filesController.setProtectionForFiles(hasProtectedFiles, selectedFiles);
                        }, disabled: areSomeFilesInReadonlySharedVault, children: [_jsx(Icon, { type: "lock", className: `mr-2 text-neutral ${MenuItemIconSize}` }), "Password protect"] })] }), _jsxs(MenuSection, { children: [_jsxs(MenuItem, { onClick: () => {
                            void application.filesController.downloadFiles(selectedFiles);
                            closeMenu();
                        }, children: [_jsx(Icon, { type: "download", className: `mr-2 text-neutral ${MenuItemIconSize}` }), "Download ", canShowZipDownloadOption ? 'separately' : ''] }), canShowZipDownloadOption && (_jsxs(MenuItem, { onClick: () => {
                            application.filesController.downloadFilesAsZip(selectedFiles).catch((error) => {
                                if (error instanceof DOMException && error.name === 'AbortError') {
                                    return;
                                }
                                console.error(error);
                                addToast({
                                    type: ToastType.Error,
                                    message: error.message || 'Failed to download files as archive',
                                });
                            });
                            closeMenu();
                        }, children: [_jsx(Icon, { type: "download", className: `mr-2 text-neutral ${MenuItemIconSize}` }), "Download as archive"] })), shouldShowRenameOption && (_jsxs(MenuItem, { onClick: () => {
                            renameToggleCallback === null || renameToggleCallback === void 0 ? void 0 : renameToggleCallback(true);
                        }, disabled: areSomeFilesInReadonlySharedVault, children: [_jsx(Icon, { type: "pencil", className: `mr-2 text-neutral ${MenuItemIconSize}` }), "Rename"] })), _jsxs(MenuItem, { onClick: () => {
                            closeMenuAndToggleFilesList();
                            void application.filesController.deleteFilesPermanently(selectedFiles);
                        }, disabled: areSomeFilesInReadonlySharedVault, children: [_jsx(Icon, { type: "trash", className: `mr-2 text-danger ${MenuItemIconSize}` }), _jsx("span", { className: "text-danger", children: "Delete permanently" })] })] }), _jsx(FileContextMenuBackupOption, { file: selectedFiles[0] }), _jsxs("div", { className: "px-3 pb-0.5 pt-1 text-xs font-medium text-neutral", children: [!hasSelectedMultipleFiles && (_jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "File ID:" }), " ", selectedFiles[0].uuid] })), _jsxs("div", { children: [_jsx("span", { className: "font-semibold", children: hasSelectedMultipleFiles ? 'Total Size:' : 'Size:' }), ' ', formatSizeToReadableString(totalFileSize)] })] })] }));
};
export default observer(FileMenuOptions);
