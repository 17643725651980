import { useCallback, useEffect } from 'react';
import { useLongPressEvent } from './useLongPress';
import { isIOS } from '@standardnotes/ui-services';
export const useContextMenuEvent = (elementRef, listener) => {
    const { attachEvents, cleanupEvents } = useLongPressEvent(elementRef, listener, true);
    const handleContextMenuEvent = useCallback((event) => {
        event.preventDefault();
        listener(event.clientX, event.clientY);
    }, [listener]);
    useEffect(() => {
        const element = elementRef.current;
        if (!element) {
            return;
        }
        const shouldUseLongPress = isIOS();
        element.addEventListener('contextmenu', handleContextMenuEvent);
        if (shouldUseLongPress) {
            attachEvents();
        }
        return () => {
            element.removeEventListener('contextmenu', handleContextMenuEvent);
            if (shouldUseLongPress) {
                cleanupEvents();
            }
        };
    }, [attachEvents, cleanupEvents, elementRef, handleContextMenuEvent, listener]);
};
