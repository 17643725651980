"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadContentsEqual = void 0;
const ItemGenerator_1 = require("../Item/ItemGenerator");
/**
 * Compares the .content fields for equality, creating new SNItem objects
 * to properly handle .content intricacies.
 */
function PayloadContentsEqual(payloadA, payloadB) {
    const itemA = (0, ItemGenerator_1.CreateDecryptedItemFromPayload)(payloadA);
    const itemB = (0, ItemGenerator_1.CreateDecryptedItemFromPayload)(payloadB);
    return itemA.isItemContentEqualWith(itemB);
}
exports.PayloadContentsEqual = PayloadContentsEqual;
