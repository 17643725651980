"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVersionLessThanOrEqualTo = exports.leftVersionGreaterThanOrEqualToRight = exports.compareVersions = exports.ProtocolVersionLength = exports.isProtocolVersionExpired = exports.ProtocolExpirationDates = exports.ProtocolVersionLastNonrootItemsKey = exports.ProtocolVersionLatest = exports.ProtocolVersion = void 0;
var ProtocolVersion;
(function (ProtocolVersion) {
    ProtocolVersion["V001"] = "001";
    ProtocolVersion["V002"] = "002";
    ProtocolVersion["V003"] = "003";
    ProtocolVersion["V004"] = "004";
})(ProtocolVersion || (exports.ProtocolVersion = ProtocolVersion = {}));
exports.ProtocolVersionLatest = ProtocolVersion.V004;
/** The last protocol version to not use root-key based items keys */
exports.ProtocolVersionLastNonrootItemsKey = ProtocolVersion.V003;
exports.ProtocolExpirationDates = Object.freeze({
    [ProtocolVersion.V001]: Date.parse('2018-01-01'),
    [ProtocolVersion.V002]: Date.parse('2020-01-01'),
});
function isProtocolVersionExpired(version) {
    const expireDate = exports.ProtocolExpirationDates[version];
    if (!expireDate) {
        return false;
    }
    const expired = new Date().getTime() > expireDate;
    return expired;
}
exports.isProtocolVersionExpired = isProtocolVersionExpired;
exports.ProtocolVersionLength = 3;
/**
 *  -1 if a < b
 *  0 if a == b
 *  1 if a > b
 */
function compareVersions(a, b) {
    const aNum = Number(a);
    const bNum = Number(b);
    return aNum - bNum;
}
exports.compareVersions = compareVersions;
function leftVersionGreaterThanOrEqualToRight(a, b) {
    return compareVersions(a, b) >= 0;
}
exports.leftVersionGreaterThanOrEqualToRight = leftVersionGreaterThanOrEqualToRight;
function isVersionLessThanOrEqualTo(input, compareTo) {
    return compareVersions(input, compareTo) <= 0;
}
exports.isVersionLessThanOrEqualTo = isVersionLessThanOrEqualTo;
