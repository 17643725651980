import { FileItem, SNNote, SNTag } from '@standardnotes/snjs';
import { getIconAndTintForNoteType } from './getIconAndTintForNoteType';
import { getIconForFileType } from './getIconForFileType';
export function getIconForItem(item, application) {
    if (item instanceof SNNote) {
        const editorForNote = application.componentManager.editorForNote(item);
        const [icon, tint] = getIconAndTintForNoteType(editorForNote.noteType);
        const className = `text-accessory-tint-${tint}`;
        return [icon, className];
    }
    else if (item instanceof FileItem) {
        const icon = getIconForFileType(item.mimeType);
        return [icon, 'text-info'];
    }
    else if (item instanceof SNTag) {
        return [item.iconString, 'text-info'];
    }
    throw new Error('Unhandled case in getItemIcon');
}
