"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevisionApiService = void 0;
const ErrorMessage_1 = require("../../Error/ErrorMessage");
const ApiCallError_1 = require("../../Error/ApiCallError");
const RevisionApiOperations_1 = require("./RevisionApiOperations");
class RevisionApiService {
    constructor(revisionServer) {
        this.revisionServer = revisionServer;
        this.operationsInProgress = new Map();
    }
    listRevisions(itemUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(RevisionApiOperations_1.RevisionApiOperations.List)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(RevisionApiOperations_1.RevisionApiOperations.List, true);
            try {
                const response = yield this.revisionServer.listRevisions({
                    itemUuid,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(RevisionApiOperations_1.RevisionApiOperations.List, false);
            }
        });
    }
    getRevision(itemUuid, revisionUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(RevisionApiOperations_1.RevisionApiOperations.Get)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(RevisionApiOperations_1.RevisionApiOperations.Get, true);
            try {
                const response = yield this.revisionServer.getRevision({
                    itemUuid,
                    revisionUuid,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(RevisionApiOperations_1.RevisionApiOperations.Get, false);
            }
        });
    }
    deleteRevision(itemUuid, revisionUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(RevisionApiOperations_1.RevisionApiOperations.Delete)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(RevisionApiOperations_1.RevisionApiOperations.Delete, true);
            try {
                const response = yield this.revisionServer.deleteRevision({
                    itemUuid,
                    revisionUuid,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(RevisionApiOperations_1.RevisionApiOperations.Delete, false);
            }
        });
    }
}
exports.RevisionApiService = RevisionApiService;
