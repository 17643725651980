"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionCriteriaValidator = void 0;
class CollectionCriteriaValidator {
    constructor(collection, element) {
        this.collection = collection;
        this.element = element;
    }
    passes() {
        return this.collection.has(this.element.uuid);
    }
}
exports.CollectionCriteriaValidator = CollectionCriteriaValidator;
