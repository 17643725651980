import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import MenuItem from '../Menu/MenuItem';
import { useApplication } from '../ApplicationProvider';
import { dateToStringStyle1 } from '@/Utils/DateUtils';
import { MenuItemIconSize } from '@/Constants/TailwindClassNames';
import MenuSection from '../Menu/MenuSection';
export const FileContextMenuBackupOption = ({ file }) => {
    const application = useApplication();
    const [backupInfo, setBackupInfo] = useState(undefined);
    const [backupAbsolutePath, setBackupAbsolutePath] = useState(undefined);
    useEffect(() => {
        var _a;
        void ((_a = application.fileBackups) === null || _a === void 0 ? void 0 : _a.getFileBackupInfo(file).then(setBackupInfo));
    }, [application, file]);
    useEffect(() => {
        var _a;
        if (!backupInfo) {
            return;
        }
        void ((_a = application.fileBackups) === null || _a === void 0 ? void 0 : _a.getFileBackupAbsolutePath(backupInfo).then(setBackupAbsolutePath));
    }, [backupInfo, application]);
    const openFileBackup = useCallback(() => {
        var _a;
        if (backupInfo) {
            void ((_a = application.fileBackups) === null || _a === void 0 ? void 0 : _a.openFileBackup(backupInfo));
        }
    }, [backupInfo, application]);
    const configureFileBackups = useCallback(() => {
        application.openPreferences('backups');
    }, [application]);
    if (!application.fileBackups) {
        return null;
    }
    return (_jsxs(MenuSection, { children: [backupInfo && (_jsx(MenuItem, { icon: 'check-circle', iconClassName: `text-success mt-1 ${MenuItemIconSize}`, className: 'items-start', onClick: openFileBackup, children: _jsxs("div", { className: "ml-2", children: [_jsxs("div", { className: "font-semibold text-success", children: ["Backed up on ", dateToStringStyle1(backupInfo.backedUpOn)] }), _jsx("div", { className: "text-xs text-neutral", children: backupAbsolutePath })] }) })), !backupInfo && application.fileBackups && (_jsx(MenuItem, { icon: 'safe-square', className: 'items-start', iconClassName: `text-neutral mt-1 ${MenuItemIconSize}`, onClick: configureFileBackups, children: _jsxs("div", { className: "ml-2", children: [_jsx("div", { children: "Configure file backups" }), _jsx("div", { className: "text-xs text-neutral", children: "File not backed up locally" })] }) }))] }));
};
