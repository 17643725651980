export var WebAppEvent;
(function (WebAppEvent) {
    WebAppEvent["NewUpdateAvailable"] = "NewUpdateAvailable";
    WebAppEvent["EditorDidFocus"] = "EditorDidFocus";
    WebAppEvent["BeganBackupDownload"] = "BeganBackupDownload";
    WebAppEvent["EndedBackupDownload"] = "EndedBackupDownload";
    WebAppEvent["PanelResized"] = "PanelResized";
    WebAppEvent["WindowDidFocus"] = "WindowDidFocus";
    WebAppEvent["WindowDidBlur"] = "WindowDidBlur";
    WebAppEvent["MobileKeyboardDidChangeFrame"] = "MobileKeyboardDidChangeFrame";
    WebAppEvent["MobileKeyboardWillChangeFrame"] = "MobileKeyboardWillChangeFrame";
})(WebAppEvent || (WebAppEvent = {}));
