import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import NotesOptions from './NotesOptions';
import Popover from '../Popover/Popover';
import RoundIconButton from '../Button/RoundIconButton';
import Menu from '../Menu/Menu';
import { ElementIds } from '@/Constants/ElementIDs';
const NotesOptionsPanel = ({ notesController, onClick, onClickPreprocessing, onButtonBlur }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const toggleMenu = useCallback(async () => {
        const willMenuOpen = !isOpen;
        if (willMenuOpen && onClickPreprocessing) {
            await onClickPreprocessing();
        }
        setIsOpen(willMenuOpen);
        if (onClick) {
            onClick();
        }
    }, [isOpen, onClickPreprocessing, onClick]);
    const [disableClickOutside, setDisableClickOutside] = useState(false);
    const handleDisableClickOutsideRequest = useCallback((disabled) => {
        setDisableClickOutside(disabled);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(RoundIconButton, { id: ElementIds.NoteOptionsButton, label: "Note options menu", onClick: toggleMenu, onBlur: onButtonBlur, ref: buttonRef, icon: "more" }), _jsx(Popover, { title: "Note options", disableClickOutside: disableClickOutside, togglePopover: toggleMenu, anchorElement: buttonRef, open: isOpen, className: "select-none", children: _jsx(Menu, { a11yLabel: "Note options menu", children: _jsx(NotesOptions, { notes: notesController.selectedNotes, requestDisableClickOutside: handleDisableClickOutsideRequest, closeMenu: toggleMenu }) }) })] }));
};
export default observer(NotesOptionsPanel);
