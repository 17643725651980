import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/snjs';
import { useCallback, useEffect, useState } from 'react';
import IconButton from '../Button/IconButton';
const MinimumZoomPercent = 10;
const DefaultZoomPercent = 100;
const MaximumZoomPercent = 1000;
const ZoomPercentModifier = 10;
const PercentageDivisor = 100;
const ImagePreview = ({ objectUrl, isEmbeddedInSuper, imageZoomLevel, setImageZoomLevel, }) => {
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [imageZoomPercent, setImageZoomPercent] = useState(imageZoomLevel ? imageZoomLevel : DefaultZoomPercent);
    const [isZoomInputVisible, setIsZoomInputVisible] = useState(false);
    useEffect(() => {
        setImageZoomPercent(imageZoomLevel ? imageZoomLevel : DefaultZoomPercent);
    }, [imageZoomLevel]);
    const setImageZoom = useCallback((zoomLevel) => {
        setImageZoomPercent(zoomLevel);
        setImageZoomLevel === null || setImageZoomLevel === void 0 ? void 0 : setImageZoomLevel(zoomLevel);
    }, [setImageZoomLevel]);
    useEffect(() => {
        const image = new Image();
        image.src = objectUrl;
        image.onload = () => {
            setImageWidth(image.width);
            setImageHeight(image.height);
        };
    }, [objectUrl]);
    const widthIfEmbedded = imageWidth * (imageZoomPercent / PercentageDivisor);
    return (_jsxs("div", { className: "group relative flex h-full min-h-0 w-full items-center justify-center", children: [_jsx("div", { className: "relative flex h-full w-full items-center justify-center overflow-auto", style: {
                    width: isEmbeddedInSuper ? `${widthIfEmbedded}px` : '',
                    aspectRatio: isEmbeddedInSuper ? `${imageWidth} / ${imageHeight}` : '',
                }, children: _jsx("img", { src: objectUrl, style: {
                        height: isEmbeddedInSuper ? '100%' : `${imageZoomPercent}%`,
                        ...(isEmbeddedInSuper
                            ? {}
                            : imageZoomPercent <= DefaultZoomPercent
                                ? {
                                    minWidth: '100%',
                                    objectFit: 'contain',
                                }
                                : {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    margin: 'auto',
                                    maxWidth: 'none',
                                }),
                    } }) }), _jsxs("div", { className: classNames(isEmbeddedInSuper ? 'hidden focus-within:flex group-hover:flex' : '', 'absolute bottom-6 left-1/2 flex -translate-x-1/2 items-center rounded border border-solid border-border bg-default px-3 py-1'), children: [_jsxs("span", { className: "mr-1.5", children: [isEmbeddedInSuper ? 'Size' : 'Zoom', ":"] }), _jsx(IconButton, { className: "rounded p-1 hover:bg-contrast", icon: 'subtract', title: isEmbeddedInSuper ? 'Decrease size' : 'Zoom Out', focusable: true, onClick: () => {
                            const newPercent = imageZoomPercent - ZoomPercentModifier;
                            if (newPercent >= ZoomPercentModifier) {
                                setImageZoom(newPercent);
                            }
                            else {
                                setImageZoom(imageZoomPercent);
                            }
                        } }), isZoomInputVisible ? (_jsxs("div", { className: "mx-2", children: [_jsx("input", { type: "number", className: "w-10 bg-default text-center", defaultValue: imageZoomPercent, onKeyDown: (event) => {
                                    event.stopPropagation();
                                    if (event.key === 'Enter') {
                                        const value = parseInt(event.currentTarget.value);
                                        if (value >= MinimumZoomPercent && value <= MaximumZoomPercent) {
                                            setImageZoom(value);
                                        }
                                        setIsZoomInputVisible(false);
                                    }
                                }, onBlur: (event) => {
                                    setIsZoomInputVisible(false);
                                    const value = parseInt(event.currentTarget.value);
                                    if (value >= MinimumZoomPercent && value <= MaximumZoomPercent) {
                                        setImageZoom(value);
                                    }
                                } }), "%"] })) : (_jsxs("button", { className: "mx-1 rounded px-1.5 py-1 hover:bg-contrast", onClick: () => setIsZoomInputVisible((visible) => !visible), children: [imageZoomPercent, "%"] })), _jsx(IconButton, { className: "rounded p-1 hover:bg-contrast", icon: "add", title: isEmbeddedInSuper ? 'Increase size' : 'Zoom In', focusable: true, onClick: () => {
                            setImageZoom(imageZoomPercent + ZoomPercentModifier);
                        } })] })] }));
};
export default ImagePreview;
