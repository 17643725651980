import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NativeFeatureIdentifier, NewNoteTitleFormat, PrefKey, isSmartView, isSystemView, PrefDefaults, FeatureStatus, Uuid, } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import Dropdown from '@/Components/Dropdown/Dropdown';
import { getDropdownItemsForAllEditors } from '@/Utils/DropdownItemsForEditors';
import { classNames } from '@standardnotes/utils';
import { NoteTitleFormatOptions } from './NoteTitleFormatOptions';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { getDayjsFormattedString } from '@/Utils/GetDayjsFormattedString';
import { ErrorBoundary } from '@/Utils/ErrorBoundary';
const PrefChangeDebounceTimeInMs = 25;
const HelpPageUrl = 'https://day.js.org/docs/en/display/format#list-of-all-available-formats';
function CustomNoteTitleFormatPreview({ format }) {
    return _jsx("em", { children: getDayjsFormattedString(undefined, format) });
}
const NewNotePreferences = ({ application, selectedTag, mode, changePreferencesCallback, disabled, }) => {
    var _a;
    const premiumModal = usePremiumModal();
    const isSystemTag = isSmartView(selectedTag) && isSystemView(selectedTag);
    const selectedTagPreferences = isSystemTag
        ? (_a = application.getPreference(PrefKey.SystemViewPreferences)) === null || _a === void 0 ? void 0 : _a[selectedTag.uuid]
        : selectedTag.preferences;
    const [editorItems, setEditorItems] = useState([]);
    const [defaultEditorIdentifier, setDefaultEditorIdentifier] = useState(NativeFeatureIdentifier.TYPES.PlainEditor);
    const [newNoteTitleFormat, setNewNoteTitleFormat] = useState(NewNoteTitleFormat.CurrentDateAndTime);
    const [customNoteTitleFormat, setCustomNoteTitleFormat] = useState('');
    const getGlobalEditorDefaultIdentifier = useCallback(() => {
        return application.componentManager.getDefaultEditorIdentifier();
    }, [application]);
    const reloadPreferences = useCallback(() => {
        if (mode === 'tag' && (selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.editorIdentifier)) {
            setDefaultEditorIdentifier(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.editorIdentifier);
        }
        else {
            const globalDefault = getGlobalEditorDefaultIdentifier();
            setDefaultEditorIdentifier(globalDefault);
        }
        if (mode === 'tag' && (selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.newNoteTitleFormat)) {
            setNewNoteTitleFormat(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.newNoteTitleFormat);
        }
        else {
            setNewNoteTitleFormat(application.getPreference(PrefKey.NewNoteTitleFormat, PrefDefaults[PrefKey.NewNoteTitleFormat]));
        }
    }, [
        mode,
        selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.editorIdentifier,
        selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.newNoteTitleFormat,
        getGlobalEditorDefaultIdentifier,
        application,
    ]);
    useEffect(() => {
        if (mode === 'tag' && (selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.customNoteTitleFormat)) {
            setCustomNoteTitleFormat(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.customNoteTitleFormat);
        }
        else {
            setCustomNoteTitleFormat(application.getPreference(PrefKey.CustomNoteTitleFormat, PrefDefaults[PrefKey.CustomNoteTitleFormat]));
        }
    }, [application, mode, selectedTag, selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.customNoteTitleFormat]);
    useEffect(() => {
        void reloadPreferences();
    }, [reloadPreferences]);
    const setNewNoteTitleFormatChange = (value) => {
        setNewNoteTitleFormat(value);
        if (mode === 'global') {
            void application.setPreference(PrefKey.NewNoteTitleFormat, value);
        }
        else {
            void changePreferencesCallback({ newNoteTitleFormat: value });
        }
    };
    useEffect(() => {
        setEditorItems(getDropdownItemsForAllEditors(application));
    }, [application]);
    const selectEditorForNewNoteDefault = useCallback((value) => {
        let identifier = undefined;
        const feature = NativeFeatureIdentifier.create(value);
        if (!feature.isFailed()) {
            identifier = feature.getValue();
        }
        else {
            const thirdPartyEditor = application.componentManager.findComponentWithPackageIdentifier(value);
            if (thirdPartyEditor) {
                identifier = Uuid.create(thirdPartyEditor.uuid).getValue();
            }
        }
        if (!identifier) {
            return;
        }
        if (application.features.getFeatureStatus(identifier) !== FeatureStatus.Entitled) {
            const editorItem = editorItems.find((item) => item.value === value);
            if (editorItem) {
                premiumModal.activate(editorItem.label);
            }
            return;
        }
        setDefaultEditorIdentifier(value);
        if (mode === 'global') {
            void application.setPreference(PrefKey.DefaultEditorIdentifier, value);
        }
        else {
            void changePreferencesCallback({ editorIdentifier: value });
        }
    }, [application, mode, editorItems, premiumModal, changePreferencesCallback]);
    const debounceTimeoutRef = useRef();
    const handleCustomFormatInputChange = (event) => {
        const newFormat = event.currentTarget.value;
        setCustomNoteTitleFormat(newFormat);
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = window.setTimeout(async () => {
            if (mode === 'tag') {
                void changePreferencesCallback({ customNoteTitleFormat: newFormat });
            }
            else {
                void application.setPreference(PrefKey.CustomNoteTitleFormat, newFormat);
            }
        }, PrefChangeDebounceTimeInMs);
    };
    return (_jsxs("div", { className: "px-3 py-3", children: [_jsxs("div", { children: [_jsx("div", { className: "text-mobile-menu-item md:text-menu-item", children: "Note Type" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { disabled: disabled, fullWidth: true, label: "Select the default note type", items: editorItems, value: defaultEditorIdentifier, onChange: (value) => selectEditorForNewNoteDefault(value) }) })] }), _jsxs("div", { children: [_jsx("div", { className: "mt-3 text-mobile-menu-item md:text-menu-item", children: "Title Format" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { disabled: disabled, fullWidth: true, label: "Select the format for the note title", items: NoteTitleFormatOptions, value: newNoteTitleFormat, onChange: setNewNoteTitleFormatChange }) })] }), newNoteTitleFormat === NewNoteTitleFormat.CustomFormat && (_jsxs("div", { className: "mt-2", children: [_jsx("div", { className: "mt-2", children: _jsx("input", { disabled: disabled, className: classNames('w-full min-w-55 rounded border border-solid border-passive-3 bg-default px-2 py-1.5 text-sm md:translucent-ui:bg-transparent', 'focus-within:ring-2 focus-within:ring-info'), placeholder: "e.g. YYYY-MM-DD", value: customNoteTitleFormat, onChange: handleCustomFormatInputChange, spellCheck: false }) }), _jsxs("div", { className: "mt-3 text-neutral", children: [_jsx("span", { className: "font-bold", children: "Preview: " }), _jsx(ErrorBoundary, { children: _jsx(CustomNoteTitleFormatPreview, { format: customNoteTitleFormat }) })] }), _jsxs("div", { className: "mt-2 text-neutral", children: [_jsx("a", { className: "underline", href: HelpPageUrl, rel: "noreferrer", target: "_blank", onClick: (event) => {
                                    if (application.isNativeMobileWeb()) {
                                        event.preventDefault();
                                        application.mobileDevice.openUrl(HelpPageUrl);
                                    }
                                }, children: "Options" }), ". Use ", _jsx("code", { children: "[]" }), " to escape formatting."] })] }))] }));
};
export default observer(NewNotePreferences);
