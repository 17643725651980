import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
import { SyncQueueStrategy } from '@standardnotes/snjs';
import { STRING_GENERIC_SYNC_ERROR } from '@/Constants/Strings';
import { formatLastSyncDate } from '@/Utils/DateUtils';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
const Sync = ({ application }) => {
    const [isSyncingInProgress, setIsSyncingInProgress] = useState(false);
    const [lastSyncDate, setLastSyncDate] = useState(formatLastSyncDate(application.sync.getLastSyncDate()));
    const doSynchronization = async () => {
        setIsSyncingInProgress(true);
        const response = await application.sync.sync({
            queueStrategy: SyncQueueStrategy.ForceSpawnNew,
            checkIntegrity: true,
        });
        setIsSyncingInProgress(false);
        if (response && response.error) {
            application.alerts.alert(STRING_GENERIC_SYNC_ERROR).catch(console.error);
        }
        else {
            setLastSyncDate(formatLastSyncDate(application.sync.getLastSyncDate()));
        }
    };
    return (_jsx(PreferencesGroup, { children: _jsx(PreferencesSegment, { children: _jsx("div", { className: "flex flex-row items-center", children: _jsxs("div", { className: "flex flex-grow flex-col", children: [_jsx(Title, { children: "Sync" }), _jsxs(Text, { children: ["Last synced ", _jsxs("span", { className: "font-bold", children: ["on ", lastSyncDate] })] }), _jsx(Button, { className: "mt-3 min-w-20", label: "Sync now", disabled: isSyncingInProgress, onClick: doSynchronization })] }) }) }) }));
};
export default observer(Sync);
