import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
export class BlockPickerOption extends MenuOption {
    constructor(title, options) {
        super(title);
        this.title = title;
        this.keywords = options.keywords || [];
        this.iconName = options.iconName;
        this.keyboardShortcut = options.keyboardShortcut;
        this.onSelect = options.onSelect.bind(this);
    }
}
