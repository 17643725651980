import { Result } from '@standardnotes/domain-core';
import { isAndroid, isIOS } from '../Utils/Utils';
export class IsMobileDevice {
    constructor(_isNativeMobileWeb) {
        this._isNativeMobileWeb = _isNativeMobileWeb;
    }
    execute() {
        return Result.ok(this._isNativeMobileWeb.execute().getValue() || isIOS() || isAndroid());
    }
}
