import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ComponentArea, ContentType, NativeFeatureIdentifier, PreferencesServiceEvent, } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import FocusModeSwitch from './FocusModeSwitch';
import ThemesMenuButton from './ThemesMenuButton';
import { sortThemes } from '@/Utils/SortThemes';
import PanelSettingsSection from './PanelSettingsSection';
import Menu from '../Menu/Menu';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
import MenuRadioButtonItem from '../Menu/MenuRadioButtonItem';
import { useApplication } from '../ApplicationProvider';
import { GetAllThemesUseCase } from '@standardnotes/ui-services';
import MenuSection from '../Menu/MenuSection';
const QuickSettingsMenu = ({ closeMenu }) => {
    const application = useApplication();
    const { focusModeEnabled, setFocusModeEnabled } = application.paneController;
    const [themes, setThemes] = useState([]);
    const [editorStackComponents, setEditorStackComponents] = useState([]);
    const activeThemes = application.componentManager.getActiveThemes();
    const hasNonLayerableActiveTheme = activeThemes.find((theme) => !theme.layerable);
    const defaultThemeOn = !hasNonLayerableActiveTheme;
    const prefsButtonRef = useRef(null);
    const defaultThemeButtonRef = useRef(null);
    const reloadThemes = useCallback(() => {
        const usecase = new GetAllThemesUseCase(application.items);
        const { thirdParty, native } = usecase.execute({ excludeLayerable: false });
        setThemes([...thirdParty, ...native].sort(sortThemes));
    }, [application]);
    const reloadEditorStackComponents = useCallback(() => {
        const toggleableComponents = application.items
            .getDisplayableComponents()
            .filter((component) => !component.isTheme() &&
            [ComponentArea.EditorStack].includes(component.area) &&
            component.identifier !== NativeFeatureIdentifier.TYPES.DeprecatedFoldersComponent);
        setEditorStackComponents(toggleableComponents);
    }, [application]);
    useEffect(() => {
        if (!themes.length) {
            reloadThemes();
        }
    }, [reloadThemes, themes.length]);
    useEffect(() => {
        const cleanupItemStream = application.items.streamItems(ContentType.TYPES.Theme, () => {
            reloadThemes();
        });
        return () => {
            cleanupItemStream();
        };
    }, [application, reloadThemes]);
    useEffect(() => {
        return application.preferences.addEventObserver((event) => {
            if (event === PreferencesServiceEvent.LocalPreferencesChanged) {
                reloadThemes();
            }
        });
    }, [application, reloadThemes]);
    useEffect(() => {
        const cleanupItemStream = application.items.streamItems(ContentType.TYPES.Component, () => {
            reloadEditorStackComponents();
        });
        return () => {
            cleanupItemStream();
        };
    }, [application, reloadEditorStackComponents]);
    useEffect(() => {
        var _a;
        (_a = prefsButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const toggleEditorStackComponent = useCallback((component) => {
        void application.componentManager.toggleComponent(component);
    }, [application]);
    const deactivateAnyNonLayerableTheme = useCallback(() => {
        const nonLayerableActiveTheme = application.componentManager.getActiveThemes().find((theme) => !theme.layerable);
        if (nonLayerableActiveTheme) {
            void application.componentManager.toggleTheme(nonLayerableActiveTheme);
        }
    }, [application]);
    const toggleDefaultTheme = useCallback(() => {
        deactivateAnyNonLayerableTheme();
    }, [deactivateAnyNonLayerableTheme]);
    return (_jsxs(Menu, { a11yLabel: "Quick settings menu", children: [editorStackComponents.length > 0 && (_jsx(MenuSection, { title: "Tools", children: editorStackComponents.map((component) => (_jsxs(MenuSwitchButtonItem, { onChange: () => {
                        toggleEditorStackComponent(component);
                    }, checked: application.componentManager.isComponentActive(component), children: [_jsx(Icon, { type: "window", className: "mr-2 text-neutral" }), component.displayName] }, component.uuid))) })), _jsxs(MenuSection, { title: "Appearance", children: [_jsx(MenuRadioButtonItem, { checked: defaultThemeOn, onClick: toggleDefaultTheme, ref: defaultThemeButtonRef, children: "Default" }), themes.map((theme) => (_jsx(ThemesMenuButton, { uiFeature: theme }, theme.uniqueIdentifier.value)))] }), _jsx(FocusModeSwitch, { application: application, onToggle: setFocusModeEnabled, onClose: closeMenu, isEnabled: focusModeEnabled }), _jsx(PanelSettingsSection, {})] }));
};
export default observer(QuickSettingsMenu);
