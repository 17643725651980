"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentMutator = void 0;
const utils_1 = require("@standardnotes/utils");
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
class ComponentMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    set package_info(package_info) {
        this.mutableContent.package_info = package_info;
    }
    set local_url(local_url) {
        this.mutableContent.local_url = local_url;
    }
    set hosted_url(hosted_url) {
        this.mutableContent.hosted_url = hosted_url;
    }
    set valid_until(valid_until) {
        this.mutableContent.valid_until = valid_until;
    }
    set permissions(permissions) {
        this.mutableContent.permissions = permissions;
    }
    set name(name) {
        this.mutableContent.name = name;
    }
    set offlineOnly(offlineOnly) {
        this.mutableContent.offlineOnly = offlineOnly;
    }
    associateWithItem(uuid) {
        const associated = this.mutableContent.associatedItemIds || [];
        (0, utils_1.addIfUnique)(associated, uuid);
        this.mutableContent.associatedItemIds = associated;
    }
    disassociateWithItem(uuid) {
        const disassociated = this.mutableContent.disassociatedItemIds || [];
        (0, utils_1.addIfUnique)(disassociated, uuid);
        this.mutableContent.disassociatedItemIds = disassociated;
    }
    removeAssociatedItemId(uuid) {
        (0, utils_1.removeFromArray)(this.mutableContent.associatedItemIds || [], uuid);
    }
    removeDisassociatedItemId(uuid) {
        (0, utils_1.removeFromArray)(this.mutableContent.disassociatedItemIds || [], uuid);
    }
}
exports.ComponentMutator = ComponentMutator;
