import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useApplication } from '@/Components/ApplicationProvider';
import Button from '@/Components/Button/Button';
import Icon from '@/Components/Icon/Icon';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import { useCallback, useState } from 'react';
import EditContactModal from '../Contacts/EditContactModal';
import { CheckmarkCircle } from '../../../../UIElements/CheckmarkCircle';
const InviteItem = ({ inviteRecord }) => {
    const application = useApplication();
    const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
    const isTrusted = inviteRecord.trusted;
    const inviteData = inviteRecord.message.data;
    const addAsTrustedContact = useCallback(() => {
        setIsAddContactModalOpen(true);
    }, []);
    const acceptInvite = useCallback(async () => {
        const result = await application.vaultInvites.acceptInvite(inviteRecord);
        if (result.isFailed()) {
            await application.alerts.alert(result.getError());
        }
    }, [application, inviteRecord]);
    const closeAddContactModal = () => setIsAddContactModalOpen(false);
    const collaborationId = application.contacts.getCollaborationIDFromInvite(inviteRecord.invite);
    const trustedContact = application.contacts.findSenderContactForInvite(inviteRecord.invite);
    const permission = application.vaultUsers.getFormattedMemberPermission(inviteRecord.invite.permission);
    return (_jsxs(_Fragment, { children: [_jsx(ModalOverlay, { isOpen: isAddContactModalOpen, close: closeAddContactModal, children: _jsx(EditContactModal, { fromInvite: inviteRecord, onCloseDialog: closeAddContactModal }) }), _jsxs("div", { className: "flex gap-3.5 rounded-lg border border-border px-3.5 py-2.5 shadow", children: [_jsx(Icon, { type: "archive", size: "custom", className: "mt-1.5 h-5.5 w-5.5 flex-shrink-0" }), _jsxs("div", { className: "flex flex-col gap-2 overflow-hidden py-1.5", children: [_jsxs("div", { className: "overflow-hidden text-ellipsis text-sm", children: ["Vault Name: ", inviteData.metadata.name] }), inviteData.metadata.description && (_jsxs("div", { className: "overflow-hidden text-ellipsis text-sm", children: ["Vault Description: ", inviteData.metadata.description] })), trustedContact ? (_jsxs("div", { className: "flex items-center gap-1", children: [_jsxs("span", { className: "overflow-hidden text-ellipsis text-sm", children: ["Trusted Sender: ", trustedContact.name] }), _jsx(CheckmarkCircle, { className: "!h-4 !w-4" })] })) : (_jsxs("div", { className: "w-full overflow-hidden whitespace-pre-wrap break-words text-sm", children: ["Sender CollaborationID: ", _jsx("span", { className: "font-mono text-xs", children: collaborationId })] })), _jsxs("div", { className: "overflow-hidden text-ellipsis text-sm", children: ["Permission: ", permission] }), _jsx("div", { className: "", children: isTrusted ? (_jsx(Button, { label: "Accept Invite", className: "text-xs", onClick: acceptInvite })) : (_jsxs("div", { children: [_jsx("div", { children: "The sender of this invite is not trusted. To accept this invite, first add the sender as a trusted contact." }), _jsx(Button, { label: "Add Trusted Contact", className: "mr-3 mt-2 text-xs", onClick: addAsTrustedContact })] })) })] })] })] }));
};
export default InviteItem;
