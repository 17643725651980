import { ContentType } from '@standardnotes/domain-core';
import { ConflictStrategy, DecryptedItem, ProtocolVersion, } from '@standardnotes/models';
export function isItemsKey(x) {
    return x.content_type === ContentType.TYPES.ItemsKey;
}
/**
 * A key used to encrypt other items. Items keys are synced and persisted.
 */
export class SNItemsKey extends DecryptedItem {
    constructor(payload) {
        super(payload);
        this.keyVersion = payload.content.version;
        this.isDefault = payload.content.isDefault;
        this.itemsKey = this.payload.content.itemsKey;
    }
    /** Do not duplicate items keys. Always keep original */
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return ConflictStrategy.KeepBase;
    }
    get dataAuthenticationKey() {
        if (this.keyVersion === ProtocolVersion.V004) {
            throw 'Attempting to access legacy data authentication key.';
        }
        return this.payload.content.dataAuthenticationKey;
    }
}
