import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import Backups from '@/Components/Preferences/Panes/Backups/Backups';
import Appearance from './Panes/Appearance';
import General from './Panes/General/General';
import AccountPreferences from './Panes/Account/AccountPreferences';
import Security from './Panes/Security/Security';
import Listed from './Panes/Listed/Listed';
import HelpAndFeedback from './Panes/HelpFeedback';
import WhatsNew from './Panes/WhatsNew/WhatsNew';
import HomeServer from './Panes/HomeServer/HomeServer';
import Vaults from './Panes/Vaults/Vaults';
import PluginsPane from './Panes/Plugins/PluginsPane';
const PaneSelector = ({ menu, application, }) => {
    switch (menu.selectedPaneId) {
        case 'general':
            return _jsx(General, {});
        case 'account':
            return _jsx(AccountPreferences, { application: application });
        case 'appearance':
            return _jsx(Appearance, { application: application });
        case 'home-server':
            return _jsx(HomeServer, {});
        case 'security':
            return _jsx(Security, { application: application });
        case 'vaults':
            return _jsx(Vaults, {});
        case 'backups':
            return _jsx(Backups, { application: application });
        case 'listed':
            return _jsx(Listed, { application: application });
        case 'shortcuts':
            return null;
        case 'plugins':
            return _jsx(PluginsPane, { pluginsLatestVersions: menu.extensionsLatestVersions });
        case 'accessibility':
            return null;
        case 'get-free-month':
            return null;
        case 'help-feedback':
            return _jsx(HelpAndFeedback, { application: application });
        case 'whats-new':
            return _jsx(WhatsNew, { application: application });
        default:
            return _jsx(General, {});
    }
};
export default observer(PaneSelector);
