import { addDaysToDate } from '@standardnotes/utils';
export function dateToDailyDayIdentifier(date) {
    return date.toLocaleDateString();
}
export function getDailyWritingStreak(todayItem, itemsByDateMapping) {
    var _a;
    if (!todayItem) {
        return 0;
    }
    const startDay = todayItem.date;
    let checkingDayOffsetFromToday = -1;
    let keepLooping = true;
    let streak = 0;
    while (keepLooping) {
        const checkingDay = addDaysToDate(startDay, checkingDayOffsetFromToday);
        const items = itemsByDateMapping[dateToDailyDayIdentifier(checkingDay)];
        if (!items || (items === null || items === void 0 ? void 0 : items.length) === 0) {
            keepLooping = false;
            break;
        }
        streak++;
        checkingDayOffsetFromToday--;
    }
    const hasEntryForToday = ((_a = itemsByDateMapping[dateToDailyDayIdentifier(todayItem.date)]) === null || _a === void 0 ? void 0 : _a.length) > 0;
    return streak + (hasEntryForToday ? 1 : 0);
}
