"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOfflineSyncSavedPayload = void 0;
const Payload_1 = require("../Payload");
function CreateOfflineSyncSavedPayload(fromPayload) {
    return {
        content_type: fromPayload.content_type,
        created_at_timestamp: fromPayload.created_at_timestamp,
        deleted: (0, Payload_1.isDeletedPayload)(fromPayload),
        updated_at_timestamp: fromPayload.updated_at_timestamp,
        updated_at: fromPayload.updated_at,
        uuid: fromPayload.uuid,
    };
}
exports.CreateOfflineSyncSavedPayload = CreateOfflineSyncSavedPayload;
