import { Result } from '@standardnotes/domain-core';
import { PrefDefaults, PrefKey } from '@standardnotes/models';
export class IsGlobalSpellcheckEnabled {
    constructor(preferences) {
        this.preferences = preferences;
    }
    execute() {
        return Result.ok(this.preferences.getValue(PrefKey.EditorSpellcheck, PrefDefaults[PrefKey.EditorSpellcheck]));
    }
}
