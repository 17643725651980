import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import { observer } from 'mobx-react-lite';
import { Title, Text } from '../../PreferencesComponents/Content';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import { useApplication } from '@/Components/ApplicationProvider';
const ClearSessionDataView = () => {
    const application = useApplication();
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Clear workspace" }), _jsx(Text, { className: "mb-3", children: "Remove all data related to the current workspace from the application." }), _jsx(Button, { colorStyle: "danger", label: "Clear workspace", onClick: () => {
                        application.accountMenuController.setSigningOut(true);
                    } })] }) }));
};
export default observer(ClearSessionDataView);
