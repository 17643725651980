import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { Text, Title, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import { ButtonType, ClientDisplayableError, ContentType } from '@standardnotes/snjs';
import Button from '@/Components/Button/Button';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import { ErrorCircle } from '@/Components/UIElements/ErrorCircle';
import { useApplication } from '@/Components/ApplicationProvider';
const ErroredItems = () => {
    const application = useApplication();
    const [erroredItems, setErroredItems] = useState(application.items.invalidNonVaultedItems);
    useEffect(() => {
        return application.items.streamItems(ContentType.TYPES.Any, () => {
            setErroredItems(application.items.invalidNonVaultedItems);
        });
    }, [application]);
    const getContentTypeDisplay = (item) => {
        const contentTypeOrError = ContentType.create(item.content_type);
        let display = null;
        if (!contentTypeOrError.isFailed()) {
            display = contentTypeOrError.getValue().getDisplayName();
        }
        if (display) {
            return `${display[0].toUpperCase()}${display.slice(1)}`;
        }
        else {
            return `Item of type ${item.content_type}`;
        }
    };
    const deleteItem = async (item) => {
        return deleteItems([item]);
    };
    const deleteItems = async (items) => {
        const confirmed = await application.alerts.confirm(`Are you sure you want to permanently delete ${items.length} item(s)?`, undefined, 'Delete', ButtonType.Danger);
        if (!confirmed) {
            return;
        }
        void application.mutator.deleteItems(items).then(() => {
            void application.sync.sync();
        });
        setErroredItems(application.items.invalidItems);
    };
    const attemptDecryption = (item) => {
        const errorOrTrue = application.canAttemptDecryptionOfItem(item);
        if (errorOrTrue instanceof ClientDisplayableError) {
            void application.alerts.showErrorAlert(errorOrTrue);
            return;
        }
        application.presentKeyRecoveryWizard();
    };
    if (erroredItems.length === 0) {
        return null;
    }
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsxs(Title, { className: "flex flex-row items-center gap-2", children: [_jsx(ErrorCircle, {}), "Error decrypting items"] }), _jsx(Text, { children: `${erroredItems.length} items are errored and could not be decrypted.` }), _jsxs("div", { className: "flex", children: [_jsx(Button, { className: "mr-2 mt-3 min-w-20", label: "Export all", onClick: () => {
                                void application.archiveService.downloadEncryptedItems(erroredItems);
                            } }), _jsx(Button, { className: "mr-2 mt-3 min-w-20", colorStyle: "danger", label: "Delete all", onClick: () => {
                                void deleteItems(erroredItems);
                            } })] }), _jsx(HorizontalSeparator, { classes: "mt-2.5 mb-3" }), erroredItems.map((item, index) => {
                    return (_jsxs(Fragment, { children: [_jsx("div", { className: "flex items-center justify-between", children: _jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: `${getContentTypeDisplay(item)} created on ${item.createdAtString}` }), _jsxs(Text, { children: ["Item ID: ", item.uuid] }), _jsxs(Text, { children: ["Last Modified: ", item.updatedAtString] }), _jsxs("div", { className: "flex", children: [_jsx(Button, { className: "mr-2 mt-3 min-w-20", label: "Attempt decryption", onClick: () => {
                                                        attemptDecryption(item);
                                                    } }), _jsx(Button, { className: "mr-2 mt-3 min-w-20", label: "Export", onClick: () => {
                                                        void application.archiveService.downloadEncryptedItem(item);
                                                    } }), _jsx(Button, { className: "mr-2 mt-3 min-w-20", colorStyle: "danger", label: "Delete", onClick: () => {
                                                        void deleteItem(item);
                                                    } })] })] }) }), index < erroredItems.length - 1 && _jsx(HorizontalSeparator, { classes: "mt-2.5 mb-3" })] }, item.uuid));
                })] }) }));
};
export default observer(ErroredItems);
