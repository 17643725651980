import { storage, StorageKey } from '@standardnotes/ui-services';
import { ApplicationEvent, } from '@standardnotes/snjs';
import { runInAction, makeObservable, observable, action } from 'mobx';
import { AbstractViewController } from './Abstract/AbstractViewController';
export class NoAccountWarningController extends AbstractViewController {
    constructor(sessions, eventBus) {
        var _a;
        super(eventBus);
        this.sessions = sessions;
        this.hide = () => {
            this.show = false;
            storage.set(StorageKey.ShowNoAccountWarning, false);
        };
        this.reset = () => {
            storage.remove(StorageKey.ShowNoAccountWarning);
        };
        this.show = sessions.isSignedIn() ? false : (_a = storage.get(StorageKey.ShowNoAccountWarning)) !== null && _a !== void 0 ? _a : true;
        eventBus.addEventHandler(this, ApplicationEvent.SignedIn);
        eventBus.addEventHandler(this, ApplicationEvent.Started);
        makeObservable(this, {
            show: observable,
            hide: action,
        });
    }
    async handleEvent(event) {
        switch (event.type) {
            case ApplicationEvent.SignedIn:
                runInAction(() => {
                    this.show = false;
                });
                break;
            case ApplicationEvent.Started:
                if (this.sessions.isSignedIn()) {
                    runInAction(() => {
                        this.show = false;
                    });
                }
                break;
        }
    }
}
