"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValetTokenOperation = void 0;
var ValetTokenOperation;
(function (ValetTokenOperation) {
    ValetTokenOperation["Read"] = "read";
    ValetTokenOperation["Write"] = "write";
    ValetTokenOperation["Delete"] = "delete";
    ValetTokenOperation["Move"] = "move";
})(ValetTokenOperation || (exports.ValetTokenOperation = ValetTokenOperation = {}));
