"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExcludeVaultsCriteriaValidator = void 0;
class ExcludeVaultsCriteriaValidator {
    constructor(excludeVaults, element) {
        this.excludeVaults = excludeVaults;
        this.element = element;
    }
    passes() {
        const doesElementBelongToExcludedVault = this.excludeVaults.some((vault) => this.element.key_system_identifier === vault);
        return !doesElementBelongToExcludedVault;
    }
}
exports.ExcludeVaultsCriteriaValidator = ExcludeVaultsCriteriaValidator;
