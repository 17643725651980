import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/snjs';
import { KeyboardKey } from '@standardnotes/ui-services';
import { useCallback, useState, useRef } from 'react';
import { useApplication } from '../ApplicationProvider';
import Icon from '../Icon/Icon';
function TableRow({ row, index: rowIndex, canSelectRows, handleRowClick, handleRowContextMenu, handleActivateRow, }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const isHoveredOrFocused = isHovered || isFocused;
    const visibleCells = row.cells.filter((cell) => !cell.hidden);
    return (_jsx("div", { role: "row", id: row.id, "aria-rowindex": rowIndex + 2, ...(canSelectRows ? { 'aria-selected': row.isSelected } : {}), className: "group relative contents", onMouseEnter: () => {
            setIsHovered(true);
        }, onMouseLeave: () => {
            setIsHovered(false);
        }, onClick: (event) => handleRowClick(event, row.id), onDoubleClick: () => handleActivateRow(row.id), onContextMenu: handleRowContextMenu(row.id), onFocus: () => {
            setIsFocused(true);
        }, onBlur: (event) => {
            var _a;
            if (!((_a = event.relatedTarget) === null || _a === void 0 ? void 0 : _a.closest(`[id="${row.id}"]`))) {
                setIsFocused(false);
            }
        }, children: visibleCells.map((cell, index, array) => {
            return (_jsxs("div", { role: "gridcell", "aria-rowindex": rowIndex + 2, "aria-colindex": cell.colIndex + 1, className: classNames('relative flex items-center overflow-hidden border-b border-border px-3 py-4 focus:border-info', row.isSelected && 'bg-info-backdrop', canSelectRows && 'cursor-pointer', canSelectRows && isHoveredOrFocused && 'bg-contrast'), tabIndex: -1, children: [cell.render, row.rowActions && index === array.length - 1 && (_jsxs("div", { className: classNames('absolute right-0 top-0 flex h-full items-center p-2', row.isSelected ? '' : isHoveredOrFocused ? '' : 'invisible', isFocused && 'visible'), children: [_jsx("div", { className: "z-[1]", children: row.rowActions }), _jsx("div", { className: classNames('absolute right-0 top-0 z-0 h-full w-full backdrop-blur-[2px]', row.isSelected ? '' : isHoveredOrFocused ? '' : 'invisible') })] }))] }, index));
        }) }));
}
const MinTableRowHeight = 50;
const MinRowsToDisplay = 20;
const PageSize = Math.ceil(document.documentElement.clientHeight / MinTableRowHeight) || MinRowsToDisplay;
const PageScrollThreshold = 200;
function Table({ table }) {
    const application = useApplication();
    const [rowsToDisplay, setRowsToDisplay] = useState(PageSize);
    const paginate = useCallback(() => {
        setRowsToDisplay((cellsToDisplay) => cellsToDisplay + PageSize);
    }, []);
    const onScroll = useCallback((event) => {
        const offset = PageScrollThreshold;
        const element = event.target;
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight - offset) {
            paginate();
        }
    }, [paginate]);
    const { id, headers, rows, colCount, rowCount, selectRow, multiSelectRow, rangeSelectUpToRow, handleRowContextMenu, handleActivateRow, selectedRows, selectionActions, canSelectRows, canSelectMultipleRows, showSelectionActions, } = table;
    const focusedRowIndex = useRef(0);
    const focusedCellIndex = useRef(0);
    const onFocus = useCallback((event) => {
        const target = event.target;
        const row = target.closest('[role="row"]');
        const cell = target.closest('[role="gridcell"],[role="columnheader"]');
        if (row) {
            focusedRowIndex.current = parseInt(row.getAttribute('aria-rowindex') || '0');
        }
        if (cell) {
            focusedCellIndex.current = parseInt(cell.getAttribute('aria-colindex') || '0');
        }
    }, []);
    const onBlur = useCallback((event) => {
        const activeElement = document.activeElement;
        if (activeElement.closest('[role="grid"]') !== event.target) {
            focusedRowIndex.current = 0;
            focusedCellIndex.current = 0;
        }
    }, []);
    const onKeyDown = useCallback((event) => {
        const gridElement = event.currentTarget;
        const allRenderedRows = gridElement.querySelectorAll('[role="row"]');
        const currentRow = Array.from(allRenderedRows).find((row) => row.getAttribute('aria-rowindex') === focusedRowIndex.current.toString());
        const allFocusableCells = Array.from(currentRow ? currentRow.querySelectorAll('[tabindex]') : []);
        const allRenderedColumnsLength = headers.length;
        const focusCell = (rowIndex, colIndex) => {
            const row = gridElement.querySelector(`[role="row"][aria-rowindex="${rowIndex}"]`);
            if (!row) {
                return;
            }
            const cell = row.querySelector(`[aria-colindex="${colIndex}"]`);
            if (cell) {
                cell.focus();
            }
        };
        switch (event.key) {
            case KeyboardKey.Up:
                event.preventDefault();
                if (focusedRowIndex.current > 1) {
                    const previousRow = focusedRowIndex.current - 1;
                    focusCell(previousRow, focusedCellIndex.current);
                }
                break;
            case KeyboardKey.Down:
                event.preventDefault();
                if (focusedRowIndex.current <= rowCount) {
                    const nextRow = focusedRowIndex.current + 1;
                    focusCell(nextRow, focusedCellIndex.current);
                }
                break;
            case KeyboardKey.Left: {
                event.preventDefault();
                if (!allFocusableCells) {
                    return;
                }
                const currentCellIndex = allFocusableCells.findIndex((cell) => parseInt(cell.getAttribute('aria-colindex') || '0') === focusedCellIndex.current);
                if (currentCellIndex === 0) {
                    return;
                }
                const previousCell = allFocusableCells[currentCellIndex - 1];
                if (!previousCell) {
                    return;
                }
                previousCell.focus();
                break;
            }
            case KeyboardKey.Right: {
                event.preventDefault();
                if (!allFocusableCells) {
                    return;
                }
                const currentCellIndex = allFocusableCells.findIndex((cell) => parseInt(cell.getAttribute('aria-colindex') || '0') === focusedCellIndex.current);
                if (currentCellIndex === allFocusableCells.length - 1) {
                    return;
                }
                const nextCell = allFocusableCells[currentCellIndex + 1];
                if (!nextCell) {
                    return;
                }
                nextCell.focus();
                break;
            }
            case KeyboardKey.Home:
                event.preventDefault();
                if (event.ctrlKey) {
                    focusCell(1, 1);
                }
                else {
                    if (!allFocusableCells) {
                        return;
                    }
                    const firstFocusableCell = allFocusableCells[0];
                    if (!firstFocusableCell) {
                        return;
                    }
                    const firstCellIndex = parseInt(firstFocusableCell.getAttribute('aria-colindex') || '0');
                    if (firstCellIndex > 0) {
                        focusCell(focusedRowIndex.current, firstCellIndex);
                    }
                }
                break;
            case KeyboardKey.End: {
                event.preventDefault();
                if (event.ctrlKey) {
                    focusCell(allRenderedRows.length, allRenderedColumnsLength || colCount);
                    return;
                }
                if (!allFocusableCells) {
                    return;
                }
                const lastFocusableCell = allFocusableCells[allFocusableCells.length - 1];
                if (!lastFocusableCell) {
                    return;
                }
                const lastCellIndex = parseInt(lastFocusableCell.getAttribute('aria-colindex') || '0');
                if (lastCellIndex > 0) {
                    focusCell(focusedRowIndex.current, lastCellIndex);
                }
                break;
            }
            case KeyboardKey.PageUp: {
                event.preventDefault();
                const previousRow = focusedRowIndex.current - 5;
                if (previousRow > 0) {
                    focusCell(previousRow, focusedCellIndex.current);
                }
                else {
                    focusCell(1, focusedCellIndex.current);
                }
                break;
            }
            case KeyboardKey.PageDown: {
                event.preventDefault();
                const nextRow = focusedRowIndex.current + 5;
                if (nextRow <= allRenderedRows.length) {
                    focusCell(nextRow, focusedCellIndex.current);
                }
                else {
                    focusCell(allRenderedRows.length, focusedCellIndex.current);
                }
                break;
            }
            case KeyboardKey.Enter: {
                const target = event.target;
                const closestColumnHeader = target.closest('[role="columnheader"]');
                if (closestColumnHeader && closestColumnHeader.getAttribute('data-can-sort')) {
                    event.preventDefault();
                    closestColumnHeader.click();
                    return;
                }
                const currentRowId = currentRow === null || currentRow === void 0 ? void 0 : currentRow.id;
                if (currentRowId) {
                    event.preventDefault();
                    handleActivateRow(currentRowId);
                }
                break;
            }
            case KeyboardKey.Space: {
                const target = event.target;
                const currentRowId = currentRow === null || currentRow === void 0 ? void 0 : currentRow.id;
                if (!currentRowId) {
                    return;
                }
                if (target.getAttribute('role') !== 'gridcell') {
                    return;
                }
                event.preventDefault();
                const isCmdOrCtrlPressed = application.keyboardService.isMac ? event.metaKey : event.ctrlKey;
                if (isCmdOrCtrlPressed && canSelectMultipleRows) {
                    multiSelectRow(currentRowId);
                }
                else if (event.shiftKey && canSelectMultipleRows) {
                    rangeSelectUpToRow(currentRowId);
                }
                else {
                    selectRow(currentRowId);
                }
                break;
            }
        }
    }, [
        application.keyboardService.isMac,
        canSelectMultipleRows,
        colCount,
        handleActivateRow,
        headers.length,
        multiSelectRow,
        rangeSelectUpToRow,
        rowCount,
        selectRow,
    ]);
    const handleRowClick = useCallback((event, rowId) => {
        if (!canSelectRows) {
            return;
        }
        const isCmdOrCtrlPressed = application.keyboardService.isMac ? event.metaKey : event.ctrlKey;
        if (isCmdOrCtrlPressed && canSelectMultipleRows) {
            multiSelectRow(rowId);
        }
        else if (event.shiftKey && canSelectMultipleRows) {
            rangeSelectUpToRow(rowId);
        }
        else {
            selectRow(rowId);
        }
    }, [
        application.keyboardService.isMac,
        canSelectMultipleRows,
        canSelectRows,
        multiSelectRow,
        rangeSelectUpToRow,
        selectRow,
    ]);
    return (_jsxs("div", { className: "block min-h-0 overflow-auto", onScroll: onScroll, children: [showSelectionActions && selectedRows.length >= 2 && (_jsxs("div", { className: "sticky top-0 z-[2] flex items-center justify-between border-b border-border bg-default px-3 py-2", children: [_jsxs("span", { className: "text-info-0 text-sm font-medium", children: [selectedRows.length, " selected"] }), selectedRows.length > 0 && selectionActions] })), _jsxs("div", { className: "relative grid w-full overflow-x-hidden px-3", role: "grid", "aria-colcount": colCount, "aria-rowcount": rowCount, "aria-multiselectable": canSelectMultipleRows, onFocus: onFocus, onBlur: onBlur, onKeyDown: onKeyDown, id: `table-${id}`, children: [_jsx("div", { role: "row", "aria-rowindex": 1, className: "contents", children: headers
                            .filter((header) => !header.hidden)
                            .map((header, index) => {
                            return (_jsx("div", { role: "columnheader", "aria-rowindex": 1, "aria-colindex": header.colIndex + 1, "aria-sort": header.isSorting ? (header.sortReversed ? 'descending' : 'ascending') : 'none', className: classNames('border-b border-border px-3 pb-2 pt-3 text-left text-sm font-medium text-passive-0', header.sortBy &&
                                    'cursor-pointer hover:bg-info-backdrop hover:underline focus:border-info focus:bg-info-backdrop'), style: {
                                    gridColumn: index + 1,
                                }, onClick: header.onSortChange, "data-can-sort": header.sortBy ? true : undefined, ...(header.sortBy && { tabIndex: index === 0 ? 0 : -1 }), children: _jsxs("div", { className: "flex items-center gap-1", children: [header.name, header.isSorting && (_jsx(Icon, { type: header.sortReversed ? 'arrow-up' : 'arrow-down', size: "custom", className: "h-4.5 w-4.5 text-passive-1" }))] }) }, index.toString()));
                        }) }), _jsx("div", { className: "contents whitespace-nowrap", children: rows.slice(0, rowsToDisplay).map((row, index) => (_jsx(TableRow, { row: row, index: index, canSelectRows: canSelectRows, handleRowClick: handleRowClick, handleRowContextMenu: handleRowContextMenu, handleActivateRow: handleActivateRow }, row.id))) })] })] }));
}
export default Table;
