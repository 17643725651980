"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorFromErrorResponse = exports.getErrorMessageFromErrorResponseBody = exports.getCaptchaHeader = exports.isErrorResponse = void 0;
function isErrorResponse(response) {
    var _a;
    return ((_a = response.data) === null || _a === void 0 ? void 0 : _a.error) != undefined || response.status >= 400;
}
exports.isErrorResponse = isErrorResponse;
function getCaptchaHeader(response) {
    var _a;
    const captchaHeader = (_a = response.headers) === null || _a === void 0 ? void 0 : _a.get('x-captcha-required');
    if (captchaHeader) {
        return captchaHeader;
    }
    return null;
}
exports.getCaptchaHeader = getCaptchaHeader;
function getErrorMessageFromErrorResponseBody(data, defaultMessage) {
    let errorMessage = defaultMessage || 'Unknown error';
    if (data &&
        typeof data === 'object' &&
        'error' in data &&
        data.error &&
        typeof data.error === 'object' &&
        'message' in data.error) {
        errorMessage = data.error.message;
    }
    return errorMessage;
}
exports.getErrorMessageFromErrorResponseBody = getErrorMessageFromErrorResponseBody;
function getErrorFromErrorResponse(response) {
    const embeddedError = response.data.error;
    if (embeddedError) {
        return embeddedError;
    }
    return {
        message: 'Unknown error',
    };
}
exports.getErrorFromErrorResponse = getErrorFromErrorResponse;
