import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PreferencesPane from '../../PreferencesComponents/PreferencesPane';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import { useEffect, useMemo, useState } from 'react';
import { LinkButton, Subtitle, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { getSectionItems } from './getSectionItems';
import { isDesktopApplication } from '@/Utils';
import { compareSemVersions } from '@standardnotes/snjs';
const WhatsNewSection = ({ items, sectionName }) => {
    if (!items) {
        return null;
    }
    return (_jsxs("div", { children: [_jsx(Subtitle, { children: sectionName }), _jsx("ul", { className: "list-inside", children: items.map((item, index) => (_jsx("li", { children: item }, index))) })] }));
};
const WhatsNew = ({ application }) => {
    const [changelog, setChangelog] = useState(null);
    const appVersion = application.version;
    const lastReadVersion = useMemo(() => application.changelogService.getLastReadVersion(), [application]);
    useEffect(() => {
        application.changelogService.getChangelog().then(setChangelog).catch(console.error);
    }, [application]);
    useEffect(() => {
        if (changelog) {
            application.changelogService.markAsRead();
        }
    }, [changelog, application]);
    if (!changelog) {
        return (_jsx("div", { className: "flex h-full w-full items-center text-center", children: _jsx("span", { className: "w-full font-bold", children: "Loading..." }) }));
    }
    const firstValidVersionIndex = changelog.versions.findIndex((version) => version.version && (getSectionItems(version, 'Bug Fixes') || getSectionItems(version, 'Features')));
    return (_jsx(PreferencesPane, { children: changelog.versions.map((version, index) => {
            const bugFixes = getSectionItems(version, 'Bug Fixes');
            const features = getSectionItems(version, 'Features');
            if (!bugFixes && !features) {
                return null;
            }
            if (!version.version) {
                return null;
            }
            const isUnreadVersion = lastReadVersion && compareSemVersions(version.version, lastReadVersion) > 0;
            const isLatest = index === firstValidVersionIndex;
            const isDesktopEnvironment = isDesktopApplication();
            const showDownloadLink = isDesktopEnvironment && isLatest;
            return (_jsx(PreferencesGroup, { children: _jsxs("div", { children: [_jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex items-start", children: [_jsx(Title, { className: "mb-3 flex", children: version.version }), version.version === appVersion && (_jsx("div", { className: "ml-2 select-none rounded bg-info px-2 py-1 text-[10px] font-bold text-info-contrast", children: "Your Version" })), isLatest && (_jsx("div", { className: "ml-2 select-none rounded bg-success px-2 py-1 text-[10px] font-bold text-success-contrast", children: "Latest Version" })), isUnreadVersion && (_jsx("div", { className: "ml-2 select-none rounded bg-success px-2 py-1 text-[10px] font-bold text-success-contrast", children: "New" }))] }), showDownloadLink && (_jsx(LinkButton, { label: 'Open Downloads', link: application.changelogService.getDesktopDownloadsUrl(version.version), className: "mb-3" }))] }), _jsx(WhatsNewSection, { sectionName: "Features", items: features }), features && bugFixes && _jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(WhatsNewSection, { sectionName: "Bug Fixes", items: bugFixes })] }, version.version) }, version.version));
        }) }));
};
export default WhatsNew;
