import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { ContentType, SystemViewId, isSystemView } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState, } from 'react';
import { classNames } from '@standardnotes/utils';
import { FOCUSABLE_BUT_NOT_TABBABLE } from '@/Constants/Constants';
import { useApplication } from '../ApplicationProvider';
const PADDING_BASE_PX = 14;
const PADDING_PER_LEVEL_PX = 21;
const getIconClass = (view, isSelected) => {
    const mapping = {
        [SystemViewId.StarredNotes]: 'text-warning',
    };
    return mapping[view.uuid] || (isSelected ? 'text-info' : 'text-neutral');
};
const SmartViewsListItem = ({ view, tagsState, setEditingSmartView }) => {
    const application = useApplication();
    const [title, setTitle] = useState(view.title || '');
    const inputRef = useRef(null);
    const level = 0;
    const isSelected = tagsState.selected === view;
    const isEditing = tagsState.editingTag === view;
    useEffect(() => {
        setTitle(view.title || '');
    }, [setTitle, view]);
    const selectCurrentTag = useCallback(async () => {
        await tagsState.setSelectedTag(view, 'views', {
            userTriggered: true,
        });
    }, [tagsState, view]);
    const onBlur = useCallback(() => {
        tagsState.save(view, title).catch(console.error);
        setTitle(view.title);
    }, [tagsState, view, title, setTitle]);
    const onInput = useCallback((e) => {
        const value = e.target.value;
        setTitle(value);
    }, [setTitle]);
    const onKeyUp = useCallback((e) => {
        var _a;
        if (e.code === 'Enter') {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            e.preventDefault();
        }
    }, [inputRef]);
    useEffect(() => {
        var _a;
        if (isEditing) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [inputRef, isEditing]);
    const onClickEdit = useCallback(() => {
        setEditingSmartView(view);
    }, [setEditingSmartView, view]);
    const onClickDelete = useCallback(() => {
        tagsState.remove(view, true).catch(console.error);
    }, [tagsState, view]);
    const isFaded = false;
    const iconClass = getIconClass(view, isSelected);
    const [conflictsCount, setConflictsCount] = useState(0);
    useEffect(() => {
        if (view.uuid !== SystemViewId.Conflicts) {
            return;
        }
        return application.items.streamItems(ContentType.TYPES.Note, () => {
            setConflictsCount(application.items.numberOfNotesWithConflicts());
        });
    }, [application, view]);
    if (view.uuid === SystemViewId.Conflicts && !conflictsCount) {
        return null;
    }
    return (_jsxs("button", { tabIndex: FOCUSABLE_BUT_NOT_TABBABLE, className: classNames('tag group px-3.5 py-0.5 focus-visible:!shadow-inner md:py-0', isSelected && 'selected', isFaded && 'opacity-50'), onClick: selectCurrentTag, onContextMenu: (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (isSystemView(view)) {
                return;
            }
            onClickEdit();
        }, style: {
            paddingLeft: `${level * PADDING_PER_LEVEL_PX + PADDING_BASE_PX}px`,
        }, children: [_jsxs("div", { className: "tag-info", children: [_jsx("div", { className: 'tag-icon mr-2', children: _jsx(Icon, { type: view.iconString, className: classNames(iconClass, 'group-hover:text-text') }) }), isEditing ? (_jsx("input", { className: 'title editing text-mobile-navigation-list-item lg:text-navigation-list-item', id: `react-tag-${view.uuid}`, onBlur: onBlur, onInput: onInput, value: title, onKeyUp: onKeyUp, spellCheck: false, ref: inputRef })) : (_jsx("div", { className: 'title overflow-hidden text-left text-mobile-navigation-list-item lg:text-navigation-list-item', id: `react-tag-${view.uuid}`, children: title })), _jsxs("div", { className: 'count text-base lg:text-sm', children: [view.uuid === SystemViewId.AllNotes && tagsState.allNotesCount, view.uuid === SystemViewId.Files && tagsState.allFilesCount, view.uuid === SystemViewId.Conflicts && conflictsCount] })] }), !isSystemView(view) && (_jsxs("div", { className: "meta", children: [view.conflictOf && _jsx("div", { className: "-mt-1 text-[0.625rem] font-bold text-danger", children: "Conflicted Copy" }), isSelected && (_jsxs("div", { className: "menu", children: [_jsx("a", { className: "item", onClick: onClickEdit, children: "Edit" }), _jsx("a", { className: "item", onClick: onClickDelete, children: "Delete" })] }))] }))] }));
};
export default observer(SmartViewsListItem);
