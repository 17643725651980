import { EditorLineWidth } from '@standardnotes/snjs';
export const EditorMaxWidths = {
    [EditorLineWidth.Narrow]: '512px',
    [EditorLineWidth.Wide]: '720px',
    [EditorLineWidth.Dynamic]: '80%',
    [EditorLineWidth.FullWidth]: '100%',
};
export const EditorMargins = {
    [EditorLineWidth.Narrow]: 'auto',
    [EditorLineWidth.Wide]: 'auto',
    [EditorLineWidth.Dynamic]: '10%',
    [EditorLineWidth.FullWidth]: '0',
};
