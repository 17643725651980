import { addToast, dismissToast } from '@standardnotes/toast';
export class ToastService {
    hideToast(toastId) {
        dismissToast(toastId);
    }
    showToast(type, message) {
        return addToast({
            type: type,
            message,
        });
    }
}
