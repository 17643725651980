import { destroyAllObjectProperties } from '@/Utils';
import { confirmDialog, PIN_NOTE_COMMAND, STAR_NOTE_COMMAND, } from '@standardnotes/ui-services';
import { StringEmptyTrash, Strings, StringUtils } from '@/Constants/Strings';
import { ContentType, PrefKey, ApplicationEvent, MutationType, PrefDefaults, LocalPrefKey, } from '@standardnotes/snjs';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { AbstractViewController } from '../Abstract/AbstractViewController';
import { CrossControllerEvent } from '../CrossControllerEvent';
export class NotesController extends AbstractViewController {
    constructor(itemListController, navigationController, itemControllerGroup, keyboardService, preferences, items, mutator, sync, protections, alerts, _isGlobalSpellcheckEnabled, _getItemTags, eventBus) {
        super(eventBus);
        this.itemListController = itemListController;
        this.navigationController = navigationController;
        this.itemControllerGroup = itemControllerGroup;
        this.keyboardService = keyboardService;
        this.preferences = preferences;
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.protections = protections;
        this.alerts = alerts;
        this._isGlobalSpellcheckEnabled = _isGlobalSpellcheckEnabled;
        this._getItemTags = _getItemTags;
        this.contextMenuOpen = false;
        this.contextMenuClickLocation = { x: 0, y: 0 };
        this.contextMenuMaxHeight = 'auto';
        this.showProtectedWarning = false;
        this.setContextMenuOpen = (open) => {
            this.contextMenuOpen = open;
        };
        makeObservable(this, {
            contextMenuOpen: observable,
            showProtectedWarning: observable,
            selectedNotes: computed,
            firstSelectedNote: computed,
            selectedNotesCount: computed,
            trashedNotesCount: computed,
            setContextMenuOpen: action,
            setContextMenuClickLocation: action,
            setShowProtectedWarning: action,
            unselectNotes: action,
        });
        this.shouldLinkToParentFolders = preferences.getValue(PrefKey.NoteAddToParentFolders, PrefDefaults[PrefKey.NoteAddToParentFolders]);
        eventBus.addEventHandler(this, ApplicationEvent.PreferencesChanged);
        eventBus.addEventHandler(this, CrossControllerEvent.UnselectAllNotes);
        this.disposers.push(this.keyboardService.addCommandHandler({
            command: PIN_NOTE_COMMAND,
            category: 'Current note',
            description: 'Pin current note',
            onKeyDown: () => {
                this.togglePinSelectedNotes();
            },
        }), this.keyboardService.addCommandHandler({
            command: STAR_NOTE_COMMAND,
            category: 'Current note',
            description: 'Star current note',
            onKeyDown: () => {
                this.toggleStarSelectedNotes();
            },
        }));
        this.disposers.push(this.itemControllerGroup.addActiveControllerChangeObserver(() => {
            const controllers = this.itemControllerGroup.itemControllers;
            const activeNoteUuids = controllers.map((controller) => controller.item.uuid);
            const selectedUuids = this.getSelectedNotesList().map((n) => n.uuid);
            for (const selectedId of selectedUuids) {
                if (!activeNoteUuids.includes(selectedId)) {
                    this.itemListController.deselectItem({ uuid: selectedId });
                }
            }
        }));
    }
    async handleEvent(event) {
        if (event.type === ApplicationEvent.PreferencesChanged) {
            this.shouldLinkToParentFolders = this.preferences.getValue(PrefKey.NoteAddToParentFolders, PrefDefaults[PrefKey.NoteAddToParentFolders]);
        }
        else if (event.type === CrossControllerEvent.UnselectAllNotes) {
            this.unselectNotes();
        }
    }
    deinit() {
        super.deinit();
        this.lastSelectedNote = undefined;
        this.itemListController = undefined;
        this.navigationController = undefined;
        destroyAllObjectProperties(this);
    }
    get selectedNotes() {
        return this.itemListController.getFilteredSelectedItems(ContentType.TYPES.Note);
    }
    get firstSelectedNote() {
        return Object.values(this.selectedNotes)[0];
    }
    get selectedNotesCount() {
        if (this.dealloced) {
            return 0;
        }
        return Object.keys(this.selectedNotes).length;
    }
    get trashedNotesCount() {
        return this.items.trashedItems.length;
    }
    setContextMenuClickLocation(location) {
        this.contextMenuClickLocation = location;
    }
    async changeSelectedNotes(mutate) {
        await this.mutator.changeItems(this.getSelectedNotesList(), mutate, MutationType.NoUpdateUserTimestamps);
        this.sync.sync().catch(console.error);
    }
    setHideSelectedNotePreviews(hide) {
        this.changeSelectedNotes((mutator) => {
            mutator.hidePreview = hide;
        }).catch(console.error);
    }
    setLockSelectedNotes(lock) {
        this.changeSelectedNotes((mutator) => {
            mutator.locked = lock;
        }).catch(console.error);
    }
    async setTrashSelectedNotes(trashed) {
        if (trashed) {
            const notesDeleted = await this.deleteNotes(false);
            if (notesDeleted) {
                runInAction(() => {
                    this.contextMenuOpen = false;
                });
            }
        }
        else {
            await this.changeSelectedNotes((mutator) => {
                mutator.trashed = trashed;
            });
            runInAction(() => {
                this.contextMenuOpen = false;
            });
        }
    }
    async deleteNotesPermanently() {
        await this.deleteNotes(true);
    }
    async deleteNotes(permanently) {
        if (this.getSelectedNotesList().some((note) => note.locked)) {
            const text = StringUtils.deleteLockedNotesAttempt(this.selectedNotesCount);
            this.alerts.alert(text).catch(console.error);
            return false;
        }
        const title = permanently ? Strings.deleteItemsPermanentlyTitle : Strings.trashItemsTitle;
        let noteTitle = undefined;
        if (this.selectedNotesCount === 1) {
            const selectedNote = this.getSelectedNotesList()[0];
            noteTitle = selectedNote.title.length ? `'${selectedNote.title}'` : 'this note';
        }
        const text = StringUtils.deleteNotes(permanently, this.selectedNotesCount, noteTitle);
        if (await confirmDialog({
            title,
            text,
            confirmButtonStyle: 'danger',
        })) {
            this.itemListController.selectNextItem();
            if (permanently) {
                await this.mutator.deleteItems(this.getSelectedNotesList());
                void this.sync.sync();
            }
            else {
                await this.changeSelectedNotes((mutator) => {
                    mutator.trashed = true;
                });
            }
            return true;
        }
        return false;
    }
    togglePinSelectedNotes() {
        const notes = this.selectedNotes;
        const pinned = notes.some((note) => note.pinned);
        if (!pinned) {
            this.setPinSelectedNotes(true);
        }
        else {
            this.setPinSelectedNotes(false);
        }
    }
    toggleStarSelectedNotes() {
        const notes = this.selectedNotes;
        const starred = notes.some((note) => note.starred);
        if (!starred) {
            this.setStarSelectedNotes(true);
        }
        else {
            this.setStarSelectedNotes(false);
        }
    }
    setPinSelectedNotes(pinned) {
        this.changeSelectedNotes((mutator) => {
            mutator.pinned = pinned;
        }).catch(console.error);
    }
    setStarSelectedNotes(starred) {
        this.changeSelectedNotes((mutator) => {
            mutator.starred = starred;
        }).catch(console.error);
    }
    async setArchiveSelectedNotes(archived) {
        if (this.getSelectedNotesList().some((note) => note.locked)) {
            this.alerts.alert(StringUtils.archiveLockedNotesAttempt(archived, this.selectedNotesCount)).catch(console.error);
            return;
        }
        await this.changeSelectedNotes((mutator) => {
            mutator.archived = archived;
        });
        runInAction(() => {
            this.itemListController.deselectAll();
            this.contextMenuOpen = false;
        });
    }
    async toggleArchiveSelectedNotes() {
        const notes = this.selectedNotes;
        const archived = notes.some((note) => note.archived);
        if (!archived) {
            await this.setArchiveSelectedNotes(true);
        }
        else {
            await this.setArchiveSelectedNotes(false);
        }
    }
    async setProtectSelectedNotes(protect) {
        const selectedNotes = this.getSelectedNotesList();
        if (protect) {
            await this.protections.protectNotes(selectedNotes);
            this.setShowProtectedWarning(true);
        }
        else {
            await this.protections.unprotectNotes(selectedNotes);
            this.setShowProtectedWarning(false);
        }
        void this.sync.sync();
    }
    unselectNotes() {
        this.itemListController.deselectAll();
    }
    getSpellcheckStateForNote(note) {
        return note.spellcheck != undefined ? note.spellcheck : this._isGlobalSpellcheckEnabled.execute().getValue();
    }
    async toggleGlobalSpellcheckForNote(note) {
        await this.mutator.changeItem(note, (mutator) => {
            mutator.toggleSpellcheck();
        }, MutationType.NoUpdateUserTimestamps);
        this.sync.sync().catch(console.error);
    }
    getEditorWidthForNote(note) {
        var _a;
        return ((_a = note.editorWidth) !== null && _a !== void 0 ? _a : this.preferences.getLocalValue(LocalPrefKey.EditorLineWidth, PrefDefaults[LocalPrefKey.EditorLineWidth]));
    }
    async setNoteEditorWidth(note, editorWidth) {
        await this.mutator.changeItem(note, (mutator) => {
            mutator.editorWidth = editorWidth;
        }, MutationType.NoUpdateUserTimestamps);
        this.sync.sync().catch(console.error);
    }
    async addTagToSelectedNotes(tag) {
        const selectedNotes = this.getSelectedNotesList();
        await Promise.all(selectedNotes.map(async (note) => {
            await this.mutator.addTagToNote(note, tag, this.shouldLinkToParentFolders);
        }));
        this.sync.sync().catch(console.error);
    }
    async removeTagFromSelectedNotes(tag) {
        const selectedNotes = this.getSelectedNotesList();
        await this.mutator.changeItem(tag, (mutator) => {
            for (const note of selectedNotes) {
                mutator.removeItemAsRelationship(note);
            }
        });
        this.sync.sync().catch(console.error);
    }
    isTagInSelectedNotes(tag) {
        const selectedNotes = this.getSelectedNotesList();
        return selectedNotes.every((note) => this._getItemTags
            .execute(note)
            .getValue()
            .find((noteTag) => noteTag.uuid === tag.uuid));
    }
    setShowProtectedWarning(show) {
        this.showProtectedWarning = show;
    }
    async emptyTrash() {
        if (await confirmDialog({
            text: StringEmptyTrash(this.trashedNotesCount),
            confirmButtonStyle: 'danger',
        })) {
            await this.mutator.emptyTrash();
            this.sync.sync().catch(console.error);
        }
    }
    getSelectedNotesList() {
        return Object.values(this.selectedNotes);
    }
}
