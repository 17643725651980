import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import Menu from '../Menu/Menu';
import Popover from '../Popover/Popover';
import FileMenuOptions from './FileMenuOptions';
const FileContextMenu = observer(({ filesController, itemListController }) => {
    const { showFileContextMenu, setShowFileContextMenu, fileContextMenuLocation } = filesController;
    const { selectedFiles } = itemListController;
    return (_jsx(Popover, { title: "File options", open: showFileContextMenu, anchorPoint: fileContextMenuLocation, togglePopover: () => setShowFileContextMenu(!showFileContextMenu), align: "start", className: "md:pb-2", children: _jsx(Menu, { a11yLabel: "File context menu", children: _jsx(FileMenuOptions, { selectedFiles: selectedFiles, closeMenu: () => setShowFileContextMenu(false), shouldShowRenameOption: false, shouldShowAttachOption: false }) }) }));
});
FileContextMenu.displayName = 'FileContextMenu';
const FileContextMenuWrapper = ({ filesController, itemListController }) => {
    const { showFileContextMenu } = filesController;
    const { selectedFiles } = itemListController;
    const selectedFile = selectedFiles[0];
    if (!showFileContextMenu || !selectedFile) {
        return null;
    }
    return _jsx(FileContextMenu, { filesController: filesController, itemListController: itemListController });
};
export default observer(FileContextMenuWrapper);
