import { saveFile } from '../utils';
export class ClassicFileSaver {
    constructor() {
        this.loggingEnabled = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log(...args) {
        if (!this.loggingEnabled) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log(args);
    }
    static maximumFileSize() {
        return 50 * 1000000;
    }
    saveFile(name, bytes) {
        this.log('Saving file to disk...');
        saveFile(name, bytes);
        this.log('Closing write stream');
    }
}
