import { MutationType } from '@standardnotes/models';
import { InfoStrings, SyncMode, } from '@standardnotes/snjs';
import { Deferred } from '@standardnotes/utils';
import { EditorSaveTimeoutDebounce } from '../Components/NoteView/Controller/EditorSaveTimeoutDebounce';
import { LargeNoteThreshold } from '@/Constants/Constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
const NotePreviewCharLimit = 160;
const MinimumStatusChangeDuration = 400;
export class NoteSyncController {
    constructor(item, items, mutator, sessions, sync, alerts, _isNativeMobileWeb) {
        this.item = item;
        this.items = items;
        this.mutator = mutator;
        this.sessions = sessions;
        this.sync = sync;
        this.alerts = alerts;
        this._isNativeMobileWeb = _isNativeMobileWeb;
        this.savingLocallyPromise = null;
        this.status = undefined;
        makeObservable(this, {
            status: observable,
            setStatus: action,
        });
    }
    setStatus(status, wait = true) {
        if (this.statusChangeTimeout) {
            clearTimeout(this.statusChangeTimeout);
        }
        if (wait) {
            this.statusChangeTimeout = setTimeout(() => {
                runInAction(() => {
                    this.status = status;
                });
            }, MinimumStatusChangeDuration);
        }
        else {
            this.status = status;
        }
    }
    showSavingStatus() {
        this.setStatus({
            type: 'saving',
            message: 'Saving…',
        }, false);
    }
    showAllChangesSavedStatus() {
        this.setStatus({
            type: 'saved',
            message: 'All changes saved' + (this.sessions.isSignedOut() ? ' offline' : ''),
        });
    }
    showWaitingToSyncLargeNoteStatus() {
        this.setStatus({
            type: 'waiting',
            message: 'Note is too large',
            description: 'It will be synced less often. Changes will be saved offline normally.',
        }, false);
    }
    showErrorStatus(error) {
        if (!error) {
            error = {
                type: 'error',
                message: 'Sync Unreachable',
                description: 'Changes saved offline',
            };
        }
        this.setStatus(error);
    }
    setItem(item) {
        this.item = item;
    }
    deinit() {
        if (this.syncTimeout) {
            clearTimeout(this.syncTimeout);
        }
        if (this.largeNoteSyncTimeout) {
            clearTimeout(this.largeNoteSyncTimeout);
        }
        if (this.statusChangeTimeout) {
            clearTimeout(this.statusChangeTimeout);
        }
        if (this.savingLocallyPromise) {
            this.savingLocallyPromise.reject();
        }
        this.savingLocallyPromise = null;
        this.largeNoteSyncTimeout = undefined;
        this.syncTimeout = undefined;
        this.status = undefined;
        this.statusChangeTimeout = undefined;
        this.item = undefined;
    }
    isLargeNote(text) {
        const textByteSize = new Blob([text]).size;
        return textByteSize > LargeNoteThreshold;
    }
    async saveAndAwaitLocalPropagation(params) {
        this.savingLocallyPromise = Deferred();
        if (this.syncTimeout) {
            clearTimeout(this.syncTimeout);
        }
        const noDebounce = params.bypassDebouncer || this.sessions.isSignedOut();
        const syncDebounceMs = noDebounce
            ? EditorSaveTimeoutDebounce.ImmediateChange
            : this._isNativeMobileWeb.execute().getValue()
                ? EditorSaveTimeoutDebounce.NativeMobileWeb
                : EditorSaveTimeoutDebounce.Desktop;
        return new Promise((resolve) => {
            const isLargeNote = this.isLargeNote(params.text ? params.text : this.item.text);
            if (isLargeNote) {
                this.showWaitingToSyncLargeNoteStatus();
                this.queueLargeNoteSyncIfNeeded();
            }
            this.syncTimeout = setTimeout(() => {
                void this.undebouncedMutateAndSync({
                    ...params,
                    localOnly: isLargeNote,
                    onLocalPropagationComplete: () => {
                        if (this.savingLocallyPromise) {
                            this.savingLocallyPromise.resolve();
                        }
                        resolve();
                    },
                });
            }, syncDebounceMs);
        });
    }
    queueLargeNoteSyncIfNeeded() {
        const isAlreadyAQueuedLargeNoteSync = this.largeNoteSyncTimeout !== undefined;
        if (!isAlreadyAQueuedLargeNoteSync) {
            const isSignedIn = this.sessions.isSignedIn();
            const timeout = isSignedIn ? EditorSaveTimeoutDebounce.LargeNote : EditorSaveTimeoutDebounce.ImmediateChange;
            this.largeNoteSyncTimeout = setTimeout(() => {
                this.largeNoteSyncTimeout = undefined;
                void this.performSyncOfLargeItem();
            }, timeout);
        }
    }
    async performSyncOfLargeItem() {
        const item = this.items.findItem(this.item.uuid);
        if (!item || !item.dirty) {
            return;
        }
        void this.sync.sync();
    }
    async undebouncedMutateAndSync(params) {
        var _a;
        if (!this.items.findItem(this.item.uuid)) {
            void this.alerts.alert(InfoStrings.InvalidNote);
            return;
        }
        await this.mutator.changeItem(this.item, (mutator) => {
            const noteMutator = mutator;
            if (params.customMutate) {
                params.customMutate(noteMutator);
            }
            if (params.title != undefined) {
                noteMutator.title = params.title;
            }
            if (params.text != undefined) {
                noteMutator.text = params.text;
            }
            if (params.previews) {
                noteMutator.preview_plain = params.previews.previewPlain;
                noteMutator.preview_html = params.previews.previewHtml;
            }
            else if (!params.dontGeneratePreviews && params.text != undefined) {
                const noteText = params.text || '';
                const truncate = noteText.length > NotePreviewCharLimit;
                const substring = noteText.substring(0, NotePreviewCharLimit);
                const previewPlain = substring + (truncate ? '...' : '');
                noteMutator.preview_plain = previewPlain;
                noteMutator.preview_html = undefined;
            }
        }, params.isUserModified ? MutationType.UpdateUserTimestamps : MutationType.NoUpdateUserTimestamps);
        void this.sync.sync({ mode: params.localOnly ? SyncMode.LocalOnly : undefined });
        this.queueLargeNoteSyncIfNeeded();
        (_a = params.onLocalPropagationComplete) === null || _a === void 0 ? void 0 : _a.call(params);
    }
    syncOnlyIfLargeNote() {
        const isLargeNote = this.isLargeNote(this.item.text);
        if (isLargeNote) {
            void this.performSyncOfLargeItem();
        }
    }
}
