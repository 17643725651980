"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaRemoteRejected = void 0;
const PayloadSource_1 = require("../../Abstract/Payload/Types/PayloadSource");
const Payload_1 = require("../../Abstract/Payload");
const SyncResolvedPayload_1 = require("./Utilities/SyncResolvedPayload");
const responses_1 = require("@standardnotes/responses");
const PayloadsByDuplicating_1 = require("../../Utilities/Payload/PayloadsByDuplicating");
const domain_core_1 = require("@standardnotes/domain-core");
class DeltaRemoteRejected {
    constructor(baseCollection, conflicts) {
        this.baseCollection = baseCollection;
        this.conflicts = conflicts;
    }
    result() {
        const results = [];
        const vaultErrors = [
            responses_1.ConflictType.SharedVaultInsufficientPermissionsError,
            responses_1.ConflictType.SharedVaultNotMemberError,
            responses_1.ConflictType.SharedVaultInvalidState,
            responses_1.ConflictType.SharedVaultSnjsVersionError,
        ];
        for (const conflict of this.conflicts) {
            if (vaultErrors.includes(conflict.type)) {
                results.push(...this.handleVaultError(conflict));
            }
            else if ((0, responses_1.conflictParamsHasServerItemAndUnsavedItem)(conflict)) {
                results.push(...this.getResultForConflictWithServerItemAndUnsavedItem(conflict));
            }
            else if ((0, responses_1.conflictParamsHasOnlyServerItem)(conflict)) {
                results.push(...this.getResultForConflictWithOnlyServerItem(conflict));
            }
            else if ((0, responses_1.conflictParamsHasOnlyUnsavedItem)(conflict)) {
                results.push(...this.getResultForConflictWithOnlyUnsavedItem(conflict));
            }
        }
        return {
            emits: results,
            source: Payload_1.PayloadEmitSource.RemoteSaved,
        };
    }
    handleVaultError(conflict) {
        const base = this.baseCollection.find(conflict.unsaved_item.uuid);
        if (!base) {
            return [];
        }
        if (conflict.type === responses_1.ConflictType.SharedVaultNotMemberError) {
            return this.resultByDuplicatingBasePayloadAsNonVaultedAndRemovingBaseItemLocally(base);
        }
        if (base.content_type === domain_core_1.ContentType.TYPES.KeySystemItemsKey) {
            return this.discardChangesOfBasePayload(base);
        }
        if (conflict.server_item) {
            return this.resultByDuplicatingBasePayloadAsNonVaultedAndTakingServerPayloadAsCanonical(base, conflict.server_item);
        }
        else {
            return this.resultByDuplicatingBasePayloadAsNonVaultedAndDiscardingChangesOfOriginal(base);
        }
    }
    discardChangesOfBasePayload(base) {
        const undirtiedPayload = base.copyAsSyncResolved({
            dirty: false,
            lastSyncEnd: new Date(),
        }, PayloadSource_1.PayloadSource.RemoteSaved);
        return [undirtiedPayload];
    }
    getResultForConflictWithOnlyUnsavedItem(conflict) {
        const base = this.baseCollection.find(conflict.unsaved_item.uuid);
        if (!base) {
            return [];
        }
        const result = base.copyAsSyncResolved({
            dirty: false,
            lastSyncEnd: new Date(),
        }, PayloadSource_1.PayloadSource.RemoteSaved);
        return [result];
    }
    getResultForConflictWithOnlyServerItem(conflict) {
        const base = this.baseCollection.find(conflict.server_item.uuid);
        if (!base) {
            return [];
        }
        return this.resultByDuplicatingBasePayloadIntoNewUuidAndTakingServerPayloadAsCanonical(base, conflict.server_item);
    }
    getResultForConflictWithServerItemAndUnsavedItem(conflict) {
        const base = this.baseCollection.find(conflict.server_item.uuid);
        if (!base) {
            return [];
        }
        return this.resultByDuplicatingBasePayloadIntoNewUuidAndTakingServerPayloadAsCanonical(base, conflict.server_item);
    }
    resultByDuplicatingBasePayloadIntoNewUuidAndTakingServerPayloadAsCanonical(basePayload, serverPayload) {
        const duplicateBasePayloadIntoNewUuid = (0, PayloadsByDuplicating_1.PayloadsByDuplicating)({
            payload: basePayload,
            baseCollection: this.baseCollection,
            isConflict: true,
            source: serverPayload.source,
        });
        const takeServerPayloadAsCanonical = serverPayload.copyAsSyncResolved({
            lastSyncBegan: basePayload.lastSyncBegan,
            dirty: false,
            lastSyncEnd: new Date(),
        }, serverPayload.source);
        return duplicateBasePayloadIntoNewUuid.concat([takeServerPayloadAsCanonical]);
    }
    resultByDuplicatingBasePayloadAsNonVaultedAndTakingServerPayloadAsCanonical(basePayload, serverPayload) {
        const duplicateBasePayloadIntoNewUuid = (0, PayloadsByDuplicating_1.PayloadsByDuplicating)({
            payload: basePayload.copy({
                key_system_identifier: undefined,
                shared_vault_uuid: undefined,
            }),
            baseCollection: this.baseCollection,
            isConflict: true,
            source: serverPayload.source,
        });
        const takeServerPayloadAsCanonical = serverPayload.copyAsSyncResolved({
            lastSyncBegan: basePayload.lastSyncBegan,
            dirty: false,
            lastSyncEnd: new Date(),
        }, serverPayload.source);
        return duplicateBasePayloadIntoNewUuid.concat([takeServerPayloadAsCanonical]);
    }
    resultByDuplicatingBasePayloadAsNonVaultedAndDiscardingChangesOfOriginal(basePayload) {
        const duplicateBasePayloadWithoutVault = (0, PayloadsByDuplicating_1.PayloadsByDuplicating)({
            payload: basePayload.copy({
                key_system_identifier: undefined,
                shared_vault_uuid: undefined,
            }),
            baseCollection: this.baseCollection,
            isConflict: true,
            source: basePayload.source,
        });
        return [...duplicateBasePayloadWithoutVault, ...this.discardChangesOfBasePayload(basePayload)];
    }
    resultByDuplicatingBasePayloadAsNonVaultedAndRemovingBaseItemLocally(basePayload) {
        const duplicateBasePayloadWithoutVault = (0, PayloadsByDuplicating_1.PayloadsByDuplicating)({
            payload: basePayload.copy({
                key_system_identifier: undefined,
                shared_vault_uuid: undefined,
            }),
            baseCollection: this.baseCollection,
            isConflict: true,
            source: basePayload.source,
        });
        const locallyDeletedBasePayload = new Payload_1.DeletedPayload(Object.assign(Object.assign(Object.assign({}, basePayload), { content: undefined, deleted: true, key_system_identifier: undefined, shared_vault_uuid: undefined }), (0, SyncResolvedPayload_1.BuildSyncResolvedParams)({
            dirty: false,
            lastSyncEnd: new Date(),
        })), PayloadSource_1.PayloadSource.RemoteSaved);
        return [...duplicateBasePayloadWithoutVault, locallyDeletedBasePayload];
    }
}
exports.DeltaRemoteRejected = DeltaRemoteRejected;
