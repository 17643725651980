/* istanbul ignore file */
import { removeFromArray } from '@standardnotes/utils';
import { InternalEventPublishStrategy } from '../Internal/InternalEventPublishStrategy';
export class AbstractService {
    constructor(internalEventBus) {
        this.internalEventBus = internalEventBus;
        this.eventObservers = [];
        this.loggingEnabled = false;
        this.criticalPromises = [];
        this.eventDisposers = [];
    }
    addEventObserver(observer) {
        this.eventObservers.push(observer);
        const thislessEventObservers = this.eventObservers;
        return () => {
            removeFromArray(thislessEventObservers, observer);
        };
    }
    async notifyEvent(eventName, data) {
        var _a;
        for (const observer of this.eventObservers) {
            await observer(eventName, data);
        }
        (_a = this.internalEventBus) === null || _a === void 0 ? void 0 : _a.publish({
            type: eventName,
            payload: data,
        });
    }
    async notifyEventSync(eventName, data) {
        var _a;
        for (const observer of this.eventObservers) {
            await observer(eventName, data);
        }
        await ((_a = this.internalEventBus) === null || _a === void 0 ? void 0 : _a.publishSync({
            type: eventName,
            payload: data,
        }, InternalEventPublishStrategy.SEQUENCE));
    }
    getDiagnostics() {
        return Promise.resolve(undefined);
    }
    /**
     * Called by application to allow services to momentarily block deinit until
     * sensitive operations complete.
     */
    async blockDeinit() {
        await Promise.all(this.criticalPromises);
    }
    /**
     * Called by application before restart.
     * Subclasses should deregister any observers/timers
     */
    deinit() {
        this.eventObservers.length = 0;
        this.internalEventBus = undefined;
        this.criticalPromises = undefined;
        for (const disposer of this.eventDisposers) {
            disposer();
        }
        this.eventDisposers = [];
    }
    /**
     * A critical function is one that should block signing out or destroying application
     * session until the crticial function has completed. For example, persisting keys to
     * disk is a critical operation, and should be wrapped in this function call. The
     * parent application instance will await all criticial functions via the `blockDeinit`
     * function before signing out and deiniting.
     */
    async executeCriticalFunction(func) {
        const promise = func();
        this.criticalPromises.push(promise);
        return promise;
    }
    getServiceName() {
        return this.constructor.name;
    }
    isApplicationService() {
        return true;
    }
}
