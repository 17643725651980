"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemsKeyDelta = void 0;
const Payload_1 = require("../../Abstract/Payload");
const ApplyDirtyState_1 = require("./Utilities/ApplyDirtyState");
class ItemsKeyDelta {
    constructor(baseCollection, applyPayloads) {
        this.baseCollection = baseCollection;
        this.applyPayloads = applyPayloads;
    }
    result() {
        const emits = [];
        const ignored = [];
        for (const apply of this.applyPayloads) {
            const base = this.baseCollection.find(apply.uuid);
            if (!base) {
                emits.push((0, ApplyDirtyState_1.payloadByFinalizingSyncState)(apply, this.baseCollection));
                continue;
            }
            if ((0, Payload_1.isEncryptedPayload)(apply) && (0, Payload_1.isDecryptedPayload)(base)) {
                const keepBaseWithApplyTimestamps = base.copyAsSyncResolved({
                    updated_at_timestamp: apply.updated_at_timestamp,
                    updated_at: apply.updated_at,
                    dirty: false,
                    lastSyncEnd: new Date(),
                });
                emits.push(keepBaseWithApplyTimestamps);
                ignored.push(apply);
            }
            else {
                emits.push((0, ApplyDirtyState_1.payloadByFinalizingSyncState)(apply, this.baseCollection));
            }
        }
        return {
            emits: emits,
            ignored,
        };
    }
}
exports.ItemsKeyDelta = ItemsKeyDelta;
