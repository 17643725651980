import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Fragment, useMemo, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import { useListKeyboardNavigation } from '@/Hooks/useListKeyboardNavigation';
import HistoryListItem from './HistoryListItem';
import { previewHistoryEntryTitle } from './utils';
import Spinner from '@/Components/Spinner/Spinner';
import { PremiumFeatureIconClass, PremiumFeatureIconName } from '../Icon/PremiumFeatureIcon';
const RemoteHistoryList = ({ features, noteHistoryController, onSelectRevision, }) => {
    const { remoteHistory, isFetchingRemoteHistory, selectRemoteRevision, selectedEntry } = noteHistoryController;
    const [listElement, setListElement] = useState(null);
    useListKeyboardNavigation(listElement);
    const remoteHistoryLength = useMemo(() => remoteHistory === null || remoteHistory === void 0 ? void 0 : remoteHistory.map((group) => group.entries).flat().length, [remoteHistory]);
    return (_jsxs("div", { className: `flex h-full w-full flex-col focus:shadow-none ${isFetchingRemoteHistory || !remoteHistoryLength ? 'items-center justify-center' : ''}`, ref: setListElement, children: [isFetchingRemoteHistory && _jsx(Spinner, { className: "h-5 w-5" }), remoteHistory === null || remoteHistory === void 0 ? void 0 : remoteHistory.map((group) => {
                if (group.entries && group.entries.length) {
                    return (_jsxs(Fragment, { children: [_jsx("div", { className: "mb-1 mt-2.5 select-none px-3 text-sm font-semibold uppercase text-passive-0", children: group.title }), group.entries.map((entry) => (_jsx(HistoryListItem, { isSelected: (selectedEntry === null || selectedEntry === void 0 ? void 0 : selectedEntry.uuid) === entry.uuid, onClick: () => {
                                    void selectRemoteRevision(entry);
                                    onSelectRevision();
                                }, children: _jsxs("div", { className: "flex flex-grow items-center justify-between", children: [_jsx("div", { children: previewHistoryEntryTitle(entry) }), !features.hasMinimumRole(entry.required_role) && (_jsx(Icon, { type: PremiumFeatureIconName, className: PremiumFeatureIconClass }))] }) }, entry.uuid)))] }, group.title));
                }
                else {
                    return null;
                }
            }), !remoteHistoryLength && !isFetchingRemoteHistory && (_jsx("div", { className: "select-none text-sm text-passive-0", children: "No remote history found" }))] }));
};
export default observer(RemoteHistoryList);
