"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isClientDisplayableError = exports.ClientDisplayableError = void 0;
const Http_1 = require("../Http");
class ClientDisplayableError {
    constructor(text, title, tag) {
        this.text = text;
        this.title = title;
        this.tag = tag;
        console.error('Client Displayable Error:', text, title || '', tag || '');
    }
    static FromError(error) {
        return new ClientDisplayableError(error.message, undefined, error.tag);
    }
    static FromString(text) {
        return new ClientDisplayableError(text);
    }
    static FromNetworkError(error) {
        return new ClientDisplayableError((0, Http_1.getErrorFromErrorResponse)(error).message);
    }
}
exports.ClientDisplayableError = ClientDisplayableError;
function isClientDisplayableError(error) {
    return error instanceof ClientDisplayableError;
}
exports.isClientDisplayableError = isClientDisplayableError;
