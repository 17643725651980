import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import RootTagDropZone from './RootTagDropZone';
import { TagsListItem } from './TagsListItem';
import { useApplication } from '../ApplicationProvider';
import { useListKeyboardNavigation } from '@/Hooks/useListKeyboardNavigation';
const TagsList = ({ type }) => {
    const application = useApplication();
    const allTags = type === 'all' ? application.navigationController.allLocalRootTags : application.navigationController.starredTags;
    const openTagContextMenu = useCallback((x, y) => {
        application.navigationController.setContextMenuClickLocation({ x, y });
        application.navigationController.setContextMenuOpen(true);
    }, [application]);
    const onContextMenu = useCallback((tag, section, posX, posY) => {
        application.navigationController.setContextMenuTag(tag, section);
        openTagContextMenu(posX, posY);
    }, [application, openTagContextMenu]);
    const [container, setContainer] = useState(null);
    useListKeyboardNavigation(container, {
        initialFocus: 0,
        shouldAutoFocus: false,
        shouldWrapAround: false,
        resetLastFocusedOnBlur: true,
    });
    if (allTags.length === 0) {
        return (_jsx("div", { className: "px-4 text-base opacity-50 lg:text-sm", children: application.navigationController.isSearching
                ? 'No tags found. Try a different search.'
                : 'No tags or folders. Create one using the add button above.' }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: setContainer, children: allTags.map((tag) => {
                    return (_jsx(TagsListItem, { level: 0, tag: tag, type: type, navigationController: application.navigationController, features: application.featuresController, linkingController: application.linkingController, onContextMenu: onContextMenu }, tag.uuid));
                }) }), type === 'all' && _jsx(RootTagDropZone, { tagsState: application.navigationController })] }));
};
export default observer(TagsList);
