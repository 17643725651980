import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import Authentication from './Authentication';
import Credentials from './Credentials';
import Sync from './Sync';
import Subscription from './Subscription/Subscription';
import SignOutWrapper from './SignOutView';
import FilesSection from './Files';
import PreferencesPane from '../../PreferencesComponents/PreferencesPane';
import SubscriptionSharing from './SubscriptionSharing/SubscriptionSharing';
import Email from './Email/Email';
import DeleteAccount from '@/Components/Preferences/Panes/Account/DeleteAccount';
const AccountPreferences = ({ application }) => {
    const isUsingThirdPartyServer = !application.sessions.isSignedIntoFirstPartyServer();
    return (_jsxs(PreferencesPane, { children: [!application.hasAccount() ? (_jsx(Authentication, { application: application })) : (_jsxs(_Fragment, { children: [_jsx(Credentials, { application: application }), _jsx(Sync, { application: application })] })), _jsx(Subscription, {}), _jsx(SubscriptionSharing, { application: application }), application.hasAccount() && application.featuresController.entitledToFiles && (_jsx(FilesSection, { application: application })), application.hasAccount() && !isUsingThirdPartyServer && _jsx(Email, { application: application }), _jsx(SignOutWrapper, { application: application }), _jsx(DeleteAccount, { application: application })] }));
};
export default observer(AccountPreferences);
