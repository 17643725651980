import { $setBlocksType } from '@lexical/selection';
import { $getSelection, $isRangeSelection } from 'lexical';
import { $createQuoteNode } from '@lexical/rich-text';
import { BlockPickerOption } from '../BlockPickerPlugin/BlockPickerOption';
export const QuoteBlock = {
    name: 'Quote',
    iconName: 'quote',
    keywords: ['block quote'],
    onSelect: (editor) => editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createQuoteNode());
        }
    }),
};
export function GetQuoteBlockOption(editor) {
    return new BlockPickerOption(QuoteBlock.name, {
        iconName: QuoteBlock.iconName,
        keywords: QuoteBlock.keywords,
        onSelect: () => QuoteBlock.onSelect(editor),
    });
}
