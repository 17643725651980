"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultListingMutator = void 0;
const Item_1 = require("../../Abstract/Item");
class VaultListingMutator extends Item_1.DecryptedItemMutator {
    set name(name) {
        this.mutableContent.name = name;
    }
    set description(description) {
        this.mutableContent.description = description;
    }
    set iconString(iconString) {
        this.mutableContent.iconString = iconString;
    }
    set sharing(sharing) {
        this.mutableContent.sharing = sharing;
    }
    set rootKeyParams(rootKeyParams) {
        this.mutableContent.rootKeyParams = rootKeyParams;
    }
    set keyStorageMode(keyStorageMode) {
        this.mutableContent.keyStorageMode = keyStorageMode;
    }
}
exports.VaultListingMutator = VaultListingMutator;
