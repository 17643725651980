import { UuidGenerator } from '@standardnotes/snjs';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
export function useTable({ data, columns, sortBy, sortReversed, onSortChange, getRowId, enableRowSelection, enableMultipleRowSelection, selectedRowIds, onRowSelectionChange, onRowActivate, onRowContextMenu, rowActions, selectionActions, showSelectionActions, }) {
    const [selectedRows, setSelectedRows] = useState(selectedRowIds || []);
    const id = useRef(UuidGenerator.GenerateUuid());
    useEffect(() => {
        if (selectedRowIds) {
            setSelectedRows(selectedRowIds);
        }
    }, [selectedRowIds]);
    useEffect(() => {
        if (onRowSelectionChange) {
            onRowSelectionChange(selectedRows);
        }
    }, [selectedRows, onRowSelectionChange]);
    const headers = useMemo(() => columns.map((column, index) => {
        return {
            name: column.name,
            isSorting: sortBy && sortBy === column.sortBy,
            sortBy: column.sortBy,
            sortReversed: sortReversed,
            onSortChange: () => {
                if (!onSortChange || !column.sortBy) {
                    return;
                }
                onSortChange(column.sortBy, sortBy === column.sortBy ? !sortReversed : false);
            },
            hidden: column.hidden || false,
            colIndex: index,
        };
    }), [columns, onSortChange, sortBy, sortReversed]);
    const rows = useMemo(() => data.map((rowData, index) => {
        const cells = columns.map((column, index) => {
            return {
                render: column.cell(rowData),
                hidden: column.hidden || false,
                colIndex: index,
            };
        });
        const id = getRowId ? getRowId(rowData) : index.toString();
        const row = {
            id,
            isSelected: enableRowSelection ? selectedRows.includes(id) : false,
            cells,
            rowData,
            rowActions: rowActions ? rowActions(rowData) : undefined,
        };
        return row;
    }), [columns, data, enableRowSelection, getRowId, rowActions, selectedRows]);
    const selectRow = useCallback((id) => {
        if (!enableRowSelection) {
            return;
        }
        setSelectedRows([id]);
    }, [enableRowSelection]);
    const multiSelectRow = useCallback((id) => {
        if (!enableRowSelection || !enableMultipleRowSelection) {
            return;
        }
        setSelectedRows((prev) => (prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]));
    }, [enableMultipleRowSelection, enableRowSelection]);
    const rangeSelectUpToRow = useCallback((id) => {
        if (!enableRowSelection || !enableMultipleRowSelection) {
            return;
        }
        const lastSelectedIndex = rows.findIndex((row) => row.id === selectedRows[selectedRows.length - 1]);
        const currentIndex = rows.findIndex((row) => row.id === id);
        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);
        const newSelectedRows = rows.slice(start, end + 1).map((row) => row.id);
        setSelectedRows(newSelectedRows);
    }, [enableMultipleRowSelection, enableRowSelection, rows, selectedRows]);
    const handleActivateRow = useCallback((id) => {
        var _a;
        if (!onRowActivate) {
            return;
        }
        const rowData = (_a = rows.find((row) => row.id === id)) === null || _a === void 0 ? void 0 : _a.rowData;
        if (rowData) {
            onRowActivate(rowData);
        }
    }, [onRowActivate, rows]);
    const handleRowContextMenu = useCallback((id) => {
        const handler = (event) => {
            var _a;
            if (!onRowContextMenu) {
                return;
            }
            event.preventDefault();
            const rowData = (_a = rows.find((row) => row.id === id)) === null || _a === void 0 ? void 0 : _a.rowData;
            if (rowData) {
                setSelectedRows([id]);
                onRowContextMenu(event.clientX, event.clientY, rowData);
            }
        };
        return handler;
    }, [onRowContextMenu, rows]);
    const colCount = useMemo(() => columns.length, [columns]);
    const rowCount = useMemo(() => data.length, [data.length]);
    const table = useMemo(() => ({
        id: id.current,
        headers,
        rows,
        colCount,
        rowCount,
        selectRow,
        multiSelectRow,
        rangeSelectUpToRow,
        handleActivateRow,
        handleRowContextMenu,
        selectedRows,
        canSelectRows: enableRowSelection || false,
        canSelectMultipleRows: enableMultipleRowSelection || false,
        selectionActions: selectionActions ? selectionActions(selectedRows) : undefined,
        showSelectionActions: showSelectionActions || false,
    }), [
        headers,
        rows,
        colCount,
        rowCount,
        selectRow,
        multiSelectRow,
        rangeSelectUpToRow,
        handleActivateRow,
        handleRowContextMenu,
        selectedRows,
        enableRowSelection,
        enableMultipleRowSelection,
        selectionActions,
        showSelectionActions,
    ]);
    return table;
}
