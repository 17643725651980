"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetchRequestHandler = void 0;
const responses_1 = require("@standardnotes/responses");
const models_1 = require("@standardnotes/models");
const lodash_1 = require("lodash");
const Error_1 = require("../Error");
class FetchRequestHandler {
    constructor(snjsVersion, appVersion, environment, logger) {
        this.snjsVersion = snjsVersion;
        this.appVersion = appVersion;
        this.environment = environment;
        this.logger = logger;
    }
    handleRequest(httpRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = this.createRequest(httpRequest);
            const response = yield this.runRequest(request, this.createRequestBody(httpRequest));
            return response;
        });
    }
    createRequest(httpRequest) {
        if (httpRequest.params && httpRequest.verb === responses_1.HttpVerb.Get && Object.keys(httpRequest.params).length > 0) {
            httpRequest.url = this.urlForUrlAndParams(httpRequest.url, httpRequest.params);
        }
        const headers = {};
        if (!httpRequest.external) {
            headers['X-SNJS-Version'] = this.snjsVersion;
            const appVersionHeaderValue = `${models_1.Environment[this.environment]}-${this.appVersion}`;
            headers['X-Application-Version'] = appVersionHeaderValue;
            if (httpRequest.authentication) {
                headers['Authorization'] = 'Bearer ' + httpRequest.authentication;
            }
        }
        let contentTypeIsSet = false;
        if (httpRequest.customHeaders && httpRequest.customHeaders.length > 0) {
            httpRequest.customHeaders.forEach(({ key, value }) => {
                headers[key] = value;
                if (key === 'Content-Type') {
                    contentTypeIsSet = true;
                }
            });
        }
        if (!contentTypeIsSet && !httpRequest.external) {
            headers['Content-Type'] = 'application/json';
        }
        return new Request(httpRequest.url, {
            method: httpRequest.verb,
            headers,
            credentials: 'include',
        });
    }
    runRequest(request, body) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const fetchResponse = yield fetch(request, {
                    body,
                });
                const response = yield this.handleFetchResponse(fetchResponse);
                return response;
            }
            catch (error) {
                return {
                    status: responses_1.HttpStatusCode.InternalServerError,
                    headers: new Map(),
                    data: {
                        error: {
                            message: 'message' in error ? error.message : 'Unknown error',
                        },
                    },
                };
            }
        });
    }
    handleFetchResponse(fetchResponse) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const httpStatus = fetchResponse.status;
            const response = {
                status: httpStatus,
                headers: new Map(),
                data: {},
            };
            fetchResponse.headers.forEach((value, key) => {
                ;
                response.headers.set(key, value);
            });
            try {
                if (httpStatus !== responses_1.HttpStatusCode.NoContent) {
                    let body;
                    const contentTypeHeader = ((_a = response.headers) === null || _a === void 0 ? void 0 : _a.get('content-type')) || ((_b = response.headers) === null || _b === void 0 ? void 0 : _b.get('Content-Type'));
                    if (contentTypeHeader === null || contentTypeHeader === void 0 ? void 0 : contentTypeHeader.includes('application/json')) {
                        body = JSON.parse(yield fetchResponse.text());
                    }
                    else {
                        body = yield fetchResponse.arrayBuffer();
                    }
                    /**
                     * v0 APIs do not have a `data` top-level object. In such cases, mimic
                     * the newer response body style by putting all the top-level
                     * properties inside a `data` object.
                     */
                    if (!body.data) {
                        response.data = body;
                    }
                    if (!(0, lodash_1.isString)(body)) {
                        Object.assign(response, body);
                    }
                }
            }
            catch (error) {
                this.logger.error(JSON.stringify(error));
            }
            if (httpStatus >= responses_1.HttpStatusCode.Success && httpStatus < responses_1.HttpStatusCode.InternalServerError) {
                if (httpStatus === responses_1.HttpStatusCode.Forbidden && (0, responses_1.isErrorResponse)(response)) {
                    if (!response.data.error) {
                        response.data.error = {
                            message: Error_1.ErrorMessage.RateLimited,
                        };
                    }
                    else {
                        response.data.error.message = Error_1.ErrorMessage.RateLimited;
                    }
                }
                return response;
            }
            else {
                const errorResponse = response;
                if (!errorResponse.data) {
                    errorResponse.data = {
                        error: {
                            message: 'Unknown error',
                        },
                    };
                }
                if ((0, lodash_1.isString)(errorResponse.data)) {
                    errorResponse.data = {
                        error: {
                            message: errorResponse.data,
                        },
                    };
                }
                if (!errorResponse.data.error) {
                    errorResponse.data.error = {
                        message: 'Unknown error',
                    };
                }
                return errorResponse;
            }
        });
    }
    urlForUrlAndParams(url, params) {
        const keyValueString = Object.keys(params)
            .map((key) => {
            return key + '=' + encodeURIComponent(params[key]);
        })
            .join('&');
        if (url.includes('?')) {
            return url + '&' + keyValueString;
        }
        else {
            return url + '?' + keyValueString;
        }
    }
    createRequestBody(httpRequest) {
        if (httpRequest.params !== undefined &&
            [responses_1.HttpVerb.Post, responses_1.HttpVerb.Put, responses_1.HttpVerb.Patch, responses_1.HttpVerb.Delete].includes(httpRequest.verb)) {
            return JSON.stringify(httpRequest.params);
        }
        return httpRequest.rawBytes;
    }
}
exports.FetchRequestHandler = FetchRequestHandler;
