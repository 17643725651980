/* istanbul ignore file */
export var SyncEvent;
(function (SyncEvent) {
    /**
     * A potentially multi-round trip that keeps syncing until all items have been uploaded.
     * However, this event will still trigger if there are more items waiting to be downloaded on the
     * server
     */
    SyncEvent["SyncCompletedWithAllItemsUploaded"] = "SyncCompletedWithAllItemsUploaded";
    SyncEvent["SyncCompletedWithAllItemsUploadedAndDownloaded"] = "SyncCompletedWithAllItemsUploadedAndDownloaded";
    SyncEvent["PaginatedSyncRequestCompleted"] = "PaginatedSyncRequestCompleted";
    SyncEvent["SyncDidBeginProcessing"] = "sync:did-begin-processing";
    SyncEvent["DownloadFirstSyncCompleted"] = "sync:download-first-completed";
    SyncEvent["SyncTakingTooLong"] = "sync:taking-too-long";
    SyncEvent["SyncError"] = "sync:error";
    SyncEvent["InvalidSession"] = "sync:invalid-session";
    SyncEvent["MajorDataChange"] = "major-data-change";
    SyncEvent["LocalDataIncrementalLoad"] = "local-data-incremental-load";
    SyncEvent["LocalDataLoaded"] = "local-data-loaded";
    SyncEvent["EnterOutOfSync"] = "enter-out-of-sync";
    SyncEvent["ExitOutOfSync"] = "exit-out-of-sync";
    SyncEvent["StatusChanged"] = "status-changed";
    SyncEvent["DatabaseWriteError"] = "database-write-error";
    SyncEvent["DatabaseReadError"] = "database-read-error";
    SyncEvent["SyncRequestsIntegrityCheck"] = "sync:requests-integrity-check";
    SyncEvent["ReceivedRemoteSharedVaults"] = "received-shared-vaults";
    SyncEvent["ReceivedSharedVaultInvites"] = "received-shared-vault-invites";
    SyncEvent["ReceivedNotifications"] = "received-user-events";
    SyncEvent["ReceivedAsymmetricMessages"] = "received-asymmetric-messages";
    SyncEvent["TooManyRequests"] = "too-many-requests";
})(SyncEvent || (SyncEvent = {}));
