"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadVaultOverrides = void 0;
function PayloadVaultOverrides(vault) {
    if (!vault) {
        return {};
    }
    return {
        key_system_identifier: vault.systemIdentifier,
        shared_vault_uuid: vault.isSharedVaultListing() ? vault.sharing.sharedVaultUuid : undefined,
    };
}
exports.PayloadVaultOverrides = PayloadVaultOverrides;
