"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticatorServer = void 0;
const Paths_1 = require("./Paths");
class AuthenticatorServer {
    constructor(httpService) {
        this.httpService = httpService;
    }
    list(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.get(Paths_1.Paths.v1.listAuthenticators, params);
        });
    }
    delete(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.delete(Paths_1.Paths.v1.deleteAuthenticator(params.authenticatorId), params);
        });
    }
    generateRegistrationOptions() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.get(Paths_1.Paths.v1.generateRegistrationOptions);
        });
    }
    verifyRegistrationResponse(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post(Paths_1.Paths.v1.verifyRegistrationResponse, params);
        });
    }
    generateAuthenticationOptions(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post(Paths_1.Paths.v1.generateAuthenticationOptions, params);
        });
    }
}
exports.AuthenticatorServer = AuthenticatorServer;
