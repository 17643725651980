"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultsPaths = void 0;
exports.SharedVaultsPaths = {
    getSharedVaults: '/v1/shared-vaults',
    createSharedVault: '/v1/shared-vaults',
    deleteSharedVault: (sharedVaultUuid) => `/v1/shared-vaults/${sharedVaultUuid}`,
    updateSharedVault: (sharedVaultUuid) => `/v1/shared-vaults/${sharedVaultUuid}`,
    createSharedVaultFileValetToken: (sharedVaultUuid) => `/v1/shared-vaults/${sharedVaultUuid}/valet-tokens`,
};
