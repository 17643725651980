import { jsx as _jsx } from "react/jsx-runtime";
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { observer } from 'mobx-react-lite';
const TwoFactorTitle = ({ auth }) => {
    if (!auth.isLoggedIn()) {
        return _jsx(Title, { children: "Two-factor authentication not available" });
    }
    return _jsx(Title, { children: "Two-factor authentication" });
};
export default observer(TwoFactorTitle);
