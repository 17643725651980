"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePayload = void 0;
const EncryptedPayload_1 = require("../../Abstract/Payload/Implementations/EncryptedPayload");
const DeletedPayload_1 = require("../../Abstract/Payload/Implementations/DeletedPayload");
const DecryptedPayload_1 = require("../../Abstract/Payload/Implementations/DecryptedPayload");
const TransferPayload_1 = require("../../Abstract/TransferPayload");
function CreatePayload(from, source) {
    if ((0, TransferPayload_1.isDecryptedTransferPayload)(from)) {
        return new DecryptedPayload_1.DecryptedPayload(from, source);
    }
    else if ((0, TransferPayload_1.isEncryptedTransferPayload)(from)) {
        return new EncryptedPayload_1.EncryptedPayload(from, source);
    }
    else if ((0, TransferPayload_1.isDeletedTransferPayload)(from)) {
        return new DeletedPayload_1.DeletedPayload(from, source);
    }
    else {
        throw Error('Unhandled case in CreatePayload');
    }
}
exports.CreatePayload = CreatePayload;
