import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import Bubble from '@/Components/Bubble/Bubble';
import { useCallback } from 'react';
const SearchOptions = ({ searchOptions }) => {
    const { includeProtectedContents, includeArchived, includeTrashed } = searchOptions;
    const toggleIncludeProtectedContents = useCallback(async () => {
        await searchOptions.toggleIncludeProtectedContents();
    }, [searchOptions]);
    return (_jsxs("div", { className: "mt-3 flex flex-wrap gap-2", onMouseDown: (e) => e.preventDefault(), children: [_jsx(Bubble, { label: "Protected Contents", selected: includeProtectedContents, onSelect: toggleIncludeProtectedContents }), _jsx(Bubble, { label: "Archived", selected: includeArchived, onSelect: searchOptions.toggleIncludeArchived }), _jsx(Bubble, { label: "Trashed", selected: includeTrashed, onSelect: searchOptions.toggleIncludeTrashed })] }));
};
export default observer(SearchOptions);
