import { action, makeObservable, observable } from 'mobx';
export class ActionsMenuController {
    constructor() {
        this.hiddenSections = {};
        this.toggleSectionVisibility = (uuid) => {
            this.hiddenSections[uuid] = !this.hiddenSections[uuid];
        };
        this.reset = () => {
            this.hiddenSections = {};
        };
        makeObservable(this, {
            hiddenSections: observable,
            toggleSectionVisibility: action,
            reset: action,
        });
    }
}
