import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useListKeyboardNavigation } from '@/Hooks/useListKeyboardNavigation';
import HistoryListItem from './HistoryListItem';
const LegacyHistoryList = ({ legacyHistory, noteHistoryController, onSelectRevision }) => {
    const { selectLegacyRevision, selectedEntry } = noteHistoryController;
    const [listElement, setListElement] = useState(null);
    useListKeyboardNavigation(listElement);
    return (_jsxs("div", { className: `flex h-full w-full flex-col focus:shadow-none ${!(legacyHistory === null || legacyHistory === void 0 ? void 0 : legacyHistory.length) ? 'items-center justify-center' : ''}`, ref: setListElement, children: [legacyHistory === null || legacyHistory === void 0 ? void 0 : legacyHistory.map((entry) => {
                var _a, _b;
                const selectedEntryUrl = (_a = selectedEntry === null || selectedEntry === void 0 ? void 0 : selectedEntry.subactions) === null || _a === void 0 ? void 0 : _a[0].url;
                const url = (_b = entry.subactions) === null || _b === void 0 ? void 0 : _b[0].url;
                return (_jsx(HistoryListItem, { isSelected: selectedEntryUrl === url, onClick: () => {
                        void selectLegacyRevision(entry);
                        onSelectRevision();
                    }, children: entry.label }, url));
            }), !(legacyHistory === null || legacyHistory === void 0 ? void 0 : legacyHistory.length) && _jsx("div", { className: "select-none text-sm text-passive-0", children: "No legacy history found" })] }));
};
export default LegacyHistoryList;
