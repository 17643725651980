import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';
import Icon from '../Icon/Icon';
import { useState, useCallback } from 'react';
import EditVaultModal from '../Preferences/Panes/Vaults/Vaults/VaultModal/EditVaultModal';
import { useVault } from '@/Hooks/useVault';
const VaultOptionsMenu = ({ vault }) => {
    const { canShowLockOption, isLocked, toggleLock, ensureVaultIsUnlocked } = useVault(vault);
    const [isVaultModalOpen, setIsVaultModalOpen] = useState(false);
    const openEditModal = useCallback(async () => {
        if (!(await ensureVaultIsUnlocked())) {
            return;
        }
        setIsVaultModalOpen(true);
    }, [ensureVaultIsUnlocked]);
    return (_jsxs(_Fragment, { children: [_jsxs(Menu, { a11yLabel: "Vault options menu", children: [_jsxs(MenuItem, { onClick: openEditModal, children: [_jsx(Icon, { type: "pencil-filled", className: "mr-2" }), "Edit vault"] }), canShowLockOption && (_jsxs(MenuItem, { onClick: toggleLock, children: [_jsx(Icon, { type: "lock", className: "mr-2" }), isLocked ? 'Unlock' : 'Lock', " vault"] }))] }), _jsx(EditVaultModal, { vault: vault, isVaultModalOpen: isVaultModalOpen, closeVaultModal: () => setIsVaultModalOpen(false) })] }));
};
export default VaultOptionsMenu;
