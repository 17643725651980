"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FillItemContentSpecialized = exports.FillItemContent = void 0;
const DefaultAppDomain_1 = require("../Item/Types/DefaultAppDomain");
const AppDataField_1 = require("../Item/Types/AppDataField");
/**
 * Modifies the input object to fill in any missing required values from the
 * content body.
 */
function FillItemContent(content) {
    if (!content.references) {
        content.references = [];
    }
    if (!content.appData) {
        content.appData = {
            [DefaultAppDomain_1.DefaultAppDomain]: {},
        };
    }
    if (!content.appData[DefaultAppDomain_1.DefaultAppDomain]) {
        content.appData[DefaultAppDomain_1.DefaultAppDomain] = {};
    }
    if (!content.appData[DefaultAppDomain_1.DefaultAppDomain][AppDataField_1.AppDataField.UserModifiedDate]) {
        content.appData[DefaultAppDomain_1.DefaultAppDomain][AppDataField_1.AppDataField.UserModifiedDate] = new Date().toString();
    }
    return content;
}
exports.FillItemContent = FillItemContent;
function FillItemContentSpecialized(content) {
    return FillItemContent(content);
}
exports.FillItemContentSpecialized = FillItemContentSpecialized;
