import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useRef, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import { KeyboardKey } from '@standardnotes/ui-services';
import Popover from '../Popover/Popover';
import { classNames } from '@standardnotes/snjs';
import { getTitleForLinkedTag } from '@/Utils/Items/Display/getTitleForLinkedTag';
import { useApplication } from '../ApplicationProvider';
import MenuItem from '../Menu/MenuItem';
import Menu from '../Menu/Menu';
const AddTagOption = ({ navigationController, linkingController, selectedItems, iconClassName, disabled, }) => {
    const application = useApplication();
    const menuContainerRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);
    const isTagLinkedToSelectedItems = (tag) => {
        return selectedItems.every((item) => application.getItemTags(item).find((itemTag) => itemTag.uuid === tag.uuid));
    };
    const linkTagToSelectedItems = (tag) => {
        selectedItems.forEach((item) => linkingController.linkItems(item, tag));
    };
    const unlinkTagFromSelectedItems = (tag) => {
        selectedItems.forEach((item) => linkingController.unlinkItems(item, tag));
    };
    return (_jsxs("div", { ref: menuContainerRef, children: [_jsxs(MenuItem, { className: "justify-between", onClick: toggleMenu, onKeyDown: (event) => {
                    if (event.key === KeyboardKey.Escape) {
                        setIsOpen(false);
                    }
                }, ref: buttonRef, disabled: disabled, children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { type: "hashtag", className: iconClassName }), "Add tag"] }), _jsx(Icon, { type: "chevron-right", className: "text-neutral" })] }), _jsx(Popover, { title: "Add tag", togglePopover: toggleMenu, anchorElement: buttonRef, open: isOpen, side: "right", align: "start", className: "py-2", overrideZIndex: "z-modal", children: _jsx(Menu, { a11yLabel: "Tag selection menu", className: "!px-0", children: navigationController.tags.map((tag) => {
                        var _a;
                        return (_jsxs(MenuItem, { onClick: () => {
                                isTagLinkedToSelectedItems(tag) ? unlinkTagFromSelectedItems(tag) : linkTagToSelectedItems(tag);
                            }, children: [tag.iconString && (_jsx(Icon, { type: tag.iconString, size: 'custom', className: 'ml-0.5 mr-1.5 h-7 w-7 text-2xl text-neutral lg:h-6 lg:w-6 lg:text-lg' })), _jsx("span", { className: classNames('overflow-hidden overflow-ellipsis whitespace-nowrap', isTagLinkedToSelectedItems(tag) ? 'font-bold' : ''), children: (_a = getTitleForLinkedTag(tag, application)) === null || _a === void 0 ? void 0 : _a.longTitle })] }, tag.uuid));
                    }) }) })] }));
};
export default observer(AddTagOption);
