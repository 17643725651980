import { Environment, Result } from '@standardnotes/snjs';
export class OpenSubscriptionDashboard {
    constructor(application, legacyApi) {
        this.application = application;
        this.legacyApi = legacyApi;
    }
    async execute() {
        const token = await this.legacyApi.getNewSubscriptionToken();
        if (!token) {
            return Result.fail('Could not get subscription token.');
        }
        const url = `${window.dashboardUrl}?subscription_token=${token}`;
        if (this.application.device.environment === Environment.Mobile) {
            this.application.device.openUrl(url);
            return Result.ok();
        }
        if (this.application.device.environment === Environment.Desktop) {
            window.open(url, '_blank');
            return Result.ok();
        }
        const windowProxy = window.open('', '_blank');
        windowProxy.location = url;
        return Result.ok();
    }
}
