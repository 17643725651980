"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadSource = void 0;
var PayloadSource;
(function (PayloadSource) {
    /**
     * Payloads with a source of Constructor means that the payload was created
     * in isolated space by the caller, and does not yet have any app-related affiliation.
     */
    PayloadSource[PayloadSource["Constructor"] = 1] = "Constructor";
    PayloadSource[PayloadSource["LocalDatabaseLoaded"] = 2] = "LocalDatabaseLoaded";
    PayloadSource[PayloadSource["RemoteRetrieved"] = 3] = "RemoteRetrieved";
    PayloadSource[PayloadSource["RemoteSaved"] = 4] = "RemoteSaved";
    PayloadSource[PayloadSource["FileImport"] = 5] = "FileImport";
})(PayloadSource || (exports.PayloadSource = PayloadSource = {}));
