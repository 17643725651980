import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { Platform, classNames } from '@standardnotes/snjs';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useApplication } from '@/Components/ApplicationProvider';
import { useCallback, useState } from 'react';
import { $createFileNode } from '../EncryptedFilePlugin/Nodes/FileUtils';
import { isIOS } from '@standardnotes/ui-services';
import Icon from '@/Components/Icon/Icon';
import Spinner from '@/Components/Spinner/Spinner';
const InlineFileComponent = ({ className, src, mimeType, fileName, format, node, nodeKey }) => {
    const application = useApplication();
    const [editor] = useLexicalComposerContext();
    const [isSaving, setIsSaving] = useState(false);
    const saveToFilesAndReplaceNode = useCallback(async () => {
        setIsSaving(true);
        try {
            const blob = await fetch(src).then((response) => response.blob());
            const file = new File([blob], fileName || application.generateUUID(), { type: mimeType });
            const { filesController, linkingController } = application;
            const uploadedFile = await filesController.uploadNewFile(file, { showToast: false });
            if (!uploadedFile) {
                return;
            }
            editor.update(() => {
                const fileNode = $createFileNode(uploadedFile.uuid);
                node.replace(fileNode);
            });
            void linkingController.linkItemToSelectedItem(uploadedFile);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsSaving(false);
        }
    }, [application, editor, fileName, mimeType, node, src]);
    const isPDF = mimeType === 'application/pdf';
    return (_jsxs(BlockWithAlignableContents, { className: className, format: format, nodeKey: nodeKey, children: [mimeType.startsWith('image') ? (_jsx("div", { className: "relative flex min-h-[2rem] flex-col items-center gap-2.5", children: _jsx("img", { alt: fileName, src: src }) })) : mimeType.startsWith('video') ? (_jsx("video", { className: "h-full w-full", controls: true, autoPlay: true, children: _jsx("source", { src: src, type: mimeType }) })) : mimeType.startsWith('audio') ? (_jsx("div", { className: "flex h-full w-full items-center justify-center", children: _jsx("audio", { controls: true, children: _jsx("source", { src: src, type: mimeType }) }) })) : (_jsx("object", { className: classNames('h-full w-full', isPDF && 'min-h-[65vh]'), data: isPDF ? src + '#view=FitV' : src })), _jsx("button", { className: classNames('mx-auto mt-2 flex items-center gap-2.5 rounded border border-border bg-default px-2.5 py-1.5', !isSaving && 'hover:bg-info hover:text-info-contrast'), onClick: () => {
                    const isIOSPlatform = application.platform === Platform.Ios || isIOS();
                    if (isIOSPlatform && document.activeElement) {
                        ;
                        document.activeElement.blur();
                    }
                    saveToFilesAndReplaceNode().catch(console.error);
                }, disabled: isSaving, children: isSaving ? (_jsxs(_Fragment, { children: [_jsx(Spinner, { className: "h-4 w-4" }), "Saving..."] })) : (_jsxs(_Fragment, { children: [_jsx(Icon, { type: "download" }), "Save to Files"] })) })] }));
};
export default InlineFileComponent;
