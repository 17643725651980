import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState, } from 'react';
import { AccountMenuPane } from './AccountMenuPane';
import Button from '@/Components/Button/Button';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import DecoratedPasswordInput from '@/Components/Input/DecoratedPasswordInput';
import Icon from '@/Components/Icon/Icon';
import IconButton from '@/Components/Button/IconButton';
import AdvancedOptions from './AdvancedOptions';
import HorizontalSeparator from '../Shared/HorizontalSeparator';
const CreateAccount = ({ setMenuPane, email, setEmail, password, setPassword }) => {
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const [isPrivateUsername, setIsPrivateUsername] = useState(false);
    useEffect(() => {
        var _a;
        if (emailInputRef.current) {
            (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    const handleEmailChange = useCallback((text) => {
        setEmail(text);
    }, [setEmail]);
    const handlePasswordChange = useCallback((text) => {
        setPassword(text);
    }, [setPassword]);
    const handleRegisterFormSubmit = useCallback((e) => {
        var _a, _b;
        e.preventDefault();
        if (!email || email.length === 0) {
            (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (!password || password.length === 0) {
            (_b = passwordInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            return;
        }
        setEmail(email);
        setPassword(password);
        setMenuPane(AccountMenuPane.ConfirmPassword);
    }, [email, password, setPassword, setMenuPane, setEmail]);
    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            handleRegisterFormSubmit(e);
        }
    }, [handleRegisterFormSubmit]);
    const handleClose = useCallback(() => {
        setMenuPane(AccountMenuPane.GeneralMenu);
        setEmail('');
        setPassword('');
    }, [setEmail, setMenuPane, setPassword]);
    const onPrivateUsernameChange = useCallback((isPrivateUsername, privateUsernameIdentifier) => {
        setIsPrivateUsername(isPrivateUsername);
        if (isPrivateUsername && privateUsernameIdentifier) {
            setEmail(privateUsernameIdentifier);
        }
    }, [setEmail]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-3 mt-1 flex items-center px-3", children: [_jsx(IconButton, { icon: "arrow-left", title: "Go back", className: "mr-2 flex p-0 text-neutral", onClick: handleClose, focusable: true }), _jsx("div", { className: "text-base font-bold", children: "Create account" })] }), _jsxs("form", { onSubmit: handleRegisterFormSubmit, className: "mb-1 px-3", children: [_jsx(DecoratedInput, { className: { container: 'mb-2' }, disabled: isPrivateUsername, left: [_jsx(Icon, { type: "email", className: "text-neutral" })], onChange: handleEmailChange, onKeyDown: handleKeyDown, placeholder: "Email", ref: emailInputRef, type: "email", value: email, spellcheck: false }), _jsx(DecoratedPasswordInput, { className: { container: 'mb-2' }, left: [_jsx(Icon, { type: "password", className: "text-neutral" })], onChange: handlePasswordChange, onKeyDown: handleKeyDown, placeholder: "Password", ref: passwordInputRef, value: password }), _jsx(Button, { className: "mt-1", label: "Next", primary: true, onClick: handleRegisterFormSubmit, fullWidth: true })] }), _jsx(HorizontalSeparator, { classes: "my-2" }), _jsx(AdvancedOptions, { onPrivateUsernameModeChange: onPrivateUsernameChange })] }));
};
export default observer(CreateAccount);
