export const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            var _a;
            if ((_a = e.target) === null || _a === void 0 ? void 0 : _a.result) {
                resolve(e.target.result);
            }
            else {
                reject();
            }
        };
        reader.readAsText(file);
    });
};
export const getBlobFromBase64 = (b64Data, contentType = '') => {
    const byteString = atob(b64Data.split(',')[1]);
    if (!contentType) {
        contentType = b64Data.split(',')[0].split(':')[1].split(';')[0];
    }
    const buffer = new ArrayBuffer(byteString.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
        view[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([buffer], { type: contentType });
    return blob;
};
