import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PhotoRecorder } from '@/Controllers/Moments/PhotoRecorder';
import { formatDateAndTimeForNote } from '@/Utils/DateUtils';
import { classNames } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import Icon from '../Icon/Icon';
import DecoratedInput from '../Input/DecoratedInput';
import Modal from '../Modal/Modal';
const PhotoCaptureModal = ({ filesController, close }) => {
    const [fileName, setFileName] = useState(formatDateAndTimeForNote(new Date()));
    const [recorder, setRecorder] = useState(() => new PhotoRecorder());
    const [isRecorderReady, setIsRecorderReady] = useState(false);
    const [capturedPhoto, setCapturedPhoto] = useState();
    const fileNameInputRef = useRef(null);
    const previewRef = useRef(null);
    useEffect(() => {
        if (!recorder) {
            return;
        }
        setIsRecorderReady(false);
        const init = async () => {
            await recorder.initialize();
            if (previewRef.current) {
                recorder.video.style.position = '';
                recorder.video.style.display = '';
                recorder.video.style.height = '100%';
                previewRef.current.append(recorder.video);
            }
            setIsRecorderReady(true);
        };
        void init();
        return () => {
            if (recorder.video) {
                recorder.finish();
            }
        };
    }, [recorder]);
    const takePhoto = useCallback(async () => {
        if (!recorder) {
            return;
        }
        const file = await recorder.takePhoto(fileName);
        setCapturedPhoto(file);
        setRecorder(undefined);
    }, [fileName, recorder]);
    const devicesAsDropdownItems = useMemo(() => {
        return (recorder === null || recorder === void 0 ? void 0 : recorder.devices)
            ? recorder.devices.map((device) => ({
                label: device.label || `Camera (${device.deviceId.slice(0, 10)})`,
                value: device.deviceId,
            }))
            : [];
    }, [recorder === null || recorder === void 0 ? void 0 : recorder.devices]);
    const savePhoto = useCallback(() => {
        var _a;
        if (!fileName) {
            (_a = fileNameInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (!capturedPhoto) {
            return;
        }
        void filesController.uploadNewFile(capturedPhoto);
        close();
    }, [capturedPhoto, close, fileName, filesController]);
    const retryPhoto = () => {
        setCapturedPhoto(undefined);
        setRecorder(new PhotoRecorder());
    };
    return (_jsx(Modal, { title: "Take a photo", close: close, actions: [
            {
                label: 'Capture',
                onClick: takePhoto,
                type: 'primary',
                mobileSlot: 'right',
                hidden: !!capturedPhoto,
            },
            {
                label: 'Upload',
                onClick: savePhoto,
                type: 'primary',
                mobileSlot: 'right',
                hidden: !capturedPhoto,
            },
            {
                label: 'Cancel',
                onClick: close,
                type: 'cancel',
                mobileSlot: 'left',
            },
            {
                label: 'Retry',
                onClick: retryPhoto,
                type: 'secondary',
            },
        ], children: _jsxs("div", { className: "px-4 py-4", children: [_jsx("div", { className: "mb-4 flex flex-col", children: _jsxs("label", { className: "text-sm font-medium text-neutral", children: ["File name:", _jsx(DecoratedInput, { className: {
                                    container: 'mt-1',
                                }, value: fileName, onChange: (fileName) => setFileName(fileName), ref: fileNameInputRef })] }) }), _jsxs("div", { className: "mt-2", children: [_jsx("div", { className: "text-sm font-medium text-neutral", children: "Preview:" }), !isRecorderReady && (_jsx("div", { className: "mt-1 w-full", children: _jsxs("div", { className: "flex h-64 w-full items-center justify-center gap-2 rounded-md bg-contrast text-base", children: [_jsx(Icon, { type: "camera", className: "text-neutral-300" }), "Initializing..."] }) })), _jsx("div", { className: classNames('mt-1 w-full', capturedPhoto && 'hidden'), ref: previewRef }), capturedPhoto && (_jsx("div", { className: "mt-1 w-full", children: _jsx("img", { src: URL.createObjectURL(capturedPhoto), alt: "Captured photo" }) }))] }), recorder && devicesAsDropdownItems.length > 1 && !capturedPhoto && (_jsx("div", { className: "mt-4", children: _jsxs("label", { className: "text-sm font-medium text-neutral", children: ["Device:", _jsx(Dropdown, { label: 'Photo Capture Device', items: devicesAsDropdownItems, value: recorder.selectedDevice.deviceId, onChange: (value) => {
                                    void recorder.setDevice(value);
                                }, classNameOverride: {
                                    wrapper: 'mt-1',
                                } })] }) }))] }) }));
};
export default observer(PhotoCaptureModal);
