export const getAllTextNodesInElement = (element) => {
    const textNodes = [];
    const walk = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null);
    let node = walk.nextNode();
    while (node) {
        textNodes.push(node);
        node = walk.nextNode();
    }
    return textNodes;
};
