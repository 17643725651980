import { UIFeature } from '@standardnotes/models';
import { FindNativeTheme } from '@standardnotes/features';
import { Uuid } from '@standardnotes/domain-core';
export class ActiveThemeList {
    constructor(items, initialList) {
        this.items = items;
        this.list = [];
        if (initialList) {
            this.list = initialList;
        }
    }
    getList() {
        return this.list.slice();
    }
    isEmpty() {
        return this.list.length === 0;
    }
    clear() {
        this.list = [];
    }
    has(candidate) {
        for (const entry of this.list) {
            if (entry.equals(candidate)) {
                return true;
            }
        }
        return false;
    }
    add(candidate) {
        if (!this.has(candidate)) {
            this.list.push(candidate);
        }
    }
    remove(candidate) {
        this.list = this.list.filter((entry) => {
            return !entry.equals(candidate);
        });
    }
    asThemes() {
        const results = [];
        for (const entry of this.list) {
            if (entry instanceof Uuid) {
                const theme = this.items.findItem(entry.value);
                if (theme) {
                    const uiFeature = new UIFeature(theme);
                    results.push(uiFeature);
                }
            }
            else {
                const theme = FindNativeTheme(entry.value);
                if (theme) {
                    const uiFeature = new UIFeature(theme);
                    results.push(uiFeature);
                }
            }
        }
        return results;
    }
}
