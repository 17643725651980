import { STRING_DELETE_TAG } from '@/Constants/Strings';
import { predicateFromJson, SmartViewDefaultIconName, } from '@standardnotes/snjs';
import { confirmDialog } from '@standardnotes/ui-services';
import { action, makeObservable, observable } from 'mobx';
export class EditSmartViewModalController {
    constructor(application) {
        this.application = application;
        this.title = '';
        this.icon = SmartViewDefaultIconName;
        this.predicateJson = '';
        this.isPredicateJsonValid = false;
        this.isSaving = false;
        this.view = undefined;
        this.setTitle = (title) => {
            this.title = title;
        };
        this.setIcon = (icon) => {
            this.icon = icon;
        };
        this.setPredicateJson = (json) => {
            this.predicateJson = json;
        };
        this.setIsPredicateJsonValid = (isValid) => {
            this.isPredicateJsonValid = isValid;
        };
        this.setView = (view) => {
            this.view = view;
            if (view) {
                this.setTitle(view.title);
                this.setIcon(view.iconString);
                this.setPredicateJson(JSON.stringify(view.predicate.toJson(), null, 2));
                this.setIsPredicateJsonValid(true);
            }
        };
        this.setIsSaving = (isSaving) => {
            this.isSaving = isSaving;
        };
        this.closeDialog = () => {
            this.setView(undefined);
            this.setTitle('');
            this.setIcon(SmartViewDefaultIconName);
            this.setPredicateJson('');
        };
        this.save = async () => {
            if (!this.view) {
                return;
            }
            this.validateAndPrettifyCustomPredicate();
            if (!this.isPredicateJsonValid) {
                return;
            }
            this.setIsSaving(true);
            await this.application.changeAndSaveItem.execute(this.view, (mutator) => {
                mutator.title = this.title;
                mutator.iconString = this.icon || SmartViewDefaultIconName;
                mutator.predicate = JSON.parse(this.predicateJson);
            });
            this.setIsSaving(false);
            this.closeDialog();
        };
        this.deleteView = async () => {
            if (!this.view) {
                return;
            }
            const view = this.view;
            this.closeDialog();
            const shouldDelete = await confirmDialog({
                text: STRING_DELETE_TAG,
                confirmButtonStyle: 'danger',
            });
            if (shouldDelete) {
                this.application.mutator
                    .deleteItem(view)
                    .then(() => this.application.sync.sync())
                    .catch(console.error);
            }
        };
        this.validateAndPrettifyCustomPredicate = () => {
            try {
                const parsedPredicate = JSON.parse(this.predicateJson);
                const predicate = predicateFromJson(parsedPredicate);
                if (predicate) {
                    this.setPredicateJson(JSON.stringify(parsedPredicate, null, 2));
                    this.setIsPredicateJsonValid(true);
                }
                else {
                    this.setIsPredicateJsonValid(false);
                }
            }
            catch (error) {
                this.setIsPredicateJsonValid(false);
                return;
            }
        };
        makeObservable(this, {
            title: observable,
            icon: observable,
            predicateJson: observable,
            isPredicateJsonValid: observable,
            isSaving: observable,
            view: observable,
            setTitle: action,
            setIcon: action,
            setPredicateJson: action,
            setIsPredicateJsonValid: action,
            setIsSaving: action,
            setView: action,
        });
    }
}
