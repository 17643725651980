"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemWithTags = void 0;
const utils_1 = require("@standardnotes/utils");
const Item_1 = require("../../../Abstract/Item");
class ItemWithTags extends Item_1.DecryptedItem {
    constructor(payload, item, tags) {
        super(payload);
        this.item = item;
        this.tags = tags;
        this.transferInItemPropertiesSoRootLevelPredicateKeyPathsCanBeMatched(item);
        this.tags = tags || payload.content.tags;
    }
    transferInItemPropertiesSoRootLevelPredicateKeyPathsCanBeMatched(item) {
        Object.assign(this, (0, utils_1.omitByCopy)(item, ['title', 'text']));
    }
    static Create(payload, item, tags) {
        return new ItemWithTags(payload, item, tags);
    }
    get tagsCount() {
        var _a;
        return ((_a = this.tags) === null || _a === void 0 ? void 0 : _a.length) || 0;
    }
    get title() {
        return this.item.title;
    }
    get text() {
        return this.item.text;
    }
}
exports.ItemWithTags = ItemWithTags;
