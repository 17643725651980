import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames, sanitizeHtmlString } from '@standardnotes/snjs';
const ListItemNotePreviewText = ({ item, hidePreview, lineLimit = 1 }) => {
    if (item.hidePreview || item.protected || hidePreview) {
        return null;
    }
    return (_jsxs("div", { className: classNames('overflow-hidden overflow-ellipsis text-base lg:text-sm', item.archived ? 'opacity-60' : ''), children: [item.preview_html && (_jsx("div", { className: "my-1", dangerouslySetInnerHTML: {
                    __html: sanitizeHtmlString(item.preview_html),
                } })), !item.preview_html && item.preview_plain && (_jsx("div", { className: `leading-1.3 line-clamp-${lineLimit} mt-1 overflow-hidden`, children: item.preview_plain }))] }));
};
export default ListItemNotePreviewText;
