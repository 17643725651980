"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactPublicKeySet = void 0;
class ContactPublicKeySet {
    constructor(dto) {
        this.encryption = dto.encryption;
        this.signing = dto.signing;
        this.timestamp = dto.timestamp;
        this.previousKeySet = dto.previousKeySet;
    }
    findKeySetWithSigningKey(signingKey) {
        if (this.signing === signingKey) {
            return this;
        }
        if (this.previousKeySet) {
            return this.previousKeySet.findKeySetWithSigningKey(signingKey);
        }
        return undefined;
    }
    findKeySetWithPublicKey(publicKey) {
        if (this.encryption === publicKey) {
            return this;
        }
        if (this.previousKeySet) {
            return this.previousKeySet.findKeySetWithPublicKey(publicKey);
        }
        return undefined;
    }
    asJson() {
        return {
            encryption: this.encryption,
            signing: this.signing,
            timestamp: this.timestamp,
            previousKeySet: this.previousKeySet ? this.previousKeySet.asJson() : undefined,
        };
    }
    mutableCopy() {
        return new ContactPublicKeySet({
            encryption: this.encryption,
            signing: this.signing,
            timestamp: this.timestamp,
            previousKeySet: this.previousKeySet ? ContactPublicKeySet.FromJson(this.previousKeySet.asJson()) : undefined,
        });
    }
    static FromJson(json) {
        return new ContactPublicKeySet({
            encryption: json.encryption,
            signing: json.signing,
            timestamp: new Date(json.timestamp),
            previousKeySet: json.previousKeySet ? ContactPublicKeySet.FromJson(json.previousKeySet) : undefined,
        });
    }
}
exports.ContactPublicKeySet = ContactPublicKeySet;
