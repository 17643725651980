import { AlertService, ButtonType } from '@standardnotes/services';
import { sanitizeHtmlString } from '@standardnotes/utils';
import { SKAlert } from '@standardnotes/styles';
import { alertDialog, confirmDialog } from './Functions';
export class WebAlertService extends AlertService {
    confirmV2(dto) {
        return confirmDialog({
            text: dto.text,
            title: dto.title,
            confirmButtonText: dto.confirmButtonText,
            cancelButtonText: dto.cancelButtonText,
            confirmButtonStyle: dto.confirmButtonType === ButtonType.Danger ? 'danger' : 'info',
        });
    }
    alertV2(dto) {
        return alertDialog({ text: dto.text, title: dto.title, closeButtonText: dto.closeButtonText });
    }
    alert(text, title, closeButtonText) {
        return alertDialog({ text, title, closeButtonText });
    }
    confirm(text, title, confirmButtonText, confirmButtonType, cancelButtonText) {
        return confirmDialog({
            text,
            title,
            confirmButtonText,
            cancelButtonText,
            confirmButtonStyle: confirmButtonType === ButtonType.Danger ? 'danger' : 'info',
        });
    }
    blockingDialog(text, title) {
        const alert = new SKAlert({
            title: title && sanitizeHtmlString(title),
            text: sanitizeHtmlString(text),
            buttons: [],
        });
        alert.present();
        return () => {
            alert.dismiss();
        };
    }
}
