"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paths = void 0;
const UserPaths = {
    register: '/v1/users',
    updateAccount: (userUuid) => `/v1/users/${userUuid}`,
    deleteAccount: (userUuid) => `/v1/users/${userUuid}`,
};
exports.Paths = {
    v1: Object.assign({}, UserPaths),
};
