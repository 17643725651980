import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isDev } from '@/Utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AccountMenuPane } from './AccountMenuPane';
import Button from '@/Components/Button/Button';
import Checkbox from '@/Components/Checkbox/Checkbox';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import DecoratedPasswordInput from '@/Components/Input/DecoratedPasswordInput';
import Icon from '@/Components/Icon/Icon';
import IconButton from '@/Components/Button/IconButton';
import AdvancedOptions from './AdvancedOptions';
import HorizontalSeparator from '../Shared/HorizontalSeparator';
import { getErrorFromErrorResponse, isErrorResponse, getCaptchaHeader } from '@standardnotes/snjs';
import { useApplication } from '../ApplicationProvider';
import { useCaptcha } from '@/Hooks/useCaptcha';
const SignInPane = ({ setMenuPane }) => {
    const application = useApplication();
    const { notesAndTagsCount } = application.accountMenuController;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [recoveryCodes, setRecoveryCodes] = useState('');
    const [error, setError] = useState('');
    const [isEphemeral, setIsEphemeral] = useState(false);
    const [isStrictSignin, setIsStrictSignin] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [shouldMergeLocal, setShouldMergeLocal] = useState(true);
    const [isPrivateUsername, setIsPrivateUsername] = useState(false);
    const [isRecoverySignIn, setIsRecoverySignIn] = useState(false);
    const [captchaURL, setCaptchaURL] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [hvmToken, setHVMToken] = useState('');
    const captchaIframe = useCaptcha(captchaURL, (token) => {
        setHVMToken(token);
        setShowCaptcha(false);
        setCaptchaURL('');
    });
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    useEffect(() => {
        var _a;
        if (emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) {
            (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if (isDev && window.devAccountEmail) {
            setEmail(window.devAccountEmail);
            setPassword(window.devAccountPassword);
        }
    }, []);
    const resetInvalid = useCallback(() => {
        if (error.length) {
            setError('');
        }
    }, [setError, error]);
    const handleEmailChange = useCallback((text) => {
        setEmail(text);
    }, []);
    const handlePasswordChange = useCallback((text) => {
        if (error.length) {
            setError('');
        }
        setPassword(text);
    }, [setPassword, error]);
    const handleEphemeralChange = useCallback(() => {
        setIsEphemeral(!isEphemeral);
    }, [isEphemeral]);
    const handleStrictSigninChange = useCallback(() => {
        setIsStrictSignin(!isStrictSignin);
    }, [isStrictSignin]);
    const onRecoveryCodesChange = useCallback((newIsRecoverySignIn, recoveryCodes) => {
        setIsRecoverySignIn(newIsRecoverySignIn);
        if (newIsRecoverySignIn && recoveryCodes) {
            setRecoveryCodes(recoveryCodes);
        }
    }, [setRecoveryCodes]);
    const handleShouldMergeChange = useCallback(() => {
        setShouldMergeLocal(!shouldMergeLocal);
    }, [shouldMergeLocal]);
    const signIn = useCallback(() => {
        var _a, _b;
        setIsSigningIn(true);
        (_a = emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        (_b = passwordInputRef === null || passwordInputRef === void 0 ? void 0 : passwordInputRef.current) === null || _b === void 0 ? void 0 : _b.blur();
        application
            .signIn(email, password, isStrictSignin, isEphemeral, shouldMergeLocal, false, hvmToken)
            .then((response) => {
            const captchaURL = getCaptchaHeader(response);
            if (captchaURL) {
                setCaptchaURL(captchaURL);
            }
            if (isErrorResponse(response)) {
                throw new Error(getErrorFromErrorResponse(response).message);
            }
            application.accountMenuController.closeAccountMenu();
        })
            .catch((err) => {
            var _a, _b;
            console.error(err);
            setError((_a = err.message) !== null && _a !== void 0 ? _a : err.toString());
            setPassword('');
            setHVMToken('');
            (_b = passwordInputRef === null || passwordInputRef === void 0 ? void 0 : passwordInputRef.current) === null || _b === void 0 ? void 0 : _b.blur();
        })
            .finally(() => {
            setIsSigningIn(false);
        });
    }, [application, email, hvmToken, isEphemeral, isStrictSignin, password, shouldMergeLocal]);
    const recoverySignIn = useCallback(() => {
        var _a, _b;
        setIsSigningIn(true);
        (_a = emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        (_b = passwordInputRef === null || passwordInputRef === void 0 ? void 0 : passwordInputRef.current) === null || _b === void 0 ? void 0 : _b.blur();
        application.signInWithRecoveryCodes
            .execute({
            recoveryCodes,
            username: email,
            password: password,
            hvmToken,
        })
            .then((result) => {
            if (result.isFailed()) {
                const error = result.getError();
                try {
                    const parsed = JSON.parse(error);
                    if (parsed.captchaURL) {
                        setCaptchaURL(parsed.captchaURL);
                        return;
                    }
                }
                catch (e) {
                    setCaptchaURL('');
                }
                throw new Error(error);
            }
            application.accountMenuController.closeAccountMenu();
        })
            .catch((err) => {
            var _a, _b;
            console.error(err);
            setError((_a = err.message) !== null && _a !== void 0 ? _a : err.toString());
            setPassword('');
            setHVMToken('');
            (_b = passwordInputRef === null || passwordInputRef === void 0 ? void 0 : passwordInputRef.current) === null || _b === void 0 ? void 0 : _b.blur();
        })
            .finally(() => {
            setIsSigningIn(false);
        });
    }, [application.accountMenuController, application.signInWithRecoveryCodes, email, hvmToken, password, recoveryCodes]);
    const onPrivateUsernameChange = useCallback((newisPrivateUsername, privateUsernameIdentifier) => {
        setIsPrivateUsername(newisPrivateUsername);
        if (newisPrivateUsername && privateUsernameIdentifier) {
            setEmail(privateUsernameIdentifier);
        }
    }, [setEmail]);
    const performSignIn = useCallback(() => {
        var _a, _b;
        if (!email || email.length === 0) {
            (_a = emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (!password || password.length === 0) {
            (_b = passwordInputRef === null || passwordInputRef === void 0 ? void 0 : passwordInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            return;
        }
        if (isRecoverySignIn) {
            recoverySignIn();
            return;
        }
        signIn();
    }, [email, isRecoverySignIn, password, recoverySignIn, signIn]);
    const handleSignInFormSubmit = useCallback((e) => {
        e.preventDefault();
        if (captchaURL) {
            setShowCaptcha(true);
            return;
        }
        performSignIn();
    }, [captchaURL, performSignIn]);
    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            handleSignInFormSubmit(e);
        }
    }, [handleSignInFormSubmit]);
    useEffect(() => {
        if (!hvmToken) {
            return;
        }
        performSignIn();
    }, [hvmToken, performSignIn]);
    const signInForm = (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-1 px-3", children: [_jsx(DecoratedInput, { className: { container: `mb-2 ${error ? 'border-danger' : null}` }, left: [_jsx(Icon, { type: "email", className: "text-neutral" })], type: "email", placeholder: "Email", value: email, onChange: handleEmailChange, onFocus: resetInvalid, onKeyDown: handleKeyDown, disabled: isSigningIn || isPrivateUsername, ref: emailInputRef, spellcheck: false }), _jsx(DecoratedPasswordInput, { className: { container: `mb-2 ${error ? 'border-danger' : null}` }, disabled: isSigningIn, left: [_jsx(Icon, { type: "password", className: "text-neutral" })], onChange: handlePasswordChange, onFocus: resetInvalid, onKeyDown: handleKeyDown, placeholder: "Password", ref: passwordInputRef, value: password }), error ? _jsx("div", { className: "my-2 text-danger", children: error }) : null, _jsx(Button, { className: "mb-3 mt-1", label: isSigningIn ? 'Signing in...' : 'Sign in', primary: true, onClick: handleSignInFormSubmit, disabled: isSigningIn, fullWidth: true }), _jsx(Checkbox, { name: "is-ephemeral", label: "Stay signed in", checked: !isEphemeral, disabled: isSigningIn || isRecoverySignIn, onChange: handleEphemeralChange }), notesAndTagsCount > 0 ? (_jsx(Checkbox, { name: "should-merge-local", label: `Merge local data (${notesAndTagsCount} notes and tags)`, checked: shouldMergeLocal, disabled: isSigningIn, onChange: handleShouldMergeChange })) : null] }), _jsx(HorizontalSeparator, { classes: "my-2" }), _jsx(AdvancedOptions, { disabled: isSigningIn, onPrivateUsernameModeChange: onPrivateUsernameChange, onStrictSignInChange: handleStrictSigninChange, onRecoveryCodesChange: onRecoveryCodesChange })] }));
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-3 mt-1 flex items-center px-3", children: [_jsx(IconButton, { icon: "arrow-left", title: "Go back", className: "mr-2 flex p-0 text-neutral", onClick: () => setMenuPane(AccountMenuPane.GeneralMenu), focusable: true, disabled: isSigningIn }), _jsx("div", { className: "text-base font-bold", children: showCaptcha ? 'Human verification' : 'Sign in' })] }), showCaptcha ? _jsx("div", { className: "p-[10px]", children: captchaIframe }) : signInForm] }));
};
export default observer(SignInPane);
