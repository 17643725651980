import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CompoundPredicateBuilder from '@/Components/SmartViewBuilder/CompoundPredicateBuilder';
import Icon from '@/Components/Icon/Icon';
import IconPicker from '@/Components/Icon/IconPicker';
import Popover from '@/Components/Popover/Popover';
import Spinner from '@/Components/Spinner/Spinner';
import { SmartViewDefaultIconName } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TabPanel from '../Tabs/TabPanel';
import { useTabState } from '../Tabs/useTabState';
import TabsContainer from '../Tabs/TabsContainer';
import CopyableCodeBlock from '../Shared/CopyableCodeBlock';
import { classNames } from '@standardnotes/utils';
import Modal from '../Modal/Modal';
import { Disclosure, DisclosureContent, useDisclosureStore } from '@ariakit/react';
const ConflictedNotesExampleCode = `{
  "keypath": "content.conflict_of.length",
  "operator": ">",
  "value": 0
}`;
const ComplexCompoundExampleCode = `{
  "operator": "and",
  "value": [
    {
      "operator": "not",
      "value": {
        "keypath": "tags",
        "operator": "includes",
        "value": {
          "keypath": "title",
          "operator": "=",
          "value": "completed"
        }
      }
    },
    {
      "keypath": "tags",
      "operator": "includes",
      "value": {
        "keypath": "title",
        "operator": "=",
        "value": "todo"
      }
    }
  ]
}
`;
const AddSmartViewModal = ({ controller, platform }) => {
    const { isSaving, title, setTitle, icon, setIcon, closeModal, saveCurrentSmartView, predicateController, customPredicateJson, setCustomPredicateJson, isCustomJsonValidPredicate, setIsCustomJsonValidPredicate, validateAndPrettifyCustomPredicate, } = controller;
    const titleInputRef = useRef(null);
    const customJsonInputRef = useRef(null);
    const [shouldShowIconPicker, setShouldShowIconPicker] = useState(false);
    const iconPickerButtonRef = useRef(null);
    const jsonExamplesDisclosure = useDisclosureStore();
    const showingJsonExamples = jsonExamplesDisclosure.useState('open');
    const toggleIconPicker = () => {
        setShouldShowIconPicker((shouldShow) => !shouldShow);
    };
    const tabState = useTabState({
        defaultTab: 'builder',
    });
    const save = useCallback(() => {
        var _a;
        if (!title.length) {
            (_a = titleInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (tabState.activeTab === 'custom' && !isCustomJsonValidPredicate) {
            validateAndPrettifyCustomPredicate();
            return;
        }
        void saveCurrentSmartView();
    }, [
        isCustomJsonValidPredicate,
        saveCurrentSmartView,
        tabState.activeTab,
        title.length,
        validateAndPrettifyCustomPredicate,
    ]);
    const canSave = tabState.activeTab === 'builder' || isCustomJsonValidPredicate;
    useEffect(() => {
        if (!customJsonInputRef.current) {
            return;
        }
        if (tabState.activeTab === 'custom' && isCustomJsonValidPredicate === false) {
            customJsonInputRef.current.focus();
        }
    }, [isCustomJsonValidPredicate, tabState.activeTab]);
    const modalActions = useMemo(() => [
        {
            label: 'Cancel',
            onClick: closeModal,
            disabled: isSaving,
            type: 'cancel',
            mobileSlot: 'left',
        },
        {
            label: isSaving ? _jsx(Spinner, { className: "h-4.5 w-4.5" }) : canSave ? 'Save' : 'Validate',
            onClick: save,
            disabled: isSaving,
            mobileSlot: 'right',
            type: 'primary',
        },
    ], [canSave, closeModal, isSaving, save]);
    return (_jsx(Modal, { title: "Add Smart View", close: closeModal, actions: modalActions, children: _jsx("div", { className: "px-4 py-4", children: _jsxs("div", { className: "flex h-full flex-col gap-4", children: [_jsxs("div", { className: "flex items-center gap-2.5", children: [_jsx("div", { className: "text-sm font-semibold", children: "Title:" }), _jsx("input", { className: "rounded border border-border bg-default px-2 py-1 md:translucent-ui:bg-transparent", value: title, onChange: (event) => {
                                    setTitle(event.target.value);
                                }, ref: titleInputRef })] }), _jsxs("div", { className: "flex items-center gap-2.5", children: [_jsx("div", { className: "text-sm font-semibold", children: "Icon:" }), _jsx("button", { className: "rounded border border-border p-2", "aria-label": "Change icon", onClick: toggleIconPicker, ref: iconPickerButtonRef, children: _jsx(Icon, { type: icon || SmartViewDefaultIconName }) }), _jsx(Popover, { title: "Choose icon", open: shouldShowIconPicker, anchorElement: iconPickerButtonRef, togglePopover: toggleIconPicker, align: "start", overrideZIndex: "z-modal", children: _jsx("div", { className: "p-2", children: _jsx(IconPicker, { selectedValue: icon || SmartViewDefaultIconName, onIconChange: (value) => {
                                            setIcon(value !== null && value !== void 0 ? value : SmartViewDefaultIconName);
                                            toggleIconPicker();
                                        }, platform: platform, useIconGrid: true }) }) })] }), _jsxs("div", { className: "flex flex-grow flex-col gap-2.5", children: [_jsx("div", { className: "text-sm font-semibold", children: "Predicate:" }), _jsxs(TabsContainer, { className: "flex flex-grow flex-col", tabs: [
                                    {
                                        id: 'builder',
                                        title: 'Builder',
                                    },
                                    {
                                        id: 'custom',
                                        title: 'Custom (JSON)',
                                    },
                                ], state: tabState, children: [_jsx(TabPanel, { state: tabState, id: "builder", className: "flex flex-col gap-2.5 p-4", children: _jsx(CompoundPredicateBuilder, { controller: predicateController }) }), _jsxs(TabPanel, { state: tabState, id: "custom", className: "flex flex-grow flex-col", children: [_jsx("textarea", { className: "h-full min-h-[10rem] w-full flex-grow resize-none bg-default px-2.5 py-1.5 font-mono text-sm", value: customPredicateJson, onChange: (event) => {
                                                    setCustomPredicateJson(event.target.value);
                                                    setIsCustomJsonValidPredicate(undefined);
                                                }, spellCheck: false, ref: customJsonInputRef }), customPredicateJson && isCustomJsonValidPredicate === false && (_jsx("div", { className: "border-t border-border px-2.5 py-1.5 text-sm text-danger", children: "Invalid JSON. Double check your entry and try again." }))] })] }), tabState.activeTab === 'custom' && (_jsxs("div", { className: "flex flex-col gap-1.5 rounded-md border-2 border-info-backdrop bg-info-backdrop px-4 py-3", children: [_jsxs(Disclosure, { store: jsonExamplesDisclosure, className: "flex items-center justify-between focus:shadow-none focus:outline-none", children: [_jsx("div", { className: "text-sm font-semibold", children: "Examples" }), _jsx(Icon, { type: showingJsonExamples ? 'chevron-up' : 'chevron-down' })] }), _jsxs(DisclosureContent, { store: jsonExamplesDisclosure, className: classNames(showingJsonExamples && 'flex', 'flex-col gap-2.5'), children: [_jsx("div", { className: "text-sm font-medium", children: "1. List notes that are conflicted copies of another note:" }), _jsx(CopyableCodeBlock, { code: ConflictedNotesExampleCode }), _jsx("div", { className: "text-sm font-medium", children: "2. List notes that have the tag `todo` but not the tag `completed`:" }), _jsx(CopyableCodeBlock, { code: ComplexCompoundExampleCode })] })] }))] })] }) }) }));
};
export default observer(AddSmartViewModal);
