import { jsx as _jsx } from "react/jsx-runtime";
import OfflineSubscription from '@/Components/Preferences/Panes/General/Offline/OfflineSubscription';
import { observer } from 'mobx-react-lite';
import AccordionItem from '@/Components/Shared/AccordionItem';
import PreferencesGroup from '../../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../../PreferencesComponents/PreferencesSegment';
import { Platform } from '@standardnotes/snjs';
import { useApplication } from '@/Components/ApplicationProvider';
const OfflineActivation = () => {
    const application = useApplication();
    const shouldShowOfflineSubscription = () => {
        return (!application.hasAccount() ||
            !application.sessions.isSignedIntoFirstPartyServer() ||
            application.features.hasOfflineRepo());
    };
    if (!shouldShowOfflineSubscription()) {
        return null;
    }
    return (_jsx(PreferencesGroup, { children: _jsx(PreferencesSegment, { children: _jsx(AccordionItem, { title: 'Offline activation', children: _jsx("div", { className: "flex flex-row items-center", children: _jsx("div", { className: "flex max-w-full flex-grow flex-col", children: application.platform !== Platform.Ios && _jsx(OfflineSubscription, { application: application }) }) }) }) }) }));
};
export default observer(OfflineActivation);
