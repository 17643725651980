import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useApplication } from '@/Components/ApplicationProvider';
import Icon from '@/Components/Icon/Icon';
import Button from '@/Components/Button/Button';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import DesignateSurvivorModal from './DesignateSurvivorModal';
export const VaultModalMembers = ({ members, isCurrentUserAdmin, vault, onChange, }) => {
    const application = useApplication();
    const removeMemberFromVault = useCallback(async (memberItem) => {
        if (vault.isSharedVaultListing()) {
            await application.vaultUsers.removeUserFromSharedVault(vault, memberItem.user_uuid);
            onChange();
        }
    }, [application.vaultUsers, vault, onChange]);
    const vaultHasNoDesignatedSurvivor = vault.isSharedVaultListing() && !vault.sharing.designatedSurvivor;
    const [isDesignateSurvivorModalOpen, setIsDesignateSurvivorModalOpen] = useState(false);
    const openDesignateSurvivorModal = () => setIsDesignateSurvivorModalOpen(true);
    const closeDesignateSurvivorModal = () => setIsDesignateSurvivorModalOpen(false);
    return (_jsxs("div", { children: [_jsx("div", { className: "mb-3 text-lg", children: "Vault Members" }), vaultHasNoDesignatedSurvivor && members.length > 1 && isCurrentUserAdmin && (_jsxs("div", { className: "bg-danger-faded mb-3 grid grid-cols-[auto,1fr] gap-x-[0.65rem] gap-y-0.5 overflow-hidden rounded p-2.5 text-danger", children: [_jsx(Icon, { type: "warning", className: "place-self-center" }), _jsx("div", { className: "text-base font-semibold", children: "No designated survivor" }), _jsx("div", { className: "col-start-2", children: "Vaults that have no designated survivor will be deleted when the owner account is deleted. In order to ensure that no data is lost, please designate a survivor who will be transferred ownership of the vault." }), _jsx(Button, { small: true, className: "col-start-2 mt-1.5", onClick: openDesignateSurvivorModal, children: "Designate survivor" }), _jsx(ModalOverlay, { isOpen: isDesignateSurvivorModalOpen, close: closeDesignateSurvivorModal, children: _jsx(DesignateSurvivorModal, { vault: vault, members: members, closeModal: closeDesignateSurvivorModal }) })] })), _jsx("div", { className: "space-y-3.5", children: members.map((member) => {
                    const isMemberVaultOwner = application.vaultUsers.isVaultUserOwner(member);
                    const contact = application.contacts.findContactForServerUser(member);
                    const permission = application.vaultUsers.getFormattedMemberPermission(member.permission);
                    return (_jsxs("div", { className: "grid grid-cols-[auto,1fr] gap-x-[0.65rem] gap-y-0.5 text-base font-medium md:text-sm", children: [_jsx(Icon, { type: "user", className: "col-start-1 col-end-2 place-self-center" }), _jsxs("div", { className: "flex items-center gap-2 overflow-hidden text-ellipsis text-base font-bold", children: [_jsx("span", { children: (contact === null || contact === void 0 ? void 0 : contact.name) || member.user_uuid }), contact ? (_jsxs("div", { className: "flex items-center gap-1 rounded bg-success px-1 py-0.5 text-xs text-success-contrast", children: [_jsx(Icon, { type: "check-circle", size: "small" }), "Trusted"] })) : (_jsxs("div", { className: "flex items-center gap-1 rounded bg-danger px-1 py-0.5 pr-1.5 text-xs text-danger-contrast", children: [_jsx(Icon, { type: "clear-circle-filled", size: "small" }), "Untrusted"] })), member.is_designated_survivor && (_jsxs("div", { className: "flex items-center gap-1 rounded bg-info px-1 py-0.5 text-xs text-success-contrast", children: [_jsx(Icon, { type: "security", size: "small" }), "Designated survivor"] }))] }), _jsx("div", { className: "col-start-2 row-start-2", children: permission }), isCurrentUserAdmin && !isMemberVaultOwner && (_jsx(Button, { className: "col-start-2 row-start-3 mt-1", label: "Remove From Vault", onClick: () => removeMemberFromVault(member), small: true }))] }, (contact === null || contact === void 0 ? void 0 : contact.uuid) || member.user_uuid));
                }) })] }));
};
