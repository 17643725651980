import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import { ContentType, isSystemView } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Title } from '../../../PreferencesComponents/Content';
import PreferencesGroup from '../../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../../PreferencesComponents/PreferencesSegment';
import AddSmartViewModal from '@/Components/SmartViewBuilder/AddSmartViewModal';
import { AddSmartViewModalController } from '@/Components/SmartViewBuilder/AddSmartViewModalController';
import EditSmartViewModal from './EditSmartViewModal';
import SmartViewItem from './SmartViewItem';
import NoSubscriptionBanner from '@/Components/NoSubscriptionBanner/NoSubscriptionBanner';
import { EditSmartViewModalController } from './EditSmartViewModalController';
import { STRING_DELETE_TAG } from '@/Constants/Strings';
import { confirmDialog } from '@standardnotes/ui-services';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
const SmartViews = ({ application, featuresController }) => {
    const addSmartViewModalController = useMemo(() => new AddSmartViewModalController(application), [application]);
    const editSmartViewModalController = useMemo(() => new EditSmartViewModalController(application), [application]);
    const [smartViews, setSmartViews] = useState(() => application.items.getSmartViews().filter((view) => !isSystemView(view)));
    useEffect(() => {
        const disposeItemStream = application.items.streamItems([ContentType.TYPES.SmartView], () => {
            setSmartViews(application.items.getSmartViews().filter((view) => !isSystemView(view)));
        });
        return disposeItemStream;
    }, [application]);
    const deleteItem = useCallback(async (view) => {
        const shouldDelete = await confirmDialog({
            text: STRING_DELETE_TAG,
            confirmButtonStyle: 'danger',
        });
        if (shouldDelete) {
            application.mutator
                .deleteItem(view)
                .then(() => application.sync.sync())
                .catch(console.error);
        }
    }, [application.mutator, application.sync]);
    return (_jsxs(_Fragment, { children: [_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Smart Views" }), !featuresController.hasSmartViews && (_jsx(NoSubscriptionBanner, { className: "mt-2", application: application, title: "Upgrade for smart views", message: "Create smart views to organize your notes according to conditions you define." })), featuresController.hasSmartViews && (_jsxs(_Fragment, { children: [_jsx("div", { className: "my-2 flex flex-col", children: smartViews.map((view) => (_jsx(SmartViewItem, { view: view, onEdit: () => editSmartViewModalController.setView(view), onDelete: deleteItem }, view.uuid))) }), _jsx(Button, { onClick: () => {
                                        addSmartViewModalController.setIsAddingSmartView(true);
                                    }, children: "Create Smart View" })] }))] }) }), _jsx(ModalOverlay, { isOpen: !!editSmartViewModalController.view, close: editSmartViewModalController.closeDialog, children: _jsx(EditSmartViewModal, { controller: editSmartViewModalController, platform: application.platform }) }), _jsx(ModalOverlay, { isOpen: addSmartViewModalController.isAddingSmartView, close: addSmartViewModalController.closeModal, children: _jsx(AddSmartViewModal, { controller: addSmartViewModalController, platform: application.platform }) })] }));
};
export default observer(SmartViews);
