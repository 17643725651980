import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { SNTag } from '@standardnotes/snjs';
import TagContextMenu from './TagContextMenu';
const TagContextMenuWrapper = ({ navigationController, featuresController }) => {
    const selectedTag = navigationController.contextMenuTag;
    if (!selectedTag || !(selectedTag instanceof SNTag)) {
        return null;
    }
    return (_jsx(TagContextMenu, { navigationController: navigationController, isEntitledToFolders: featuresController.hasFolders, selectedTag: selectedTag }));
};
export default observer(TagContextMenuWrapper);
