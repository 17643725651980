"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canBlockDeinit = exports.isDeinitable = void 0;
function isDeinitable(service) {
    if (!service) {
        throw new Error('Service is undefined');
    }
    return typeof service.deinit === 'function';
}
exports.isDeinitable = isDeinitable;
function canBlockDeinit(service) {
    if (!service) {
        throw new Error('Service is undefined');
    }
    return typeof service.blockDeinit === 'function';
}
exports.canBlockDeinit = canBlockDeinit;
