import { jsx as _jsx } from "react/jsx-runtime";
import { mergeRefs } from '@/Hooks/mergeRefs';
import { Dialog, useDialogStore } from '@ariakit/react';
import { forwardRef, useCallback, useId } from 'react';
import { useModalAnimation } from '../Modal/useModalAnimation';
import { useMediaQuery, MutuallyExclusiveMediaQueryBreakpoints } from '@/Hooks/useMediaQuery';
import { classNames } from '@standardnotes/snjs';
const ModalOverlay = forwardRef(({ isOpen, children, animationVariant, close, className, backdropClassName, animate, ...props }, ref) => {
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const [isMounted, setElement] = useModalAnimation(isOpen, isMobileScreen, animationVariant, (animate === 'mobile' && !isMobileScreen) || (animate === 'desktop' && isMobileScreen));
    const dialog = useDialogStore({
        open: isMounted,
        setOpen: (open) => {
            if (!open) {
                close();
            }
        },
        animated: !isMobileScreen,
    });
    const portalId = useId();
    const getPortalElement = useCallback(() => {
        const id = 'portal/' + portalId;
        const existing = document.getElementById(id);
        if (existing) {
            existing.remove();
        }
        const div = document.createElement('div');
        div.id = id;
        div.className = 'fixed flex items-center justify-center left-0 top-0 z-modal h-full w-full pointer-events-none';
        div.setAttribute('data-dialog-portal', '');
        document.body.appendChild(div);
        return div;
    }, [portalId]);
    const addCloseMethod = useCallback((element) => {
        if (element) {
            ;
            element.close = close;
        }
    }, [close]);
    if (!isMounted) {
        return null;
    }
    return (_jsx(Dialog, { tabIndex: 0, className: classNames('pointer-events-auto z-[1] m-0 flex h-full w-full flex-col border-[--popover-border-color] bg-default p-0 md:h-auto md:max-h-[85vh] md:w-160 md:rounded md:border md:bg-[--popover-background-color] md:shadow-main md:[backdrop-filter:var(--popover-backdrop-filter)]', 'focus-visible:shadow-none focus-visible:outline-none', className), backdrop: _jsx("div", { className: classNames('pointer-events-auto absolute z-0 h-full w-full bg-passive-5 opacity-0', 'md:opacity-50 md:transition-opacity md:duration-75 [&[data-enter]]:md:opacity-75', backdropClassName), onClick: close }), ref: mergeRefs([setElement, addCloseMethod, ref]), store: dialog, modal: false, portal: true, portalElement: getPortalElement, preventBodyScroll: true, hideOnInteractOutside: false, ...props, children: children }));
});
export default ModalOverlay;
