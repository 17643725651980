import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { IlNotesIcon } from '@standardnotes/icons';
import { observer } from 'mobx-react-lite';
import NotesOptionsPanel from '@/Components/NotesOptions/NotesOptionsPanel';
import PinNoteButton from '@/Components/PinNoteButton/PinNoteButton';
import Button from '../Button/Button';
import { useCallback } from 'react';
import ChangeMultipleButton from '../ChangeEditor/ChangeMultipleButton';
const MultipleSelectedNotes = ({ application }) => {
    const { notesController, itemListController } = application;
    const count = notesController.selectedNotesCount;
    const cancelMultipleSelection = useCallback(() => {
        itemListController.cancelMultipleSelection();
    }, [itemListController]);
    return (_jsxs("div", { className: "flex h-full flex-col items-center", children: [_jsxs("div", { className: "flex w-full items-center justify-between p-4", children: [_jsxs("h1", { className: "m-0 text-lg font-bold", children: [count, " selected notes"] }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "mr-3", children: _jsx(ChangeMultipleButton, { application: application, notesController: notesController }) }), _jsx("div", { className: "mr-3", children: _jsx(PinNoteButton, { notesController: notesController }) }), _jsx(NotesOptionsPanel, { notesController: notesController })] })] }), _jsxs("div", { className: "flex min-h-full w-full max-w-md flex-grow flex-col items-center justify-center md:min-h-0", children: [_jsx(IlNotesIcon, { className: "block" }), _jsxs("h2", { className: "m-0 mt-4 text-center text-lg font-bold", children: [count, " selected notes"] }), _jsx("p", { className: "mt-2 max-w-60 text-center text-sm", children: "Actions will be performed on all selected notes." }), _jsx(Button, { className: "mt-2.5", onClick: cancelMultipleSelection, children: "Cancel multiple selection" })] })] }));
};
export default observer(MultipleSelectedNotes);
