"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentDirtyIndex = exports.getIncrementedDirtyIndex = void 0;
let dirtyIndex = 0;
function getIncrementedDirtyIndex() {
    dirtyIndex++;
    return dirtyIndex;
}
exports.getIncrementedDirtyIndex = getIncrementedDirtyIndex;
function getCurrentDirtyIndex() {
    return dirtyIndex;
}
exports.getCurrentDirtyIndex = getCurrentDirtyIndex;
