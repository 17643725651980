"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeFiltersForDisplayOptions = exports.computeUnifiedFilterForDisplayOptions = void 0;
const CompoundPredicate_1 = require("../Predicate/CompoundPredicate");
const ItemWithTags_1 = require("./Search/ItemWithTags");
const SearchUtilities_1 = require("./Search/SearchUtilities");
const SmartView_1 = require("../../Syncable/SmartView");
const domain_core_1 = require("@standardnotes/domain-core");
function computeUnifiedFilterForDisplayOptions(options, collection, additionalFilters = []) {
    const filters = computeFiltersForDisplayOptions(options, collection).concat(additionalFilters);
    return (item) => {
        return (0, SearchUtilities_1.itemPassesFilters)(item, filters);
    };
}
exports.computeUnifiedFilterForDisplayOptions = computeUnifiedFilterForDisplayOptions;
function computeFiltersForDisplayOptions(options, collection) {
    var _a;
    const filters = [];
    let viewsPredicate = undefined;
    if (options.views && options.views.length > 0) {
        const compoundPredicate = new CompoundPredicate_1.CompoundPredicate('and', options.views.map((t) => t.predicate));
        viewsPredicate = compoundPredicate;
        filters.push((item) => {
            if (compoundPredicate.keypathIncludesString('tags')) {
                const noteWithTags = ItemWithTags_1.ItemWithTags.Create(item.payload, item, collection.elementsReferencingElement(item, domain_core_1.ContentType.TYPES.Tag));
                return compoundPredicate.matchesItem(noteWithTags);
            }
            else {
                return compoundPredicate.matchesItem(item);
            }
        });
    }
    if (options.tags && options.tags.length > 0) {
        for (const tag of options.tags) {
            filters.push((item) => tag.isReferencingItem(item));
        }
    }
    if (options.includePinned === false && !(viewsPredicate === null || viewsPredicate === void 0 ? void 0 : viewsPredicate.keypathIncludesString('pinned'))) {
        filters.push((item) => !item.pinned);
    }
    if (options.includeProtected === false && !(viewsPredicate === null || viewsPredicate === void 0 ? void 0 : viewsPredicate.keypathIncludesString('protected'))) {
        filters.push((item) => !item.protected);
    }
    if (options.includeTrashed === false && !(viewsPredicate === null || viewsPredicate === void 0 ? void 0 : viewsPredicate.keypathIncludesString('trashed'))) {
        filters.push((item) => !item.trashed);
    }
    if (options.includeArchived === false && !(viewsPredicate === null || viewsPredicate === void 0 ? void 0 : viewsPredicate.keypathIncludesString('archived'))) {
        filters.push((item) => !item.archived);
    }
    if (options.searchQuery) {
        const query = options.searchQuery;
        filters.push((item) => (0, SearchUtilities_1.itemMatchesQuery)(item, query, collection));
    }
    if (!(viewsPredicate === null || viewsPredicate === void 0 ? void 0 : viewsPredicate.keypathIncludesString('conflict_of')) &&
        !((_a = options.views) === null || _a === void 0 ? void 0 : _a.some((v) => v.uuid === SmartView_1.SystemViewId.TrashedNotes))) {
        filters.push((item) => !item.conflictOf);
    }
    return filters;
}
exports.computeFiltersForDisplayOptions = computeFiltersForDisplayOptions;
