import { InternalEventPublishStrategy, removeFromArray } from '@standardnotes/snjs';
export class AbstractViewController {
    constructor(eventBus) {
        this.eventBus = eventBus;
        this.dealloced = false;
        this.disposers = [];
        this.eventObservers = [];
    }
    async publishCrossControllerEventSync(name, data) {
        await this.eventBus.publishSync({ type: name, payload: data }, InternalEventPublishStrategy.SEQUENCE);
    }
    deinit() {
        this.dealloced = true;
        this.eventBus = undefined;
        for (const disposer of this.disposers) {
            disposer();
        }
        ;
        this.disposers = undefined;
        this.eventObservers.length = 0;
    }
    addEventObserver(observer) {
        this.eventObservers.push(observer);
        return () => {
            removeFromArray(this.eventObservers, observer);
        };
    }
    notifyEvent(event, data) {
        this.eventObservers.forEach((observer) => observer(event, data));
    }
}
