"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itemMatchesQuery = exports.itemPassesFilters = exports.notesAndFilesMatchingOptions = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const DisplayOptionsToFilters_1 = require("../DisplayOptionsToFilters");
var MatchResult;
(function (MatchResult) {
    MatchResult[MatchResult["None"] = 0] = "None";
    MatchResult[MatchResult["Title"] = 1] = "Title";
    MatchResult[MatchResult["Text"] = 2] = "Text";
    MatchResult[MatchResult["TitleAndText"] = 3] = "TitleAndText";
    MatchResult[MatchResult["Uuid"] = 5] = "Uuid";
})(MatchResult || (MatchResult = {}));
function notesAndFilesMatchingOptions(options, fromItems, collection) {
    const filters = (0, DisplayOptionsToFilters_1.computeFiltersForDisplayOptions)(options, collection);
    return fromItems.filter((item) => {
        return itemPassesFilters(item, filters);
    });
}
exports.notesAndFilesMatchingOptions = notesAndFilesMatchingOptions;
function itemPassesFilters(item, filters) {
    for (const filter of filters) {
        if (!filter(item)) {
            return false;
        }
    }
    return true;
}
exports.itemPassesFilters = itemPassesFilters;
function itemMatchesQuery(itemToMatch, searchQuery, collection) {
    const itemTags = collection.elementsReferencingElement(itemToMatch, domain_core_1.ContentType.TYPES.Tag);
    const someTagsMatches = itemTags.some((tag) => matchResultForStringQuery(tag, searchQuery.query) !== MatchResult.None);
    if (itemToMatch.protected && !searchQuery.includeProtectedNoteText) {
        const match = matchResultForStringQuery(itemToMatch, searchQuery.query);
        return match === MatchResult.Title || match === MatchResult.TitleAndText || someTagsMatches;
    }
    return matchResultForStringQuery(itemToMatch, searchQuery.query) !== MatchResult.None || someTagsMatches;
}
exports.itemMatchesQuery = itemMatchesQuery;
function matchResultForStringQuery(item, searchString) {
    var _a, _b;
    if (searchString.length === 0) {
        return MatchResult.TitleAndText;
    }
    const title = (_a = item.title) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    const text = (_b = item.text) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    const lowercaseText = searchString.toLowerCase();
    const words = lowercaseText.split(' ');
    const quotedText = stringBetweenQuotes(lowercaseText);
    if (quotedText) {
        return (((title === null || title === void 0 ? void 0 : title.includes(quotedText)) ? MatchResult.Title : MatchResult.None) +
            ((text === null || text === void 0 ? void 0 : text.includes(quotedText)) ? MatchResult.Text : MatchResult.None));
    }
    if (stringIsUuid(lowercaseText)) {
        return item.uuid === lowercaseText ? MatchResult.Uuid : MatchResult.None;
    }
    const matchesTitle = title &&
        words.every((word) => {
            return title.indexOf(word) >= 0;
        });
    const matchesBody = text &&
        words.every((word) => {
            return text.indexOf(word) >= 0;
        });
    return (matchesTitle ? MatchResult.Title : 0) + (matchesBody ? MatchResult.Text : 0);
}
function stringBetweenQuotes(text) {
    const matches = text.match(/"(.*?)"/);
    return matches ? matches[1] : null;
}
function stringIsUuid(text) {
    const matches = text.match(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/);
    return matches ? true : false;
}
