import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '../Icon/Icon';
import DecoratedInput from '../Input/DecoratedInput';
import { observer } from 'mobx-react-lite';
import ClearInputButton from '../ClearInputButton/ClearInputButton';
import { useCallback, useEffect, useRef, useState } from 'react';
import { classNames } from '@standardnotes/snjs';
const TagSearchBar = ({ navigationController }) => {
    const { searchQuery, setSearchQuery } = navigationController;
    const inputRef = useRef(null);
    const onClearSearch = useCallback(() => {
        var _a;
        setSearchQuery('');
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [setSearchQuery]);
    const [isParentScrolling, setIsParentScrolling] = useState(false);
    const searchBarRef = useRef(null);
    useEffect(() => {
        const searchBar = searchBarRef.current;
        if (!searchBar) {
            return;
        }
        const parent = searchBar.parentElement;
        if (!parent) {
            return;
        }
        const scrollListener = () => {
            const { scrollTop } = parent;
            setIsParentScrolling(scrollTop > 0);
        };
        parent.addEventListener('scroll', scrollListener);
        return () => {
            parent.removeEventListener('scroll', scrollListener);
        };
    }, []);
    return (_jsx("div", { className: classNames('sticky top-0 bg-[inherit] px-4 pt-4', isParentScrolling &&
            'after:absolute after:left-0 after:top-full after:-z-[1] after:block after:h-4 after:w-full after:border-b after:border-border after:bg-[inherit]'), role: "search", ref: searchBarRef, children: _jsx(DecoratedInput, { ref: inputRef, autocomplete: false, className: {
                container: '!bg-default px-1',
                input: 'text-base placeholder:text-passive-0 lg:text-sm',
            }, placeholder: 'Search tags...', value: searchQuery, onChange: setSearchQuery, left: [_jsx(Icon, { type: "search", className: "mr-1 h-4.5 w-4.5 flex-shrink-0 text-passive-1" })], right: [searchQuery && _jsx(ClearInputButton, { onClick: onClearSearch })], roundedFull: true }) }));
};
export default observer(TagSearchBar);
