import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import ListedMenuItem from './ListedMenuItem';
import Spinner from '@/Components/Spinner/Spinner';
import MenuSection from '@/Components/Menu/MenuSection';
const ListedActionsMenu = ({ application, note }) => {
    const [menuGroups, setMenuGroups] = useState([]);
    const [isFetchingAccounts, setIsFetchingAccounts] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    useEffect(() => {
        const authorize = async () => {
            if (!application.listed.isNoteAuthorizedForListed(note)) {
                await application.listed.authorizeNoteForListed(note);
            }
            setIsAuthorized(application.listed.isNoteAuthorizedForListed(note));
        };
        void authorize();
    }, [application, note]);
    const reloadMenuGroup = useCallback(async (group) => {
        if (!isAuthorized) {
            return;
        }
        const updatedAccountInfo = await application.listed.getListedAccountInfo(group.account, note.uuid);
        if (!updatedAccountInfo) {
            return;
        }
        const updatedGroup = {
            name: updatedAccountInfo.display_name,
            account: group.account,
            actions: updatedAccountInfo.actions,
        };
        const updatedGroups = menuGroups.map((group) => {
            if (updatedGroup.account.authorId === group.account.authorId) {
                return updatedGroup;
            }
            else {
                return group;
            }
        });
        setMenuGroups(updatedGroups);
    }, [application, menuGroups, note, isAuthorized]);
    useEffect(() => {
        const fetchListedAccounts = async () => {
            if (!application.hasAccount()) {
                setIsFetchingAccounts(false);
                return;
            }
            if (!isAuthorized) {
                return;
            }
            try {
                const listedAccountEntries = await application.listed.getListedAccounts();
                if (!listedAccountEntries.length) {
                    throw new Error('No Listed accounts found');
                }
                const menuGroups = [];
                await Promise.all(listedAccountEntries.map(async (account) => {
                    const accountInfo = await application.listed.getListedAccountInfo(account, note.uuid);
                    if (accountInfo) {
                        menuGroups.push({
                            name: accountInfo.display_name,
                            account,
                            actions: accountInfo.actions,
                        });
                    }
                    else {
                        menuGroups.push({
                            name: account.authorId,
                            account,
                            actions: [],
                        });
                    }
                }));
                setMenuGroups(menuGroups.sort((a, b) => {
                    return a.name.toString().toLowerCase() < b.name.toString().toLowerCase() ? -1 : 1;
                }));
            }
            catch (err) {
                console.error(err);
            }
            finally {
                setIsFetchingAccounts(false);
            }
        };
        void fetchListedAccounts();
    }, [application, note.uuid, isAuthorized]);
    if (!isAuthorized) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [isFetchingAccounts && (_jsx("div", { className: "flex w-full items-center justify-center p-4", children: _jsx(Spinner, { className: "h-5 w-5" }) })), !isFetchingAccounts && menuGroups.length ? (_jsx(_Fragment, { children: menuGroups.map((group) => (_jsx(MenuSection, { title: _jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { type: "notes", className: "mr-2 text-info" }), " ", group.name] }), children: group.actions.length ? (group.actions.map((action) => (_jsx(ListedMenuItem, { action: action, note: note, group: group, application: application, reloadMenuGroup: reloadMenuGroup }, action.url)))) : (_jsx("div", { className: "select-none px-3 py-2 text-sm text-passive-0", children: "No actions available" })) }, group.account.authorId))) })) : null, !isFetchingAccounts && !menuGroups.length ? (_jsx("div", { className: "flex w-full items-center justify-center px-4 py-6", children: _jsx("div", { className: "select-none text-sm text-passive-0", children: "No Listed accounts found" }) })) : null] }));
};
export default ListedActionsMenu;
