import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Spinner from '@/Components/Spinner/Spinner';
const ListedMenuItem = ({ action, note, application, group, reloadMenuGroup, }) => {
    const [isRunning, setIsRunning] = useState(false);
    const handleClick = useCallback(async () => {
        if (isRunning) {
            return;
        }
        setIsRunning(true);
        await application.actions.runAction(action, note);
        setIsRunning(false);
        reloadMenuGroup(group).catch(console.error);
    }, [application, action, group, isRunning, note, reloadMenuGroup]);
    return (_jsx("button", { onClick: handleClick, className: "flex w-full cursor-pointer items-center border-0 bg-transparent px-3 py-2 text-left text-sm text-text hover:bg-contrast hover:text-foreground focus:bg-info-backdrop focus:shadow-none", children: _jsxs("div", { className: "flex w-full flex-row items-center justify-between", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "font-semibold", children: action.label }), action.access_type && (_jsxs("div", { className: "mt-0.5 text-xs text-passive-0", children: ['Uses ', _jsx("strong", { children: action.access_type }), ' access to this note.'] }))] }), isRunning && _jsx(Spinner, { className: "h-3 w-3" })] }) }, action.url));
};
export default ListedMenuItem;
