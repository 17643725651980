import { ValidAppViewRoutes } from './Params/AppViewRouteParams';
import { RootQueryParam } from './RootQueryParam';
import { RootRoutes } from './RootRoutes';
import { RouteType } from './RouteType';
export class RouteParser {
    constructor(url) {
        this.url = new URL(url);
        this.path = this.url.pathname;
        this.searchParams = this.url.searchParams;
        this.parsedType = this.parseTypeFromQueryParameters();
    }
    get type() {
        return this.parsedType;
    }
    get userRequestParams() {
        this.checkForProperRouteType(RouteType.UserRequest);
        return {
            requestType: this.searchParams.get(RootQueryParam.UserRequest),
        };
    }
    get subscriptionInviteParams() {
        this.checkForProperRouteType(RouteType.AcceptSubscriptionInvite);
        return {
            inviteUuid: this.searchParams.get(RootQueryParam.AcceptSubscriptionInvite),
        };
    }
    get demoParams() {
        this.checkForProperRouteType(RouteType.Demo);
        return {
            token: this.searchParams.get(RootQueryParam.DemoToken),
        };
    }
    get settingsParams() {
        this.checkForProperRouteType(RouteType.Settings);
        return {
            panel: this.searchParams.get(RootQueryParam.Settings),
        };
    }
    get purchaseParams() {
        this.checkForProperRouteType(RouteType.Purchase);
        return {
            plan: this.searchParams.get('plan'),
            period: this.searchParams.get('period'),
        };
    }
    get onboardingParams() {
        this.checkForProperRouteType(RouteType.Onboarding);
        return {
            fromHomepage: !!this.searchParams.get('from_homepage'),
        };
    }
    get appViewRouteParam() {
        this.checkForProperRouteType(RouteType.AppViewRoute);
        const appViewRoute = this.searchParams.get(RootQueryParam.AppViewRoute);
        if (!ValidAppViewRoutes.includes(appViewRoute)) {
            return;
        }
        return this.searchParams.get(RootQueryParam.AppViewRoute);
    }
    checkForProperRouteType(type) {
        if (this.parsedType !== type) {
            throw new Error('Accessing invalid params');
        }
    }
    parseTypeFromQueryParameters() {
        if (this.path === RootRoutes.Onboarding) {
            return RouteType.Onboarding;
        }
        const isValidPath = ['index.html', 'popup.html'].some((path) => this.path.endsWith(path));
        if (this.path !== RootRoutes.None && !isValidPath) {
            return RouteType.None;
        }
        const rootQueryParametersMap = new Map([
            [RootQueryParam.Purchase, RouteType.Purchase],
            [RootQueryParam.Settings, RouteType.Settings],
            [RootQueryParam.DemoToken, RouteType.Demo],
            [RootQueryParam.AcceptSubscriptionInvite, RouteType.AcceptSubscriptionInvite],
            [RootQueryParam.UserRequest, RouteType.UserRequest],
            [RootQueryParam.AppViewRoute, RouteType.AppViewRoute],
        ]);
        for (const rootQueryParam of rootQueryParametersMap.keys()) {
            if (this.searchParams.has(rootQueryParam)) {
                return rootQueryParametersMap.get(rootQueryParam);
            }
        }
        return RouteType.None;
    }
}
