import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import OtherSessionsSignOutContainer from '@/Components/OtherSessionsSignOut/OtherSessionsSignOut';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { observer } from 'mobx-react-lite';
import { Subtitle, Title, Text } from '../../PreferencesComponents/Content';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import ClearSessionDataView from './ClearSessionDataView';
const SignOutView = observer(({ application }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(PreferencesGroup, { children: [_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Sign out" }), _jsx(Subtitle, { children: "Other devices" }), _jsx(Text, { children: "Want to sign out on all devices except this one?" }), _jsxs("div", { className: "mt-3 flex flex-row flex-wrap gap-3", children: [_jsx(Button, { label: "Sign out other sessions", onClick: () => {
                                            application.accountMenuController.setOtherSessionsSignOut(true);
                                        } }), _jsx(Button, { label: "Manage sessions", onClick: () => application.openSessionsModal() })] })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs(PreferencesSegment, { children: [_jsx(Subtitle, { children: "This workspace" }), _jsx(Text, { children: "Remove all data related to the current workspace from the application." }), _jsx(Button, { className: "mt-3", colorStyle: "danger", label: "Sign out workspace", onClick: () => {
                                    application.accountMenuController.setSigningOut(true);
                                } })] })] }), _jsx(OtherSessionsSignOutContainer, { application: application })] }));
});
SignOutView.displayName = 'SignOutView';
const SignOutWrapper = ({ application }) => {
    if (!application.hasAccount()) {
        return _jsx(ClearSessionDataView, {});
    }
    return _jsx(SignOutView, { application: application });
};
export default observer(SignOutWrapper);
