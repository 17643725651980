import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DecoratedInput from '@/Components/Input/DecoratedInput';
import DecoratedPasswordInput from '@/Components/Input/DecoratedPasswordInput';
const labelClassName = 'block mb-1';
const ChangeEmailForm = ({ setNewEmail, setCurrentPassword }) => {
    return (_jsxs("div", { className: "flex w-full flex-col", children: [_jsxs("div", { className: "mb-3", children: [_jsx("label", { className: labelClassName, htmlFor: "change-email-email-input", children: "New Email" }), _jsx(DecoratedInput, { type: "email", id: "change-email-email-input", onChange: (newEmail) => {
                            setNewEmail(newEmail);
                        } })] }), _jsxs("div", { className: "mb-2", children: [_jsx("label", { className: labelClassName, htmlFor: "change-email-password-input", children: "Current Password" }), _jsx(DecoratedPasswordInput, { id: "change-email-password-input", type: "password", onChange: (currentPassword) => {
                            setCurrentPassword(currentPassword);
                        } })] })] }));
};
export default ChangeEmailForm;
