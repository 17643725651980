"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = void 0;
const utils_1 = require("@standardnotes/utils");
const lodash_1 = require("lodash");
class Collection {
    constructor(copy = false, mapCopy, typedMapCopy, referenceMapCopy, conflictMapCopy) {
        this.map = {};
        this.typedMap = {};
        /** An array of uuids of items that are dirty */
        this.dirtyIndex = new Set();
        /** An array of uuids of items that are not marked as deleted */
        this.nondeletedIndex = new Set();
        /** An array of uuids of items that are errorDecrypting or waitingForKey */
        this.invalidsIndex = new Set();
        this.isDecryptedElement = (e) => {
            return (0, utils_1.isObject)(e.content);
        };
        this.isEncryptedElement = (e) => {
            return 'content' in e && (0, utils_1.isString)(e.content);
        };
        this.isErrorDecryptingElement = (e) => {
            return this.isEncryptedElement(e);
        };
        this.isDeletedElement = (e) => {
            return 'deleted' in e && e.deleted === true;
        };
        this.isNonDeletedElement = (e) => {
            return !this.isDeletedElement(e);
        };
        if (copy) {
            this.map = mapCopy;
            this.typedMap = typedMapCopy;
            this.referenceMap = referenceMapCopy;
            this.conflictMap = conflictMapCopy;
        }
        else {
            this.referenceMap = new utils_1.UuidMap();
            this.conflictMap = new utils_1.UuidMap();
        }
    }
    uuids() {
        return Object.keys(this.map);
    }
    all(contentType) {
        var _a;
        if (contentType) {
            if (Array.isArray(contentType)) {
                const elements = [];
                for (const type of contentType) {
                    (0, utils_1.extendArray)(elements, this.typedMap[type] || []);
                }
                return elements;
            }
            else {
                return ((_a = this.typedMap[contentType]) === null || _a === void 0 ? void 0 : _a.slice()) || [];
            }
        }
        else {
            return Object.keys(this.map).map((uuid) => {
                return this.map[uuid];
            });
        }
    }
    /** Returns all elements that are not marked as deleted */
    nondeletedElements() {
        const uuids = Array.from(this.nondeletedIndex);
        return this.findAll(uuids).filter(this.isNonDeletedElement);
    }
    /** Returns all elements that are errorDecrypting or waitingForKey */
    invalidElements() {
        const uuids = Array.from(this.invalidsIndex);
        return this.findAll(uuids);
    }
    /** Returns all elements that are marked as dirty */
    dirtyElements() {
        const uuids = Array.from(this.dirtyIndex);
        return this.findAll(uuids);
    }
    findAll(uuids) {
        const results = [];
        for (const id of uuids) {
            const element = this.map[id];
            if (element) {
                results.push(element);
            }
        }
        return results;
    }
    find(uuid) {
        return this.map[uuid];
    }
    has(uuid) {
        return this.find(uuid) != undefined;
    }
    /**
     * If an item is not found, an `undefined` element
     * will be inserted into the array.
     */
    findAllIncludingBlanks(uuids) {
        const results = [];
        for (const id of uuids) {
            const element = this.map[id];
            results.push(element);
        }
        return results;
    }
    set(elements) {
        elements = Array.isArray(elements) ? elements : [elements];
        if (elements.length === 0) {
            console.warn('Attempting to set 0 elements onto collection');
            return;
        }
        for (const element of elements) {
            this.map[element.uuid] = element;
            this.setToTypedMap(element);
            if (this.isErrorDecryptingElement(element)) {
                this.invalidsIndex.add(element.uuid);
            }
            else {
                this.invalidsIndex.delete(element.uuid);
            }
            if (this.isDecryptedElement(element)) {
                const conflictOf = element.content.conflict_of;
                if (conflictOf && !element.content.trashed) {
                    this.conflictMap.establishRelationship(conflictOf, element.uuid);
                }
                const isConflictOfButTrashed = conflictOf && element.content.trashed;
                const isInConflictMapButIsNotConflictOf = !conflictOf && this.conflictMap.getInverseRelationships(element.uuid).length > 0;
                const isInConflictMapButDoesNotHaveConflicts = this.conflictMap.existsInDirectMap(element.uuid) &&
                    this.conflictMap.getDirectRelationships(element.uuid).length === 0;
                if (isInConflictMapButIsNotConflictOf || isInConflictMapButDoesNotHaveConflicts || isConflictOfButTrashed) {
                    this.conflictMap.removeFromMap(element.uuid);
                }
                this.referenceMap.setAllRelationships(element.uuid, element.references.map((r) => r.uuid));
            }
            if (element.dirty) {
                this.dirtyIndex.add(element.uuid);
            }
            else {
                this.dirtyIndex.delete(element.uuid);
            }
            if (element.deleted) {
                this.nondeletedIndex.delete(element.uuid);
                if (this.conflictMap.existsInDirectMap(element.uuid) || this.conflictMap.existsInInverseMap(element.uuid)) {
                    this.conflictMap.removeFromMap(element.uuid);
                }
            }
            else {
                this.nondeletedIndex.add(element.uuid);
            }
        }
    }
    discard(elements) {
        elements = Array.isArray(elements) ? elements : [elements];
        for (const element of elements) {
            this.deleteFromTypedMap(element);
            delete this.map[element.uuid];
            this.conflictMap.removeFromMap(element.uuid);
            this.referenceMap.removeFromMap(element.uuid);
        }
    }
    uuidReferencesForUuid(uuid) {
        return this.referenceMap.getDirectRelationships(uuid);
    }
    uuidsThatReferenceUuid(uuid) {
        return this.referenceMap.getInverseRelationships(uuid);
    }
    referencesForElement(element) {
        const uuids = this.referenceMap.getDirectRelationships(element.uuid);
        return this.findAll(uuids);
    }
    conflictsOf(uuid) {
        const uuids = this.conflictMap.getDirectRelationships(uuid);
        return this.findAll(uuids);
    }
    elementsReferencingElement(element, contentType) {
        const uuids = this.uuidsThatReferenceUuid(element.uuid);
        const items = this.findAll(uuids);
        if (!contentType) {
            return items;
        }
        return items.filter((item) => item.content_type === contentType);
    }
    setToTypedMap(element) {
        const array = this.typedMap[element.content_type] || [];
        (0, lodash_1.remove)(array, { uuid: element.uuid });
        array.push(element);
        this.typedMap[element.content_type] = array;
    }
    deleteFromTypedMap(element) {
        const array = this.typedMap[element.content_type] || [];
        (0, lodash_1.remove)(array, { uuid: element.uuid });
        this.typedMap[element.content_type] = array;
    }
    uuidsOfItemsWithConflicts() {
        return this.conflictMap.getAllDirectKeys();
    }
}
exports.Collection = Collection;
