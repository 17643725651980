import { jsx as _jsx } from "react/jsx-runtime";
import { ApplicationEvent } from '@standardnotes/snjs';
import { useCallback, useEffect, useState } from 'react';
import ModalOverlay from '../Modal/ModalOverlay';
import PermissionsModal from './PermissionsModal';
const PermissionsModalWrapper = ({ application }) => {
    const [dialog, setDialog] = useState();
    const presentPermissionsDialog = useCallback((permissionDialog) => {
        setDialog(permissionDialog);
    }, []);
    const dismissPermissionsDialog = useCallback(() => {
        setDialog(undefined);
    }, []);
    const onAppStart = useCallback(() => {
        application.componentManager.setPermissionDialogUIHandler(presentPermissionsDialog);
    }, [application, presentPermissionsDialog]);
    useEffect(() => {
        if (application.isStarted()) {
            onAppStart();
        }
        const removeAppObserver = application.addEventObserver(async (eventName) => {
            if (eventName === ApplicationEvent.Started) {
                onAppStart();
            }
        });
        return () => {
            removeAppObserver();
        };
    }, [application, onAppStart]);
    return (_jsx(ModalOverlay, { isOpen: !!dialog, close: dismissPermissionsDialog, className: "md:!w-[350px]", children: dialog && (_jsx(PermissionsModal, { callback: dialog.callback, dismiss: dismissPermissionsDialog, component: dialog.component, permissionsString: dialog.permissionsString })) }));
};
export default PermissionsModalWrapper;
