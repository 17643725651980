import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
const IconButton = ({ onClick, className = '', icon, title, focusable, iconClassName = '', disabled = false, }) => {
    const click = (e) => {
        e.preventDefault();
        onClick(e);
    };
    const focusableClass = focusable ? '' : 'focus:shadow-none';
    return (_jsx("button", { type: "button", title: title, className: `no-border flex cursor-pointer flex-row items-center bg-transparent ${focusableClass} ${className}`, onClick: click, disabled: disabled, "aria-label": title, children: _jsx(Icon, { type: icon, className: iconClassName }) }));
};
export default IconButton;
