import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import ImportModalFileItem from './ImportModalFileItem';
import ImportModalInitialPage from './InitialPage';
import Modal from '../Modal/Modal';
import ModalOverlay from '../Modal/ModalOverlay';
import { useApplication } from '../ApplicationProvider';
import Switch from '../Switch/Switch';
import LinkedItemBubble from '../LinkedItems/LinkedItemBubble';
import { createLinkFromItem } from '@/Utils/Items/Search/createLinkFromItem';
import ItemSelectionDropdown from '../ItemSelectionDropdown/ItemSelectionDropdown';
import { ContentType } from '@standardnotes/snjs';
import Button from '../Button/Button';
import { ClassicFileReader } from '@standardnotes/filepicker';
const ImportModal = ({ importModalController }) => {
    const application = useApplication();
    const { files, setFiles, addFiles, addImportsToTag, setAddImportsToTag, shouldCreateTag, setShouldCreateTag, existingTagForImports, setExistingTagForImports, updateFile, removeFile, parseAndImport, isVisible, close, } = importModalController;
    const isReadyToImport = files.length > 0 && files.every((file) => file.status === 'pending' && file.service);
    const importSuccessOrError = files.length > 0 && files.every((file) => file.status === 'finished' || file.status === 'error');
    const modalActions = useMemo(() => [
        {
            label: 'Import',
            type: 'primary',
            onClick: parseAndImport,
            hidden: !isReadyToImport,
            mobileSlot: 'right',
            disabled: !isReadyToImport || (!shouldCreateTag && !existingTagForImports),
        },
        {
            label: importSuccessOrError ? 'Close' : 'Cancel',
            type: 'cancel',
            onClick: close,
            mobileSlot: 'left',
        },
    ], [close, existingTagForImports, importSuccessOrError, isReadyToImport, parseAndImport, shouldCreateTag]);
    const selectFiles = useCallback(async (service) => {
        const files = await ClassicFileReader.selectFiles();
        addFiles(files, service);
    }, [addFiles]);
    return (_jsx(ModalOverlay, { isOpen: isVisible, close: close, children: _jsxs(Modal, { title: "Import", close: close, actions: modalActions, className: "flex flex-col", children: [_jsxs("div", { className: "min-h-0 flex-grow overflow-y-auto px-4 py-4", children: [!files.length && _jsx(ImportModalInitialPage, { setFiles: setFiles, selectFiles: selectFiles }), files.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: "divide-y divide-border", children: files.map((file) => (_jsx(ImportModalFileItem, { file: file, updateFile: updateFile, removeFile: removeFile, importer: application.importer }, file.id))) }), !importSuccessOrError && (_jsx(Button, { className: "mt-4", onClick: () => {
                                        selectFiles().catch(console.error);
                                    }, small: true, children: "Add files" }))] }))] }), files.length > 0 && (_jsxs("div", { className: "flex flex-col gap-3 border-t border-border px-4 py-4 md:gap-2 md:py-3", children: [_jsx(Switch, { className: "flex items-center gap-2", checked: addImportsToTag, onChange: setAddImportsToTag, children: _jsx("span", { className: "text-sm", children: "Add all imported notes to tag" }) }), addImportsToTag && (_jsxs(_Fragment, { children: [_jsxs("label", { className: "mt-1.5 flex items-center gap-2 text-sm", children: [_jsx("input", { type: "radio", name: "import-tag", className: "h-6 w-6 md:h-4 md:w-4", checked: shouldCreateTag, onChange: () => {
                                                setShouldCreateTag(true);
                                            } }), "Create new tag"] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsxs("label", { className: "flex items-center gap-2 text-sm", children: [_jsx("input", { type: "radio", name: "import-tag", className: "h-6 w-6 md:h-4 md:w-4", checked: !shouldCreateTag, onChange: () => {
                                                                setShouldCreateTag(false);
                                                            } }), "Add to existing tag"] }), existingTagForImports && (_jsx(LinkedItemBubble, { className: "m-1 mr-2", link: createLinkFromItem(existingTagForImports, 'linked'), unlinkItem: async () => {
                                                        setExistingTagForImports(undefined);
                                                    }, isBidirectional: false, inlineFlex: true }))] }), !shouldCreateTag && (_jsx("div", { className: "ml-8 md:ml-6", children: _jsx(ItemSelectionDropdown, { onSelection: (tag) => setExistingTagForImports(tag), placeholder: "Select tag to add imported notes to...", contentTypes: [ContentType.TYPES.Tag] }) }))] })] }))] }))] }) }));
};
export default observer(ImportModal);
