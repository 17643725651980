"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadsByUpdatingReferencingPayloadReferences = void 0;
const lodash_1 = require("lodash");
const Payload_1 = require("../../Abstract/Payload");
const DirtyCounter_1 = require("../../Runtime/DirtyCounter/DirtyCounter");
function PayloadsByUpdatingReferencingPayloadReferences(payload, baseCollection, add = [], removeIds = []) {
    const referencingPayloads = baseCollection.elementsReferencingElement(payload).filter(Payload_1.isDecryptedPayload);
    const results = [];
    for (const referencingPayload of referencingPayloads) {
        const references = referencingPayload.content.references.slice();
        const reference = referencingPayload.getReference(payload.uuid);
        for (const addPayload of add) {
            const newReference = Object.assign(Object.assign({}, reference), { uuid: addPayload.uuid, content_type: addPayload.content_type });
            references.push(newReference);
        }
        for (const id of removeIds) {
            (0, lodash_1.remove)(references, { uuid: id });
        }
        const result = referencingPayload.copyAsSyncResolved({
            dirty: true,
            dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
            lastSyncEnd: new Date(),
            content: Object.assign(Object.assign({}, referencingPayload.content), { references }),
        });
        results.push(result);
    }
    return results;
}
exports.PayloadsByUpdatingReferencingPayloadReferences = PayloadsByUpdatingReferencingPayloadReferences;
