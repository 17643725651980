"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SureFindPayload = exports.FindPayload = void 0;
function FindPayload(payloads, uuid) {
    return payloads.find((payload) => payload.uuid === uuid);
}
exports.FindPayload = FindPayload;
function SureFindPayload(payloads, uuid) {
    return FindPayload(payloads, uuid);
}
exports.SureFindPayload = SureFindPayload;
