import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VisuallyHidden, Radio, RadioGroup, useRadioStore } from '@ariakit/react';
import { classNames } from '@standardnotes/utils';
function RadioButtonGroup({ value, items, onChange, className }) {
    const radio = useRadioStore({
        value,
        orientation: 'horizontal',
        setValue(value) {
            onChange(value);
        },
    });
    return (_jsx(RadioGroup, { store: radio, className: `flex divide-x divide-border rounded border border-border md:translucent-ui:border-[--popover-border-color] ${className !== null && className !== void 0 ? className : ''}`, children: items.map(({ label, value: itemValue }) => (_jsxs("label", { className: classNames('flex-grow select-none px-3.5 py-1.5 text-center', 'first:rounded-bl first:rounded-tl last:rounded-br last:rounded-tr', itemValue === value &&
                'bg-info-backdrop font-medium text-info ring-1 ring-inset ring-info focus-within:ring-2'), children: [_jsx(VisuallyHidden, { children: _jsx(Radio, { value: itemValue }) }), label] }, itemValue))) }));
}
export default RadioButtonGroup;
