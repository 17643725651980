import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import { useApplication } from '../ApplicationProvider';
import { ContentType } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import VaultSelectMenuItemWithOptions from './MenuItemWithVaultOption';
import Icon from '../Icon/Icon';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
const ManyVaultSelectionMenu = () => {
    const application = useApplication();
    const [vaults, setVaults] = useState(() => application.vaults.getVaults());
    useEffect(() => {
        return application.items.streamItems(ContentType.TYPES.VaultListing, () => {
            setVaults(application.vaults.getVaults());
        });
    }, [application.items, application.vaults]);
    const isVaultVisible = useCallback((vault) => {
        return !application.vaultDisplayService.isVaultDisabledOrLocked(vault);
    }, [application]);
    const toggleVault = useCallback((vault) => {
        if (isVaultVisible(vault)) {
            application.vaultDisplayService.hideVault(vault);
        }
        else {
            application.vaultDisplayService.unhideVault(vault);
        }
    }, [isVaultVisible, application]);
    return (_jsxs(Menu, { a11yLabel: "Vault selection menu", children: [!vaults.length && _jsx("div", { className: "py-1 text-center", children: "No vaults found" }), vaults.map((vault) => (_jsx(VaultSelectMenuItemWithOptions, { vault: vault, children: _jsxs(MenuSwitchButtonItem, { className: "flex-grow !px-0 focus:!bg-transparent", onChange: () => {
                        toggleVault(vault);
                    }, checked: isVaultVisible(vault), children: [_jsx(Icon, { type: vault.iconString, className: "mr-2 text-neutral" }), _jsxs("div", { className: "flex w-full items-center gap-1", children: [vault.name, application.vaultLocks.isVaultLocked(vault) && _jsx(Icon, { className: "ml-1", type: "lock", size: 'small' })] })] }) }, vault.uuid)))] }));
};
export default observer(ManyVaultSelectionMenu);
