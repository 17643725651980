import { action, makeAutoObservable, observable } from 'mobx';
export class TagsCountsState {
    constructor(items) {
        this.items = items;
        this.counts = {};
        makeAutoObservable(this, {
            counts: observable.ref,
            update: action,
        });
    }
    update(tags) {
        const newCounts = Object.assign({}, this.counts);
        tags.forEach((tag) => {
            newCounts[tag.uuid] = this.items.countableNotesForTag(tag);
        });
        this.counts = newCounts;
    }
}
