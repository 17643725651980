import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SMART_TAGS_FEATURE_NAME } from '@/Constants/Constants';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import IconButton from '../Button/IconButton';
import EditSmartViewModal from '../Preferences/Panes/General/SmartViews/EditSmartViewModal';
import { EditSmartViewModalController } from '../Preferences/Panes/General/SmartViews/EditSmartViewModalController';
import ModalOverlay from '../Modal/ModalOverlay';
import AddSmartViewModal from '../SmartViewBuilder/AddSmartViewModal';
import { AddSmartViewModalController } from '../SmartViewBuilder/AddSmartViewModalController';
import SmartViewsList from './SmartViewsList';
const SmartViewsSection = ({ application, navigationController, featuresController }) => {
    const premiumModal = usePremiumModal();
    const addSmartViewModalController = useMemo(() => new AddSmartViewModalController(application), [application]);
    const editSmartViewModalController = useMemo(() => new EditSmartViewModalController(application), [application]);
    const createNewSmartView = useCallback(() => {
        if (!featuresController.hasSmartViews) {
            premiumModal.activate(SMART_TAGS_FEATURE_NAME);
            return;
        }
        addSmartViewModalController.setIsAddingSmartView(true);
    }, [addSmartViewModalController, premiumModal, featuresController.hasSmartViews]);
    return (_jsxs("section", { children: [_jsx("div", { className: 'section-title-bar', children: _jsxs("div", { className: "section-title-bar-header", children: [_jsx("div", { className: "title text-base md:text-sm", children: _jsx("span", { className: "font-bold", children: "Views" }) }), !navigationController.isSearching && (_jsx(IconButton, { focusable: true, icon: "add", title: "Create a new smart view", className: "p-0 text-neutral", onClick: createNewSmartView }))] }) }), _jsx(SmartViewsList, { navigationController: navigationController, featuresController: featuresController, setEditingSmartView: editSmartViewModalController.setView }), _jsx(ModalOverlay, { isOpen: !!editSmartViewModalController.view, close: editSmartViewModalController.closeDialog, children: _jsx(EditSmartViewModal, { controller: editSmartViewModalController, platform: application.platform }) }), _jsx(ModalOverlay, { isOpen: addSmartViewModalController.isAddingSmartView, close: addSmartViewModalController.closeModal, children: _jsx(AddSmartViewModal, { controller: addSmartViewModalController, platform: application.platform }) })] }));
};
export default observer(SmartViewsSection);
