import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { observer } from 'mobx-react-lite';
import { AccountIllustration } from '@standardnotes/icons';
import { AccountMenuPane } from '@/Components/AccountMenu/AccountMenuPane';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
const Authentication = ({ application }) => {
    const clickSignIn = () => {
        application.preferencesController.closePreferences();
        application.accountMenuController.setCurrentPane(AccountMenuPane.SignIn);
        application.accountMenuController.setShow(true);
    };
    const clickRegister = () => {
        application.preferencesController.closePreferences();
        application.accountMenuController.setCurrentPane(AccountMenuPane.Register);
        application.accountMenuController.setShow(true);
    };
    return (_jsx(PreferencesGroup, { children: _jsx(PreferencesSegment, { children: _jsxs("div", { className: "flex flex-col items-center px-4 md:px-12", children: [_jsx(AccountIllustration, { className: "mb-3" }), _jsx(Title, { children: "You're not signed in" }), _jsx("div", { className: "mb-3 text-center text-base lg:text-sm", children: "Sign in to sync your notes and preferences across all your devices and enable end-to-end encryption." }), _jsx(Button, { primary: true, label: "Create free account", onClick: clickRegister, className: "mb-3" }), _jsxs("div", { className: "text-base lg:text-sm", children: ["Already have an account?", ' ', _jsx("button", { className: "cursor-pointer border-0 bg-default p-0 text-info underline", onClick: clickSignIn, children: "Sign in" })] })] }) }) }));
};
export default observer(Authentication);
