"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DependencyContainer = void 0;
const Utils_1 = require("../Utils/Utils");
const isDeinitable_1 = require("./isDeinitable");
class DependencyContainer {
    constructor() {
        this.factory = new Map();
        this.dependencies = new Map();
    }
    deinit() {
        this.factory.clear();
        const deps = this.getAll();
        for (const dep of deps) {
            if ((0, isDeinitable_1.isDeinitable)(dep)) {
                dep.deinit();
            }
        }
        this.dependencies.clear();
    }
    getAll() {
        return Array.from(this.dependencies.values()).filter(Utils_1.isNotUndefined);
    }
    bind(sym, maker) {
        this.factory.set(sym, maker);
    }
    get(sym) {
        const dep = this.dependencies.get(sym);
        if (dep) {
            return dep;
        }
        const maker = this.factory.get(sym);
        if (!maker) {
            throw new Error(`No dependency maker found for ${sym.toString()}`);
        }
        const instance = maker();
        if (!instance) {
            /** Could be optional */
            return undefined;
        }
        this.dependencies.set(sym, instance);
        return instance;
    }
}
exports.DependencyContainer = DependencyContainer;
