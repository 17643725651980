import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DecoratedInput from '@/Components/Input/DecoratedInput';
import IconButton from '@/Components/Button/IconButton';
import { observer } from 'mobx-react-lite';
import CopyButton from './CopyButton';
import Bullet from './Bullet';
import { downloadSecretKey } from './download-secret-key';
import Icon from '@/Components/Icon/Icon';
const SaveSecretKey = ({ activation: act }) => {
    return (_jsx("div", { className: "h-33 flex flex-row items-center px-4 py-4", children: _jsxs("div", { className: "flex flex-grow flex-col", children: [_jsxs("div", { className: "flex flex-row flex-wrap items-center gap-1", children: [_jsx(Bullet, {}), _jsxs("div", { className: "text-sm", children: [_jsx("b", { children: "Save your secret key" }), ' ', _jsx("a", { target: "_blank", href: "https://standardnotes.com/help/21/where-should-i-store-my-two-factor-authentication-secret-key", children: "somewhere safe" }), ":"] }), _jsx(DecoratedInput, { disabled: true, right: [
                                _jsx(CopyButton, { copyValue: act.secretKey }),
                                _jsx(IconButton, { focusable: false, title: "Download", icon: "download", className: "p-0", onClick: () => {
                                        downloadSecretKey(act.secretKey);
                                    } }),
                            ], value: act.secretKey, className: { container: 'ml-2' } })] }), _jsx("div", { className: "h-2" }), _jsxs("div", { className: "flex flex-row items-center", children: [_jsx(Bullet, {}), _jsx("div", { className: "min-w-1" }), _jsxs("div", { className: "text-sm", children: ["You can use this key to generate codes if you lose access to your authenticator app.", _jsx("br", {}), _jsxs("a", { target: "_blank", rel: "noreferrer noopener", className: "underline hover:no-underline", href: "https://standardnotes.com/help/22/what-happens-if-i-lose-my-2fa-device-and-my-secret-key", children: ["Learn more", _jsx(Icon, { className: "ml-1 inline", type: "open-in", size: "small" })] })] })] })] }) }));
};
export default observer(SaveSecretKey);
