import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from '@/Components/Preferences/PreferencesComponents/Content';
import { observer } from 'mobx-react-lite';
import { is2FAActivation, is2FAEnabled } from '../TwoFactorAuth';
import TwoFactorActivationView from '../TwoFactorActivationView';
import TwoFactorTitle from './TwoFactorTitle';
import TwoFactorDescription from './TwoFactorDescription';
import TwoFactorSwitch from './TwoFactorSwitch';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import RecoveryCodeBanner from '@/Components/RecoveryCodeBanner/RecoveryCodeBanner';
import Modal from '@/Components/Modal/Modal';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
const TwoFactorAuthView = ({ auth, application, canDisable2FA }) => {
    const shouldShowActivationModal = auth.status !== 'fetching' && is2FAActivation(auth.status);
    const activationModalTitle = shouldShowActivationModal
        ? auth.status.activationStep === 'scan-qr-code'
            ? 'Step 1 of 3 - Scan QR code'
            : auth.status.activationStep === 'save-secret-key'
                ? 'Step 2 of 3 - Save secret key'
                : auth.status.activationStep === 'verification'
                    ? 'Step 3 of 3 - Verification'
                    : auth.status.activationStep === 'success'
                        ? 'Successfully Enabled'
                        : ''
        : '';
    const closeActivationModal = () => {
        if (auth.status === 'fetching') {
            return;
        }
        if (!is2FAActivation(auth.status)) {
            return;
        }
        if (auth.status.activationStep === 'success') {
            auth.status.finishActivation();
        }
        auth.status.cancelActivation();
    };
    const activationModalActions = shouldShowActivationModal
        ? [
            {
                label: 'Cancel',
                onClick: auth.status.cancelActivation,
                type: 'cancel',
                mobileSlot: 'left',
                hidden: auth.status.activationStep !== 'scan-qr-code',
            },
            {
                label: 'Back',
                onClick: auth.status.activationStep === 'save-secret-key'
                    ? auth.status.openScanQRCode
                    : auth.status.openSaveSecretKey,
                type: 'cancel',
                mobileSlot: 'left',
                hidden: auth.status.activationStep !== 'save-secret-key' && auth.status.activationStep !== 'verification',
            },
            {
                label: 'Next',
                onClick: auth.status.activationStep === 'scan-qr-code'
                    ? auth.status.openSaveSecretKey
                    : auth.status.activationStep === 'save-secret-key'
                        ? auth.status.openVerification
                        : auth.status.enable2FA,
                type: 'primary',
                mobileSlot: 'right',
                hidden: auth.status.activationStep === 'success',
            },
            {
                label: 'Finish',
                onClick: auth.status.finishActivation,
                type: 'primary',
                mobileSlot: 'right',
                hidden: auth.status.activationStep !== 'success',
            },
        ]
        : [];
    return (_jsxs(_Fragment, { children: [_jsxs(PreferencesGroup, { children: [_jsx(PreferencesSegment, { children: _jsxs("div", { className: "flex flex-row gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-grow flex-col", children: [_jsx(TwoFactorTitle, { auth: auth }), _jsx(TwoFactorDescription, { auth: auth })] }), _jsx(TwoFactorSwitch, { auth: auth, canDisable2FA: canDisable2FA })] }) }), auth.errorMessage != null && (_jsx(PreferencesSegment, { children: _jsx(Text, { className: "text-danger", children: auth.errorMessage }) })), auth.status !== 'fetching' && is2FAEnabled(auth.status) && (_jsx(PreferencesSegment, { children: _jsx("div", { className: "mt-3", children: _jsx(RecoveryCodeBanner, { application: application }) }) }))] }), _jsx(ModalOverlay, { isOpen: shouldShowActivationModal, close: closeActivationModal, children: _jsx(Modal, { title: activationModalTitle, close: closeActivationModal, actions: activationModalActions, children: shouldShowActivationModal && _jsx(TwoFactorActivationView, { activation: auth.status }) }) })] }));
};
export default observer(TwoFactorAuthView);
