import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import { isIOS } from '@standardnotes/ui-services';
const MultitaskingPrivacy = ({ application }) => {
    const [hasScreenshotPrivacy, setHasScreenshotPrivacy] = useState(false);
    useEffect(() => {
        const hasScreenshotPrivacyEnabled = application.protections.getMobileScreenshotPrivacyEnabled();
        setHasScreenshotPrivacy(hasScreenshotPrivacyEnabled);
    }, [application]);
    const onScreenshotPrivacyPress = async () => {
        const enable = !hasScreenshotPrivacy;
        setHasScreenshotPrivacy(enable);
        application.protections.setMobileScreenshotPrivacyEnabled(enable);
        application.device.setAndroidScreenshotPrivacy(enable);
    };
    const screenshotPrivacyFeatureText = isIOS() ? 'Multitasking Privacy' : 'Multitasking/Screenshot Privacy';
    const screenshotPrivacyTitle = hasScreenshotPrivacy
        ? `Disable ${screenshotPrivacyFeatureText}`
        : `Enable ${screenshotPrivacyFeatureText}`;
    return (_jsx("div", { children: _jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: screenshotPrivacyFeatureText }), _jsx(Button, { className: 'mt-1', label: screenshotPrivacyTitle, onClick: onScreenshotPrivacyPress, primary: true })] }) }) }));
};
export default observer(MultitaskingPrivacy);
