import { nanoid } from 'nanoid';
import { action, atom } from 'nanostores';
export const toastStore = atom([]);
export const updateToast = action(toastStore, 'updateToast', (store, toastId, options) => {
    const existingToasts = store.get();
    store.set(existingToasts.map((toast) => {
        if (toast.id === toastId) {
            return {
                ...toast,
                ...options,
            };
        }
        else {
            return toast;
        }
    }));
});
const removeToast = action(toastStore, 'removeToast', (store, toastId) => {
    const existingToasts = store.get();
    store.set(existingToasts.filter((toast) => toast.id !== toastId));
});
const DelayBeforeRemovingToast = 175;
export const dismissToast = action(toastStore, 'dismissToast', (store, toastId) => {
    const existingToasts = store.get();
    store.set(existingToasts.map((toast) => {
        if (toast.id === toastId) {
            return {
                ...toast,
                dismissed: true,
            };
        }
        else {
            return toast;
        }
    }));
    setTimeout(() => {
        removeToast(toastId);
    }, DelayBeforeRemovingToast);
});
export const addToast = action(toastStore, 'addToast', (store, options) => {
    var _a;
    const existingToasts = store.get();
    const isToastIdDuplicate = existingToasts.findIndex((toast) => toast.id === options.id) > -1;
    const id = options.id && !isToastIdDuplicate ? options.id : nanoid();
    if (isToastIdDuplicate) {
        console.warn(`Generated new ID for toast instead of overriding toast of ID "${options.id}".
If you want to update an existing toast, use the \`updateToast()\` function instead.`);
    }
    const toast = {
        ...options,
        id,
        dismissed: false,
        pauseOnWindowBlur: (_a = options.pauseOnWindowBlur) !== null && _a !== void 0 ? _a : true,
    };
    store.set([...existingToasts, toast]);
    return id;
});
