"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadsByDuplicating = void 0;
const utils_1 = require("@standardnotes/utils");
const PayloadsByUpdatingReferencingPayloadReferences_1 = require("./PayloadsByUpdatingReferencingPayloadReferences");
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
const DirtyCounter_1 = require("../../Runtime/DirtyCounter/DirtyCounter");
/**
 * Copies payload and assigns it a new uuid.
 * @returns An array of payloads that have changed as a result of copying.
 */
function PayloadsByDuplicating(dto) {
    const { payload, baseCollection, isConflict, additionalContent, source } = dto;
    const results = [];
    const override = {
        uuid: utils_1.UuidGenerator.GenerateUuid(),
        dirty: true,
        dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
        lastSyncBegan: undefined,
        lastSyncEnd: new Date(),
        duplicate_of: payload.uuid,
    };
    let copy;
    if ((0, TypeCheck_1.isDecryptedPayload)(payload)) {
        const contentOverride = Object.assign(Object.assign({}, payload.content), additionalContent);
        if (isConflict) {
            contentOverride.conflict_of = payload.uuid;
        }
        copy = payload.copyAsSyncResolved(Object.assign(Object.assign({}, override), { content: contentOverride, deleted: false }));
    }
    else {
        copy = payload.copyAsSyncResolved(Object.assign({}, override), source || payload.source);
    }
    results.push(copy);
    if ((0, TypeCheck_1.isDecryptedPayload)(payload) && (0, TypeCheck_1.isDecryptedPayload)(copy)) {
        /**
         * Get the payloads that make reference to payload and add the copy.
         */
        const updatedReferencing = (0, PayloadsByUpdatingReferencingPayloadReferences_1.PayloadsByUpdatingReferencingPayloadReferences)(payload, baseCollection, [copy]);
        (0, utils_1.extendArray)(results, updatedReferencing);
    }
    return results;
}
exports.PayloadsByDuplicating = PayloadsByDuplicating;
