"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemContentsEqual = void 0;
const utils_1 = require("@standardnotes/utils");
const DefaultAppDomain_1 = require("../../Abstract/Item/Types/DefaultAppDomain");
function ItemContentsEqual(leftContent, rightContent, keysToIgnore, appDataKeysToIgnore) {
    /* Create copies of objects before running omit as not to modify source values directly. */
    const leftContentCopy = (0, utils_1.sortedCopy)(leftContent);
    if (leftContentCopy.appData) {
        const domainData = leftContentCopy.appData[DefaultAppDomain_1.DefaultAppDomain];
        (0, utils_1.omitInPlace)(domainData, appDataKeysToIgnore);
        /**
         * We don't want to disqualify comparison if one object contains an empty domain object
         * and the other doesn't contain a domain object. This can happen if you create an item
         * without setting dirty, which means it won't be initialized with a client_updated_at
         */
        if (domainData) {
            if (Object.keys(domainData).length === 0) {
                delete leftContentCopy.appData;
            }
        }
        else {
            delete leftContentCopy.appData;
        }
    }
    (0, utils_1.omitInPlace)(leftContentCopy, keysToIgnore);
    const rightContentCopy = (0, utils_1.sortedCopy)(rightContent);
    if (rightContentCopy.appData) {
        const domainData = rightContentCopy.appData[DefaultAppDomain_1.DefaultAppDomain];
        (0, utils_1.omitInPlace)(domainData, appDataKeysToIgnore);
        if (domainData) {
            if (Object.keys(domainData).length === 0) {
                delete rightContentCopy.appData;
            }
        }
        else {
            delete rightContentCopy.appData;
        }
    }
    (0, utils_1.omitInPlace)(rightContentCopy, keysToIgnore);
    return JSON.stringify(leftContentCopy) === JSON.stringify(rightContentCopy);
}
exports.ItemContentsEqual = ItemContentsEqual;
