import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import SubscriptionInformation from './SubscriptionInformation';
import NoSubscription from './NoSubscription';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { useApplication } from '@/Components/ApplicationProvider';
import { SubscriptionManagerEvent } from '@standardnotes/snjs';
const Subscription = () => {
    const application = useApplication();
    const [onlineSubscription, setOnlineSubscription] = useState(application.subscriptionController.onlineSubscription);
    useEffect(() => {
        return application.subscriptions.addEventObserver((event) => {
            if (event === SubscriptionManagerEvent.DidFetchSubscription) {
                setOnlineSubscription(application.subscriptionController.onlineSubscription);
            }
        });
    }, [application.subscriptions, application.subscriptionController]);
    useEffect(() => {
        void application.subscriptions.fetchOnlineSubscription();
    }, [application.subscriptions]);
    return (_jsx(PreferencesGroup, { children: _jsx(PreferencesSegment, { children: _jsx("div", { className: "flex flex-row items-center", children: _jsxs("div", { className: "flex flex-grow flex-col", children: [_jsx(Title, { children: "Subscription" }), onlineSubscription ? _jsx(SubscriptionInformation, {}) : _jsx(NoSubscription, { application: application })] }) }) }) }));
};
export default observer(Subscription);
