import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames, ContentType, pluralize } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import { Disclosure, DisclosureContent, DisclosureProvider } from '@ariakit/react';
const NoteImportTypeColors = {
    evernote: 'bg-[#14cc45] text-[#000]',
    simplenote: 'bg-[#3360cc] text-default',
    'google-keep': 'bg-[#fbbd00] text-[#000]',
    aegis: 'bg-[#0d47a1] text-default',
    plaintext: 'bg-default border border-border',
    html: 'bg-accessory-tint-2',
    super: 'bg-accessory-tint-1 text-accessory-tint-1',
};
const NoteImportTypeIcons = {
    evernote: 'evernote',
    simplenote: 'simplenote',
    'google-keep': 'gkeep',
    aegis: 'aegis',
    plaintext: 'plain-text',
    html: 'rich-text',
    super: 'file-doc',
};
const countSuccessfulItemsByGroup = (successful) => {
    let notes = 0;
    let tags = 0;
    let files = 0;
    for (const item of successful) {
        if (item.content_type === ContentType.TYPES.Note) {
            notes++;
        }
        else if (item.content_type === ContentType.TYPES.Tag) {
            tags++;
        }
        else if (item.content_type === ContentType.TYPES.File) {
            files++;
        }
    }
    return {
        notes,
        tags,
        files,
    };
};
const ImportErroredAccordion = ({ errored }) => {
    return (_jsxs(DisclosureProvider, { children: [_jsx(Disclosure, { children: _jsxs("div", { className: "flex items-center gap-1", children: [_jsx(Icon, { type: "warning", className: "flex-shrink-0 text-danger", size: "small" }), "Could not import ", errored.length, " ", pluralize(errored.length, 'item', 'items'), " (click for details)"] }) }), _jsx(DisclosureContent, { className: "w-full overflow-hidden pl-5", children: errored.map((item, index) => (_jsxs("div", { className: "flex w-full items-center gap-1 overflow-hidden", children: [_jsxs("span", { children: [index + 1, "."] }), _jsxs("span", { className: "overflow-hidden text-ellipsis whitespace-nowrap font-semibold", children: [item.name, ":"] }), _jsx("span", { className: "overflow-hidden text-ellipsis whitespace-nowrap", children: item.error.message })] }, index))) })] }));
};
const ImportFinishedStatus = ({ file }) => {
    if (file.status !== 'finished') {
        return null;
    }
    const { notes, tags, files } = countSuccessfulItemsByGroup(file.successful);
    const notesStatus = notes > 0 ? `${notes} ${pluralize(notes, 'note', 'notes')}` : '';
    const tagsStatus = tags > 0 ? `${tags} ${pluralize(tags, 'tag', 'tags')}` : '';
    const filesStatus = files > 0 ? `${files} ${pluralize(files, 'file', 'files')}` : '';
    const status = [notesStatus, tagsStatus, filesStatus].filter(Boolean).join(', ');
    return (_jsxs(_Fragment, { children: [file.successful.length > 0 && (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(Icon, { type: "check-circle-filled", className: "flex-shrink-0 text-success", size: "small" }), _jsxs("span", { children: [status, " imported"] })] })), file.errored.length > 0 && _jsx(ImportErroredAccordion, { errored: file.errored })] }));
};
const ImportModalFileItem = ({ file, updateFile, removeFile, importer, }) => {
    const [isDetectingService, setIsDetectingService] = useState(false);
    const [changingService, setChangingService] = useState(false);
    const setFileService = useCallback(async (service) => {
        if (!service) {
            setChangingService(true);
        }
        updateFile({
            ...file,
            service,
            status: 'pending',
        });
    }, [file, updateFile]);
    useEffect(() => {
        const detect = async () => {
            setIsDetectingService(true);
            const detectedService = await importer.detectService(file.file);
            void setFileService(detectedService);
            setIsDetectingService(false);
        };
        if (file.service === undefined) {
            void detect();
        }
    }, [file, importer, setFileService]);
    return (_jsxs("div", { className: classNames('flex gap-2 overflow-hidden px-2 py-2.5', file.service == null ? 'flex-col items-start md:flex-row md:items-center' : 'items-center'), children: [_jsxs("div", { className: "mr-auto flex w-full items-center", children: [file.service && (_jsx("div", { className: classNames('mr-4 rounded p-2', NoteImportTypeColors[file.service]), children: _jsx(Icon, { type: NoteImportTypeIcons[file.service], size: "medium" }) })), _jsxs("div", { className: "flex w-full flex-col overflow-hidden", children: [_jsx("div", { children: file.file.name }), isDetectingService ? (_jsx("div", { className: "text-xs opacity-75", children: "Detecting service..." })) : (_jsxs("div", { className: classNames(file.status !== 'finished' && 'line-clamp-3', 'w-full text-xs opacity-75'), children: [file.status === 'pending' && file.service && 'Ready to import', file.status === 'pending' && !file.service && 'Could not auto-detect service. Please select manually.', file.status === 'parsing' && 'Parsing...', file.status === 'importing' && 'Importing...', file.status === 'uploading-files' && 'Uploading and embedding files...', file.status === 'error' && file.error.message, _jsx(ImportFinishedStatus, { file: file })] }))] })] }), file.status === 'pending' && (_jsxs("div", { className: "flex items-center", children: [changingService ? (_jsxs(_Fragment, { children: [_jsxs("form", { className: "flex items-center", onSubmit: (event) => {
                                    event.preventDefault();
                                    const form = event.target;
                                    const service = form.elements[0];
                                    void setFileService(service.value);
                                    setChangingService(false);
                                }, children: [_jsxs("select", { className: "mr-2 rounded border border-border bg-default px-2 py-1 text-sm", defaultValue: file.service ? file.service : undefined, children: [_jsx("option", { value: "evernote", children: "Evernote" }), _jsx("option", { value: "simplenote", children: "Simplenote" }), _jsx("option", { value: "google-keep", children: "Google Keep" }), _jsx("option", { value: "aegis", children: "Aegis" }), _jsx("option", { value: "plaintext", children: "Plaintext" }), _jsx("option", { value: "html", children: "HTML" }), _jsx("option", { value: "super", children: "Super" })] }), _jsx("button", { "aria-label": "Choose service", type: "submit", className: "rounded border border-border bg-default p-1.5 hover:bg-contrast", children: _jsx(Icon, { type: "check", size: "medium" }) })] }), _jsx("button", { "aria-label": "Cancel", className: "ml-2 rounded border border-border bg-default p-1.5 hover:bg-contrast", onClick: () => {
                                    setChangingService(false);
                                }, children: _jsx(Icon, { type: "close", size: "medium" }) })] })) : (_jsx("button", { "aria-label": "Change service", className: "rounded border border-border bg-default p-1.5 hover:bg-contrast", onClick: () => {
                            setChangingService(true);
                        }, children: _jsx(Icon, { type: "settings", size: "medium" }) })), _jsx("button", { "aria-label": "Remove", className: "ml-2 rounded border border-border bg-default p-1.5 hover:bg-contrast", onClick: () => {
                            removeFile(file.id);
                        }, children: _jsx(Icon, { type: "trash", size: "medium" }) })] })), file.status === 'finished' && file.successful.length > 0 && file.errored.length === 0 && (_jsx(Icon, { type: "check-circle-filled", className: "flex-shrink-0 text-success" })), file.status === 'error' && _jsx(Icon, { type: "warning", className: "flex-shrink-0 text-danger" })] }));
};
export default observer(ImportModalFileItem);
