"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNFeatureRepo = void 0;
const utils_1 = require("@standardnotes/utils");
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
class SNFeatureRepo extends DecryptedItem_1.DecryptedItem {
    get migratedToUserSetting() {
        return (0, utils_1.useBoolean)(this.payload.content.migratedToUserSetting, false);
    }
    get migratedToOfflineEntitlements() {
        return (0, utils_1.useBoolean)(this.payload.content.migratedToOfflineEntitlements, false);
    }
    get onlineUrl() {
        return this.payload.content.url;
    }
    get offlineFeaturesUrl() {
        return this.payload.content.offlineFeaturesUrl;
    }
    get offlineKey() {
        return this.payload.content.offlineKey;
    }
}
exports.SNFeatureRepo = SNFeatureRepo;
