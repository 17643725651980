import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { alertDialog } from '@standardnotes/ui-services';
import { STRING_IMPORT_SUCCESS, STRING_INVALID_IMPORT_FILE, STRING_IMPORTING_ZIP_FILE, STRING_UNSUPPORTED_BACKUP_FILE_VERSION, StringImportError, STRING_E2E_ENABLED, STRING_LOCAL_ENC_ENABLED, STRING_ENC_NOT_ENABLED, } from '@/Constants/Strings';
import { sanitizeFileName } from '@standardnotes/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Title, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Spinner from '@/Components/Spinner/Spinner';
import { downloadOrShareBlobBasedOnPlatform } from '@/Utils/DownloadOrShareBasedOnPlatform';
const DataBackups = ({ application }) => {
    const fileInputRef = useRef(null);
    const [isImportDataLoading, setIsImportDataLoading] = useState(false);
    const { isBackupEncrypted, isEncryptionEnabled, setIsBackupEncrypted, setIsEncryptionEnabled, setEncryptionStatusString, } = application.accountMenuController;
    const refreshEncryptionStatus = useCallback(() => {
        const hasUser = application.hasAccount();
        const hasPasscode = application.hasPasscode();
        const encryptionEnabled = hasUser || hasPasscode;
        const encryptionStatusString = hasUser
            ? STRING_E2E_ENABLED
            : hasPasscode
                ? STRING_LOCAL_ENC_ENABLED
                : STRING_ENC_NOT_ENABLED;
        setEncryptionStatusString(encryptionStatusString);
        setIsEncryptionEnabled(encryptionEnabled);
        setIsBackupEncrypted(encryptionEnabled);
    }, [application, setEncryptionStatusString, setIsBackupEncrypted, setIsEncryptionEnabled]);
    useEffect(() => {
        refreshEncryptionStatus();
    }, [refreshEncryptionStatus]);
    const downloadDataArchive = async () => {
        const result = isBackupEncrypted
            ? await application.createEncryptedBackupFile.execute()
            : await application.createDecryptedBackupFile.execute();
        if (result.isFailed()) {
            return;
        }
        const data = result.getValue();
        const blobData = new Blob([JSON.stringify(data, null, 2)], {
            type: 'text/json',
        });
        if (isBackupEncrypted) {
            const filename = `Standard Notes Encrypted Backup and Import File - ${application.archiveService.formattedDateForExports()}`;
            const sanitizedFilename = sanitizeFileName(filename) + '.txt';
            void downloadOrShareBlobBasedOnPlatform({
                archiveService: application.archiveService,
                platform: application.platform,
                mobileDevice: application.mobileDevice,
                blob: blobData,
                filename: sanitizedFilename,
                isNativeMobileWeb: application.isNativeMobileWeb(),
                showToastOnAndroid: undefined,
            });
        }
        else {
            const zippedDecryptedItemsBlob = await application.archiveService.getZippedDecryptedItemsBlob(data);
            const filename = `Standard Notes Backup - ${application.archiveService.formattedDateForExports()}`;
            const sanitizedFilename = sanitizeFileName(filename) + '.zip';
            void downloadOrShareBlobBasedOnPlatform({
                archiveService: application.archiveService,
                platform: application.platform,
                mobileDevice: application.mobileDevice,
                blob: zippedDecryptedItemsBlob,
                filename: sanitizedFilename,
                isNativeMobileWeb: application.isNativeMobileWeb(),
                showToastOnAndroid: undefined,
            });
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const readFile = async (file) => {
        if (file.type === 'application/zip') {
            application.alerts.alert(STRING_IMPORTING_ZIP_FILE).catch(console.error);
            return;
        }
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                var _a;
                try {
                    const data = JSON.parse((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
                    resolve(data);
                }
                catch (e) {
                    application.alerts.alert(STRING_INVALID_IMPORT_FILE).catch(console.error);
                }
            };
            reader.readAsText(file);
        });
    };
    const performImport = async (data) => {
        setIsImportDataLoading(true);
        const result = await application.importData(data);
        setIsImportDataLoading(false);
        let statusText = STRING_IMPORT_SUCCESS;
        if (result.isFailed()) {
            statusText = result.getError();
        }
        else if (result.getValue().errorCount) {
            statusText = StringImportError(result.getValue().errorCount);
        }
        void alertDialog({
            text: statusText,
        });
    };
    const importFileSelected = async (event) => {
        var _a, _b;
        const { files } = event.target;
        if (!files) {
            return;
        }
        const file = files[0];
        const data = await readFile(file);
        if (!data) {
            return;
        }
        const version = data.version || ((_a = data.keyParams) === null || _a === void 0 ? void 0 : _a.version) || ((_b = data.auth_params) === null || _b === void 0 ? void 0 : _b.version);
        if (!version) {
            await performImport(data);
            return;
        }
        if (application.encryption.supportedVersions().includes(version)) {
            await performImport(data);
        }
        else {
            setIsImportDataLoading(false);
            void alertDialog({ text: STRING_UNSUPPORTED_BACKUP_FILE_VERSION });
        }
    };
    // Whenever "Import Backup" is either clicked or key-pressed, proceed the import
    const handleImportFile = (event) => {
        if (event instanceof KeyboardEvent) {
            const { code } = event;
            // Process only when "Enter" or "Space" keys are pressed
            if (code !== 'Enter' && code !== 'Space') {
                return;
            }
            // Don't proceed the event's default action
            // (like scrolling in case the "space" key is pressed)
            event.preventDefault();
        }
        ;
        fileInputRef.current.click();
    };
    return (_jsx(_Fragment, { children: _jsxs(PreferencesGroup, { children: [_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Data backups" }), _jsx(Subtitle, { children: "Download a backup of all your text-based data" }), isEncryptionEnabled && (_jsx("form", { className: "sk-panel-form sk-panel-row", children: _jsxs("div", { className: "flex items-center gap-2", children: [_jsxs("label", { className: "flex items-center gap-2", children: [_jsx("input", { type: "radio", onChange: () => setIsBackupEncrypted(true), checked: isBackupEncrypted }), _jsx("span", { className: "text-base font-medium md:text-sm", children: "Encrypted" })] }), _jsxs("label", { className: "flex items-center gap-2", children: [_jsx("input", { type: "radio", onChange: () => setIsBackupEncrypted(false), checked: !isBackupEncrypted }), _jsx("span", { className: "text-base font-medium md:text-sm", children: "Decrypted" })] })] }) })), _jsx(Button, { onClick: downloadDataArchive, label: "Download backup", className: "mt-2" })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs(PreferencesSegment, { children: [_jsx(Subtitle, { children: "Import a previously saved backup file" }), _jsxs("div", { className: "mt-3 flex flex-row items-center", children: [_jsx(Button, { label: "Import backup", onClick: handleImportFile }), _jsx("input", { type: "file", ref: fileInputRef, onChange: importFileSelected, className: "hidden" }), isImportDataLoading && _jsx(Spinner, { className: "ml-4" })] })] })] }) }));
};
export default observer(DataBackups);
