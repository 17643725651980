import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { AccountMenuPane } from './AccountMenuPane';
import MenuPaneSelector from './MenuPaneSelector';
import { KeyboardKey } from '@standardnotes/ui-services';
import { useApplication } from '../ApplicationProvider';
const AccountMenu = ({ mainApplicationGroup }) => {
    const application = useApplication();
    const { currentPane } = application.accountMenuController;
    const closeAccountMenu = useCallback(() => {
        application.accountMenuController.closeAccountMenu();
    }, [application]);
    const setCurrentPane = useCallback((pane) => {
        application.accountMenuController.setCurrentPane(pane);
    }, [application]);
    const handleKeyDown = useCallback((event) => {
        if (event.key === KeyboardKey.Escape) {
            if (currentPane === AccountMenuPane.GeneralMenu) {
                closeAccountMenu();
            }
            else if (currentPane === AccountMenuPane.ConfirmPassword) {
                setCurrentPane(AccountMenuPane.Register);
            }
            else {
                setCurrentPane(AccountMenuPane.GeneralMenu);
            }
        }
    }, [closeAccountMenu, currentPane, setCurrentPane]);
    return (_jsx("div", { id: "account-menu", className: "sn-component", onKeyDown: handleKeyDown, children: _jsx(MenuPaneSelector, { mainApplicationGroup: mainApplicationGroup, menuPane: currentPane, setMenuPane: setCurrentPane, closeMenu: closeAccountMenu }) }));
};
export default observer(AccountMenu);
