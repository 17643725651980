import { isMacPlatform } from './platformCheck';
export var KeyboardModifier;
(function (KeyboardModifier) {
    KeyboardModifier["Shift"] = "Shift";
    KeyboardModifier["Ctrl"] = "Control";
    /** ⌘ key on Mac, ⊞ key on Windows */
    KeyboardModifier["Meta"] = "Meta";
    KeyboardModifier["Alt"] = "Alt";
})(KeyboardModifier || (KeyboardModifier = {}));
export function getPrimaryModifier(platform) {
    return isMacPlatform(platform) ? KeyboardModifier.Meta : KeyboardModifier.Ctrl;
}
