import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ButtonType, InvitationStatus } from '@standardnotes/snjs';
import { SubtitleLight, Text } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
const InvitationsList = ({ subscriptionState, application }) => {
    const [lockContinue, setLockContinue] = useState(false);
    const { usedInvitationsCount, subscriptionInvitations } = subscriptionState;
    const activeSubscriptions = subscriptionInvitations === null || subscriptionInvitations === void 0 ? void 0 : subscriptionInvitations.filter((invitation) => [InvitationStatus.Sent, InvitationStatus.Accepted].includes(invitation.status));
    const handleCancel = async (invitationUuid) => {
        if (lockContinue) {
            application.alerts.alert('Cancelation already in progress.').catch(console.error);
            return;
        }
        const confirmed = await application.alerts.confirm('All uploaded files of this user will be removed. This action cannot be undone.', 'Are you sure you want to cancel this invitation?', 'Cancel Invitation', ButtonType.Danger);
        if (!confirmed) {
            return;
        }
        setLockContinue(true);
        const success = await subscriptionState.cancelSubscriptionInvitation(invitationUuid);
        setLockContinue(false);
        if (!success) {
            application.alerts
                .alert('Could not cancel invitation. Please try again or contact support if the issue persists.')
                .catch(console.error);
        }
    };
    if (usedInvitationsCount === 0) {
        return _jsx(Text, { className: "mb-3 mt-1", children: "Make your first subscription invite below." });
    }
    return (_jsxs("div", { children: [_jsx(SubtitleLight, { className: "mb-2 text-info", children: "Active Invites" }), activeSubscriptions === null || activeSubscriptions === void 0 ? void 0 : activeSubscriptions.map((invitation) => (_jsxs("div", { className: "mb-4 mt-1", children: [_jsxs(Text, { children: [invitation.inviteeIdentifier, " ", _jsxs("span", { className: "text-info", children: ["(", invitation.status, ")"] })] }), invitation.status !== InvitationStatus.Canceled && (_jsx(Button, { className: "mt-2 min-w-20", label: "Cancel", onClick: () => handleCancel(invitation.uuid) }))] }, invitation.uuid))), !subscriptionState.allInvitationsUsed && _jsx(HorizontalSeparator, { classes: "my-4" })] }));
};
export default observer(InvitationsList);
