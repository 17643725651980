import { ShouldPersistNoteStateKey } from '@/Components/Preferences/Panes/General/Persistence';
import { ApplicationEvent, ContentType, } from '@standardnotes/snjs';
import { PersistenceKey, StorageKey } from '@standardnotes/ui-services';
import { CrossControllerEvent } from '../CrossControllerEvent';
export class PersistenceService {
    constructor(itemListController, navigationController, storage, items, sync, eventBus) {
        this.itemListController = itemListController;
        this.navigationController = navigationController;
        this.storage = storage;
        this.items = items;
        this.sync = sync;
        this.eventBus = eventBus;
        this.didHydrateOnce = false;
        this.hydratePersistedValues = () => {
            this.eventBus.publish({
                type: CrossControllerEvent.HydrateFromPersistedValues,
                payload: this.persistenceEnabled ? this.getPersistedValues() : undefined,
            });
        };
        eventBus.addEventHandler(this, ApplicationEvent.LocalDataLoaded);
        eventBus.addEventHandler(this, ApplicationEvent.LocalDataIncrementalLoad);
        eventBus.addEventHandler(this, CrossControllerEvent.HydrateFromPersistedValues);
        eventBus.addEventHandler(this, CrossControllerEvent.RequestValuePersistence);
    }
    async handleEvent(event) {
        switch (event.type) {
            case ApplicationEvent.LocalDataLoaded: {
                if (!this.didHydrateOnce) {
                    this.hydratePersistedValues();
                    this.didHydrateOnce = true;
                }
                break;
            }
            case ApplicationEvent.LocalDataIncrementalLoad: {
                const canHydrate = this.items.getItems([ContentType.TYPES.Note, ContentType.TYPES.Tag]).length > 0;
                if (!canHydrate) {
                    return;
                }
                this.hydratePersistedValues();
                this.didHydrateOnce = true;
                break;
            }
            case CrossControllerEvent.HydrateFromPersistedValues: {
                this.hydrateFromPersistedValues(event.payload);
                break;
            }
            case CrossControllerEvent.RequestValuePersistence: {
                this.persistCurrentState();
                break;
            }
        }
    }
    get persistenceEnabled() {
        var _a;
        return (_a = this.storage.getValue(ShouldPersistNoteStateKey)) !== null && _a !== void 0 ? _a : true;
    }
    persistCurrentState() {
        const values = {
            [PersistenceKey.ItemListController]: this.itemListController.getPersistableValue(),
            [PersistenceKey.NavigationController]: this.navigationController.getPersistableValue(),
        };
        this.persistValues(values);
        const selectedItemsState = values['selected-items-controller'];
        const navigationSelectionState = values['navigation-controller'];
        const launchPriorityUuids = [];
        if (selectedItemsState.selectedUuids.length) {
            launchPriorityUuids.push(...selectedItemsState.selectedUuids);
        }
        if (navigationSelectionState.selectedTagUuid) {
            launchPriorityUuids.push(navigationSelectionState.selectedTagUuid);
        }
        this.sync.setLaunchPriorityUuids(launchPriorityUuids);
    }
    hydrateFromPersistedValues(values) {
        const navigationState = values === null || values === void 0 ? void 0 : values[PersistenceKey.NavigationController];
        this.navigationController.hydrateFromPersistedValue(navigationState);
        const selectedItemsState = values === null || values === void 0 ? void 0 : values[PersistenceKey.ItemListController];
        this.itemListController.hydrateFromPersistedValue(selectedItemsState);
    }
    persistValues(values) {
        if (!this.sync.isDatabaseLoaded()) {
            return;
        }
        if (!this.persistenceEnabled) {
            return;
        }
        this.storage.setValue(StorageKey.MasterStatePersistenceKey, values);
    }
    clearPersistedValues() {
        if (!this.sync.isDatabaseLoaded()) {
            return;
        }
        void this.storage.removeValue(StorageKey.MasterStatePersistenceKey);
    }
    getPersistedValues() {
        return this.storage.getValue(StorageKey.MasterStatePersistenceKey);
    }
}
