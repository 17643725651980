import { WebApplication } from './WebApplication';
import { SNApplicationGroup } from '@standardnotes/snjs';
import { getPlatform, isDesktopApplication } from '@/Utils';
const createApplication = (descriptor, deviceInterface, defaultSyncServerHost, device, webSocketUrl) => {
    const platform = getPlatform(device);
    const application = new WebApplication(deviceInterface, platform, descriptor.identifier, defaultSyncServerHost, webSocketUrl);
    return application;
};
export class WebApplicationGroup extends SNApplicationGroup {
    constructor(defaultSyncServerHost, device, webSocketUrl) {
        super(device);
        this.defaultSyncServerHost = defaultSyncServerHost;
        this.webSocketUrl = webSocketUrl;
    }
    async initialize() {
        const defaultSyncServerHost = this.defaultSyncServerHost;
        const webSocketUrl = this.webSocketUrl;
        await super.initialize({
            applicationCreator: async (descriptor, device) => {
                return createApplication(descriptor, device, defaultSyncServerHost, device, webSocketUrl);
            },
        });
        if (isDesktopApplication()) {
            window.webClient = this.primaryApplication.desktopManager;
        }
    }
    deinit() {
        super.deinit();
        if (isDesktopApplication()) {
            delete window.webClient;
        }
    }
}
