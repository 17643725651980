"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiEndpointParam = void 0;
var ApiEndpointParam;
(function (ApiEndpointParam) {
    ApiEndpointParam["LastSyncToken"] = "sync_token";
    ApiEndpointParam["PaginationToken"] = "cursor_token";
    ApiEndpointParam["SyncDlLimit"] = "limit";
    ApiEndpointParam["SyncPayloads"] = "items";
    ApiEndpointParam["ApiVersion"] = "api";
    ApiEndpointParam["SharedVaultUuids"] = "shared_vault_uuids";
})(ApiEndpointParam || (exports.ApiEndpointParam = ApiEndpointParam = {}));
