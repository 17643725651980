import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeySystemRootKeyStorageMode, classNames } from '@standardnotes/snjs';
import StyledRadioInput from '@/Components/Radio/StyledRadioInput';
const options = [
    {
        value: KeySystemRootKeyStorageMode.Synced,
        label: 'Synced (Recommended)',
        description: 'Your vault key will be encrypted and synced to your account and automatically available on your other devices.',
    },
    {
        value: KeySystemRootKeyStorageMode.Local,
        label: 'Local',
        description: 'Your vault key will be encrypted and saved locally on this device. You will need to manually enter your vault key on your other devices.',
    },
    {
        value: KeySystemRootKeyStorageMode.Ephemeral,
        label: 'Ephemeral',
        description: 'Your vault key will only be stored in memory and will be forgotten when you close the app. You will need to manually enter your vault key on your other devices.',
    },
];
export const KeyStoragePreference = ({ value, onChange, }) => {
    return (_jsxs("div", { children: [_jsx("div", { className: "mb-3 text-lg", children: "Vault Key Storage Mode" }), _jsx("div", { className: "space-y-3", children: options.map((option) => {
                    const isSelected = value === option.value;
                    return (_jsxs("label", { className: "grid grid-cols-[auto,1fr] gap-x-[0.65rem] gap-y-1 text-base font-medium md:text-sm", children: [_jsx(StyledRadioInput, { className: "col-start-1 col-end-2 place-self-center", name: "option", checked: isSelected, onChange: () => {
                                    onChange(option.value);
                                } }), _jsx("div", { className: classNames('select-none', isSelected ? 'font-semibold' : ''), children: option.label }), _jsx("div", { className: "col-start-2 row-start-2 text-sm opacity-80", children: option.description })] }, option.value));
                }) })] }));
};
