import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/snjs';
import VaultNameBadge from '../Vaults/VaultNameBadge';
import SharedByContactBadge from '../Vaults/SharedByContactBadge';
import { useItemVaultInfo } from '@/Hooks/useItemVaultInfo';
const ListItemVaultInfo = ({ item, className }) => {
    const { vault, sharedByContact } = useItemVaultInfo(item);
    if (!vault) {
        return null;
    }
    return (_jsxs("div", { className: classNames('flex flex-wrap items-center gap-2', className), children: [_jsx(VaultNameBadge, { vault: vault }), sharedByContact && _jsx(SharedByContactBadge, { contact: sharedByContact })] }));
};
export default ListItemVaultInfo;
