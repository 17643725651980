import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { FOCUSABLE_BUT_NOT_TABBABLE, TAG_FOLDERS_FEATURE_NAME } from '@/Constants/Constants';
import { KeyboardKey } from '@standardnotes/ui-services';
import { SNTag } from '@standardnotes/snjs';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState, } from 'react';
import { classNames } from '@standardnotes/utils';
import { useFileDragNDrop } from '../FileDragNDropProvider';
import { log, LoggingDomain } from '@/Logging';
import { NoteDragDataFormat, TagDragDataFormat } from './DragNDrop';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { useApplication } from '../ApplicationProvider';
const PADDING_BASE_PX = 14;
const PADDING_PER_LEVEL_PX = 21;
export const TagsListItem = observer(({ tag, type, features, navigationController, level, onContextMenu, linkingController }) => {
    const application = useApplication();
    const [title, setTitle] = useState(tag.title || '');
    const [subtagTitle, setSubtagTitle] = useState('');
    const inputRef = useRef(null);
    const subtagInputRef = useRef(null);
    const menuButtonRef = useRef(null);
    const isContextMenuOpenForTag = navigationController.contextMenuTag === tag &&
        navigationController.contextMenuOpen &&
        navigationController.contextMenuTagSection === type;
    const isSelected = navigationController.selected === tag && navigationController.selectedLocation === type;
    const isEditing = navigationController.editingTag === tag && navigationController.selectedLocation === type;
    const isAddingSubtag = navigationController.addingSubtagTo === tag &&
        (navigationController.contextMenuTag === tag
            ? navigationController.contextMenuTagSection === type
            : navigationController.selectedLocation === type);
    const noteCounts = computed(() => navigationController.getNotesCount(tag));
    const childrenTags = computed(() => navigationController.getChildren(tag)).get();
    const hasChildren = childrenTags.length > 0;
    const hasFolders = features.hasFolders;
    const premiumModal = usePremiumModal();
    const [showChildren, setShowChildren] = useState(tag.expanded);
    const [hadChildren, setHadChildren] = useState(hasChildren);
    const [isBeingDraggedOver, setIsBeingDraggedOver] = useState(false);
    useEffect(() => {
        if (!hadChildren && hasChildren) {
            setShowChildren(true);
        }
        setHadChildren(hasChildren);
    }, [hadChildren, hasChildren]);
    useEffect(() => {
        setTitle(tag.title || '');
    }, [setTitle, tag]);
    const setTagExpanded = useCallback((expanded) => {
        if (!hasChildren) {
            return;
        }
        setShowChildren(expanded);
        if (!navigationController.isSearching) {
            navigationController.setExpanded(tag, expanded);
        }
    }, [hasChildren, navigationController, tag]);
    const toggleChildren = useCallback((e) => {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        const shouldShowChildren = !showChildren;
        setTagExpanded(shouldShowChildren);
    }, [showChildren, setTagExpanded]);
    useEffect(() => {
        if (!navigationController.isSearching) {
            setShowChildren(tag.expanded);
        }
    }, [navigationController.isSearching, tag]);
    const selectCurrentTag = useCallback(async () => {
        await navigationController.setSelectedTag(tag, type, {
            userTriggered: true,
        });
    }, [navigationController, tag, type]);
    const onBlur = useCallback(() => {
        navigationController.save(tag, title).catch(console.error);
        setTitle(tag.title);
    }, [navigationController, tag, title, setTitle]);
    const onInput = useCallback((e) => {
        const value = e.target.value;
        setTitle(value);
    }, [setTitle]);
    const onKeyDown = useCallback((e) => {
        var _a;
        if (e.key === KeyboardKey.Enter) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            e.preventDefault();
        }
    }, [inputRef]);
    useEffect(() => {
        var _a;
        if (isEditing) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [inputRef, isEditing]);
    const onSubtagInput = useCallback((e) => {
        const value = e.target.value;
        setSubtagTitle(value);
    }, []);
    const onSubtagInputBlur = useCallback(() => {
        navigationController.createSubtagAndAssignParent(tag, subtagTitle).catch(console.error);
        setSubtagTitle('');
    }, [subtagTitle, tag, navigationController]);
    const onSubtagKeyDown = useCallback((e) => {
        var _a;
        if (e.key === KeyboardKey.Enter) {
            e.preventDefault();
            (_a = subtagInputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    }, [subtagInputRef]);
    useEffect(() => {
        var _a;
        if (isAddingSubtag) {
            (_a = subtagInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [subtagInputRef, isAddingSubtag]);
    const toggleContextMenu = useCallback((event) => {
        var _a;
        event.preventDefault();
        event.stopPropagation();
        if (!menuButtonRef.current) {
            return;
        }
        const contextMenuOpen = navigationController.contextMenuOpen;
        const menuButtonRect = (_a = menuButtonRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (contextMenuOpen) {
            navigationController.setContextMenuOpen(false);
        }
        else {
            onContextMenu(tag, type, menuButtonRect.right, menuButtonRect.top);
        }
    }, [navigationController, onContextMenu, tag, type]);
    const tagRef = useRef(null);
    const { addDragTarget, removeDragTarget } = useFileDragNDrop();
    useEffect(() => {
        const target = tagRef.current;
        if (target) {
            addDragTarget(target, {
                tooltipText: `Drop your files to upload and link them to tag "${tag.title}"`,
                async callback(file) {
                    await linkingController.linkItems(file, tag);
                },
            });
        }
        return () => {
            if (target) {
                removeDragTarget(target);
            }
        };
    }, [addDragTarget, linkingController, removeDragTarget, tag]);
    log(LoggingDomain.NavigationList, 'Rendering TagsListItem');
    const onDragStart = useCallback((event) => {
        event.dataTransfer.setData(TagDragDataFormat, tag.uuid);
    }, [tag.uuid]);
    const onDragEnter = useCallback((event) => {
        if (event.dataTransfer.types.includes(TagDragDataFormat) ||
            event.dataTransfer.types.includes(NoteDragDataFormat)) {
            event.preventDefault();
            setIsBeingDraggedOver(true);
        }
    }, []);
    const removeDragIndicator = useCallback(() => {
        setIsBeingDraggedOver(false);
    }, []);
    const onDragOver = useCallback((event) => {
        if (event.dataTransfer.types.includes(TagDragDataFormat) ||
            event.dataTransfer.types.includes(NoteDragDataFormat)) {
            event.preventDefault();
        }
    }, []);
    const onDrop = useCallback(async (event) => {
        setIsBeingDraggedOver(false);
        const draggedTagUuid = event.dataTransfer.getData(TagDragDataFormat);
        const draggedNoteUuid = event.dataTransfer.getData(NoteDragDataFormat);
        if (draggedTagUuid) {
            if (!navigationController.isValidTagParent(tag, { uuid: draggedTagUuid })) {
                return;
            }
            if (!hasFolders) {
                premiumModal.activate(TAG_FOLDERS_FEATURE_NAME);
                return;
            }
            void navigationController.assignParent(draggedTagUuid, tag.uuid);
            return;
        }
        else if (draggedNoteUuid) {
            const currentTag = navigationController.selected;
            const shouldSwapTags = currentTag instanceof SNTag && currentTag.uuid !== tag.uuid;
            const note = application.items.findSureItem(draggedNoteUuid);
            await linkingController.linkItems(note, tag);
            if (shouldSwapTags) {
                await linkingController.unlinkItems(note, currentTag);
            }
            return;
        }
    }, [application.items, hasFolders, linkingController, navigationController, premiumModal, tag]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { role: "button", tabIndex: FOCUSABLE_BUT_NOT_TABBABLE, className: classNames('tag group px-3.5 py-0.5 focus-visible:!shadow-inner md:py-0', (isSelected || isContextMenuOpenForTag) && 'selected', isBeingDraggedOver && 'is-drag-over'), onClick: selectCurrentTag, onKeyDown: (event) => {
                    if (event.key === KeyboardKey.Enter || event.key === KeyboardKey.Space) {
                        selectCurrentTag().catch(console.error);
                    }
                    else if (event.key === KeyboardKey.Left) {
                        setTagExpanded(false);
                    }
                    else if (event.key === KeyboardKey.Right) {
                        setTagExpanded(true);
                    }
                }, ref: tagRef, style: {
                    paddingLeft: `${level * PADDING_PER_LEVEL_PX + PADDING_BASE_PX}px`,
                }, onContextMenu: (e) => {
                    e.preventDefault();
                    onContextMenu(tag, type, e.clientX, e.clientY);
                }, draggable: !navigationController.isSearching, onDragStart: onDragStart, onDragEnter: onDragEnter, onDragExit: removeDragIndicator, onDragOver: onDragOver, onDragLeave: removeDragIndicator, onDrop: onDrop, children: [_jsxs("div", { className: "tag-info", title: title, children: [_jsx("div", { onClick: selectCurrentTag, className: 'tag-icon draggable mr-2', children: _jsx(Icon, { type: tag.iconString, className: classNames('cursor-pointer group-hover:text-text', isSelected ? 'text-info' : 'text-neutral') }) }), isEditing && (_jsx("input", { className: 'title editing overflow-hidden text-mobile-navigation-list-item focus:shadow-none focus:outline-none lg:text-navigation-list-item', id: `react-tag-${tag.uuid}-${type}`, onBlur: onBlur, onInput: onInput, value: title, onKeyDown: onKeyDown, spellCheck: false, ref: inputRef })), !isEditing && (_jsx(_Fragment, { children: _jsx("div", { className: 'title overflow-hidden text-left text-mobile-navigation-list-item focus:shadow-none focus:outline-none lg:text-navigation-list-item', id: `react-tag-${tag.uuid}-${type}`, children: title }) })), _jsxs("div", { className: "flex items-center", children: [isSelected && (_jsx("a", { role: "button", className: 'mr-2 cursor-pointer border-0 bg-transparent hover:bg-contrast focus:shadow-inner', onClick: toggleContextMenu, onMouseDown: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }, ref: menuButtonRef, children: _jsx(Icon, { type: "more", className: "text-neutral" }) })), hasChildren && (_jsx("a", { role: "button", className: `focus:shadow-inner ${showChildren ? 'cursor-n-resize' : 'cursor-s-resize'} ${showChildren ? 'opened' : 'closed'} `, onClick: toggleChildren, children: _jsx(Icon, { className: 'text-neutral', size: "large", type: showChildren ? 'menu-arrow-down-alt' : 'menu-arrow-right' }) })), _jsx("div", { onClick: hasChildren ? toggleChildren : undefined, className: `count text-base lg:text-sm ${hasChildren ? (showChildren ? 'cursor-n-resize' : 'cursor-s-resize') : ''}`, children: noteCounts.get() })] })] }), tag.conflictOf && _jsx("div", { className: "-mt-1 text-[0.625rem] font-bold text-danger", children: "Conflicted Copy" })] }), isAddingSubtag && (_jsx("div", { className: "tag overflow-hidden", style: {
                    paddingLeft: `${(level + 1) * PADDING_PER_LEVEL_PX + PADDING_BASE_PX}px`,
                }, children: _jsxs("div", { className: "tag-info", children: [_jsx("div", { className: "flex h-full min-w-[22px] items-center border-0 bg-transparent p-0" }), _jsx("div", { className: "tag-icon mr-1", children: _jsx(Icon, { type: "hashtag", className: "mr-1 text-neutral" }) }), _jsx("input", { className: "title w-full text-mobile-navigation-list-item focus:shadow-none focus:outline-none lg:text-navigation-list-item", type: "text", ref: subtagInputRef, onBlur: onSubtagInputBlur, onKeyDown: onSubtagKeyDown, value: subtagTitle, onInput: onSubtagInput })] }) })), showChildren && (_jsx(_Fragment, { children: childrenTags.map((tag) => {
                    return (_jsx(TagsListItem, { level: level + 1, tag: tag, type: type, navigationController: navigationController, features: features, linkingController: linkingController, onContextMenu: onContextMenu }, tag.uuid));
                }) }))] }));
});
TagsListItem.displayName = 'TagsListItem';
