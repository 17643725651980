import { action, computed, makeObservable, observable } from 'mobx';
import { RootQueryParam } from '@standardnotes/ui-services';
import { AbstractViewController } from './Abstract/AbstractViewController';
const DEFAULT_PANE = 'account';
export class PreferencesController extends AbstractViewController {
    constructor(routeService, eventBus) {
        super(eventBus);
        this.routeService = routeService;
        this._open = false;
        this.currentPane = DEFAULT_PANE;
        this.setCurrentPane = (prefId) => {
            this.currentPane = prefId;
        };
        this.openPreferences = (prefId) => {
            if (prefId) {
                this.currentPane = prefId;
            }
            this._open = true;
        };
        this.closePreferences = () => {
            this._open = false;
            this.currentPane = DEFAULT_PANE;
            this.routeService.removeQueryParameterFromURL(RootQueryParam.Settings);
        };
        makeObservable(this, {
            _open: observable,
            currentPane: observable,
            openPreferences: action,
            closePreferences: action,
            setCurrentPane: action,
            isOpen: computed,
        });
    }
    get isOpen() {
        return this._open;
    }
}
