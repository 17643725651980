import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { doesItemMatchSearchQuery } from '@/Utils/Items/Search/doesItemMatchSearchQuery';
import { Combobox, ComboboxItem, ComboboxPopover, useComboboxStore, VisuallyHidden, } from '@ariakit/react';
import { classNames, naturalSort } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useDeferredValue, useEffect, useState } from 'react';
import { useApplication } from '../ApplicationProvider';
import LinkedItemMeta from '../LinkedItems/LinkedItemMeta';
const ItemSelectionDropdown = ({ contentTypes, placeholder, onSelection, comboboxProps, className = {} }) => {
    const application = useApplication();
    const combobox = useComboboxStore(comboboxProps);
    const value = combobox.useState('value');
    const open = combobox.useState('open');
    useEffect(() => {
        if (value.length < 1 && open) {
            combobox.setOpen(false);
        }
    }, [combobox, open, value.length]);
    const searchQuery = useDeferredValue(value);
    const [items, setItems] = useState([]);
    useEffect(() => {
        const searchableItems = naturalSort(application.items.getItems(contentTypes), 'title');
        const filteredItems = searchableItems.filter((item) => {
            return doesItemMatchSearchQuery(item, searchQuery, application);
        });
        setItems(filteredItems);
    }, [searchQuery, application, contentTypes]);
    return (_jsxs("div", { children: [_jsx(VisuallyHidden, { children: "Select an item" }), _jsx(Combobox, { store: combobox, placeholder: placeholder, className: classNames('h-7 w-70 bg-transparent text-sm text-text focus:border-b-2 focus:border-info focus:shadow-none focus:outline-none lg:text-xs', className.input) }), _jsx(ComboboxPopover, { store: combobox, className: classNames('z-dropdown-menu max-h-[var(--popover-available-height)] w-[var(--popover-anchor-width)] overflow-y-auto rounded bg-default py-2 shadow-main', className.popover), children: items.length > 0 ? (items.map((item) => (_jsx(ComboboxItem, { className: "flex w-full cursor-pointer items-center justify-between gap-4 overflow-hidden px-3 py-2 hover:bg-contrast hover:text-foreground [&[data-active-item]]:bg-info-backdrop", hideOnClick: true, onClick: () => {
                        combobox.setValue('');
                        onSelection(item);
                    }, children: _jsx(LinkedItemMeta, { item: item, searchQuery: searchQuery }) }, item.uuid)))) : (_jsx("div", { className: "px-2", children: "No results found" })) })] }));
};
export default observer(ItemSelectionDropdown);
