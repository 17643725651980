import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { areDatesInSameDay, areDatesInSameMonth } from '@/Utils/DateUtils';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Calendar from './Calendar';
import { CalendarMonths } from './Constants';
import { insertMonths, insertMonthsWithTarget } from './CalendarUtilts';
import { InfinteScroller } from '../InfiniteScroller/InfiniteScroller';
import { classNames } from '@standardnotes/utils';
import { LoggingDomain, log } from '@/Logging';
import { usePrevious } from './usePrevious';
import { isMobileScreen } from '@/Utils';
const PageSize = 2;
const InfiniteCalendar = forwardRef(({ activities, onDateSelect, selectedDay, className, children }, ref) => {
    const [expanded, setExpanded] = useState(isMobileScreen() ? false : true);
    const [restoreScrollAfterExpand, setRestoreScrollAfterExpand] = useState(false);
    const scrollerRef = useRef(null);
    const previousSelectedDay = usePrevious(selectedDay);
    const [activeDate, setActiveDate] = useState(new Date());
    const today = new Date();
    const [months, setMonths] = useState(() => {
        const base = [{ date: today }];
        insertMonths(base, 'front', 2);
        insertMonths(base, 'end', 2);
        return base;
    });
    const hasMonthInList = useCallback((date) => {
        for (const month of months) {
            if (areDatesInSameMonth(month.date, date)) {
                return true;
            }
        }
        return false;
    }, [months]);
    const insertMonthInList = useCallback((date) => {
        setMonths(insertMonthsWithTarget(months, date));
    }, [months]);
    const scrollToMonth = useCallback((date) => {
        var _a;
        const elementId = elementIdForMonth(date);
        (_a = scrollerRef.current) === null || _a === void 0 ? void 0 : _a.scrollToElementId(elementId);
    }, [scrollerRef]);
    const goToMonth = useCallback((month) => {
        if (!hasMonthInList(month)) {
            insertMonthInList(month);
        }
        log(LoggingDomain.DailyNotes, '[Calendar] Scrolling to month', month, 'from goToMonth');
        setActiveDate(month);
        scrollToMonth(month);
    }, [hasMonthInList, insertMonthInList, scrollToMonth]);
    useImperativeHandle(ref, () => ({
        goToMonth(date) {
            goToMonth(date);
        },
    }), [goToMonth]);
    const resetNumberOfCalendarsToBase = useCallback((centerOnDate) => {
        const base = [{ date: centerOnDate }];
        insertMonths(base, 'front', 1);
        insertMonths(base, 'end', 1);
        setMonths(base);
    }, [setMonths]);
    useEffect(() => {
        if (selectedDay) {
            if (previousSelectedDay && areDatesInSameDay(previousSelectedDay, selectedDay)) {
                log(LoggingDomain.DailyNotes, '[Calendar] selectedDay has changed, but is same as previous', selectedDay);
                return;
            }
            log(LoggingDomain.DailyNotes, '[Calendar] selectedDay has changed, going to month:', selectedDay);
            goToMonth(selectedDay);
        }
    }, [selectedDay, goToMonth, previousSelectedDay]);
    useEffect(() => {
        if (!restoreScrollAfterExpand) {
            return;
        }
        if (expanded) {
            log(LoggingDomain.DailyNotes, '[Calendar] Scrolling to month', activeDate, 'from restoreScrollAfterExpand useEffect');
            scrollToMonth(activeDate);
            setRestoreScrollAfterExpand(false);
        }
    }, [expanded, scrollToMonth, activeDate, restoreScrollAfterExpand, setRestoreScrollAfterExpand]);
    const paginateLeft = useCallback(() => {
        log(LoggingDomain.DailyNotes, '[Calendar] paginateLeft');
        setMonths((prevMonths) => {
            const copy = prevMonths.slice();
            insertMonths(copy, 'front', PageSize);
            return copy;
        });
    }, [setMonths]);
    const paginateRight = useCallback(() => {
        log(LoggingDomain.DailyNotes, '[Calendar] paginateRight');
        setMonths((prevMonths) => {
            const copy = prevMonths.slice();
            insertMonths(copy, 'end', PageSize);
            return copy;
        });
    }, [setMonths]);
    const onElementVisibility = useCallback((id) => {
        const index = months.findIndex((candidate) => elementIdForMonth(candidate.date) === id);
        if (index >= 0) {
            const newMonth = months[index];
            log(LoggingDomain.DailyNotes, '[Calendar] Month element did become visible, setting activeDate', newMonth);
            setActiveDate(newMonth.date);
        }
    }, [months]);
    const toggleVisibility = useCallback(() => {
        setRestoreScrollAfterExpand(true);
        setExpanded(!expanded);
    }, [expanded, setExpanded, setRestoreScrollAfterExpand]);
    const elementIdForMonth = (date) => {
        return `month-${date.getFullYear()}-${date.getMonth()}`;
    };
    const handleDaySelection = useCallback((date) => {
        resetNumberOfCalendarsToBase(date);
        onDateSelect(date);
    }, [onDateSelect, resetNumberOfCalendarsToBase]);
    return (_jsxs("div", { className: 'border-b border-solid border-border', children: [_jsxs("div", { onClick: toggleVisibility, className: classNames('text-md flex cursor-pointer items-center justify-center px-4 py-2', 'text-center font-bold hover:bg-contrast'), children: [CalendarMonths[activeDate.getMonth()], " ", activeDate.getFullYear()] }), expanded && (_jsx(InfinteScroller, { paginateFront: paginateLeft, paginateEnd: paginateRight, direction: 'horizontal', onElementVisibility: onElementVisibility, ref: scrollerRef, className: className, isMobileScreen: isMobileScreen(), children: months.map((month) => {
                    const id = elementIdForMonth(month.date);
                    return (_jsx("div", { id: id, children: _jsx(Calendar, { className: "mx-2", activities: activities, onDateSelect: handleDaySelection, startDate: month.date, selectedDay: selectedDay }, id) }, id));
                }) })), expanded && children] }));
});
export default InfiniteCalendar;
