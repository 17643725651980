import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import Button from '@/Components/Button/Button';
import Spinner from '@/Components/Spinner/Spinner';
const HistoryModalFooter = ({ dismissModal, noteHistoryController, readonly = false }) => {
    const { selectedRevision, restoreRevision, restoreRevisionAsCopy, selectedEntry, deleteRemoteRevision } = noteHistoryController;
    const [isDeletingRevision, setIsDeletingRevision] = useState(false);
    const restoreSelectedRevision = useCallback(() => {
        if (selectedRevision) {
            void restoreRevision(selectedRevision);
            dismissModal();
        }
    }, [dismissModal, restoreRevision, selectedRevision]);
    const restoreAsCopy = useCallback(async () => {
        if (selectedRevision) {
            void restoreRevisionAsCopy(selectedRevision);
            dismissModal();
        }
    }, [dismissModal, restoreRevisionAsCopy, selectedRevision]);
    const deleteSelectedRevision = useCallback(async () => {
        if (!selectedEntry) {
            return;
        }
        setIsDeletingRevision(true);
        await deleteRemoteRevision(selectedEntry);
        setIsDeletingRevision(false);
    }, [deleteRemoteRevision, selectedEntry]);
    return (_jsxs("div", { className: "flex min-h-6 flex-shrink-0 flex-wrap items-center gap-2.5 border-t border-solid border-border px-2.5 py-2 md:justify-between", children: [_jsx(Button, { className: "py-1.35", label: "Close", onClick: dismissModal }), selectedRevision && selectedEntry && !readonly && (_jsxs(_Fragment, { children: [selectedEntry.uuid && (_jsx(Button, { className: "md:ml-auto", onClick: deleteSelectedRevision, children: isDeletingRevision ? _jsx(Spinner, { className: "my-1 h-3 w-3" }) : 'Delete this revision' })), _jsx(Button, { className: !selectedEntry.uuid ? 'md:ml-auto' : '', label: "Restore as a copy", onClick: restoreAsCopy }), _jsx(Button, { className: "", label: "Restore version", onClick: restoreSelectedRevision, primary: true })] }))] }));
};
export default observer(HistoryModalFooter);
