import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { useRef } from 'react';
import AccountMenu from '../AccountMenu/AccountMenu';
import Icon from '../Icon/Icon';
import Popover from '../Popover/Popover';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
import { observer } from 'mobx-react-lite';
const AccountMenuButton = ({ hasError, controller, mainApplicationGroup, onClickOutside, toggleMenu, user }) => {
    const buttonRef = useRef(null);
    const { show: isOpen } = controller;
    return (_jsxs(_Fragment, { children: [_jsx(StyledTooltip, { label: "Open account menu", children: _jsx("button", { ref: buttonRef, onClick: toggleMenu, className: classNames(isOpen ? 'bg-border' : '', 'flex h-full w-8 cursor-pointer items-center justify-center rounded-full'), children: _jsx("div", { className: hasError ? 'text-danger' : user ? 'text-info' : 'text-neutral', children: _jsx(Icon, { type: "account-circle", className: "h-5 w-5 hover:text-info" }) }) }) }), _jsx(Popover, { title: "Account", anchorElement: buttonRef, open: isOpen, togglePopover: toggleMenu, side: "top", align: "start", className: "py-2", children: _jsx(AccountMenu, { onClickOutside: onClickOutside, mainApplicationGroup: mainApplicationGroup }) })] }));
};
export default observer(AccountMenuButton);
