"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortTwoItems = exports.KeepSameOrder = exports.SortRightFirst = exports.SortLeftFirst = void 0;
const utils_1 = require("@standardnotes/utils");
const CollectionSort_1 = require("../Collection/CollectionSort");
exports.SortLeftFirst = -1;
exports.SortRightFirst = 1;
exports.KeepSameOrder = 0;
/** @O(n * log(n)) */
function sortTwoItems(a, b, sortBy, sortDirection, bypassPinCheck = false) {
    /** If the elements are undefined, move to beginning */
    if (!a) {
        return exports.SortLeftFirst;
    }
    if (!b) {
        return exports.SortRightFirst;
    }
    if (!bypassPinCheck) {
        if (a.pinned && b.pinned) {
            return sortTwoItems(a, b, sortBy, sortDirection, true);
        }
        if (a.pinned) {
            return exports.SortLeftFirst;
        }
        if (b.pinned) {
            return exports.SortRightFirst;
        }
    }
    const aValue = a[sortBy] || '';
    const bValue = b[sortBy] || '';
    const smallerNaturallyComesFirst = sortDirection === 'asc';
    let compareResult = exports.KeepSameOrder;
    /**
     * Check for string length due to issue on React Native 0.65.1
     * where empty strings causes crash:
     * https://github.com/facebook/react-native/issues/32174
     * */
    if (sortBy === CollectionSort_1.CollectionSort.Title &&
        (0, utils_1.isString)(aValue) &&
        (0, utils_1.isString)(bValue) &&
        aValue.length > 0 &&
        bValue.length > 0) {
        compareResult = aValue.localeCompare(bValue, 'en', { numeric: true });
    }
    else if (aValue > bValue) {
        compareResult = exports.SortRightFirst;
    }
    else if (aValue < bValue) {
        compareResult = exports.SortLeftFirst;
    }
    else {
        compareResult = exports.KeepSameOrder;
    }
    const isLeftSmaller = compareResult === exports.SortLeftFirst;
    const isLeftBigger = compareResult === exports.SortRightFirst;
    if (isLeftSmaller) {
        if (smallerNaturallyComesFirst) {
            return exports.SortLeftFirst;
        }
        else {
            return exports.SortRightFirst;
        }
    }
    else if (isLeftBigger) {
        if (smallerNaturallyComesFirst) {
            return exports.SortRightFirst;
        }
        else {
            return exports.SortLeftFirst;
        }
    }
    else {
        return exports.KeepSameOrder;
    }
}
exports.sortTwoItems = sortTwoItems;
