import { $setBlocksType } from '@lexical/selection';
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical';
import { BlockPickerOption } from '../BlockPickerPlugin/BlockPickerOption';
export const ParagraphBlock = {
    name: 'Paragraph',
    iconName: 'paragraph',
    keywords: ['normal', 'paragraph', 'p', 'text'],
    onSelect: (editor) => editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createParagraphNode());
        }
    }),
};
export function GetParagraphBlockOption(editor) {
    return new BlockPickerOption(ParagraphBlock.name, {
        iconName: ParagraphBlock.iconName,
        keywords: ParagraphBlock.keywords,
        onSelect: () => ParagraphBlock.onSelect(editor),
    });
}
