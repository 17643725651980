import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon/Icon';
import { observer } from 'mobx-react-lite';
import { SystemViewId, isSmartView } from '@standardnotes/snjs';
const MobileMultiSelectionToolbar = ({ notesController, navigationController }) => {
    const { selectedNotes } = notesController;
    const { selected } = navigationController;
    const archived = selectedNotes.some((note) => note.archived);
    return (_jsxs("div", { className: "flex w-full bg-contrast pb-safe-bottom", children: [_jsx("button", { className: "flex-grow px-2 py-3 active:bg-passive-3", onClick: () => notesController.togglePinSelectedNotes(), children: _jsx(Icon, { type: "pin", className: "mx-auto text-info", size: "large" }) }), _jsx("button", { className: "flex-grow px-2 py-3 active:bg-passive-3", onClick: () => notesController.toggleArchiveSelectedNotes().catch(console.error), children: _jsx(Icon, { type: archived ? 'unarchive' : 'archive', className: "mx-auto text-info", size: "large" }) }), _jsx("button", { className: "flex-grow px-2 py-3 active:bg-passive-3", onClick: () => {
                    const isInTrashView = selected && isSmartView(selected) && selected.uuid === SystemViewId.TrashedNotes;
                    const allSelectedNotesAreTrashed = selectedNotes.every((note) => note.trashed);
                    const shouldDeletePermanently = isInTrashView || allSelectedNotesAreTrashed;
                    if (shouldDeletePermanently) {
                        notesController.deleteNotesPermanently().catch(console.error);
                    }
                    else {
                        notesController.setTrashSelectedNotes(true).catch(console.error);
                    }
                }, children: _jsx(Icon, { type: "trash", className: "mx-auto text-info", size: "large" }) }), _jsx("button", { className: "flex-grow px-2 py-3 active:bg-passive-3", onClick: () => notesController.setContextMenuOpen(true), children: _jsx(Icon, { type: "more", className: "mx-auto text-info", size: "large" }) })] }));
};
export default observer(MobileMultiSelectionToolbar);
