import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $createCodeNode } from '@lexical/code';
import { $createTextNode, $getRoot, $nodesOfType, ParagraphNode } from 'lexical';
import { $convertToMarkdownString } from '@lexical/markdown';
import { MarkdownTransformers } from '../../MarkdownTransformers';
export default function MarkdownPreviewPlugin({ onMarkdown }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.update(() => {
            const root = $getRoot();
            const paragraphs = $nodesOfType(ParagraphNode);
            for (const paragraph of paragraphs) {
                if (paragraph.isEmpty()) {
                    paragraph.remove();
                }
            }
            const markdown = $convertToMarkdownString(MarkdownTransformers);
            root.clear().append($createCodeNode('markdown').append($createTextNode(markdown)));
            root.selectEnd();
            onMarkdown(markdown);
        });
    }, [editor, onMarkdown]);
    return null;
}
