"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticatorApiOperations = void 0;
var AuthenticatorApiOperations;
(function (AuthenticatorApiOperations) {
    AuthenticatorApiOperations[AuthenticatorApiOperations["List"] = 0] = "List";
    AuthenticatorApiOperations[AuthenticatorApiOperations["Delete"] = 1] = "Delete";
    AuthenticatorApiOperations[AuthenticatorApiOperations["GenerateRegistrationOptions"] = 2] = "GenerateRegistrationOptions";
    AuthenticatorApiOperations[AuthenticatorApiOperations["GenerateAuthenticationOptions"] = 3] = "GenerateAuthenticationOptions";
    AuthenticatorApiOperations[AuthenticatorApiOperations["VerifyRegistrationResponse"] = 4] = "VerifyRegistrationResponse";
})(AuthenticatorApiOperations || (exports.AuthenticatorApiOperations = AuthenticatorApiOperations = {}));
