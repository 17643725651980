"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConflictDelta = void 0;
const utils_1 = require("@standardnotes/utils");
const domain_core_1 = require("@standardnotes/domain-core");
const ItemGenerator_1 = require("../../Utilities/Item/ItemGenerator");
const HistoryMap_1 = require("../History/HistoryMap");
const ConflictStrategy_1 = require("../../Abstract/Item/Types/ConflictStrategy");
const PayloadsByDuplicating_1 = require("../../Utilities/Payload/PayloadsByDuplicating");
const PayloadContentsEqual_1 = require("../../Utilities/Payload/PayloadContentsEqual");
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
const ItemsKeyDelta_1 = require("./ItemsKeyDelta");
const DirtyCounter_1 = require("../DirtyCounter/DirtyCounter");
class ConflictDelta {
    constructor(baseCollection, basePayload, applyPayload, historyMap) {
        this.baseCollection = baseCollection;
        this.basePayload = basePayload;
        this.applyPayload = applyPayload;
        this.historyMap = historyMap;
    }
    result() {
        if (this.applyPayload.content_type === domain_core_1.ContentType.TYPES.ItemsKey) {
            const keyDelta = new ItemsKeyDelta_1.ItemsKeyDelta(this.baseCollection, [this.applyPayload]);
            return keyDelta.result();
        }
        const strategy = this.getConflictStrategy();
        return {
            emits: this.handleStrategy(strategy),
            ignored: [],
        };
    }
    getConflictStrategy() {
        const isBaseErrored = (0, TypeCheck_1.isErrorDecryptingPayload)(this.basePayload);
        const isApplyErrored = (0, TypeCheck_1.isErrorDecryptingPayload)(this.applyPayload);
        if (isBaseErrored || isApplyErrored) {
            if (isBaseErrored && !isApplyErrored) {
                return ConflictStrategy_1.ConflictStrategy.KeepBaseDuplicateApply;
            }
            else if (!isBaseErrored && isApplyErrored) {
                return ConflictStrategy_1.ConflictStrategy.DuplicateBaseKeepApply;
            }
            else if (isBaseErrored && isApplyErrored) {
                return ConflictStrategy_1.ConflictStrategy.KeepApply;
            }
        }
        else if ((0, TypeCheck_1.isDecryptedPayload)(this.basePayload)) {
            /**
             * Ensure no conflict has already been created with the incoming content.
             * This can occur in a multi-page sync request where in the middle of the request,
             * we make changes to many items, including duplicating, but since we are still not
             * uploading the changes until after the multi-page request completes, we may have
             * already conflicted this item.
             */
            const existingConflict = this.baseCollection.conflictsOf(this.applyPayload.uuid)[0];
            if (existingConflict &&
                (0, TypeCheck_1.isDecryptedPayload)(existingConflict) &&
                (0, TypeCheck_1.isDecryptedPayload)(this.applyPayload) &&
                (0, PayloadContentsEqual_1.PayloadContentsEqual)(existingConflict, this.applyPayload)) {
                /** Conflict exists and its contents are the same as incoming value, do not make duplicate */
                return ConflictStrategy_1.ConflictStrategy.KeepBase;
            }
            else {
                const tmpBaseItem = (0, ItemGenerator_1.CreateDecryptedItemFromPayload)(this.basePayload);
                const tmpApplyItem = (0, ItemGenerator_1.CreateItemFromPayload)(this.applyPayload);
                const historyEntries = this.historyMap[this.basePayload.uuid] || [];
                const previousRevision = HistoryMap_1.historyMapFunctions.getNewestRevision(historyEntries);
                return tmpBaseItem.strategyWhenConflictingWithItem(tmpApplyItem, previousRevision);
            }
        }
        else if ((0, TypeCheck_1.isDeletedPayload)(this.basePayload) || (0, TypeCheck_1.isDeletedPayload)(this.applyPayload)) {
            const baseDeleted = (0, TypeCheck_1.isDeletedPayload)(this.basePayload);
            const applyDeleted = (0, TypeCheck_1.isDeletedPayload)(this.applyPayload);
            if (baseDeleted && applyDeleted) {
                return ConflictStrategy_1.ConflictStrategy.KeepApply;
            }
            else {
                return ConflictStrategy_1.ConflictStrategy.KeepApply;
            }
        }
        throw Error('Unhandled strategy in Conflict Delta getConflictStrategy');
    }
    handleStrategy(strategy) {
        if (strategy === ConflictStrategy_1.ConflictStrategy.KeepBase) {
            return this.handleKeepBaseStrategy();
        }
        if (strategy === ConflictStrategy_1.ConflictStrategy.KeepApply) {
            return this.handleKeepApplyStrategy();
        }
        if (strategy === ConflictStrategy_1.ConflictStrategy.KeepBaseDuplicateApply) {
            return this.handleKeepBaseDuplicateApplyStrategy();
        }
        if (strategy === ConflictStrategy_1.ConflictStrategy.DuplicateBaseKeepApply) {
            return this.handleDuplicateBaseKeepApply();
        }
        if (strategy === ConflictStrategy_1.ConflictStrategy.KeepBaseMergeRefs) {
            return this.handleKeepBaseMergeRefsStrategy();
        }
        throw Error('Unhandled strategy in conflict delta payloadsByHandlingStrategy');
    }
    handleKeepBaseStrategy() {
        const updatedAt = this.applyPayload.serverUpdatedAt;
        const updatedAtTimestamp = this.applyPayload.updated_at_timestamp;
        const leftPayload = this.basePayload.copyAsSyncResolved({
            updated_at: updatedAt,
            updated_at_timestamp: updatedAtTimestamp,
            dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
            dirty: true,
            lastSyncEnd: new Date(),
        }, this.applyPayload.source);
        return [leftPayload];
    }
    handleKeepApplyStrategy() {
        const result = this.applyPayload.copyAsSyncResolved({
            lastSyncBegan: this.basePayload.lastSyncBegan,
            lastSyncEnd: new Date(),
            dirty: false,
        }, this.applyPayload.source);
        return [result];
    }
    handleKeepBaseDuplicateApplyStrategy() {
        const updatedAt = this.applyPayload.serverUpdatedAt;
        const updatedAtTimestamp = this.applyPayload.updated_at_timestamp;
        const leftPayload = this.basePayload.copyAsSyncResolved({
            updated_at: updatedAt,
            updated_at_timestamp: updatedAtTimestamp,
            dirty: true,
            dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
            lastSyncEnd: new Date(),
        }, this.applyPayload.source);
        const rightPayloads = (0, PayloadsByDuplicating_1.PayloadsByDuplicating)({
            payload: this.applyPayload,
            baseCollection: this.baseCollection,
            isConflict: true,
            source: this.applyPayload.source,
        });
        return [leftPayload].concat(rightPayloads);
    }
    handleDuplicateBaseKeepApply() {
        const leftPayloads = (0, PayloadsByDuplicating_1.PayloadsByDuplicating)({
            payload: this.basePayload,
            baseCollection: this.baseCollection,
            isConflict: true,
            source: this.applyPayload.source,
        });
        const rightPayload = this.applyPayload.copyAsSyncResolved({
            lastSyncBegan: this.basePayload.lastSyncBegan,
            dirty: false,
            lastSyncEnd: new Date(),
        }, this.applyPayload.source);
        return leftPayloads.concat([rightPayload]);
    }
    handleKeepBaseMergeRefsStrategy() {
        if (!(0, TypeCheck_1.isDecryptedPayload)(this.basePayload) || !(0, TypeCheck_1.isDecryptedPayload)(this.applyPayload)) {
            return [];
        }
        const refs = (0, utils_1.uniqCombineObjArrays)(this.basePayload.content.references, this.applyPayload.content.references, [
            'uuid',
            'content_type',
        ]);
        const updatedAt = this.applyPayload.serverUpdatedAt;
        const updatedAtTimestamp = this.applyPayload.updated_at_timestamp;
        const payload = this.basePayload.copyAsSyncResolved({
            updated_at: updatedAt,
            updated_at_timestamp: updatedAtTimestamp,
            dirty: true,
            dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
            lastSyncEnd: new Date(),
            content: Object.assign(Object.assign({}, this.basePayload.content), { references: refs }),
        }, this.applyPayload.source);
        return [payload];
    }
}
exports.ConflictDelta = ConflictDelta;
