/* istanbul ignore file */
export var SyncQueueStrategy;
(function (SyncQueueStrategy) {
    /**
     * Promise will be resolved on the next sync request after the current one completes.
     * If there is no scheduled sync request, one will be scheduled.
     */
    SyncQueueStrategy[SyncQueueStrategy["ResolveOnNext"] = 1] = "ResolveOnNext";
    /**
     * A new sync request is guarenteed to be generated for your request, no matter how long it takes.
     * Promise will be resolved whenever this sync request is processed in the serial queue.
     */
    SyncQueueStrategy[SyncQueueStrategy["ForceSpawnNew"] = 2] = "ForceSpawnNew";
})(SyncQueueStrategy || (SyncQueueStrategy = {}));
