"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeletedItem = void 0;
const GenericItem_1 = require("./GenericItem");
class DeletedItem extends GenericItem_1.GenericItem {
    constructor(payload) {
        super(payload);
        this.deleted = true;
    }
    payloadRepresentation(override) {
        return this.payload.copy(override);
    }
}
exports.DeletedItem = DeletedItem;
