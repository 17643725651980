"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDeletedLocalStorageContextPayload = exports.CreateDecryptedLocalStorageContextPayload = exports.CreateEncryptedLocalStorageContextPayload = exports.isEncryptedLocalStoragePayload = void 0;
const utils_1 = require("@standardnotes/utils");
const TransferPayload_1 = require("../TransferPayload");
function isEncryptedLocalStoragePayload(p) {
    return (0, TransferPayload_1.isEncryptedTransferPayload)(p);
}
exports.isEncryptedLocalStoragePayload = isEncryptedLocalStoragePayload;
function CreateEncryptedLocalStorageContextPayload(fromPayload) {
    return {
        auth_hash: fromPayload.auth_hash,
        content_type: fromPayload.content_type,
        content: fromPayload.content,
        created_at_timestamp: fromPayload.created_at_timestamp,
        created_at: fromPayload.created_at,
        deleted: false,
        dirty: fromPayload.dirty != undefined ? fromPayload.dirty : false,
        duplicate_of: fromPayload.duplicate_of,
        enc_item_key: fromPayload.enc_item_key,
        errorDecrypting: fromPayload.errorDecrypting,
        items_key_id: fromPayload.items_key_id,
        updated_at_timestamp: fromPayload.updated_at_timestamp,
        updated_at: fromPayload.updated_at,
        uuid: fromPayload.uuid,
        waitingForKey: fromPayload.waitingForKey,
        user_uuid: fromPayload.user_uuid,
        key_system_identifier: fromPayload.key_system_identifier,
        shared_vault_uuid: fromPayload.shared_vault_uuid,
        last_edited_by_uuid: fromPayload.last_edited_by_uuid,
        signatureData: fromPayload.signatureData,
    };
}
exports.CreateEncryptedLocalStorageContextPayload = CreateEncryptedLocalStorageContextPayload;
function CreateDecryptedLocalStorageContextPayload(fromPayload) {
    return {
        content_type: fromPayload.content_type,
        content: fromPayload.content,
        created_at_timestamp: fromPayload.created_at_timestamp,
        created_at: fromPayload.created_at,
        deleted: false,
        duplicate_of: fromPayload.duplicate_of,
        updated_at_timestamp: fromPayload.updated_at_timestamp,
        updated_at: fromPayload.updated_at,
        uuid: fromPayload.uuid,
        dirty: (0, utils_1.useBoolean)(fromPayload.dirty, false),
        user_uuid: fromPayload.user_uuid,
        key_system_identifier: fromPayload.key_system_identifier,
        shared_vault_uuid: fromPayload.shared_vault_uuid,
        last_edited_by_uuid: fromPayload.last_edited_by_uuid,
        signatureData: fromPayload.signatureData,
    };
}
exports.CreateDecryptedLocalStorageContextPayload = CreateDecryptedLocalStorageContextPayload;
function CreateDeletedLocalStorageContextPayload(fromPayload) {
    return {
        content_type: fromPayload.content_type,
        content: undefined,
        created_at_timestamp: fromPayload.created_at_timestamp,
        created_at: fromPayload.created_at,
        deleted: true,
        dirty: true,
        duplicate_of: fromPayload.duplicate_of,
        updated_at_timestamp: fromPayload.updated_at_timestamp,
        updated_at: fromPayload.updated_at,
        uuid: fromPayload.uuid,
        user_uuid: fromPayload.user_uuid,
        key_system_identifier: fromPayload.key_system_identifier,
        shared_vault_uuid: fromPayload.shared_vault_uuid,
        last_edited_by_uuid: fromPayload.last_edited_by_uuid,
        signatureData: fromPayload.signatureData,
    };
}
exports.CreateDeletedLocalStorageContextPayload = CreateDeletedLocalStorageContextPayload;
