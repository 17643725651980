import { CompoundPredicateBuilderController } from '@/Components/SmartViewBuilder/CompoundPredicateBuilderController';
import { predicateFromJson, SmartViewDefaultIconName, } from '@standardnotes/snjs';
import { action, makeObservable, observable } from 'mobx';
export class AddSmartViewModalController {
    constructor(application) {
        this.application = application;
        this.isAddingSmartView = false;
        this.isSaving = false;
        this.title = '';
        this.icon = SmartViewDefaultIconName;
        this.predicateController = new CompoundPredicateBuilderController();
        this.customPredicateJson = undefined;
        this.isCustomJsonValidPredicate = undefined;
        this.setIsAddingSmartView = (isAddingSmartView) => {
            this.isAddingSmartView = isAddingSmartView;
        };
        this.setIsSaving = (isSaving) => {
            this.isSaving = isSaving;
        };
        this.setTitle = (title) => {
            this.title = title;
        };
        this.setIcon = (icon) => {
            this.icon = icon;
        };
        this.setCustomPredicateJson = (customPredicateJson) => {
            this.customPredicateJson = customPredicateJson;
        };
        this.setIsCustomJsonValidPredicate = (isCustomJsonValidPredicate) => {
            this.isCustomJsonValidPredicate = isCustomJsonValidPredicate;
        };
        this.closeModal = () => {
            this.setIsAddingSmartView(false);
            this.setTitle('');
            this.setIcon('');
            this.setIsSaving(false);
            this.predicateController.resetState();
            this.setCustomPredicateJson('');
            this.setIsCustomJsonValidPredicate(undefined);
        };
        this.saveCurrentSmartView = async () => {
            this.setIsSaving(true);
            if (!this.title) {
                this.setIsSaving(false);
                return;
            }
            const predicateJson = this.customPredicateJson && this.isCustomJsonValidPredicate
                ? JSON.parse(this.customPredicateJson)
                : this.predicateController.toJson();
            const predicate = predicateFromJson(predicateJson);
            await this.application.mutator.createSmartView({
                title: this.title,
                predicate,
                iconString: this.icon,
                vault: this.application.vaultDisplayService.exclusivelyShownVault,
            });
            this.setIsSaving(false);
            this.closeModal();
        };
        this.validateAndPrettifyCustomPredicate = () => {
            if (!this.customPredicateJson) {
                this.setIsCustomJsonValidPredicate(false);
                return;
            }
            try {
                const parsedPredicate = JSON.parse(this.customPredicateJson);
                const predicate = predicateFromJson(parsedPredicate);
                if (predicate) {
                    this.setCustomPredicateJson(JSON.stringify(parsedPredicate, null, 2));
                    this.setIsCustomJsonValidPredicate(true);
                }
                else {
                    this.setIsCustomJsonValidPredicate(false);
                }
            }
            catch (error) {
                this.setIsCustomJsonValidPredicate(false);
                return;
            }
        };
        makeObservable(this, {
            isAddingSmartView: observable,
            setIsAddingSmartView: action,
            isSaving: observable,
            setIsSaving: action,
            title: observable,
            setTitle: action,
            icon: observable,
            setIcon: action,
            customPredicateJson: observable,
            isCustomJsonValidPredicate: observable,
            setCustomPredicateJson: action,
            setIsCustomJsonValidPredicate: action,
        });
    }
}
