import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { AndroidConfirmBeforeExitKey } from '@/Components/Preferences/Panes/General/Defaults';
import { observer } from 'mobx-react-lite';
import { createContext, memo, useCallback, useContext, useEffect } from 'react';
const BackHandlerContext = createContext(null);
export const useAndroidBackHandler = () => {
    const value = useContext(BackHandlerContext);
    if (!value) {
        throw new Error('Component must be a child of <AndroidBackHandlerProvider />');
    }
    return value;
};
const MemoizedChildren = memo(({ children }) => _jsx(_Fragment, { children: children }));
const AndroidBackHandlerProvider = ({ application, children }) => {
    const addAndroidBackHandler = useCallback((listener) => application.addAndroidBackHandlerEventListener(listener), [application]);
    useEffect(() => {
        application.setAndroidBackHandlerFallbackListener(() => {
            var _a;
            const shouldConfirm = (_a = application.getValue(AndroidConfirmBeforeExitKey)) !== null && _a !== void 0 ? _a : true;
            application.mobileDevice.exitApp(shouldConfirm);
            return true;
        });
    }, [application]);
    return (_jsx(BackHandlerContext.Provider, { value: addAndroidBackHandler, children: _jsx(MemoizedChildren, { children: children }) }));
};
export default observer(AndroidBackHandlerProvider);
