"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNEditor = void 0;
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
/**
 * @deprecated
 * Editor objects are depracated in favor of ComponentItem objects
 */
class SNEditor extends DecryptedItem_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.notes = [];
        this.data = {};
        this.url = payload.content.url;
        this.name = payload.content.name;
        this.data = payload.content.data || {};
        this.isDefault = payload.content.default;
        this.systemEditor = payload.content.systemEditor;
    }
}
exports.SNEditor = SNEditor;
