import { assertUnreachable } from '@standardnotes/utils';
import { ChallengeValidation } from '../Types/ChallengeValidation';
import { ChallengePromptTitle } from './PromptTitles';
/* istanbul ignore file */
export class ChallengePrompt {
    constructor(validation, title, placeholder, secureTextEntry = true, keyboardType, initialValue, contextData) {
        this.validation = validation;
        this.secureTextEntry = secureTextEntry;
        this.keyboardType = keyboardType;
        this.initialValue = initialValue;
        this.contextData = contextData;
        this.id = Math.random();
        switch (this.validation) {
            case ChallengeValidation.AccountPassword:
                this.title = title !== null && title !== void 0 ? title : ChallengePromptTitle.AccountPassword;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : ChallengePromptTitle.AccountPassword;
                this.validates = true;
                break;
            case ChallengeValidation.LocalPasscode:
                this.title = title !== null && title !== void 0 ? title : ChallengePromptTitle.LocalPasscode;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : ChallengePromptTitle.LocalPasscode;
                this.validates = true;
                break;
            case ChallengeValidation.Biometric:
                this.title = title !== null && title !== void 0 ? title : ChallengePromptTitle.Biometrics;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = true;
                break;
            case ChallengeValidation.Authenticator:
                this.title = title !== null && title !== void 0 ? title : ChallengePromptTitle.U2F;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = true;
                break;
            case ChallengeValidation.ProtectionSessionDuration:
                this.title = title !== null && title !== void 0 ? title : ChallengePromptTitle.RememberFor;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = true;
                break;
            case ChallengeValidation.None:
                this.title = title !== null && title !== void 0 ? title : '';
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = false;
                break;
            default:
                assertUnreachable(this.validation);
        }
        Object.freeze(this);
    }
}
