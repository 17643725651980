import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { KeyboardKey } from '@standardnotes/ui-services';
import { useCallback, useEffect, useRef, useState, } from 'react';
import MenuRadioButtonItem from '@/Components/Menu/MenuRadioButtonItem';
const WorkspaceMenuItem = ({ descriptor, onClick, onDelete, renameDescriptor, hideOptions, }) => {
    const [isRenaming, setIsRenaming] = useState(false);
    const [inputValue, setInputValue] = useState(descriptor.label);
    const itemRef = useRef(null);
    const inputRef = useRef(null);
    useEffect(() => {
        var _a;
        if (isRenaming) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isRenaming]);
    const handleChange = useCallback((event) => {
        setInputValue(event.target.value);
    }, []);
    const handleInputKeyDown = useCallback((event) => {
        var _a;
        if (event.key === KeyboardKey.Enter || event.key === KeyboardKey.Escape) {
            event.preventDefault();
            event.stopPropagation();
            (_a = itemRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    const handleInputBlur = useCallback(() => {
        renameDescriptor(inputValue);
        setIsRenaming(false);
    }, [inputValue, renameDescriptor]);
    return (_jsxs("div", { className: "relative", children: [_jsx(MenuRadioButtonItem, { ref: itemRef, className: "overflow-hidden", onClick: onClick, checked: descriptor.primary, children: _jsxs("div", { className: "ml-2 flex w-full items-center justify-between gap-3 overflow-hidden", children: [!isRenaming && _jsx("div", { className: "overflow-hidden text-ellipsis", children: descriptor.label }), descriptor.primary && !hideOptions && (_jsxs("div", { className: "flex items-center gap-3", children: [_jsx("a", { role: "button", className: "flex h-5 w-5 cursor-pointer items-center justify-center border-0 bg-transparent p-0 hover:bg-contrast", onClick: (e) => {
                                        e.stopPropagation();
                                        setIsRenaming((isRenaming) => !isRenaming);
                                    }, children: _jsx(Icon, { type: "pencil", className: "text-neutral", size: "medium" }) }), _jsx("a", { role: "button", className: "flex h-5 w-5 cursor-pointer items-center justify-center border-0 bg-transparent p-0 hover:bg-contrast", onClick: (e) => {
                                        e.stopPropagation();
                                        onDelete();
                                    }, children: _jsx(Icon, { type: "trash", className: "text-danger", size: "medium" }) })] }))] }) }), isRenaming && (_jsx("input", { ref: inputRef, type: "text", value: inputValue, onChange: handleChange, onKeyDown: handleInputKeyDown, onBlur: handleInputBlur, onClick: (e) => e.stopPropagation(), className: "absolute left-11 top-1/2 -translate-y-1/2 bg-default" }))] }));
};
export default WorkspaceMenuItem;
