import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import SmartViewsListItem from './SmartViewsListItem';
import { useListKeyboardNavigation } from '@/Hooks/useListKeyboardNavigation';
const SmartViewsList = ({ navigationController, featuresController, setEditingSmartView, }) => {
    const allViews = navigationController.smartViews;
    const [container, setContainer] = useState(null);
    useListKeyboardNavigation(container, {
        initialFocus: 0,
        shouldAutoFocus: false,
        shouldWrapAround: false,
        resetLastFocusedOnBlur: true,
    });
    if (allViews.length === 0 && navigationController.isSearching) {
        return (_jsx("div", { className: "px-4 py-1 text-base opacity-60 lg:text-sm", children: "No smart views found. Try a different search." }));
    }
    return (_jsx("div", { ref: setContainer, children: allViews.map((view) => {
            return (_jsx(SmartViewsListItem, { view: view, tagsState: navigationController, features: featuresController, setEditingSmartView: setEditingSmartView }, view.uuid));
        }) }));
};
export default observer(SmartViewsList);
