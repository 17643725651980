import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useApplication } from '@/Components/ApplicationProvider';
import Button from '@/Components/Button/Button';
import { useState, useRef, useEffect } from 'react';
import { ButtonType } from '@standardnotes/snjs';
const PluginEntrySubInfo = ({ plugin }) => {
    const application = useApplication();
    const isThirdParty = 'identifier' in plugin && application.features.isThirdPartyFeature(plugin.identifier);
    const [isRenaming, setIsRenaming] = useState(false);
    const [newPluginName, setNewPluginName] = useState(plugin.name);
    const renameable = isThirdParty;
    const inputRef = useRef(null);
    useEffect(() => {
        var _a;
        if (isRenaming) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [inputRef, isRenaming]);
    const startRenaming = () => {
        setNewPluginName(plugin.name);
        setIsRenaming(true);
    };
    const cancelRename = () => {
        setNewPluginName(plugin.name);
        setIsRenaming(false);
    };
    const confirmRename = () => {
        if (!newPluginName) {
            return;
        }
        changeName(newPluginName);
        setIsRenaming(false);
    };
    const [_, setPluginName] = useState(plugin.displayName);
    const changeName = (newName) => {
        setPluginName(newName);
        application.changeAndSaveItem
            .execute(plugin, (mutator) => {
            mutator.name = newName;
        })
            .then((result) => {
            const component = result.getValue();
            setPluginName(component.name);
        })
            .catch(console.error);
    };
    const uninstall = async () => {
        application.alerts
            .confirm('Are you sure you want to uninstall this plugin?', 'Uninstall Plugin?', 'Uninstall', ButtonType.Danger, 'Cancel')
            .then(async (shouldRemove) => {
            if (shouldRemove) {
                await application.mutator.deleteItem(plugin);
                void application.sync.sync();
            }
        })
            .catch((err) => {
            application.alerts.alert(err).catch(console.error);
        });
    };
    return (_jsxs("div", { className: "align-center my-2.5 flex items-center justify-between md:items-center", children: [_jsx("input", { ref: inputRef, disabled: !isRenaming || !renameable, autoComplete: "off", className: "no-border mr-2 flex-grow rounded-sm bg-default px-0 py-1 text-sm font-bold text-text", type: "text", value: newPluginName, onChange: ({ target: input }) => setNewPluginName(input === null || input === void 0 ? void 0 : input.value) }), isRenaming && (_jsxs("div", { className: "flex gap-1", children: [_jsx(Button, { small: true, className: "cursor-pointer", onClick: confirmRename, children: "Confirm" }), _jsx(Button, { small: true, className: "cursor-pointer", onClick: cancelRename, children: "Cancel" })] })), !isRenaming && (_jsxs("div", { className: "flex flex-row flex-wrap justify-end gap-2", children: [renameable && !isRenaming && (_jsx(Button, { small: true, className: "cursor-pointer", onClick: startRenaming, children: "Rename" })), _jsx(Button, { small: true, className: "min-w-20", label: 'Uninstall', onClick: uninstall })] }))] }));
};
export default PluginEntrySubInfo;
