"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricMessageServer = void 0;
const Paths_1 = require("./Paths");
class AsymmetricMessageServer {
    constructor(httpService) {
        this.httpService = httpService;
    }
    createMessage(params) {
        return this.httpService.post(Paths_1.AsymmetricMessagesPaths.createMessage, {
            recipient_uuid: params.recipientUuid,
            encrypted_message: params.encryptedMessage,
            replaceability_identifier: params.replaceabilityIdentifier,
        });
    }
    getInboundUserMessages() {
        return this.httpService.get(Paths_1.AsymmetricMessagesPaths.getInboundUserMessages());
    }
    getOutboundUserMessages() {
        return this.httpService.get(Paths_1.AsymmetricMessagesPaths.getOutboundUserMessages());
    }
    getMessages() {
        return this.httpService.get(Paths_1.AsymmetricMessagesPaths.getMessages);
    }
    deleteMessage(params) {
        return this.httpService.delete(Paths_1.AsymmetricMessagesPaths.deleteMessage(params.messageUuid));
    }
    deleteAllInboundMessages() {
        return this.httpService.delete(Paths_1.AsymmetricMessagesPaths.deleteAllInboundMessages);
    }
}
exports.AsymmetricMessageServer = AsymmetricMessageServer;
