"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentItem = void 0;
const utils_1 = require("@standardnotes/utils");
const features_1 = require("@standardnotes/features");
const AppDataField_1 = require("../../Abstract/Item/Types/AppDataField");
const ConflictStrategy_1 = require("../../Abstract/Item/Types/ConflictStrategy");
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const Predicate_1 = require("../../Runtime/Predicate/Predicate");
const domain_core_1 = require("@standardnotes/domain-core");
/**
 * Components are mostly iframe based extensions that communicate with the SN parent
 * via the postMessage API. However, a theme can also be a component, which is activated
 * only by its url.
 */
class ComponentItem extends DecryptedItem_1.DecryptedItem {
    constructor(payload) {
        var _a, _b, _c;
        super(payload);
        this.permissions = [];
        if (payload.content.hosted_url && (0, utils_1.isValidUrl)(payload.content.hosted_url)) {
            this.hosted_url = payload.content.hosted_url;
        }
        else if (payload.content.url && (0, utils_1.isValidUrl)(payload.content.url)) {
            this.hosted_url = payload.content.url;
        }
        else if (payload.content.legacy_url && (0, utils_1.isValidUrl)(payload.content.legacy_url)) {
            this.hosted_url = payload.content.legacy_url;
        }
        this.local_url = payload.content.local_url;
        this.valid_until = new Date(payload.content.valid_until || 0);
        this.offlineOnly = (_a = payload.content.offlineOnly) !== null && _a !== void 0 ? _a : false;
        this.name = payload.content.name;
        if (this.content_type === domain_core_1.ContentType.TYPES.Theme) {
            this.area = features_1.ComponentArea.Themes;
        }
        else {
            this.area = payload.content.area;
        }
        this.package_info = payload.content.package_info || {};
        this.permissions = payload.content.permissions || [];
        this.autoupdateDisabled = (_b = payload.content.autoupdateDisabled) !== null && _b !== void 0 ? _b : false;
        this.disassociatedItemIds = payload.content.disassociatedItemIds || [];
        this.associatedItemIds = payload.content.associatedItemIds || [];
        /**
         * @legacy
         * We don't want to set this.url directly, as we'd like to phase it out.
         * If the content.url exists, we'll transfer it to legacy_url. We'll only
         * need to set this if content.hosted_url is blank, otherwise,
         * hosted_url is the url replacement.
         */
        this.legacy_url = !payload.content.hosted_url ? payload.content.url : undefined;
        this.legacyComponentData = this.payload.content.componentData || {};
        this.legacyActive = (_c = payload.content.active) !== null && _c !== void 0 ? _c : false;
    }
    /** Do not duplicate components under most circumstances. Always keep original */
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return ConflictStrategy_1.ConflictStrategy.KeepBase;
    }
    get isSingleton() {
        return true;
    }
    get displayName() {
        var _a;
        return ((_a = (0, features_1.FindNativeFeature)(this.identifier)) === null || _a === void 0 ? void 0 : _a.name) || this.name;
    }
    singletonPredicate() {
        const uniqueIdentifierPredicate = new Predicate_1.Predicate('identifier', '=', this.identifier);
        return uniqueIdentifierPredicate;
    }
    isTheme() {
        return this.content_type === domain_core_1.ContentType.TYPES.Theme || this.area === features_1.ComponentArea.Themes;
    }
    /** @deprecated Use global application PrefKey.DefaultEditorIdentifier */
    legacyIsDefaultEditor() {
        return this.getAppDomainValue(AppDataField_1.AppDataField.DefaultEditor) === true;
    }
    hasValidHostedUrl() {
        return (this.hosted_url || this.legacy_url) != undefined;
    }
    contentKeysToIgnoreWhenCheckingEquality() {
        const componentKeys = ['active', 'disassociatedItemIds', 'associatedItemIds'];
        const superKeys = super.contentKeysToIgnoreWhenCheckingEquality();
        return [...componentKeys, ...superKeys];
    }
    isExplicitlyEnabledForItem(uuid) {
        return this.associatedItemIds.indexOf(uuid) !== -1;
    }
    isExplicitlyDisabledForItem(uuid) {
        return this.disassociatedItemIds.indexOf(uuid) !== -1;
    }
    get isExpired() {
        return this.valid_until.getTime() > 0 && this.valid_until <= new Date();
    }
    get identifier() {
        return this.package_info.identifier;
    }
    get thirdPartyPackageInfo() {
        return this.package_info;
    }
    get noteType() {
        var _a;
        if ((0, features_1.isEditorFeatureDescription)(this.package_info)) {
            return (_a = this.package_info.note_type) !== null && _a !== void 0 ? _a : features_1.NoteType.Unknown;
        }
        return features_1.NoteType.Unknown;
    }
    get isDeprecated() {
        var _a;
        let flags = (_a = this.package_info.flags) !== null && _a !== void 0 ? _a : [];
        flags = flags.map((flag) => flag.toLowerCase());
        return flags.includes(features_1.ComponentFlag.Deprecated);
    }
    get deprecationMessage() {
        return this.package_info.deprecation_message;
    }
    get layerableTheme() {
        var _a;
        if (!this.isTheme()) {
            return false;
        }
        const themePackageInfo = this.package_info;
        return (_a = themePackageInfo === null || themePackageInfo === void 0 ? void 0 : themePackageInfo.layerable) !== null && _a !== void 0 ? _a : false;
    }
}
exports.ComponentItem = ComponentItem;
