import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FOCUSABLE_BUT_NOT_TABBABLE } from '@/Constants/Constants';
import { classNames } from '@standardnotes/snjs';
import { forwardRef } from 'react';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator/KeyboardShortcutIndicator';
import Switch from '../Switch/Switch';
import MenuListItem from './MenuListItem';
const MenuSwitchButtonItem = forwardRef(({ checked, onChange, disabled, onBlur, tabIndex, children, shortcut, className, forceDesktopStyle, ...props }, ref) => {
    return (_jsx(MenuListItem, { children: _jsxs("button", { disabled: disabled, ref: ref, className: classNames('flex w-full cursor-pointer items-center border-0 bg-transparent px-3 py-2 md:py-1.5', 'text-left text-text focus:bg-info-backdrop focus:shadow-none enabled:hover:bg-passive-3 enabled:hover:text-foreground', 'text-mobile-menu-item md:text-tablet-menu-item lg:text-menu-item', 'disabled:cursor-not-allowed disabled:opacity-60', className), onClick: () => {
                onChange(!checked);
            }, onBlur: onBlur, tabIndex: typeof tabIndex === 'number' ? tabIndex : FOCUSABLE_BUT_NOT_TABBABLE, role: "menuitemcheckbox", "aria-checked": checked, ...props, children: [_jsx("span", { className: "flex flex-grow items-center", children: children }), _jsxs("div", { className: "flex items-center", children: [shortcut && _jsx(KeyboardShortcutIndicator, { className: "mr-2", shortcut: shortcut }), _jsx(Switch, { disabled: disabled, className: "pointer-events-none flex px-0", checked: checked, onChange: onChange, tabIndex: FOCUSABLE_BUT_NOT_TABBABLE, forceDesktopStyle: forceDesktopStyle })] })] }) }));
});
export default MenuSwitchButtonItem;
