"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkRemotePayloadAllowed = exports.FilterDisallowedRemotePayloadsAndMap = void 0;
const TransferPayload_1 = require("../TransferPayload");
function CreateFilteredServerItem(item) {
    return Object.assign(Object.assign({}, item), { __passed_filter__: true });
}
function FilterDisallowedRemotePayloadsAndMap(payloads) {
    const filtered = [];
    const disallowed = [];
    for (const payload of payloads) {
        const result = checkRemotePayloadAllowed(payload);
        if (result.allowed === undefined) {
            disallowed.push(payload);
        }
        else {
            filtered.push(CreateFilteredServerItem(result.allowed));
        }
    }
    return {
        filtered,
        disallowed,
    };
}
exports.FilterDisallowedRemotePayloadsAndMap = FilterDisallowedRemotePayloadsAndMap;
function checkRemotePayloadAllowed(payload) {
    if ((0, TransferPayload_1.isCorruptTransferPayload)(payload)) {
        return { disallowed: payload };
    }
    if ((0, TransferPayload_1.isEncryptedTransferPayload)(payload) || payload.content == undefined) {
        return { allowed: payload };
    }
    else {
        return { disallowed: payload };
    }
}
exports.checkRemotePayloadAllowed = checkRemotePayloadAllowed;
