/**
 * Before 11/23/2022, web and desktop used independent semantic versioning. After this point, desktop automatically
 * uses web's versioning. The mapping below allows us to see what particular web version a legacy desktop version maps to
 */
export const LegacyWebToDesktopVersionMapping = {
    '3.107.0': '3.101.2',
    '3.106.0': '3.101.1',
    '3.105.0': '3.101.0',
    '3.104.1': '3.100.18',
    '3.104.0': '3.100.17',
    '3.103.2': '3.100.16',
    '3.103.1': '3.100.15',
    '3.103.0': '3.100.14',
    '3.102.0': '3.100.13',
    '3.101.2': '3.100.12',
    '3.101.1': '3.100.11',
    '3.101.0': '3.100.10',
    '3.100.8': '3.100.9',
    '3.100.7': '3.100.8',
    '3.100.6': '3.100.7',
    '3.100.5': '3.100.6',
    '3.100.4': '3.100.5',
    '3.100.3': '3.100.4',
    '3.100.2': '3.100.3',
    '3.100.1': '3.100.2',
    '3.100.0': '3.100.1',
    '3.99.0': '3.100.0',
    '3.98.2': '3.23.301',
    '3.98.1': '3.23.300',
    '3.98.0': '3.23.299',
    '3.97.0': '3.23.298',
    '3.96.1': '3.23.297',
    '3.96.0': '3.23.296',
    '3.95.1': '3.23.295',
    '3.95.0': '3.23.294',
    '3.94.2': '3.23.293',
    '3.94.1': '3.23.292',
    '3.94.0': '3.23.291',
    '3.93.19': '3.23.290',
    '3.93.18': '3.23.289',
    '3.93.17': '3.23.288',
    '3.93.16': '3.23.287',
    '3.93.15': '3.23.286',
    '3.93.14': '3.23.285',
    '3.93.13': '3.23.284',
    '3.93.12': '3.23.283',
    '3.93.11': '3.23.282',
    '3.93.10': '3.23.281',
    '3.93.9': '3.23.280',
    '3.93.8': '3.23.279',
    '3.93.7': '3.23.278',
    '3.93.6': '3.23.277',
    '3.93.5': '3.23.276',
    '3.93.4': '3.23.275',
    '3.93.3': '3.23.274',
    '3.93.2': '3.23.273',
    '3.93.1': '3.23.272',
    '3.93.0': '3.23.271',
    '3.92.0': '3.23.270',
    '3.91.1': '3.23.269',
    '3.91.0': '3.23.268',
    '3.90.11': '3.23.267',
    '3.90.10': '3.23.266',
    '3.90.9': '3.23.265',
    '3.90.8': '3.23.264',
    '3.90.7': '3.23.263',
    '3.90.6': '3.23.262',
    '3.90.5': '3.23.261',
    '3.90.4': '3.23.260',
    '3.90.3': '3.23.259',
    '3.90.2': '3.23.258',
    '3.90.1': '3.23.257',
    '3.90.0': '3.23.256',
    '3.89.0': '3.23.255',
    '3.88.1': '3.23.254',
    '3.88.0': '3.23.253',
    '3.87.2': '3.23.252',
    '3.87.1': '3.23.251',
    '3.87.0': '3.23.250',
    '3.86.0': '3.23.249',
    '3.85.2': '3.23.248',
    '3.85.1': '3.23.247',
    '3.85.0': '3.23.246',
    '3.84.7': '3.23.245',
    '3.84.6': '3.23.244',
    '3.84.5': '3.23.243',
    '3.84.4': '3.23.242',
    '3.84.3': '3.23.241',
    '3.84.2': '3.23.240',
    '3.84.1': '3.23.239',
    '3.84.0': '3.23.238',
    '3.83.0': '3.23.237',
    '3.82.6': '3.23.236',
    '3.82.5': '3.23.235',
    '3.82.4': '3.23.234',
    '3.82.3': '3.23.233',
    '3.82.2': '3.23.232',
    '3.82.1': '3.23.231',
    '3.82.0': '3.23.230',
    '3.81.0': '3.23.229',
    '3.80.1': '3.23.228',
    '3.80.0': '3.23.227',
    '3.79.0': '3.23.226',
    '3.78.1': '3.23.225',
    '3.78.0': '3.23.224',
    '3.77.1': '3.23.223',
    '3.77.0': '3.23.222',
    '3.76.2': '3.23.221',
    '3.76.1': '3.23.220',
    '3.76.0': '3.23.219',
    '3.75.1': '3.23.218',
    '3.75.0': '3.23.217',
    '3.74.0': '3.23.216',
    '3.73.0': '3.23.215',
    '3.72.4': '3.23.214',
    '3.72.3': '3.23.213',
    '3.72.2': '3.23.212',
    '3.72.1': '3.23.211',
    '3.72.0': '3.23.210',
    '3.71.8': '3.23.209',
    '3.71.7': '3.23.208',
    '3.71.6': '3.23.207',
    '3.71.5': '3.23.206',
    '3.71.4': '3.23.205',
    '3.71.3': '3.23.204',
    '3.71.2': '3.23.203',
    '3.71.1': '3.23.202',
    '3.71.0': '3.23.201',
    '3.70.5': '3.23.200',
    '3.70.4': '3.23.199',
    '3.70.3': '3.23.198',
    '3.70.2': '3.23.197',
    '3.70.1': '3.23.196',
    '3.70.0': '3.23.195',
    '3.69.1': '3.23.194',
    '3.69.0': '3.23.193',
    '3.68.6': '3.23.192',
    '3.68.5': '3.23.191',
    '3.68.4': '3.23.190',
    '3.68.3': '3.23.189',
    '3.68.2': '3.23.188',
    '3.68.1': '3.23.187',
    '3.68.0': '3.23.186',
    '3.67.4': '3.23.185',
    '3.67.3': '3.23.184',
    '3.67.2': '3.23.183',
    '3.67.1': '3.23.182',
    '3.67.0': '3.23.181',
    '3.66.0': '3.23.180',
    '3.65.0': '3.23.179',
    '3.64.0': '3.23.178',
    '3.63.3': '3.23.177',
    '3.63.2': '3.23.176',
    '3.63.1': '3.23.175',
    '3.63.0': '3.23.174',
    '3.62.4': '3.23.173',
    '3.62.3': '3.23.172',
    '3.62.2': '3.23.171',
    '3.62.1': '3.23.170',
    '3.62.0': '3.23.169',
    '3.61.0': '3.23.168',
    '3.60.0': '3.23.167',
    '3.59.3': '3.23.166',
    '3.59.2': '3.23.165',
    '3.59.1': '3.23.164',
    '3.59.0': '3.23.163',
    '3.58.1': '3.23.162',
    '3.58.0': '3.23.161',
    '3.57.3': '3.23.160',
    '3.57.2': '3.23.159',
    '3.57.1': '3.23.158',
    '3.57.0': '3.23.157',
    '3.56.0': '3.23.156',
    '3.55.2': '3.23.155',
    '3.55.1': '3.23.154',
    '3.55.0': '3.23.153',
    '3.54.6': '3.23.152',
    '3.54.5': '3.23.151',
    '3.54.4': '3.23.150',
    '3.54.3': '3.23.149',
    '3.54.2': '3.23.148',
    '3.54.1': '3.23.147',
    '3.54.0': '3.23.146',
    '3.53.0': '3.23.145',
    '3.52.1': '3.23.144',
    '3.52.0': '3.23.143',
    '3.51.0': '3.23.142',
    '3.50.6': '3.23.141',
    '3.50.5': '3.23.140',
    '3.50.4': '3.23.139',
    '3.50.3': '3.23.138',
    '3.50.2': '3.23.137',
    '3.50.1': '3.23.136',
    '3.50.0': '3.23.135',
    '3.49.3': '3.23.134',
    '3.49.2': '3.23.133',
    '3.49.1': '3.23.132',
    '3.49.0': '3.23.131',
    '3.48.3': '3.23.130',
    '3.48.2': '3.23.129',
    '3.48.1': '3.23.128',
    '3.48.0': '3.23.127',
    '3.47.3': '3.23.126',
    '3.47.2': '3.23.125',
    '3.47.1': '3.23.124',
    '3.47.0': '3.23.123',
    '3.46.3': '3.23.122',
    '3.46.2': '3.23.121',
    '3.46.1': '3.23.120',
    '3.46.0': '3.23.119',
    '3.45.18': '3.23.118',
    '3.45.17': '3.23.117',
    '3.45.16': '3.23.116',
    '3.45.15': '3.23.115',
    '3.45.14': '3.23.114',
    '3.45.13': '3.23.113',
    '3.45.12': '3.23.112',
    '3.45.11': '3.23.111',
    '3.45.10': '3.23.110',
    '3.45.9': '3.23.109',
    '3.45.8': '3.23.108',
    '3.45.7': '3.23.107',
    '3.45.6': '3.23.106',
    '3.45.5': '3.23.105',
    '3.45.4': '3.23.104',
    '3.45.3': '3.23.103',
    '3.45.2': '3.23.102',
    '3.45.1': '3.23.101',
    '3.45.0': '3.23.100',
    '3.44.7': '3.23.99',
    '3.44.6': '3.23.98',
    '3.44.5': '3.23.97',
    '3.44.4': '3.23.96',
    '3.44.3': '3.23.95',
    '3.44.2': '3.23.94',
    '3.44.1': '3.23.93',
    '3.44.0': '3.23.92',
    '3.43.1': '3.23.91',
    '3.43.0': '3.23.90',
    '3.42.1': '3.23.89',
    '3.42.0': '3.23.88',
    '3.41.3': '3.23.87',
    '3.41.2': '3.23.86',
    '3.41.1': '3.23.85',
    '3.41.0': '3.23.84',
    '3.40.7': '3.23.83',
    '3.40.6': '3.23.82',
    '3.40.5': '3.23.81',
    '3.40.4': '3.23.80',
    '3.40.3': '3.23.79',
    '3.40.2': '3.23.78',
    '3.40.1': '3.23.77',
    '3.40.0': '3.23.76',
    '3.39.1': '3.23.75',
    '3.39.0': '3.23.74',
    '3.38.6': '3.23.73',
    '3.38.5': '3.23.72',
    '3.38.4': '3.23.71',
    '3.38.3': '3.23.70',
    '3.38.2': '3.23.69',
    '3.38.1': '3.23.68',
    '3.38.0': '3.23.67',
    '3.37.7': '3.23.66',
    '3.37.6': '3.23.65',
    '3.37.5': '3.23.64',
    '3.37.4': '3.23.63',
    '3.37.3': '3.23.62',
    '3.37.2': '3.23.61',
    '3.37.1': '3.23.60',
    '3.37.0': '3.23.59',
    '3.36.0': '3.23.58',
    '3.35.2': '3.23.57',
    '3.35.1': '3.23.56',
    '3.35.0': '3.23.55',
    '3.34.0': '3.23.54',
    '3.33.8': '3.23.53',
    '3.33.7': '3.23.52',
    '3.33.6': '3.23.51',
    '3.33.5': '3.23.50',
    '3.33.4': '3.23.49',
    '3.33.3': '3.23.48',
    '3.33.2': '3.23.47',
    '3.33.1': '3.23.46',
    '3.33.0': '3.23.45',
    '3.32.0': '3.23.44',
    '3.31.1': '3.23.43',
    '3.31.0': '3.23.42',
    '3.30.0': '3.23.41',
    '3.29.2': '3.23.40',
    '3.29.1': '3.23.39',
    '3.29.0': '3.23.38',
    '3.28.1': '3.23.37',
    '3.28.0': '3.23.36',
    '3.27.7': '3.23.35',
    '3.27.6': '3.23.34',
    '3.27.5': '3.23.33',
    '3.27.4': '3.23.32',
    '3.27.3': '3.23.31',
    '3.27.2': '3.23.30',
    '3.27.1': '3.23.29',
    '3.27.0': '3.23.28',
    '3.26.4': '3.23.27',
    '3.26.3': '3.23.26',
    '3.26.2': '3.23.25',
    '3.26.1': '3.23.24',
    '3.26.0': '3.23.23',
    '3.25.0': '3.23.22',
    '3.24.6': '3.23.21',
    '3.24.5': '3.23.20',
    '3.24.4': '3.23.19',
    '3.24.3': '3.23.18',
    '3.24.3-alpha.1': '3.23.17',
    '3.24.3-alpha.0': '3.23.16',
    '3.24.2': '3.23.15',
    '3.24.2-alpha.1': '3.23.14',
    '3.24.2-alpha.0': '3.23.13',
    '3.24.1': '3.23.12',
    '3.24.1-alpha.0': '3.23.11',
    '3.24.0': '3.23.10',
    '3.24.0-alpha.7': '3.23.9',
    '3.24.0-alpha.6': '3.23.8',
    '3.24.0-alpha.5': '3.23.7',
    '3.24.0-alpha.4': '3.23.6',
    '3.24.0-alpha.3': '3.23.5',
    '3.24.0-alpha.2': '3.23.4',
    '3.24.0-alpha.1': '3.23.3',
    '3.24.0-alpha.0': '3.23.2',
    '3.23.0': '3.23.1',
    '3.23.0-alpha.1': '3.23.0',
    '3.23.0-alpha.0': '3.22.21',
    '3.22.7-alpha.0': '3.22.20',
    '3.22.6': '3.22.19',
    '3.22.6-alpha.0': '3.22.18',
    '3.22.5': '3.22.17',
    '3.22.4': '3.22.13-alpha.9',
    '3.22.3': '3.22.13-alpha.8',
    '3.22.3-alpha.9': '3.22.16-alpha.1',
    '3.22.3-alpha.8': '3.22.16-alpha.0',
    '3.22.3-alpha.7': '3.22.15',
    '3.22.3-alpha.6': '3.22.15-alpha.3',
    '3.22.3-alpha.5': '3.22.15-alpha.2',
    '3.22.3-alpha.4': '3.22.15-alpha.1',
    '3.22.3-alpha.3': '3.22.15-alpha.0',
    '3.22.3-alpha.2': '3.22.14',
    '3.22.3-alpha.1': '3.22.14-alpha.0',
    '3.22.3-alpha.0': '3.22.13',
    '3.22.2': '3.22.13-alpha.10',
    '3.22.1': '3.22.13-alpha.7',
    '3.22.0': '3.22.13-alpha.6',
    '3.21.0': '3.22.13-alpha.5',
};
