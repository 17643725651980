import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDateAndTimeForNote } from '@/Utils/DateUtils';
import { isNote } from '@standardnotes/snjs';
import { forwardRef } from 'react';
import ListItemFlagIcons from '../ListItemFlagIcons';
import ListItemMetadata from '../ListItemMetadata';
import ListItemTags from '../ListItemTags';
import ListItemNotePreviewText from '../ListItemNotePreviewText';
import { ListItemTitle } from '../ListItemTitle';
import { EmptyPlaceholderBars } from './EmptyPlaceholderBars';
const DaySquare = ({ day, hasActivity, weekday }) => {
    return (_jsx("div", { className: "mr-5", children: _jsxs("div", { className: `${hasActivity ? 'bg-danger text-danger-contrast' : 'bg-neutral text-neutral-contrast'} h-19 w-18 rounded p-2 text-center`, children: [_jsx("div", { className: "text-sm font-bold", children: weekday }), _jsx("div", { className: "text-4xl font-bold", children: day })] }) }));
};
export const DailyItemCell = forwardRef(({ item, tags = [], section, onClick, selected, hideDate = false, hidePreview = false, hideTags = false }, ref) => {
    return (_jsxs("div", { ref: ref, onClick: onClick, className: `content-list-item flex w-full cursor-pointer items-stretch text-text ${selected && 'selected border-l-2 border-solid border-danger'}`, id: section.id, children: [_jsx("div", { className: "min-w-0 flex-grow border-b border-solid border-border px-4 py-4", children: _jsxs("div", { className: "flex items-start overflow-hidden text-base", children: [_jsx(DaySquare, { weekday: section.weekday, hasActivity: item != undefined, day: section.day }), _jsxs("div", { className: "w-full leading-[1.3]", children: [item && (_jsxs(_Fragment, { children: [_jsx(ListItemTitle, { item: item }), isNote(item) && _jsx(ListItemNotePreviewText, { hidePreview: hidePreview, item: item, lineLimit: 5 }), _jsx(ListItemMetadata, { item: item, hideDate: hideDate, sortBy: 'created_at' }), _jsx(ListItemTags, { hideTags: hideTags, tags: tags })] })), !item && (_jsxs("div", { className: "w-full", children: [_jsx("div", { className: "break-word mr-2 font-semibold", children: formatDateAndTimeForNote(section.date, false) }), _jsx(EmptyPlaceholderBars, { rows: 1 })] }))] })] }) }), item && _jsx(ListItemFlagIcons, { item: item, hasFiles: false })] }));
});
