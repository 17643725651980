"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultDisplayOptions = void 0;
const utils_1 = require("@standardnotes/utils");
const VaultDisplayOptionsTypes_1 = require("./VaultDisplayOptionsTypes");
function KeySystemIdentifiers(vaults) {
    return vaults.map((vault) => vault.systemIdentifier);
}
class VaultDisplayOptions {
    constructor(options) {
        this.options = options;
    }
    getOptions() {
        return this.options;
    }
    getExclusivelyShownVault() {
        if ((0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options)) {
            throw new Error('Not in exclusive display mode');
        }
        return this.options.exclusive;
    }
    isInExclusiveDisplayMode() {
        return !(0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options);
    }
    isVaultExplicitlyExcluded(vault) {
        if ((0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options)) {
            return this.options.exclude.some((excludedVault) => excludedVault === vault.systemIdentifier);
        }
        else if (this.options.exclusive) {
            return this.options.exclusive !== vault.systemIdentifier;
        }
        throw new Error('Invalid vault display options');
    }
    isVaultExclusivelyShown(vault) {
        return !(0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options) && this.options.exclusive === vault.systemIdentifier;
    }
    isVaultDisabledOrLocked(vault) {
        if ((0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options)) {
            const matchingLocked = this.options.locked.find((lockedVault) => lockedVault === vault.systemIdentifier);
            if (matchingLocked) {
                return true;
            }
        }
        return this.isVaultExplicitlyExcluded(vault);
    }
    getPersistableValue() {
        return this.options;
    }
    newOptionsByIntakingLockedVaults(lockedVaults) {
        if ((0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options)) {
            return new VaultDisplayOptions({ exclude: this.options.exclude, locked: KeySystemIdentifiers(lockedVaults) });
        }
        else {
            return new VaultDisplayOptions({ exclusive: this.options.exclusive });
        }
    }
    newOptionsByExcludingVault(vault, lockedVaults) {
        return this.newOptionsByExcludingVaults([vault], lockedVaults);
    }
    newOptionsByExcludingVaults(vaults, lockedVaults) {
        if ((0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options)) {
            return new VaultDisplayOptions({
                exclude: (0, utils_1.uniqueArray)([...this.options.exclude, ...KeySystemIdentifiers(vaults)]),
                locked: KeySystemIdentifiers(lockedVaults),
            });
        }
        else {
            return new VaultDisplayOptions({
                exclude: KeySystemIdentifiers(vaults),
                locked: KeySystemIdentifiers(lockedVaults),
            });
        }
    }
    newOptionsByUnexcludingVault(vault, lockedVaults) {
        if ((0, VaultDisplayOptionsTypes_1.isExclusionaryOptionsValue)(this.options)) {
            return new VaultDisplayOptions({
                exclude: this.options.exclude.filter((excludedVault) => excludedVault !== vault.systemIdentifier),
                locked: KeySystemIdentifiers(lockedVaults),
            });
        }
        else {
            return new VaultDisplayOptions({ exclude: [], locked: KeySystemIdentifiers(lockedVaults) });
        }
    }
    static FromPersistableValue(value) {
        return new VaultDisplayOptions(value);
    }
}
exports.VaultDisplayOptions = VaultDisplayOptions;
