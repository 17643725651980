import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import InstallCustomPlugin from '@/Components/Preferences/Panes/Plugins/InstallCustom/InstallCustomPlugin';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import BrowsePlugins from './BrowsePlugins/BrowsePlugins';
import PreferencesPane from '../../PreferencesComponents/PreferencesPane';
import { Title } from '../../PreferencesComponents/Content';
import ManagePlugins from './ManagePlugins/ManagePlugins';
const PluginsPane = ({ pluginsLatestVersions }) => {
    return (_jsxs(PreferencesPane, { children: [_jsx(PreferencesGroup, { children: _jsx(PreferencesSegment, { children: _jsx(BrowsePlugins, {}) }) }), _jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Manage Plugins" }), _jsx(ManagePlugins, { className: 'mt-3', pluginsLatestVersions: pluginsLatestVersions })] }) }), _jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Install Custom Plugin" }), _jsx(InstallCustomPlugin, { className: 'mt-3' })] }) })] }));
};
export default observer(PluginsPane);
