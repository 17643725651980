import { DAYS_IN_A_WEEK, DAYS_IN_A_YEAR } from '@/Constants/Constants';
import { calculateDifferenceBetweenDatesInDays } from '../../Utils/CalculateDifferenceBetweenDatesInDays';
export const formatDateAsMonthYearString = (date) => {
    return date.toLocaleDateString(undefined, {
        month: 'long',
        year: 'numeric',
    });
};
export const getGroupIndexForEntry = (entry, groups) => {
    var _a;
    const todayAsDate = new Date();
    const entryDate = new Date((_a = entry.created_at) !== null && _a !== void 0 ? _a : entry.payload.updated_at);
    const differenceBetweenDatesInDays = calculateDifferenceBetweenDatesInDays(todayAsDate, entryDate);
    if (differenceBetweenDatesInDays === 0) {
        return groups.findIndex((group) => group.title === GROUP_TITLE_TODAY);
    }
    if (differenceBetweenDatesInDays > 0 && differenceBetweenDatesInDays < DAYS_IN_A_WEEK) {
        return groups.findIndex((group) => group.title === GROUP_TITLE_WEEK);
    }
    if (differenceBetweenDatesInDays > DAYS_IN_A_YEAR) {
        return groups.findIndex((group) => group.title === GROUP_TITLE_YEAR);
    }
    const formattedEntryMonthYear = formatDateAsMonthYearString(entryDate);
    return groups.findIndex((group) => group.title === formattedEntryMonthYear);
};
const GROUP_TITLE_TODAY = 'Today';
const GROUP_TITLE_WEEK = 'This Week';
const GROUP_TITLE_YEAR = 'More Than A Year Ago';
export const sortRevisionListIntoGroups = (revisionList) => {
    const sortedGroups = [
        {
            title: GROUP_TITLE_TODAY,
            entries: [],
        },
        {
            title: GROUP_TITLE_WEEK,
            entries: [],
        },
        {
            title: GROUP_TITLE_YEAR,
            entries: [],
        },
    ];
    const addBeforeLastGroup = (group) => {
        sortedGroups.splice(sortedGroups.length - 1, 0, group);
    };
    revisionList === null || revisionList === void 0 ? void 0 : revisionList.forEach((entry) => {
        var _a, _b, _c;
        const groupIndex = getGroupIndexForEntry(entry, sortedGroups);
        if (groupIndex > -1) {
            (_b = (_a = sortedGroups[groupIndex]) === null || _a === void 0 ? void 0 : _a.entries) === null || _b === void 0 ? void 0 : _b.push(entry);
        }
        else {
            addBeforeLastGroup({
                title: formatDateAsMonthYearString(new Date((_c = entry.created_at) !== null && _c !== void 0 ? _c : entry.payload.updated_at)),
                entries: [entry],
            });
        }
    });
    return sortedGroups;
};
export const previewHistoryEntryTitle = (revision) => {
    return new Date(revision.created_at).toLocaleString();
};
