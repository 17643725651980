import { jsx as _jsx } from "react/jsx-runtime";
import RevisionContentLocked from './RevisionContentLocked';
import { observer } from 'mobx-react-lite';
import { RevisionContentState } from '@/Controllers/NoteHistory/Types';
import Spinner from '@/Components/Spinner/Spinner';
import { ReadonlyNoteContent } from '../NoteView/ReadonlyNoteContent';
const HistoryModalContentPane = ({ noteHistoryController, note }) => {
    const { selectedRevision, contentState } = noteHistoryController;
    switch (contentState) {
        case RevisionContentState.Idle:
            return (_jsx("div", { className: "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 select-none text-sm text-passive-0", children: "No revision selected" }));
        case RevisionContentState.Loading:
            return _jsx(Spinner, { className: "absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2" });
        case RevisionContentState.Loaded:
            if (!selectedRevision) {
                return null;
            }
            return _jsx(ReadonlyNoteContent, { note: note, content: selectedRevision.payload.content, showLinkedItems: false });
        case RevisionContentState.NotEntitled:
            return _jsx(RevisionContentLocked, {});
        default:
            return null;
    }
};
export default observer(HistoryModalContentPane);
