import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDateForContextMenu } from '@/Utils/DateUtils';
import { getIconForFileType } from '@/Utils/Items/Icons/getIconForFileType';
import { formatSizeToReadableString } from '@standardnotes/filepicker';
import { FileItem, PrefKey, SystemViewId, SNNote, isSystemView, isSmartView, isNote, } from '@standardnotes/snjs';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { FileItemActionType } from '../AttachedFilesPopover/PopoverFileItemAction';
import { getFileIconComponent } from '../FilePreview/getFileIconComponent';
import Popover from '../Popover/Popover';
import Table from '../Table/Table';
import { useTable } from '../Table/useTable';
import Menu from '../Menu/Menu';
import FileMenuOptions from '../FileContextMenu/FileMenuOptions';
import Icon from '../Icon/Icon';
import LinkedItemBubble from '../LinkedItems/LinkedItemBubble';
import LinkedItemsPanel from '../LinkedItems/LinkedItemsPanel';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import { useApplication } from '../ApplicationProvider';
import { getIconAndTintForNoteType } from '@/Utils/Items/Icons/getIconAndTintForNoteType';
import NotesOptions from '../NotesOptions/NotesOptions';
import { useItemLinks } from '@/Hooks/useItemLinks';
import ListItemVaultInfo from '../ContentListView/ListItemVaultInfo';
const ContextMenuCell = ({ items }) => {
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const anchorElementRef = useRef(null);
    const allItemsAreNotes = useMemo(() => {
        return items.every((item) => item instanceof SNNote);
    }, [items]);
    const allItemsAreFiles = useMemo(() => {
        return items.every((item) => item instanceof FileItem);
    }, [items]);
    if (!allItemsAreNotes && !allItemsAreFiles) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("button", { className: "rounded-full border border-border bg-default p-1", ref: anchorElementRef, onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setContextMenuVisible((visible) => !visible);
                }, children: _jsx(Icon, { type: "more" }) }), _jsx(Popover, { title: "File options", open: contextMenuVisible, anchorElement: anchorElementRef, togglePopover: () => {
                    setContextMenuVisible(false);
                }, side: "bottom", align: "start", className: "py-2", children: _jsxs(Menu, { a11yLabel: "File context menu", children: [allItemsAreFiles && (_jsx(FileMenuOptions, { closeMenu: () => {
                                setContextMenuVisible(false);
                            }, shouldShowRenameOption: false, shouldShowAttachOption: false, selectedFiles: items })), allItemsAreNotes && (_jsx(NotesOptions, { notes: items, closeMenu: () => {
                                setContextMenuVisible(false);
                            } }))] }) })] }));
};
const ItemLinksCell = ({ item }) => {
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const anchorElementRef = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx("button", { className: "rounded-full border border-border bg-default p-1", ref: anchorElementRef, onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setContextMenuVisible((visible) => !visible);
                }, children: _jsx(Icon, { type: "link" }) }), _jsx(Popover, { title: "Linked items", open: contextMenuVisible, anchorElement: anchorElementRef, togglePopover: () => {
                    setContextMenuVisible(false);
                }, side: "bottom", align: "start", className: "py-2", children: _jsx(LinkedItemsPanel, { item: item }) })] }));
};
const ItemNameCell = ({ item, hideIcon }) => {
    const application = useApplication();
    const [backupInfo, setBackupInfo] = useState(undefined);
    const isItemFile = item instanceof FileItem;
    const editor = isNote(item) ? application.componentManager.editorForNote(item) : undefined;
    const noteType = isNote(item) ? item.noteType : editor ? editor.noteType : undefined;
    const [noteIcon, noteIconTint] = getIconAndTintForNoteType(noteType);
    useEffect(() => {
        var _a;
        if (isItemFile) {
            void ((_a = application.fileBackups) === null || _a === void 0 ? void 0 : _a.getFileBackupInfo(item).then(setBackupInfo));
        }
    }, [application, isItemFile, item]);
    return (_jsxs("div", { className: "flex items-center gap-3 whitespace-normal", children: [_jsxs("span", { className: "relative", children: [!hideIcon ? (isItemFile ? (getFileIconComponent(getIconForFileType(item.mimeType), 'w-6 h-6 flex-shrink-0')) : (_jsx(Icon, { type: noteIcon, className: `text-accessory-tint-${noteIconTint}` }))) : null, backupInfo && (_jsx("div", { className: "absolute bottom-1 right-1 translate-x-1/2 translate-y-1/2 rounded-full bg-default text-success", title: "File is backed up locally", children: _jsx(Icon, { size: "small", type: "check-circle-filled" }) }))] }), _jsx("span", { className: "overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium", children: item.title }), _jsx(ListItemVaultInfo, { item: item }), item.protected && (_jsx("span", { className: "flex items-center", title: "File is protected", children: _jsx(Icon, { ariaLabel: "File is protected", type: "lock-filled", className: "h-3.5 w-3.5 text-passive-1", size: "custom" }) }))] }));
};
const AttachedToCell = ({ item }) => {
    const { notesLinkedToItem, notesLinkingToItem, filesLinkedToItem, filesLinkingToItem, tagsLinkedToItem } = useItemLinks(item);
    const application = useApplication();
    const allLinks = notesLinkedToItem.concat(notesLinkingToItem, filesLinkedToItem, filesLinkingToItem, tagsLinkedToItem);
    if (!allLinks.length) {
        return null;
    }
    return (_jsxs("div", { className: "flex items-center gap-2 overflow-hidden", children: [_jsx(LinkedItemBubble, { className: "overflow-hidden border border-transparent hover:border-border focus:border-info focus:shadow-none", link: allLinks[0], unlinkItem: async (itemToUnlink) => {
                    void application.mutator.unlinkItems(item, itemToUnlink);
                }, isBidirectional: false }, allLinks[0].id), allLinks.length - 1 >= 1 && _jsxs("span", { children: ["and ", allLinks.length - 1, " more..."] })] }));
};
const ContentTableView = ({ application, items }) => {
    const listHasFiles = items.some((item) => item instanceof FileItem);
    const { sortBy, sortDirection } = application.itemListController.displayOptions;
    const sortReversed = sortDirection === 'asc';
    const { hideDate, hideEditorIcon: hideIcon, hideTags } = application.itemListController.webDisplayOptions;
    const onSortChange = useCallback(async (sortBy, sortReversed) => {
        const selectedTag = application.navigationController.selected;
        if (!selectedTag) {
            return;
        }
        if (selectedTag.uuid === SystemViewId.Files) {
            const systemViewPreferences = application.getPreference(PrefKey.SystemViewPreferences) || {};
            const filesViewPreferences = systemViewPreferences[SystemViewId.Files] || {};
            await application.setPreference(PrefKey.SystemViewPreferences, {
                ...systemViewPreferences,
                [SystemViewId.Files]: {
                    ...filesViewPreferences,
                    sortBy,
                    sortReverse: sortReversed,
                },
            });
            return;
        }
        const isNonFilesSystemView = isSmartView(selectedTag) && isSystemView(selectedTag);
        if (isNonFilesSystemView) {
            return;
        }
        await application.changeAndSaveItem.execute(selectedTag, (mutator) => {
            mutator.preferences = {
                ...mutator.preferences,
                sortBy,
                sortReverse: sortReversed,
            };
        });
    }, [application]);
    const [contextMenuItem, setContextMenuItem] = useState(undefined);
    const [contextMenuPosition, setContextMenuPosition] = useState(undefined);
    const isSmallBreakpoint = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const isMediumBreakpoint = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.md);
    const isLargeBreakpoint = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.lg);
    const columnDefs = useMemo(() => [
        {
            name: 'Name',
            sortBy: 'title',
            cell: (item) => _jsx(ItemNameCell, { item: item, hideIcon: hideIcon }),
        },
        {
            name: 'Upload date',
            sortBy: 'created_at',
            cell: (item) => {
                return formatDateForContextMenu(item.created_at);
            },
            hidden: isSmallBreakpoint || hideDate,
        },
        {
            name: 'Size',
            sortBy: 'decryptedSize',
            cell: (item) => {
                return item instanceof FileItem ? formatSizeToReadableString(item.decryptedSize) : null;
            },
            hidden: isSmallBreakpoint || !listHasFiles,
        },
        {
            name: 'Attached to',
            hidden: isSmallBreakpoint || isMediumBreakpoint || isLargeBreakpoint || hideTags,
            cell: (item) => _jsx(AttachedToCell, { item: item }),
        },
    ], [hideDate, hideIcon, hideTags, isLargeBreakpoint, isMediumBreakpoint, isSmallBreakpoint, listHasFiles]);
    const getRowId = useCallback((item) => item.uuid, []);
    const table = useTable({
        data: items,
        sortBy,
        sortReversed,
        onSortChange,
        getRowId,
        columns: columnDefs,
        enableRowSelection: true,
        enableMultipleRowSelection: true,
        onRowActivate(item) {
            if (item instanceof FileItem) {
                void application.filesController.handleFileAction({
                    type: FileItemActionType.PreviewFile,
                    payload: {
                        file: item,
                        otherFiles: items.filter((i) => i instanceof FileItem),
                    },
                });
            }
        },
        onRowContextMenu(x, y, file) {
            setContextMenuPosition({ x, y });
            setContextMenuItem(file);
        },
        rowActions: (item) => {
            const vault = application.vaults.getItemVault(item);
            const isReadonly = (vault === null || vault === void 0 ? void 0 : vault.isSharedVaultListing()) && application.vaultUsers.isCurrentUserReadonlyVaultMember(vault);
            return (_jsxs("div", { className: "flex items-center gap-2", children: [!isReadonly && _jsx(ItemLinksCell, { item: item }), _jsx(ContextMenuCell, { items: [item] })] }));
        },
        selectionActions: (itemIds) => _jsx(ContextMenuCell, { items: items.filter((item) => itemIds.includes(item.uuid)) }),
        showSelectionActions: true,
    });
    const closeContextMenu = () => {
        setContextMenuPosition(undefined);
        setContextMenuItem(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Table, { table: table }), contextMenuPosition && contextMenuItem && (_jsxs(Popover, { title: "Options", open: true, anchorPoint: contextMenuPosition, togglePopover: () => {
                    setContextMenuPosition(undefined);
                    setContextMenuItem(undefined);
                }, side: "bottom", align: "start", className: "py-2", children: [contextMenuItem instanceof FileItem && (_jsx(Menu, { a11yLabel: "File context menu", children: _jsx(FileMenuOptions, { closeMenu: closeContextMenu, shouldShowRenameOption: false, shouldShowAttachOption: false, selectedFiles: [contextMenuItem] }) })), contextMenuItem instanceof SNNote && (_jsx(Menu, { className: "select-none", a11yLabel: "Note context menu", children: _jsx(NotesOptions, { notes: [contextMenuItem], closeMenu: closeContextMenu }) }))] }))] }));
};
export default ContentTableView;
