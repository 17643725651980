import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { KeyboardKey } from '@standardnotes/ui-services';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { ContentType } from '@standardnotes/snjs';
import Icon from '../Icon/Icon';
import { getIconForItem } from '@/Utils/Items/Icons/getIconForItem';
import { useApplication } from '../ApplicationProvider';
import { getTitleForLinkedTag } from '@/Utils/Items/Display/getTitleForLinkedTag';
import { getItemTitleInContextOfLinkBubble } from '@/Utils/Items/Search/doesItemMatchSearchQuery';
const LinkedItemBubble = ({ link, activateItem, unlinkItem, focusPreviousItem, focusNextItem, focusedId, setFocusedId, isBidirectional, inlineFlex, className, readonly, wrappable, }) => {
    const ref = useRef();
    const application = useApplication();
    const [showUnlinkButton, setShowUnlinkButton] = useState(false);
    const unlinkButtonRef = useRef();
    const [wasClicked, setWasClicked] = useState(false);
    const handleFocus = () => {
        if (focusedId !== link.id) {
            setFocusedId === null || setFocusedId === void 0 ? void 0 : setFocusedId(link.id);
        }
        setShowUnlinkButton(true);
    };
    const onBlur = () => {
        setShowUnlinkButton(false);
        setWasClicked(false);
    };
    const onClick = (event) => {
        if (wasClicked && event.target !== unlinkButtonRef.current) {
            setWasClicked(false);
            if (readonly) {
                return;
            }
            void (activateItem === null || activateItem === void 0 ? void 0 : activateItem(link.item));
        }
        else {
            setWasClicked(true);
        }
    };
    const onUnlinkClick = (event) => {
        event.stopPropagation();
        void unlinkItem(link.item);
    };
    const onKeyDown = (event) => {
        switch (event.key) {
            case KeyboardKey.Backspace: {
                focusPreviousItem === null || focusPreviousItem === void 0 ? void 0 : focusPreviousItem();
                void unlinkItem(link.item);
                break;
            }
            case KeyboardKey.Left:
                focusPreviousItem === null || focusPreviousItem === void 0 ? void 0 : focusPreviousItem();
                break;
            case KeyboardKey.Right:
                focusNextItem === null || focusNextItem === void 0 ? void 0 : focusNextItem();
                break;
        }
    };
    const [icon, iconClassName] = getIconForItem(link.item, application);
    const tagTitle = getTitleForLinkedTag(link.item, application);
    useEffect(() => {
        var _a;
        if (link.id === focusedId) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [focusedId, link.id]);
    if (wrappable) {
        return (_jsxs("a", { ref: (el) => (ref.current = el), tabIndex: 0, className: classNames('group cursor-pointer rounded align-middle [&>*]:align-middle', 'bg-passive-4-opacity-variant outline-1 outline-info hover:bg-contrast focus:bg-contrast focus:outline', 'whitespace-pre-wrap text-left text-sm text-text hover:no-underline focus:no-underline lg:text-xs', 'py-1 pl-1 pr-2', className), onFocus: handleFocus, onBlur: onBlur, onClick: onClick, title: tagTitle ? tagTitle.longTitle : link.item.title, onKeyDown: onKeyDown, children: [_jsx(Icon, { type: icon, className: classNames('mr-1 inline', iconClassName), size: "small" }), tagTitle && _jsx("span", { className: "text-passive-1", children: tagTitle.titlePrefix }), link.type === 'linked-by' && link.item.content_type !== ContentType.TYPES.Tag && (_jsx("span", { className: !isBidirectional ? 'hidden group-focus:inline' : '', children: "Linked By:" })), _jsx("span", { children: getItemTitleInContextOfLinkBubble(link.item) }), showUnlinkButton && !readonly && (_jsx("button", { ref: (el) => (unlinkButtonRef.current = el), role: "button", className: "-mr-1 ml-2 inline-flex cursor-pointer border-0 bg-transparent p-0", onClick: onUnlinkClick, children: _jsx(Icon, { type: "close", className: "text-neutral hover:text-info", size: "small" }) }))] }));
    }
    return (_jsxs("button", { ref: (el) => (ref.current = el), className: classNames('group h-6 cursor-pointer items-center rounded bg-passive-4-opacity-variant py-2 pl-1 pr-2 align-middle text-sm', 'text-text hover:bg-contrast focus:bg-contrast lg:text-xs', inlineFlex ? 'inline-flex' : 'flex', className), onFocus: handleFocus, onBlur: onBlur, onClick: onClick, title: tagTitle ? tagTitle.longTitle : link.item.title, onKeyDown: onKeyDown, children: [_jsx(Icon, { type: icon, className: classNames('mr-1 flex-shrink-0', iconClassName), size: "small" }), _jsxs("span", { className: "flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap", children: [tagTitle && _jsx("span", { className: "text-passive-1", children: tagTitle.titlePrefix }), _jsxs("span", { className: "flex items-center gap-1", children: [link.type === 'linked-by' && link.item.content_type !== ContentType.TYPES.Tag && (_jsx("span", { className: !isBidirectional ? 'hidden group-focus:block' : '', children: "Linked By:" })), getItemTitleInContextOfLinkBubble(link.item)] })] }), showUnlinkButton && !readonly && (_jsx("a", { ref: (el) => (unlinkButtonRef.current = el), role: "button", className: "-mr-1 ml-2 flex cursor-pointer border-0 bg-transparent p-0", onClick: onUnlinkClick, children: _jsx(Icon, { type: "close", className: "text-neutral hover:text-info", size: "small" }) }))] }));
};
export default observer(LinkedItemBubble);
