import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Switch from '@/Components/Switch/Switch';
import { Subtitle, Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { LogSessionUserAgentOption, SettingName } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { STRING_FAILED_TO_UPDATE_USER_SETTING } from '@/Constants/Strings';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import Spinner from '@/Components/Spinner/Spinner';
const Privacy = ({ application }) => {
    const [sessionUaLoggingValue, setSessionUaLoggingValue] = useState(LogSessionUserAgentOption.Enabled);
    const [isLoading, setIsLoading] = useState(true);
    const updateSetting = async (settingName, payload) => {
        try {
            await application.settings.updateSetting(settingName, payload, false);
            return true;
        }
        catch (e) {
            application.alerts.alert(STRING_FAILED_TO_UPDATE_USER_SETTING).catch(console.error);
            return false;
        }
    };
    const loadSettings = useCallback(async () => {
        if (!application.sessions.getUser()) {
            return;
        }
        setIsLoading(true);
        try {
            const userSettings = await application.settings.listSettings();
            setSessionUaLoggingValue(userSettings.getSettingValue(SettingName.create(SettingName.NAMES.LogSessionUserAgent).getValue(), LogSessionUserAgentOption.Enabled));
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }, [application]);
    useEffect(() => {
        loadSettings().catch(console.error);
    }, [loadSettings]);
    const toggleSessionLogging = async () => {
        const previousValue = sessionUaLoggingValue;
        const newValue = previousValue === LogSessionUserAgentOption.Enabled
            ? LogSessionUserAgentOption.Disabled
            : LogSessionUserAgentOption.Enabled;
        setSessionUaLoggingValue(newValue);
        const updateResult = await updateSetting(SettingName.create(SettingName.NAMES.LogSessionUserAgent).getValue(), newValue);
        if (!updateResult) {
            setSessionUaLoggingValue(previousValue);
        }
    };
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Privacy" }), _jsx("div", { children: _jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Session user agent logging" }), _jsx(Text, { children: "User agent logging allows you to identify the devices or browsers signed into your account. For increased privacy, you can disable this feature, which will remove all saved user agent values from our server, and disable future logging of this value." })] }), isLoading ? (_jsx(Spinner, { className: "h-5 w-5 flex-shrink-0" })) : (_jsx(Switch, { onChange: toggleSessionLogging, checked: sessionUaLoggingValue === LogSessionUserAgentOption.Enabled }))] }) })] }) }));
};
export default observer(Privacy);
