import { Platform } from '@standardnotes/snjs';
export function isMacPlatform(platform) {
    return platform === Platform.MacDesktop || platform === Platform.MacWeb;
}
export function isWindowsPlatform(platform) {
    return platform === Platform.WindowsDesktop || platform === Platform.WindowsWeb;
}
export function isMobilePlatform(platform) {
    return platform === Platform.Ios || platform === Platform.Android;
}
