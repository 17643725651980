import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from '@/Components/Modal/Modal';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import { useApplication } from '@/Components/ApplicationProvider';
import { KeySystemPasswordType, KeySystemRootKeyStorageMode, isClientDisplayableError, } from '@standardnotes/snjs';
import { VaultModalMembers } from './VaultModalMembers';
import { VaultModalInvites } from './VaultModalInvites';
import { PasswordTypePreference } from './PasswordTypePreference';
import { KeyStoragePreference } from './KeyStoragePreference';
import useItem from '@/Hooks/useItem';
import Button from '@/Components/Button/Button';
import Icon from '@/Components/Icon/Icon';
import StyledTooltip from '@/Components/StyledTooltip/StyledTooltip';
import Popover from '@/Components/Popover/Popover';
import IconPicker from '@/Components/Icon/IconPicker';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import { Disclosure, DisclosureContent, useDisclosureStore } from '@ariakit/react';
import Spinner from '@/Components/Spinner/Spinner';
const EditVaultModalContent = ({ onCloseDialog, existingVaultUuid, creatingSharedVault }) => {
    const application = useApplication();
    const existingVault = useItem(existingVaultUuid);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [iconString, setIconString] = useState('safe-square');
    const [members, setMembers] = useState([]);
    const [invites, setInvites] = useState([]);
    const [isLoadingCollaborationInfo, setIsLoadingCollaborationInfo] = useState(false);
    const [isAdmin, setIsAdmin] = useState(true);
    const [passwordType, setPasswordType] = useState(KeySystemPasswordType.Randomized);
    const [keyStorageMode, setKeyStorageMode] = useState(KeySystemRootKeyStorageMode.Synced);
    const [customPassword, setCustomPassword] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const nameInputRef = useRef(null);
    useEffect(() => {
        var _a, _b;
        if (existingVault) {
            setName((_a = existingVault.name) !== null && _a !== void 0 ? _a : '');
            setDescription((_b = existingVault.description) !== null && _b !== void 0 ? _b : '');
            setIconString(existingVault.iconString);
            setPasswordType(existingVault.rootKeyParams.passwordType);
            setKeyStorageMode(existingVault.keyStorageMode);
        }
    }, [application.vaults, existingVault]);
    const reloadVaultInfo = useCallback(async () => {
        if (!existingVault) {
            return;
        }
        if (existingVault.isSharedVaultListing()) {
            setIsAdmin(existingVault.isSharedVaultListing() && application.vaultUsers.isCurrentUserSharedVaultAdmin(existingVault));
            setIsLoadingCollaborationInfo(true);
            const users = await application.vaultUsers.getSharedVaultUsersFromServer(existingVault);
            if (users) {
                setMembers(users);
            }
            const invites = await application.vaultInvites.getOutboundInvites(existingVault);
            if (!isClientDisplayableError(invites)) {
                setInvites(invites);
            }
            setIsLoadingCollaborationInfo(false);
        }
    }, [application, existingVault]);
    useEffect(() => {
        void reloadVaultInfo();
    }, [application.vaults, reloadVaultInfo]);
    const handleDialogClose = useCallback(() => {
        onCloseDialog();
    }, [onCloseDialog]);
    const saveExistingVault = useCallback(async (vault) => {
        var _a;
        if (!name) {
            (_a = nameInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (vault.name !== name || vault.description !== description || vault.iconString !== iconString) {
            await application.vaults.changeVaultMetadata(vault, {
                name: name,
                description: description,
                iconString: iconString,
            });
        }
        const isChangingPasswordType = vault.keyPasswordType !== passwordType;
        const isChangingKeyStorageMode = vault.keyStorageMode !== keyStorageMode;
        const getPasswordTypeParams = () => {
            if (!isChangingPasswordType) {
                throw new Error('Password type is not changing');
            }
            if (passwordType === KeySystemPasswordType.UserInputted) {
                if (!customPassword) {
                    throw new Error('Custom password is not set');
                }
                return {
                    passwordType,
                    userInputtedPassword: customPassword,
                };
            }
            else {
                return {
                    passwordType,
                };
            }
        };
        if (isChangingPasswordType || isChangingKeyStorageMode) {
            await application.vaults.changeVaultKeyOptions({
                vault,
                newPasswordOptions: isChangingPasswordType ? getPasswordTypeParams() : undefined,
                newStorageMode: isChangingKeyStorageMode ? keyStorageMode : undefined,
            });
        }
        handleDialogClose();
    }, [
        application.vaults,
        customPassword,
        description,
        handleDialogClose,
        iconString,
        keyStorageMode,
        name,
        passwordType,
    ]);
    const createNewVault = useCallback(async () => {
        var _a;
        if (!name) {
            (_a = nameInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (passwordType === KeySystemPasswordType.UserInputted) {
            if (!customPassword) {
                throw new Error('Custom key is not set');
            }
            if (creatingSharedVault) {
                await application.sharedVaults.createSharedVault({
                    name,
                    description,
                    iconString: iconString,
                    storagePreference: keyStorageMode,
                    userInputtedPassword: customPassword,
                });
            }
            else {
                await application.vaults.createUserInputtedPasswordVault({
                    name,
                    description,
                    iconString: iconString,
                    storagePreference: keyStorageMode,
                    userInputtedPassword: customPassword,
                });
            }
        }
        else if (creatingSharedVault) {
            await application.sharedVaults.createSharedVault({
                name,
                description,
                iconString: iconString,
                storagePreference: keyStorageMode,
                userInputtedPassword: undefined,
            });
        }
        else {
            await application.vaults.createRandomizedVault({
                name,
                description,
                iconString: iconString,
            });
        }
        handleDialogClose();
    }, [
        application.sharedVaults,
        application.vaults,
        creatingSharedVault,
        customPassword,
        description,
        handleDialogClose,
        iconString,
        keyStorageMode,
        name,
        passwordType,
    ]);
    const handleSubmit = useCallback(async () => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        if (existingVault) {
            await saveExistingVault(existingVault);
        }
        else {
            await createNewVault();
        }
        setIsSubmitting(false);
    }, [isSubmitting, existingVault, saveExistingVault, createNewVault]);
    const modalActions = useMemo(() => [
        {
            label: existingVault ? 'Save Vault' : creatingSharedVault ? 'Create Shared Vault' : 'Create Vault',
            onClick: handleSubmit,
            type: 'primary',
            mobileSlot: 'right',
            disabled: isSubmitting,
        },
        {
            label: 'Cancel',
            onClick: handleDialogClose,
            type: 'cancel',
            mobileSlot: 'left',
        },
    ], [creatingSharedVault, existingVault, handleDialogClose, handleSubmit, isSubmitting]);
    const [shouldShowIconPicker, setShouldShowIconPicker] = useState(false);
    const iconPickerButtonRef = useRef(null);
    const toggleIconPicker = useCallback(() => {
        setShouldShowIconPicker((shouldShow) => !shouldShow);
    }, []);
    const advancedOptionsDisclosure = useDisclosureStore();
    const isShowingAdvancedOptions = advancedOptionsDisclosure.useState('open');
    if (existingVault && application.vaultLocks.isVaultLocked(existingVault)) {
        return _jsx("div", { children: "Vault is locked." });
    }
    return (_jsx(Modal, { title: existingVault ? 'Edit Vault' : 'Create New Vault', close: handleDialogClose, actions: modalActions, children: _jsxs("div", { className: "flex w-full flex-col space-y-3.5 px-4.5 py-4", children: [_jsxs("div", { children: [_jsx("div", { className: "text-lg", children: "Vault Info" }), _jsx("div", { className: "mt-1", children: "The vault name and description are end-to-end encrypted." }), _jsxs("div", { className: "mt-3.5 flex items-center gap-3", children: [_jsx(StyledTooltip, { className: "!z-modal", label: "Choose icon", children: _jsx(Button, { className: "!px-1.5", ref: iconPickerButtonRef, onClick: toggleIconPicker, children: _jsx(Icon, { type: iconString }) }) }), _jsx(Popover, { title: "Choose icon", open: shouldShowIconPicker, anchorElement: iconPickerButtonRef, togglePopover: toggleIconPicker, align: "start", overrideZIndex: "z-modal", hideOnClickInModal: true, children: _jsx("div", { className: "p-2", children: _jsx(IconPicker, { selectedValue: iconString || 'safe-square', onIconChange: (value) => {
                                                setIconString(value !== null && value !== void 0 ? value : 'safe-square');
                                                toggleIconPicker();
                                            }, platform: application.platform, useIconGrid: true }) }) }), _jsx(DecoratedInput, { className: {
                                        container: 'flex-grow',
                                    }, ref: nameInputRef, value: name, placeholder: "Vault Name", onChange: (value) => {
                                        setName(value);
                                    } })] }), _jsx(DecoratedInput, { className: { container: 'mt-3' }, value: description, placeholder: "Vault description", onChange: (value) => {
                                setDescription(value);
                            } })] }), existingVault ? (isLoadingCollaborationInfo ? (_jsxs("div", { className: "flex items-center gap-3 py-2 text-base", children: [_jsx(Spinner, { className: "h-5 w-5" }), "Loading collaboration info..."] })) : (_jsxs(_Fragment, { children: [members.length > 0 && (_jsx(VaultModalMembers, { vault: existingVault, members: members, onChange: reloadVaultInfo, isCurrentUserAdmin: isAdmin })), invites.length > 0 && (_jsx(VaultModalInvites, { invites: invites, onChange: reloadVaultInfo, isAdmin: isAdmin }))] }))) : null, _jsxs(Disclosure, { store: advancedOptionsDisclosure, className: "flex items-center justify-between focus:shadow-none focus:outline-none", children: [_jsx("div", { className: "text-lg", children: "Advanced options" }), _jsx(Icon, { type: isShowingAdvancedOptions ? 'chevron-up' : 'chevron-down' })] }), _jsxs(DisclosureContent, { className: "space-y-3.5 pb-3", store: advancedOptionsDisclosure, children: [_jsx(PasswordTypePreference, { value: passwordType, onChange: setPasswordType, onCustomKeyChange: setCustomPassword }), _jsx(KeyStoragePreference, { value: keyStorageMode, onChange: setKeyStorageMode })] })] }) }));
};
const EditVaultModal = ({ isVaultModalOpen, closeVaultModal, vault, creatingSharedVault, }) => {
    return (_jsx(ModalOverlay, { className: "md:max-h-[70vh]", isOpen: isVaultModalOpen, close: closeVaultModal, children: _jsx(EditVaultModalContent, { creatingSharedVault: creatingSharedVault, existingVaultUuid: vault === null || vault === void 0 ? void 0 : vault.uuid, onCloseDialog: closeVaultModal }) }));
};
export default EditVaultModal;
