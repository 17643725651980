import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChallengeValidation, ProtectionSessionDurations, ReactNativeToWebEvent, } from '@standardnotes/snjs';
import { useCallback, useEffect, useRef } from 'react';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import DecoratedPasswordInput from '@/Components/Input/DecoratedPasswordInput';
import BiometricsPrompt from './BiometricsPrompt';
import U2FPrompt from './U2FPrompt';
const ChallengeModalPrompt = ({ application, prompt, values, index, onValueChange, isInvalid, contextData, }) => {
    const inputRef = useRef(null);
    const biometricsButtonRef = useRef(null);
    const authenticatorButtonRef = useRef(null);
    const activatePrompt = useCallback(async () => {
        var _a, _b, _c;
        if (prompt.validation === ChallengeValidation.Biometric) {
            if (application.isNativeMobileWeb()) {
                const appState = await application.mobileDevice.getAppState();
                if (appState !== 'active') {
                    return;
                }
            }
            const hasUserAlreadyInteracted = typeof values[prompt.id].value === 'boolean';
            if (hasUserAlreadyInteracted) {
                return;
            }
            (_a = biometricsButtonRef.current) === null || _a === void 0 ? void 0 : _a.click();
            return;
        }
        const parentForm = (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.closest('form');
        if (parentForm === null || parentForm === void 0 ? void 0 : parentForm.contains(document.activeElement)) {
            return;
        }
        (_c = inputRef.current) === null || _c === void 0 ? void 0 : _c.focus();
    }, [application, prompt.id, prompt.validation, values]);
    useEffect(() => {
        if (!application.isNativeMobileWeb()) {
            return;
        }
        const disposeListener = application.addNativeMobileEventListener((event) => {
            if (event === ReactNativeToWebEvent.GainingFocus) {
                void activatePrompt();
            }
        });
        return () => {
            if (disposeListener) {
                disposeListener();
            }
        };
    }, [activatePrompt, application]);
    useEffect(() => {
        const isNotFirstPrompt = index !== 0;
        if (isNotFirstPrompt) {
            return;
        }
        void activatePrompt();
    }, [activatePrompt, index]);
    useEffect(() => {
        var _a;
        if (isInvalid) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isInvalid]);
    return (_jsxs("div", { className: "mb-3 w-full", children: [prompt.validation === ChallengeValidation.ProtectionSessionDuration ? (_jsxs("div", { className: "min-w-76", children: [_jsx("div", { className: "mb-2 text-sm font-medium", children: "Allow protected access for" }), _jsx("div", { className: "flex items-center justify-between rounded bg-passive-4 p-1", children: ProtectionSessionDurations.map((option) => {
                            const selected = option.valueInSeconds === values[prompt.id].value;
                            return (_jsxs("label", { className: `relative flex cursor-pointer items-center justify-center rounded px-2 py-1.5 text-center focus-within:ring-2 focus-within:ring-info ${selected ? 'bg-default font-semibold text-foreground' : 'text-passive-0 hover:bg-passive-3'}`, children: [_jsx("input", { type: "radio", name: `session-duration-${prompt.id}`, className: 'absolute left-0 top-0 m-0 h-px w-px appearance-none focus:shadow-none focus:outline-none', style: {
                                            marginRight: 0,
                                        }, checked: selected, onChange: (event) => {
                                            event.preventDefault();
                                            onValueChange(option.valueInSeconds, prompt);
                                        } }), option.label] }, option.label));
                        }) })] })) : prompt.validation === ChallengeValidation.Biometric ? (_jsx(BiometricsPrompt, { application: application, onValueChange: onValueChange, prompt: prompt, buttonRef: biometricsButtonRef })) : prompt.validation === ChallengeValidation.Authenticator ? (_jsx(U2FPrompt, { application: application, onValueChange: onValueChange, prompt: prompt, buttonRef: authenticatorButtonRef, contextData: contextData })) : prompt.secureTextEntry ? (_jsx(DecoratedPasswordInput, { ref: inputRef, placeholder: prompt.placeholder, className: { container: `w-full max-w-76 ${isInvalid ? 'border-danger' : ''}` }, onChange: (value) => onValueChange(value, prompt) })) : (_jsx(DecoratedInput, { ref: inputRef, placeholder: prompt.placeholder, className: { container: `w-full max-w-76 ${isInvalid ? 'border-danger' : ''}` }, onChange: (value) => onValueChange(value, prompt) })), isInvalid && _jsx("div", { className: "mt-2 text-sm text-danger", children: "Invalid authentication, please try again." })] }, prompt.id));
};
export default ChallengeModalPrompt;
