import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon/Icon';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator/KeyboardShortcutIndicator';
import MenuItem from '../Menu/MenuItem';
import { iconClass } from './ClassNames';
import MenuSection from '../Menu/MenuSection';
import { SUPER_SHOW_MARKDOWN_PREVIEW, SUPER_TOGGLE_SEARCH } from '@standardnotes/ui-services';
import { useMemo, useCallback } from 'react';
import { useCommandService } from '../CommandProvider';
const SuperNoteOptions = ({ closeMenu }) => {
    const commandService = useCommandService();
    const markdownShortcut = useMemo(() => commandService.keyboardShortcutForCommand(SUPER_SHOW_MARKDOWN_PREVIEW), [commandService]);
    const findShortcut = useMemo(() => commandService.keyboardShortcutForCommand(SUPER_TOGGLE_SEARCH), [commandService]);
    const enableSuperMarkdownPreview = useCallback(() => {
        commandService.triggerCommand(SUPER_SHOW_MARKDOWN_PREVIEW);
    }, [commandService]);
    const findInNote = useCallback(() => {
        commandService.triggerCommand(SUPER_TOGGLE_SEARCH);
        closeMenu();
    }, [closeMenu, commandService]);
    return (_jsxs(MenuSection, { children: [_jsxs(MenuItem, { onClick: findInNote, children: [_jsx(Icon, { type: "search", className: iconClass }), "Find in note", findShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: findShortcut })] }), _jsxs(MenuItem, { onClick: enableSuperMarkdownPreview, children: [_jsx(Icon, { type: "markdown", className: iconClass }), "Show Markdown", markdownShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: markdownShortcut })] })] }));
};
export default SuperNoteOptions;
