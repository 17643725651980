import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import IconButton from '@/Components/Button/IconButton';
const CopyButton = ({ copyValue: secretKey }) => {
    const [isCopied, setCopied] = useState(false);
    return (_jsx(IconButton, { focusable: false, title: "Copy to clipboard", icon: isCopied ? 'check' : 'copy', className: `${isCopied ? 'success' : undefined} p-0`, onClick: () => {
            var _a;
            (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(secretKey).catch(console.error);
            setCopied(() => true);
        } }));
};
export default CopyButton;
