import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { Platform } from '@standardnotes/snjs';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import { getEmojiLength } from './EmojiLength';
import Icon, { isIconEmoji } from './Icon';
import { IconNameToSvgMapping } from './IconNameToSvgMapping';
import DecoratedInput from '../Input/DecoratedInput';
const TabButton = forwardRef(({ type, label, currentType, selectTab, }, ref) => {
    const isSelected = currentType === type;
    return (_jsx("button", { className: `relative mr-2 cursor-pointer border-0 pb-1.5 text-mobile-menu-item focus:shadow-none md:text-tablet-menu-item lg:text-menu-item ${isSelected ? 'font-medium text-info' : 'text-text'}`, onClick: () => {
            selectTab(type);
        }, ref: ref, children: label }));
});
const IconPicker = ({ selectedValue, onIconChange, platform, className, useIconGrid, iconGridClassName, autoFocus, }) => {
    const iconKeys = useMemo(() => Object.keys(IconNameToSvgMapping), []);
    const iconOptions = useMemo(() => iconKeys.map((value) => ({
        label: value,
        value: value,
        icon: value,
    })), [iconKeys]);
    const isSelectedEmoji = isIconEmoji(selectedValue);
    const isMacOS = platform === Platform.MacWeb || platform === Platform.MacDesktop;
    const isWindows = platform === Platform.WindowsWeb || platform === Platform.WindowsDesktop;
    const emojiInputRef = useRef(null);
    const [emojiInputFocused, setEmojiInputFocused] = useState(true);
    const [currentType, setCurrentType] = useState(isSelectedEmoji ? 'emoji' : 'icon');
    const [emojiInputValue, setEmojiInputValue] = useState(isSelectedEmoji ? selectedValue : '');
    useEffect(() => {
        setEmojiInputValue(isSelectedEmoji ? selectedValue : '');
    }, [isSelectedEmoji, selectedValue]);
    const selectTab = (type) => {
        if (type === 'reset') {
            onIconChange(undefined);
            setEmojiInputValue('');
        }
        else {
            setCurrentType(type);
        }
    };
    const handleIconChange = (value) => {
        onIconChange(value);
    };
    const handleEmojiChange = (value) => {
        var _a;
        setEmojiInputValue(value);
        const emojiLength = getEmojiLength(value);
        if (emojiLength === 1) {
            onIconChange(value);
            (_a = emojiInputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            setEmojiInputFocused(false);
        }
        else {
            setEmojiInputFocused(true);
        }
    };
    const focusOnMount = useCallback((element) => {
        if (element) {
            setTimeout(() => {
                element.focus();
            });
        }
    }, []);
    return (_jsxs("div", { className: `flex h-full flex-grow flex-col ${className}`, children: [_jsxs("div", { className: "flex", children: [_jsx(TabButton, { label: "Icon", type: 'icon', currentType: currentType, selectTab: selectTab }), _jsx(TabButton, { label: "Emoji", type: 'emoji', currentType: currentType, selectTab: selectTab }), _jsx(TabButton, { label: "Reset", type: 'reset', currentType: currentType, selectTab: selectTab })] }), _jsxs("div", { className: classNames('mt-1 h-full min-h-0', currentType === 'icon' && 'overflow-auto'), children: [currentType === 'icon' &&
                        (useIconGrid ? (_jsx("div", { className: classNames('flex w-full flex-wrap items-center gap-6 p-1 md:max-h-24 md:gap-4 md:p-0', iconGridClassName), children: iconKeys.map((iconName, index) => (_jsx("button", { onClick: () => {
                                    handleIconChange(iconName);
                                }, ref: index === 0 ? focusOnMount : undefined, children: _jsx(Icon, { type: iconName }) }, iconName))) })) : (_jsx(Dropdown, { fullWidth: true, label: "Change the icon for a tag", items: iconOptions, value: selectedValue, onChange: handleIconChange }))), currentType === 'emoji' && (_jsxs(_Fragment, { children: [_jsx(DecoratedInput, { ref: emojiInputRef, autocomplete: false, autofocus: autoFocus !== null && autoFocus !== void 0 ? autoFocus : emojiInputFocused, type: "text", value: emojiInputValue, onChange: (value) => handleEmojiChange(value) }), _jsx("div", { className: "mt-2 text-sm text-passive-0 lg:text-xs", children: "Use your keyboard to enter or paste in an emoji character." }), isMacOS && (_jsx("div", { className: "mt-2 text-sm text-passive-0 lg:text-xs", children: "On macOS: \u2318 + \u2303 + Space bar to bring up emoji picker." })), isWindows && (_jsx("div", { className: "mt-2 text-sm text-passive-0 lg:text-xs", children: "On Windows: Windows key + . to bring up emoji picker." }))] }))] })] }));
};
export default IconPicker;
