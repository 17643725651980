"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateComponentRetrievedContextPayload = void 0;
function CreateComponentRetrievedContextPayload(fromPayload) {
    return {
        content_type: fromPayload.content_type,
        content: fromPayload.content,
        created_at: fromPayload.created_at,
        deleted: false,
        uuid: fromPayload.uuid,
    };
}
exports.CreateComponentRetrievedContextPayload = CreateComponentRetrievedContextPayload;
