import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { IlNotesIcon } from '@standardnotes/icons';
import { observer } from 'mobx-react-lite';
import Button from '../Button/Button';
import { useCallback } from 'react';
import FileOptionsPanel from '../FileContextMenu/FileOptionsPanel';
const MultipleSelectedFiles = ({ itemListController }) => {
    const count = itemListController.selectedFilesCount;
    const cancelMultipleSelection = useCallback(() => {
        itemListController.cancelMultipleSelection();
    }, [itemListController]);
    return (_jsxs("div", { className: "flex h-full flex-col items-center", children: [_jsxs("div", { className: "flex w-full items-center justify-between p-4", children: [_jsxs("h1", { className: "m-0 text-lg font-bold", children: [count, " selected files"] }), _jsx("div", { children: _jsx(FileOptionsPanel, { itemListController: itemListController }) })] }), _jsxs("div", { className: "flex min-h-full w-full max-w-md flex-grow flex-col items-center justify-center", children: [_jsx(IlNotesIcon, { className: "block" }), _jsxs("h2", { className: "m-0 mt-4 text-center text-lg font-bold", children: [count, " selected files"] }), _jsx("p", { className: "mt-2 max-w-60 text-center text-sm", children: "Actions will be performed on all selected files." }), _jsx(Button, { className: "mt-2.5", onClick: cancelMultipleSelection, children: "Cancel multiple selection" })] })] }));
};
export default observer(MultipleSelectedFiles);
