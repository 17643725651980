"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultUsersServer = void 0;
const Paths_1 = require("./Paths");
class SharedVaultUsersServer {
    constructor(httpService) {
        this.httpService = httpService;
    }
    designateSurvivor(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post(Paths_1.SharedVaultUsersPaths.designateSurvivor(params.sharedVaultUuid.value, params.sharedVaultMemberUuid.value));
        });
    }
    getSharedVaultUsers(params) {
        return this.httpService.get(Paths_1.SharedVaultUsersPaths.getSharedVaultUsers(params.sharedVaultUuid));
    }
    deleteSharedVaultUser(params) {
        return this.httpService.delete(Paths_1.SharedVaultUsersPaths.deleteSharedVaultUser(params.sharedVaultUuid, params.userUuid));
    }
}
exports.SharedVaultUsersServer = SharedVaultUsersServer;
