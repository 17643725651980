import { jsx as _jsx } from "react/jsx-runtime";
import { classNames, ReactNativeToWebEvent } from '@standardnotes/snjs';
import { useEffect, useState } from 'react';
import { useApplication } from '../ApplicationProvider';
import Icon from '../Icon/Icon';
const IosKeyboardClose = () => {
    const application = useApplication();
    const [isVisible, setIsVisible] = useState(false);
    const [isFocusInSuperEditor, setIsFocusInSuperEditor] = useState(() => { var _a; return !!((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.closest('#blocks-editor,#super-mobile-toolbar')); });
    useEffect(() => {
        return application.addNativeMobileEventListener((event) => {
            if (event === ReactNativeToWebEvent.KeyboardWillShow) {
                setIsVisible(true);
            }
            else if (event === ReactNativeToWebEvent.KeyboardWillHide) {
                setIsVisible(false);
            }
        });
    }, [application]);
    useEffect(() => {
        const handleFocusChange = () => {
            var _a;
            setIsFocusInSuperEditor(!!((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.closest('#blocks-editor,#super-mobile-toolbar')));
        };
        document.addEventListener('focusin', handleFocusChange);
        document.addEventListener('focusout', handleFocusChange);
        return () => {
            document.removeEventListener('focusin', handleFocusChange);
            document.removeEventListener('focusout', handleFocusChange);
        };
    }, []);
    if (!isVisible) {
        return null;
    }
    return (_jsx("button", { className: classNames('absolute bottom-1.5 right-3 rounded-full border border-border bg-contrast p-2.5', isFocusInSuperEditor && 'hidden'), children: _jsx(Icon, { type: "keyboard-close" }) }));
};
export default IosKeyboardClose;
