import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { addToast, ToastType } from '@standardnotes/toast';
import { useRef, useState } from 'react';
import Icon from '../Icon/Icon';
const CopyableCodeBlock = ({ code }) => {
    const buttonRef = useRef(null);
    const [didCopy, setDidCopy] = useState(false);
    const [isCopyButtonVisible, setIsCopyButtonVisible] = useState(false);
    return (_jsxs("div", { className: "group relative", onMouseEnter: () => setIsCopyButtonVisible(true), onMouseLeave: () => setIsCopyButtonVisible(false), children: [_jsx("pre", { className: "overflow-auto rounded-md bg-default px-2.5 py-1.5", children: code }), _jsxs("div", { className: "absolute right-1.5 top-1.5", children: [_jsx("button", { ref: buttonRef, className: classNames('peer rounded border border-border bg-default p-2 text-text hover:bg-contrast', !isCopyButtonVisible && 'hidden'), onClick: () => {
                            navigator.clipboard.writeText(code).then(() => {
                                setDidCopy(true);
                                setTimeout(() => {
                                    var _a;
                                    setDidCopy(false);
                                    (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                                }, 1000);
                            }, () => {
                                addToast({
                                    type: ToastType.Error,
                                    message: 'Failed to copy to clipboard',
                                });
                                setDidCopy(false);
                            });
                        }, children: _jsx(Icon, { type: "copy", size: "small" }) }), _jsx("div", { className: classNames(didCopy && isCopyButtonVisible ? '' : 'hidden', 'absolute right-0 top-full min-w-max translate-x-2 translate-y-1 select-none rounded border border-border bg-default px-3 py-1.5 text-left md:peer-hover:block'), children: didCopy ? 'Copied!' : 'Copy example to clipboard' })] })] }));
};
export default CopyableCodeBlock;
