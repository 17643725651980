import { createContext, useContext, useState } from 'react';
export const useTabState = ({ defaultTab }) => {
    const [activeTab, setActiveTab] = useState(defaultTab);
    return { activeTab, setActiveTab };
};
export const TabStateContext = createContext(undefined);
export const useTabStateContext = () => {
    const context = useContext(TabStateContext);
    if (context === undefined) {
        throw new Error('useTabStateContext must be used within a <TabList/>');
    }
    if (context.state === undefined) {
        throw new Error('Tab state must be provided to the parent <TabList/>');
    }
    return context;
};
