import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Username } from '@standardnotes/snjs';
import { useState } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import U2FPromptIframeContainer from './U2FPromptIframeContainer';
import { isAndroid } from '@standardnotes/ui-services';
const U2FPrompt = ({ application, onValueChange, prompt, buttonRef, contextData }) => {
    const [authenticatorResponse, setAuthenticatorResponse] = useState(null);
    const [error, setError] = useState('');
    if (!application.isFullU2FClient && !isAndroid()) {
        return (_jsx(U2FPromptIframeContainer, { contextData: contextData, apiHost: application.getHost.execute().getValue() || window.defaultSyncServer, onResponse: (response) => {
                onValueChange(response, prompt);
            } }));
    }
    else {
        return (_jsxs("div", { className: "min-w-76", children: [error && _jsx("div", { className: "text-red-500", children: error }), _jsx(Button, { primary: true, fullWidth: true, colorStyle: authenticatorResponse ? 'success' : 'info', onClick: async () => {
                        const usernameOrError = Username.create(contextData.username);
                        if (usernameOrError.isFailed()) {
                            setError(usernameOrError.getError());
                            return;
                        }
                        const username = usernameOrError.getValue();
                        let authenticatorResponse = null;
                        if (isAndroid()) {
                            const authenticatorOptionsOrError = await application.getAuthenticatorAuthenticationOptions.execute({
                                username: username.value,
                            });
                            if (authenticatorOptionsOrError.isFailed()) {
                                setError(authenticatorOptionsOrError.getError());
                                return;
                            }
                            const authenticatorOptions = authenticatorOptionsOrError.getValue();
                            authenticatorResponse = await application.mobileDevice.authenticateWithU2F(JSON.stringify(authenticatorOptions));
                        }
                        else {
                            const authenticatorResponseOrError = await application.getAuthenticatorAuthenticationResponse.execute({
                                username: username.value,
                            });
                            if (authenticatorResponseOrError.isFailed()) {
                                setError(authenticatorResponseOrError.getError());
                                return;
                            }
                            authenticatorResponse = authenticatorResponseOrError.getValue();
                        }
                        if (authenticatorResponse === null) {
                            setError('Failed to obtain device response');
                            return;
                        }
                        setAuthenticatorResponse(authenticatorResponse);
                        onValueChange(authenticatorResponse, prompt);
                    }, ref: buttonRef, children: authenticatorResponse ? (_jsxs("span", { className: "flex items-center justify-center gap-3", children: [_jsx(Icon, { type: "check-circle" }), "Obtained Device Response"] })) : ('Authenticate Device') })] }));
    }
};
export default U2FPrompt;
