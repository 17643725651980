import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VideoRecorder } from '@/Controllers/Moments/VideoRecorder';
import { formatDateAndTimeForNote } from '@/Utils/DateUtils';
import { classNames } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import DecoratedInput from '../Input/DecoratedInput';
import Modal from '../Modal/Modal';
const VideoCaptureModal = ({ filesController, close }) => {
    const [fileName, setFileName] = useState(formatDateAndTimeForNote(new Date()));
    const [recorder, setRecorder] = useState(() => new VideoRecorder(fileName));
    const [isRecorderReady, setIsRecorderReady] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [capturedVideo, setCapturedVideo] = useState();
    const fileNameInputRef = useRef(null);
    const previewRef = useRef(null);
    useEffect(() => {
        const init = async () => {
            await recorder.initialize();
            if (previewRef.current) {
                recorder.video.style.position = '';
                recorder.video.style.display = '';
                recorder.video.style.height = '100%';
                previewRef.current.append(recorder.video);
            }
            setIsRecorderReady(true);
        };
        void init();
        return () => {
            if (recorder.video) {
                void recorder.stop();
            }
        };
    }, [recorder]);
    const startRecording = useCallback(async () => {
        await recorder.startRecording();
        setIsRecording(true);
    }, [recorder]);
    const saveVideo = useCallback(() => {
        var _a;
        if (!fileName) {
            (_a = fileNameInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (!capturedVideo) {
            return;
        }
        const namedFile = new File([capturedVideo], fileName, {
            type: capturedVideo.type,
        });
        void filesController.uploadNewFile(namedFile);
        close();
    }, [capturedVideo, close, fileName, filesController]);
    const capturedVideoObjectURL = useMemo(() => {
        if (!capturedVideo) {
            return;
        }
        return URL.createObjectURL(capturedVideo);
    }, [capturedVideo]);
    const stopRecording = async () => {
        const capturedVideo = await recorder.stop();
        setIsRecording(false);
        setCapturedVideo(capturedVideo);
    };
    const retryRecording = () => {
        setCapturedVideo(undefined);
        setRecorder(new VideoRecorder(fileName));
        setIsRecorderReady(false);
    };
    return (_jsx(Modal, { title: "Record a video", close: close, actions: [
            {
                label: 'Cancel',
                onClick: close,
                type: 'cancel',
                mobileSlot: 'left',
            },
            {
                label: 'Record',
                onClick: startRecording,
                type: 'primary',
                mobileSlot: 'right',
                hidden: !!capturedVideo || isRecording,
            },
            {
                label: 'Stop',
                onClick: stopRecording,
                type: 'primary',
                mobileSlot: 'right',
                hidden: !!capturedVideo || !isRecording,
            },
            {
                label: 'Retry',
                onClick: retryRecording,
                type: 'secondary',
                hidden: !capturedVideo,
            },
            {
                label: 'Upload',
                onClick: saveVideo,
                type: 'primary',
                mobileSlot: 'right',
                hidden: !capturedVideo,
            },
        ], children: _jsxs("div", { className: "px-4 py-4", children: [_jsx("div", { className: "mb-4 flex flex-col", children: _jsxs("label", { className: "text-sm font-medium text-neutral", children: ["File name:", _jsx(DecoratedInput, { className: {
                                    container: 'mt-1',
                                }, value: fileName, onChange: (fileName) => setFileName(fileName), ref: fileNameInputRef })] }) }), _jsxs("div", { className: "mt-2", children: [_jsx("div", { className: "text-sm font-medium text-neutral", children: "Preview:" }), !isRecorderReady && (_jsx("div", { className: "mt-1 w-full", children: _jsxs("div", { className: "flex h-64 w-full items-center justify-center gap-2 rounded-md bg-contrast text-base", children: [_jsx(Icon, { type: "camera", className: "text-neutral-300" }), "Initializing..."] }) })), _jsx("div", { className: classNames('mt-1 w-full', capturedVideo && 'hidden'), ref: previewRef }), capturedVideo && (_jsx("div", { className: "mt-1 w-full", children: _jsx("video", { src: capturedVideoObjectURL, controls: true }) }))] })] }) }));
};
export default observer(VideoCaptureModal);
