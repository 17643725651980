import { jsx as _jsx } from "react/jsx-runtime";
import { getColorsForPrimaryVariant } from '@/Components/Button/Button';
import { classNames } from '@standardnotes/utils';
export const Title = ({ children, className }) => (_jsx("h2", { className: classNames('m-0 mb-1 text-lg font-bold text-info md:text-base', className), children: children }));
export const Subtitle = ({ children, className }) => (_jsx("h4", { className: classNames('m-0 mb-1 text-base font-medium lg:text-sm', className), children: children }));
export const SubtitleLight = ({ children, className }) => (_jsx("h4", { className: classNames('m-0 mb-1 text-base font-normal lg:text-sm', className), children: children }));
export const Text = ({ children, className }) => (_jsx("p", { className: classNames('text-base lg:text-xs', className), children: children }));
export const SmallText = ({ children, className }) => (_jsx("p", { className: classNames('text-sm lg:text-xs', className), children: children }));
export const LinkButton = ({ label, link, className, onClick }) => (_jsx("a", { target: "_blank", className: classNames('block w-fit rounded border border-solid border-border bg-normal-button px-4 py-1.5 text-base font-bold text-text hover:bg-contrast focus:bg-contrast lg:text-sm', className), href: link, onClick: onClick, children: label }));
export const Pill = ({ children, className, style }) => {
    const colorClass = getColorsForPrimaryVariant(style);
    return (_jsx("div", { className: classNames('ml-2 rounded px-2 py-1 text-[10px] font-bold', className, colorClass), children: children }));
};
