import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SmartViewsSection from '@/Components/Tags/SmartViewsSection';
import TagsSection from '@/Components/Tags/TagsSection';
import { ApplicationEvent, PrefKey, WebAppEvent } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useState } from 'react';
import { classNames } from '@standardnotes/utils';
import { useResponsiveAppPane } from '../Panes/ResponsivePaneProvider';
import UpgradeNow from '../Footer/UpgradeNow';
import RoundIconButton from '../Button/RoundIconButton';
import { PANEL_NAME_NAVIGATION } from '@/Constants/Constants';
import { PaneLayout } from '@/Controllers/PaneController/PaneLayout';
import { usePaneSwipeGesture } from '../Panes/usePaneGesture';
import { mergeRefs } from '@/Hooks/mergeRefs';
import { useAvailableSafeAreaPadding } from '@/Hooks/useSafeAreaPadding';
import QuickSettingsButton from '../Footer/QuickSettingsButton';
import VaultSelectionButton from '../Footer/VaultSelectionButton';
import PreferencesButton from '../Footer/PreferencesButton';
import TagSearchBar from './TagSearchBar';
const Navigation = forwardRef(({ application, className, children, id }, ref) => {
    const { setPaneLayout } = useResponsiveAppPane();
    const [hasPasscode, setHasPasscode] = useState(() => application.hasPasscode());
    useEffect(() => {
        const removeObserver = application.addEventObserver(async () => {
            setHasPasscode(application.hasPasscode());
        }, ApplicationEvent.KeyStatusChanged);
        return removeObserver;
    }, [application]);
    useEffect(() => {
        return application.addWebEventObserver((event, data) => {
            if (event === WebAppEvent.PanelResized) {
                const { panel, width } = data;
                if (panel === PANEL_NAME_NAVIGATION) {
                    application.setPreference(PrefKey.TagsPanelWidth, width).catch(console.error);
                }
            }
        });
    }, [application]);
    const [setElement] = usePaneSwipeGesture('left', (element) => {
        setPaneLayout(PaneLayout.ItemSelection);
        element.style.left = '0';
    }, {
        gesture: 'swipe',
    });
    const { hasBottomInset } = useAvailableSafeAreaPadding();
    return (_jsxs("div", { id: id, className: classNames(className, 'sn-component section pb-[50px] md:pb-0', 'h-full max-h-full overflow-hidden pt-safe-top md:h-full md:max-h-full md:min-h-0'), ref: mergeRefs([ref, setElement]), children: [_jsxs("div", { id: "navigation-content", className: "flex-grow overflow-y-auto overflow-x-hidden", children: [_jsx(TagSearchBar, { navigationController: application.navigationController }), _jsx(SmartViewsSection, { application: application, featuresController: application.featuresController, navigationController: application.navigationController }), _jsx(TagsSection, {})] }), _jsxs("div", { className: classNames('fixed bottom-0 flex min-h-[50px] w-full items-center border-t border-border bg-contrast', 'px-3.5 pt-2.5 md:hidden', hasBottomInset ? 'pb-safe-bottom' : 'pb-2.5'), children: [_jsx(RoundIconButton, { className: "mr-auto bg-default", onClick: () => {
                            setPaneLayout(PaneLayout.ItemSelection);
                        }, label: "Go to items list", icon: "chevron-left" }), _jsx(UpgradeNow, { application: application, subscriptionContoller: application.subscriptionController, featuresController: application.featuresController }), _jsx(RoundIconButton, { className: "ml-2.5 bg-default", onClick: () => {
                            application.accountMenuController.toggleShow();
                        }, label: "Go to account menu", icon: "account-circle" }), hasPasscode && (_jsx(RoundIconButton, { id: "lock-item", onClick: () => application.lock(), label: "Locks application and wipes unencrypted data from memory.", className: "ml-2.5 bg-default", icon: "lock-filled" })), _jsx(PreferencesButton, { openPreferences: () => application.preferencesController.openPreferences() }), _jsx(QuickSettingsButton, { application: application, isMobileNavigation: true }), application.featuresController.isVaultsEnabled() && _jsx(VaultSelectionButton, { isMobileNavigation: true })] }), children] }));
});
export default observer(Navigation);
