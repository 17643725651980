import { Deferred } from '@standardnotes/snjs';
export class VideoRecorder {
    constructor(fileName) {
        this.fileName = fileName;
        this.dataReadyPromise = Deferred();
        this.onData = async (event) => {
            const blob = new Blob([event.data], { type: 'video/mp4' });
            const url = URL.createObjectURL(blob);
            const res = await fetch(url);
            const responseBlob = await res.blob();
            const file = new File([responseBlob], this.fileName, { type: 'video/mp4' });
            this.dataReadyPromise.resolve(file);
        };
    }
    static async isSupported() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some((device) => device.kind === 'videoinput');
        return hasCamera;
    }
    async initialize() {
        var _a, _b;
        this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        this.recorder = new MediaRecorder(this.stream);
        this.video = document.createElement('video');
        this.video.playsInline = true;
        this.video.style.position = 'absolute';
        this.video.style.display = 'none';
        this.video.volume = 0;
        this.canvas = document.createElement('canvas');
        document.body.append(this.video);
        this.video.srcObject = this.stream;
        await this.video.play();
        await this.awaitVideoReady(this.video);
        const videoTrack = this.stream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        this.width = (_a = settings.width) !== null && _a !== void 0 ? _a : 1280;
        this.height = (_b = settings.height) !== null && _b !== void 0 ? _b : 720;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }
    async startRecording() {
        this.recorder.start();
        this.recorder.ondataavailable = this.onData;
    }
    async stop() {
        var _a, _b;
        this.video.pause();
        if (this.recorder.state !== 'inactive') {
            this.recorder.stop();
        }
        (_a = this.video.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(this.video);
        (_b = this.canvas.parentElement) === null || _b === void 0 ? void 0 : _b.removeChild(this.canvas);
        this.video.remove();
        this.canvas.remove();
        this.stream.getTracks().forEach((track) => {
            track.stop();
        });
        return this.dataReadyPromise.promise;
    }
    async awaitVideoReady(video) {
        return new Promise((resolve) => {
            video.addEventListener('canplaythrough', () => {
                resolve(null);
            });
        });
    }
}
