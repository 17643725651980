import { Result } from '@standardnotes/domain-core';
import { Environment, Platform } from '@standardnotes/models';
export class IsNativeIOS {
    constructor(environment, platform) {
        this.environment = environment;
        this.platform = platform;
    }
    execute() {
        return Result.ok(this.environment === Environment.Mobile && this.platform === Platform.Ios);
    }
}
