"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Deferred = void 0;
const Deferred = () => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        resolve,
        reject,
        promise,
    };
};
exports.Deferred = Deferred;
