import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AllNonCompoundPredicateOperators } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { PredicateKeypathLabels, PredicateKeypathTypes } from './PredicateKeypaths';
import PredicateValue from './PredicateValue';
const CompoundPredicateBuilder = ({ controller }) => {
    const { operator, setOperator, predicates, setPredicate, changePredicateKeypath, addPredicate, removePredicate } = controller;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsxs("label", { className: "flex items-center gap-2", children: [_jsx("input", { type: "radio", name: "predicate", value: "and", checked: operator === 'and', onChange: (event) => {
                                    setOperator(event.target.value);
                                } }), "Should match ALL conditions"] }), _jsxs("label", { className: "flex items-center gap-2", children: [_jsx("input", { type: "radio", name: "predicate", value: "or", checked: operator === 'or', onChange: (event) => {
                                    setOperator(event.target.value);
                                } }), "Should match ANY conditions"] })] }), predicates.map((predicate, index) => (_jsxs("div", { className: "flex flex-col gap-2.5", children: [_jsxs("div", { className: "flex w-full flex-col gap-2 md:flex-row md:items-center", children: [index !== 0 && _jsx("div", { className: "mr-2 text-sm font-semibold", children: operator === 'and' ? 'AND' : 'OR' }), _jsx("select", { className: "flex-grow rounded border border-border bg-default px-2 py-1.5 focus:outline focus:outline-1 focus:outline-info", value: predicate.keypath, onChange: (event) => {
                                    changePredicateKeypath(index, event.target.value);
                                }, children: Object.entries(PredicateKeypathLabels).map(([key, label]) => (_jsx("option", { value: key, children: label }, key))) }), _jsx("select", { className: "rounded border border-border bg-default px-2 py-1.5 focus:outline focus:outline-1 focus:outline-info", value: predicate.operator, onChange: (event) => {
                                    setPredicate(index, { operator: event.target.value });
                                }, children: AllNonCompoundPredicateOperators.map((operator) => (_jsx("option", { value: operator, children: operator }, operator))) }), predicate.keypath && (_jsx(PredicateValue, { keypath: predicate.keypath, value: typeof predicate.value !== 'string' ? JSON.stringify(predicate.value) : predicate.value, setValue: (value) => {
                                    setPredicate(index, { value });
                                } })), index !== 0 && (_jsx("button", { className: "rounded border border-border p-1 text-danger", "aria-label": "Remove condition", onClick: () => {
                                    removePredicate(index);
                                }, children: _jsx(Icon, { type: "trash" }) }))] }), index === predicates.length - 1 && (_jsx(Button, { className: "flex items-center gap-2", onClick: () => {
                            addPredicate();
                        }, children: "Add another condition" }))] }, index))), predicates.some((predicate) => PredicateKeypathTypes[predicate.keypath] === 'date') && (_jsxs("div", { className: "flex flex-col gap-2 rounded-md border-2 border-info-backdrop bg-info-backdrop px-4 py-3 [&_code]:rounded [&_code]:bg-default [&_code]:px-1.5 [&_code]:py-1", children: [_jsx("div", { className: "text-sm font-semibold", children: "Date Examples:" }), _jsxs("ul", { className: "space-y-2 pl-4", children: [_jsxs("li", { children: ["To get all the items modified within the last 7 days, you can use ", _jsx("code", { children: "User Modified Date" }), ' ', _jsx("code", { children: ">" }), " ", _jsx("code", { children: "7.days.ago" })] }), _jsxs("li", { children: ["To get all the items created before June 2022, you can use ", _jsx("code", { children: "Created At" }), " ", _jsx("code", { children: "<" }), ' ', _jsx("code", { children: "06/01/2022" })] })] })] }))] }));
};
export default observer(CompoundPredicateBuilder);
