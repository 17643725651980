import { AsymmetricMessagePayloadType } from '@standardnotes/models';
const TypesUsingReplaceableIdentifiers = [
    AsymmetricMessagePayloadType.SharedVaultRootKeyChanged,
    AsymmetricMessagePayloadType.SharedVaultMetadataChanged,
];
export function GetReplaceabilityIdentifier(type, sharedVaultUuid, keySystemIdentifier) {
    if (!TypesUsingReplaceableIdentifiers.includes(type)) {
        return undefined;
    }
    return [type, sharedVaultUuid, keySystemIdentifier].join(':');
}
