import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeySystemPasswordType, classNames } from '@standardnotes/snjs';
import StyledRadioInput from '@/Components/Radio/StyledRadioInput';
import DecoratedPasswordInput from '@/Components/Input/DecoratedPasswordInput';
import { useState } from 'react';
const options = [
    {
        value: KeySystemPasswordType.Randomized,
        label: 'Randomized (Recommended)',
        description: 'Your vault key will be randomly generated and synced to your account.',
    },
    {
        value: KeySystemPasswordType.UserInputted,
        label: 'Custom (Advanced)',
        description: 'Choose your own key for your vault. This is an advanced option and is not recommended for most users.',
    },
];
export const PasswordTypePreference = ({ value, onChange, onCustomKeyChange, }) => {
    const [customKey, setCustomKey] = useState('');
    const onKeyInputChange = (value) => {
        setCustomKey(value);
        onCustomKeyChange(value);
    };
    return (_jsxs("div", { children: [_jsx("div", { className: "mb-3 text-lg", children: "Vault Key Type" }), _jsx("div", { className: "mb-1 space-y-3", children: options.map((option) => {
                    const isSelected = value === option.value;
                    return (_jsxs("label", { className: "grid grid-cols-[auto,1fr] gap-x-[0.65rem] gap-y-1 text-base font-medium md:text-sm", children: [_jsx(StyledRadioInput, { className: "col-start-1 col-end-2 place-self-center", name: "option", checked: isSelected, onChange: () => {
                                    onChange(option.value);
                                } }), _jsx("div", { className: classNames('select-none', isSelected ? 'font-semibold' : ''), children: option.label }), _jsx("div", { className: "col-start-2 row-start-2 text-sm opacity-80", children: option.description })] }, option.value));
                }) }), value === KeySystemPasswordType.UserInputted && (_jsx("div", { className: "mt-2", children: _jsx(DecoratedPasswordInput, { placeholder: "Choose a password", id: "key-input", value: customKey, onChange: onKeyInputChange, type: "password" }) }))] }));
};
