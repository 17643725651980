import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { NoteType, Platform, pluralize } from '@standardnotes/snjs';
import { CHANGE_EDITOR_WIDTH_COMMAND, OPEN_NOTE_HISTORY_COMMAND, PIN_NOTE_COMMAND, SHOW_HIDDEN_OPTIONS_KEYBOARD_COMMAND, STAR_NOTE_COMMAND, } from '@standardnotes/ui-services';
import ChangeEditorOption from './ChangeEditorOption';
import ListedActionsOption from './Listed/ListedActionsOption';
import AddTagOption from './AddTagOption';
import { addToast, dismissToast, ToastType } from '@standardnotes/toast';
import { useResponsiveAppPane } from '../Panes/ResponsivePaneProvider';
import { AppPaneId } from '../Panes/AppPaneMetadata';
import { createNoteExport } from '@/Utils/NoteExportUtils';
import ProtectedUnauthorizedLabel from '../ProtectedItemOverlay/ProtectedUnauthorizedLabel';
import { MenuItemIconSize } from '@/Constants/TailwindClassNames';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator/KeyboardShortcutIndicator';
import { NoteAttributes } from './NoteAttributes';
import { SpellcheckOptions } from './SpellcheckOptions';
import { NoteSizeWarning } from './NoteSizeWarning';
import { iconClass } from './ClassNames';
import SuperNoteOptions from './SuperNoteOptions';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
import MenuItem from '../Menu/MenuItem';
import ModalOverlay from '../Modal/ModalOverlay';
import SuperExportModal from './SuperExportModal';
import { useApplication } from '../ApplicationProvider';
import { MutuallyExclusiveMediaQueryBreakpoints } from '@/Hooks/useMediaQuery';
import AddToVaultMenuOption from '../Vaults/AddToVaultMenuOption';
import MenuSection from '../Menu/MenuSection';
import { downloadOrShareBlobBasedOnPlatform } from '@/Utils/DownloadOrShareBasedOnPlatform';
import { shareBlobOnMobile } from '@/NativeMobileWeb/ShareBlobOnMobile';
const iconSize = MenuItemIconSize;
const iconClassDanger = `text-danger mr-2 ${iconSize}`;
const iconClassWarning = `text-warning mr-2 ${iconSize}`;
const iconClassSuccess = `text-success mr-2 ${iconSize}`;
const NotesOptions = ({ notes, closeMenu }) => {
    const application = useApplication();
    const [altKeyDown, setAltKeyDown] = useState(false);
    const { toggleAppPane } = useResponsiveAppPane();
    const toggleOn = (condition) => {
        const notesMatchingAttribute = notes.filter(condition);
        const notesNotMatchingAttribute = notes.filter((note) => !condition(note));
        return notesMatchingAttribute.length > notesNotMatchingAttribute.length;
    };
    const hidePreviews = toggleOn((note) => note.hidePreview);
    const locked = toggleOn((note) => note.locked);
    const protect = toggleOn((note) => note.protected);
    const archived = notes.some((note) => note.archived);
    const unarchived = notes.some((note) => !note.archived);
    const trashed = notes.some((note) => note.trashed);
    const notTrashed = notes.some((note) => !note.trashed);
    const pinned = notes.some((note) => note.pinned);
    const unpinned = notes.some((note) => !note.pinned);
    const starred = notes.some((note) => note.starred);
    const editorForNote = useMemo(() => (notes[0] ? application.componentManager.editorForNote(notes[0]) : undefined), [application.componentManager, notes]);
    useEffect(() => {
        const removeAltKeyObserver = application.keyboardService.addCommandHandler({
            command: SHOW_HIDDEN_OPTIONS_KEYBOARD_COMMAND,
            onKeyDown: () => {
                setAltKeyDown(true);
            },
            onKeyUp: () => {
                setAltKeyDown(false);
            },
        });
        return () => {
            removeAltKeyObserver();
        };
    }, [application]);
    const [showExportSuperModal, setShowExportSuperModal] = useState(false);
    const closeSuperExportModal = useCallback(() => {
        setShowExportSuperModal(false);
    }, []);
    const downloadSelectedItems = useCallback(async () => {
        if (notes.length === 0) {
            return;
        }
        const toast = addToast({
            type: ToastType.Progress,
            message: `Exporting ${notes.length} ${pluralize(notes.length, 'note', 'notes')}...`,
        });
        try {
            const result = await createNoteExport(application, notes);
            if (!result) {
                return;
            }
            const { blob, fileName } = result;
            void downloadOrShareBlobBasedOnPlatform({
                archiveService: application.archiveService,
                platform: application.platform,
                mobileDevice: application.mobileDevice,
                blob: blob,
                filename: fileName,
                isNativeMobileWeb: application.isNativeMobileWeb(),
            });
            dismissToast(toast);
        }
        catch (error) {
            console.error(error);
            addToast({
                type: ToastType.Error,
                message: 'Could not export notes',
            });
            dismissToast(toast);
        }
    }, [application, notes]);
    const exportSelectedItems = useCallback(() => {
        const hasSuperNote = notes.some((note) => note.noteType === NoteType.Super);
        if (hasSuperNote) {
            setShowExportSuperModal(true);
            return;
        }
        downloadSelectedItems().catch(console.error);
    }, [downloadSelectedItems, notes]);
    const shareSelectedItems = useCallback(() => {
        createNoteExport(application, notes)
            .then((result) => {
            if (!result) {
                return;
            }
            const { blob, fileName } = result;
            shareBlobOnMobile(application.mobileDevice, application.isNativeMobileWeb(), blob, fileName).catch(console.error);
        })
            .catch(console.error);
    }, [application, notes]);
    const closeMenuAndToggleNotesList = useCallback(() => {
        const isMobileScreen = matchMedia(MutuallyExclusiveMediaQueryBreakpoints.sm).matches;
        if (isMobileScreen) {
            toggleAppPane(AppPaneId.Items);
        }
        closeMenu();
    }, [closeMenu, toggleAppPane]);
    const duplicateSelectedItems = useCallback(async () => {
        await Promise.all(notes.map((note) => application.mutator
            .duplicateItem(note)
            .then((duplicated) => addToast({
            type: ToastType.Regular,
            message: `Duplicated note "${duplicated.title}"`,
            actions: [
                {
                    label: 'Open',
                    handler: (toastId) => {
                        application.itemListController.selectUuids([duplicated.uuid], true).catch(console.error);
                        dismissToast(toastId);
                    },
                },
            ],
            autoClose: true,
        }))
            .catch(console.error)));
        void application.sync.sync();
        closeMenuAndToggleNotesList();
    }, [application.mutator, application.itemListController, application.sync, closeMenuAndToggleNotesList, notes]);
    const openRevisionHistoryModal = useCallback(() => {
        application.historyModalController.openModal(application.notesController.firstSelectedNote);
    }, [application.historyModalController, application.notesController.firstSelectedNote]);
    const historyShortcut = useMemo(() => application.keyboardService.keyboardShortcutForCommand(OPEN_NOTE_HISTORY_COMMAND), [application]);
    const pinShortcut = useMemo(() => application.keyboardService.keyboardShortcutForCommand(PIN_NOTE_COMMAND), [application]);
    const starShortcut = useMemo(() => application.keyboardService.keyboardShortcutForCommand(STAR_NOTE_COMMAND), [application]);
    const toggleLineWidthModal = useCallback(() => {
        application.keyboardService.triggerCommand(CHANGE_EDITOR_WIDTH_COMMAND);
    }, [application.keyboardService]);
    const editorWidthShortcut = useMemo(() => application.keyboardService.keyboardShortcutForCommand(CHANGE_EDITOR_WIDTH_COMMAND), [application]);
    const unauthorized = notes.some((note) => !application.isAuthorizedToRenderItem(note));
    if (unauthorized) {
        return _jsx(ProtectedUnauthorizedLabel, {});
    }
    const areSomeNotesInSharedVault = notes.some((note) => { var _a; return (_a = application.vaults.getItemVault(note)) === null || _a === void 0 ? void 0 : _a.isSharedVaultListing(); });
    const areSomeNotesInReadonlySharedVault = notes.some((note) => {
        const vault = application.vaults.getItemVault(note);
        return (vault === null || vault === void 0 ? void 0 : vault.isSharedVaultListing()) && application.vaultUsers.isCurrentUserReadonlyVaultMember(vault);
    });
    const hasAdminPermissionForAllSharedNotes = notes.every((note) => {
        const vault = application.vaults.getItemVault(note);
        if (!(vault === null || vault === void 0 ? void 0 : vault.isSharedVaultListing())) {
            return true;
        }
        return application.vaultUsers.isCurrentUserSharedVaultAdmin(vault);
    });
    if (notes.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [notes.length === 1 && (_jsxs(_Fragment, { children: [_jsx(MenuSection, { children: _jsxs(MenuItem, { onClick: openRevisionHistoryModal, children: [_jsx(Icon, { type: "history", className: iconClass }), "Note history", historyShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: historyShortcut })] }) }), _jsx(MenuSection, { children: _jsxs(MenuItem, { onClick: toggleLineWidthModal, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "line-width", className: iconClass }), "Editor width", editorWidthShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: editorWidthShortcut })] }) })] })), _jsxs(MenuSection, { children: [_jsxs(MenuSwitchButtonItem, { checked: locked, onChange: (locked) => {
                            application.notesController.setLockSelectedNotes(locked);
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "pencil-off", className: iconClass }), "Prevent editing"] }), _jsxs(MenuSwitchButtonItem, { checked: !hidePreviews, onChange: (hidePreviews) => {
                            application.notesController.setHideSelectedNotePreviews(!hidePreviews);
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "rich-text", className: iconClass }), "Show preview"] }), _jsxs(MenuSwitchButtonItem, { checked: protect, onChange: (protect) => {
                            application.notesController.setProtectSelectedNotes(protect).catch(console.error);
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "lock", className: iconClass }), "Password protect"] })] }), notes.length === 1 && (_jsx(MenuSection, { children: _jsx(ChangeEditorOption, { iconClassName: iconClass, application: application, note: notes[0], disabled: areSomeNotesInReadonlySharedVault }) })), _jsxs(MenuSection, { className: notes.length > 1 ? 'md:!mb-2' : '', children: [application.featuresController.isVaultsEnabled() && (_jsx(AddToVaultMenuOption, { iconClassName: iconClass, items: notes, disabled: !hasAdminPermissionForAllSharedNotes })), application.navigationController.tagsCount > 0 && (_jsx(AddTagOption, { iconClassName: iconClass, navigationController: application.navigationController, selectedItems: notes, linkingController: application.linkingController, disabled: areSomeNotesInReadonlySharedVault })), _jsxs(MenuItem, { onClick: () => {
                            application.notesController.setStarSelectedNotes(!starred);
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "star", className: iconClass }), starred ? 'Unstar' : 'Star', starShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: starShortcut })] }), unpinned && (_jsxs(MenuItem, { onClick: () => {
                            application.notesController.setPinSelectedNotes(true);
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "pin", className: iconClass }), "Pin to top", pinShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: pinShortcut })] })), pinned && (_jsxs(MenuItem, { onClick: () => {
                            application.notesController.setPinSelectedNotes(false);
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "unpin", className: iconClass }), "Unpin", pinShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: pinShortcut })] })), _jsxs(MenuItem, { onClick: exportSelectedItems, children: [_jsx(Icon, { type: "download", className: iconClass }), "Export"] }), application.platform === Platform.Android && (_jsxs(MenuItem, { onClick: shareSelectedItems, children: [_jsx(Icon, { type: "share", className: iconClass }), "Share"] })), _jsxs(MenuItem, { onClick: duplicateSelectedItems, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "copy", className: iconClass }), "Duplicate"] }), unarchived && (_jsxs(MenuItem, { onClick: async () => {
                            await application.notesController.setArchiveSelectedNotes(true).catch(console.error);
                            closeMenuAndToggleNotesList();
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "archive", className: iconClassWarning }), _jsx("span", { className: "text-warning", children: "Archive" })] })), archived && (_jsxs(MenuItem, { onClick: async () => {
                            await application.notesController.setArchiveSelectedNotes(false).catch(console.error);
                            closeMenuAndToggleNotesList();
                        }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "unarchive", className: iconClassWarning }), _jsx("span", { className: "text-warning", children: "Unarchive" })] })), notTrashed &&
                        (altKeyDown ? (_jsxs(MenuItem, { disabled: areSomeNotesInReadonlySharedVault, onClick: async () => {
                                await application.notesController.deleteNotesPermanently();
                                closeMenuAndToggleNotesList();
                            }, children: [_jsx(Icon, { type: "close", className: "mr-2 text-danger" }), _jsx("span", { className: "text-danger", children: "Delete permanently" })] })) : (_jsxs(MenuItem, { onClick: async () => {
                                await application.notesController.setTrashSelectedNotes(true);
                                closeMenuAndToggleNotesList();
                            }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "trash", className: iconClassDanger }), _jsx("span", { className: "text-danger", children: "Move to trash" })] }))), trashed && (_jsxs(_Fragment, { children: [_jsxs(MenuItem, { onClick: async () => {
                                    await application.notesController.setTrashSelectedNotes(false);
                                    closeMenuAndToggleNotesList();
                                }, disabled: areSomeNotesInReadonlySharedVault, children: [_jsx(Icon, { type: "restore", className: iconClassSuccess }), _jsx("span", { className: "text-success", children: "Restore" })] }), _jsxs(MenuItem, { disabled: areSomeNotesInReadonlySharedVault, onClick: async () => {
                                    await application.notesController.deleteNotesPermanently();
                                    closeMenuAndToggleNotesList();
                                }, children: [_jsx(Icon, { type: "close", className: "mr-2 text-danger" }), _jsx("span", { className: "text-danger", children: "Delete permanently" })] }), _jsx(MenuItem, { onClick: async () => {
                                    await application.notesController.emptyTrash();
                                    closeMenuAndToggleNotesList();
                                }, disabled: areSomeNotesInReadonlySharedVault, children: _jsxs("div", { className: "flex items-start", children: [_jsx(Icon, { type: "trash-sweep", className: "mr-2 text-danger" }), _jsxs("div", { className: "flex-row", children: [_jsx("div", { className: "text-danger", children: "Empty Trash" }), _jsxs("div", { className: "text-xs", children: [application.notesController.trashedNotesCount, " notes in Trash"] })] })] }) })] }))] }), notes.length === 1 && (_jsxs(_Fragment, { children: [notes[0].noteType === NoteType.Super && _jsx(SuperNoteOptions, { closeMenu: closeMenu }), !areSomeNotesInSharedVault && (_jsx(MenuSection, { children: _jsx(ListedActionsOption, { iconClassName: iconClass, application: application, note: notes[0] }) })), editorForNote && (_jsx(MenuSection, { children: _jsx(SpellcheckOptions, { editorForNote: editorForNote, notesController: application.notesController, note: notes[0], disabled: areSomeNotesInReadonlySharedVault }) })), _jsx(NoteAttributes, { className: "mb-2", application: application, note: notes[0] }), _jsx(NoteSizeWarning, { note: notes[0] })] })), _jsx(ModalOverlay, { isOpen: showExportSuperModal, close: closeSuperExportModal, className: "md:max-w-[25vw]", children: _jsx(SuperExportModal, { notes: notes, exportNotes: downloadSelectedItems, close: closeSuperExportModal }) })] }));
};
export default observer(NotesOptions);
