import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import Tools from './Tools';
import Defaults from './Defaults';
import LabsPane from './Labs/Labs';
import OfflineActivation from '@/Components/Preferences/Panes/General/Offline/OfflineActivation';
import PreferencesPane from '../../PreferencesComponents/PreferencesPane';
import Persistence from './Persistence';
import SmartViews from './SmartViews/SmartViews';
import Moments from './Moments';
import NewNoteDefaults from './NewNoteDefaults';
import { useApplication } from '@/Components/ApplicationProvider';
const General = () => {
    const application = useApplication();
    return (_jsxs(PreferencesPane, { children: [_jsx(Persistence, { application: application }), _jsx(Defaults, { application: application }), _jsx(NewNoteDefaults, {}), _jsx(Tools, { application: application }), _jsx(SmartViews, { application: application, featuresController: application.featuresController }), _jsx(Moments, { application: application }), _jsx(LabsPane, { application: application }), _jsx(OfflineActivation, {})] }));
};
export default observer(General);
