"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentAction = void 0;
var ComponentAction;
(function (ComponentAction) {
    ComponentAction["SetSize"] = "set-size";
    ComponentAction["StreamItems"] = "stream-items";
    ComponentAction["StreamContextItem"] = "stream-context-item";
    ComponentAction["SaveItems"] = "save-items";
    ComponentAction["CreateItem"] = "create-item";
    ComponentAction["CreateItems"] = "create-items";
    ComponentAction["DeleteItems"] = "delete-items";
    ComponentAction["SetComponentData"] = "set-component-data";
    ComponentAction["RequestPermissions"] = "request-permissions";
    ComponentAction["DuplicateItem"] = "duplicate-item";
    ComponentAction["ComponentRegistered"] = "component-registered";
    ComponentAction["ActivateThemes"] = "themes";
    ComponentAction["Reply"] = "reply";
    ComponentAction["ThemesActivated"] = "themes-activated";
    ComponentAction["KeyDown"] = "key-down";
    ComponentAction["KeyUp"] = "key-up";
    ComponentAction["Click"] = "click";
})(ComponentAction || (exports.ComponentAction = ComponentAction = {}));
