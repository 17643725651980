export class PhotoRecorder {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() { }
    static async isSupported() {
        if (!navigator.mediaDevices) {
            return false;
        }
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some((device) => device.kind === 'videoinput');
        return hasCamera;
    }
    async setDevice(deviceId) {
        var _a;
        this.selectedDevice = (_a = this.devices.find((device) => device.deviceId === deviceId)) !== null && _a !== void 0 ? _a : this.devices[0];
        this.stream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: this.selectedDevice.deviceId,
            },
            audio: false,
        });
        this.video.srcObject = this.stream;
        await this.video.play();
        await this.awaitVideoReady(this.video);
    }
    async initialize() {
        var _a, _b;
        this.devices = (await navigator.mediaDevices.enumerateDevices()).filter((device) => device.kind === 'videoinput');
        if (this.devices.length === 0) {
            return;
        }
        this.selectedDevice = this.devices[0];
        this.stream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: this.selectedDevice.deviceId,
            },
            audio: false,
        });
        this.video = document.createElement('video');
        this.video.playsInline = true;
        this.video.style.position = 'absolute';
        this.video.style.display = 'none';
        this.video.oncontextmenu = (e) => e.preventDefault();
        this.canvas = document.createElement('canvas');
        document.body.append(this.video);
        this.video.srcObject = this.stream;
        await this.video.play();
        await this.awaitVideoReady(this.video);
        const videoTrack = this.stream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        this.width = (_a = settings.width) !== null && _a !== void 0 ? _a : 1280;
        this.height = (_b = settings.height) !== null && _b !== void 0 ? _b : 720;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }
    async takePhoto(fileName) {
        if (!this.canvas) {
            return undefined;
        }
        const context = this.canvas.getContext('2d');
        context === null || context === void 0 ? void 0 : context.drawImage(this.video, 0, 0, this.width, this.height);
        const dataUrl = this.canvas.toDataURL('image/png');
        const isFailedImage = dataUrl.length < 100000;
        if (isFailedImage) {
            return undefined;
        }
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        const file = new File([blob], fileName, { type: 'image/png' });
        return file;
    }
    finish() {
        var _a, _b, _c;
        if (!this.canvas || !this.video) {
            return;
        }
        this.video.pause();
        (_a = this.video.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(this.video);
        (_b = this.canvas.parentElement) === null || _b === void 0 ? void 0 : _b.removeChild(this.canvas);
        this.video.remove();
        this.canvas.remove();
        (_c = this.stream) === null || _c === void 0 ? void 0 : _c.getTracks().forEach((track) => {
            track.stop();
        });
    }
    async awaitVideoReady(video) {
        return new Promise((resolve) => {
            video.addEventListener('canplaythrough', () => {
                resolve(null);
            });
        });
    }
}
