export const splitRangeWithinString = (string, start, end) => {
    const isStartOutOfBounds = start > string.length || start < 0;
    const isEndOutOfBounds = end > string.length || end < 0;
    const isInvalidRange = start > end;
    if (isStartOutOfBounds || isEndOutOfBounds || isInvalidRange) {
        return [string];
    }
    else {
        return [string.slice(0, start), string.slice(start, end), string.slice(end)].filter((slice) => slice.length > 0);
    }
};
export const getIndexOfQueryInString = (string, query) => {
    const lowercasedTitle = string.toLowerCase();
    const lowercasedQuery = query.toLowerCase();
    return lowercasedTitle.indexOf(lowercasedQuery);
};
export const splitQueryInString = (string, query) => {
    const indexOfQueryInTitle = getIndexOfQueryInString(string, query);
    if (indexOfQueryInTitle < 0) {
        return [string];
    }
    return splitRangeWithinString(string, indexOfQueryInTitle, indexOfQueryInTitle + query.length);
};
export const capitalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
