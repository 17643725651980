import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CANCEL_SEARCH_COMMAND, CREATE_NEW_NOTE_KEYBOARD_COMMAND, keyboardStringForShortcut, NEXT_LIST_ITEM_KEYBOARD_COMMAND, PREVIOUS_LIST_ITEM_KEYBOARD_COMMAND, SEARCH_KEYBOARD_COMMAND, SELECT_ALL_ITEMS_KEYBOARD_COMMAND, } from '@standardnotes/ui-services';
import { PANEL_NAME_NOTES } from '@/Constants/Constants';
import { Platform, PrefKey, WebAppEvent } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import ContentList from '@/Components/ContentListView/ContentList';
import NoAccountWarning from '@/Components/NoAccountWarning/NoAccountWarning';
import { ElementIds } from '@/Constants/ElementIDs';
import ContentListHeader from './Header/ContentListHeader';
import { AppPaneId } from '../Panes/AppPaneMetadata';
import { useResponsiveAppPane } from '../Panes/ResponsivePaneProvider';
import SearchBar from '../SearchBar/SearchBar';
import { classNames } from '@standardnotes/utils';
import { useFileDragNDrop } from '../FileDragNDropProvider';
import DailyContentList from './Daily/DailyContentList';
import { FeatureName } from '@/Controllers/FeatureName';
import { useForwardedRef } from '@/Hooks/useForwardedRef';
import FloatingAddButton from './FloatingAddButton';
import ContentTableView from '../ContentTableView/ContentTableView';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import EmptyFilesView from './EmptyFilesView';
import { PaneLayout } from '@/Controllers/PaneController/PaneLayout';
import { usePaneSwipeGesture } from '../Panes/usePaneGesture';
import { mergeRefs } from '@/Hooks/mergeRefs';
import Icon from '../Icon/Icon';
import MobileMultiSelectionToolbar from './MobileMultiSelectionToolbar';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
const ContentListView = forwardRef(({ application, className, id, children, onPanelWidthLoad }, ref) => {
    const { paneController, accountMenuController, filesController, itemListController, navigationController, noAccountWarningController, searchOptionsController, linkingController, notesController, } = application;
    const { setPaneLayout, panes } = useResponsiveAppPane();
    const { selected: selectedTag, selectedAsTag } = navigationController;
    const { completedFullSync, createNewNote, optionsSubtitle, paginate, panelTitle, renderedItems, items, isCurrentNoteTemplate, isTableViewEnabled, selectedUuids, selectNextItem, selectPreviousItem, } = itemListController;
    const innerRef = useForwardedRef(ref);
    const { addDragTarget, removeDragTarget } = useFileDragNDrop();
    useEffect(() => {
        return application.addWebEventObserver((event, data) => {
            if (event === WebAppEvent.PanelResized) {
                const { panel, width } = data;
                if (panel === PANEL_NAME_NOTES) {
                    if (selectedAsTag) {
                        void navigationController.setPanelWidthForTag(selectedAsTag, width);
                    }
                    else {
                        void application.setPreference(PrefKey.NotesPanelWidth, width).catch(console.error);
                    }
                }
            }
        });
    }, [application, navigationController, selectedAsTag]);
    useEffect(() => {
        var _a;
        const panelWidth = ((_a = selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.preferences) === null || _a === void 0 ? void 0 : _a.panelWidth) || application.getPreference(PrefKey.NotesPanelWidth);
        if (panelWidth) {
            onPanelWidthLoad(panelWidth);
        }
    }, [selectedTag, application, onPanelWidthLoad]);
    const fileDropCallback = useCallback(async (file) => {
        const currentTag = navigationController.selected;
        if (!currentTag) {
            return;
        }
        if (navigationController.isInAnySystemView() || navigationController.isInSmartView()) {
            console.error('Trying to link uploaded files to smart view');
            return;
        }
        await linkingController.linkItems(file, currentTag);
    }, [navigationController, linkingController]);
    useEffect(() => {
        const target = innerRef.current;
        const currentTag = navigationController.selected;
        const shouldAddDropTarget = !navigationController.isInAnySystemView() && !navigationController.isInSmartView();
        if (target && shouldAddDropTarget && currentTag) {
            addDragTarget(target, {
                tooltipText: `Drop your files to upload and link them to tag "${currentTag.title}"`,
                callback: fileDropCallback,
            });
        }
        return () => {
            if (target) {
                removeDragTarget(target);
            }
        };
    }, [
        addDragTarget,
        fileDropCallback,
        navigationController,
        navigationController.selected,
        removeDragTarget,
        innerRef,
    ]);
    const icon = selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.iconString;
    const isFilesSmartView = useMemo(() => navigationController.isInFilesView, [navigationController.isInFilesView]);
    const addNewItem = useCallback(async () => {
        if (isFilesSmartView) {
            if (!application.entitledToFiles) {
                application.showPremiumModal(FeatureName.Files);
                return;
            }
            void filesController.selectAndUploadNewFiles();
        }
        else {
            await createNewNote();
            setPaneLayout(PaneLayout.Editing);
        }
    }, [isFilesSmartView, application, filesController, createNewNote, setPaneLayout]);
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const shouldUseTableView = (isFilesSmartView || isTableViewEnabled) && !isMobileScreen;
    useEffect(() => {
        const searchBarElement = document.getElementById(ElementIds.SearchBar);
        /**
         * In the browser we're not allowed to override cmd/ctrl + n, so we have to
         * use Control modifier as well. These rules don't apply to desktop, but
         * probably better to be consistent.
         */
        return application.keyboardService.addCommandHandlers([
            {
                command: CREATE_NEW_NOTE_KEYBOARD_COMMAND,
                category: 'General',
                description: 'Create new note',
                onKeyDown: (event) => {
                    event.preventDefault();
                    void addNewItem();
                },
            },
            {
                command: NEXT_LIST_ITEM_KEYBOARD_COMMAND,
                category: 'Notes list',
                description: 'Go to next item',
                elements: [document.body, ...(searchBarElement ? [searchBarElement] : [])],
                onKeyDown: () => {
                    if (searchBarElement === document.activeElement) {
                        searchBarElement === null || searchBarElement === void 0 ? void 0 : searchBarElement.blur();
                    }
                    if (shouldUseTableView) {
                        return;
                    }
                    selectNextItem();
                },
            },
            {
                command: PREVIOUS_LIST_ITEM_KEYBOARD_COMMAND,
                category: 'Notes list',
                description: 'Go to previous item',
                element: document.body,
                onKeyDown: () => {
                    if (shouldUseTableView) {
                        return;
                    }
                    selectPreviousItem();
                },
            },
            {
                command: SEARCH_KEYBOARD_COMMAND,
                category: 'General',
                description: 'Toggle global search',
                onKeyDown: (event) => {
                    if (searchBarElement) {
                        event.preventDefault();
                        searchBarElement.focus();
                    }
                },
            },
            {
                command: CANCEL_SEARCH_COMMAND,
                onKeyDown: () => {
                    if (searchBarElement) {
                        searchBarElement.blur();
                    }
                },
            },
            {
                command: SELECT_ALL_ITEMS_KEYBOARD_COMMAND,
                category: 'General',
                description: 'Select all items',
                onKeyDown: (event) => {
                    const isTargetInsideContentList = event.target.closest(`#${ElementIds.ContentList}`);
                    if (!isTargetInsideContentList) {
                        return;
                    }
                    event.preventDefault();
                    itemListController.selectAll();
                },
            },
        ]);
    }, [
        addNewItem,
        application.keyboardService,
        createNewNote,
        itemListController,
        selectNextItem,
        selectPreviousItem,
        shouldUseTableView,
    ]);
    const shortcutForCreate = useMemo(() => application.keyboardService.keyboardShortcutForCommand(CREATE_NEW_NOTE_KEYBOARD_COMMAND), [application]);
    const addButtonLabel = useMemo(() => {
        return isFilesSmartView
            ? 'Upload file'
            : `Create a new note in the selected tag (${shortcutForCreate && keyboardStringForShortcut(shortcutForCreate)})`;
    }, [isFilesSmartView, shortcutForCreate]);
    const dailyMode = selectedAsTag === null || selectedAsTag === void 0 ? void 0 : selectedAsTag.isDailyEntry;
    const handleDailyListSelection = useCallback(async (item, userTriggered) => {
        await itemListController.selectItemWithScrollHandling(item, {
            userTriggered: true,
            scrollIntoView: userTriggered === false,
            animated: false,
        });
    }, [itemListController]);
    useEffect(() => {
        var _a;
        const hasEditorPane = panes.includes(AppPaneId.Editor);
        if (!hasEditorPane) {
            (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.style.removeProperty('width');
        }
    }, [selectedUuids, innerRef, isCurrentNoteTemplate, renderedItems, panes]);
    const [setElement] = usePaneSwipeGesture('right', () => setPaneLayout(PaneLayout.TagSelection), {
        requiresStartFromEdge: application.platform !== Platform.Android,
    });
    return (_jsxs("div", { id: id, className: classNames(className, 'sn-component section h-full overflow-hidden pt-safe-top'), "aria-label": 'Notes & Files', ref: mergeRefs([innerRef, setElement]), children: [isMobileScreen && !itemListController.isMultipleSelectionMode && (_jsx(FloatingAddButton, { onClick: addNewItem, label: addButtonLabel, style: dailyMode ? 'danger' : 'info' })), _jsx("div", { id: "items-title-bar", className: "section-title-bar border-b border-solid border-border", children: _jsxs("div", { id: "items-title-bar-container", children: [selectedTag && (_jsx(ContentListHeader, { application: application, panelTitle: panelTitle, icon: icon, addButtonLabel: addButtonLabel, addNewItem: addNewItem, isFilesSmartView: isFilesSmartView, isTableViewEnabled: isTableViewEnabled || isFilesSmartView, optionsSubtitle: optionsSubtitle, selectedTag: selectedTag, filesController: filesController, itemListController: itemListController, paneController: paneController })), (!shouldUseTableView || isMobileScreen) && (_jsx(SearchBar, { itemListController: itemListController, searchOptionsController: searchOptionsController, hideOptions: shouldUseTableView })), _jsx(NoAccountWarning, { accountMenuController: accountMenuController, noAccountWarningController: noAccountWarningController })] }) }), itemListController.isMultipleSelectionMode && (_jsxs("div", { className: "flex items-center border-b border-l-2 border-border border-l-transparent py-2.5 pr-4", children: [_jsx("div", { className: "px-4", children: _jsx(StyledTooltip, { label: "Select all items", showOnHover: true, showOnMobile: true, children: _jsx("button", { className: "ml-auto rounded border border-border p-1 hover:bg-contrast", onClick: () => {
                                    itemListController.selectAll();
                                }, children: _jsx(Icon, { type: "select-all", size: "medium" }) }) }) }), _jsxs("div", { className: "text-base font-semibold md:text-sm", children: [itemListController.selectedItemsCount, " selected"] }), _jsx(StyledTooltip, { label: "Cancel multiple selection", showOnHover: true, showOnMobile: true, children: _jsx("button", { className: "ml-auto rounded border border-border p-1 hover:bg-contrast", onClick: () => {
                                itemListController.cancelMultipleSelection();
                            }, children: _jsx(Icon, { type: "close", size: "medium" }) }) })] })), selectedAsTag && dailyMode && (_jsx(DailyContentList, { items: items, selectedTag: selectedAsTag, selectedUuids: selectedUuids, itemListController: itemListController, onSelect: handleDailyListSelection })), !dailyMode && completedFullSync && !renderedItems.length ? (isFilesSmartView ? (_jsx(EmptyFilesView, { addNewItem: addNewItem })) : (_jsx("p", { className: "empty-items-list opacity-50", children: "No items." }))) : null, !dailyMode && !completedFullSync && !renderedItems.length ? (_jsx("p", { className: "empty-items-list opacity-50", children: "Loading..." })) : null, !dailyMode && renderedItems.length ? (shouldUseTableView ? (_jsx(ContentTableView, { items: items, application: application })) : (_jsx(ContentList, { items: renderedItems, selectedUuids: selectedUuids, application: application, paginate: paginate }))) : null, isMobileScreen && itemListController.isMultipleSelectionMode && (_jsx(MobileMultiSelectionToolbar, { notesController: notesController, navigationController: navigationController })), _jsx("div", { className: "absolute bottom-0 h-safe-bottom w-full" }), children] }));
});
export default observer(ContentListView);
