import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Icon from '@/Components/Icon/Icon';
import { FOCUSABLE_BUT_NOT_TABBABLE } from '@/Constants/Constants';
import { classNames } from '@standardnotes/utils';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator/KeyboardShortcutIndicator';
import MenuListItem from './MenuListItem';
const MenuItem = forwardRef(({ children, className = '', icon, iconClassName = 'w-6 h-6 md:w-5 md:h-5 text-neutral mr-2', tabIndex, shortcut, disabled, ...props }, ref) => {
    return (_jsx(MenuListItem, { children: _jsxs("button", { ref: ref, role: "menuitem", tabIndex: typeof tabIndex === 'number' ? tabIndex : FOCUSABLE_BUT_NOT_TABBABLE, className: classNames('flex w-full cursor-pointer select-none border-0 bg-transparent px-3 py-2.5 text-left md:py-1.5', 'text-mobile-menu-item text-text enabled:hover:bg-passive-3 enabled:hover:text-foreground', 'focus:bg-info-backdrop focus:shadow-none md:text-tablet-menu-item lg:text-menu-item', 'disabled:cursor-not-allowed disabled:opacity-60', className, className.includes('items-') ? '' : 'items-center'), disabled: disabled, ...props, children: [shortcut && _jsx(KeyboardShortcutIndicator, { className: "mr-2", shortcut: shortcut }), icon ? _jsx(Icon, { type: icon, className: classNames('flex-shrink-0', iconClassName) }) : null, children] }) }));
});
export default MenuItem;
