"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UIFeature = void 0;
const features_1 = require("@standardnotes/features");
const TypeGuards_1 = require("./TypeGuards");
const domain_core_1 = require("@standardnotes/domain-core");
const Component_1 = require("../../Syncable/Component");
class UIFeature {
    constructor(item) {
        this.item = item;
    }
    get isComponent() {
        return (0, TypeGuards_1.isItemBasedFeature)(this.item);
    }
    get isThemeComponent() {
        return (0, TypeGuards_1.isItemBasedFeature)(this.item) && (0, Component_1.isTheme)(this.item);
    }
    get asComponent() {
        if ((0, TypeGuards_1.isItemBasedFeature)(this.item)) {
            return this.item;
        }
        throw new Error('Cannot cast item to component');
    }
    get asTheme() {
        if ((0, TypeGuards_1.isItemBasedFeature)(this.item)) {
            return this.item;
        }
        throw new Error('Cannot cast item to component');
    }
    get asFeatureDescription() {
        if ((0, TypeGuards_1.isNativeFeature)(this.item)) {
            return this.item;
        }
        throw new Error('Cannot cast item to feature description');
    }
    get isNativeFeature() {
        return (0, features_1.FindNativeFeature)(this.featureIdentifier) !== undefined;
    }
    get uniqueIdentifier() {
        if ((0, TypeGuards_1.isNativeFeature)(this.item)) {
            const nativeFeature = features_1.NativeFeatureIdentifier.create(this.item.identifier);
            return nativeFeature.getValue();
        }
        else {
            return domain_core_1.Uuid.create(this.item.uuid).getValue();
        }
    }
    get featureIdentifier() {
        return this.item.identifier;
    }
    get noteType() {
        var _a;
        if ((0, TypeGuards_1.isNativeFeature)(this.item) && (0, features_1.isEditorFeatureDescription)(this.item)) {
            return (_a = this.item.note_type) !== null && _a !== void 0 ? _a : features_1.NoteType.Unknown;
        }
        else if ((0, TypeGuards_1.isItemBasedFeature)(this.item)) {
            return this.item.noteType;
        }
        throw new Error('Invalid component or feature description');
    }
    get fileType() {
        var _a, _b;
        if ((0, TypeGuards_1.isNativeFeature)(this.item) && (0, features_1.isEditorFeatureDescription)(this.item)) {
            return this.item.file_type;
        }
        else if ((0, TypeGuards_1.isItemBasedFeature)(this.item) && (0, features_1.isEditorFeatureDescription)(this.item.package_info)) {
            return (_b = (_a = this.item.package_info) === null || _a === void 0 ? void 0 : _a.file_type) !== null && _b !== void 0 ? _b : 'txt';
        }
        throw new Error('Invalid component or feature description');
    }
    get displayName() {
        var _a;
        if ((0, TypeGuards_1.isNativeFeature)(this.item)) {
            return (_a = this.item.name) !== null && _a !== void 0 ? _a : '';
        }
        else {
            return this.item.displayName;
        }
    }
    get description() {
        var _a, _b;
        if ((0, TypeGuards_1.isNativeFeature)(this.item)) {
            return (_a = this.item.description) !== null && _a !== void 0 ? _a : '';
        }
        else {
            return (_b = this.item.package_info.description) !== null && _b !== void 0 ? _b : '';
        }
    }
    get deprecationMessage() {
        if ((0, TypeGuards_1.isNativeFeature)(this.item)) {
            return this.item.deprecation_message;
        }
        else {
            return this.item.deprecationMessage;
        }
    }
    get expirationDate() {
        if ((0, TypeGuards_1.isNativeFeature)(this.item)) {
            return this.item.expires_at ? new Date(this.item.expires_at) : undefined;
        }
        else {
            return this.item.valid_until;
        }
    }
    get featureDescription() {
        if ((0, TypeGuards_1.isNativeFeature)(this.item)) {
            return this.item;
        }
        else {
            return this.item.package_info;
        }
    }
    get acquiredPermissions() {
        var _a;
        if ((0, TypeGuards_1.isNativeFeature)(this.item) && (0, features_1.isIframeComponentFeatureDescription)(this.item)) {
            return (_a = this.item.component_permissions) !== null && _a !== void 0 ? _a : [];
        }
        else if ((0, TypeGuards_1.isItemBasedFeature)(this.item)) {
            return this.item.permissions;
        }
        throw new Error('Invalid component or feature description');
    }
    get area() {
        if ('area' in this.item) {
            return this.item.area;
        }
        return features_1.ComponentArea.Editor;
    }
    get layerable() {
        var _a;
        if ((0, TypeGuards_1.isItemBasedFeature)(this.item) && (0, Component_1.isTheme)(this.item)) {
            return this.item.layerableTheme;
        }
        else if ((0, features_1.isThemeFeatureDescription)(this.asFeatureDescription)) {
            return (_a = this.asFeatureDescription.layerable) !== null && _a !== void 0 ? _a : false;
        }
        return false;
    }
    get dockIcon() {
        if ((0, TypeGuards_1.isItemBasedFeature)(this.item) && (0, Component_1.isTheme)(this.item)) {
            return this.asTheme.package_info.dock_icon;
        }
        else if ((0, features_1.isThemeFeatureDescription)(this.asFeatureDescription)) {
            return this.asFeatureDescription.dock_icon;
        }
        return undefined;
    }
}
exports.UIFeature = UIFeature;
