import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CHANGE_EDITOR_COMMAND, KeyboardKey } from '@standardnotes/ui-services';
import { useCallback, useMemo, useRef, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import ChangeEditorMenu from '@/Components/ChangeEditor/ChangeEditorMenu';
import Popover from '../Popover/Popover';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator/KeyboardShortcutIndicator';
import MenuItem from '../Menu/MenuItem';
const ChangeEditorOption = ({ application, note, iconClassName, disabled, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuContainerRef = useRef(null);
    const buttonRef = useRef(null);
    const toggleMenu = useCallback(async () => {
        setIsOpen((isOpen) => !isOpen);
    }, []);
    const shortcut = useMemo(() => application.keyboardService.keyboardShortcutForCommand(CHANGE_EDITOR_COMMAND), [application]);
    return (_jsxs("div", { ref: menuContainerRef, children: [_jsxs(MenuItem, { className: "justify-between", onClick: toggleMenu, onKeyDown: (event) => {
                    if (event.key === KeyboardKey.Escape) {
                        setIsOpen(false);
                    }
                }, disabled: disabled, ref: buttonRef, children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { type: "dashboard", className: `${iconClassName} mr-2 text-neutral` }), "Change note type"] }), _jsxs("div", { className: "flex", children: [shortcut && _jsx(KeyboardShortcutIndicator, { className: 'mr-2', shortcut: shortcut }), _jsx(Icon, { type: "chevron-right", className: "text-neutral" })] })] }), _jsx(Popover, { title: "Change note type", align: "start", anchorElement: buttonRef, className: "md:pb-1", open: isOpen, side: "right", togglePopover: toggleMenu, children: _jsx(ChangeEditorMenu, { application: application, note: note, closeMenu: () => {
                        setIsOpen(false);
                    } }) })] }));
};
export default ChangeEditorOption;
