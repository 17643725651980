import { removeFromArray } from '@standardnotes/utils';
import { NoteViewController } from './NoteViewController';
import { FileViewController } from './FileViewController';
export class ItemGroupController {
    constructor(items, mutator, sync, sessions, preferences, components, alerts, _isNativeMobileWeb) {
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.sessions = sessions;
        this.preferences = preferences;
        this.components = components;
        this.alerts = alerts;
        this._isNativeMobileWeb = _isNativeMobileWeb;
        this.itemControllers = [];
        this.changeObservers = [];
        this.eventObservers = [];
    }
    deinit() {
        ;
        this.items = undefined;
        this.eventObservers.forEach((removeObserver) => {
            removeObserver();
        });
        this.changeObservers.length = 0;
        for (const controller of this.itemControllers) {
            this.closeItemController(controller, { notify: false });
        }
        this.itemControllers.length = 0;
    }
    async createItemController(context) {
        if (this.activeItemViewController) {
            this.closeItemController(this.activeItemViewController, { notify: false });
        }
        let controller;
        if (context.file) {
            controller = new FileViewController(context.file, this.items);
        }
        else if (context.note) {
            controller = new NoteViewController(context.note, this.items, this.mutator, this.sync, this.sessions, this.preferences, this.components, this.alerts, this._isNativeMobileWeb);
        }
        else if (context.templateOptions) {
            controller = new NoteViewController(undefined, this.items, this.mutator, this.sync, this.sessions, this.preferences, this.components, this.alerts, this._isNativeMobileWeb, context.templateOptions);
        }
        else {
            throw Error('Invalid input to createItemController');
        }
        this.itemControllers.push(controller);
        await controller.initialize();
        this.notifyObservers();
        return controller;
    }
    closeItemController(controller, { notify = true } = { notify: true }) {
        if (controller instanceof NoteViewController) {
            controller.syncOnlyIfLargeNote();
        }
        controller.deinit();
        removeFromArray(this.itemControllers, controller);
        if (notify) {
            this.notifyObservers();
        }
    }
    closeActiveItemController() {
        const activeController = this.activeItemViewController;
        if (activeController) {
            this.closeItemController(activeController, { notify: true });
        }
    }
    closeAllItemControllers() {
        for (const controller of this.itemControllers) {
            this.closeItemController(controller, { notify: false });
        }
        this.notifyObservers();
    }
    get activeItemViewController() {
        return this.itemControllers[0];
    }
    /**
     * Notifies observer when the active controller has changed.
     */
    addActiveControllerChangeObserver(callback) {
        this.changeObservers.push(callback);
        if (this.activeItemViewController) {
            callback(this.activeItemViewController);
        }
        const thislessChangeObservers = this.changeObservers;
        return () => {
            removeFromArray(thislessChangeObservers, callback);
        };
    }
    notifyObservers() {
        for (const observer of this.changeObservers) {
            observer(this.activeItemViewController);
        }
    }
}
