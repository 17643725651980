export class AndroidBackHandler {
    constructor() {
        this.listeners = new Set();
    }
    setFallbackListener(listener) {
        this.fallbackListener = listener;
    }
    addEventListener(listener) {
        this.listeners.add(listener);
        return () => {
            this.listeners.delete(listener);
        };
    }
    notifyEvent() {
        let handled = false;
        for (const listener of Array.from(this.listeners).reverse()) {
            if (listener()) {
                handled = true;
                return;
            }
            else {
                handled = false;
            }
        }
        if (!handled && this.fallbackListener) {
            this.fallbackListener();
        }
    }
}
