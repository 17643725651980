import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, memo } from 'react';
import { observer } from 'mobx-react-lite';
const LinkingControllerContext = createContext(undefined);
export const useLinkingController = () => {
    const value = useContext(LinkingControllerContext);
    if (!value) {
        throw new Error('Component must be a child of <LinkingControllerProvider />');
    }
    return value;
};
const MemoizedChildren = memo(({ children }) => _jsx(_Fragment, { children: children }));
const LinkingControllerProvider = ({ controller, children }) => {
    return (_jsx(LinkingControllerContext.Provider, { value: controller, children: _jsx(MemoizedChildren, { children: children }) }));
};
export default observer(LinkingControllerProvider);
