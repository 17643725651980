import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { SNNote } from '@standardnotes/snjs';
import Icon from '../Icon/Icon';
import { PremiumFeatureIconName } from '../Icon/PremiumFeatureIcon';
import LinkedItemMeta from './LinkedItemMeta';
import { LinkedItemSearchResultsAddTagOption } from './LinkedItemSearchResultsAddTagOption';
import { useCallback } from 'react';
const LinkedItemSearchResults = ({ createAndAddNewTag, linkItems, results, searchQuery, shouldShowCreateTag, onClickCallback, isEntitledToNoteLinking, item, }) => {
    const onClickAddNew = useCallback((searchQuery) => {
        void createAndAddNewTag(searchQuery);
        onClickCallback === null || onClickCallback === void 0 ? void 0 : onClickCallback();
    }, [createAndAddNewTag, onClickCallback]);
    return (_jsxs("div", { className: "my-1", children: [results.map((result) => {
                const cannotLinkItem = !isEntitledToNoteLinking && result instanceof SNNote;
                return (_jsxs("button", { className: "flex w-full items-center justify-between gap-4 overflow-hidden px-3 py-2 hover:bg-contrast hover:text-foreground focus:bg-info-backdrop", onClick: () => {
                        void linkItems(item, result);
                        onClickCallback === null || onClickCallback === void 0 ? void 0 : onClickCallback();
                    }, children: [_jsx(LinkedItemMeta, { item: result, searchQuery: searchQuery }), cannotLinkItem && _jsx(Icon, { type: PremiumFeatureIconName, className: "ml-auto flex-shrink-0 text-info" })] }, result.uuid));
            }), shouldShowCreateTag && (_jsx(LinkedItemSearchResultsAddTagOption, { searchQuery: searchQuery, onClickCallback: onClickAddNew }))] }));
};
export default observer(LinkedItemSearchResults);
