/**
 * The File System Access API File Picker
 * https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API
 */
export class StreamingFileSaver {
    constructor(name) {
        this.name = name;
        this.loggingEnabled = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log(...args) {
        if (!this.loggingEnabled) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log(args);
    }
    static available() {
        return window.showSaveFilePicker != undefined;
    }
    /** This function must be called in response to a user interaction, otherwise, it will be rejected by the browser. */
    async selectFileToSaveTo(handle) {
        this.log('Showing save file picker');
        const downloadHandle = handle
            ? handle
            : await window.showSaveFilePicker({
                suggestedName: this.name,
            });
        this.writableStream = await downloadHandle.createWritable();
    }
    async pushBytes(bytes) {
        if (!this.writableStream) {
            throw Error('Must call selectFileToSaveTo first');
        }
        this.log('Writing chunk to disk of size', bytes.length);
        await this.writableStream.write(bytes);
    }
    async finish() {
        if (!this.writableStream) {
            throw Error('Must call selectFileToSaveTo first');
        }
        this.log('Closing write stream');
        await this.writableStream.close();
    }
}
