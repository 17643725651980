export class StreamingFileApi {
    async selectDirectory() {
        try {
            const nativeHandle = await window.showDirectoryPicker();
            return { nativeHandle };
        }
        catch (error) {
            return 'aborted';
        }
    }
    async createFile(directory, name) {
        const nativeHandle = await directory.nativeHandle.getFileHandle(name, { create: true });
        const writableStream = await nativeHandle.createWritable();
        return {
            nativeHandle,
            writableStream,
        };
    }
    async createDirectory(parentDirectory, name) {
        const nativeHandle = await parentDirectory.nativeHandle.getDirectoryHandle(name, { create: true });
        return { nativeHandle };
    }
    async saveBytes(file, bytes) {
        await file.writableStream.write(bytes);
        return 'success';
    }
    async saveString(file, contents) {
        await file.writableStream.write(contents);
        return 'success';
    }
    async closeFileWriteStream(file) {
        await file.writableStream.close();
        return 'success';
    }
    async selectFile() {
        try {
            const selection = await window.showOpenFilePicker();
            const file = selection[0];
            return {
                nativeHandle: file,
            };
        }
        catch (_) {
            return 'aborted';
        }
    }
    async readFile(fileHandle, onBytes) {
        const file = await fileHandle.nativeHandle.getFile();
        const stream = file.stream();
        const reader = stream.getReader();
        let previousChunk;
        const processChunk = async (result) => {
            if (result.done) {
                await onBytes(previousChunk, true);
                return;
            }
            if (previousChunk) {
                await onBytes(previousChunk, false);
            }
            previousChunk = result.value;
            return reader.read().then(processChunk);
        };
        await reader.read().then(processChunk);
        return 'success';
    }
}
