import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useRef, useCallback } from 'react';
import RoundIconButton from '../Button/RoundIconButton';
import Popover from '../Popover/Popover';
import LinkedItemsPanel from './LinkedItemsPanel';
const LinkedItemsButton = ({ linkingController, onClick, onClickPreprocessing }) => {
    const { activeItem, isLinkingPanelOpen, setIsLinkingPanelOpen } = linkingController;
    const buttonRef = useRef(null);
    const toggleMenu = useCallback(async () => {
        const willMenuOpen = !isLinkingPanelOpen;
        if (willMenuOpen && onClickPreprocessing) {
            await onClickPreprocessing();
        }
        setIsLinkingPanelOpen(willMenuOpen);
        if (onClick) {
            onClick();
        }
    }, [isLinkingPanelOpen, onClick, onClickPreprocessing, setIsLinkingPanelOpen]);
    if (!activeItem) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(RoundIconButton, { label: "Linked items panel", onClick: toggleMenu, ref: buttonRef, icon: "link" }), _jsx(Popover, { title: "Linked items", togglePopover: toggleMenu, anchorElement: buttonRef, open: isLinkingPanelOpen, className: "pb-2", forceFullHeightOnMobile: true, children: _jsx(LinkedItemsPanel, { item: activeItem }) })] }));
};
export default observer(LinkedItemsButton);
