import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AutoEmbedOption, LexicalAutoEmbedPlugin, URL_MATCHER, } from '@lexical/react/LexicalAutoEmbedPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';
import useModal from '../../Lexical/Hooks/useModal';
import { INSERT_TWEET_COMMAND } from '../TwitterPlugin';
import { INSERT_YOUTUBE_COMMAND } from '../YouTubePlugin';
import { classNames } from '@standardnotes/snjs';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import Button from '@/Components/Button/Button';
import { isMobileScreen } from '../../../../Utils';
export const YoutubeEmbedConfig = {
    contentName: 'Youtube Video',
    exampleUrl: 'https://www.youtube.com/watch?v=jNQXAC9IVRw',
    // Icon for display.
    icon: _jsx("i", { className: "icon youtube" }),
    iconName: 'youtube',
    insertNode: (editor, result) => {
        editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, result.id);
    },
    keywords: ['youtube', 'video'],
    // Determine if a given URL is a match and return url data.
    parseUrl: (url) => {
        const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);
        const id = match ? ((match === null || match === void 0 ? void 0 : match[2].length) === 11 ? match[2] : null) : null;
        if (id != null) {
            return {
                id,
                url,
            };
        }
        return null;
    },
    type: 'youtube-video',
};
export const TwitterEmbedConfig = {
    // e.g. Tweet or Google Map.
    contentName: 'Tweet',
    exampleUrl: 'https://twitter.com/jack/status/20',
    // Icon for display.
    icon: _jsx("i", { className: "icon tweet" }),
    iconName: 'tweet',
    // Create the Lexical embed node from the url data.
    insertNode: (editor, result) => {
        editor.dispatchCommand(INSERT_TWEET_COMMAND, result.id);
    },
    // For extra searching.
    keywords: ['tweet', 'twitter'],
    // Determine if a given URL is a match and return url data.
    parseUrl: (text) => {
        const match = /^https:\/\/twitter\.com\/(#!\/)?(\w+)\/status(es)*\/(\d+)$/.exec(text);
        if (match != null) {
            return {
                id: match[4],
                url: match[0],
            };
        }
        return null;
    },
    type: 'tweet',
};
export const EmbedConfigs = [TwitterEmbedConfig, YoutubeEmbedConfig];
function AutoEmbedMenuItem({ index, isSelected, onClick, onMouseEnter, option, }) {
    return (_jsx("li", { tabIndex: -1, className: classNames('cursor-pointer rounded px-2 py-1', isSelected && 'bg-info-backdrop'), ref: option.setRefElement, role: "option", "aria-selected": isSelected, id: 'typeahead-item-' + index, onMouseEnter: onMouseEnter, onClick: onClick, children: _jsx("span", { className: "text", children: option.title }) }, option.key));
}
function AutoEmbedMenu({ options, selectedItemIndex, onOptionClick, onOptionMouseEnter, }) {
    return (_jsx("div", { className: "typeahead-popover min-w-max rounded border border-border bg-default p-1", children: _jsx("ul", { className: "list-none", children: options.map((option, i) => (_jsx(AutoEmbedMenuItem, { index: i, isSelected: selectedItemIndex === i, onClick: () => onOptionClick(option, i), onMouseEnter: () => onOptionMouseEnter(i), option: option }, option.key))) }) }));
}
const debounce = (callback, delay) => {
    let timeoutId;
    return (text) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback(text);
        }, delay);
    };
};
export function AutoEmbedDialog({ embedConfig, onClose, }) {
    const [text, setText] = useState('');
    const [editor] = useLexicalComposerContext();
    const [embedResult, setEmbedResult] = useState(null);
    const validateText = useMemo(() => debounce((inputText) => {
        const urlMatch = URL_MATCHER.exec(inputText);
        if (embedConfig != null && inputText != null && urlMatch != null) {
            void Promise.resolve(embedConfig.parseUrl(inputText)).then((parseResult) => {
                setEmbedResult(parseResult);
            });
        }
        else if (embedResult != null) {
            setEmbedResult(null);
        }
    }, 200), [embedConfig, embedResult]);
    const onClick = () => {
        if (embedResult != null) {
            embedConfig.insertNode(editor, embedResult);
            onClose();
        }
    };
    const focusOnMount = useCallback((element) => {
        if (element) {
            setTimeout(() => element.focus());
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("label", { className: "flex flex-col gap-1.5", children: ["URL:", _jsx(DecoratedInput, { value: text, onChange: (text) => {
                            setText(text);
                            validateText(text);
                        }, ref: focusOnMount })] }), _jsx("div", { className: "mt-2.5 flex justify-end", children: _jsx(Button, { disabled: !embedResult, onClick: onClick, small: isMobileScreen(), children: "Embed" }) })] }));
}
export default function AutoEmbedPlugin() {
    const [modal, showModal] = useModal();
    const openEmbedModal = (embedConfig) => {
        showModal(`Embed ${embedConfig.contentName}`, (onClose) => (_jsx(AutoEmbedDialog, { embedConfig: embedConfig, onClose: onClose })));
    };
    const getMenuOptions = (activeEmbedConfig, embedFn, dismissFn) => {
        return [
            new AutoEmbedOption('Dismiss', {
                onSelect: dismissFn,
            }),
            new AutoEmbedOption(`Embed ${activeEmbedConfig.contentName}`, {
                onSelect: embedFn,
            }),
        ];
    };
    return (_jsxs(_Fragment, { children: [modal, _jsx(LexicalAutoEmbedPlugin, { embedConfigs: EmbedConfigs, onOpenEmbedModalForConfig: openEmbedModal, getMenuOptions: getMenuOptions, menuRenderFn: (anchorElementRef, { selectedIndex, options, selectOptionAndCleanUp, setHighlightedIndex }) => {
                    return anchorElementRef.current
                        ? ReactDOM.createPortal(_jsx("div", { className: "typeahead-popover auto-embed-menu", style: {
                                marginLeft: anchorElementRef.current.style.width,
                            }, children: _jsx(AutoEmbedMenu, { options: options, selectedItemIndex: selectedIndex, onOptionClick: (option, index) => {
                                    setHighlightedIndex(index);
                                    selectOptionAndCleanUp(option);
                                }, onOptionMouseEnter: (index) => {
                                    setHighlightedIndex(index);
                                } }) }), anchorElementRef.current)
                        : null;
                } })] }));
}
