import { log as utilsLog } from '@standardnotes/utils';
import { isDev } from './Utils';
export var LoggingDomain;
(function (LoggingDomain) {
    LoggingDomain[LoggingDomain["DailyNotes"] = 0] = "DailyNotes";
    LoggingDomain[LoggingDomain["NoteView"] = 1] = "NoteView";
    LoggingDomain[LoggingDomain["ItemsList"] = 2] = "ItemsList";
    LoggingDomain[LoggingDomain["NavigationList"] = 3] = "NavigationList";
    LoggingDomain[LoggingDomain["Viewport"] = 4] = "Viewport";
    LoggingDomain[LoggingDomain["Selection"] = 5] = "Selection";
    LoggingDomain[LoggingDomain["BlockEditor"] = 6] = "BlockEditor";
    LoggingDomain[LoggingDomain["Purchasing"] = 7] = "Purchasing";
    LoggingDomain[LoggingDomain["Panes"] = 8] = "Panes";
    LoggingDomain[LoggingDomain["U2F"] = 9] = "U2F";
})(LoggingDomain || (LoggingDomain = {}));
const LoggingStatus = {
    [LoggingDomain.DailyNotes]: false,
    [LoggingDomain.NoteView]: false,
    [LoggingDomain.ItemsList]: false,
    [LoggingDomain.NavigationList]: false,
    [LoggingDomain.Viewport]: false,
    [LoggingDomain.Selection]: false,
    [LoggingDomain.BlockEditor]: false,
    [LoggingDomain.Purchasing]: false,
    [LoggingDomain.Panes]: false,
    [LoggingDomain.U2F]: false,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(domain, ...args) {
    if (!isDev || !LoggingStatus[domain]) {
        return;
    }
    utilsLog(LoggingDomain[domain], ...args);
}
