"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOfflineSyncPushContextPayload = void 0;
const Payload_1 = require("../Payload");
function CreateOfflineSyncPushContextPayload(fromPayload) {
    const base = {
        content: undefined,
        content_type: fromPayload.content_type,
        created_at_timestamp: fromPayload.created_at_timestamp,
        created_at: fromPayload.created_at,
        deleted: false,
        duplicate_of: fromPayload.duplicate_of,
        updated_at_timestamp: fromPayload.updated_at_timestamp,
        updated_at: fromPayload.updated_at,
        uuid: fromPayload.uuid,
    };
    if ((0, Payload_1.isDecryptedPayload)(fromPayload)) {
        return Object.assign(Object.assign({}, base), { content: fromPayload.content });
    }
    else {
        return Object.assign(Object.assign({}, base), { deleted: fromPayload.deleted });
    }
}
exports.CreateOfflineSyncPushContextPayload = CreateOfflineSyncPushContextPayload;
