"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateNonDecryptedPayloadSplit = exports.CreatePayloadSplitWithDiscardables = exports.CreatePayloadSplit = void 0;
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
function CreatePayloadSplit(payloads) {
    const split = {
        encrypted: [],
        decrypted: [],
        deleted: [],
    };
    for (const payload of payloads) {
        if ((0, TypeCheck_1.isDecryptedPayload)(payload)) {
            split.decrypted.push(payload);
        }
        else if ((0, TypeCheck_1.isEncryptedPayload)(payload)) {
            split.encrypted.push(payload);
        }
        else if ((0, TypeCheck_1.isDeletedPayload)(payload)) {
            split.deleted.push(payload);
        }
        else {
            throw Error('Unhandled case in CreatePayloadSplit');
        }
    }
    return split;
}
exports.CreatePayloadSplit = CreatePayloadSplit;
function CreatePayloadSplitWithDiscardables(payloads) {
    const split = {
        encrypted: [],
        decrypted: [],
        deleted: [],
        discardable: [],
    };
    for (const payload of payloads) {
        if ((0, TypeCheck_1.isDecryptedPayload)(payload)) {
            split.decrypted.push(payload);
        }
        else if ((0, TypeCheck_1.isEncryptedPayload)(payload)) {
            split.encrypted.push(payload);
        }
        else if ((0, TypeCheck_1.isDeletedPayload)(payload)) {
            if (payload.discardable) {
                split.discardable.push(payload);
            }
            else {
                split.deleted.push(payload);
            }
        }
        else {
            throw Error('Unhandled case in CreatePayloadSplitWithDiscardables');
        }
    }
    return split;
}
exports.CreatePayloadSplitWithDiscardables = CreatePayloadSplitWithDiscardables;
function CreateNonDecryptedPayloadSplit(payloads) {
    const split = {
        encrypted: [],
        deleted: [],
    };
    for (const payload of payloads) {
        if ((0, TypeCheck_1.isEncryptedPayload)(payload)) {
            split.encrypted.push(payload);
        }
        else if ((0, TypeCheck_1.isDeletedPayload)(payload)) {
            split.deleted.push(payload);
        }
        else {
            throw Error('Unhandled case in CreateNonDecryptedPayloadSplit');
        }
    }
    return split;
}
exports.CreateNonDecryptedPayloadSplit = CreateNonDecryptedPayloadSplit;
