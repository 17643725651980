import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from '@/Components/Preferences/PreferencesComponents/Content';
import { observer } from 'mobx-react-lite';
const TwoFactorDescription = ({ auth }) => {
    if (!auth.isLoggedIn()) {
        return _jsx(Text, { children: "Sign in or register for an account to configure 2FA." });
    }
    return _jsx(Text, { children: "An extra layer of security when logging in to your account." });
};
export default observer(TwoFactorDescription);
