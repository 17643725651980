import { ContentType } from '@standardnotes/domain-core';
import { ConflictStrategy, DecryptedItem, } from '@standardnotes/models';
export function isKeySystemItemsKey(x) {
    return x.content_type === ContentType.TYPES.KeySystemItemsKey;
}
/**
 * A key used to encrypt other items. Items keys are synced and persisted.
 */
export class KeySystemItemsKey extends DecryptedItem {
    constructor(payload) {
        super(payload);
        this.creationTimestamp = payload.content.creationTimestamp;
        this.keyVersion = payload.content.version;
        this.itemsKey = this.payload.content.itemsKey;
        this.rootKeyToken = this.payload.content.rootKeyToken;
    }
    /** Do not duplicate vault items keys. Always keep original */
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return ConflictStrategy.KeepBase;
    }
}
