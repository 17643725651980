import { INSERT_COLLAPSIBLE_COMMAND } from '../../Plugins/CollapsiblePlugin';
import { BlockPickerOption } from '../BlockPickerPlugin/BlockPickerOption';
export const CollapsibleBlock = {
    name: 'Collapsible',
    iconName: 'details-block',
    keywords: ['collapse', 'collapsible', 'toggle'],
    onSelect: (editor) => editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined),
};
export function GetCollapsibleBlockOption(editor) {
    return new BlockPickerOption(CollapsibleBlock.name, {
        iconName: CollapsibleBlock.iconName,
        keywords: CollapsibleBlock.keywords,
        onSelect: () => CollapsibleBlock.onSelect(editor),
    });
}
