import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Title, Text, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@/Components/Button/Button';
import { FileBackupsConstantsV1 } from '@standardnotes/snjs';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Icon from '@/Components/Icon/Icon';
import { ClassicFileReader, StreamingFileApi } from '@standardnotes/filepicker';
import EncryptionStatusItem from '../../Security/EncryptionStatusItem';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import Spinner from '@/Components/Spinner/Spinner';
const BackupsDropZone = ({ application }) => {
    const [droppedFile, setDroppedFile] = useState(undefined);
    const [decryptedFileItem, setDecryptedFileItem] = useState(undefined);
    const [binaryFile, setBinaryFile] = useState(undefined);
    const [isSavingAsDecrypted, setIsSavingAsDecrypted] = useState(false);
    const fileSystem = useMemo(() => new StreamingFileApi(), []);
    useEffect(() => {
        if (droppedFile) {
            void application.files.decryptBackupMetadataFile(droppedFile).then(setDecryptedFileItem);
        }
        else {
            setDecryptedFileItem(undefined);
        }
    }, [droppedFile, application]);
    const chooseRelatedBinaryFile = useCallback(async () => {
        const selection = await application.files.selectFile(fileSystem);
        if (selection === 'aborted' || selection === 'failed') {
            return;
        }
        setBinaryFile(selection);
    }, [application, fileSystem]);
    const downloadBinaryFileAsDecrypted = useCallback(async () => {
        if (!decryptedFileItem || !binaryFile) {
            return;
        }
        setIsSavingAsDecrypted(true);
        const result = await application.files.readBackupFileAndSaveDecrypted(binaryFile, decryptedFileItem, fileSystem);
        if (result === 'success') {
            void application.alerts.alert(`<strong>${decryptedFileItem.name}</strong> has been successfully decrypted and saved to your chosen directory.`);
            setBinaryFile(undefined);
            setDecryptedFileItem(undefined);
            setDroppedFile(undefined);
        }
        else if (result === 'failed') {
            void application.alerts.alert('Unable to save file to local directory. This may be caused by failure to decrypt, or failure to save the file locally.');
        }
        setIsSavingAsDecrypted(false);
    }, [decryptedFileItem, application, binaryFile, fileSystem]);
    const handleFileSelection = useCallback(async (file) => {
        const text = await file.text();
        const type = application.files.isFileNameFileBackupRelated(file.name);
        if (type === false) {
            return;
        }
        if (type === 'binary') {
            void application.alerts.alert('Please drag the metadata file instead of the encrypted data file.');
            return;
        }
        try {
            const metadata = JSON.parse(text);
            setDroppedFile(metadata);
        }
        catch (error) {
            console.error(error);
        }
    }, [application.alerts, application.files]);
    const handleDragOver = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const handleDragIn = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const handleDragOut = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const handleDrop = useCallback(async (event) => {
        var _a;
        event.preventDefault();
        event.stopPropagation();
        const items = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items;
        if (!items || items.length === 0) {
            return;
        }
        const item = items[0];
        const file = item.getAsFile();
        if (!file) {
            return;
        }
        await handleFileSelection(file).catch(console.error);
        event.dataTransfer.clearData();
    }, [handleFileSelection]);
    useEffect(() => {
        window.addEventListener('dragenter', handleDragIn);
        window.addEventListener('dragleave', handleDragOut);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('drop', handleDrop);
        return () => {
            window.removeEventListener('dragenter', handleDragIn);
            window.removeEventListener('dragleave', handleDragOut);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('drop', handleDrop);
        };
    }, [handleDragIn, handleDrop, handleDragOver, handleDragOut]);
    if (!droppedFile) {
        return (_jsxs(_Fragment, { children: [_jsxs(Text, { className: "mb-2", children: ["To decrypt a backup file, drag and drop the file's respective ", _jsx("i", { children: "metadata.sn.json" }), " file here or select it below."] }), _jsx(Button, { onClick: () => {
                        ClassicFileReader.selectFiles()
                            .then(async (files) => {
                            if (files.length === 0) {
                                return;
                            }
                            const file = files[0];
                            handleFileSelection(file).catch(console.error);
                        })
                            .catch(console.error);
                    }, children: "Select file" })] }));
    }
    return (_jsx(_Fragment, { children: _jsxs(PreferencesSegment, { children: [!decryptedFileItem && _jsx(Text, { children: "Attempting to decrypt metadata file..." }), decryptedFileItem && (_jsxs(_Fragment, { children: [_jsx(Title, { children: "Backup Decryption" }), _jsx(EncryptionStatusItem, { status: decryptedFileItem.name, icon: _jsx(Icon, { type: "attachment-file", className: "min-h-5 min-w-5" }), checkmark: true }), _jsx(HorizontalSeparator, { classes: 'mt-3 mb-3' }), _jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { children: [_jsx(Subtitle, { children: "1. Choose related data file" }), _jsxs(Text, { className: `em mr-3 text-xs ${binaryFile ? 'success font-bold' : ''}`, children: [droppedFile.file.uuid, "/", FileBackupsConstantsV1.BinaryFileName] })] }), _jsx("div", { children: _jsx(Button, { label: "Choose", className: 'min-w-40 px-1 text-xs', onClick: chooseRelatedBinaryFile, disabled: !!binaryFile }) })] }), _jsx(HorizontalSeparator, { classes: 'mt-3 mb-3' }), _jsxs("div", { className: "flex items-center justify-between", children: [_jsx(Subtitle, { children: "2. Decrypt and save file to your computer" }), _jsx("div", { children: _jsx(Button, { label: isSavingAsDecrypted ? undefined : 'Save', className: 'min-w-40 px-1 text-xs', onClick: downloadBinaryFileAsDecrypted, disabled: isSavingAsDecrypted || !binaryFile, children: isSavingAsDecrypted && (_jsx("div", { className: "flex w-full justify-center", children: _jsx(Spinner, { className: "h-5 w-5" }) })) }) })] })] }))] }) }));
};
export default BackupsDropZone;
