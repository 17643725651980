import { WebCrypto } from '@/Application/Crypto';
import { Platform, SNApplication, isDesktopDevice, PrefKey, ContentType, WebAppEvent, MobileUnlockTiming, Environment, ApplicationOptionsDefaults, InternalFeatureService, ApiVersion, } from '@standardnotes/snjs';
import { action, computed, makeObservable, observable } from 'mobx';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import { getBlobFromBase64, isDesktopApplication, isDev } from '@/Utils';
import { WebAlertService, } from '@standardnotes/ui-services';
import { setCustomViewportHeight } from '@/setViewportHeightWithFallback';
import { VisibilityObserver } from './VisibilityObserver';
import { DevMode } from './DevMode';
import { ToastType, addToast, dismissToast } from '@standardnotes/toast';
import { WebDependencies } from './Dependencies/WebDependencies';
import { Web_TYPES } from './Dependencies/Types';
import { removeFromArray } from '@standardnotes/utils';
import { FileItemActionType } from '@/Components/AttachedFilesPopover/PopoverFileItemAction';
export class WebApplication extends SNApplication {
    constructor(deviceInterface, platform, identifier, defaultSyncServerHost, webSocketUrl) {
        super({
            environment: deviceInterface.environment,
            platform: platform,
            deviceInterface: deviceInterface,
            crypto: WebCrypto,
            alertService: new WebAlertService(),
            identifier,
            defaultHost: defaultSyncServerHost,
            appVersion: deviceInterface.appVersion,
            webSocketUrl: webSocketUrl,
            /**
             * iOS file:// based origin does not work with production cookies
             */
            apiVersion: platform === Platform.Ios || platform === Platform.Android ? ApiVersion.v0 : ApiVersion.v1,
            loadBatchSize: deviceInterface.environment === Environment.Mobile ? 250 : ApplicationOptionsDefaults.loadBatchSize,
            sleepBetweenBatches: deviceInterface.environment === Environment.Mobile ? 250 : ApplicationOptionsDefaults.sleepBetweenBatches,
            allowMultipleSelection: deviceInterface.environment !== Environment.Mobile,
            allowNoteSelectionStatePersistence: deviceInterface.environment !== Environment.Mobile,
            u2fAuthenticatorRegistrationPromptFunction: startRegistration,
            u2fAuthenticatorVerificationPromptFunction: startAuthentication,
        });
        this.enableUnfinishedFeatures = window === null || window === void 0 ? void 0 : window.enabledUnfinishedFeatures;
        this.deps = new WebDependencies(this);
        this.webEventObservers = [];
        this.disposers = [];
        this.isSessionsModalVisible = false;
        this.openSessionsModal = () => {
            this.isSessionsModalVisible = true;
        };
        this.closeSessionsModal = () => {
            this.isSessionsModalVisible = false;
        };
        this.addNativeMobileEventListener = (listener) => {
            if (!this.mobileWebReceiver) {
                return;
            }
            return this.mobileWebReceiver.addReactListener(listener);
        };
        makeObservable(this, {
            dealloced: observable,
            preferencesController: computed,
            isSessionsModalVisible: observable,
            openSessionsModal: action,
            closeSessionsModal: action,
        });
        this.createBackgroundServices();
    }
    createBackgroundServices() {
        void this.mobileWebReceiver;
        void this.autolockService;
        void this.persistence;
        if (this.environment !== Environment.Clipper) {
            void this.themeManager;
        }
        void this.momentsService;
        void this.routeService;
        if (isDev) {
            this.devMode = new DevMode(this);
        }
        if (!this.isNativeMobileWeb()) {
            this.webOrDesktopDevice.setApplication(this);
        }
        const appEventObserver = this.deps.get(Web_TYPES.ApplicationEventObserver);
        this.disposers.push(this.addEventObserver(appEventObserver.handle.bind(appEventObserver)));
        if (this.isNativeMobileWeb()) {
            this.disposers.push(this.addEventObserver(async (event) => {
                this.mobileDevice.notifyApplicationEvent(event);
            }));
            // eslint-disable-next-line no-console
            console.log = (...args) => {
                this.mobileDevice.consoleLog(...args);
            };
        }
        if (!isDesktopApplication()) {
            this.visibilityObserver = new VisibilityObserver((event) => {
                this.notifyWebEvent(event);
            });
        }
    }
    deinit(mode, source) {
        if (!this.isNativeMobileWeb()) {
            this.webOrDesktopDevice.removeApplication(this);
        }
        super.deinit(mode, source);
        for (const disposer of this.disposers) {
            disposer();
        }
        this.disposers.length = 0;
        this.deps.deinit();
        try {
            this.webEventObservers.length = 0;
            if (this.visibilityObserver) {
                this.visibilityObserver.deinit();
                this.visibilityObserver = undefined;
            }
        }
        catch (error) {
            console.error('Error while deiniting application', error);
        }
    }
    addWebEventObserver(observer) {
        this.webEventObservers.push(observer);
        return () => {
            removeFromArray(this.webEventObservers, observer);
        };
    }
    notifyWebEvent(event, data) {
        for (const observer of this.webEventObservers) {
            observer(event, data);
        }
        this.events.publish({ type: event, payload: data });
    }
    publishPanelDidResizeEvent(name, width, collapsed) {
        const data = {
            panel: name,
            collapsed,
            width,
        };
        this.notifyWebEvent(WebAppEvent.PanelResized, data);
    }
    get desktopDevice() {
        if (isDesktopDevice(this.device)) {
            return this.device;
        }
        return undefined;
    }
    getInternalFeatureService() {
        return InternalFeatureService.get();
    }
    isNativeIOS() {
        return this.deps.get(Web_TYPES.IsNativeIOS).execute().getValue();
    }
    get isMobileDevice() {
        return this.deps.get(Web_TYPES.IsMobileDevice).execute().getValue();
    }
    get hideOutboundSubscriptionLinks() {
        return this.isNativeIOS();
    }
    get mobileDevice() {
        return this.device;
    }
    get webOrDesktopDevice() {
        return this.device;
    }
    async checkForSecurityUpdate() {
        return this.protocolUpgradeAvailable();
    }
    performDesktopTextBackup() {
        var _a;
        return (_a = this.desktopManager) === null || _a === void 0 ? void 0 : _a.saveDesktopBackup();
    }
    isGlobalSpellcheckEnabled() {
        return this.deps.get(Web_TYPES.IsGlobalSpellcheckEnabled).execute().getValue();
    }
    getItemTags(item) {
        return this.items.itemsReferencingItem(item).filter((ref) => {
            return ref.content_type === ContentType.TYPES.Tag;
        });
    }
    get version() {
        return this.device.appVersion;
    }
    async toggleGlobalSpellcheck() {
        const currentValue = this.isGlobalSpellcheckEnabled();
        return this.setPreference(PrefKey.EditorSpellcheck, !currentValue);
    }
    async handleMobileEnteringBackgroundEvent() {
        await this.lockApplicationAfterMobileEventIfApplicable();
    }
    async handleMobileGainingFocusEvent() {
        /** Optional override */
    }
    handleInitialMobileScreenshotPrivacy() {
        if (this.platform !== Platform.Android) {
            return;
        }
        if (this.protections.getMobileScreenshotPrivacyEnabled()) {
            this.mobileDevice.setAndroidScreenshotPrivacy(true);
        }
        else {
            this.mobileDevice.setAndroidScreenshotPrivacy(false);
        }
    }
    async handleMobileLosingFocusEvent() {
        if (this.protections.getMobileScreenshotPrivacyEnabled()) {
            this.mobileDevice.stopHidingMobileInterfaceFromScreenshots();
        }
        await this.lockApplicationAfterMobileEventIfApplicable();
    }
    async handleMobileResumingFromBackgroundEvent() {
        if (this.protections.getMobileScreenshotPrivacyEnabled()) {
            this.mobileDevice.hideMobileInterfaceFromScreenshots();
        }
    }
    handleMobileColorSchemeChangeEvent() {
        void this.themeManager.handleMobileColorSchemeChangeEvent();
    }
    handleMobileKeyboardWillChangeFrameEvent(frame) {
        if (frame.contentHeight > 0) {
            setCustomViewportHeight(frame.contentHeight, 'px', true);
        }
        if (frame.isFloatingKeyboard) {
            setCustomViewportHeight(100, 'vh', true);
        }
        this.notifyWebEvent(WebAppEvent.MobileKeyboardWillChangeFrame, frame);
    }
    handleMobileKeyboardDidChangeFrameEvent(frame) {
        this.notifyWebEvent(WebAppEvent.MobileKeyboardDidChangeFrame, frame);
    }
    handleOpenFilePreviewEvent({ id }) {
        const file = this.items.findItem(id);
        if (!file) {
            return;
        }
        this.filesController
            .handleFileAction({
            type: FileItemActionType.PreviewFile,
            payload: {
                file,
            },
        })
            .catch(console.error);
    }
    handleReceivedFileEvent(file) {
        const filesController = this.filesController;
        const blob = getBlobFromBase64(file.data, file.mimeType);
        const mappedFile = new File([blob], file.name, { type: file.mimeType });
        filesController.uploadNewFile(mappedFile).catch(console.error);
    }
    async handleReceivedTextEvent({ text, title }) {
        const titleForNote = title || this.itemListController.titleForNewNote();
        const note = this.items.createTemplateItem(ContentType.TYPES.Note, {
            title: titleForNote,
            text: text,
            references: [],
        });
        const insertedNote = await this.mutator.insertItem(note);
        this.itemListController.selectItem(insertedNote.uuid, true).catch(console.error);
        addToast({
            type: ToastType.Success,
            message: 'Successfully created note from shared text',
        });
    }
    async handleReceivedLinkEvent({ link, title }) {
        const url = new URL(link);
        const paths = url.pathname.split('/');
        const finalPath = paths[paths.length - 1];
        const isImagePath = !!finalPath && /\.(png|svg|webp|jpe?g)/.test(finalPath);
        if (isImagePath) {
            const fetchToastUuid = addToast({
                type: ToastType.Loading,
                message: 'Fetching image from link...',
            });
            try {
                const imgResponse = await fetch(link);
                if (!imgResponse.ok) {
                    throw new Error(`${imgResponse.status}: Could not fetch image`);
                }
                const imgBlob = await imgResponse.blob();
                const file = new File([imgBlob], finalPath, {
                    type: imgBlob.type,
                });
                this.filesController.uploadNewFile(file).catch(console.error);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                dismissToast(fetchToastUuid);
            }
            return;
        }
        this.handleReceivedTextEvent({
            title: title,
            text: link,
        }).catch(console.error);
    }
    async lockApplicationAfterMobileEventIfApplicable() {
        const isLocked = await this.protections.isLocked();
        if (isLocked) {
            return;
        }
        const hasBiometrics = this.protections.hasBiometricsEnabled();
        const hasPasscode = this.hasPasscode();
        const passcodeTiming = this.protections.getMobilePasscodeTiming();
        const biometricsTiming = this.protections.getMobileBiometricsTiming();
        const passcodeLockImmediately = hasPasscode && passcodeTiming === MobileUnlockTiming.Immediately;
        const biometricsLockImmediately = hasBiometrics && biometricsTiming === MobileUnlockTiming.Immediately;
        if (passcodeLockImmediately) {
            await this.lock();
        }
        else if (biometricsLockImmediately) {
            this.protections.softLockBiometrics();
        }
    }
    handleAndroidBackButtonPressed() {
        if (typeof this.androidBackHandler !== 'undefined') {
            this.androidBackHandler.notifyEvent();
        }
    }
    addAndroidBackHandlerEventListener(listener) {
        if (typeof this.androidBackHandler !== 'undefined') {
            return this.androidBackHandler.addEventListener(listener);
        }
        return;
    }
    setAndroidBackHandlerFallbackListener(listener) {
        if (typeof this.androidBackHandler !== 'undefined') {
            this.androidBackHandler.setFallbackListener(listener);
        }
    }
    isAuthorizedToRenderItem(item) {
        if (item.protected && this.hasProtectionSources()) {
            return this.protections.hasUnprotectedAccessSession();
        }
        return true;
    }
    entitledToPerTagPreferences() {
        return this.hasValidFirstPartySubscription();
    }
    get entitledToFiles() {
        return this.featuresController.entitledToFiles;
    }
    showPremiumModal(featureName) {
        void this.featuresController.showPremiumAlert(featureName);
    }
    hasValidFirstPartySubscription() {
        return this.subscriptionController.hasFirstPartyOnlineOrOfflineSubscription();
    }
    async openPurchaseFlow() {
        await this.purchaseFlowController.openPurchaseFlow();
    }
    showAccountMenu() {
        this.accountMenuController.setShow(true);
    }
    hideAccountMenu() {
        this.accountMenuController.setShow(false);
    }
    /**
     * Full U2F clients are only web browser clients. They support adding and removing keys as well as authentication.
     * The desktop and mobile clients cannot support adding keys.
     */
    get isFullU2FClient() {
        return this.environment === Environment.Web;
    }
    openPreferences(pane) {
        this.preferencesController.openPreferences();
        if (pane) {
            this.preferencesController.setCurrentPane(pane);
        }
    }
    generateUUID() {
        return this.options.crypto.generateUUID();
    }
    /**
     * Dependency
     * Accessors
     */
    get routeService() {
        return this.deps.get(Web_TYPES.RouteService);
    }
    get androidBackHandler() {
        return this.deps.get(Web_TYPES.AndroidBackHandler);
    }
    get vaultDisplayService() {
        return this.deps.get(Web_TYPES.VaultDisplayService);
    }
    get desktopManager() {
        return this.deps.get(Web_TYPES.DesktopManager);
    }
    get autolockService() {
        return this.deps.get(Web_TYPES.AutolockService);
    }
    get archiveService() {
        return this.deps.get(Web_TYPES.ArchiveManager);
    }
    get paneController() {
        return this.deps.get(Web_TYPES.PaneController);
    }
    get linkingController() {
        return this.deps.get(Web_TYPES.LinkingController);
    }
    get changelogService() {
        return this.deps.get(Web_TYPES.ChangelogService);
    }
    get pluginsService() {
        return this.deps.get(Web_TYPES.PluginsService);
    }
    get momentsService() {
        return this.deps.get(Web_TYPES.MomentsService);
    }
    get themeManager() {
        return this.deps.get(Web_TYPES.ThemeManager);
    }
    get keyboardService() {
        return this.deps.get(Web_TYPES.KeyboardService);
    }
    get featuresController() {
        return this.deps.get(Web_TYPES.FeaturesController);
    }
    get filesController() {
        return this.deps.get(Web_TYPES.FilesController);
    }
    get filePreviewModalController() {
        return this.deps.get(Web_TYPES.FilePreviewModalController);
    }
    get notesController() {
        return this.deps.get(Web_TYPES.NotesController);
    }
    get importModalController() {
        return this.deps.get(Web_TYPES.ImportModalController);
    }
    get navigationController() {
        return this.deps.get(Web_TYPES.NavigationController);
    }
    get historyModalController() {
        return this.deps.get(Web_TYPES.HistoryModalController);
    }
    get syncStatusController() {
        return this.deps.get(Web_TYPES.SyncStatusController);
    }
    get itemListController() {
        return this.deps.get(Web_TYPES.ItemListController);
    }
    get importer() {
        return this.deps.get(Web_TYPES.Importer);
    }
    get subscriptionController() {
        return this.deps.get(Web_TYPES.SubscriptionController);
    }
    get purchaseFlowController() {
        return this.deps.get(Web_TYPES.PurchaseFlowController);
    }
    get persistence() {
        return this.deps.get(Web_TYPES.PersistenceService);
    }
    get itemControllerGroup() {
        return this.deps.get(Web_TYPES.ItemGroupController);
    }
    get noAccountWarningController() {
        return this.deps.get(Web_TYPES.NoAccountWarningController);
    }
    get searchOptionsController() {
        return this.deps.get(Web_TYPES.SearchOptionsController);
    }
    get openSubscriptionDashboard() {
        return this.deps.get(Web_TYPES.OpenSubscriptionDashboard);
    }
    get mobileWebReceiver() {
        return this.deps.get(Web_TYPES.MobileWebReceiver);
    }
    get accountMenuController() {
        return this.deps.get(Web_TYPES.AccountMenuController);
    }
    get preferencesController() {
        return this.deps.get(Web_TYPES.PreferencesController);
    }
    get isNativeMobileWebUseCase() {
        return this.deps.get(Web_TYPES.IsNativeMobileWeb);
    }
}
