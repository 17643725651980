import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DecoratedInput from '@/Components/Input/DecoratedInput';
import { observer } from 'mobx-react-lite';
import Bullet from './Bullet';
const Verification = ({ activation: act }) => {
    const secretKeyClass = act.verificationStatus === 'invalid-secret' ? 'border-danger' : '';
    const authTokenClass = act.verificationStatus === 'invalid-auth-code' ? 'border-danger' : '';
    return (_jsx("div", { className: "h-33 flex flex-row items-center px-4 py-4", children: _jsxs("div", { className: "flex flex-grow flex-col gap-4", children: [_jsxs("div", { className: "flex flex-row flex-wrap items-center gap-1", children: [_jsxs("div", { className: "text-sm", children: [_jsx(Bullet, { className: "align-middle" }), _jsxs("span", { className: "align-middle", children: ["Enter your ", _jsx("b", { children: "secret key" }), ":"] })] }), _jsx(DecoratedInput, { className: { container: `ml-2 w-full md:w-96 ${secretKeyClass}` }, onChange: act.setInputSecretKey })] }), _jsxs("div", { className: "flex flex-row flex-wrap items-center gap-1", children: [_jsxs("div", { className: "text-sm", children: [_jsx(Bullet, { className: "align-middle" }), _jsxs("span", { className: "align-middle", children: ["Verify the ", _jsx("b", { children: "authentication code" }), " generated by your authenticator app:"] })] }), _jsx(DecoratedInput, { className: { container: `ml-2 w-full md:w-30 ${authTokenClass}` }, onChange: act.setInputOtpToken })] }), act.verificationStatus === 'invalid-auth-code' && (_jsx("div", { className: "flex-grow text-sm text-danger", children: "Incorrect authentication code, please try again." })), act.verificationStatus === 'invalid-secret' && (_jsx("div", { className: "flex-grow text-sm text-danger", children: "Incorrect secret key, please try again." }))] }) }));
};
export default observer(Verification);
