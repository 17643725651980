import { jsx as _jsx } from "react/jsx-runtime";
import { useStore } from '@nanostores/react';
import { toastStore } from './toastStore';
import { ToastTimer } from './ToastTimer';
export const ToastContainer = () => {
    const toasts = useStore(toastStore);
    if (!toasts.length) {
        return null;
    }
    return (_jsx("div", { className: "z-toast fixed bottom-6 right-6 flex w-[calc(100%-3rem)] flex-col items-end md:w-auto", children: toasts.map((toast, index) => (_jsx(ToastTimer, { toast: toast, index: index }, toast.id))) }));
};
