"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeySystemRootKey = exports.isKeySystemRootKey = void 0;
const Item_1 = require("../../Abstract/Item");
const domain_core_1 = require("@standardnotes/domain-core");
function isKeySystemRootKey(x) {
    return x.content_type === domain_core_1.ContentType.TYPES.KeySystemRootKey;
}
exports.isKeySystemRootKey = isKeySystemRootKey;
class KeySystemRootKey extends Item_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.keyParams = payload.content.keyParams;
        this.systemIdentifier = payload.content.systemIdentifier;
        this.key = payload.content.key;
        this.keyVersion = payload.content.keyVersion;
        this.token = payload.content.token;
    }
    strategyWhenConflictingWithItem(item, _previousRevision) {
        const baseKeyTimestamp = this.keyParams.creationTimestamp;
        const incomingKeyTimestamp = item.keyParams.creationTimestamp;
        return incomingKeyTimestamp > baseKeyTimestamp ? Item_1.ConflictStrategy.KeepApply : Item_1.ConflictStrategy.KeepBase;
    }
    get itemsKey() {
        return this.key;
    }
    get key_system_identifier() {
        return undefined;
    }
    get shared_vault_uuid() {
        return undefined;
    }
    isEqual(other) {
        return this.itemsKey === other.itemsKey && this.token === other.token;
    }
}
exports.KeySystemRootKey = KeySystemRootKey;
