"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.classNames = void 0;
const Utils_1 = require("./Utils");
const classNames = (...values) => {
    return values
        .map((value) => (typeof value === 'string' ? value : null))
        .filter(Utils_1.isNotUndefined)
        .join(' ');
};
exports.classNames = classNames;
