import { jsx as _jsx } from "react/jsx-runtime";
import { PaneLayout } from '@/Controllers/PaneController/PaneLayout';
import { mergeRefs } from '@/Hooks/mergeRefs';
import { forwardRef } from 'react';
import { useResponsiveAppPane } from '../Panes/ResponsivePaneProvider';
import { usePaneSwipeGesture } from '../Panes/usePaneGesture';
import NoteGroupView from './NoteGroupView';
const EditorPane = forwardRef(({ application, className, id }, ref) => {
    const { setPaneLayout } = useResponsiveAppPane();
    const [setElement] = usePaneSwipeGesture('right', () => {
        setPaneLayout(PaneLayout.ItemSelection);
    });
    return (_jsx("div", { id: id, ref: mergeRefs([ref, setElement]), className: `flex h-full flex-grow flex-col bg-default pt-safe-top ${className}`, children: _jsx(NoteGroupView, { className: className, application: application }) }));
});
export default EditorPane;
