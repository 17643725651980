import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { STRING_E2E_ENABLED, STRING_ENC_NOT_ENABLED, STRING_LOCAL_ENC_ENABLED } from '@/Constants/Strings';
import { observer } from 'mobx-react-lite';
import { Title, Text } from '../../PreferencesComponents/Content';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import EncryptionEnabled from './EncryptionEnabled';
import { useApplication } from '@/Components/ApplicationProvider';
const Encryption = () => {
    const app = useApplication();
    const hasUser = app.hasAccount();
    const hasPasscode = app.hasPasscode();
    const isEncryptionEnabled = app.isEncryptionAvailable();
    const encryptionStatusString = hasUser
        ? STRING_E2E_ENABLED
        : hasPasscode
            ? STRING_LOCAL_ENC_ENABLED
            : STRING_ENC_NOT_ENABLED;
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Encryption" }), _jsx(Text, { children: encryptionStatusString }), isEncryptionEnabled && _jsx(EncryptionEnabled, {})] }) }));
};
export default observer(Encryption);
