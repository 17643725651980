import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { createContext, useCallback, useContext, useMemo } from 'react';
import PremiumFeaturesModal from '@/Components/PremiumFeaturesModal/PremiumFeaturesModal';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import { classNames } from '@standardnotes/snjs';
import { PremiumFeatureModalType } from '@/Components/PremiumFeaturesModal/PremiumFeatureModalType';
const PremiumModalContext = createContext(null);
const PremiumModalProvider_ = PremiumModalContext.Provider;
export const usePremiumModal = () => {
    const value = useContext(PremiumModalContext);
    if (!value) {
        throw new Error('invalid PremiumModal context');
    }
    return value;
};
const PremiumModalProvider = observer(({ application, children }) => {
    const featureName = application.featuresController.premiumAlertFeatureName || '';
    const hasSubscription = application.hasValidFirstPartySubscription();
    const activate = useCallback((feature) => {
        application.featuresController.showPremiumAlert(feature).catch(console.error);
    }, [application.featuresController]);
    const close = useCallback(() => {
        application.featuresController.closePremiumAlert();
    }, [application.featuresController]);
    const showSuperDemo = useCallback(() => {
        application.featuresController.showSuperDemoModal();
    }, [application.featuresController]);
    const value = useMemo(() => ({ activate, showSuperDemo }), [activate, showSuperDemo]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalOverlay, { isOpen: application.featuresController.premiumAlertType != undefined, close: close, className: classNames('w-full', application.featuresController.premiumAlertType === PremiumFeatureModalType.SuperDemo
                    ? 'md:!h-full md:w-full md:max-w-[70vw]'
                    : '!h-auto max-w-[90vw] md:max-w-89'), backdropClassName: "!opacity-50", children: _jsx(PremiumFeaturesModal, { application: application, featureName: featureName, hasSubscription: hasSubscription, onClose: close, type: application.featuresController.premiumAlertType }) }), _jsx(PremiumModalProvider_, { value: value, children: children })] }));
});
PremiumModalProvider.displayName = 'PremiumModalProvider';
const PremiumModalProviderWithDeallocateHandling = ({ application, children }) => {
    return _jsx(PremiumModalProvider, { application: application, children: children });
};
export default observer(PremiumModalProviderWithDeallocateHandling);
