"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompoundPredicate = void 0;
class CompoundPredicate {
    constructor(operator, predicates) {
        this.operator = operator;
        this.predicates = predicates;
    }
    matchesItem(item) {
        if (this.operator === 'and') {
            for (const subPredicate of this.predicates) {
                if (!subPredicate.matchesItem(item)) {
                    return false;
                }
            }
            return true;
        }
        if (this.operator === 'or') {
            for (const subPredicate of this.predicates) {
                if (subPredicate.matchesItem(item)) {
                    return true;
                }
            }
            return false;
        }
        return false;
    }
    keypathIncludesString(verb) {
        for (const subPredicate of this.predicates) {
            if (subPredicate.keypathIncludesString(verb)) {
                return true;
            }
        }
        return false;
    }
    toJson() {
        return {
            operator: this.operator,
            value: this.predicates.map((predicate) => predicate.toJson()),
        };
    }
}
exports.CompoundPredicate = CompoundPredicate;
