import { GetFeatures } from '@standardnotes/snjs';
import { makeAutoObservable, observable } from 'mobx';
export class PackageProvider {
    static async load() {
        const versionMap = new Map();
        GetFeatures().forEach((feature) => {
            versionMap.set(feature.identifier, 'Latest');
        });
        return new PackageProvider(versionMap);
    }
    constructor(latestVersionsMap) {
        this.latestVersionsMap = latestVersionsMap;
        makeAutoObservable(this, {
            latestVersionsMap: observable.ref,
        });
    }
    getVersion(extension) {
        return this.latestVersionsMap.get(extension.package_info.identifier);
    }
}
