import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { CollectionSort } from '@standardnotes/snjs';
const ListItemMetadata = ({ item, hideDate, sortBy }) => {
    const showModifiedDate = sortBy === CollectionSort.UpdatedAt;
    if (hideDate && !item.protected) {
        return null;
    }
    return (_jsxs("div", { className: "leading-1.4 mt-1 text-sm opacity-50 lg:text-xs", children: [item.protected && _jsxs("span", { children: ["Protected ", hideDate ? '' : ' • '] }), !hideDate && showModifiedDate && _jsxs("span", { children: ["Modified ", item.updatedAtString || 'Now'] }), !hideDate && !showModifiedDate && _jsx("span", { children: item.createdAtString || 'Now' })] }));
};
export default ListItemMetadata;
