"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNActionsExtension = void 0;
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const ConflictStrategy_1 = require("../../Abstract/Item/Types/ConflictStrategy");
/**
 * Related to the SNActionsService and the local Action model.
 */
class SNActionsExtension extends DecryptedItem_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.actions = [];
        this.name = payload.content.name || '';
        this.description = payload.content.description || '';
        this.url = payload.content.hosted_url || payload.content.url;
        this.supported_types = payload.content.supported_types;
        this.package_info = this.payload.content.package_info || {};
        this.deprecation = payload.content.deprecation;
        this.actions = payload.content.actions;
    }
    get displayName() {
        return this.name;
    }
    get thirdPartyPackageInfo() {
        return this.package_info;
    }
    get isListedExtension() {
        return this.package_info.identifier === 'org.standardnotes.listed';
    }
    actionsWithContextForItem(item) {
        return this.actions.filter((action) => {
            return action.context === item.content_type || action.context === 'Item';
        });
    }
    /** Do not duplicate. Always keep original */
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return ConflictStrategy_1.ConflictStrategy.KeepBase;
    }
}
exports.SNActionsExtension = SNActionsExtension;
