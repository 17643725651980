"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaRemoteUuidConflicts = void 0;
const utils_1 = require("@standardnotes/utils");
const ImmutablePayloadCollection_1 = require("../Collection/Payload/ImmutablePayloadCollection");
const PayloadsByAlternatingUuid_1 = require("../../Utilities/Payload/PayloadsByAlternatingUuid");
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
const Payload_1 = require("../../Abstract/Payload");
/**
 * UUID conflicts can occur if a user attmpts to import an old data
 * backup with uuids from the old account into a new account.
 * In uuid_conflict, we receive the value we attmpted to save.
 */
class DeltaRemoteUuidConflicts {
    constructor(baseCollection, conflicts) {
        this.baseCollection = baseCollection;
        this.conflicts = conflicts;
    }
    result() {
        const results = [];
        const baseCollectionCopy = this.baseCollection.mutableCopy();
        for (const conflict of this.conflicts) {
            /**
             * The payload in question may have been modified as part of alternating a uuid for
             * another item. For example, alternating a uuid for a note will also affect the
             * referencing tag, which would be added to `results`, but could also be inside
             * of this.applyCollection. In this case we'd prefer the most recently modified value.
             */
            const moreRecent = results.find((r) => r.uuid === conflict.unsaved_item.uuid);
            const useApply = moreRecent || conflict.unsaved_item;
            if (!(0, TypeCheck_1.isDecryptedPayload)(useApply)) {
                continue;
            }
            const alternateResults = (0, PayloadsByAlternatingUuid_1.PayloadsByAlternatingUuid)(useApply, ImmutablePayloadCollection_1.ImmutablePayloadCollection.FromCollection(baseCollectionCopy));
            baseCollectionCopy.set(alternateResults);
            (0, utils_1.filterFromArray)(results, (r) => (0, utils_1.Uuids)(alternateResults).includes(r.uuid));
            (0, utils_1.extendArray)(results, alternateResults);
        }
        return {
            emits: results,
            source: Payload_1.PayloadEmitSource.RemoteRetrieved,
        };
    }
}
exports.DeltaRemoteUuidConflicts = DeltaRemoteUuidConflicts;
