import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Subtitle, Title, Text } from '@/Components/Preferences/PreferencesComponents/Content';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { useMemo } from 'react';
import TextBackupsDesktop from './TextBackupsDesktop';
const TextBackupsCrossPlatform = ({ application }) => {
    const fileBackupsService = useMemo(() => application.fileBackups, [application]);
    return fileBackupsService ? (_jsx(TextBackupsDesktop, { backupsService: fileBackupsService })) : (_jsx(_Fragment, { children: _jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Automatic text backups" }), _jsx(Subtitle, { children: "Automatically save encrypted and decrypted backups of your note and tag data." }), _jsx(Text, { className: "mt-3", children: "To enable text backups, use the Standard Notes desktop application." })] }) }) }));
};
export default TextBackupsCrossPlatform;
