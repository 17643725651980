"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurePayload = void 0;
const utils_1 = require("@standardnotes/utils");
const PayloadSource_1 = require("../Types/PayloadSource");
const ContentTypeUsesRootKeyEncryption_1 = require("../../../Runtime/Encryption/ContentTypeUsesRootKeyEncryption");
class PurePayload {
    constructor(rawPayload, source = PayloadSource_1.PayloadSource.Constructor) {
        var _a, _b, _c, _d;
        if (!rawPayload.uuid) {
            throw Error(`Attempting to construct payload with null uuid
        Content type: ${rawPayload.content_type}`);
        }
        if (rawPayload.key_system_identifier && (0, ContentTypeUsesRootKeyEncryption_1.ContentTypeUsesRootKeyEncryption)(rawPayload.content_type)) {
            throw new Error('Rootkey-encrypted payload should not have a key system identifier');
        }
        this.source = source;
        this.uuid = rawPayload.uuid;
        this.content = rawPayload.content;
        this.content_type = rawPayload.content_type;
        this.deleted = (0, utils_1.useBoolean)(rawPayload.deleted, false);
        this.dirty = rawPayload.dirty;
        this.duplicate_of = rawPayload.duplicate_of;
        this.updated_at = new Date(rawPayload.updated_at || 0);
        this.updated_at_timestamp = rawPayload.updated_at_timestamp || 0;
        if (this.updated_at_timestamp < 0) {
            this.updated_at_timestamp = 0;
            this.updated_at = new Date(0);
        }
        this.created_at = new Date(rawPayload.created_at || new Date());
        this.created_at_timestamp = rawPayload.created_at_timestamp || 0;
        if (this.created_at_timestamp < 0) {
            this.created_at_timestamp = this.updated_at_timestamp;
            this.created_at = this.updated_at;
        }
        this.lastSyncBegan = rawPayload.lastSyncBegan ? new Date(rawPayload.lastSyncBegan) : undefined;
        this.lastSyncEnd = rawPayload.lastSyncEnd ? new Date(rawPayload.lastSyncEnd) : undefined;
        this.dirtyIndex = rawPayload.dirtyIndex;
        this.globalDirtyIndexAtLastSync = rawPayload.globalDirtyIndexAtLastSync;
        this.user_uuid = (_a = rawPayload.user_uuid) !== null && _a !== void 0 ? _a : undefined;
        this.key_system_identifier = (_b = rawPayload.key_system_identifier) !== null && _b !== void 0 ? _b : undefined;
        this.shared_vault_uuid = (_c = rawPayload.shared_vault_uuid) !== null && _c !== void 0 ? _c : undefined;
        this.last_edited_by_uuid = (_d = rawPayload.last_edited_by_uuid) !== null && _d !== void 0 ? _d : undefined;
        this.signatureData = rawPayload.signatureData;
        const timeToAllowSubclassesToFinishConstruction = 0;
        setTimeout(() => {
            (0, utils_1.deepFreeze)(this);
        }, timeToAllowSubclassesToFinishConstruction);
    }
    ejected() {
        const comprehensive = {
            uuid: this.uuid,
            content: this.content,
            deleted: this.deleted,
            content_type: this.content_type,
            created_at: this.created_at,
            updated_at: this.updated_at,
            created_at_timestamp: this.created_at_timestamp,
            updated_at_timestamp: this.updated_at_timestamp,
            dirty: this.dirty,
            duplicate_of: this.duplicate_of,
            dirtyIndex: this.dirtyIndex,
            globalDirtyIndexAtLastSync: this.globalDirtyIndexAtLastSync,
            lastSyncBegan: this.lastSyncBegan,
            lastSyncEnd: this.lastSyncEnd,
            key_system_identifier: this.key_system_identifier,
            user_uuid: this.user_uuid,
            shared_vault_uuid: this.shared_vault_uuid,
            last_edited_by_uuid: this.last_edited_by_uuid,
            signatureData: this.signatureData,
        };
        return comprehensive;
    }
    get serverUpdatedAt() {
        return this.updated_at;
    }
    get serverUpdatedAtTimestamp() {
        return this.updated_at_timestamp;
    }
}
exports.PurePayload = PurePayload;
