import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../Modal/Modal';
import ModalOverlay from '../Modal/ModalOverlay';
import { TOGGLE_KEYBOARD_SHORTCUTS_MODAL, } from '@standardnotes/ui-services';
import { observer } from 'mobx-react-lite';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator/KeyboardShortcutIndicator';
const createGroupedItems = (items) => {
    const groupedItems = {
        'Current note': [],
        Formatting: [],
        'Super notes': [],
        'Notes list': [],
        General: [],
    };
    return items.reduce((acc, item) => {
        acc[item.category].push(item);
        return acc;
    }, groupedItems);
};
const Item = ({ item }) => {
    return (_jsxs("div", { className: "flex items-center gap-2 px-4 py-2.5", children: [_jsx("div", { children: item.description }), _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: item, small: false, dimmed: false })] }));
};
const KeyboardShortcutsModal = ({ keyboardService }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState(() => createGroupedItems(keyboardService.getRegisteredKeyboardShorcutHelpItems()));
    const close = useCallback(() => {
        setIsOpen(false);
    }, []);
    useEffect(() => {
        return keyboardService.addCommandHandler({
            command: TOGGLE_KEYBOARD_SHORTCUTS_MODAL,
            category: 'General',
            description: 'Toggle keyboard shortcuts help',
            onKeyDown: () => {
                setItems(createGroupedItems(keyboardService.getRegisteredKeyboardShorcutHelpItems()));
                setIsOpen((open) => !open);
            },
        });
    }, [keyboardService]);
    const actions = useMemo(() => [
        {
            label: 'Cancel',
            onClick: close,
            type: 'cancel',
            mobileSlot: 'left',
        },
    ], [close]);
    return (_jsx(ModalOverlay, { isOpen: isOpen, close: close, children: _jsx(Modal, { title: "Keyboard shortcuts", close: close, actions: actions, children: Object.entries(items).map(([category, items]) => items.length > 0 && (_jsxs("div", { children: [_jsx("div", { className: "p-4 pb-0.5 pt-4 text-base font-semibold capitalize", children: category }), items.map((item, index) => (_jsx(Item, { item: item }, index)))] }, category))) }) }));
};
export default observer(KeyboardShortcutsModal);
