"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemViewId = void 0;
var SystemViewId;
(function (SystemViewId) {
    SystemViewId["AllNotes"] = "all-notes";
    SystemViewId["Files"] = "files";
    SystemViewId["ArchivedNotes"] = "archived-notes";
    SystemViewId["TrashedNotes"] = "trashed-notes";
    SystemViewId["UntaggedNotes"] = "untagged-notes";
    SystemViewId["StarredNotes"] = "starred-notes";
    SystemViewId["Conflicts"] = "conflicts";
})(SystemViewId || (exports.SystemViewId = SystemViewId = {}));
