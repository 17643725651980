import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import DecoratedInput from './DecoratedInput';
import IconButton from '@/Components/Button/IconButton';
const Toggle = ({ isToggled, setIsToggled }) => (_jsx(IconButton, { className: "h-5 w-5 justify-center rounded-full p-0 text-neutral hover:bg-passive-4", icon: isToggled ? 'eye-off' : 'eye', iconClassName: "w-3.5 h-3.5", title: "Show/hide password", onClick: () => setIsToggled((isToggled) => !isToggled), focusable: true }));
/**
 * Password input that has a toggle to show/hide password and can be decorated on the left and right side
 */
const DecoratedPasswordInput = forwardRef((props, ref) => {
    const [isToggled, setIsToggled] = useState(false);
    const rightSideDecorations = props.right ? [...props.right] : [];
    return (_jsx(DecoratedInput, { ...props, ref: ref, type: isToggled ? 'text' : 'password', right: [...rightSideDecorations, _jsx(Toggle, { isToggled: isToggled, setIsToggled: setIsToggled })] }));
});
export default DecoratedPasswordInput;
