import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Button from '@/Components/Button/Button';
import ModalDialogButtons from '../Modal/ModalDialogButtons';
import Modal from '../Modal/Modal';
const PermissionsModal = ({ callback, component, dismiss, permissionsString }) => {
    const accept = useCallback(() => {
        callback(true);
        dismiss();
    }, [callback, dismiss]);
    const deny = useCallback(() => {
        callback(false);
        dismiss();
    }, [callback, dismiss]);
    return (_jsx(Modal, { title: "Activate Plugin", close: deny, actions: [
            { label: 'Cancel', onClick: deny, type: 'cancel', mobileSlot: 'left' },
            {
                label: 'Continue',
                onClick: accept,
                type: 'primary',
                mobileSlot: 'right',
            },
        ], customFooter: _jsx(ModalDialogButtons, { className: "hidden md:flex", children: _jsx(Button, { primary: true, fullWidth: true, onClick: accept, className: "block", children: "Continue" }) }), children: _jsxs("div", { className: "px-4 py-4", children: [_jsxs("div", { className: "text-base", children: [_jsx("strong", { children: component.displayName }), ' would like to interact with your ', permissionsString] }), _jsx("div", { className: "sk-panel-row [word-break:break-word]", children: _jsx("p", { className: "sk-p", children: "Plugins use an offline messaging system to communicate and can only access the current note." }) })] }) }));
};
export default PermissionsModal;
