import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useResponsiveAppPane } from '../../Panes/ResponsivePaneProvider';
import { AppPaneId } from '../../Panes/AppPaneMetadata';
import { createDailyItemsWithToday, createItemsByDateMapping, insertBlanks, templateEntryForDate, } from './CreateDailySections';
import { DailyItemCell } from './DailyItemCell';
import { pluralize } from '@standardnotes/snjs';
import { dateToDailyDayIdentifier, getDailyWritingStreak } from './Utils';
import InfiniteCalendar from '../Calendar/InfiniteCalendar';
import { InfinteScroller } from '../InfiniteScroller/InfiniteScroller';
import { LoggingDomain, log } from '@/Logging';
import { isMobileScreen } from '@/Utils';
const PageSize = 10;
const DailyContentList = ({ items, itemListController, onSelect, selectedUuids, selectedTag, }) => {
    var _a;
    const { toggleAppPane } = useResponsiveAppPane();
    const [needsSelectionReload, setNeedsSelectionReload] = useState(false);
    const [todayItem, setTodayItem] = useState();
    const [selectedDay, setSelectedDay] = useState();
    const calendarRef = useRef(null);
    const [lastVisibleDay, setLastVisibleDay] = useState();
    const scrollerRef = useRef(null);
    const [dailyItems, setDailyItems] = useState(() => {
        return createDailyItemsWithToday(PageSize);
    });
    const { hideTags, hideDate, hideNotePreview } = itemListController.webDisplayOptions;
    const itemsByDateMapping = useMemo(() => {
        return createItemsByDateMapping(items);
    }, [items]);
    const currentStreak = useMemo(() => getDailyWritingStreak(todayItem, itemsByDateMapping), [todayItem, itemsByDateMapping]);
    useEffect(() => {
        setTodayItem(dailyItems.find((item) => item.isToday));
    }, [dailyItems]);
    useLayoutEffect(() => {
        var _a;
        if (todayItem && scrollerRef.current) {
            (_a = scrollerRef.current) === null || _a === void 0 ? void 0 : _a.scrollToElementId(todayItem.id);
        }
    }, [todayItem, scrollerRef]);
    const calendarActivities = useMemo(() => {
        return items.map((item) => {
            return {
                date: item.created_at,
                item: item,
            };
        });
    }, [items]);
    const paginateBottom = useCallback(() => {
        log(LoggingDomain.DailyNotes, '[ContentList] paginateBottom');
        setDailyItems((prev) => {
            const copy = prev.slice();
            insertBlanks(copy, 'end', PageSize);
            return copy;
        });
    }, [setDailyItems]);
    const paginateTop = useCallback(() => {
        log(LoggingDomain.DailyNotes, '[ContentList] paginateTop');
        setDailyItems((prev) => {
            const copy = prev.slice();
            insertBlanks(copy, 'front', PageSize);
            return copy;
        });
    }, [setDailyItems]);
    const onListItemDidBecomeVisible = useCallback((elementId) => {
        var _a;
        const dailyItem = dailyItems.find((candidate) => candidate.id === elementId);
        if (dailyItem && dailyItem !== lastVisibleDay) {
            setLastVisibleDay(dailyItem);
            log(LoggingDomain.DailyNotes, '[ContentList] Item did become visible for date', dailyItem.date);
            (_a = calendarRef === null || calendarRef === void 0 ? void 0 : calendarRef.current) === null || _a === void 0 ? void 0 : _a.goToMonth(dailyItem.date);
        }
        else {
            log(LoggingDomain.DailyNotes, '[ContentList] Ignoring duplicate day visibility');
        }
    }, [dailyItems, lastVisibleDay]);
    const onClickItem = useCallback(async (day, item, userTriggered) => {
        await onSelect(item, userTriggered);
        toggleAppPane(AppPaneId.Editor);
        setSelectedDay(day.date);
    }, [onSelect, toggleAppPane]);
    const onClickTemplate = useCallback((date) => {
        setSelectedDay(date);
        void itemListController.createNewNote(undefined, date, 'editor');
        toggleAppPane(AppPaneId.Editor);
    }, [setSelectedDay, itemListController, toggleAppPane]);
    const dailyItemForDate = useCallback((date) => {
        return dailyItems.find((candidate) => dateToDailyDayIdentifier(date) === candidate.dateKey);
    }, [dailyItems]);
    useEffect(() => {
        if (needsSelectionReload) {
            setNeedsSelectionReload(false);
            if (!todayItem) {
                return;
            }
            const items = itemsByDateMapping[todayItem.id];
            if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
                const item = items[0];
                const dailyItem = dailyItemForDate(item.created_at);
                if (dailyItem) {
                    void onClickItem(dailyItem, items[0], false);
                }
            }
            else {
                onClickTemplate(todayItem.date);
                const itemElement = document.getElementById(todayItem.id);
                itemElement === null || itemElement === void 0 ? void 0 : itemElement.scrollIntoView({ behavior: 'auto' });
            }
        }
    }, [needsSelectionReload, onClickItem, onClickTemplate, todayItem, dailyItemForDate, itemsByDateMapping]);
    useEffect(() => {
        setNeedsSelectionReload(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTag.uuid]);
    const onCalendarSelect = useCallback((date) => {
        const dailyItem = templateEntryForDate(date);
        const items = itemsByDateMapping[dailyItem.id];
        if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
            void onClickItem(dailyItem, items[0], false);
        }
        else if (dailyItem) {
            void onClickTemplate(dailyItem.date);
        }
    }, [onClickItem, onClickTemplate, itemsByDateMapping]);
    const hasItemsOnSelectedDay = selectedDay && ((_a = itemsByDateMapping[dateToDailyDayIdentifier(selectedDay)]) === null || _a === void 0 ? void 0 : _a.length) > 0;
    return (_jsxs(_Fragment, { children: [_jsx(InfiniteCalendar, { activities: calendarActivities, activityType: 'created', onDateSelect: onCalendarSelect, selectedDay: selectedDay, selectedDayType: !selectedDay ? undefined : hasItemsOnSelectedDay ? 'item' : 'template', ref: calendarRef, className: 'flex-column flex', children: currentStreak > 0 && (_jsxs("div", { className: "flex w-full items-center justify-center border-t border-solid border-border bg-secondary-background p-2", children: [_jsx("span", { className: "opacity-50", children: "Current Streak" }), _jsxs("span", { className: "ml-1.5 font-bold", children: [currentStreak, " ", pluralize(currentStreak, 'Day', 'Days')] })] })) }), _jsx(InfinteScroller, { paginateFront: paginateTop, paginateEnd: paginateBottom, direction: "vertical", onElementVisibility: onListItemDidBecomeVisible, className: 'flex-1', ref: scrollerRef, isMobileScreen: isMobileScreen(), children: dailyItems.map((dailyItem) => {
                    const items = itemsByDateMapping[dailyItem.id];
                    if (items) {
                        return items.map((item) => (_jsx(DailyItemCell, { selected: selectedUuids.has(item.uuid), section: dailyItem, id: dailyItem.id, item: item, hideDate: hideDate, hidePreview: hideNotePreview, hideTags: hideTags, onClick: () => onClickItem(dailyItem, item, true) }, item.uuid)));
                    }
                    else {
                        return (_jsx(DailyItemCell, { selected: selectedDay && dailyItem.id === dateToDailyDayIdentifier(selectedDay), section: dailyItem, id: dailyItem.id, onClick: () => onClickTemplate(dailyItem.date) }, dailyItem.dateKey));
                    }
                }) })] }));
};
export default DailyContentList;
