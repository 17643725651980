import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { UpgradePrompt } from '../PremiumFeaturesModal/Subviews/UpgradePrompt';
import { useApplication } from '../ApplicationProvider';
export const PreferencesPremiumOverlay = () => {
    const ctaButtonRef = useRef(null);
    const application = useApplication();
    const hasSubscription = application.hasValidFirstPartySubscription();
    const onClick = () => {
        application.preferencesController.closePreferences();
    };
    return (_jsxs("div", { className: "absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center", children: [_jsx("div", { className: "absolute h-full w-full bg-default opacity-[86%]" }), _jsx("div", { className: "border-1 z-10 rounded border border-border bg-default p-5", children: _jsx(UpgradePrompt, { featureName: 'Plugin Gallery', ctaRef: ctaButtonRef, application: application, hasSubscription: hasSubscription, inline: true, onClick: onClick }) })] }));
};
export default observer(PreferencesPremiumOverlay);
