"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Platform = void 0;
var Platform;
(function (Platform) {
    Platform[Platform["Ios"] = 1] = "Ios";
    Platform[Platform["Android"] = 2] = "Android";
    Platform[Platform["MacWeb"] = 3] = "MacWeb";
    Platform[Platform["MacDesktop"] = 4] = "MacDesktop";
    Platform[Platform["WindowsWeb"] = 5] = "WindowsWeb";
    Platform[Platform["WindowsDesktop"] = 6] = "WindowsDesktop";
    Platform[Platform["LinuxWeb"] = 7] = "LinuxWeb";
    Platform[Platform["LinuxDesktop"] = 8] = "LinuxDesktop";
})(Platform || (exports.Platform = Platform = {}));
