"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FillIframeEditorDefaults = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const ComponentAction_1 = require("../../Component/ComponentAction");
const ComponentArea_1 = require("../../Component/ComponentArea");
function FillIframeEditorDefaults(component) {
    if (!component.index_path) {
        component.index_path = 'dist/index.html';
    }
    if (!component.component_permissions) {
        component.component_permissions = [
            {
                name: ComponentAction_1.ComponentAction.StreamContextItem,
                content_types: [domain_core_1.ContentType.TYPES.Note],
            },
        ];
    }
    component.content_type = domain_core_1.ContentType.TYPES.Component;
    if (!component.area) {
        component.area = ComponentArea_1.ComponentArea.Editor;
    }
    if (component.interchangeable == undefined) {
        component.interchangeable = true;
    }
    return component;
}
exports.FillIframeEditorDefaults = FillIframeEditorDefaults;
