import { addToast, dismissToast, ToastType } from '@standardnotes/toast';
import { ApplicationEvent, ProtectionEvent, StorageKey, } from '@standardnotes/services';
import { isDev } from '@/Utils';
import { PrefKey, sleep } from '@standardnotes/snjs';
import { action, makeObservable, observable } from 'mobx';
import { AbstractViewController } from '@/Controllers/Abstract/AbstractViewController';
import { dateToStringStyle1 } from '@/Utils/DateUtils';
import { PhotoRecorder } from './PhotoRecorder';
const EVERY_HOUR = 1000 * 60 * 60;
const EVERY_TEN_SECONDS = 1000 * 10;
const DEBUG_MODE = isDev && false;
const DELAY_AFTER_STARTING_CAMERA_TO_ALLOW_MOBILE_AUTOFOCUS = 2000;
export class MomentsService extends AbstractViewController {
    constructor(filesController, linkingController, storage, preferences, items, protections, desktopDevice, _isMobileDevice, eventBus) {
        super(eventBus);
        this.filesController = filesController;
        this.linkingController = linkingController;
        this.storage = storage;
        this.preferences = preferences;
        this.items = items;
        this.protections = protections;
        this.desktopDevice = desktopDevice;
        this._isMobileDevice = _isMobileDevice;
        this.isEnabled = false;
        this.enableMoments = () => {
            this.storage.setValue(StorageKey.MomentsEnabled, true);
            this.isEnabled = true;
            void this.beginTakingPhotos();
        };
        this.disableMoments = () => {
            this.storage.setValue(StorageKey.MomentsEnabled, false);
            this.isEnabled = false;
            clearInterval(this.intervalReference);
        };
        this.takePhoto = async () => {
            const isAppLocked = await this.protections.isLocked();
            if (isAppLocked) {
                return;
            }
            const isAppInForeground = document.visibilityState === 'visible';
            let toastId;
            if (isAppInForeground) {
                toastId = addToast({
                    type: ToastType.Regular,
                    message: 'Capturing Moment...',
                    pauseOnWindowBlur: false,
                });
            }
            if (this.desktopDevice) {
                const granted = await this.desktopDevice.askForMediaAccess('camera');
                if (!granted) {
                    if (toastId) {
                        dismissToast(toastId);
                    }
                    addToast({
                        type: ToastType.Error,
                        message: 'Please enable Camera permissions for Standard Notes to enable Moments.',
                        duration: 3000,
                    });
                    return;
                }
            }
            const filename = `Moment ${dateToStringStyle1(new Date())}.png`;
            const camera = new PhotoRecorder();
            await camera.initialize();
            if (this._isMobileDevice.execute().getValue()) {
                await sleep(DELAY_AFTER_STARTING_CAMERA_TO_ALLOW_MOBILE_AUTOFOCUS);
            }
            let file = await camera.takePhoto(filename);
            if (!file) {
                await sleep(1000);
                file = await camera.takePhoto(filename);
                if (!file) {
                    return undefined;
                }
            }
            if (toastId) {
                dismissToast(toastId);
            }
            const uploadedFile = await this.filesController.uploadNewFile(file);
            if (uploadedFile) {
                if (isAppInForeground) {
                    void this.linkingController.linkItemToSelectedItem(uploadedFile);
                }
                const defaultTag = this.getDefaultTag();
                if (defaultTag) {
                    void this.linkingController.linkItems(uploadedFile, defaultTag);
                }
            }
            camera.finish();
            return uploadedFile;
        };
        makeObservable(this, {
            isEnabled: observable,
            enableMoments: action,
            disableMoments: action,
        });
        eventBus.addEventHandler(this, ApplicationEvent.LocalDataLoaded);
        eventBus.addEventHandler(this, ProtectionEvent.BiometricsSoftLockEngaged);
        eventBus.addEventHandler(this, ProtectionEvent.BiometricsSoftLockDisengaged);
    }
    async handleEvent(event) {
        var _a;
        switch (event.type) {
            case ApplicationEvent.LocalDataLoaded: {
                this.isEnabled = (_a = this.storage.getValue(StorageKey.MomentsEnabled)) !== null && _a !== void 0 ? _a : false;
                if (this.isEnabled) {
                    void this.beginTakingPhotos();
                }
                break;
            }
            case ProtectionEvent.BiometricsSoftLockEngaged: {
                this.pauseMoments();
                break;
            }
            case ProtectionEvent.BiometricsSoftLockDisengaged: {
                this.resumeMoments();
                break;
            }
        }
    }
    deinit() {
        super.deinit();
        this.filesController = undefined;
    }
    pauseMoments() {
        clearInterval(this.intervalReference);
    }
    resumeMoments() {
        if (!this.isEnabled) {
            return;
        }
        void this.beginTakingPhotos();
    }
    beginTakingPhotos() {
        if (this.intervalReference) {
            clearInterval(this.intervalReference);
        }
        void this.takePhoto();
        this.intervalReference = setInterval(() => {
            void this.takePhoto();
        }, DEBUG_MODE ? EVERY_TEN_SECONDS : EVERY_HOUR);
    }
    getDefaultTag() {
        const defaultTagId = this.preferences.getValue(PrefKey.MomentsDefaultTagUuid);
        if (defaultTagId) {
            return this.items.findItem(defaultTagId);
        }
    }
}
