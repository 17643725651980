import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pill, Subtitle, Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import Button from '@/Components/Button/Button';
import Switch from '@/Components/Switch/Switch';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import ItemSelectionDropdown from '@/Components/ItemSelectionDropdown/ItemSelectionDropdown';
import { ContentType, PrefKey } from '@standardnotes/snjs';
import usePreference from '@/Hooks/usePreference';
import LinkedItemBubble from '@/Components/LinkedItems/LinkedItemBubble';
import { createLinkFromItem } from '@/Utils/Items/Search/createLinkFromItem';
const Moments = ({ application }) => {
    const momentsEnabled = application.momentsService.isEnabled;
    const premiumModal = usePremiumModal();
    const defaultTagId = usePreference(PrefKey.MomentsDefaultTagUuid);
    const [defaultTag, setDefaultTag] = useState();
    useEffect(() => {
        if (!defaultTagId) {
            setDefaultTag(undefined);
            return;
        }
        const tag = application.items.findItem(defaultTagId);
        setDefaultTag(tag);
    }, [defaultTagId, application]);
    const enable = useCallback(() => {
        if (!application.featuresController.entitledToFiles) {
            premiumModal.activate('Moments');
            return;
        }
        void application.momentsService.enableMoments();
    }, [application, premiumModal]);
    const disable = useCallback(() => {
        void application.momentsService.disableMoments();
    }, [application]);
    const toggle = useCallback(() => {
        if (momentsEnabled) {
            disable();
        }
        else {
            enable();
        }
    }, [momentsEnabled, enable, disable]);
    const takePhoto = useCallback(() => {
        if (!application.featuresController.entitledToFiles) {
            premiumModal.activate('Moments');
            return;
        }
        void application.momentsService.takePhoto();
    }, [application, premiumModal]);
    const selectTag = useCallback((tag) => {
        void application.setPreference(PrefKey.MomentsDefaultTagUuid, tag.uuid);
    }, [application]);
    const unselectTag = useCallback(async () => {
        void application.setPreference(PrefKey.MomentsDefaultTagUuid, undefined);
    }, [application]);
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-start", children: [_jsx(Title, { children: "Moments" }), _jsx(Pill, { style: 'success', children: "Labs" }), _jsx(Pill, { style: 'info', children: "Professional" })] }), _jsx(Switch, { onChange: toggle, checked: momentsEnabled })] }), _jsx(Subtitle, { children: "Your personal photo journal" }), momentsEnabled && (_jsxs("div", { className: "mb-2 flex items-center", children: [defaultTag && (_jsx("div", { children: _jsx(LinkedItemBubble, { className: "m-1 mr-2", link: createLinkFromItem(defaultTag, 'linked'), unlinkItem: unselectTag, isBidirectional: false, inlineFlex: true }) })), _jsx(ItemSelectionDropdown, { onSelection: selectTag, placeholder: "Select tag to save Moments to...", contentTypes: [ContentType.TYPES.Tag] })] })), _jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { className: "flex flex-col" }), _jsxs(PreferencesSegment, { children: [_jsx(Text, { children: "Moments lets you capture photos of yourself throughout the day, creating a visual record of your life, one photo at a time. Using your webcam or mobile selfie-cam, Moments takes a photo of you every half hour. All photos are end-to-end encrypted and stored in your files. Enable Moments on a per-device basis to get started." }), _jsx("div", { className: "mt-5 flex flex-row flex-wrap gap-3", children: _jsx(Button, { colorStyle: "info", onClick: takePhoto, children: "Capture Present Moment" }) })] })] })] }) }));
};
export default observer(Moments);
