import { PremiumFeatureModalType } from '@/Components/PremiumFeaturesModal/PremiumFeatureModalType';
import { destroyAllObjectProperties } from '@/Utils';
import { ApplicationEvent, NativeFeatureIdentifier, FeatureStatus, RoleName, } from '@standardnotes/snjs';
import { action, makeObservable, observable, runInAction, when } from 'mobx';
import { AbstractViewController } from './Abstract/AbstractViewController';
import { CrossControllerEvent } from './CrossControllerEvent';
import { featureTrunkVaultsEnabled } from '@/FeatureTrunk';
export class FeaturesController extends AbstractViewController {
    deinit() {
        super.deinit();
        this.showPremiumAlert = undefined;
        this.closePremiumAlert = undefined;
        this.hasFolders = undefined;
        this.hasSmartViews = undefined;
        this.entitledToFiles = undefined;
        this.premiumAlertFeatureName = undefined;
        this.premiumAlertType = undefined;
        destroyAllObjectProperties(this);
    }
    constructor(features, eventBus) {
        super(eventBus);
        this.features = features;
        this.premiumAlertType = undefined;
        this.showPurchaseSuccessAlert = () => {
            this.premiumAlertType = PremiumFeatureModalType.UpgradeSuccess;
        };
        this.showSuperDemoModal = () => {
            this.premiumAlertType = PremiumFeatureModalType.SuperDemo;
        };
        this.hasFolders = this.isEntitledToFolders();
        this.hasSmartViews = this.isEntitledToSmartViews();
        this.entitledToFiles = this.isEntitledToFiles();
        this.premiumAlertFeatureName = undefined;
        makeObservable(this, {
            hasFolders: observable,
            hasSmartViews: observable,
            entitledToFiles: observable,
            premiumAlertType: observable,
            premiumAlertFeatureName: observable,
            showPremiumAlert: action,
            closePremiumAlert: action,
            showPurchaseSuccessAlert: action,
        });
        eventBus.addEventHandler(this, CrossControllerEvent.DisplayPremiumModal);
        eventBus.addEventHandler(this, ApplicationEvent.DidPurchaseSubscription);
        eventBus.addEventHandler(this, ApplicationEvent.FeaturesAvailabilityChanged);
        eventBus.addEventHandler(this, ApplicationEvent.Launched);
        eventBus.addEventHandler(this, ApplicationEvent.LocalDataLoaded);
        eventBus.addEventHandler(this, ApplicationEvent.UserRolesChanged);
        this.showPremiumAlert = this.showPremiumAlert.bind(this);
        this.closePremiumAlert = this.closePremiumAlert.bind(this);
    }
    async handleEvent(event) {
        switch (event.type) {
            case ApplicationEvent.DidPurchaseSubscription:
                this.showPurchaseSuccessAlert();
                break;
            case ApplicationEvent.FeaturesAvailabilityChanged:
            case ApplicationEvent.Launched:
            case ApplicationEvent.LocalDataLoaded:
            case ApplicationEvent.UserRolesChanged:
                runInAction(() => {
                    this.hasFolders = this.isEntitledToFolders();
                    this.hasSmartViews = this.isEntitledToSmartViews();
                    this.entitledToFiles = this.isEntitledToFiles();
                });
                break;
            case CrossControllerEvent.DisplayPremiumModal:
                {
                    const payload = event.payload;
                    void this.showPremiumAlert(payload.featureName);
                }
                break;
        }
    }
    async showPremiumAlert(featureName) {
        this.premiumAlertFeatureName = featureName;
        this.premiumAlertType = PremiumFeatureModalType.UpgradePrompt;
        return when(() => this.premiumAlertType === undefined);
    }
    closePremiumAlert() {
        this.premiumAlertType = undefined;
    }
    isEntitledToFiles() {
        const status = this.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.Files).getValue());
        return status === FeatureStatus.Entitled;
    }
    isEntitledToFolders() {
        const status = this.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.TagNesting).getValue());
        return status === FeatureStatus.Entitled;
    }
    isEntitledToSmartViews() {
        const status = this.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.SmartFilters).getValue());
        return status === FeatureStatus.Entitled;
    }
    isVaultsEnabled() {
        const enabled = this.features.isExperimentalFeatureEnabled(NativeFeatureIdentifier.TYPES.Vaults);
        return (featureTrunkVaultsEnabled() ||
            enabled ||
            this.features.hasRole(RoleName.create(RoleName.NAMES.InternalTeamUser).getValue()));
    }
    isEntitledToSharedVaults() {
        const status = this.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.SharedVaults).getValue());
        const isEntitledToFeature = status === FeatureStatus.Entitled;
        return featureTrunkVaultsEnabled() || isEntitledToFeature;
    }
}
