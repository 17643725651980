export var KeyboardKey;
(function (KeyboardKey) {
    KeyboardKey["Tab"] = "Tab";
    KeyboardKey["Backspace"] = "Backspace";
    KeyboardKey["Up"] = "ArrowUp";
    KeyboardKey["Down"] = "ArrowDown";
    KeyboardKey["Left"] = "ArrowLeft";
    KeyboardKey["Right"] = "ArrowRight";
    KeyboardKey["Enter"] = "Enter";
    KeyboardKey["Escape"] = "Escape";
    KeyboardKey["Home"] = "Home";
    KeyboardKey["End"] = "End";
    KeyboardKey["Space"] = " ";
    KeyboardKey["PageUp"] = "PageUp";
    KeyboardKey["PageDown"] = "PageDown";
})(KeyboardKey || (KeyboardKey = {}));
