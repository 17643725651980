import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { STRING_SIGN_OUT_CONFIRMATION } from '@/Constants/Strings';
import { observer } from 'mobx-react-lite';
import { isDesktopApplication } from '@/Utils';
import Button from '@/Components/Button/Button';
import Icon from '../Icon/Icon';
import AlertDialog from '../AlertDialog/AlertDialog';
import HorizontalSeparator from '../Shared/HorizontalSeparator';
const ConfirmSignoutModal = ({ application, applicationGroup }) => {
    var _a, _b, _c;
    const hasAnyBackupsEnabled = ((_a = application.fileBackups) === null || _a === void 0 ? void 0 : _a.isFilesBackupsEnabled()) ||
        ((_b = application.fileBackups) === null || _b === void 0 ? void 0 : _b.isPlaintextBackupsEnabled()) ||
        ((_c = application.fileBackups) === null || _c === void 0 ? void 0 : _c.isTextBackupsEnabled());
    const cancelRef = useRef(null);
    const closeDialog = useCallback(() => {
        application.accountMenuController.setSigningOut(false);
    }, [application.accountMenuController]);
    const workspaces = applicationGroup.getDescriptors();
    const showWorkspaceWarning = workspaces.length > 1 && isDesktopApplication();
    const confirm = useCallback(() => {
        application.user.signOut().catch(console.error);
        closeDialog();
    }, [application, closeDialog]);
    return (_jsxs(AlertDialog, { closeDialog: closeDialog, children: [_jsxs("div", { className: "flex items-center justify-between text-lg font-bold", children: ["Sign out workspace?", _jsx("button", { className: "rounded p-1 font-bold hover:bg-contrast", onClick: closeDialog, children: _jsx(Icon, { type: "close" }) })] }), _jsx("div", { className: "sk-panel-row", children: _jsxs("div", { children: [_jsx("p", { className: "text-base text-foreground lg:text-sm", children: STRING_SIGN_OUT_CONFIRMATION }), showWorkspaceWarning && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsxs("p", { className: "text-base text-foreground lg:text-sm", children: [_jsx("strong", { children: "Note: " }), "Because you have other workspaces signed in, this sign out may leave logs and other metadata of your session on this device. For a more robust sign out that performs a hard clear of all app-related data, use the ", _jsx("i", { children: "Sign out all workspaces" }), " option under ", _jsx("i", { children: "Switch workspace" }), "."] })] }))] }) }), hasAnyBackupsEnabled && (_jsxs(_Fragment, { children: [_jsx(HorizontalSeparator, { classes: "my-2" }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "sk-panel-row" }), _jsxs("div", { children: [_jsx("p", { className: "text-base text-foreground lg:text-sm", children: "Local backups are enabled for this workspace. Review your backup files manually to decide what to keep." }), _jsx("button", { className: "sk-a mt-2 cursor-pointer rounded p-0 capitalize lg:text-sm", onClick: () => {
                                            var _a;
                                            void ((_a = application.fileBackups) === null || _a === void 0 ? void 0 : _a.openAllDirectoriesContainingBackupFiles());
                                        }, children: "View backup files" })] })] })] })), _jsxs("div", { className: "mt-4 flex justify-end gap-2", children: [_jsx(Button, { ref: cancelRef, onClick: closeDialog, children: "Cancel" }), _jsx(Button, { primary: true, colorStyle: "danger", onClick: confirm, children: application.hasAccount() ? 'Sign Out' : 'Delete Workspace' })] })] }));
};
ConfirmSignoutModal.displayName = 'ConfirmSignoutModal';
const ConfirmSignoutContainer = (props) => {
    if (!props.application.accountMenuController.signingOut) {
        return null;
    }
    return _jsx(ConfirmSignoutModal, { ...props });
};
export default observer(ConfirmSignoutContainer);
