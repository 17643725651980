import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApplicationGroupEvent, ButtonType } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import Menu from '@/Components/Menu/Menu';
import MenuItem from '@/Components/Menu/MenuItem';
import WorkspaceMenuItem from './WorkspaceMenuItem';
import { useApplication } from '@/Components/ApplicationProvider';
import MenuSection from '@/Components/Menu/MenuSection';
const WorkspaceSwitcherMenu = ({ mainApplicationGroup, hideWorkspaceOptions = false, }) => {
    const application = useApplication();
    const [applicationDescriptors, setApplicationDescriptors] = useState(mainApplicationGroup.getDescriptors());
    useEffect(() => {
        const applicationDescriptors = mainApplicationGroup.getDescriptors();
        setApplicationDescriptors(applicationDescriptors);
        const removeAppGroupObserver = mainApplicationGroup.addEventObserver((event) => {
            if (event === ApplicationGroupEvent.DescriptorsDataChanged) {
                const applicationDescriptors = mainApplicationGroup.getDescriptors();
                setApplicationDescriptors(applicationDescriptors);
            }
        });
        return () => {
            removeAppGroupObserver();
        };
    }, [mainApplicationGroup]);
    const signoutAll = useCallback(async () => {
        const confirmed = await application.alerts.confirm('Are you sure you want to sign out of all workspaces on this device?', undefined, 'Sign out all', ButtonType.Danger);
        if (!confirmed) {
            return;
        }
        mainApplicationGroup.signOutAllWorkspaces().catch(console.error);
    }, [mainApplicationGroup, application]);
    const destroyWorkspace = useCallback(() => {
        application.accountMenuController.setSigningOut(true);
    }, [application]);
    const activateWorkspace = useCallback(async (descriptor) => {
        void mainApplicationGroup.unloadCurrentAndActivateDescriptor(descriptor);
    }, [mainApplicationGroup]);
    const addAnotherWorkspace = useCallback(async () => {
        void mainApplicationGroup.unloadCurrentAndCreateNewDescriptor();
    }, [mainApplicationGroup]);
    return (_jsxs(Menu, { a11yLabel: "Workspace switcher menu", className: "focus:shadow-none", children: [_jsx(MenuSection, { children: applicationDescriptors.map((descriptor) => (_jsx(WorkspaceMenuItem, { descriptor: descriptor, hideOptions: hideWorkspaceOptions, onDelete: destroyWorkspace, onClick: () => activateWorkspace(descriptor), renameDescriptor: (label) => mainApplicationGroup.renameDescriptor(descriptor, label) }, descriptor.identifier))) }), _jsxs(MenuSection, { children: [_jsxs(MenuItem, { onClick: addAnotherWorkspace, children: [_jsx(Icon, { type: "user-add", className: "mr-2 text-neutral" }), "Add another workspace"] }), !hideWorkspaceOptions && (_jsxs(MenuItem, { onClick: signoutAll, children: [_jsx(Icon, { type: "signOut", className: "mr-2 text-neutral" }), "Sign out all workspaces"] }))] })] }));
};
export default observer(WorkspaceSwitcherMenu);
