import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '../../Icon/Icon';
import { classNames } from '@standardnotes/utils';
import Popover from '@/Components/Popover/Popover';
import DisplayOptionsMenu from './DisplayOptionsMenu';
import { NavigationMenuButton } from '@/Components/NavigationMenu/NavigationMenu';
import { ApplicationEvent, isTag } from '@standardnotes/snjs';
import RoundIconButton from '@/Components/Button/RoundIconButton';
import { MediaQueryBreakpoints, MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import AddItemMenuButton from './AddItemMenuButton';
import SearchButton from './SearchButton';
import ListItemVaultInfo from '../ListItemVaultInfo';
const ContentListHeader = ({ application, panelTitle, icon, addButtonLabel, addNewItem, isFilesSmartView, isTableViewEnabled, optionsSubtitle, selectedTag, filesController, itemListController, paneController, }) => {
    const displayOptionsContainerRef = useRef(null);
    const displayOptionsButtonRef = useRef(null);
    const isDailyEntry = isTag(selectedTag) && selectedTag.isDailyEntry;
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const matchesMd = useMediaQuery(MediaQueryBreakpoints.md);
    const isTouchScreen = !useMediaQuery(MediaQueryBreakpoints.pointerFine);
    const isTablet = matchesMd && isTouchScreen;
    const [syncSubtitle, setSyncSubtitle] = useState('');
    const [outOfSync, setOutOfSync] = useState(false);
    const showSyncSubtitle = isMobileScreen && (!!syncSubtitle || outOfSync);
    useEffect(() => {
        return application.addEventObserver(async (event) => {
            if (event === ApplicationEvent.CompletedInitialSync) {
                setSyncSubtitle('');
                return;
            }
            if (event === ApplicationEvent.EnteredOutOfSync) {
                setOutOfSync(true);
                return;
            }
            if (event === ApplicationEvent.ExitedOutOfSync) {
                setOutOfSync(false);
                return;
            }
            const syncStatus = application.sync.getSyncStatus();
            const { localDataDone, localDataCurrent, localDataTotal } = syncStatus.getStats();
            if (event === ApplicationEvent.SyncStatusChanged) {
                setSyncSubtitle(syncStatus.syncInProgress && !application.sync.completedOnlineDownloadFirstSync ? 'Syncing...' : '');
                return;
            }
            if (event === ApplicationEvent.LocalDataIncrementalLoad || event === ApplicationEvent.LocalDataLoaded) {
                if (localDataDone) {
                    setSyncSubtitle('');
                    return;
                }
                setSyncSubtitle(`Loading ${localDataCurrent}/${localDataTotal} items...`);
                return;
            }
        });
    }, [application]);
    const [showDisplayOptionsMenu, setShowDisplayOptionsMenu] = useState(false);
    const toggleDisplayOptionsMenu = useCallback(() => {
        setShowDisplayOptionsMenu((show) => !show);
    }, []);
    const OptionsMenu = useMemo(() => {
        return (_jsx("div", { className: "flex", children: _jsxs("div", { className: "relative", ref: displayOptionsContainerRef, children: [_jsx(RoundIconButton, { className: classNames(showDisplayOptionsMenu ? 'bg-contrast' : undefined), onClick: toggleDisplayOptionsMenu, ref: displayOptionsButtonRef, icon: "sort-descending", label: "Display options menu" }), _jsx(Popover, { open: showDisplayOptionsMenu, anchorElement: displayOptionsButtonRef, togglePopover: toggleDisplayOptionsMenu, align: "start", className: "py-2", title: "Display options", children: _jsx(DisplayOptionsMenu, { application: application, isFilesSmartView: isFilesSmartView, selectedTag: selectedTag, paneController: paneController }) })] }) }));
    }, [showDisplayOptionsMenu, toggleDisplayOptionsMenu, application, isFilesSmartView, selectedTag, paneController]);
    const AddButton = useMemo(() => {
        return (_jsx(AddItemMenuButton, { isInFilesSmartView: isFilesSmartView, isDailyEntry: isDailyEntry, addButtonLabel: addButtonLabel, addNewItem: addNewItem, filesController: filesController }));
    }, [addButtonLabel, addNewItem, filesController, isDailyEntry, isFilesSmartView]);
    const SearchBarButton = useMemo(() => {
        if (!isTableViewEnabled || isMobileScreen) {
            return null;
        }
        return _jsx(SearchButton, { itemListController: itemListController });
    }, [isTableViewEnabled, isMobileScreen, itemListController]);
    const FolderName = useMemo(() => {
        return (_jsx("div", { className: "flex min-w-0 flex-grow flex-col break-words pt-1 lg:pt-0", children: _jsxs("div", { className: classNames('flex min-w-0 flex-grow', !optionsSubtitle && !showSyncSubtitle ? 'items-center' : ''), children: [icon && (_jsx(Icon, { type: icon, size: "custom", className: classNames('ml-0.5 mr-2 h-7 w-7 flex-shrink-0 text-2xl text-neutral lg:h-6 lg:w-6 lg:text-lg', optionsSubtitle && 'md:mt-0.5') })), _jsxs("div", { className: "mr-2 flex min-w-0 flex-col break-words", children: [_jsx("div", { className: "text-2xl font-semibold text-text md:text-lg", children: panelTitle }), showSyncSubtitle && (_jsx("div", { className: classNames('-mt-1 text-xs md:mt-0', outOfSync ? 'text-warning' : 'text-passive-0'), children: outOfSync ? 'Potentially Out of Sync' : syncSubtitle })), optionsSubtitle && _jsx("div", { className: "text-xs text-passive-0", children: optionsSubtitle }), _jsx(ListItemVaultInfo, { className: "mt-1", item: selectedTag })] })] }) }));
    }, [optionsSubtitle, showSyncSubtitle, icon, panelTitle, outOfSync, syncSubtitle, selectedTag]);
    const PhoneAndDesktopLayout = useMemo(() => {
        return (_jsxs("div", { className: 'flex w-full justify-between md:flex', children: [_jsx(NavigationMenuButton, {}), FolderName, _jsxs("div", { className: "flex items-start gap-3 md:items-center", children: [SearchBarButton, OptionsMenu, AddButton] })] }));
    }, [FolderName, SearchBarButton, OptionsMenu, AddButton]);
    const TabletLayout = useMemo(() => {
        return (_jsxs("div", { className: 'w-full flex-col', children: [_jsxs("div", { className: "mb-2 flex justify-between", children: [_jsx(NavigationMenuButton, {}), _jsxs("div", { className: "flex", children: [OptionsMenu, AddButton] })] }), FolderName] }));
    }, [OptionsMenu, AddButton, FolderName]);
    return (_jsxs("div", { className: "section-title-bar-header items-start gap-1", children: [!isTablet && PhoneAndDesktopLayout, isTablet && TabletLayout] }));
};
export default memo(ContentListHeader);
