import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UIFeature, GetDarkThemeFeature } from '@standardnotes/snjs';
import { TOGGLE_DARK_MODE_COMMAND } from '@standardnotes/ui-services';
import { classNames } from '@standardnotes/utils';
import { useEffect, useRef, useState } from 'react';
import { useCommandService } from '../CommandProvider';
import Icon from '../Icon/Icon';
import Popover from '../Popover/Popover';
import QuickSettingsMenu from '../QuickSettingsMenu/QuickSettingsMenu';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
import RoundIconButton from '../Button/RoundIconButton';
const QuickSettingsButton = ({ application, isMobileNavigation = false }) => {
    const buttonRef = useRef(null);
    const commandService = useCommandService();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    useEffect(() => {
        const darkThemeFeature = new UIFeature(GetDarkThemeFeature());
        return commandService.addCommandHandler({
            command: TOGGLE_DARK_MODE_COMMAND,
            category: 'General',
            description: 'Toggle dark mode',
            onKeyDown: () => {
                void application.componentManager.toggleTheme(darkThemeFeature);
                return true;
            },
        });
    }, [application, commandService]);
    return (_jsxs(_Fragment, { children: [_jsx(StyledTooltip, { label: "Open quick settings menu", children: isMobileNavigation ? (_jsx(RoundIconButton, { className: "ml-2.5 bg-default", onClick: toggleMenu, label: "Go to vaults menu", icon: "themes" })) : (_jsx("button", { onClick: toggleMenu, className: "flex h-full w-8 cursor-pointer items-center justify-center", ref: buttonRef, children: _jsx("div", { className: "h-5", children: _jsx(Icon, { type: "themes", className: classNames(isOpen && 'text-info', 'rounded hover:text-info') }) }) })) }), _jsx(Popover, { title: "Quick settings", togglePopover: toggleMenu, anchorElement: buttonRef, open: isOpen, side: "top", align: "start", className: "md:py-2", children: _jsx(QuickSettingsMenu, { closeMenu: toggleMenu }) })] }));
};
export default QuickSettingsButton;
