import { jsx as _jsx } from "react/jsx-runtime";
import { WebApplicationGroup } from '@/Application/WebApplicationGroup';
import { Component } from 'react';
import ApplicationView from '@/Components/ApplicationView/ApplicationView';
import { ApplicationGroupEvent, DeinitSource } from '@standardnotes/snjs';
import { getPlatformString, isDesktopApplication } from '@/Utils';
import DeallocateHandler from '../DeallocateHandler/DeallocateHandler';
import { IS_CHROME } from '../../Constants/Constants';
const renderDialog = (message) => {
    return (_jsx("div", { className: "flex h-full w-full items-center justify-center bg-passive-5", role: "alert", children: _jsx("div", { className: 'challenge-modal shadow-overlay-light relative flex max-w-125 flex-col items-center rounded border border-solid border-border bg-default p-6', children: _jsx("div", { className: "text-base lg:text-xs", children: message }) }) }));
};
class ApplicationGroupView extends Component {
    constructor(props) {
        super(props);
        if (props.device.isDeviceDestroyed()) {
            this.state = {
                deviceDestroyed: true,
            };
            return;
        }
        this.group = new WebApplicationGroup(props.server, props.device, props.websocketUrl);
        window.mainApplicationGroup = this.group;
        this.applicationObserverRemover = this.group.addEventObserver((event, data) => {
            if (event === ApplicationGroupEvent.PrimaryApplicationSet) {
                const castData = data;
                this.application = castData.application;
                this.setState({ activeApplication: this.application });
            }
            else if (event === ApplicationGroupEvent.DeviceWillRestart) {
                const castData = data;
                this.setState({ dealloced: true, deallocSource: castData.source });
            }
        });
        this.state = {};
        this.group.initialize().catch(console.error);
        const platformString = getPlatformString();
        if (!document.body.classList.contains(platformString)) {
            document.body.classList.add(platformString);
        }
        if (IS_CHROME) {
            document.body.classList.add('chromium');
        }
    }
    deinit() {
        var _a, _b;
        this.application = undefined;
        (_a = this.applicationObserverRemover) === null || _a === void 0 ? void 0 : _a.call(this);
        this.applicationObserverRemover = undefined;
        (_b = this.group) === null || _b === void 0 ? void 0 : _b.deinit();
        this.group = undefined;
        this.setState({ dealloced: true, activeApplication: undefined });
        const onDestroy = this.props.onDestroy;
        onDestroy();
    }
    render() {
        if (this.state.deviceDestroyed) {
            const message = `Secure memory has destroyed this application instance. ${isDesktopApplication()
                ? 'Restart the app to continue.'
                : 'Close this browser tab and open a new one to continue.'}`;
            return renderDialog(message);
        }
        if (this.state.dealloced) {
            const message = this.state.deallocSource === DeinitSource.Lock ? 'Locking workspace...' : 'Switching workspace...';
            return renderDialog(message);
        }
        if (!this.group || !this.state.activeApplication || this.state.activeApplication.dealloced) {
            return null;
        }
        return (_jsx("div", { id: this.state.activeApplication.identifier, className: 'h-full', children: _jsx(DeallocateHandler, { application: this.state.activeApplication, children: _jsx(ApplicationView, { mainApplicationGroup: this.group, application: this.state.activeApplication }, this.state.activeApplication.ephemeralIdentifier) }) }, this.state.activeApplication.ephemeralIdentifier));
    }
}
export default ApplicationGroupView;
