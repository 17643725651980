export function getAbsolutePositionedParent(element) {
    if (!element) {
        return null;
    }
    const parent = element.parentElement;
    if (!parent) {
        return null;
    }
    const position = window.getComputedStyle(parent).getPropertyValue('position');
    if (position === 'absolute') {
        return parent;
    }
    return getAbsolutePositionedParent(parent);
}
