import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import Popover from '../Popover/Popover';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
import VaultSelectionMenu from '../VaultSelectionMenu/VaultSelectionMenu';
import { useApplication } from '../ApplicationProvider';
import RoundIconButton from '../Button/RoundIconButton';
import { observer } from 'mobx-react-lite';
const VaultSelectionButton = ({ isMobileNavigation = false }) => {
    const application = useApplication();
    const buttonRef = useRef(null);
    const exclusivelyShownVault = application.vaultDisplayService.exclusivelyShownVault;
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    if (!application.featuresController.isVaultsEnabled()) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(StyledTooltip, { label: "Open vault selection menu", children: isMobileNavigation ? (_jsx(RoundIconButton, { className: "ml-2.5 bg-default", onClick: toggleMenu, label: "Go to vaults menu", icon: "safe-square" })) : (_jsx("button", { onClick: toggleMenu, className: "flex h-full cursor-pointer items-center justify-center", ref: buttonRef, children: _jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { type: "safe-square", className: classNames(isOpen ? 'text-info' : exclusivelyShownVault ? 'text-success' : '', 'rounded hover:text-info') }), exclusivelyShownVault && (_jsx("div", { className: classNames('ml-1 text-xs font-bold', isOpen && 'text-info'), children: exclusivelyShownVault.name }))] }) })) }), _jsx(Popover, { title: "Vault options", togglePopover: toggleMenu, anchorElement: buttonRef, open: isOpen, side: "top", align: "start", className: "py-2", children: _jsx(VaultSelectionMenu, {}) })] }));
};
export default observer(VaultSelectionButton);
