import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import { PurchaseFlowPane } from '@/Controllers/PurchaseFlow/PurchaseFlowPane';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import FloatingLabelInput from '@/Components/Input/FloatingLabelInput';
import { isEmailValid } from '@/Utils';
import { BlueDotIcon, CircleIcon, DiamondIcon, CreateAccountIllustration } from '@standardnotes/icons';
import { useCaptcha } from '@/Hooks/useCaptcha';
import { AccountMenuPane } from '../../AccountMenu/AccountMenuPane';
import { isErrorResponse } from '@standardnotes/snjs';
const CreateAccount = ({ application }) => {
    const { setCurrentPane } = application.purchaseFlowController;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [isPasswordNotMatching, setIsPasswordNotMatching] = useState(false);
    const [hvmToken, setHVMToken] = useState('');
    const [captchaURL, setCaptchaURL] = useState('');
    const register = useCallback(() => {
        setIsCreatingAccount(true);
        application
            .register(email, password, hvmToken)
            .then(() => {
            application.accountMenuController.closeAccountMenu();
            application.accountMenuController.setCurrentPane(AccountMenuPane.GeneralMenu);
        })
            .catch((err) => {
            console.error(err);
            application.alerts.alert(err).catch(console.error);
        })
            .finally(() => {
            setIsCreatingAccount(false);
        });
    }, [application, email, hvmToken, password]);
    const captchaIframe = useCaptcha(captchaURL, (token) => {
        setHVMToken(token);
        setCaptchaURL('');
    });
    useEffect(() => {
        if (!hvmToken) {
            return;
        }
        register();
    }, [hvmToken, register]);
    const checkIfCaptchaRequiredAndRegister = useCallback(() => {
        application
            .getCaptchaUrl()
            .then((response) => {
            if (isErrorResponse(response)) {
                throw new Error();
            }
            const { captchaUIUrl } = response.data;
            if (captchaUIUrl) {
                setCaptchaURL(captchaUIUrl);
            }
            else {
                setCaptchaURL('');
                register();
            }
        })
            .catch((error) => {
            console.error(error);
            setCaptchaURL('');
            register();
        });
    }, [application, register]);
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const confirmPasswordInputRef = useRef(null);
    useEffect(() => {
        var _a;
        if (emailInputRef.current) {
            (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsEmailInvalid(false);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setIsPasswordNotMatching(false);
    };
    const handleSignInInstead = () => {
        setCurrentPane(PurchaseFlowPane.SignIn);
    };
    const subscribeWithoutAccount = () => {
        void application.purchaseFlowController.openPurchaseWebpage();
    };
    const handleCreateAccount = async () => {
        var _a, _b, _c, _d, _e;
        if (!email) {
            (_a = emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (!isEmailValid(email)) {
            setIsEmailInvalid(true);
            (_b = emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            return;
        }
        if (!password) {
            (_c = passwordInputRef === null || passwordInputRef === void 0 ? void 0 : passwordInputRef.current) === null || _c === void 0 ? void 0 : _c.focus();
            return;
        }
        if (!confirmPassword) {
            (_d = confirmPasswordInputRef === null || confirmPasswordInputRef === void 0 ? void 0 : confirmPasswordInputRef.current) === null || _d === void 0 ? void 0 : _d.focus();
            return;
        }
        if (password !== confirmPassword) {
            setConfirmPassword('');
            setIsPasswordNotMatching(true);
            (_e = confirmPasswordInputRef === null || confirmPasswordInputRef === void 0 ? void 0 : confirmPasswordInputRef.current) === null || _e === void 0 ? void 0 : _e.focus();
            return;
        }
        checkIfCaptchaRequiredAndRegister();
    };
    const CreateAccountForm = (_jsx("form", { onSubmit: handleCreateAccount, children: _jsxs("div", { className: "flex flex-col", children: [_jsx(FloatingLabelInput, { className: `min-w-auto md:min-w-90 ${isEmailInvalid ? 'mb-2' : 'mb-4'}`, id: "purchase-sign-in-email", type: "email", label: "Email", value: email, onChange: handleEmailChange, ref: emailInputRef, disabled: isCreatingAccount, isInvalid: isEmailInvalid }), isEmailInvalid ? _jsx("div", { className: "mb-4 text-danger", children: "Please provide a valid email." }) : null, _jsx(FloatingLabelInput, { className: "min-w-auto mb-4 md:min-w-90", id: "purchase-create-account-password", type: "password", label: "Password", value: password, onChange: handlePasswordChange, ref: passwordInputRef, disabled: isCreatingAccount }), _jsx(FloatingLabelInput, { className: `min-w-auto md:min-w-90 ${isPasswordNotMatching ? 'mb-2' : 'mb-4'}`, id: "create-account-confirm", type: "password", label: "Repeat password", value: confirmPassword, onChange: handleConfirmPasswordChange, ref: confirmPasswordInputRef, disabled: isCreatingAccount, isInvalid: isPasswordNotMatching }), isPasswordNotMatching ? (_jsx("div", { className: "mb-4 text-danger", children: "Passwords don't match. Please try again." })) : null] }) }));
    return (_jsxs("div", { className: "flex items-center", children: [_jsx(CircleIcon, { className: "absolute -left-28 top-[40%] h-8 w-8" }), _jsx(BlueDotIcon, { className: "absolute -left-10 top-[35%] h-4 w-4" }), _jsx(DiamondIcon, { className: "absolute -bottom-5 left-0 -z-[1] h-26 w-26 -translate-x-1/2" }), _jsx(CircleIcon, { className: "absolute -right-20 bottom-[35%] h-8 w-8" }), _jsx(BlueDotIcon, { className: "absolute -right-10 bottom-[25%] h-4 w-4" }), _jsx(DiamondIcon, { className: "absolute -right-2 top-0 -z-[1] h-18 w-18 translate-x-1/2" }), _jsxs("div", { className: "mr-0 lg:mr-12", children: [_jsx("h1", { className: "mb-2 mt-0 text-2xl font-bold", children: "Create your free account" }), _jsx("div", { className: "mb-4 text-sm font-medium", children: "to continue to Standard Notes." }), captchaURL ? captchaIframe : CreateAccountForm, _jsxs("div", { className: "flex flex-col-reverse items-start justify-between md:flex-row md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("button", { onClick: handleSignInInstead, disabled: isCreatingAccount, className: "mb-2 flex cursor-pointer items-start border-0 bg-default p-0 font-medium text-info hover:underline", children: "Sign in instead" }), !application.isNativeIOS() && (_jsx("button", { onClick: subscribeWithoutAccount, disabled: isCreatingAccount, className: "flex cursor-pointer items-start border-0 bg-default p-0 font-medium text-info hover:underline", children: "Subscribe without account" }))] }), _jsx(Button, { className: "mb-4 py-2.5 md:mb-0", primary: true, label: isCreatingAccount ? 'Creating account...' : 'Create account', onClick: handleCreateAccount, disabled: isCreatingAccount })] })] }), _jsx(CreateAccountIllustration, { className: "hidden lg:block" })] }));
};
export default observer(CreateAccount);
