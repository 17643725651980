import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Title, Text, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import { useCallback, useState } from 'react';
import Button from '@/Components/Button/Button';
import Switch from '@/Components/Switch/Switch';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Icon from '@/Components/Icon/Icon';
import EncryptionStatusItem from '../../Security/EncryptionStatusItem';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { useApplication } from '@/Components/ApplicationProvider';
const TextBackupsDesktop = ({ backupsService }) => {
    const application = useApplication();
    const [backupsEnabled, setBackupsEnabled] = useState(backupsService.isTextBackupsEnabled());
    const [backupsLocation, setBackupsLocation] = useState(backupsService.getTextBackupsLocation());
    const changeBackupsLocation = useCallback(async () => {
        const newLocation = await backupsService.changeTextBackupsLocation();
        setBackupsLocation(newLocation);
    }, [backupsService]);
    const openBackupsLocation = useCallback(async () => {
        await backupsService.openTextBackupsLocation();
    }, [backupsService]);
    const toggleBackups = useCallback(async () => {
        if (backupsEnabled) {
            backupsService.disableTextBackups();
        }
        else {
            await backupsService.enableTextBackups();
        }
        setBackupsEnabled(backupsService.isTextBackupsEnabled());
        setBackupsLocation(backupsService.getTextBackupsLocation());
    }, [backupsEnabled, backupsService]);
    const performBackup = useCallback(async () => {
        var _a;
        void ((_a = application.desktopManager) === null || _a === void 0 ? void 0 : _a.saveDesktopBackup());
    }, [application]);
    return (_jsx(_Fragment, { children: _jsxs(PreferencesGroup, { children: [_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Automatic Encrypted Text Backups" }), _jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { className: "mr-10 flex flex-col", children: _jsx(Subtitle, { children: "Automatically save encrypted text backups of all your note and tag data to this computer." }) }), _jsx(Switch, { onChange: toggleBackups, checked: backupsEnabled })] }), !backupsEnabled && (_jsxs(_Fragment, { children: [_jsx(HorizontalSeparator, { classes: "mt-2.5 mb-4" }), _jsx(Text, { children: "Text backups are not enabled. Enable to choose where your data is backed up." })] }))] }), backupsEnabled && (_jsxs(_Fragment, { children: [_jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs(PreferencesSegment, { children: [_jsxs(_Fragment, { children: [_jsx(Text, { className: "mb-3", children: "Text backups are enabled and saved to:" }), _jsx(EncryptionStatusItem, { status: backupsLocation || 'Not Set', icon: _jsx(Icon, { type: "attachment-file", className: "min-h-5 min-w-5" }), checkmark: false }), _jsxs("div", { className: "mt-2.5 flex flex-row", children: [_jsx(Button, { label: "Open Location", className: 'mr-3 text-xs', onClick: openBackupsLocation }), _jsx(Button, { label: "Change Location", className: 'mr-3 text-xs', onClick: changeBackupsLocation })] })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(Text, { className: "mb-3", children: "Backups are saved automatically throughout the day. You can perform a one-time backup now below." }), _jsx("div", { className: "flex flex-row", children: _jsx(Button, { label: "Perform Backup", className: 'mr-3 text-xs', onClick: performBackup }) })] })] }))] }) }));
};
export default observer(TextBackupsDesktop);
