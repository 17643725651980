export var FileItemActionType;
(function (FileItemActionType) {
    FileItemActionType[FileItemActionType["AttachFileToNote"] = 0] = "AttachFileToNote";
    FileItemActionType[FileItemActionType["DetachFileToNote"] = 1] = "DetachFileToNote";
    FileItemActionType[FileItemActionType["DeleteFile"] = 2] = "DeleteFile";
    FileItemActionType[FileItemActionType["DownloadFile"] = 3] = "DownloadFile";
    FileItemActionType[FileItemActionType["RenameFile"] = 4] = "RenameFile";
    FileItemActionType[FileItemActionType["ToggleFileProtection"] = 5] = "ToggleFileProtection";
    FileItemActionType[FileItemActionType["PreviewFile"] = 6] = "PreviewFile";
})(FileItemActionType || (FileItemActionType = {}));
