import { FORMAT_ELEMENT_COMMAND } from 'lexical';
import { BlockPickerOption } from '../BlockPickerPlugin/BlockPickerOption';
export const LeftAlignBlock = {
    name: 'Align left',
    iconName: 'align-left',
    keywords: ['align', 'justify', 'left'],
    onSelect: (editor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left'),
};
export const CenterAlignBlock = {
    name: 'Align center',
    iconName: 'align-center',
    keywords: ['align', 'justify', 'center'],
    onSelect: (editor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center'),
};
export const RightAlignBlock = {
    name: 'Align right',
    iconName: 'align-right',
    keywords: ['align', 'justify', 'right'],
    onSelect: (editor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right'),
};
export const JustifyAlignBlock = {
    name: 'Align justify',
    iconName: 'align-justify',
    keywords: ['align', 'justify', 'justify'],
    onSelect: (editor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify'),
};
export function GetLeftAlignBlockOption(editor) {
    return new BlockPickerOption(LeftAlignBlock.name, {
        iconName: LeftAlignBlock.iconName,
        keywords: LeftAlignBlock.keywords,
        onSelect: () => LeftAlignBlock.onSelect(editor),
    });
}
export function GetCenterAlignBlockOption(editor) {
    return new BlockPickerOption(CenterAlignBlock.name, {
        iconName: CenterAlignBlock.iconName,
        keywords: CenterAlignBlock.keywords,
        onSelect: () => CenterAlignBlock.onSelect(editor),
    });
}
export function GetRightAlignBlockOption(editor) {
    return new BlockPickerOption(RightAlignBlock.name, {
        iconName: RightAlignBlock.iconName,
        keywords: RightAlignBlock.keywords,
        onSelect: () => RightAlignBlock.onSelect(editor),
    });
}
export function GetJustifyAlignBlockOption(editor) {
    return new BlockPickerOption(JustifyAlignBlock.name, {
        iconName: JustifyAlignBlock.iconName,
        keywords: JustifyAlignBlock.keywords,
        onSelect: () => JustifyAlignBlock.onSelect(editor),
    });
}
