import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import { PurchaseFlowPane } from '@/Controllers/PurchaseFlow/PurchaseFlowPane';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import FloatingLabelInput from '@/Components/Input/FloatingLabelInput';
import { isEmailValid } from '@/Utils';
import { BlueDotIcon, CircleIcon, DiamondIcon } from '@standardnotes/icons';
import { isErrorResponse, getCaptchaHeader } from '@standardnotes/snjs';
import { useCaptcha } from '@/Hooks/useCaptcha';
const SignIn = ({ application }) => {
    const { setCurrentPane } = application.purchaseFlowController;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
    const [otherErrorMessage, setOtherErrorMessage] = useState('');
    const [captchaURL, setCaptchaURL] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [hvmToken, setHVMToken] = useState('');
    const captchaIframe = useCaptcha(captchaURL, (token) => {
        setHVMToken(token);
        setShowCaptcha(false);
        setCaptchaURL('');
    });
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    useEffect(() => {
        var _a;
        if (emailInputRef.current) {
            (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsEmailInvalid(false);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setIsPasswordInvalid(false);
        setOtherErrorMessage('');
    };
    const handleCreateAccountInstead = () => {
        if (isSigningIn) {
            return;
        }
        setCurrentPane(PurchaseFlowPane.CreateAccount);
    };
    const handleSignIn = async () => {
        var _a, _b, _c, _d;
        if (!email) {
            (_a = emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (!isEmailValid(email)) {
            setIsEmailInvalid(true);
            (_b = emailInputRef === null || emailInputRef === void 0 ? void 0 : emailInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            return;
        }
        if (!password) {
            (_c = passwordInputRef === null || passwordInputRef === void 0 ? void 0 : passwordInputRef.current) === null || _c === void 0 ? void 0 : _c.focus();
            return;
        }
        if (captchaURL) {
            setShowCaptcha(true);
            return;
        }
        setIsSigningIn(true);
        try {
            const response = await application.signIn(email, password, undefined, undefined, undefined, undefined, hvmToken);
            const captchaURL = getCaptchaHeader(response);
            if (captchaURL) {
                setCaptchaURL(captchaURL);
                return;
            }
            else {
                setCaptchaURL('');
            }
            if (isErrorResponse(response)) {
                throw new Error((_d = response.data.error) === null || _d === void 0 ? void 0 : _d.message);
            }
            else {
                application.purchaseFlowController.closePurchaseFlow();
                void application.purchaseFlowController.openPurchaseFlow();
            }
        }
        catch (err) {
            console.error(err);
            if (err.toString().includes('Invalid email or password')) {
                setIsEmailInvalid(true);
                setIsPasswordInvalid(true);
                setOtherErrorMessage('Invalid email or password.');
                setPassword('');
            }
            else {
                application.alerts.alert(err).catch(console.error);
            }
        }
        finally {
            setIsSigningIn(false);
        }
    };
    const signInForm = (_jsxs("form", { onSubmit: handleSignIn, children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(FloatingLabelInput, { className: `min-w-auto sm:min-w-90 ${isEmailInvalid && !otherErrorMessage ? 'mb-2' : 'mb-4'}`, id: "purchase-sign-in-email", type: "email", label: "Email", value: email, onChange: handleEmailChange, ref: emailInputRef, disabled: isSigningIn, isInvalid: isEmailInvalid }), isEmailInvalid && !otherErrorMessage ? (_jsx("div", { className: "mb-4 text-danger", children: "Please provide a valid email." })) : null, _jsx(FloatingLabelInput, { className: `min-w-auto sm:min-w-90 ${otherErrorMessage ? 'mb-2' : 'mb-4'}`, id: "purchase-sign-in-password", type: "password", label: "Password", value: password, onChange: handlePasswordChange, ref: passwordInputRef, disabled: isSigningIn, isInvalid: isPasswordInvalid }), otherErrorMessage ? _jsx("div", { className: "mb-4 text-danger", children: otherErrorMessage }) : null] }), _jsx(Button, { className: `${isSigningIn ? 'min-w-30' : 'min-w-24'} mb-5 py-2.5`, primary: true, label: isSigningIn ? 'Signing in...' : 'Sign in', onClick: handleSignIn, disabled: isSigningIn })] }));
    return (_jsxs("div", { className: "flex items-center", children: [_jsx(CircleIcon, { className: "absolute -left-56 top-[35%] h-8 w-8" }), _jsx(BlueDotIcon, { className: "absolute -left-40 top-[30%] h-4 w-4" }), _jsx(DiamondIcon, { className: "absolute -bottom-5 left-0 -z-[1] h-26 w-26 -translate-x-1/2" }), _jsx(CircleIcon, { className: "absolute -right-56 bottom-[30%] h-8 w-8" }), _jsx(BlueDotIcon, { className: "absolute -right-44 bottom-[20%] h-4 w-4" }), _jsx(DiamondIcon, { className: "absolute -right-2 top-0 -z-[1] h-18 w-18 translate-x-1/2" }), _jsxs("div", { children: [_jsx("h1", { className: "mb-2 mt-0 text-2xl font-bold", children: "Sign in" }), _jsx("div", { className: "mb-4 text-sm font-medium", children: "to continue to Standard Notes." }), showCaptcha ? captchaIframe : signInForm, _jsxs("div", { className: "text-sm font-medium text-passive-1", children: ["Don\u2019t have an account yet?", ' ', _jsx("a", { className: `text-info ${isSigningIn ? 'cursor-not-allowed' : 'cursor-pointer '}`, onClick: handleCreateAccountInstead, children: "Create account" })] })] })] }));
};
export default observer(SignIn);
