"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutationType = void 0;
var MutationType;
(function (MutationType) {
    MutationType[MutationType["UpdateUserTimestamps"] = 1] = "UpdateUserTimestamps";
    /**
     * The item was changed as part of an internal operation, such as a migration, or, a user
     * interaction that shouldn't modify timestamps (pinning, protecting, etc).
     */
    MutationType[MutationType["NoUpdateUserTimestamps"] = 2] = "NoUpdateUserTimestamps";
    /**
     * The item was changed as part of an internal function that wishes to modify
     * internal item properties, such as lastSyncBegan, without modifying the item's dirty
     * state. By default all other mutation types will result in a dirtied result.
     */
    MutationType[MutationType["NonDirtying"] = 3] = "NonDirtying";
})(MutationType || (exports.MutationType = MutationType = {}));
