import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PhotoCaptureModal from '@/Components/CameraCaptureModal/PhotoCaptureModal';
import VideoCaptureModal from '@/Components/CameraCaptureModal/VideoCaptureModal';
import Icon from '@/Components/Icon/Icon';
import Menu from '@/Components/Menu/Menu';
import MenuItem from '@/Components/Menu/MenuItem';
import Popover from '@/Components/Popover/Popover';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import { PhotoRecorder } from '@/Controllers/Moments/PhotoRecorder';
import { classNames } from '@standardnotes/snjs';
import { useEffect, useRef, useState } from 'react';
import StyledTooltip from '@/Components/StyledTooltip/StyledTooltip';
const AddItemMenuButton = ({ filesController, isDailyEntry, addButtonLabel, isInFilesSmartView, addNewItem, }) => {
    const addItemButtonRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [deviceHasCamera, setDeviceHasCamera] = useState(false);
    const [captureType, setCaptureType] = useState();
    useEffect(() => {
        const setCameraSupport = async () => {
            setDeviceHasCamera(await PhotoRecorder.isSupported());
        };
        void setCameraSupport();
    }, []);
    const canShowMenu = isInFilesSmartView && deviceHasCamera;
    const closeCaptureModal = () => {
        setCaptureType(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(StyledTooltip, { label: addButtonLabel, children: _jsx("button", { className: classNames('z-editor-title-bar hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-solid border-transparent hover:brightness-125 md:flex', isDailyEntry ? 'bg-danger text-danger-contrast' : 'bg-info text-info-contrast'), "aria-label": addButtonLabel, onClick: () => {
                        if (canShowMenu) {
                            setIsMenuOpen((isOpen) => !isOpen);
                        }
                        else {
                            addNewItem();
                        }
                    }, ref: addItemButtonRef, children: _jsx(Icon, { type: "add", size: "custom", className: "h-5 w-5" }) }) }), canShowMenu && (_jsx(Popover, { title: "Add item", open: isMenuOpen, anchorElement: addItemButtonRef, togglePopover: () => {
                    setIsMenuOpen((isOpen) => !isOpen);
                }, side: "bottom", align: "center", className: "py-2", children: _jsxs(Menu, { a11yLabel: 'test', children: [_jsxs(MenuItem, { onClick: () => {
                                addNewItem();
                                setIsMenuOpen(false);
                            }, children: [_jsx(Icon, { type: "add", className: "mr-2" }), addButtonLabel] }), _jsxs(MenuItem, { onClick: async () => {
                                setCaptureType('photo');
                                setIsMenuOpen(false);
                            }, children: [_jsx(Icon, { type: "camera", className: "mr-2" }), "Take photo"] }), _jsxs(MenuItem, { onClick: async () => {
                                setCaptureType('video');
                                setIsMenuOpen(false);
                            }, children: [_jsx(Icon, { type: "camera", className: "mr-2" }), "Record video"] })] }) })), _jsx(ModalOverlay, { isOpen: captureType === 'photo', close: closeCaptureModal, children: _jsx(PhotoCaptureModal, { filesController: filesController, close: closeCaptureModal }) }), _jsx(ModalOverlay, { isOpen: captureType === 'video', close: closeCaptureModal, children: _jsx(VideoCaptureModal, { filesController: filesController, close: closeCaptureModal }) })] }));
};
export default AddItemMenuButton;
