import { INSERT_EMBED_COMMAND } from '@lexical/react/LexicalAutoEmbedPlugin';
import { EmbedConfigs } from '../AutoEmbedPlugin';
import { BlockPickerOption } from '../BlockPickerPlugin/BlockPickerOption';
export function GetEmbedsBlocks(editor) {
    return EmbedConfigs.map((embedConfig) => ({
        name: `Embed ${embedConfig.contentName}`,
        iconName: embedConfig.iconName,
        keywords: [...embedConfig.keywords, 'embed'],
        onSelect: () => editor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type),
    }));
}
export function GetEmbedsBlockOptions(editor) {
    return GetEmbedsBlocks(editor).map((block) => new BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
