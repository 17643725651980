"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopyPayloadWithContentOverride = void 0;
const CreatePayload_1 = require("./CreatePayload");
function CopyPayloadWithContentOverride(payload, contentOverride) {
    const params = Object.assign(Object.assign({}, payload.ejected()), { content: Object.assign(Object.assign({}, payload.content), contentOverride) });
    const result = (0, CreatePayload_1.CreatePayload)(params, payload.source);
    return result;
}
exports.CopyPayloadWithContentOverride = CopyPayloadWithContentOverride;
