import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useEffect } from 'react';
import { ApplicationEvent } from '@standardnotes/snjs';
import { isSameDay } from '@/Utils';
import Button from '@/Components/Button/Button';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import { Title, Text } from '../../PreferencesComponents/Content';
const Protections = ({ application }) => {
    const enableProtections = () => {
        application.clearProtectionSession().catch(console.error);
    };
    const [hasProtections, setHasProtections] = useState(() => application.hasProtectionSources());
    const getProtectionsDisabledUntil = useCallback(() => {
        const protectionExpiry = application.getProtectionSessionExpiryDate();
        const now = new Date();
        if (protectionExpiry > now) {
            let f;
            if (isSameDay(protectionExpiry, now)) {
                f = new Intl.DateTimeFormat(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                });
            }
            else {
                f = new Intl.DateTimeFormat(undefined, {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'short',
                    hour: 'numeric',
                    minute: 'numeric',
                });
            }
            return f.format(protectionExpiry);
        }
        return null;
    }, [application]);
    const [protectionsDisabledUntil, setProtectionsDisabledUntil] = useState(getProtectionsDisabledUntil());
    useEffect(() => {
        const removeUnprotectedSessionBeginObserver = application.addEventObserver(async () => {
            setProtectionsDisabledUntil(getProtectionsDisabledUntil());
        }, ApplicationEvent.UnprotectedSessionBegan);
        const removeUnprotectedSessionEndObserver = application.addEventObserver(async () => {
            setProtectionsDisabledUntil(getProtectionsDisabledUntil());
        }, ApplicationEvent.UnprotectedSessionExpired);
        const removeKeyStatusChangedObserver = application.addEventObserver(async () => {
            setHasProtections(application.hasProtectionSources());
        }, ApplicationEvent.KeyStatusChanged);
        return () => {
            removeUnprotectedSessionBeginObserver();
            removeUnprotectedSessionEndObserver();
            removeKeyStatusChangedObserver();
        };
    }, [application, getProtectionsDisabledUntil]);
    if (!hasProtections) {
        return null;
    }
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Protections" }), protectionsDisabledUntil ? (_jsxs(Text, { className: "text-info", children: ["Unprotected access expires at ", protectionsDisabledUntil, "."] })) : (_jsx(Text, { className: "text-info", children: "Protections are enabled." })), _jsx(Text, { className: "mt-2", children: "Actions like viewing or searching protected notes, exporting decrypted backups, or revoking an active session require additional authentication such as entering your account password or application passcode." }), protectionsDisabledUntil && (_jsx(Button, { className: "mt-3", primary: true, label: "End Unprotected Access", onClick: enableProtections }))] }) }));
};
export default Protections;
