import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Icon from '@/Components/Icon/Icon';
import { classNames } from '@standardnotes/utils';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
const RoundIconButton = forwardRef(({ onClick, className, icon: iconType, iconClassName, iconProps, id, label, ...props }, ref) => {
    const click = (e) => {
        e.preventDefault();
        onClick(e);
    };
    return (_jsx(StyledTooltip, { label: label, children: _jsx("button", { className: classNames('bg-text-padding m-0 flex h-10 min-w-10 cursor-pointer items-center justify-center rounded-full border', 'border-solid border-border bg-clip-padding text-neutral hover:bg-contrast hover:text-text focus:bg-contrast', 'focus:text-text focus:outline-none focus:ring-info md:h-8 md:min-w-8', className), onClick: click, ref: ref, id: id, "aria-label": label, ...props, children: _jsx(Icon, { ...iconProps, type: iconType, className: iconClassName }) }) }));
});
export default RoundIconButton;
