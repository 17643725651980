export const ElementIds = {
    ContentList: 'notes-scrollable',
    EditorColumn: 'editor-column',
    EditorContent: 'editor-content',
    FileTextPreview: 'file-text-preview',
    FileTitleEditor: 'file-title-editor',
    ItemsColumn: 'items-column',
    NavigationColumn: 'navigation',
    NoteTextEditor: 'note-text-editor',
    NoteTitleEditor: 'note-title-editor',
    NoteOptionsButton: 'note-options-button',
    RootId: 'app-group-root',
    NoteStatusTooltip: 'note-status-tooltip',
    ItemLinkAutocompleteInput: 'item-link-autocomplete-input',
    SearchBar: 'search-bar',
    ConflictResolutionButton: 'conflict-resolution-button',
    SuperEditor: 'super-editor',
};
