import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Subtitle, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { useApplication } from '@/Components/ApplicationProvider';
import ContactItem from './Contacts/ContactItem';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import EditContactModal from './Contacts/EditContactModal';
import { useCallback, useEffect, useState } from 'react';
import { ContentType, SharedVaultServiceEvent, VaultUserServiceEvent, RoleName, ProtocolVersion, compareVersions, VaultInviteServiceEvent, } from '@standardnotes/snjs';
import VaultItem from './Vaults/VaultItem';
import Button from '@/Components/Button/Button';
import InviteItem from './Invites/InviteItem';
import EditVaultModal from './Vaults/VaultModal/EditVaultModal';
import PreferencesPane from '../../PreferencesComponents/PreferencesPane';
import { ToastType, addToast } from '@standardnotes/toast';
import NoProSubscription from '../Account/NoProSubscription';
const Vaults = observer(() => {
    const application = useApplication();
    const hasAccount = application.hasAccount();
    const isSharedVaultsEnabled = application.featuresController.isEntitledToSharedVaults();
    const [vaults, setVaults] = useState([]);
    const [canCreateMoreVaults, setCanCreateMoreVaults] = useState(true);
    const [invites, setInvites] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
    const closeAddContactModal = () => setIsAddContactModalOpen(false);
    const [isCreatingSharedVault, setIsCreatingSharedVault] = useState(false);
    const [isVaultModalOpen, setIsVaultModalOpen] = useState(false);
    const closeVaultModal = () => {
        setIsVaultModalOpen(false);
        setIsCreatingSharedVault(false);
    };
    const vaultService = application.vaults;
    const contactService = application.contacts;
    const featuresService = application.features;
    const updateVaults = useCallback(async () => {
        const vaults = vaultService.getVaults();
        const ownedVaults = vaults.filter((vault) => {
            return !vault.isSharedVaultListing() ? true : application.vaultUsers.isCurrentUserSharedVaultOwner(vault);
        });
        if (featuresService.hasMinimumRole(RoleName.NAMES.ProUser)) {
            setCanCreateMoreVaults(true);
        }
        else if (featuresService.hasMinimumRole(RoleName.NAMES.PlusUser)) {
            setCanCreateMoreVaults(ownedVaults.length < 3);
        }
        else {
            setCanCreateMoreVaults(ownedVaults.length < 1);
        }
        setVaults(vaults);
    }, [vaultService, featuresService, application.vaultUsers]);
    const updateInvites = useCallback(async () => {
        setInvites(application.vaultInvites.getCachedPendingInviteRecords());
    }, [application.vaultInvites]);
    useEffect(() => {
        return application.vaultInvites.addEventObserver((event) => {
            if (event === VaultInviteServiceEvent.InvitesReloaded) {
                void updateInvites();
            }
        });
    }, [application.vaultInvites, updateInvites]);
    const updateContacts = useCallback(async () => {
        setContacts(contactService.getAllContacts());
    }, [contactService]);
    const updateAllData = useCallback(async () => {
        await Promise.all([updateVaults(), updateInvites(), updateContacts()]);
    }, [updateContacts, updateInvites, updateVaults]);
    useEffect(() => {
        return application.sharedVaults.addEventObserver((event) => {
            if (event === SharedVaultServiceEvent.SharedVaultStatusChanged) {
                void updateAllData();
            }
        });
    }, [application.sharedVaults, updateAllData]);
    useEffect(() => {
        return application.sharedVaults.addEventObserver((event) => {
            if (event === SharedVaultServiceEvent.SharedVaultFileStorageUsageChanged) {
                void updateAllData();
            }
        });
    }, [application.sharedVaults, updateAllData]);
    useEffect(() => {
        return application.vaultUsers.addEventObserver((event) => {
            if (event === VaultUserServiceEvent.UsersChanged) {
                void updateAllData();
            }
        });
    }, [application.vaultUsers, updateAllData]);
    useEffect(() => {
        return application.vaultInvites.addEventObserver(() => {
            void updateAllData();
        });
    }, [application.vaultInvites, updateAllData]);
    useEffect(() => {
        return application.items.streamItems([ContentType.TYPES.VaultListing, ContentType.TYPES.TrustedContact], () => {
            void updateAllData();
        });
    }, [application, updateAllData]);
    useEffect(() => {
        void application.vaultInvites.downloadInboundInvites();
        void updateAllData();
    }, [updateAllData, application.vaultInvites]);
    const createNewVault = useCallback(async () => {
        setIsVaultModalOpen(true);
    }, []);
    const createNewSharedVault = useCallback(async () => {
        setIsCreatingSharedVault(true);
        setIsVaultModalOpen(true);
    }, []);
    const createNewContact = useCallback(() => {
        setIsAddContactModalOpen(true);
    }, []);
    return (_jsxs(PreferencesPane, { children: [_jsx(ModalOverlay, { isOpen: isAddContactModalOpen, close: closeAddContactModal, children: _jsx(EditContactModal, { onCloseDialog: closeAddContactModal }) }), _jsx(EditVaultModal, { isVaultModalOpen: isVaultModalOpen, creatingSharedVault: isCreatingSharedVault, closeVaultModal: closeVaultModal }), invites.length > 0 && (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Incoming Invites" }), _jsx("div", { className: "my-2 flex flex-col gap-3.5", children: invites.map((invite) => {
                                return _jsx(InviteItem, { inviteRecord: invite }, invite.invite.uuid);
                            }) })] }) })), hasAccount && isSharedVaultsEnabled && (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Contacts" }), contacts.length > 0 && (_jsx("div", { className: "my-2 flex flex-col gap-3.5", children: contacts.map((contact) => {
                                return _jsx(ContactItem, { contact: contact }, contact.uuid);
                            }) })), _jsx("div", { className: "mt-2.5 flex flex-row", children: _jsx(Button, { label: "Add New Contact", className: "mr-3", onClick: createNewContact }) })] }) })), hasAccount && isSharedVaultsEnabled && (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "CollaborationID" }), _jsx(Subtitle, { children: "Share your CollaborationID with collaborators to join their vaults." }), contactService.isCollaborationEnabled() ? (_jsxs(_Fragment, { children: [_jsx("code", { className: "mt-2.5 overflow-hidden whitespace-pre-wrap break-words rounded border border-border bg-contrast p-3", children: contactService.getCollaborationID() }), _jsx(Button, { label: "Copy ID", className: "mt-2", onClick: async () => {
                                        try {
                                            await navigator.clipboard.writeText(contactService.getCollaborationID());
                                            addToast({
                                                type: ToastType.Success,
                                                message: 'Copied to clipboard',
                                            });
                                        }
                                        catch (error) {
                                            addToast({
                                                type: ToastType.Error,
                                                message: 'Failed to copy to clipboard',
                                            });
                                            console.error(error);
                                        }
                                    } })] })) : (_jsx("div", { className: "mt-2.5 flex flex-row", children: _jsx(Button, { label: "Enable Vault Sharing", className: "mr-3 text-xs", onClick: () => contactService.enableCollaboration() }) }))] }) })), _jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Vaults" }), vaults.length > 0 && (_jsx("div", { className: "my-2 flex flex-col gap-3.5", children: vaults.map((vault) => {
                                return _jsx(VaultItem, { vault: vault }, vault.uuid);
                            }) })), canCreateMoreVaults ? (_jsxs("div", { className: "mt-2.5 flex gap-3", children: [_jsx(Button, { label: "Create Vault", onClick: createNewVault }), hasAccount && isSharedVaultsEnabled && (_jsx(Button, { label: "Create Shared Vault", onClick: createNewSharedVault }))] })) : (_jsx("div", { className: "mt-3.5", children: _jsx(NoProSubscription, { application: application, text: _jsx("span", { children: "Please upgrade in order to increase your vault limit." }) }) }))] }) })] }));
});
const VaultsWrapper = () => {
    const application = useApplication();
    const hasAccount = application.hasAccount();
    const accountProtocolVersion = application.getUserVersion();
    const isAccountProtocolNotSupported = accountProtocolVersion && compareVersions(accountProtocolVersion, ProtocolVersion.V004) < 0;
    if (hasAccount && isAccountProtocolNotSupported) {
        return (_jsx(PreferencesPane, { children: _jsxs(PreferencesGroup, { children: [_jsx(Title, { children: "Account update required" }), _jsx(Subtitle, { children: "In order to use Vaults, you must update your account to use the latest data encryption version." }), _jsx(Button, { primary: true, className: "mt-3", onClick: () => application.upgradeProtocolVersion().catch(console.error), children: "Update Account" })] }) }));
    }
    return _jsx(Vaults, {});
};
export default observer(VaultsWrapper);
