"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paths = void 0;
const RevisionsPaths = {
    listRevisions: (itemUuid) => `/v2/items/${itemUuid}/revisions`,
    getRevision: (itemUuid, revisionUuid) => `/v2/items/${itemUuid}/revisions/${revisionUuid}`,
    deleteRevision: (itemUuid, revisionUuid) => `/v2/items/${itemUuid}/revisions/${revisionUuid}`,
};
exports.Paths = {
    v2: Object.assign({}, RevisionsPaths),
};
