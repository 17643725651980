import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Title, Text, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import { useCallback, useState } from 'react';
import Button from '@/Components/Button/Button';
import Switch from '@/Components/Switch/Switch';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Icon from '@/Components/Icon/Icon';
import BackupsDropZone from './BackupsDropZone';
import EncryptionStatusItem from '../../Security/EncryptionStatusItem';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { useApplication } from '@/Components/ApplicationProvider';
const FileBackupsDesktop = ({ backupsService }) => {
    const application = useApplication();
    const [backupsEnabled, setBackupsEnabled] = useState(backupsService.isFilesBackupsEnabled());
    const [backupsLocation, setBackupsLocation] = useState(backupsService.getFilesBackupsLocation());
    const changeBackupsLocation = useCallback(async () => {
        const newLocation = await backupsService.changeFilesBackupsLocation();
        setBackupsLocation(newLocation);
    }, [backupsService]);
    const openBackupsLocation = useCallback(async () => {
        await backupsService.openFilesBackupsLocation();
    }, [backupsService]);
    const toggleBackups = useCallback(async () => {
        if (backupsEnabled) {
            backupsService.disableFilesBackups();
        }
        else {
            await backupsService.enableFilesBackups();
        }
        setBackupsEnabled(backupsService.isFilesBackupsEnabled());
        setBackupsLocation(backupsService.getFilesBackupsLocation());
    }, [backupsService, backupsEnabled]);
    return (_jsx(_Fragment, { children: _jsxs(PreferencesGroup, { children: [_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Automatic file backups" }), _jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { className: "mr-10 flex flex-col", children: _jsx(Subtitle, { children: "Automatically save encrypted backups of your uploaded files to this computer." }) }), _jsx(Switch, { onChange: toggleBackups, checked: backupsEnabled })] }), !backupsEnabled && (_jsxs(_Fragment, { children: [_jsx(HorizontalSeparator, { classes: "mt-2.5 mb-4" }), _jsx(Text, { children: "File backups are not enabled. Enable to choose where your files are backed up." })] }))] }), backupsEnabled && (_jsxs(_Fragment, { children: [_jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(PreferencesSegment, { children: _jsxs(_Fragment, { children: [_jsx(Text, { className: "mb-3", children: "Files backups are enabled. When you upload a new file on any device and open this application, files will be backed up in encrypted form to:" }), _jsx(EncryptionStatusItem, { status: backupsLocation || 'Not Set', icon: _jsx(Icon, { type: "attachment-file", className: "min-h-5 min-w-5" }), checkmark: false }), _jsxs("div", { className: "mt-2.5 flex flex-row", children: [_jsx(Button, { label: "Open Location", className: 'mr-3 text-xs', onClick: openBackupsLocation }), _jsx(Button, { label: "Change Location", className: 'mr-3 text-xs', onClick: changeBackupsLocation })] })] }) })] })), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(PreferencesSegment, { children: _jsx(BackupsDropZone, { application: application }) })] }) }));
};
export default observer(FileBackupsDesktop);
