"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrustedContact = void 0;
const Item_1 = require("../../Abstract/Item");
const ContactPublicKeySet_1 = require("./PublicKeySet/ContactPublicKeySet");
const Predicate_1 = require("../../Runtime/Predicate/Predicate");
const PublicKeyTrustStatus_1 = require("./Types/PublicKeyTrustStatus");
class TrustedContact extends Item_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.name = payload.content.name;
        this.contactUuid = payload.content.contactUuid;
        this.publicKeySet = ContactPublicKeySet_1.ContactPublicKeySet.FromJson(payload.content.publicKeySet);
        this.isMe = payload.content.isMe;
    }
    get isSingleton() {
        return true;
    }
    singletonPredicate() {
        return TrustedContact.singletonPredicate;
    }
    hasCurrentOrPreviousSigningPublicKey(signingPublicKey) {
        return this.publicKeySet.findKeySetWithSigningKey(signingPublicKey) !== undefined;
    }
    getTrustStatusForPublicKey(encryptionPublicKey) {
        if (this.publicKeySet.encryption === encryptionPublicKey) {
            return PublicKeyTrustStatus_1.PublicKeyTrustStatus.Trusted;
        }
        const previous = this.publicKeySet.findKeySetWithPublicKey(encryptionPublicKey);
        if (previous) {
            return PublicKeyTrustStatus_1.PublicKeyTrustStatus.Previous;
        }
        return PublicKeyTrustStatus_1.PublicKeyTrustStatus.NotTrusted;
    }
    getTrustStatusForSigningPublicKey(signingPublicKey) {
        if (this.publicKeySet.signing === signingPublicKey) {
            return PublicKeyTrustStatus_1.PublicKeyTrustStatus.Trusted;
        }
        const previous = this.publicKeySet.findKeySetWithSigningKey(signingPublicKey);
        if (previous) {
            return PublicKeyTrustStatus_1.PublicKeyTrustStatus.Previous;
        }
        return PublicKeyTrustStatus_1.PublicKeyTrustStatus.NotTrusted;
    }
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return Item_1.ConflictStrategy.KeepBase;
    }
}
exports.TrustedContact = TrustedContact;
TrustedContact.singletonPredicate = new Predicate_1.Predicate('isMe', '=', true);
