import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useBeforeUnload } from '@/Hooks/useBeforeUnload';
import ChangeEmailForm from './ChangeEmailForm';
import ChangeEmailSuccess from './ChangeEmailSuccess';
import Modal from '@/Components/Modal/Modal';
var SubmitButtonTitles;
(function (SubmitButtonTitles) {
    SubmitButtonTitles["Default"] = "Continue";
    SubmitButtonTitles["GeneratingKeys"] = "Generating Keys...";
    SubmitButtonTitles["Finish"] = "Finish";
})(SubmitButtonTitles || (SubmitButtonTitles = {}));
var Steps;
(function (Steps) {
    Steps[Steps["InitialStep"] = 0] = "InitialStep";
    Steps[Steps["FinishStep"] = 1] = "FinishStep";
})(Steps || (Steps = {}));
const ChangeEmail = ({ onCloseDialog, application }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [isContinuing, setIsContinuing] = useState(false);
    const [lockContinue, setLockContinue] = useState(false);
    const [submitButtonTitle, setSubmitButtonTitle] = useState(SubmitButtonTitles.Default);
    const [currentStep, setCurrentStep] = useState(Steps.InitialStep);
    useBeforeUnload();
    const applicationAlertService = application.alerts;
    const validateCurrentPassword = useCallback(async () => {
        if (!currentPassword || currentPassword.length === 0) {
            applicationAlertService.alert('Please enter your current password.').catch(console.error);
            return false;
        }
        const success = await application.validateAccountPassword(currentPassword);
        if (!success) {
            applicationAlertService
                .alert('The current password you entered is not correct. Please try again.')
                .catch(console.error);
            return false;
        }
        return success;
    }, [application, applicationAlertService, currentPassword]);
    const resetProgressState = () => {
        setSubmitButtonTitle(SubmitButtonTitles.Default);
        setIsContinuing(false);
    };
    const processEmailChange = useCallback(async () => {
        await application.performDesktopTextBackup();
        setLockContinue(true);
        const response = await application.changeEmail(newEmail, currentPassword);
        const success = !response.error;
        setLockContinue(false);
        return success;
    }, [application, currentPassword, newEmail]);
    const dismiss = useCallback(() => {
        if (lockContinue) {
            applicationAlertService.alert('Cannot close window until pending tasks are complete.').catch(console.error);
        }
        else {
            onCloseDialog();
        }
    }, [applicationAlertService, lockContinue, onCloseDialog]);
    const handleSubmit = useCallback(async () => {
        if (lockContinue || isContinuing) {
            return;
        }
        if (currentStep === Steps.FinishStep) {
            dismiss();
            return;
        }
        setIsContinuing(true);
        setSubmitButtonTitle(SubmitButtonTitles.GeneratingKeys);
        const valid = await validateCurrentPassword();
        if (!valid) {
            resetProgressState();
            return;
        }
        const success = await processEmailChange();
        if (!success) {
            resetProgressState();
            return;
        }
        setIsContinuing(false);
        setSubmitButtonTitle(SubmitButtonTitles.Finish);
        setCurrentStep(Steps.FinishStep);
    }, [currentStep, dismiss, isContinuing, lockContinue, processEmailChange, validateCurrentPassword]);
    const handleDialogClose = useCallback(() => {
        if (lockContinue) {
            applicationAlertService.alert('Cannot close window until pending tasks are complete.').catch(console.error);
        }
        else {
            onCloseDialog();
        }
    }, [applicationAlertService, lockContinue, onCloseDialog]);
    const modalActions = useMemo(() => [
        {
            label: 'Cancel',
            onClick: handleDialogClose,
            type: 'cancel',
            mobileSlot: 'left',
        },
        {
            label: submitButtonTitle,
            onClick: handleSubmit,
            type: 'primary',
            mobileSlot: 'right',
        },
    ], [handleDialogClose, handleSubmit, submitButtonTitle]);
    return (_jsx(Modal, { title: "Change Email", close: handleDialogClose, actions: modalActions, children: _jsxs("div", { className: "px-4.5 py-4", children: [currentStep === Steps.InitialStep && (_jsx(ChangeEmailForm, { setNewEmail: setNewEmail, setCurrentPassword: setCurrentPassword })), currentStep === Steps.FinishStep && _jsx(ChangeEmailSuccess, {})] }) }));
};
export default ChangeEmail;
