import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getPositionedPopoverStyles } from '@/Components/Popover/GetPositionedPopoverStyles';
import { $isTextNode, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND, } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getDOMRangeRect } from '../../Lexical/Utils/getDOMRangeRect';
import { classNames } from '@standardnotes/snjs';
import Icon from '@/Components/Icon/Icon';
import StyledTooltip from '@/Components/StyledTooltip/StyledTooltip';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { KeyboardKey } from '@standardnotes/ui-services';
import Button from '@/Components/Button/Button';
import { sanitizeUrl } from '../../Lexical/Utils/sanitizeUrl';
import { useElementResize } from '@/Hooks/useElementRect';
import { createPortal } from 'react-dom';
import { ElementIds } from '@/Constants/ElementIDs';
import { getAdjustedStylesForNonPortalPopover } from '@/Components/Popover/Utils/getAdjustedStylesForNonPortal';
export const $isLinkTextNode = (node, selection) => {
    const parent = node.getParent();
    return ($isLinkNode(parent) &&
        parent.getChildrenSize() === 1 &&
        $isTextNode(node) &&
        parent.getFirstChild() === node &&
        selection.anchor.getNode() === selection.focus.getNode());
};
const LinkEditor = ({ editor, setIsEditingLink, isMobile, linkNode, linkTextNode, }) => {
    var _a;
    const [url, setURL] = useState('');
    const [text, setText] = useState('');
    useEffect(() => {
        editor.getEditorState().read(() => {
            if (linkNode) {
                setURL(linkNode.getURL());
            }
            if (linkTextNode) {
                setText(linkTextNode.getTextContent());
            }
        });
    }, [editor, linkNode, linkTextNode]);
    const linkInputRef = useRef(null);
    const linkEditorRef = useRef(null);
    const rangeRect = useRef();
    const positionUpdateRAF = useRef();
    const updateLinkEditorPosition = useCallback(() => {
        if (positionUpdateRAF.current) {
            cancelAnimationFrame(positionUpdateRAF.current);
        }
        positionUpdateRAF.current = requestAnimationFrame(() => {
            var _a, _b;
            if (isMobile) {
                (_a = linkInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                return;
            }
            const nativeSelection = window.getSelection();
            const rootElement = editor.getRootElement();
            if (nativeSelection !== null && rootElement !== null) {
                if (rootElement.contains(nativeSelection.anchorNode)) {
                    rangeRect.current = getDOMRangeRect(nativeSelection, rootElement);
                }
            }
            const linkEditorElement = linkEditorRef.current;
            if (!linkEditorElement) {
                setTimeout(updateLinkEditorPosition);
                return;
            }
            if (!rootElement) {
                return;
            }
            if (!rangeRect.current) {
                return;
            }
            const linkEditorRect = linkEditorElement.getBoundingClientRect();
            const rootElementRect = rootElement.getBoundingClientRect();
            const calculatedStyles = getPositionedPopoverStyles({
                align: 'center',
                side: 'top',
                anchorRect: rangeRect.current,
                popoverRect: linkEditorRect,
                documentRect: rootElementRect,
                offset: 12,
                maxHeightFunction: () => 'none',
            });
            if (calculatedStyles) {
                const adjustedStyles = getAdjustedStylesForNonPortalPopover(linkEditorElement, calculatedStyles);
                Object.entries(adjustedStyles).forEach(([key, value]) => {
                    linkEditorElement.style.setProperty(key, value);
                });
                linkEditorElement.style.display = 'block';
                (_b = linkInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            }
        });
    }, [editor, isMobile]);
    useElementResize(linkEditorRef.current, updateLinkEditorPosition);
    useEffect(() => {
        updateLinkEditorPosition();
        return mergeRegister(editor.registerUpdateListener(() => {
            updateLinkEditorPosition();
        }), editor.registerCommand(SELECTION_CHANGE_COMMAND, (_payload) => {
            updateLinkEditorPosition();
            return false;
        }, COMMAND_PRIORITY_LOW));
    }, [editor, updateLinkEditorPosition]);
    const handleSubmission = () => {
        if (url !== '') {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(url));
        }
        if (linkTextNode !== null && text !== '') {
            editor.update(() => {
                linkTextNode.setTextContent(text);
            }, {
                discrete: true,
            });
        }
        setIsEditingLink(false);
    };
    useEffect(() => {
        const linkEditor = linkEditorRef.current;
        if (!linkEditor) {
            return;
        }
        const handleFocusOut = (event) => {
            if (!linkEditor.contains(event.relatedTarget)) {
                setIsEditingLink(false);
            }
        };
        linkEditor.addEventListener('focusout', handleFocusOut);
        return () => {
            linkEditor.removeEventListener('focusout', handleFocusOut);
        };
    }, [setIsEditingLink]);
    return createPortal(_jsx("div", { className: classNames('absolute z-dropdown-menu rounded-lg border border-border bg-contrast px-2 py-1 shadow-sm shadow-contrast', isMobile
            ? 'bottom-12 left-1/2 w-[calc(100%_-_1rem)] -translate-x-1/2'
            : 'left-0 top-0 hidden w-auto translate-x-0 translucent-ui:border-[--popover-border-color] translucent-ui:bg-[--popover-background-color] translucent-ui:[backdrop-filter:var(--popover-backdrop-filter)]'), ref: linkEditorRef, children: _jsxs("div", { className: "flex flex-col gap-2 py-1", children: [linkTextNode && (_jsxs("div", { className: "flex items-center gap-1.5", children: [_jsx(Icon, { type: "plain-text", className: "flex-shrink-0" }), _jsx("input", { value: text, onChange: (event) => {
                                setText(event.target.value);
                            }, onKeyDown: (event) => {
                                if (event.key === KeyboardKey.Enter) {
                                    event.preventDefault();
                                    handleSubmission();
                                }
                                else if (event.key === KeyboardKey.Escape) {
                                    event.preventDefault();
                                    setIsEditingLink(false);
                                }
                            }, className: "flex-grow rounded-sm border border-border bg-contrast p-1 text-text sm:min-w-[20ch] translucent-ui:md:border-0" })] })), _jsxs("div", { className: "flex items-center gap-1.5", children: [_jsx(Icon, { type: "link", className: "flex-shrink-0" }), _jsx("input", { ref: linkInputRef, value: url, onChange: (event) => {
                                setURL(event.target.value);
                            }, onKeyDown: (event) => {
                                if (event.key === KeyboardKey.Enter) {
                                    event.preventDefault();
                                    handleSubmission();
                                }
                                else if (event.key === KeyboardKey.Escape) {
                                    event.preventDefault();
                                    setIsEditingLink(false);
                                }
                            }, className: "flex-grow rounded-sm border border-border bg-contrast p-1 text-text sm:min-w-[40ch] translucent-ui:md:border-0" })] }), _jsxs("div", { className: "flex items-center justify-end gap-1.5", children: [_jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Cancel editing", children: _jsx(Button, { onClick: () => {
                                    setIsEditingLink(false);
                                    editor.focus();
                                }, onMouseDown: (event) => event.preventDefault(), children: "Cancel" }) }), _jsx(StyledTooltip, { showOnMobile: true, showOnHover: true, label: "Save link", children: _jsx(Button, { primary: true, onClick: handleSubmission, onMouseDown: (event) => event.preventDefault(), children: "Apply" }) })] })] }) }), (_a = document.getElementById(ElementIds.SuperEditor)) !== null && _a !== void 0 ? _a : document.body);
};
export default LinkEditor;
