import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery, MutuallyExclusiveMediaQueryBreakpoints } from '@/Hooks/useMediaQuery';
import { useAvailableSafeAreaPadding } from '@/Hooks/useSafeAreaPadding';
import { classNames } from '@standardnotes/snjs';
import { useMemo, useRef, useState } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Popover from '../Popover/Popover';
import MobileModalAction from './MobileModalAction';
import MobileModalHeader from './MobileModalHeader';
import ModalAndroidBackHandler from './ModalAndroidBackHandler';
const Modal = ({ title, close, actions = [], className, customHeader, disableCustomHeader = false, customFooter, children, }) => {
    const sortedActions = useMemo(() => actions
        .sort((a, b) => {
        if (a.type === 'cancel') {
            return -1;
        }
        if (b.type === 'cancel') {
            return 1;
        }
        if (a.type === 'destructive') {
            return -1;
        }
        if (b.type === 'destructive') {
            return 1;
        }
        if (a.type === 'secondary') {
            return -1;
        }
        if (b.type === 'secondary') {
            return 1;
        }
        return 0;
    })
        .filter((action) => !action.hidden), [actions]);
    const primaryActions = sortedActions.filter((action) => action.type === 'primary');
    if (primaryActions.length > 1) {
        throw new Error('Modal can only have 1 primary action');
    }
    const cancelActions = sortedActions.filter((action) => action.type === 'cancel');
    if (cancelActions.length > 1) {
        throw new Error('Modal can only have 1 cancel action');
    }
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const leftSlotAction = sortedActions.find((action) => action.mobileSlot === 'left');
    const rightSlotAction = sortedActions.find((action) => action.mobileSlot === 'right');
    const firstPrimaryActionIndex = sortedActions.findIndex((action) => action.type === 'primary');
    const extraActions = sortedActions.filter((action) => action.type !== 'primary' && action.type !== 'cancel');
    const [showAdvanced, setShowAdvanced] = useState(false);
    const advancedOptionRef = useRef(null);
    const { hasTopInset, hasBottomInset } = useAvailableSafeAreaPadding();
    return (_jsxs(_Fragment, { children: [_jsx(ModalAndroidBackHandler, { close: close }), customHeader && !disableCustomHeader ? (customHeader) : (_jsx("div", { className: classNames('flex w-full flex-shrink-0 select-none items-center justify-between rounded-t border-b border-solid border-border bg-default px-2 text-text md:px-4.5 md:py-3 md:translucent-ui:bg-transparent', hasTopInset ? 'pb-1.5 pt-safe-top' : 'py-1.5'), children: _jsxs(MobileModalHeader, { className: "flex-row items-center justify-between md:flex md:gap-0", children: [leftSlotAction ? (_jsx(MobileModalAction, { type: leftSlotAction.type, action: leftSlotAction.onClick, disabled: leftSlotAction.disabled, slot: "left", children: leftSlotAction.label })) : (_jsx("div", { className: "md:hidden" })), _jsxs("div", { className: "flex items-center justify-center gap-2 overflow-hidden text-center font-semibold text-text md:flex-grow md:text-left md:text-lg", children: [extraActions.length > 0 && (_jsxs(_Fragment, { children: [_jsx(MobileModalAction, { type: "secondary", action: () => setShowAdvanced((show) => !show), slot: "left", ref: advancedOptionRef, children: _jsx("div", { className: "rounded-full border border-border p-0.5", children: _jsx(Icon, { type: "more" }) }) }), _jsx(Popover, { title: "Advanced", open: showAdvanced, anchorElement: advancedOptionRef, disableMobileFullscreenTakeover: true, togglePopover: () => setShowAdvanced((show) => !show), align: "start", portal: false, className: "!fixed divide-y divide-border border border-border", children: extraActions
                                                .filter((action) => action.type !== 'cancel')
                                                .map((action, index) => (_jsx("button", { className: classNames('p-2 text-base font-semibold hover:bg-contrast focus:bg-info-backdrop focus:shadow-none focus:outline-none', action.type === 'destructive' && 'text-danger'), onClick: () => {
                                                    action.onClick();
                                                    setShowAdvanced(false);
                                                }, disabled: action.disabled, children: action.label }, index))) })] })), _jsx("span", { className: "overflow-hidden text-ellipsis whitespace-nowrap ", children: title })] }), _jsx("div", { className: "hidden items-center gap-2 md:flex", children: _jsx("button", { tabIndex: 0, className: "ml-2 rounded p-1 font-bold hover:bg-contrast", onClick: close, children: _jsx(Icon, { type: "close" }) }) }), rightSlotAction ? (_jsx(MobileModalAction, { type: rightSlotAction.type, action: rightSlotAction.onClick, disabled: rightSlotAction.disabled, slot: "right", children: rightSlotAction.label })) : null] }) })), _jsx("div", { className: classNames('flex-grow overflow-y-auto', className), children: children }), customFooter
                ? customFooter
                : sortedActions.length > 0 && (_jsx("div", { className: classNames('hidden items-center justify-start gap-3 border-t border-border px-2.5 py-2 md:flex md:px-4 md:py-4', hasBottomInset && 'pb-safe-bottom'), children: sortedActions.map((action, index) => (_jsx(Button, { primary: action.type === 'primary', colorStyle: action.type === 'destructive' ? 'danger' : undefined, onClick: action.onClick, className: classNames(action.mobileSlot ? 'hidden md:block' : '', index === firstPrimaryActionIndex && 'ml-auto'), "data-type": action.type, disabled: action.disabled, small: isMobileScreen, children: action.label }, index))) }))] }));
};
export default Modal;
