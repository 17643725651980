"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaOfflineSaved = void 0;
const Payload_1 = require("../../Abstract/Payload");
const ApplyDirtyState_1 = require("./Utilities/ApplyDirtyState");
class DeltaOfflineSaved {
    constructor(baseCollection, applyContextualPayloads) {
        this.baseCollection = baseCollection;
        this.applyContextualPayloads = applyContextualPayloads;
    }
    result() {
        const processed = [];
        for (const apply of this.applyContextualPayloads) {
            const base = this.baseCollection.find(apply.uuid);
            if (!base) {
                continue;
            }
            processed.push((0, ApplyDirtyState_1.payloadByFinalizingSyncState)(base, this.baseCollection));
        }
        return {
            emits: processed,
            source: Payload_1.PayloadEmitSource.OfflineSyncSaved,
        };
    }
}
exports.DeltaOfflineSaved = DeltaOfflineSaved;
