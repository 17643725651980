import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { PrefKey } from '@standardnotes/snjs';
import { useApplication } from '../ApplicationProvider';
import Modal from '../Modal/Modal';
import usePreference from '@/Hooks/usePreference';
import { useEffect } from 'react';
import Switch from '../Switch/Switch';
import { noteHasEmbeddedFiles } from '@/Utils/NoteExportUtils';
import Dropdown from '../Dropdown/Dropdown';
const SuperExportModal = ({ notes, exportNotes, close }) => {
    const application = useApplication();
    const superNoteExportFormat = usePreference(PrefKey.SuperNoteExportFormat);
    const superNoteExportEmbedBehavior = usePreference(PrefKey.SuperNoteExportEmbedBehavior);
    const superNoteExportUseMDFrontmatter = usePreference(PrefKey.SuperNoteExportUseMDFrontmatter);
    const superNoteExportPDFPageSize = usePreference(PrefKey.SuperNoteExportPDFPageSize);
    useEffect(() => {
        if (superNoteExportFormat === 'json' && superNoteExportEmbedBehavior === 'separate') {
            void application.setPreference(PrefKey.SuperNoteExportEmbedBehavior, 'reference');
        }
        if (superNoteExportFormat === 'md' && superNoteExportEmbedBehavior === 'reference') {
            void application.setPreference(PrefKey.SuperNoteExportEmbedBehavior, 'separate');
        }
        if (superNoteExportFormat === 'pdf' && superNoteExportEmbedBehavior !== 'inline') {
            void application.setPreference(PrefKey.SuperNoteExportEmbedBehavior, 'inline');
        }
    }, [application, superNoteExportEmbedBehavior, superNoteExportFormat]);
    const someNotesHaveEmbeddedFiles = notes.some(noteHasEmbeddedFiles);
    const canShowEmbeddedFileOptions = !['json', 'pdf'].includes(superNoteExportFormat);
    return (_jsxs(Modal, { title: "Export notes", className: "p-4", close: close, actions: [
            {
                label: 'Cancel',
                type: 'cancel',
                onClick: close,
                mobileSlot: 'left',
            },
            {
                label: 'Export',
                type: 'primary',
                onClick: () => {
                    close();
                    exportNotes();
                },
                mobileSlot: 'right',
            },
        ], children: [_jsxs("div", { className: "mb-2", children: [_jsxs("div", { className: "mb-2 flex flex-wrap items-center justify-between gap-2", children: [_jsxs("div", { className: "text-base", children: ["Choose export format ", notes.length > 1 ? 'for Super notes' : ''] }), _jsx(Dropdown, { label: "Export format", items: [
                                    { label: 'Super (.json)', value: 'json' },
                                    { label: 'Markdown (.md)', value: 'md' },
                                    { label: 'HTML', value: 'html' },
                                    { label: 'PDF', value: 'pdf' },
                                ], value: superNoteExportFormat, onChange: (value) => {
                                    void application.setPreference(PrefKey.SuperNoteExportFormat, value);
                                }, classNameOverride: {
                                    wrapper: 'w-full md:w-fit',
                                } })] }), superNoteExportFormat === 'md' && (_jsx("div", { className: "mt-2 text-xs text-passive-0", children: "Note that conversion to Markdown is not lossless. Some features like collapsible blocks and formatting like superscript/subscript may not be correctly converted." }))] }), superNoteExportFormat === 'pdf' && (_jsxs("div", { className: "mt-4 flex flex-wrap items-center justify-between gap-2", children: [_jsx("div", { className: "text-base", children: "Page size" }), _jsx(Dropdown, { label: "Page size", items: [
                            { label: 'A3', value: 'A3' },
                            { label: 'A4', value: 'A4' },
                            { label: 'Letter', value: 'LETTER' },
                            { label: 'Legal', value: 'LEGAL' },
                            { label: 'Tabloid', value: 'TABLOID' },
                        ], value: superNoteExportPDFPageSize, onChange: (value) => {
                            void application.setPreference(PrefKey.SuperNoteExportPDFPageSize, value);
                        }, classNameOverride: {
                            wrapper: 'w-full md:w-fit',
                        } })] })), superNoteExportFormat === 'md' && (_jsx("div", { className: "mt-4", children: _jsx(Switch, { checked: superNoteExportUseMDFrontmatter, onChange: (checked) => {
                        void application.setPreference(PrefKey.SuperNoteExportUseMDFrontmatter, checked);
                    }, className: "!flex items-center", children: _jsx("span", { className: "ml-2", children: "Export with frontmatter" }) }) })), canShowEmbeddedFileOptions && someNotesHaveEmbeddedFiles && (_jsxs("div", { className: "mb-2 mt-4 flex flex-wrap items-center justify-between gap-2", children: [_jsx("div", { className: "text-base", children: "Embedded files" }), _jsx(Dropdown, { label: "Embedded files", items: [
                            { label: 'Inline', value: 'inline' },
                            { label: 'Export separately', value: 'separate' },
                        ].concat(superNoteExportFormat !== 'md' ? [{ label: 'Keep as reference', value: 'reference' }] : []), value: superNoteExportEmbedBehavior, onChange: (value) => {
                            void application.setPreference(PrefKey.SuperNoteExportEmbedBehavior, value);
                        } })] }))] }));
};
export default SuperExportModal;
