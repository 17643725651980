"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isErrorDecryptingPayload = exports.isDeletedPayload = exports.isEncryptedPayload = exports.isDecryptedPayload = void 0;
const TransferPayload_1 = require("../../TransferPayload");
function isDecryptedPayload(payload) {
    return (0, TransferPayload_1.isDecryptedTransferPayload)(payload);
}
exports.isDecryptedPayload = isDecryptedPayload;
function isEncryptedPayload(payload) {
    return (0, TransferPayload_1.isEncryptedTransferPayload)(payload);
}
exports.isEncryptedPayload = isEncryptedPayload;
function isDeletedPayload(payload) {
    return (0, TransferPayload_1.isDeletedTransferPayload)(payload);
}
exports.isDeletedPayload = isDeletedPayload;
function isErrorDecryptingPayload(payload) {
    return (0, TransferPayload_1.isErrorDecryptingTransferPayload)(payload);
}
exports.isErrorDecryptingPayload = isErrorDecryptingPayload;
