import { jsx as _jsx } from "react/jsx-runtime";
const TabPanel = ({ state, id, children, ...props }) => {
    const { activeTab } = state;
    const isActive = activeTab === id;
    if (!isActive) {
        return null;
    }
    return (_jsx("div", { role: "tabpanel", id: `tab-panel-${id}`, "aria-labelledby": `tab-control-${id}`, ...props, children: children }));
};
export default TabPanel;
