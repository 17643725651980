import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo, useState } from 'react';
import { useListKeyboardNavigation } from '@/Hooks/useListKeyboardNavigation';
import HistoryListItem from './HistoryListItem';
import { observer } from 'mobx-react-lite';
const SessionHistoryList = ({ noteHistoryController, onSelectRevision }) => {
    const { sessionHistory, selectedRevision, selectSessionRevision } = noteHistoryController;
    const [listElement, setListElement] = useState(null);
    useListKeyboardNavigation(listElement);
    const sessionHistoryLength = useMemo(() => sessionHistory === null || sessionHistory === void 0 ? void 0 : sessionHistory.map((group) => group.entries).flat().length, [sessionHistory]);
    return (_jsxs("div", { className: `flex h-full w-full flex-col focus:shadow-none ${!sessionHistoryLength ? 'items-center justify-center' : ''}`, ref: setListElement, children: [sessionHistory === null || sessionHistory === void 0 ? void 0 : sessionHistory.map((group) => {
                if (group.entries && group.entries.length) {
                    return (_jsxs(Fragment, { children: [_jsx("div", { className: "mb-1 mt-2.5 select-none px-3 text-sm font-semibold uppercase text-passive-0", children: group.title }), group.entries.map((entry, index) => (_jsx(HistoryListItem, { isSelected: (selectedRevision === null || selectedRevision === void 0 ? void 0 : selectedRevision.payload.updated_at) === entry.payload.updated_at, onClick: () => {
                                    selectSessionRevision(entry);
                                    onSelectRevision();
                                }, children: entry.previewTitle() }, index)))] }, group.title));
                }
                else {
                    return null;
                }
            }), !sessionHistoryLength && _jsx("div", { className: "select-none text-sm text-passive-0", children: "No session history found" })] }));
};
export default observer(SessionHistoryList);
