import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ComponentAction, FeatureStatus, ComponentViewerEvent, ComponentViewerError, SubscriptionManagerEvent, } from '@standardnotes/snjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import OfflineRestricted from '@/Components/ComponentView/OfflineRestricted';
import UrlMissing from '@/Components/ComponentView/UrlMissing';
import IsDeprecated from '@/Components/ComponentView/IsDeprecated';
import NotEntitledBanner from '@/Components/ComponentView/NotEntitledBanner';
import IssueOnLoading from '@/Components/ComponentView/IssueOnLoading';
import { useApplication } from '../ApplicationProvider';
/**
 * The maximum amount of time we'll wait for a component
 * to load before displaying error
 */
const MaxLoadThreshold = 4000;
const VisibilityChangeKey = 'visibilitychange';
const MSToWaitAfterIframeLoadToAvoidFlicker = 35;
const IframeFeatureView = ({ onLoad, componentViewer, requestReload, readonly = false, usedInModal = false, }) => {
    const application = useApplication();
    const iframeRef = useRef(null);
    const [loadTimeout, setLoadTimeout] = useState(undefined);
    const [hasIssueLoading, setHasIssueLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [featureStatus, setFeatureStatus] = useState(componentViewer.getFeatureStatus());
    const [isComponentValid, setIsComponentValid] = useState(true);
    const [error, setError] = useState(undefined);
    const [deprecationMessage, setDeprecationMessage] = useState(undefined);
    const [isDeprecationMessageDismissed, setIsDeprecationMessageDismissed] = useState(false);
    const [didAttemptReload, setDidAttemptReload] = useState(false);
    const uiFeature = componentViewer.getComponentOrFeatureItem();
    const reloadValidityStatus = useCallback(() => {
        setFeatureStatus(componentViewer.getFeatureStatus());
        if (!componentViewer.lockReadonly) {
            componentViewer.setReadonly(featureStatus !== FeatureStatus.Entitled || readonly);
        }
        setIsComponentValid(componentViewer.shouldRender());
        if (isLoading && !isComponentValid) {
            setIsLoading(false);
        }
        setError(componentViewer.getError());
        setDeprecationMessage(uiFeature.deprecationMessage);
    }, [componentViewer, isLoading, isComponentValid, uiFeature.deprecationMessage, featureStatus, readonly]);
    useEffect(() => {
        reloadValidityStatus();
    }, [reloadValidityStatus]);
    useEffect(() => {
        return application.subscriptions.addEventObserver((event) => {
            if (event === SubscriptionManagerEvent.DidFetchSubscription) {
                reloadValidityStatus();
            }
        });
    }, [application.subscriptions, reloadValidityStatus]);
    const dismissDeprecationMessage = () => {
        setIsDeprecationMessageDismissed(true);
    };
    const onVisibilityChange = useCallback(() => {
        if (document.visibilityState === 'hidden') {
            return;
        }
        if (hasIssueLoading) {
            requestReload === null || requestReload === void 0 ? void 0 : requestReload(componentViewer);
        }
    }, [hasIssueLoading, componentViewer, requestReload]);
    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setIsLoading(false);
            setHasIssueLoading(true);
            if (!didAttemptReload) {
                setDidAttemptReload(true);
                requestReload === null || requestReload === void 0 ? void 0 : requestReload(componentViewer);
            }
            else {
                document.addEventListener(VisibilityChangeKey, onVisibilityChange);
            }
        }, MaxLoadThreshold);
        setLoadTimeout(loadTimeout);
        return () => {
            if (loadTimeout) {
                clearTimeout(loadTimeout);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentViewer]);
    const onIframeLoad = useCallback(() => {
        const iframe = iframeRef.current;
        const contentWindow = iframe.contentWindow;
        if (loadTimeout) {
            clearTimeout(loadTimeout);
        }
        try {
            componentViewer.setWindow(contentWindow);
        }
        catch (error) {
            console.error(error);
        }
        setTimeout(() => {
            setIsLoading(false);
            setHasIssueLoading(false);
            onLoad === null || onLoad === void 0 ? void 0 : onLoad();
        }, MSToWaitAfterIframeLoadToAvoidFlicker);
    }, [componentViewer, onLoad, loadTimeout]);
    useEffect(() => {
        const removeFeaturesChangedObserver = componentViewer.addEventObserver((event) => {
            if (event === ComponentViewerEvent.FeatureStatusUpdated) {
                setFeatureStatus(componentViewer.getFeatureStatus());
            }
        });
        return () => {
            removeFeaturesChangedObserver();
        };
    }, [componentViewer]);
    useEffect(() => {
        const removeActionObserver = componentViewer.addActionObserver((action, data) => {
            switch (action) {
                case ComponentAction.KeyDown:
                    application.keyboardService.handleComponentKeyDown(data.keyboardModifier);
                    break;
                case ComponentAction.KeyUp:
                    application.keyboardService.handleComponentKeyUp(data.keyboardModifier);
                    break;
                case ComponentAction.Click:
                    application.notesController.setContextMenuOpen(false);
                    break;
                default:
                    return;
            }
        });
        return () => {
            removeActionObserver();
        };
    }, [componentViewer, application]);
    useEffect(() => {
        var _a;
        const unregisterDesktopObserver = (_a = application.desktopManager) === null || _a === void 0 ? void 0 : _a.registerUpdateObserver((updatedComponent) => {
            if (updatedComponent.uuid === uiFeature.uniqueIdentifier.value) {
                requestReload === null || requestReload === void 0 ? void 0 : requestReload(componentViewer);
            }
        });
        return () => {
            unregisterDesktopObserver === null || unregisterDesktopObserver === void 0 ? void 0 : unregisterDesktopObserver();
        };
    }, [application, requestReload, componentViewer, uiFeature]);
    const sandboxAttributes = useMemo(() => {
        const attributes = [
            'allow-scripts',
            'allow-top-navigation-by-user-activation',
            'allow-popups',
            'allow-modals',
            'allow-forms',
            'allow-downloads',
        ];
        if (uiFeature.isNativeFeature) {
            attributes.push('allow-popups-to-escape-sandbox');
        }
        if (application.isNativeMobileWeb()) {
            /**
             * Native mobile web serves native components through the file:// protocol.
             * Native mobile web also does not use localStorage, unlike the web app.
             * So, components served through the file:// (precompiled editors) will be treated
             * as same origin as the parent app, but will not have meaningful access.
             *
             * Third party components will have a non-file:// origin, and thus don't need this attribute.
             */
            if (uiFeature.isNativeFeature) {
                attributes.push('allow-same-origin');
            }
        }
        return attributes;
    }, [application, uiFeature]);
    return (_jsxs(_Fragment, { children: [hasIssueLoading && (_jsx(IssueOnLoading, { componentName: uiFeature.displayName, reloadIframe: () => {
                    reloadValidityStatus(), requestReload === null || requestReload === void 0 ? void 0 : requestReload(componentViewer, true);
                } })), featureStatus !== FeatureStatus.Entitled && (_jsx(NotEntitledBanner, { featureStatus: featureStatus, feature: uiFeature.featureDescription })), deprecationMessage && !isDeprecationMessageDismissed && (_jsx(IsDeprecated, { deprecationMessage: deprecationMessage, dismissDeprecationMessage: dismissDeprecationMessage })), error === ComponentViewerError.OfflineRestricted && _jsx(OfflineRestricted, {}), error === ComponentViewerError.MissingUrl && _jsx(UrlMissing, { componentName: uiFeature.displayName }), uiFeature.uniqueIdentifier && isComponentValid && (_jsx("iframe", { className: "h-full w-full flex-grow bg-transparent", ref: iframeRef, onLoad: onIframeLoad, "data-component-viewer-id": componentViewer.identifier, frameBorder: 0, src: componentViewer.url || '', sandbox: sandboxAttributes.join(' '), ...(usedInModal && { 'data-used-in-modal': true }), children: "Loading" })), isLoading && _jsx("div", { className: 'loading-overlay' })] }));
};
export default observer(IframeFeatureView);
