import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
import Icon from '@/Components/Icon/Icon';
const U2FDevicesList = ({ application, devices, onError, onDeviceDeleted }) => {
    const handleDeleteButtonOnClick = useCallback(async (authenticatorId) => {
        const deleteAuthenticatorOrError = await application.deleteAuthenticator.execute({
            authenticatorId,
        });
        if (deleteAuthenticatorOrError.isFailed()) {
            onError(deleteAuthenticatorOrError.getError());
            return;
        }
        await onDeviceDeleted();
    }, [application, onDeviceDeleted, onError]);
    return (_jsx("div", { children: devices.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Subtitle, { children: "Devices" }), _jsx("div", { className: "flex flex-grow flex-col divide-y divide-border", children: devices.map((device) => (_jsxs("div", { className: "flex items-center py-2", children: [_jsx(Icon, { type: "security" }), _jsx("div", { className: "ml-2 mr-auto text-sm", children: device.name }), _jsx(Button, { small: true, label: "Delete", onClick: async () => handleDeleteButtonOnClick(device.id) }, device.id)] }, `device-${device.id}`))) })] })) }));
};
export default observer(U2FDevicesList);
