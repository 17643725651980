import { jsx as _jsx } from "react/jsx-runtime";
const baseClassNames = 'border border-solid w-3 h-3 p-0 rounded-full flex-shrink-0';
const IndicatorCircle = ({ style }) => {
    switch (style) {
        case 'neutral':
            return _jsx("div", { className: `${baseClassNames} border-neutral bg-neutral` });
        case 'info':
            return _jsx("div", { className: `${baseClassNames} border-info bg-info` });
        case 'danger':
            return _jsx("div", { className: `${baseClassNames} border-danger bg-danger` });
    }
};
export default IndicatorCircle;
