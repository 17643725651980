import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { useApplication } from '../ApplicationProvider';
import { FeatureName } from '@/Controllers/FeatureName';
import { NativeFeatureIdentifier, FeatureStatus } from '@standardnotes/snjs';
const ImportModalInitialPage = ({ setFiles, selectFiles }) => {
    const application = useApplication();
    return (_jsxs(_Fragment, { children: [_jsxs("button", { onClick: () => selectFiles(), className: "flex min-h-[30vh] w-full flex-col items-center justify-center gap-2 rounded border-2 border-dashed border-info p-2 hover:border-4", onDragStart: (e) => e.preventDefault(), onDragOver: (e) => e.preventDefault(), onDrop: (e) => {
                    e.preventDefault();
                    const files = Array.from(e.dataTransfer.files);
                    setFiles(files);
                }, children: [_jsx("div", { className: "text-lg font-semibold", children: "Drag and drop files to auto-detect and import" }), _jsx("div", { className: "text-sm", children: "Or click to open file picker" })] }), _jsx("div", { className: "my-4 w-full text-center", children: "or import from:" }), _jsxs("div", { className: "flex flex-wrap items-center justify-center gap-4", children: [_jsxs(Button, { className: "flex items-center !py-2", onClick: () => selectFiles('evernote'), children: [_jsx(Icon, { type: "evernote", className: "mr-2 text-[#14cc45]" }), "Evernote"] }), _jsxs(Button, { className: "flex items-center !py-2", onClick: () => selectFiles('google-keep'), children: [_jsx(Icon, { type: "gkeep", className: "mr-2 text-[#fbbd00]" }), "Google Keep"] }), _jsxs(Button, { className: "flex items-center !py-2", onClick: () => selectFiles('simplenote'), children: [_jsx(Icon, { type: "simplenote", className: "mr-2 text-[#3360cc]" }), "Simplenote"] }), _jsxs(Button, { className: "flex items-center !py-2", onClick: () => selectFiles('aegis'), children: [_jsx(Icon, { type: "aegis", className: "mr-2 rounded bg-[#0d47a1] p-1 text-[#fff]", size: "normal" }), "Aegis"] }), _jsxs(Button, { className: "flex items-center !py-2", onClick: () => selectFiles('plaintext'), children: [_jsx(Icon, { type: "plain-text", className: "mr-2 text-info" }), "Plaintext / Markdown"] }), _jsxs(Button, { className: "flex items-center !py-2", onClick: () => selectFiles('html'), children: [_jsx(Icon, { type: "rich-text", className: "mr-2 text-accessory-tint-2" }), "HTML"] }), _jsxs(Button, { className: "flex items-center !py-2", onClick: () => {
                            const isEntitledToSuper = application.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.SuperEditor).getValue()) === FeatureStatus.Entitled;
                            if (!isEntitledToSuper) {
                                application.showPremiumModal(FeatureName.Super);
                                return;
                            }
                            selectFiles('super').catch(console.error);
                        }, children: [_jsx(Icon, { type: "file-doc", className: "mr-2 text-accessory-tint-1" }), "Super (JSON)"] })] })] }));
};
export default observer(ImportModalInitialPage);
