"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptedPayload = void 0;
const ProtocolVersionFromEncryptedString_1 = require("../../../Local/Protocol/ProtocolVersionFromEncryptedString");
const PayloadSource_1 = require("../Types/PayloadSource");
const PurePayload_1 = require("./PurePayload");
class EncryptedPayload extends PurePayload_1.PurePayload {
    constructor(rawPayload, source = PayloadSource_1.PayloadSource.Constructor) {
        super(rawPayload, source);
        const versionResult = (0, ProtocolVersionFromEncryptedString_1.ProtocolVersionFromEncryptedString)(rawPayload.content);
        if (versionResult.isFailed()) {
            throw new Error('EncryptedPayload constructor versionResult is failed');
        }
        this.auth_hash = rawPayload.auth_hash;
        this.content = rawPayload.content;
        this.deleted = false;
        this.enc_item_key = rawPayload.enc_item_key;
        this.errorDecrypting = rawPayload.errorDecrypting;
        this.items_key_id = rawPayload.items_key_id;
        this.version = versionResult.getValue();
        this.waitingForKey = rawPayload.waitingForKey;
    }
    ejected() {
        return Object.assign(Object.assign({}, super.ejected()), { enc_item_key: this.enc_item_key, items_key_id: this.items_key_id, auth_hash: this.auth_hash, errorDecrypting: this.errorDecrypting, waitingForKey: this.waitingForKey, content: this.content, deleted: this.deleted });
    }
    copy(override, source = this.source) {
        const result = new EncryptedPayload(Object.assign(Object.assign({}, this.ejected()), override), source);
        return result;
    }
    copyAsSyncResolved(override, source = this.source) {
        const result = new EncryptedPayload(Object.assign(Object.assign({}, this.ejected()), override), source);
        return result;
    }
}
exports.EncryptedPayload = EncryptedPayload;
