import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { useApplication } from '@/Components/ApplicationProvider';
const U2FTitle = () => {
    const application = useApplication();
    if (application.sessions.getUser() === undefined) {
        return _jsx(Title, { children: "Hardware security key authentication not available" });
    }
    return _jsx(Title, { children: "Hardware security key authentication" });
};
export default observer(U2FTitle);
