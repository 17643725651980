"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImmutablePayloadCollection = void 0;
const PayloadCollection_1 = require("./PayloadCollection");
class ImmutablePayloadCollection extends PayloadCollection_1.PayloadCollection {
    get payloads() {
        return this.all();
    }
    /** We don't use a constructor for this because we don't want the constructor to have
     * side-effects, such as calling collection.set(). */
    static WithPayloads(payloads = []) {
        const collection = new ImmutablePayloadCollection();
        if (payloads.length > 0) {
            collection.set(payloads);
        }
        Object.freeze(collection);
        return collection;
    }
    static FromCollection(collection) {
        const mapCopy = Object.freeze(Object.assign({}, collection.map));
        const typedMapCopy = Object.freeze(Object.assign({}, collection.typedMap));
        const referenceMapCopy = Object.freeze(collection.referenceMap.makeCopy());
        const conflictMapCopy = Object.freeze(collection.conflictMap.makeCopy());
        const result = new ImmutablePayloadCollection(true, mapCopy, typedMapCopy, referenceMapCopy, conflictMapCopy);
        Object.freeze(result);
        return result;
    }
    mutableCopy() {
        const mapCopy = Object.assign({}, this.map);
        const typedMapCopy = Object.assign({}, this.typedMap);
        const referenceMapCopy = this.referenceMap.makeCopy();
        const conflictMapCopy = this.conflictMap.makeCopy();
        const result = new PayloadCollection_1.PayloadCollection(true, mapCopy, typedMapCopy, referenceMapCopy, conflictMapCopy);
        return result;
    }
}
exports.ImmutablePayloadCollection = ImmutablePayloadCollection;
