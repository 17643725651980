import { IgnoreScopes } from './IgnoreScopes';
function removeAnythingInParentheses(line) {
    return line.replace(/\(.*\)/g, '');
}
function capitalizeFirstLetter(line) {
    return line.charAt(0).toUpperCase() + line.slice(1);
}
export function formatChangelogLine(line) {
    let result = capitalizeFirstLetter(line);
    result = removeAnythingInParentheses(result);
    return result;
}
function lineHasIgnoredScope(line) {
    return IgnoreScopes.some((scope) => line.toLowerCase().includes(scope.toLowerCase()));
}
function lineHasOnlyOneWord(line) {
    return line.trim().split(' ').length === 1;
}
export function getSectionItems(version, sectionKey) {
    const section = version.parsed[sectionKey];
    if (!section) {
        return undefined;
    }
    const filtered = section.map(formatChangelogLine).filter((item) => {
        return !lineHasIgnoredScope(item) && !lineHasOnlyOneWord(item);
    });
    if (filtered.length === 0) {
        return undefined;
    }
    return filtered;
}
