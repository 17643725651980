"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEncryptedErroredItem = exports.isDecryptedOrDeletedItem = exports.isDeletedItem = exports.isNotEncryptedItem = exports.isEncryptedItem = exports.isDecryptedItem = void 0;
const TypeCheck_1 = require("../../Payload/Interfaces/TypeCheck");
function isDecryptedItem(item) {
    return 'payload' in item && (0, TypeCheck_1.isDecryptedPayload)(item.payload);
}
exports.isDecryptedItem = isDecryptedItem;
function isEncryptedItem(item) {
    return (0, TypeCheck_1.isEncryptedPayload)(item.payload);
}
exports.isEncryptedItem = isEncryptedItem;
function isNotEncryptedItem(item) {
    return !isEncryptedItem(item);
}
exports.isNotEncryptedItem = isNotEncryptedItem;
function isDeletedItem(item) {
    return (0, TypeCheck_1.isDeletedPayload)(item.payload);
}
exports.isDeletedItem = isDeletedItem;
function isDecryptedOrDeletedItem(item) {
    return isDecryptedItem(item) || isDeletedItem(item);
}
exports.isDecryptedOrDeletedItem = isDecryptedOrDeletedItem;
function isEncryptedErroredItem(item) {
    return isEncryptedItem(item) && item.errorDecrypting === true;
}
exports.isEncryptedErroredItem = isEncryptedErroredItem;
