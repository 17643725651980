"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemCollection = void 0;
const TypeCheck_1 = require("../../../Abstract/Item/Interfaces/TypeCheck");
const Collection_1 = require("../Collection");
class ItemCollection extends Collection_1.Collection {
    onChange(delta) {
        const changedOrInserted = delta.changed.concat(delta.inserted);
        if (changedOrInserted.length > 0) {
            this.set(changedOrInserted);
        }
        this.discard(delta.discarded);
    }
    findDecrypted(uuid) {
        const result = this.find(uuid);
        if (!result) {
            return undefined;
        }
        return (0, TypeCheck_1.isDecryptedItem)(result) ? result : undefined;
    }
    findAllDecrypted(uuids) {
        return this.findAll(uuids).filter(TypeCheck_1.isDecryptedItem);
    }
    findAllDecryptedWithBlanks(uuids) {
        const results = this.findAllIncludingBlanks(uuids);
        const mapped = results.map((i) => {
            if (i == undefined || (0, TypeCheck_1.isDecryptedItem)(i)) {
                return i;
            }
            return undefined;
        });
        return mapped;
    }
    allDecrypted(contentType) {
        return this.all(contentType).filter(TypeCheck_1.isDecryptedItem);
    }
}
exports.ItemCollection = ItemCollection;
