import { modifiersForEvent } from './modifiersForEvent';
export function eventMatchesKeyAndModifiers(event, shortcut) {
    var _a;
    const eventModifiers = modifiersForEvent(event);
    const shortcutModifiers = (_a = shortcut.modifiers) !== null && _a !== void 0 ? _a : [];
    if (eventModifiers.length !== shortcutModifiers.length) {
        return false;
    }
    for (const modifier of shortcutModifiers) {
        if (!eventModifiers.includes(modifier)) {
            return false;
        }
    }
    if (!shortcut.key && !shortcut.code) {
        return true;
    }
    if (shortcut.key) {
        return shortcut.key.toLowerCase() === event.key.toLowerCase();
    }
    else {
        return shortcut.code === event.code;
    }
}
