"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExclusiveVaultCriteriaValidator = void 0;
class ExclusiveVaultCriteriaValidator {
    constructor(exclusiveVault, element) {
        this.exclusiveVault = exclusiveVault;
        this.element = element;
    }
    passes() {
        return this.element.key_system_identifier === this.exclusiveVault;
    }
}
exports.ExclusiveVaultCriteriaValidator = ExclusiveVaultCriteriaValidator;
