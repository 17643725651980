import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, memo } from 'react';
import { observer } from 'mobx-react-lite';
const FilesControllerContext = createContext(undefined);
export const useFilesController = () => {
    const value = useContext(FilesControllerContext);
    if (!value) {
        throw new Error('Component must be a child of <FilesControllerProvider />');
    }
    return value;
};
const MemoizedChildren = memo(({ children }) => _jsx(_Fragment, { children: children }));
const FilesControllerProvider = ({ controller, children }) => {
    return (_jsx(FilesControllerContext.Provider, { value: controller, children: _jsx(MemoizedChildren, { children: children }) }));
};
export default observer(FilesControllerProvider);
