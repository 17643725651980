"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.experimentalFeatures = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const NativeFeatureIdentifier_1 = require("../Feature/NativeFeatureIdentifier");
const PermissionName_1 = require("../Permission/PermissionName");
function experimentalFeatures() {
    return [
        {
            name: 'Private vaults',
            description: 'Private vaults allow you to store notes, files and tags into separate, encrypted vaults.',
            availableInRoles: [domain_core_1.RoleName.NAMES.CoreUser, domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.Vaults,
            permission_name: PermissionName_1.PermissionName.Vaults,
        },
    ];
}
exports.experimentalFeatures = experimentalFeatures;
