import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useId } from 'react';
import { createPortal } from 'react-dom';
const Portal = ({ children, disabled = false }) => {
    const [container, setContainer] = useState();
    const id = 'portal/' + useId();
    useEffect(() => {
        const container = document.createElement('div');
        container.id = id;
        document.body.append(container);
        setContainer(container);
        return () => container.remove();
    }, [id]);
    if (disabled) {
        return _jsx(_Fragment, { children: children });
    }
    return container ? createPortal(children, container) : null;
};
export default Portal;
