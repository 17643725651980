"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateItemDelta = void 0;
function CreateItemDelta(partial) {
    return {
        changed: partial.changed || [],
        inserted: partial.inserted || [],
        discarded: partial.discarded || [],
        ignored: partial.ignored || [],
        unerrored: partial.unerrored || [],
    };
}
exports.CreateItemDelta = CreateItemDelta;
