import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FeatureStatus, dateToLocalizedString } from '@standardnotes/snjs';
import { useCallback } from 'react';
import Button from '@/Components/Button/Button';
import { WarningCircle } from '../UIElements/WarningCircle';
import { useApplication } from '../ApplicationProvider';
const statusString = (featureStatus, expiredDate, featureName) => {
    switch (featureStatus) {
        case FeatureStatus.InCurrentPlanButExpired:
            if (expiredDate) {
                return `Your subscription expired on ${dateToLocalizedString(expiredDate)}`;
            }
            else {
                return 'Your subscription expired.';
            }
        case FeatureStatus.NoUserSubscription:
            return 'You do not have an active subscription';
        case FeatureStatus.NotInCurrentPlan:
            return `Please upgrade your plan to access ${featureName}`;
        default:
            return `${featureName} is valid and you should not be seeing this message`;
    }
};
const NotEntitledBanner = ({ featureStatus, feature }) => {
    const application = useApplication();
    const expiredDate = application.subscriptions.userSubscriptionExpirationDate;
    const manageSubscription = useCallback(() => {
        void application.openSubscriptionDashboard.execute();
    }, [application]);
    return (_jsx("div", { className: 'sn-component', children: _jsxs("div", { className: "flex min-h-[1.625rem] w-full select-none items-center justify-between border-b border-border bg-contrast px-2 py-2.5 text-text", children: [_jsx("div", { className: 'left', children: _jsxs("div", { className: "flex items-start", children: [_jsx("div", { className: "mt-1", children: _jsx(WarningCircle, {}) }), _jsxs("div", { className: "ml-2", children: [_jsx("strong", { children: statusString(featureStatus, expiredDate, feature.name) }), _jsxs("div", { className: 'sk-p', children: [feature.name, " is in a read-only state."] })] })] }) }), _jsx("div", { className: 'right', children: _jsx(Button, { onClick: manageSubscription, primary: true, colorStyle: "success", small: true, children: "Manage subscription" }) })] }) }));
};
export default NotEntitledBanner;
