import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { classNames } from '@standardnotes/snjs';
import { Select, SelectItem, SelectLabel, SelectPopover, useSelectStore } from '@ariakit/react';
import { KeyboardKey } from '@standardnotes/ui-services';
const Dropdown = ({ label, value, onChange, items, disabled, fullWidth, classNameOverride = {}, popoverPlacement, showLabel, }) => {
    var _a;
    const select = useSelectStore({
        value,
        setValue: onChange,
        placement: popoverPlacement || 'top',
    });
    const isExpanded = select.useState('open');
    const currentItem = items.find((item) => item.value === value);
    return (_jsxs("div", { className: classNameOverride.wrapper, onKeyDown: (event) => {
            if (event.key === KeyboardKey.Escape) {
                event.stopPropagation();
                select.toggle();
            }
        }, children: [_jsx(SelectLabel, { className: !showLabel ? 'sr-only' : '', store: select, children: label }), _jsxs(Select, { className: classNames('flex w-full min-w-55 items-center justify-between rounded border border-passive-3 bg-default px-3.5 py-1.5 text-sm text-foreground md:translucent-ui:bg-transparent', disabled && 'opacity-50', classNameOverride.button, !fullWidth && 'md:w-fit'), store: select, disabled: disabled, children: [_jsxs("div", { className: "flex items-center", children: [(currentItem === null || currentItem === void 0 ? void 0 : currentItem.icon) ? (_jsx("div", { className: "mr-2 flex", children: _jsx(Icon, { type: currentItem.icon, className: (_a = currentItem.iconClassName) !== null && _a !== void 0 ? _a : '', size: "small" }) })) : null, _jsx("div", { className: "text-base lg:text-sm", children: currentItem === null || currentItem === void 0 ? void 0 : currentItem.label })] }), _jsx(Icon, { type: "chevron-down", size: "normal", className: isExpanded ? 'rotate-180' : '' })] }), _jsx(SelectPopover, { store: select, className: classNames('z-dropdown-menu max-h-[var(--popover-available-height)] w-[var(--popover-anchor-width)] overflow-y-auto rounded border border-passive-3 bg-default py-1 [backdrop-filter:var(--popover-backdrop-filter)]', classNameOverride.popover), portal: false, children: items.map((item) => {
                    var _a;
                    return (_jsxs(SelectItem, { className: "group flex cursor-pointer items-center bg-transparent px-3 py-1.5 text-sm text-text hover:bg-contrast hover:text-foreground [&[data-active-item]]:bg-info [&[data-active-item]]:text-info-contrast", value: item.value, disabled: item.disabled, children: [item.icon ? (_jsx("div", { className: "mr-3 flex", children: _jsx(Icon, { type: item.icon, className: (_a = item.iconClassName) !== null && _a !== void 0 ? _a : '', size: "small" }) })) : null, _jsx("div", { className: "text-base lg:text-sm", children: item.label })] }, item.value));
                }) })] }));
};
export default Dropdown;
