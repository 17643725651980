import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import Modal from '@/Components/Modal/Modal';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import { useApplication } from '@/Components/ApplicationProvider';
const U2FAddDeviceView = ({ addAuthenticator, onDeviceAddingModalToggle, onDeviceAdded }) => {
    const application = useApplication();
    const [deviceName, setDeviceName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const handleDeviceNameChange = useCallback((deviceName) => {
        setDeviceName(deviceName);
    }, []);
    const handleAddDeviceClick = useCallback(async () => {
        if (!deviceName) {
            setErrorMessage('Device name is required');
            return;
        }
        const user = application.sessions.getUser();
        if (user === undefined) {
            setErrorMessage('User not found');
            return;
        }
        const authenticatorAddedOrError = await addAuthenticator.execute({
            userUuid: user.uuid,
            authenticatorName: deviceName,
        });
        if (authenticatorAddedOrError.isFailed()) {
            setErrorMessage(authenticatorAddedOrError.getError());
            return;
        }
        onDeviceAddingModalToggle(false);
        await onDeviceAdded();
    }, [deviceName, setErrorMessage, application, addAuthenticator, onDeviceAddingModalToggle, onDeviceAdded]);
    const closeModal = () => {
        onDeviceAddingModalToggle(false);
    };
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    return (_jsx(Modal, { title: "Add Security Key", close: closeModal, actions: [
            {
                label: 'Cancel',
                type: 'cancel',
                onClick: closeModal,
                mobileSlot: 'left',
                hidden: !isMobileScreen,
            },
            {
                label: (_jsxs(_Fragment, { children: ["Add ", _jsx("span", { className: "hidden md:inline", children: "Device" })] })),
                type: 'primary',
                onClick: handleAddDeviceClick,
                mobileSlot: 'right',
            },
        ], children: _jsx("div", { className: "flex px-4 py-4", children: _jsxs("div", { className: "ml-4 flex flex-grow flex-col gap-1", children: [_jsx("label", { htmlFor: "u2f-device-name", className: "mb-2 text-sm font-semibold", children: "Device Name" }), _jsx(DecoratedInput, { autofocus: true, id: "u2f-device-name", className: { container: 'w-92' }, value: deviceName, onChange: handleDeviceNameChange, onEnter: handleAddDeviceClick }), errorMessage && _jsx("div", { className: "mt-1.5 text-danger", children: errorMessage })] }) }) }));
};
export default observer(U2FAddDeviceView);
