import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Title, Subtitle, Text } from '@/Components/Preferences/PreferencesComponents/Content';
import { observer } from 'mobx-react-lite';
import { ButtonType } from '@standardnotes/snjs';
import { useCallback, useEffect, useState } from 'react';
import ListedAccountItem from './ListedAccountItem';
import Button from '@/Components/Button/Button';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import PreferencesPane from '../../PreferencesComponents/PreferencesPane';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
const Listed = ({ application }) => {
    const [accounts, setAccounts] = useState([]);
    const [requestingAccount, setRequestingAccount] = useState();
    const reloadAccounts = useCallback(async () => {
        if (application.hasAccount()) {
            setAccounts(await application.listed.getListedAccounts());
        }
    }, [application]);
    useEffect(() => {
        reloadAccounts().catch(console.error);
    }, [reloadAccounts]);
    const registerNewAccount = useCallback(() => {
        setRequestingAccount(true);
        const requestAccount = async () => {
            const account = await application.listed.requestNewListedAccount();
            if (account) {
                const openSettings = await application.alerts.confirm('Your new Listed blog has been successfully created!' +
                    ' You can publish a new post to your blog from Standard Notes via the' +
                    ' <i>Actions</i> menu in the editor pane. Open your blog settings to begin setting it up.', undefined, 'Open Settings', ButtonType.Info, 'Later');
                reloadAccounts().catch(console.error);
                if (openSettings) {
                    const info = await application.listed.getListedAccountInfo(account);
                    if (info) {
                        application.device.openUrl(info === null || info === void 0 ? void 0 : info.settings_url);
                    }
                }
            }
            setRequestingAccount(false);
        };
        requestAccount().catch(console.error);
    }, [application, reloadAccounts]);
    return (_jsxs(PreferencesPane, { children: [accounts.length > 0 && (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsxs(Title, { children: ["Your ", accounts.length === 1 ? 'blog' : 'blogs', " on Listed"] }), _jsx("div", { className: "h-2 w-full" }), accounts.map((item, index, array) => {
                            return (_jsx(ListedAccountItem, { account: item, showSeparator: index !== array.length - 1, application: application }, item.authorId));
                        })] }) })), _jsxs(PreferencesGroup, { children: [_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "About Listed" }), _jsx("div", { className: "h-2 w-full" }), _jsx(Subtitle, { children: "What is Listed?" }), _jsxs(Text, { children: ["Listed is a free blogging platform that allows you to create a public journal published directly from your notes.", ' ', !application.sessions.getUser() && 'To get started, sign in or register for a Standard Notes account.'] }), _jsx("a", { className: "mt-2 text-info", target: "_blank", href: "https://listed.to", rel: "noreferrer noopener", children: "Learn more" })] }), application.sessions.getUser() && (_jsxs(_Fragment, { children: [_jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs(PreferencesSegment, { children: [_jsx(Subtitle, { children: "Get Started" }), _jsx(Text, { children: "Create a free Listed author account to get started." }), _jsx(Button, { className: "mt-3", disabled: requestingAccount, label: requestingAccount ? 'Creating account...' : 'Create new author', onClick: registerNewAccount })] })] }))] })] }));
};
export default observer(Listed);
