import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Subtitle, Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
import { observer } from 'mobx-react-lite';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { dateToLocalizedString } from '@standardnotes/snjs';
import { useCallback, useState } from 'react';
import ChangeEmail from '@/Components/Preferences/Panes/Account/ChangeEmail/ChangeEmail';
import PasswordWizard from '@/Components/PasswordWizard/PasswordWizard';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
const Credentials = ({ application }) => {
    const [isChangeEmailDialogOpen, setIsChangeEmailDialogOpen] = useState(false);
    const [shouldShowPasswordWizard, setShouldShowPasswordWizard] = useState(false);
    const user = application.sessions.getUser();
    const passwordCreatedAtTimestamp = application.getUserPasswordCreationDate();
    const passwordCreatedOn = dateToLocalizedString(passwordCreatedAtTimestamp);
    const presentPasswordWizard = useCallback(() => {
        setShouldShowPasswordWizard(true);
    }, []);
    const dismissPasswordWizard = useCallback(() => {
        setShouldShowPasswordWizard(false);
    }, []);
    const closeChangeEmailDialog = () => setIsChangeEmailDialogOpen(false);
    return (_jsxs(_Fragment, { children: [_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Credentials" }), _jsx(Subtitle, { children: "Email" }), _jsxs(Text, { children: ["You're signed in as ", _jsx("span", { className: "wrap font-bold", children: user === null || user === void 0 ? void 0 : user.email })] }), _jsx(Button, { className: "mt-3 min-w-20", label: "Change email", onClick: () => {
                                setIsChangeEmailDialogOpen(true);
                            } }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(Subtitle, { children: "Password" }), _jsxs(Text, { children: ["Current password was set on ", _jsx("span", { className: "font-bold", children: passwordCreatedOn })] }), _jsx(Button, { className: "mt-3 min-w-20", label: "Change password", onClick: presentPasswordWizard }), _jsx(ModalOverlay, { isOpen: isChangeEmailDialogOpen, close: closeChangeEmailDialog, children: _jsx(ChangeEmail, { onCloseDialog: closeChangeEmailDialog, application: application }) })] }) }), _jsx(ModalOverlay, { isOpen: shouldShowPasswordWizard, close: dismissPasswordWizard, children: _jsx(PasswordWizard, { application: application, dismissModal: dismissPasswordWizard }) })] }));
};
export default observer(Credentials);
