import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { classNames } from '@standardnotes/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { TagDragDataFormat } from './DragNDrop';
const RootTagDropZone = ({ tagsState }) => {
    const [isOver, setIsOver] = useState(false);
    const removeDragIndicator = useCallback(() => {
        setIsOver(false);
    }, []);
    const onDragOver = useCallback((event) => {
        if (event.dataTransfer.types.includes(TagDragDataFormat)) {
            event.preventDefault();
            setIsOver(true);
        }
    }, []);
    const onDrop = useCallback((event) => {
        setIsOver(false);
        const draggedTagUuid = event.dataTransfer.getData(TagDragDataFormat);
        if (!draggedTagUuid) {
            return;
        }
        if (draggedTagUuid) {
            void tagsState.assignParent(draggedTagUuid, undefined);
        }
    }, [tagsState]);
    return (_jsxs("div", { className: classNames('root-drop', isOver && 'active is-drag-over'), onDragExit: removeDragIndicator, onDragOver: onDragOver, onDragLeave: removeDragIndicator, onDrop: onDrop, children: [_jsx(Icon, { className: "text-neutral", type: "link-off" }), _jsxs("p", { className: "content", children: ["Move the tag here to ", _jsx("br", {}), "remove it from its folder."] })] }));
};
export default observer(RootTagDropZone);
