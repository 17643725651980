import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import MobileItemsListButton from '../NoteGroupView/MobileItemsListButton';
const ProtectedItemOverlay = ({ showAccountMenu, onViewItem, hasProtectionSources, itemType }) => {
    const instructionText = hasProtectionSources
        ? `Authenticate to view this ${itemType}.`
        : `Add a passcode or create an account to require authentication to view this ${itemType}.`;
    return (_jsx("div", { "aria-label": "Protected overlay", className: "section editor sn-component p-5", children: _jsxs("div", { className: "flex h-full flex-grow flex-col justify-center md:flex-row md:items-center", children: [_jsx("div", { className: "mb-auto p-4 md:hidden", children: _jsx(MobileItemsListButton, {}) }), _jsxs("div", { className: "mb-auto flex max-w-md flex-col items-center justify-center text-center md:mb-0", children: [_jsxs("h1", { className: "m-0 w-full text-2xl font-bold", children: ["This ", itemType, " is protected"] }), _jsx("p", { className: "mt-2 w-full text-lg", children: instructionText }), _jsxs("div", { className: "mt-4 flex gap-3", children: [!hasProtectionSources && (_jsx(Button, { primary: true, small: true, onClick: () => {
                                        showAccountMenu();
                                    }, children: "Open account menu" })), _jsx(Button, { small: true, onClick: onViewItem, children: hasProtectionSources ? 'Authenticate' : `View ${itemType}` })] })] })] }) }));
};
export default ProtectedItemOverlay;
