import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/snjs';
import { useState, useRef } from 'react';
import Icon from '../Icon/Icon';
import VaultOptionsMenu from './VaultOptionsMenu';
import Popover from '../Popover/Popover';
const VaultSelectMenuItemWithOptions = ({ vault, children, }) => {
    const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
    const optionsButtonRef = useRef(null);
    const toggleOptionsMenu = () => {
        setIsOptionsMenuOpen((open) => !open);
    };
    return (_jsxs("div", { className: "group flex items-center gap-3 px-3 focus-within:bg-info-backdrop", children: [children, _jsx("button", { className: classNames('flex-shrink-0 rounded-full border border-border p-1 hover:bg-default focus:bg-default group-focus-within:bg-default', isOptionsMenuOpen && 'bg-default'), onClick: toggleOptionsMenu, ref: optionsButtonRef, children: _jsx(Icon, { type: "more", size: "small" }) }), _jsx(Popover, { title: "Vault options", open: isOptionsMenuOpen, anchorElement: optionsButtonRef, side: "top", align: "start", className: "py-1", togglePopover: toggleOptionsMenu, children: _jsx(VaultOptionsMenu, { vault: vault }) })] }));
};
export default VaultSelectMenuItemWithOptions;
