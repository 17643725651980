import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { useTabStateContext } from './useTabState';
const Tab = ({ id, className, children, ...props }) => {
    const { state } = useTabStateContext();
    const { activeTab, setActiveTab } = state;
    const isActive = activeTab === id;
    return (_jsx("button", { role: "tab", id: `tab-control-${id}`, onClick: () => {
            setActiveTab(id);
        }, "aria-selected": isActive, "aria-controls": `tab-panel-${id}`, className: classNames('relative cursor-pointer border-0 bg-default px-3 py-2.5 text-sm focus:shadow-inner md:translucent-ui:bg-transparent', isActive ? 'font-medium text-info' : 'text-text', isActive && 'after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-info', className), ...props, children: children }));
};
export default Tab;
