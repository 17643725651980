"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
class Logger {
    constructor(appIdentifier) {
        this.appIdentifier = appIdentifier;
        this.level = 'none';
    }
    canLog(level) {
        if (this.level === 'none') {
            return false;
        }
        const levels = ['debug', 'info', 'warn', 'error'];
        return levels.indexOf(level) >= levels.indexOf(this.level);
    }
    setLevel(level) {
        this.level = level;
    }
    debug(message, ...optionalParams) {
        if (this.canLog('debug')) {
            this.logWithColor(message, ...optionalParams);
        }
    }
    info(message, ...optionalParams) {
        if (this.canLog('info')) {
            this.logWithColor(message, ...optionalParams);
        }
    }
    warn(message, ...optionalParams) {
        if (this.canLog('warn')) {
            console.warn(message, ...optionalParams);
        }
    }
    error(message, ...optionalParams) {
        if (this.canLog('error')) {
            console.error(message, ...optionalParams);
        }
    }
    logWithColor(...args) {
        const date = new Date();
        const timeString = `${date.toLocaleTimeString().replace(' PM', '').replace(' AM', '')}.${date.getMilliseconds()}`;
        this.customLog(`%c${this.appIdentifier}%c${timeString}`, 'color: font-weight: bold; margin-right: 4px', 'color: gray', ...args);
    }
    customLog(..._args) {
        // eslint-disable-next-line no-console, prefer-rest-params
        Function.prototype.apply.call(console.log, console, arguments);
    }
}
exports.Logger = Logger;
exports.default = Logger;
