import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { classNames } from '@standardnotes/snjs';
import { formatDateForContextMenu } from '@/Utils/DateUtils';
import { calculateReadTime } from './Utils/calculateReadTime';
import { countNoteAttributes } from './Utils/countNoteAttributes';
import { formatSizeToReadableString } from '@standardnotes/filepicker';
export const useNoteAttributes = (application, note) => {
    const { words, characters, paragraphs } = useMemo(() => countNoteAttributes(note.text), [note.text]);
    const readTime = useMemo(() => (typeof words === 'number' ? calculateReadTime(words) : 'N/A'), [words]);
    const userModifiedDate = useMemo(() => formatDateForContextMenu(note.userModifiedDate), [note.userModifiedDate]);
    const serverUpdatedAt = useMemo(() => formatDateForContextMenu(note.serverUpdatedAt), [note.serverUpdatedAt]);
    const dateCreated = useMemo(() => formatDateForContextMenu(note.created_at), [note.created_at]);
    const size = useMemo(() => new Blob([note.text]).size, [note.text]);
    const editor = application.componentManager.editorForNote(note);
    const format = editor.fileType;
    return {
        size,
        words,
        characters,
        paragraphs,
        readTime,
        userModifiedDate,
        serverUpdatedAt,
        dateCreated,
        format,
    };
};
export const NoteAttributes = ({ application, note, className }) => {
    const { size, words, characters, paragraphs, readTime, userModifiedDate, dateCreated, format } = useNoteAttributes(application, note);
    const canShowWordCount = typeof words === 'number' && (format === 'txt' || format === 'md');
    return (_jsxs("div", { className: classNames('select-text px-3 py-1.5 text-sm font-medium text-neutral lg:text-xs', className), children: [canShowWordCount ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-1", children: [words, " words \u00B7 ", characters, " characters \u00B7 ", paragraphs, " paragraphs"] }), _jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "Read time:" }), " ", readTime] })] })) : null, _jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "Last modified:" }), " ", userModifiedDate] }), _jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "Created:" }), " ", dateCreated] }), _jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "Note ID:" }), " ", note.uuid] }), _jsxs("div", { children: [_jsx("span", { className: "font-semibold", children: "Size:" }), " ", formatSizeToReadableString(size)] })] }));
};
