"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SodiumTag = void 0;
var SodiumTag;
(function (SodiumTag) {
    SodiumTag[SodiumTag["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PUSH"] = 0] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PUSH";
    SodiumTag[SodiumTag["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PULL"] = 1] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PULL";
    SodiumTag[SodiumTag["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_REKEY"] = 2] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_REKEY";
    SodiumTag[SodiumTag["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_FINAL"] = 3] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_FINAL";
})(SodiumTag || (exports.SodiumTag = SodiumTag = {}));
