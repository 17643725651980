import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PreferencesSegment from '../../../PreferencesComponents/PreferencesSegment';
import { useApplication } from '@/Components/ApplicationProvider';
import PluginRowView from './PluginRowView';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { ContentType } from '@standardnotes/snjs';
import { Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { PreferencesPremiumOverlay } from '@/Components/Preferences/PremiumOverlay';
const BrowsePlugins = () => {
    const application = useApplication();
    const [plugins, setPlugins] = useState(null);
    const reloadPlugins = useCallback(() => {
        application.pluginsService.getInstallablePlugins().then(setPlugins).catch(console.error);
    }, [application]);
    useEffect(() => {
        reloadPlugins();
    }, [reloadPlugins]);
    useEffect(() => {
        application.items.streamItems([ContentType.TYPES.Component, ContentType.TYPES.Theme], reloadPlugins);
    }, [application, reloadPlugins]);
    const hasSubscription = application.hasValidFirstPartySubscription();
    return (_jsxs("div", { className: "relative", children: [_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Browse Plugins" }), _jsxs(Text, { className: "text-neutral", children: ["Plugins run in a secure sandbox and can only access data you allow it. Note types allow specialized editing experiences, but in most cases, the ", _jsx("strong", { children: "built-in Super note type" }), " can encapsulate any functionality found in plugins."] }), !plugins && (_jsx("div", { className: "mb-3 mt-5 flex h-full w-full items-center", children: _jsx("span", { className: "w-full font-bold", children: "Loading..." }) })), _jsx("div", { className: "mt-2", children: plugins === null || plugins === void 0 ? void 0 : plugins.map((plugin, index) => {
                            return (_jsxs("div", { children: [_jsx(PluginRowView, { plugin: plugin }), index < plugins.length - 1 && _jsx(HorizontalSeparator, {})] }, plugin.name));
                        }) })] }), _jsx(HorizontalSeparator, {}), _jsx(Text, { className: "mt-4 text-danger", children: "Plugins may not be actively maintained. Standard Notes cannot attest to the quality or user experience of these plugins, and is not responsible for any data loss that may arise from their use." }), !hasSubscription && _jsx(PreferencesPremiumOverlay, {})] }));
};
export default observer(BrowsePlugins);
