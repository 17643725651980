"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.themes = void 0;
const PermissionName_1 = require("../Permission/PermissionName");
const NativeFeatureIdentifier_1 = require("../Feature/NativeFeatureIdentifier");
const FillThemeComponentDefaults_1 = require("./Utilities/FillThemeComponentDefaults");
const domain_core_1 = require("@standardnotes/domain-core");
function themes() {
    const midnight = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        name: 'Midnight',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.MidnightTheme,
        permission_name: PermissionName_1.PermissionName.MidnightTheme,
        isDark: true,
        dock_icon: {
            type: 'circle',
            background_color: '#086DD6',
            foreground_color: '#ffffff',
            border_color: '#086DD6',
        },
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const futura = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        name: 'Futura',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.FuturaTheme,
        permission_name: PermissionName_1.PermissionName.FuturaTheme,
        isDark: true,
        dock_icon: {
            type: 'circle',
            background_color: '#fca429',
            foreground_color: '#ffffff',
            border_color: '#fca429',
        },
    });
    const solarizedDark = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        name: 'Solarized Dark',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.SolarizedDarkTheme,
        permission_name: PermissionName_1.PermissionName.SolarizedDarkTheme,
        isDark: true,
        dock_icon: {
            type: 'circle',
            background_color: '#2AA198',
            foreground_color: '#ffffff',
            border_color: '#2AA198',
        },
    });
    const autobiography = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        name: 'Autobiography',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.AutobiographyTheme,
        permission_name: PermissionName_1.PermissionName.AutobiographyTheme,
        dock_icon: {
            type: 'circle',
            background_color: '#9D7441',
            foreground_color: '#ECE4DB',
            border_color: '#9D7441',
        },
    });
    const dark = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        availableInRoles: [domain_core_1.RoleName.NAMES.CoreUser, domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        name: 'Dark',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DarkTheme,
        permission_name: PermissionName_1.PermissionName.FocusedTheme,
        clientControlled: true,
        isDark: true,
        dock_icon: {
            type: 'circle',
            background_color: '#a464c2',
            foreground_color: '#ffffff',
            border_color: '#a464c2',
        },
    });
    const titanium = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        name: 'Titanium',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.TitaniumTheme,
        permission_name: PermissionName_1.PermissionName.TitaniumTheme,
        dock_icon: {
            type: 'circle',
            background_color: '#6e2b9e',
            foreground_color: '#ffffff',
            border_color: '#6e2b9e',
        },
    });
    const dynamic = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        name: 'Dynamic Panels',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DynamicTheme,
        permission_name: PermissionName_1.PermissionName.ThemeDynamic,
        layerable: true,
        no_mobile: true,
    });
    const proton = (0, FillThemeComponentDefaults_1.FillThemeComponentDefaults)({
        availableInRoles: [domain_core_1.RoleName.NAMES.CoreUser, domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        name: 'Carbon',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.ProtonTheme,
        permission_name: PermissionName_1.PermissionName.ProtonTheme,
        dock_icon: {
            type: 'circle',
            background_color: '#16141c',
            foreground_color: '#ffffff',
            border_color: '#4a4658',
        },
    });
    return [midnight, futura, solarizedDark, autobiography, dark, proton, titanium, dynamic];
}
exports.themes = themes;
