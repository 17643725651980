import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import ListedActionsMenu from './ListedActionsMenu';
import { KeyboardKey } from '@standardnotes/ui-services';
import Popover from '../../Popover/Popover';
import MenuItem from '@/Components/Menu/MenuItem';
const ListedActionsOption = ({ application, note, iconClassName }) => {
    const menuContainerRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = useCallback(async () => {
        if (!application.listed.isNoteAuthorizedForListed(note)) {
            await application.listed.authorizeNoteForListed(note);
        }
        if (application.listed.isNoteAuthorizedForListed(note)) {
            setIsOpen((isOpen) => !isOpen);
        }
    }, [application, note]);
    return (_jsxs("div", { ref: menuContainerRef, children: [_jsxs(MenuItem, { className: "justify-between", onClick: toggleMenu, onKeyDown: (event) => {
                    if (event.key === KeyboardKey.Escape) {
                        setIsOpen(false);
                    }
                }, ref: buttonRef, children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { type: "listed", className: `mr-2 text-neutral ${iconClassName}` }), "Listed actions"] }), _jsx(Icon, { type: "chevron-right", className: "text-neutral" })] }), _jsx(Popover, { title: "Listed", togglePopover: toggleMenu, anchorElement: buttonRef, open: isOpen, side: "right", align: "end", className: "px-4 md:px-0 md:pt-0", children: _jsx(ListedActionsMenu, { application: application, note: note }) })] }));
};
export default ListedActionsOption;
