import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
const BiometricsPrompt = ({ application, onValueChange, prompt, buttonRef }) => {
    const [authenticated, setAuthenticated] = useState(false);
    return (_jsx("div", { className: "min-w-76", children: _jsx(Button, { primary: true, fullWidth: true, colorStyle: authenticated ? 'success' : 'info', onClick: async () => {
                const authenticated = await application.mobileDevice.authenticateWithBiometrics();
                setAuthenticated(authenticated);
                onValueChange(authenticated, prompt);
            }, ref: buttonRef, children: authenticated ? (_jsxs("span", { className: "flex items-center justify-center gap-3", children: [_jsx(Icon, { type: "check-circle" }), "Biometrics successful"] })) : ('Tap to use biometrics') }) }));
};
export default BiometricsPrompt;
