"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNativeFeature = exports.isItemBasedFeature = exports.isUIFeatureAnIframeFeature = void 0;
const features_1 = require("@standardnotes/features");
function isUIFeatureAnIframeFeature(x) {
    return (0, features_1.isIframeComponentFeatureDescription)(x.featureDescription);
}
exports.isUIFeatureAnIframeFeature = isUIFeatureAnIframeFeature;
function isItemBasedFeature(x) {
    return 'uuid' in x;
}
exports.isItemBasedFeature = isItemBasedFeature;
function isNativeFeature(x) {
    return !('uuid' in x);
}
exports.isNativeFeature = isNativeFeature;
