import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useApplication } from '@/Components/ApplicationProvider';
import Icon from '@/Components/Icon/Icon';
import { ContentType, StaticItemCounter } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import EncryptionStatusItem from './EncryptionStatusItem';
import { formatCount } from './formatCount';
const EncryptionEnabled = () => {
    const application = useApplication();
    const itemCounter = new StaticItemCounter();
    const count = itemCounter.countNotesAndTags(application.items.getItems([ContentType.TYPES.Note, ContentType.TYPES.Tag]));
    const files = application.items.getItems([ContentType.TYPES.File]);
    const notes = formatCount(count.notes, 'notes');
    const tags = formatCount(count.tags, 'tags');
    const archived = formatCount(count.archived, 'archived notes');
    const deleted = formatCount(count.deleted, 'trashed notes');
    const filesCount = formatCount(files.length, 'files');
    const noteIcon = _jsx(Icon, { type: "rich-text", className: "min-h-5 min-w-5" });
    const tagIcon = _jsx(Icon, { type: "hashtag", className: "min-h-5 min-w-5" });
    const archiveIcon = _jsx(Icon, { type: "archive", className: "min-h-5 min-w-5" });
    const trashIcon = _jsx(Icon, { type: "trash", className: "min-h-5 min-w-5" });
    const filesIcon = _jsx(Icon, { type: "folder", className: "min-h-5 min-w-5" });
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-row flex-wrap items-start pt-1.5 md:pb-1", children: [_jsx(EncryptionStatusItem, { status: notes, icon: noteIcon }), _jsx("div", { className: "min-w-3" }), _jsx(EncryptionStatusItem, { status: filesCount, icon: filesIcon }), _jsx("div", { className: "min-w-3" }), _jsx(EncryptionStatusItem, { status: tags, icon: tagIcon }), _jsx("div", { className: "min-w-3" }), _jsx(EncryptionStatusItem, { status: archived, icon: archiveIcon }), _jsx("div", { className: "min-w-3" }), _jsx(EncryptionStatusItem, { status: deleted, icon: trashIcon })] }) }));
};
export default observer(EncryptionEnabled);
