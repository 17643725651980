import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { Hovercard, HovercardAnchor, useHovercardStore } from '@ariakit/react';
const AuthAppInfoTooltip = () => {
    const infoHovercard = useHovercardStore({
        showTimeout: 100,
    });
    return (_jsxs(_Fragment, { children: [_jsx(HovercardAnchor, { store: infoHovercard, children: _jsx(Icon, { type: "info" }) }), _jsx(Hovercard, { store: infoHovercard, className: " max-w-76 rounded border border-border bg-default px-3 py-2 text-sm", children: "Some apps, like Google Authenticator, do not back up and restore your secret keys if you lose your device or get a new one." })] }));
};
export default AuthAppInfoTooltip;
