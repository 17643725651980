import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import U2FTitle from './U2FTitle';
import U2FDescription from './U2FDescription';
import Button from '@/Components/Button/Button';
import U2FAddDeviceView from '../U2FAddDeviceView';
import U2FDevicesList from './U2FDevicesList';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import RecoveryCodeBanner from '@/Components/RecoveryCodeBanner/RecoveryCodeBanner';
const U2FView = ({ application, is2FAEnabled, loadAuthenticatorsCallback }) => {
    const [showDeviceAddingModal, setShowDeviceAddingModal] = useState(false);
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState('');
    const handleAddDeviceClick = useCallback(() => {
        setShowDeviceAddingModal(true);
    }, []);
    const loadAuthenticatorDevices = useCallback(async () => {
        const authenticatorListOrError = await application.listAuthenticators.execute();
        if (authenticatorListOrError.isFailed()) {
            setError(authenticatorListOrError.getError());
            return;
        }
        const authenticatorList = authenticatorListOrError.getValue();
        setDevices(authenticatorList);
        loadAuthenticatorsCallback(authenticatorList);
    }, [setError, setDevices, application, loadAuthenticatorsCallback]);
    useEffect(() => {
        loadAuthenticatorDevices().catch(console.error);
    }, [loadAuthenticatorDevices]);
    return (_jsxs(_Fragment, { children: [_jsxs(PreferencesGroup, { children: [_jsx(PreferencesSegment, { children: _jsxs("div", { className: "flex flex-col", children: [_jsx(U2FTitle, {}), _jsx(U2FDescription, { is2FAEnabled: is2FAEnabled })] }) }), _jsxs(PreferencesSegment, { classes: "mt-2", children: [error && _jsx("div", { className: "text-danger", children: error }), _jsx(U2FDevicesList, { application: application, devices: devices, onError: setError, onDeviceDeleted: loadAuthenticatorDevices }), _jsx(Button, { className: "mt-1", disabled: !application.isFullU2FClient || !is2FAEnabled, label: "Add Device", primary: true, onClick: handleAddDeviceClick })] }), devices.length > 0 && (_jsx(PreferencesSegment, { children: _jsx("div", { className: "mt-3", children: _jsx(RecoveryCodeBanner, { application: application }) }) }))] }), _jsx(ModalOverlay, { isOpen: showDeviceAddingModal, close: () => setShowDeviceAddingModal(false), children: _jsx(U2FAddDeviceView, { onDeviceAddingModalToggle: setShowDeviceAddingModal, onDeviceAdded: loadAuthenticatorDevices, addAuthenticator: application.addAuthenticator }) })] }));
};
export default observer(U2FView);
