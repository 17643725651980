"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeprecatedStatusCode = void 0;
var DeprecatedStatusCode;
(function (DeprecatedStatusCode) {
    DeprecatedStatusCode[DeprecatedStatusCode["LocalValidationError"] = 10] = "LocalValidationError";
    DeprecatedStatusCode[DeprecatedStatusCode["CanceledMfa"] = 11] = "CanceledMfa";
    DeprecatedStatusCode[DeprecatedStatusCode["HttpStatusMinSuccess"] = 200] = "HttpStatusMinSuccess";
    DeprecatedStatusCode[DeprecatedStatusCode["HttpStatusNoContent"] = 204] = "HttpStatusNoContent";
    DeprecatedStatusCode[DeprecatedStatusCode["HttpStatusMaxSuccess"] = 299] = "HttpStatusMaxSuccess";
    /** The session's access token is expired, but the refresh token is valid */
    DeprecatedStatusCode[DeprecatedStatusCode["HttpStatusExpiredAccessToken"] = 498] = "HttpStatusExpiredAccessToken";
    /** The session's access token and refresh token are expired, user must reauthenticate */
    DeprecatedStatusCode[DeprecatedStatusCode["HttpStatusInvalidSession"] = 401] = "HttpStatusInvalidSession";
    /** User's IP is rate-limited. */
    DeprecatedStatusCode[DeprecatedStatusCode["HttpStatusForbidden"] = 403] = "HttpStatusForbidden";
    DeprecatedStatusCode[DeprecatedStatusCode["HttpBadRequest"] = 400] = "HttpBadRequest";
})(DeprecatedStatusCode || (exports.DeprecatedStatusCode = DeprecatedStatusCode = {}));
