import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { MobileUnlockTiming } from '@standardnotes/snjs';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import Button from '@/Components/Button/Button';
import { classNames } from '@standardnotes/utils';
const BiometricsLock = ({ application }) => {
    const [hasBiometrics, setHasBiometrics] = useState(false);
    const [supportsBiometrics, setSupportsBiometrics] = useState(false);
    const [biometricsTimingOptions, setBiometricsTimingOptions] = useState(() => application.protections.getMobileBiometricsTimingOptions());
    useEffect(() => {
        const appHasBiometrics = application.protections.hasBiometricsEnabled();
        setHasBiometrics(appHasBiometrics);
        const hasBiometricsSupport = async () => {
            var _a, _b;
            const hasBiometricsAvailable = await ((_b = (_a = application.device).getDeviceBiometricsAvailability) === null || _b === void 0 ? void 0 : _b.call(_a));
            setSupportsBiometrics(hasBiometricsAvailable);
        };
        void hasBiometricsSupport();
    }, [application]);
    const setBiometricsTimingValue = async (timing) => {
        application.protections.setMobileBiometricsTiming(timing);
        setBiometricsTimingOptions(() => application.protections.getMobileBiometricsTimingOptions());
    };
    const disableBiometrics = useCallback(async () => {
        if (await application.protections.disableBiometrics()) {
            setHasBiometrics(false);
        }
    }, [application]);
    const onBiometricsPress = async () => {
        if (hasBiometrics) {
            await disableBiometrics();
        }
        else {
            setHasBiometrics(true);
            application.protections.enableBiometrics();
            await setBiometricsTimingValue(MobileUnlockTiming.OnQuit);
        }
    };
    const biometricTitle = hasBiometrics ? 'Disable Biometrics Lock' : 'Enable Biometrics Lock';
    if (!supportsBiometrics) {
        return null;
    }
    return (_jsx("div", { children: _jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Biometrics Lock" }), _jsx(Button, { className: 'mt-1', label: biometricTitle, onClick: onBiometricsPress, primary: true }), hasBiometrics && (_jsxs("div", { className: "mt-2 flex flex-row items-center", children: [_jsx("div", { className: 'mr-3', children: "Require Biometrics" }), biometricsTimingOptions.map((option) => {
                                return (_jsx("a", { className: classNames('mr-3 cursor-pointer rounded px-1.5 py-0.5', option.selected ? 'bg-info text-info-contrast' : 'text-info'), onClick: () => {
                                        void setBiometricsTimingValue(option.key);
                                    }, children: option.title }, option.key));
                            })] }))] }) }) }));
};
export default observer(BiometricsLock);
