import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, useDialogStore } from '@ariakit/react';
import { classNames } from '@standardnotes/snjs';
import { useCallback } from 'react';
const AlertDialog = ({ children, closeDialog, className, ...props }) => {
    const dialog = useDialogStore({
        open: true,
        ...props,
    });
    const addCloseMethod = useCallback((element) => {
        if (element) {
            ;
            element.close = closeDialog;
        }
    }, [closeDialog]);
    return (_jsxs(Dialog, { store: dialog, role: "alertdialog", className: "pointer-events-auto fixed left-0 top-0 z-modal flex h-full w-full items-center justify-center", modal: false, portal: true, preventBodyScroll: true, ref: addCloseMethod, children: [_jsx("div", { className: "absolute z-0 h-full w-full bg-passive-5 opacity-25 md:opacity-75", role: "presentation", onClick: closeDialog }), _jsx("div", { className: classNames('z-[1] w-[95vw] rounded border border-[--popover-border-color] bg-[--popover-background-color] px-6 py-5 shadow-xl [backdrop-filter:var(--popover-backdrop-filter)] md:w-auto', !(className === null || className === void 0 ? void 0 : className.includes('max-w-')) && 'max-w-[600px]', className), children: children })] }));
};
export default AlertDialog;
