import { ByteChunker } from '@standardnotes/files';
/**
 * The File System Access API File Picker
 * https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API
 */
export const StreamingFileReader = {
    getFilesFromHandles,
    selectFiles,
    readFile,
    available,
    maximumFileSize,
};
function maximumFileSize() {
    return undefined;
}
function getFilesFromHandles(handles) {
    return Promise.all(handles.map((handle) => handle.getFile()));
}
async function selectFiles() {
    let selectedFilesHandles;
    try {
        selectedFilesHandles = await window.showOpenFilePicker({ multiple: true });
    }
    catch (error) {
        selectedFilesHandles = [];
    }
    return getFilesFromHandles(selectedFilesHandles);
}
async function readFile(file, minimumChunkSize, onChunk) {
    const byteChunker = new ByteChunker(minimumChunkSize, onChunk);
    const stream = file.stream();
    const reader = stream.getReader();
    let previousChunk;
    const processChunk = async (result) => {
        if (result.done) {
            await byteChunker.addBytes(previousChunk, true);
            return;
        }
        if (previousChunk) {
            await byteChunker.addBytes(previousChunk, false);
        }
        previousChunk = result.value;
        return reader.read().then(processChunk);
    };
    await reader.read().then(processChunk);
    return {
        name: file.name,
        mimeType: file.type,
    };
}
function available() {
    return window.showOpenFilePicker != undefined;
}
