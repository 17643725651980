export const transactionForAssociateComponentWithCurrentNote = (component, note) => {
    const transaction = {
        itemUuid: component.uuid,
        mutate: (m) => {
            const mutator = m;
            mutator.removeDisassociatedItemId(note.uuid);
            mutator.associateWithItem(note.uuid);
        },
    };
    return transaction;
};
export const transactionForDisassociateComponentWithCurrentNote = (component, note) => {
    const transaction = {
        itemUuid: component.uuid,
        mutate: (m) => {
            const mutator = m;
            mutator.removeAssociatedItemId(note.uuid);
            mutator.disassociateWithItem(note.uuid);
        },
    };
    return transaction;
};
