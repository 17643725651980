import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import IconPicker from '@/Components/Icon/IconPicker';
import Popover from '@/Components/Popover/Popover';
import Modal from '@/Components/Modal/Modal';
import Spinner from '@/Components/Spinner/Spinner';
import { SmartViewDefaultIconName } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
const EditSmartViewModal = ({ controller, platform }) => {
    const { view, title, setTitle, predicateJson, setPredicateJson, isPredicateJsonValid, setIsPredicateJsonValid, icon, setIcon, save, isSaving, closeDialog, deleteView, } = controller;
    const titleInputRef = useRef(null);
    const predicateJsonInputRef = useRef(null);
    const [shouldShowIconPicker, setShouldShowIconPicker] = useState(false);
    const iconPickerButtonRef = useRef(null);
    const toggleIconPicker = useCallback(() => {
        setShouldShowIconPicker((shouldShow) => !shouldShow);
    }, []);
    const saveSmartView = useCallback(async () => {
        var _a;
        if (!title.length) {
            (_a = titleInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        void save();
    }, [save, title.length]);
    useEffect(() => {
        if (!predicateJsonInputRef.current) {
            return;
        }
        if (isPredicateJsonValid === false) {
            predicateJsonInputRef.current.focus();
        }
    }, [isPredicateJsonValid]);
    const modalActions = useMemo(() => [
        {
            label: 'Delete',
            onClick: deleteView,
            disabled: isSaving,
            type: 'destructive',
        },
        {
            label: 'Cancel',
            onClick: closeDialog,
            disabled: isSaving,
            type: 'cancel',
            mobileSlot: 'left',
        },
        {
            label: isSaving ? _jsx(Spinner, { className: "h-4.5 w-4.5" }) : 'Save',
            onClick: saveSmartView,
            disabled: isSaving,
            type: 'primary',
            mobileSlot: 'right',
        },
    ], [closeDialog, deleteView, isSaving, saveSmartView]);
    if (!view) {
        return null;
    }
    return (_jsx(Modal, { title: `Edit Smart View "${view.title}"`, close: closeDialog, actions: modalActions, children: _jsx("div", { className: "px-4 py-4", children: _jsxs("div", { className: "flex h-full flex-col gap-4", children: [_jsxs("div", { className: "flex items-center gap-2.5", children: [_jsx("div", { className: "text-sm font-semibold", children: "Title:" }), _jsx("input", { className: "rounded border border-border bg-default px-2 py-1 md:translucent-ui:bg-transparent", value: title, onChange: (event) => {
                                    setTitle(event.target.value);
                                }, ref: titleInputRef })] }), _jsxs("div", { className: "flex items-center gap-2.5", children: [_jsx("div", { className: "text-sm font-semibold", children: "Icon:" }), _jsx("button", { className: "rounded border border-border p-2", "aria-label": "Change icon", onClick: toggleIconPicker, ref: iconPickerButtonRef, children: _jsx(Icon, { type: icon || SmartViewDefaultIconName }) }), _jsx(Popover, { title: "Choose icon", open: shouldShowIconPicker, anchorElement: iconPickerButtonRef, togglePopover: toggleIconPicker, align: "start", overrideZIndex: "z-modal", children: _jsx("div", { className: "p-2", children: _jsx(IconPicker, { selectedValue: icon || SmartViewDefaultIconName, onIconChange: (value) => {
                                            setIcon(value || SmartViewDefaultIconName);
                                            toggleIconPicker();
                                        }, platform: platform, useIconGrid: true }) }) })] }), _jsxs("div", { className: "flex flex-grow flex-col gap-2.5", children: [_jsx("div", { className: "text-sm font-semibold", children: "Predicate:" }), _jsxs("div", { className: "flex flex-grow flex-col overflow-hidden rounded-md border border-border", children: [_jsx("textarea", { className: "h-full min-h-[10rem] w-full flex-grow resize-none bg-default px-2.5 py-1.5 font-mono text-sm md:translucent-ui:bg-transparent", value: predicateJson, onChange: (event) => {
                                            setPredicateJson(event.target.value);
                                            setIsPredicateJsonValid(true);
                                        }, spellCheck: false, ref: predicateJsonInputRef }), !isPredicateJsonValid && (_jsx("div", { className: "border-t border-border px-2.5 py-1.5 text-sm text-danger", children: "Invalid JSON. Double check your entry and try again." }))] })] })] }) }) }));
};
export default observer(EditSmartViewModal);
