import { action, makeObservable, observable } from 'mobx';
export class SyncStatusController {
    constructor() {
        this.inProgress = false;
        this.errorMessage = undefined;
        this.humanReadablePercentage = undefined;
        this.update = (status) => {
            var _a;
            this.errorMessage = (_a = status.error) === null || _a === void 0 ? void 0 : _a.message;
            this.inProgress = status.syncInProgress;
            const stats = status.getStats();
            const completionPercentage = stats.uploadCompletionCount === 0 ? 0 : stats.uploadCompletionCount / stats.uploadTotalCount;
            if (completionPercentage === 0) {
                this.humanReadablePercentage = undefined;
            }
            else {
                this.humanReadablePercentage = completionPercentage.toLocaleString(undefined, {
                    style: 'percent',
                });
            }
        };
        makeObservable(this, {
            inProgress: observable,
            errorMessage: observable,
            humanReadablePercentage: observable,
            update: action,
        });
    }
}
