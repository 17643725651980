"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotPredicate = void 0;
class NotPredicate {
    constructor(predicate) {
        this.predicate = predicate;
    }
    matchesItem(item) {
        return !this.predicate.matchesItem(item);
    }
    keypathIncludesString(verb) {
        return this.predicate.keypathIncludesString(verb);
    }
    toJson() {
        return {
            operator: 'not',
            value: this.predicate.toJson(),
        };
    }
}
exports.NotPredicate = NotPredicate;
