"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionVerb = exports.ActionAccessType = void 0;
var ActionAccessType;
(function (ActionAccessType) {
    ActionAccessType["Encrypted"] = "encrypted";
    ActionAccessType["Decrypted"] = "decrypted";
})(ActionAccessType || (exports.ActionAccessType = ActionAccessType = {}));
var ActionVerb;
(function (ActionVerb) {
    ActionVerb["Get"] = "get";
    ActionVerb["Render"] = "render";
    ActionVerb["Show"] = "show";
    ActionVerb["Post"] = "post";
    ActionVerb["Nested"] = "nested";
})(ActionVerb || (exports.ActionVerb = ActionVerb = {}));
