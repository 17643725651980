import { downloadBlobOnAndroid } from '@/NativeMobileWeb/DownloadBlobOnAndroid';
import { shareBlobOnMobile } from '@/NativeMobileWeb/ShareBlobOnMobile';
import { Platform } from '@standardnotes/snjs';
export const downloadOrShareBlobBasedOnPlatform = async (dto) => {
    var _a;
    if (!dto.isNativeMobileWeb) {
        dto.archiveService.downloadData(dto.blob, dto.filename);
        return;
    }
    if (dto.mobileDevice && dto.platform === Platform.Ios) {
        void shareBlobOnMobile(dto.mobileDevice, dto.isNativeMobileWeb, dto.blob, dto.filename);
        return;
    }
    if (dto.mobileDevice && dto.platform === Platform.Android) {
        void downloadBlobOnAndroid(dto.mobileDevice, dto.blob, dto.filename, (_a = dto.showToastOnAndroid) !== null && _a !== void 0 ? _a : true);
        return;
    }
};
