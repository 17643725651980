import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import { formatDateForContextMenu } from '@/Utils/DateUtils';
import { getIconForItem } from '@/Utils/Items/Icons/getIconForItem';
import { formatSizeToReadableString } from '@standardnotes/filepicker';
import { FileItem } from '@standardnotes/snjs';
import { KeyboardKey } from '@standardnotes/ui-services';
import { useRef, useState } from 'react';
import { useApplication } from '../ApplicationProvider';
import { FileItemActionType } from '../AttachedFilesPopover/PopoverFileItemAction';
import Icon from '../Icon/Icon';
import MenuItem from '../Menu/MenuItem';
import Popover from '../Popover/Popover';
import HorizontalSeparator from '../Shared/HorizontalSeparator';
import LinkedFileMenuOptions from './LinkedFileMenuOptions';
import LinkedItemMeta from './LinkedItemMeta';
export const LinkedItemsSectionItem = ({ activateItem, item, searchQuery, unlinkItem, handleFileAction, }) => {
    var _a;
    const menuButtonRef = useRef(null);
    const application = useApplication();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen((open) => !open);
    const [isRenamingFile, setIsRenamingFile] = useState(false);
    const [icon, className] = getIconForItem(item, application);
    const title = (_a = item.title) !== null && _a !== void 0 ? _a : '';
    const renameFile = async (name) => {
        if (!(item instanceof FileItem)) {
            return;
        }
        await handleFileAction({
            type: FileItemActionType.RenameFile,
            payload: {
                file: item,
                name: name,
            },
        });
        setIsRenamingFile(false);
    };
    return (_jsxs("div", { className: "relative flex items-center justify-between", children: [isRenamingFile && item instanceof FileItem ? (_jsxs("div", { className: "flex flex-grow items-center gap-4 py-2 pl-3 pr-12", children: [_jsx(Icon, { type: icon, className: classNames('flex-shrink-0', className) }), _jsx("input", { className: "min-w-0 flex-grow bg-default text-sm", defaultValue: title, onKeyDown: (event) => {
                            if (event.key === KeyboardKey.Escape) {
                                setIsRenamingFile(false);
                            }
                            else if (event.key === KeyboardKey.Enter) {
                                const newTitle = event.currentTarget.value;
                                void renameFile(newTitle);
                            }
                        }, ref: (node) => {
                            if (node) {
                                node.focus();
                            }
                        } })] })) : (_jsx("button", { className: "flex max-w-full flex-grow items-center justify-between gap-4 py-2 pl-3 pr-12 text-sm hover:bg-info-backdrop focus:bg-info-backdrop", onClick: () => activateItem(item), onContextMenu: (event) => {
                    event.preventDefault();
                    toggleMenu();
                }, children: _jsx(LinkedItemMeta, { item: item, searchQuery: searchQuery }) })), _jsx("button", { className: "absolute right-3 top-1/2 h-7 w-7 -translate-y-1/2 cursor-pointer rounded-full border-0 bg-transparent p-1 hover:bg-contrast", onClick: toggleMenu, ref: menuButtonRef, children: _jsx(Icon, { type: "more", className: "text-neutral" }) }), _jsxs(Popover, { title: "Options", open: isMenuOpen, togglePopover: toggleMenu, anchorElement: menuButtonRef, side: "bottom", align: "center", className: "py-2", children: [_jsxs(MenuItem, { onClick: () => {
                            unlinkItem();
                            toggleMenu();
                        }, children: [_jsx(Icon, { type: "link-off", className: "mr-2 text-danger" }), "Unlink"] }), item instanceof FileItem && (_jsx(LinkedFileMenuOptions, { file: item, closeMenu: toggleMenu, handleFileAction: handleFileAction, setIsRenamingFile: setIsRenamingFile })), _jsx(HorizontalSeparator, { classes: "my-2" }), _jsxs("div", { className: "mt-1 px-3 py-1 text-xs font-medium text-neutral", children: [_jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "Created at:" }), " ", formatDateForContextMenu(item.created_at)] }), _jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "Modified at:" }), " ", formatDateForContextMenu(item.userModifiedDate)] }), _jsxs("div", { className: "mb-1", children: [_jsx("span", { className: "font-semibold", children: "ID:" }), " ", item.uuid] }), item instanceof FileItem && (_jsxs("div", { children: [_jsx("span", { className: "font-semibold", children: "Size:" }), " ", formatSizeToReadableString(item.decryptedSize)] }))] })] })] }));
};
