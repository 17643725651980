import { GetSortedPayloadsByPriority, namespacedKey, RawStorageKey, } from '@standardnotes/snjs';
import { Database } from '../Database';
export class WebOrDesktopDevice {
    constructor(appVersion) {
        this.appVersion = appVersion;
        this.databases = [];
    }
    setApplication(application) {
        const database = new Database(application.identifier, application.alerts);
        this.databases.push(database);
    }
    removeApplication(application) {
        const database = this.databaseForIdentifier(application.identifier);
        if (database) {
            database.deinit();
            this.databases = this.databases.filter((db) => db !== database);
        }
    }
    deinit() {
        for (const database of this.databases) {
            database.deinit();
        }
        this.databases = [];
    }
    async getJsonParsedRawStorageValue(key) {
        const value = await this.getRawStorageValue(key);
        if (value == undefined) {
            return undefined;
        }
        try {
            return JSON.parse(value);
        }
        catch (e) {
            return value;
        }
    }
    databaseForIdentifier(identifier) {
        return this.databases.find((database) => database.databaseName === identifier);
    }
    async clearAllDataFromDevice(workspaceIdentifiers) {
        await this.clearRawKeychainValue();
        await this.removeAllRawStorageValues();
        await Database.deleteAll(workspaceIdentifiers);
        return { killsApplication: false };
    }
    async getRawStorageValue(key) {
        const result = localStorage.getItem(key);
        if (result == undefined) {
            return undefined;
        }
        return result;
    }
    async setRawStorageValue(key, value) {
        localStorage.setItem(key, value);
    }
    async removeRawStorageValue(key) {
        localStorage.removeItem(key);
    }
    async removeAllRawStorageValues() {
        localStorage.clear();
    }
    async removeRawStorageValuesForIdentifier(identifier) {
        await this.removeRawStorageValue(namespacedKey(identifier, RawStorageKey.SnjsVersion));
        await this.removeRawStorageValue(namespacedKey(identifier, RawStorageKey.StorageObject));
    }
    async openDatabase(identifier) {
        this.databaseForIdentifier(identifier).unlock();
        return new Promise((resolve, reject) => {
            this.databaseForIdentifier(identifier)
                .openDatabase(() => {
                resolve({ isNewDatabase: true });
            })
                .then(() => {
                resolve({ isNewDatabase: false });
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    async getDatabaseLoadChunks(options, identifier) {
        const entries = await this.getAllDatabaseEntries(identifier);
        const { itemsKeyPayloads, keySystemRootKeyPayloads, keySystemItemsKeyPayloads, contentTypePriorityPayloads, remainingPayloads, } = GetSortedPayloadsByPriority(entries, options);
        const itemsKeysChunk = {
            entries: itemsKeyPayloads,
        };
        const keySystemRootKeysChunk = {
            entries: keySystemRootKeyPayloads,
        };
        const keySystemItemsKeysChunk = {
            entries: keySystemItemsKeyPayloads,
        };
        const contentTypePriorityChunk = {
            entries: contentTypePriorityPayloads,
        };
        const remainingPayloadsChunks = [];
        for (let i = 0; i < remainingPayloads.length; i += options.batchSize) {
            remainingPayloadsChunks.push({
                entries: remainingPayloads.slice(i, i + options.batchSize),
            });
        }
        const result = {
            fullEntries: {
                itemsKeys: itemsKeysChunk,
                keySystemRootKeys: keySystemRootKeysChunk,
                keySystemItemsKeys: keySystemItemsKeysChunk,
                remainingChunks: [contentTypePriorityChunk, ...remainingPayloadsChunks],
            },
            remainingChunksItemCount: contentTypePriorityPayloads.length + remainingPayloads.length,
        };
        return result;
    }
    async getAllDatabaseEntries(identifier) {
        return this.databaseForIdentifier(identifier).getAllPayloads();
    }
    getDatabaseEntries(identifier, keys) {
        return this.databaseForIdentifier(identifier).getPayloadsForKeys(keys);
    }
    async saveDatabaseEntry(payload, identifier) {
        return this.databaseForIdentifier(identifier).savePayload(payload);
    }
    async saveDatabaseEntries(payloads, identifier) {
        return this.databaseForIdentifier(identifier).savePayloads(payloads);
    }
    async removeDatabaseEntry(id, identifier) {
        return this.databaseForIdentifier(identifier).deletePayload(id);
    }
    async removeAllDatabaseEntries(identifier) {
        return this.databaseForIdentifier(identifier).clearAllPayloads();
    }
    async getNamespacedKeychainValue(identifier) {
        const keychain = await this.getKeychainValue();
        if (!keychain) {
            return;
        }
        return keychain[identifier];
    }
    async setNamespacedKeychainValue(value, identifier) {
        let keychain = await this.getKeychainValue();
        if (!keychain) {
            keychain = {};
        }
        return this.setKeychainValue({
            ...keychain,
            [identifier]: value,
        });
    }
    async clearNamespacedKeychainValue(identifier) {
        const keychain = await this.getKeychainValue();
        if (!keychain) {
            return;
        }
        delete keychain[identifier];
        return this.setKeychainValue(keychain);
    }
    setRawKeychainValue(value) {
        return this.setKeychainValue(value);
    }
    openUrl(url) {
        const win = window.open(url, '_blank');
        if (win) {
            win.focus();
        }
    }
    async performSoftReset() {
        window.location.reload();
    }
}
