import { logWithColor } from '@standardnotes/utils';
export const isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
export var LoggingDomain;
(function (LoggingDomain) {
    LoggingDomain[LoggingDomain["FilesPackage"] = 0] = "FilesPackage";
})(LoggingDomain || (LoggingDomain = {}));
const LoggingStatus = {
    [LoggingDomain.FilesPackage]: false,
};
const DomainColor = {
    [LoggingDomain.FilesPackage]: 'green',
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(domain, ...args) {
    if (!isDev || !LoggingStatus[domain]) {
        return;
    }
    logWithColor(LoggingDomain[domain], DomainColor[domain], ...args);
}
