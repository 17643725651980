import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isPayloadSourceRetrieved, NativeFeatureIdentifier, FeatureStatus, GetSuperNoteFeature, EditorLineHeightValues, WebAppEvent, LocalPrefKey, } from '@standardnotes/snjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BlocksEditor } from './BlocksEditor';
import { BlocksEditorComposer } from './BlocksEditorComposer';
import { ItemSelectionPlugin } from './Plugins/ItemSelectionPlugin/ItemSelectionPlugin';
import { FileNode } from './Plugins/EncryptedFilePlugin/Nodes/FileNode';
import FilePlugin from './Plugins/EncryptedFilePlugin/FilePlugin';
import { ErrorBoundary } from '@/Utils/ErrorBoundary';
import LinkingControllerProvider from '../../Controllers/LinkingControllerProvider';
import { BubbleNode } from './Plugins/ItemBubblePlugin/Nodes/BubbleNode';
import ItemBubblePlugin from './Plugins/ItemBubblePlugin/ItemBubblePlugin';
import { NodeObserverPlugin } from './Plugins/NodeObserverPlugin/NodeObserverPlugin';
import FilesControllerProvider from '@/Controllers/FilesControllerProvider';
import { ChangeContentCallbackPlugin, } from './Plugins/ChangeContentCallback/ChangeContentCallback';
import { useCommandService } from '@/Components/CommandProvider';
import { SUPER_SHOW_MARKDOWN_PREVIEW, getPrimaryModifier } from '@standardnotes/ui-services';
import { SuperNoteMarkdownPreview } from './SuperNoteMarkdownPreview';
import GetMarkdownPlugin from './Plugins/GetMarkdownPlugin/GetMarkdownPlugin';
import { useResponsiveEditorFontSize } from '@/Utils/getPlaintextFontSize';
import ReadonlyPlugin from './Plugins/ReadonlyPlugin/ReadonlyPlugin';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import NotEntitledBanner from '../ComponentView/NotEntitledBanner';
import AutoFocusPlugin from './Plugins/AutoFocusPlugin';
import { useLocalPreference } from '@/Hooks/usePreference';
import BlockPickerMenuPlugin from './Plugins/BlockPickerPlugin/BlockPickerPlugin';
import { EditorEventSource } from '@/Types/EditorEventSource';
import { ElementIds } from '@/Constants/ElementIDs';
export const SuperNotePreviewCharLimit = 160;
export const SuperEditor = ({ application, linkingController, filesController, spellcheck, controller, readonly, onFocus, onBlur, }) => {
    const note = useRef(controller.item);
    const changeEditorFunction = useRef();
    const ignoreNextChange = useRef(false);
    const [showMarkdownPreview, setShowMarkdownPreview] = useState(false);
    const getMarkdownPlugin = useRef(null);
    const [featureStatus, setFeatureStatus] = useState(FeatureStatus.Entitled);
    useEffect(() => {
        setFeatureStatus(application.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.SuperEditor).getValue(), {
            inContextOfItem: note.current,
        }));
    }, [application.features]);
    const commandService = useCommandService();
    useEffect(() => {
        return commandService.addCommandHandler({
            command: SUPER_SHOW_MARKDOWN_PREVIEW,
            category: 'Super notes',
            description: 'Show markdown preview for current note',
            onKeyDown: () => setShowMarkdownPreview(true),
        });
    }, [commandService]);
    useEffect(() => {
        const platform = application.platform;
        const primaryModifier = getPrimaryModifier(application.platform);
        return commandService.registerExternalKeyboardShortcutHelpItems([
            {
                key: 'b',
                modifiers: [primaryModifier],
                description: 'Bold',
                category: 'Formatting',
                platform: platform,
            },
            {
                key: 'i',
                modifiers: [primaryModifier],
                description: 'Italic',
                category: 'Formatting',
                platform: platform,
            },
            {
                key: 'u',
                modifiers: [primaryModifier],
                description: 'Underline',
                category: 'Formatting',
                platform: platform,
            },
            {
                key: 'k',
                modifiers: [primaryModifier],
                description: 'Link',
                category: 'Formatting',
                platform: platform,
            },
        ]);
    }, [application.platform, commandService]);
    const closeMarkdownPreview = useCallback(() => {
        setShowMarkdownPreview(false);
    }, []);
    useEffect(() => {
        return application.actions.addPayloadRequestHandler((uuid) => {
            var _a, _b;
            if (uuid === note.current.uuid) {
                const basePayload = note.current.payload.ejected();
                return {
                    ...basePayload,
                    content: {
                        ...basePayload.content,
                        text: (_b = (_a = getMarkdownPlugin.current) === null || _a === void 0 ? void 0 : _a.getMarkdown()) !== null && _b !== void 0 ? _b : basePayload.content.text,
                    },
                };
            }
        });
    }, [application]);
    const handleChange = useCallback(async (value, preview) => {
        if (ignoreNextChange.current === true) {
            ignoreNextChange.current = false;
            return;
        }
        void controller.saveAndAwaitLocalPropagation({
            text: value,
            isUserModified: true,
            previews: {
                previewPlain: preview,
                previewHtml: undefined,
            },
        });
    }, [controller]);
    const handleBubbleRemove = useCallback((itemUuid) => {
        const item = application.items.findItem(itemUuid);
        if (item) {
            linkingController.unlinkItemFromSelectedItem(item).catch(console.error);
        }
    }, [linkingController, application]);
    useEffect(() => {
        const disposer = controller.addNoteInnerValueChangeObserver((updatedNote, source) => {
            var _a;
            if (updatedNote.uuid !== note.current.uuid) {
                throw Error('Editor received changes for non-current note');
            }
            if (isPayloadSourceRetrieved(source)) {
                ignoreNextChange.current = true;
                (_a = changeEditorFunction.current) === null || _a === void 0 ? void 0 : _a.call(changeEditorFunction, updatedNote.text);
            }
            note.current = updatedNote;
        });
        return disposer;
    }, [controller, controller.item.uuid]);
    const [lineHeight] = useLocalPreference(LocalPrefKey.EditorLineHeight);
    const [fontSize] = useLocalPreference(LocalPrefKey.EditorFontSize);
    const responsiveFontSize = useResponsiveEditorFontSize(fontSize, false);
    const ref = useRef(null);
    useEffect(() => {
        const invalidURLClickFix = (event) => {
            var _a;
            if (event.target.tagName !== 'A') {
                return;
            }
            const isAbsoluteLink = (_a = event.target.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.startsWith('http');
            if (!isAbsoluteLink) {
                event.preventDefault();
            }
        };
        const element = ref.current;
        if (element) {
            element.addEventListener('click', invalidURLClickFix);
        }
        return () => {
            if (element) {
                element.removeEventListener('click', invalidURLClickFix);
            }
        };
    }, []);
    const handleFocus = useCallback((event) => {
        application.notifyWebEvent(WebAppEvent.EditorDidFocus, { eventSource: EditorEventSource.UserInteraction });
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
    }, [application, onFocus]);
    return (_jsxs("div", { id: ElementIds.SuperEditor, className: "font-editor relative flex h-full w-full flex-col", style: {
            '--line-height': EditorLineHeightValues[lineHeight],
            '--font-size': responsiveFontSize,
        }, ref: ref, children: [featureStatus !== FeatureStatus.Entitled && (_jsx(NotEntitledBanner, { featureStatus: featureStatus, feature: GetSuperNoteFeature() })), _jsxs(ErrorBoundary, { children: [_jsx(LinkingControllerProvider, { controller: linkingController, children: _jsx(FilesControllerProvider, { controller: filesController, children: _jsx(BlocksEditorComposer, { readonly: note.current.locked || readonly, initialValue: note.current.text, children: _jsxs(BlocksEditor, { onChange: handleChange, className: "blocks-editor h-full resize-none", previewLength: SuperNotePreviewCharLimit, spellcheck: spellcheck, readonly: note.current.locked || readonly, onFocus: handleFocus, onBlur: onBlur, children: [_jsx(ItemSelectionPlugin, { currentNote: note.current }), _jsx(FilePlugin, { currentNote: note.current }), _jsx(ItemBubblePlugin, {}), _jsx(GetMarkdownPlugin, { ref: getMarkdownPlugin }), _jsx(ChangeContentCallbackPlugin, { providerCallback: (callback) => (changeEditorFunction.current = callback) }), _jsx(NodeObserverPlugin, { nodeType: BubbleNode, onRemove: handleBubbleRemove }), _jsx(NodeObserverPlugin, { nodeType: FileNode, onRemove: handleBubbleRemove }), readonly === undefined && _jsx(ReadonlyPlugin, { note: note.current }), _jsx(AutoFocusPlugin, { isEnabled: controller.isTemplateNote }), _jsx(BlockPickerMenuPlugin, {})] }) }) }) }), _jsx(ModalOverlay, { isOpen: showMarkdownPreview, close: closeMarkdownPreview, children: _jsx(SuperNoteMarkdownPreview, { note: note.current, closeDialog: closeMarkdownPreview }) })] })] }));
};
