import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeyboardKey } from '@standardnotes/ui-services';
import { useState, useCallback, useRef } from 'react';
import SearchOptions from '@/Components/SearchOptions/SearchOptions';
import Icon from '../Icon/Icon';
import DecoratedInput from '../Input/DecoratedInput';
import { observer } from 'mobx-react-lite';
import ClearInputButton from '../ClearInputButton/ClearInputButton';
import { ElementIds } from '@/Constants/ElementIDs';
const SearchBar = ({ itemListController, searchOptionsController, hideOptions = false }) => {
    const searchInputRef = useRef(null);
    const { noteFilterText, setNoteFilterText, clearFilterText, onFilterEnter } = itemListController;
    const [focusedSearch, setFocusedSearch] = useState(false);
    const onNoteFilterTextChange = useCallback((text) => {
        setNoteFilterText(text);
    }, [setNoteFilterText]);
    const onNoteFilterKeyUp = useCallback((e) => {
        if (e.key === KeyboardKey.Enter) {
            onFilterEnter();
        }
    }, [onFilterEnter]);
    const onSearchFocus = useCallback(() => setFocusedSearch(true), []);
    const onSearchBlur = useCallback(() => setFocusedSearch(false), []);
    const onClearSearch = useCallback(() => {
        var _a;
        clearFilterText();
        (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [clearFilterText]);
    return (_jsxs("div", { className: "pb-0.5 pt-3", role: "search", children: [_jsx(DecoratedInput, { autocomplete: false, id: ElementIds.SearchBar, className: {
                    container: 'px-1',
                    input: 'text-base placeholder:text-passive-0 lg:text-sm',
                }, placeholder: 'Search...', value: noteFilterText, ref: searchInputRef, onBlur: onSearchBlur, onChange: onNoteFilterTextChange, onFocus: onSearchFocus, onKeyUp: onNoteFilterKeyUp, left: [_jsx(Icon, { type: "search", className: "mr-1 h-4.5 w-4.5 flex-shrink-0 text-passive-1" })], right: [noteFilterText && _jsx(ClearInputButton, { onClick: onClearSearch })], roundedFull: true }), (focusedSearch || noteFilterText) && !hideOptions && (_jsx("div", { className: "animate-fade-from-top", children: _jsx(SearchOptions, { searchOptions: searchOptionsController }) }))] }));
};
export default observer(SearchBar);
