import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import CalendarDay from './CalendarDay';
import { CalendarDays, CalendarDaysLeap, CalendarDaysOfTheWeek } from './Constants';
import { createActivityRecord, dateToDateOnlyString, isLeapYear, getStartDayOfMonth } from './CalendarUtilts';
import { areDatesInSameDay } from '@/Utils/DateUtils';
const Calendar = ({ activities, startDate, onDateSelect, selectedDay, className }) => {
    const activityMap = useMemo(() => createActivityRecord(activities), [activities]);
    const [date, setDate] = useState(startDate || new Date());
    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [startDay, setStartDay] = useState(getStartDayOfMonth(date));
    useEffect(() => {
        setDate(startDate);
        setMonth(startDate.getMonth());
        setYear(startDate.getFullYear());
        setStartDay(getStartDayOfMonth(startDate));
    }, [startDate]);
    const today = new Date();
    const dayBundle = isLeapYear(year) ? CalendarDaysLeap : CalendarDays;
    const days = Array(dayBundle[month] + (startDay - 1)).fill(null);
    return (_jsx("div", { className: `w-300 ${className} min-h-[210px]`, children: _jsxs("div", { className: "ml-auto mr-auto w-70", children: [_jsx("div", { className: "flex w-full flex-wrap", children: CalendarDaysOfTheWeek.map((d) => (_jsx("div", { className: 'flex h-8 w-[14.2%] items-center justify-center', children: d }, d))) }), _jsx("div", { className: "flex w-full flex-wrap", children: days.map((_, index) => {
                        const dayIndex = index - (startDay - 2);
                        const date = new Date(year, month, dayIndex);
                        const day = date.getDate();
                        const activities = activityMap[dateToDateOnlyString(date)] || [];
                        const isTemplate = selectedDay && areDatesInSameDay(selectedDay, date);
                        const type = activities.length > 0 ? 'item' : isTemplate ? 'template' : 'empty';
                        return (_jsx(CalendarDay, { isLastMonth: dayIndex <= 0, day: day, isToday: areDatesInSameDay(date, today), onClick: () => onDateSelect(date), type: type }, index));
                    }) })] }) }));
};
export default Calendar;
