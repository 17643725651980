"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTagToParentTagReference = exports.isLegacyTagToNoteReference = exports.isReference = exports.isLegacyAnonymousReference = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const ContenteReferenceType_1 = require("./ContenteReferenceType");
const isLegacyAnonymousReference = (x) => {
    return x.reference_type === undefined;
};
exports.isLegacyAnonymousReference = isLegacyAnonymousReference;
const isReference = (x) => {
    return x.reference_type !== undefined;
};
exports.isReference = isReference;
const isLegacyTagToNoteReference = (x, currentItem) => {
    const isReferenceToANote = x.content_type === domain_core_1.ContentType.TYPES.Note;
    const isReferenceFromATag = currentItem.content_type === domain_core_1.ContentType.TYPES.Tag;
    return isReferenceToANote && isReferenceFromATag;
};
exports.isLegacyTagToNoteReference = isLegacyTagToNoteReference;
const isTagToParentTagReference = (x) => {
    return (0, exports.isReference)(x) && x.reference_type === ContenteReferenceType_1.ContentReferenceType.TagToParentTag;
};
exports.isTagToParentTagReference = isTagToParentTagReference;
