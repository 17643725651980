"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paths = void 0;
const SessionPaths = {
    refreshSession: '/v1/sessions/refresh',
};
const RecoveryPaths = {
    generateRecoveryCodes: '/v1/recovery/codes',
    recoveryKeyParams: '/v1/recovery/login-params',
    signInWithRecoveryCodes: '/v1/recovery/login',
};
exports.Paths = {
    v1: Object.assign(Object.assign({}, SessionPaths), RecoveryPaths),
};
