import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import BlocksEditorTheme from './Lexical/Theme/Theme';
import { BlockEditorNodes } from './Lexical/Nodes/AllNodes';
export const BlocksEditorComposer = ({ initialValue, children, readonly, nodes = [], }) => {
    return (_jsx(LexicalComposer, { initialConfig: {
            namespace: 'BlocksEditor',
            theme: BlocksEditorTheme,
            editable: !readonly,
            onError: (error) => console.error(error),
            editorState: typeof initialValue === 'string' && initialValue.length === 0 ? undefined : initialValue,
            nodes: [...nodes, ...BlockEditorNodes],
        }, children: _jsx(_Fragment, { children: children }) }));
};
