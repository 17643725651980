import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { classNames } from '@standardnotes/utils';
import { isHandlingFileDrag } from '@/Utils/DragTypeCheck';
import { StreamingFileReader } from '@standardnotes/filepicker';
import { useMemo, useState, createContext, useRef, useCallback, useEffect, useContext, memo } from 'react';
import Portal from './Portal/Portal';
import { ElementIds } from '@/Constants/ElementIDs';
export const FileDnDContext = createContext(null);
export const useFileDragNDrop = () => {
    const value = useContext(FileDnDContext);
    if (!value) {
        throw new Error('Current component must be a child of <FileDragNDropProvider />');
    }
    return value;
};
const FileDragOverlayClassName = 'overlay pointer-events-none absolute top-0 left-0 z-footer-bar h-full w-full border-2 border-info before:block before:h-full before:w-full before:bg-info before:opacity-20';
const MemoizedChildren = memo(({ children }) => {
    return _jsx(_Fragment, { children: children });
});
const FileDragNDropProvider = ({ application, children }) => {
    const premiumModal = usePremiumModal();
    const [isDraggingFiles, setIsDraggingFiles] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const fileDragOverlayRef = useRef(null);
    const addOverlayToElement = useCallback((target) => {
        if (fileDragOverlayRef.current) {
            const targetBoundingRect = target.getBoundingClientRect();
            fileDragOverlayRef.current.style.width = `${targetBoundingRect.width}px`;
            fileDragOverlayRef.current.style.height = `${targetBoundingRect.height}px`;
            fileDragOverlayRef.current.style.transform = `translate(${targetBoundingRect.x}px, ${targetBoundingRect.y}px)`;
        }
    }, []);
    const removeOverlayFromElement = useCallback(() => {
        if (fileDragOverlayRef.current) {
            fileDragOverlayRef.current.style.width = '';
            fileDragOverlayRef.current.style.height = '';
            fileDragOverlayRef.current.style.transform = '';
        }
    }, []);
    const dragTargets = useRef(new Map());
    const addDragTarget = useCallback((target, data) => {
        target.setAttribute('data-file-drag-target', '');
        dragTargets.current.set(target, data);
    }, []);
    const removeDragTarget = useCallback((target) => {
        target.removeAttribute('data-file-drag-target');
        dragTargets.current.delete(target);
    }, []);
    const dragCounter = useRef(0);
    const resetState = useCallback(() => {
        setIsDraggingFiles(false);
        setTooltipText('');
        removeOverlayFromElement();
    }, [removeOverlayFromElement]);
    const handleDrag = useCallback((event) => {
        if (isHandlingFileDrag(event, application)) {
            event.preventDefault();
            event.stopPropagation();
        }
    }, [application]);
    const handleDragStart = useCallback((event) => {
        if (isHandlingFileDrag(event, application)) {
            event.preventDefault();
            event.stopPropagation();
            if (event.dataTransfer) {
                event.dataTransfer.clearData();
            }
        }
    }, [application]);
    const handleDragIn = useCallback((event) => {
        var _a, _b;
        if (!isHandlingFileDrag(event, application)) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        removeOverlayFromElement();
        let closestDragTarget = null;
        if (event.target instanceof HTMLElement) {
            closestDragTarget = event.target.closest('[data-file-drag-target]');
        }
        dragCounter.current = dragCounter.current + 1;
        if ((_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items.length) {
            setIsDraggingFiles(true);
            if (closestDragTarget) {
                addOverlayToElement(closestDragTarget);
                const tooltipText = (_b = dragTargets.current.get(closestDragTarget)) === null || _b === void 0 ? void 0 : _b.tooltipText;
                if (tooltipText) {
                    setTooltipText(tooltipText);
                }
            }
            else {
                setTooltipText('');
                removeOverlayFromElement();
            }
        }
    }, [addOverlayToElement, application, removeOverlayFromElement]);
    const handleDragOut = useCallback((event) => {
        if (!isHandlingFileDrag(event, application)) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        dragCounter.current = dragCounter.current - 1;
        if (dragCounter.current > 0) {
            return;
        }
        resetState();
    }, [application, resetState]);
    const handleDrop = useCallback((event) => {
        var _a;
        if (!isHandlingFileDrag(event, application)) {
            resetState();
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        let closestDragTarget = null;
        if (event.target instanceof HTMLElement) {
            closestDragTarget = event.target.closest('[data-file-drag-target]');
        }
        resetState();
        if (!application.featuresController.entitledToFiles) {
            premiumModal.activate('Files');
            return;
        }
        if ((_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items.length) {
            Array.from(event.dataTransfer.items).forEach(async (item) => {
                const fileOrHandle = StreamingFileReader.available()
                    ? (await item.getAsFileSystemHandle())
                    : item.getAsFile();
                if (!fileOrHandle) {
                    return;
                }
                const dragTarget = closestDragTarget ? dragTargets.current.get(closestDragTarget) : undefined;
                if (dragTarget === null || dragTarget === void 0 ? void 0 : dragTarget.handleFileUpload) {
                    dragTarget.handleFileUpload(fileOrHandle);
                    return;
                }
                const uploadedFile = await application.filesController.uploadNewFile(fileOrHandle, {
                    note: dragTarget === null || dragTarget === void 0 ? void 0 : dragTarget.note,
                });
                if (!uploadedFile) {
                    return;
                }
                if (dragTarget === null || dragTarget === void 0 ? void 0 : dragTarget.callback) {
                    dragTarget.callback(uploadedFile);
                }
            });
            dragCounter.current = 0;
        }
    }, [application, premiumModal, resetState]);
    useEffect(() => {
        const appGroupRoot = document.getElementById(ElementIds.RootId);
        if (!appGroupRoot) {
            return;
        }
        appGroupRoot.addEventListener('dragstart', handleDragStart);
        appGroupRoot.addEventListener('dragenter', handleDragIn);
        appGroupRoot.addEventListener('dragleave', handleDragOut);
        appGroupRoot.addEventListener('dragover', handleDrag);
        appGroupRoot.addEventListener('drop', handleDrop);
        return () => {
            appGroupRoot.removeEventListener('dragstart', handleDragStart);
            appGroupRoot.removeEventListener('dragenter', handleDragIn);
            appGroupRoot.removeEventListener('dragleave', handleDragOut);
            appGroupRoot.removeEventListener('dragover', handleDrag);
            appGroupRoot.removeEventListener('drop', handleDrop);
        };
    }, [handleDragIn, handleDrop, handleDrag, handleDragOut, handleDragStart]);
    const contextValue = useMemo(() => {
        return {
            isDraggingFiles,
            addDragTarget,
            removeDragTarget,
        };
    }, [addDragTarget, isDraggingFiles, removeDragTarget]);
    return (_jsxs(FileDnDContext.Provider, { value: contextValue, children: [_jsx(MemoizedChildren, { children: children }), isDraggingFiles ? (_jsx(_Fragment, { children: _jsx("div", { className: "pointer-events-none absolute bottom-8 left-1/2 z-dropdown-menu -translate-x-1/2 rounded border-2 border-info bg-default px-5 py-3 shadow-main", children: tooltipText.length ? tooltipText : 'Drop your files to upload them' }) })) : null, _jsx(Portal, { children: _jsx("div", { className: classNames(FileDragOverlayClassName, isDraggingFiles ? 'visible' : 'invisible'), ref: fileDragOverlayRef }) })] }));
};
export default FileDragNDropProvider;
