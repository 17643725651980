import { NativeFeatureIdentifier } from '@standardnotes/features';
import { NoteType } from '@standardnotes/snjs';
import { makeObservable, observable, action } from 'mobx';
import { PredicateKeypathTypes } from './PredicateKeypaths';
const getEmptyPredicate = () => {
    return {
        keypath: 'title',
        operator: '!=',
        value: '',
    };
};
export class CompoundPredicateBuilderController {
    constructor() {
        this.operator = 'and';
        this.predicates = [getEmptyPredicate()];
        this.setOperator = (operator) => {
            this.operator = operator;
        };
        this.setPredicate = (index, predicate) => {
            const predicateAtIndex = this.predicates[index];
            this.predicates[index] = {
                ...predicateAtIndex,
                ...predicate,
            };
        };
        this.changePredicateKeypath = (index, keypath) => {
            const currentKeyPath = this.predicates[index].keypath;
            const currentKeyPathType = PredicateKeypathTypes[currentKeyPath];
            const newKeyPathType = PredicateKeypathTypes[keypath];
            if (currentKeyPathType !== newKeyPathType) {
                switch (newKeyPathType) {
                    case 'string':
                        this.setPredicate(index, { value: '' });
                        break;
                    case 'boolean':
                        this.setPredicate(index, { value: true });
                        break;
                    case 'number':
                        this.setPredicate(index, { value: 0 });
                        break;
                    case 'noteType':
                        this.setPredicate(index, { value: Object.values(NoteType)[0] });
                        break;
                    case 'editorIdentifier':
                        this.setPredicate(index, { value: NativeFeatureIdentifier.TYPES.PlainEditor });
                        break;
                    case 'date':
                        this.setPredicate(index, { value: '1.days.ago' });
                        break;
                }
            }
            this.setPredicate(index, { keypath });
        };
        this.addPredicate = () => {
            this.predicates.push(getEmptyPredicate());
        };
        this.removePredicate = (index) => {
            this.predicates.splice(index, 1);
        };
        makeObservable(this, {
            operator: observable,
            setOperator: action,
            predicates: observable,
            setPredicate: action,
            addPredicate: action,
            removePredicate: action,
        });
    }
    toJson() {
        return {
            operator: this.operator,
            value: this.predicates,
        };
    }
    resetState() {
        this.operator = 'and';
        this.predicates = [getEmptyPredicate()];
    }
}
