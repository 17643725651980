import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FOCUSABLE_BUT_NOT_TABBABLE } from '@/Constants/Constants';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import WorkspaceSwitcherMenu from './WorkspaceSwitcherMenu';
import MenuItem from '@/Components/Menu/MenuItem';
import Popover from '@/Components/Popover/Popover';
import { MenuItemIconSize } from '@/Constants/TailwindClassNames';
const WorkspaceSwitcherOption = ({ mainApplicationGroup }) => {
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(MenuItem, { tabIndex: FOCUSABLE_BUT_NOT_TABBABLE, ref: buttonRef, onClick: toggleMenu, className: "justify-between", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { type: "user-switch", className: `mr-2 text-neutral ${MenuItemIconSize}` }), "Switch workspace"] }), _jsx(Icon, { type: "chevron-right", className: `text-neutral ${MenuItemIconSize}` })] }), _jsx(Popover, { title: "Switch workspace", align: "end", anchorElement: buttonRef, className: "pb-2", open: isOpen, side: "right", togglePopover: toggleMenu, children: _jsx(WorkspaceSwitcherMenu, { mainApplicationGroup: mainApplicationGroup }) })] }));
};
export default observer(WorkspaceSwitcherOption);
