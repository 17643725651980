import { BubbleNode } from './BubbleNode';
export function convertToBubbleElement(domNode) {
    const itemUuid = domNode.getAttribute('data-lexical-item-uuid');
    if (itemUuid) {
        const node = $createBubbleNode(itemUuid);
        return { node };
    }
    return null;
}
export function $createBubbleNode(itemUuid) {
    return new BubbleNode(itemUuid);
}
export function $isBubbleNode(node) {
    return node instanceof BubbleNode;
}
