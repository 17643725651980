import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useRef } from 'react';
import { STRING_DELETE_ACCOUNT_CONFIRMATION } from '@/Constants/Strings';
import Button from '@/Components/Button/Button';
import Icon from '../Icon/Icon';
import AlertDialog from '../AlertDialog/AlertDialog';
const ConfirmDeleteAccountModal = ({ application }) => {
    const closeDialog = useCallback(() => {
        application.accountMenuController.setDeletingAccount(false);
    }, [application.accountMenuController]);
    const cancelRef = useRef(null);
    const confirm = useCallback(() => {
        application.user.deleteAccount().catch(console.error);
        closeDialog();
    }, [application.user, closeDialog]);
    return (_jsxs(AlertDialog, { closeDialog: closeDialog, children: [_jsxs("div", { className: "flex items-center justify-between text-lg font-bold", children: ["Delete account?", _jsx("button", { className: "rounded p-1 font-bold hover:bg-contrast", onClick: closeDialog, children: _jsx(Icon, { type: "close" }) })] }), _jsx("div", { className: "sk-panel-row", children: _jsx("div", { children: _jsx("p", { className: "text-base text-foreground lg:text-sm", children: STRING_DELETE_ACCOUNT_CONFIRMATION }) }) }), _jsxs("div", { className: "mt-4 flex justify-end gap-2", children: [_jsx(Button, { ref: cancelRef, onClick: closeDialog, children: "Cancel" }), _jsx(Button, { primary: true, colorStyle: "danger", onClick: confirm, children: "Delete my account for good" })] })] }));
};
ConfirmDeleteAccountModal.displayName = 'ConfirmDeleteAccountModal';
const ConfirmDeleteAccountContainer = (props) => {
    if (!props.application.accountMenuController.deletingAccount) {
        return null;
    }
    return _jsx(ConfirmDeleteAccountModal, { ...props });
};
export default observer(ConfirmDeleteAccountContainer);
