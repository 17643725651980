import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spinner from '@/Components/Spinner/Spinner';
import { formatSizeToReadableString } from '@standardnotes/filepicker';
import { SettingName } from '@standardnotes/snjs';
import { useEffect, useState } from 'react';
import { Subtitle, Title } from '../../PreferencesComponents/Content';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
const FilesSection = ({ application }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [filesQuotaUsed, setFilesQuotaUsed] = useState(0);
    const [filesQuotaTotal, setFilesQuotaTotal] = useState(0);
    useEffect(() => {
        const getFilesQuota = async () => {
            const filesQuotaUsed = await application.settings.getSubscriptionSetting(SettingName.create(SettingName.NAMES.FileUploadBytesUsed).getValue());
            if (filesQuotaUsed) {
                setFilesQuotaUsed(parseFloat(filesQuotaUsed));
            }
            if (application.sessions.isSignedIntoFirstPartyServer()) {
                const filesQuotaTotal = await application.settings.getSubscriptionSetting(SettingName.create(SettingName.NAMES.FileUploadBytesLimit).getValue());
                if (filesQuotaTotal) {
                    setFilesQuotaTotal(parseFloat(filesQuotaTotal));
                }
            }
            setIsLoading(false);
        };
        getFilesQuota().catch(console.error);
    }, [application]);
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Files" }), _jsx(Subtitle, { children: "Storage Quota" }), isLoading ? (_jsx("div", { className: "mt-2", children: _jsx(Spinner, { className: "h-3 w-3" }) })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-1 mt-1", children: [_jsx("span", { className: "font-semibold", children: formatSizeToReadableString(filesQuotaUsed) }), " of", ' ', _jsx("span", { children: application.sessions.isSignedIntoFirstPartyServer()
                                        ? formatSizeToReadableString(filesQuotaTotal)
                                        : '∞' }), ' ', "used"] }), _jsx("progress", { className: "progress-bar w-full", "aria-label": "Files storage used", value: filesQuotaUsed, max: filesQuotaTotal })] }))] }) }));
};
export default FilesSection;
