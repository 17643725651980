import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dropdown from '@/Components/Dropdown/Dropdown';
import { Subtitle, Title } from '../../PreferencesComponents/Content';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useApplication } from '@/Components/ApplicationProvider';
import { getDropdownItemsForAllEditors } from '@/Utils/DropdownItemsForEditors';
import { FeatureStatus, NativeFeatureIdentifier, NewNoteTitleFormat, PrefDefaults, PrefKey, Uuid, classNames, } from '@standardnotes/snjs';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { NoteTitleFormatOptions } from '@/Components/ContentListView/Header/NoteTitleFormatOptions';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { ErrorBoundary } from '@/Utils/ErrorBoundary';
import { getDayjsFormattedString } from '@/Utils/GetDayjsFormattedString';
const PrefChangeDebounceTimeInMs = 25;
const HelpPageUrl = 'https://day.js.org/docs/en/display/format#list-of-all-available-formats';
const NewNoteDefaults = () => {
    const application = useApplication();
    const premiumModal = usePremiumModal();
    const [editorItems, setEditorItems] = useState([]);
    useEffect(() => {
        setEditorItems(getDropdownItemsForAllEditors(application));
    }, [application]);
    const [defaultEditorIdentifier, setDefaultEditorIdentifier] = useState(NativeFeatureIdentifier.TYPES.PlainEditor);
    const [newNoteTitleFormat, setNewNoteTitleFormat] = useState(NewNoteTitleFormat.CurrentDateAndTime);
    const [customNoteTitleFormat, setCustomNoteTitleFormat] = useState('');
    const reloadPreferences = useCallback(() => {
        const globalDefault = application.componentManager.getDefaultEditorIdentifier();
        setDefaultEditorIdentifier(globalDefault);
        setNewNoteTitleFormat(application.getPreference(PrefKey.NewNoteTitleFormat, PrefDefaults[PrefKey.NewNoteTitleFormat]));
        setCustomNoteTitleFormat(application.getPreference(PrefKey.CustomNoteTitleFormat, PrefDefaults[PrefKey.CustomNoteTitleFormat]));
    }, [application]);
    useEffect(() => {
        void reloadPreferences();
    }, [reloadPreferences]);
    const selectEditorForNewNoteDefault = useCallback((value) => {
        let identifier = undefined;
        const feature = NativeFeatureIdentifier.create(value);
        if (!feature.isFailed()) {
            identifier = feature.getValue();
        }
        else {
            const thirdPartyEditor = application.componentManager.findComponentWithPackageIdentifier(value);
            if (thirdPartyEditor) {
                identifier = Uuid.create(thirdPartyEditor.uuid).getValue();
            }
        }
        if (!identifier) {
            return;
        }
        if (application.features.getFeatureStatus(identifier) !== FeatureStatus.Entitled) {
            if (feature.getValue().value === NativeFeatureIdentifier.TYPES.SuperEditor) {
                premiumModal.showSuperDemo();
                return;
            }
            const editorItem = editorItems.find((item) => item.value === value);
            if (editorItem) {
                premiumModal.activate(editorItem.label);
            }
            return;
        }
        setDefaultEditorIdentifier(value);
        void application.setPreference(PrefKey.DefaultEditorIdentifier, value);
    }, [application, editorItems, premiumModal]);
    const setNewNoteTitleFormatChange = useCallback((value) => {
        setNewNoteTitleFormat(value);
        void application.setPreference(PrefKey.NewNoteTitleFormat, value);
    }, [application]);
    const debounceTimeoutRef = useRef();
    const handleCustomFormatInputChange = (event) => {
        const newFormat = event.currentTarget.value;
        setCustomNoteTitleFormat(newFormat);
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = window.setTimeout(async () => {
            void application.setPreference(PrefKey.CustomNoteTitleFormat, newFormat);
        }, PrefChangeDebounceTimeInMs);
    };
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "New Note Defaults" }), _jsxs("div", { className: "mt-2", children: [_jsxs("div", { children: [_jsx(Subtitle, { children: "Note Type" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { label: "Select the default note type", items: editorItems, value: defaultEditorIdentifier, onChange: (value) => selectEditorForNewNoteDefault(value) }) })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { children: [_jsx(Subtitle, { children: "Title Format" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { label: "Select the format for the note title", items: NoteTitleFormatOptions, value: newNoteTitleFormat, onChange: setNewNoteTitleFormatChange }) }), newNoteTitleFormat === NewNoteTitleFormat.CustomFormat && (_jsxs("div", { className: "mt-2", children: [_jsx("div", { className: "mt-2", children: _jsx("input", { className: classNames('w-full min-w-55 rounded border border-solid border-passive-3 bg-default px-2 py-1.5 text-base md:w-auto md:translucent-ui:bg-transparent lg:text-sm', 'focus-within:ring-2 focus-within:ring-info'), placeholder: "e.g. YYYY-MM-DD", value: customNoteTitleFormat, onChange: handleCustomFormatInputChange, spellCheck: false }) }), _jsxs("div", { className: "mt-3 text-neutral", children: [_jsx("span", { className: "font-bold", children: "Preview: " }), _jsx(ErrorBoundary, { children: _jsx("em", { children: getDayjsFormattedString(undefined, customNoteTitleFormat) }) })] }), _jsxs("div", { className: "mt-2 text-neutral", children: [_jsx("a", { className: "underline", href: HelpPageUrl, rel: "noreferrer", target: "_blank", onClick: (event) => {
                                                        if (application.isNativeMobileWeb()) {
                                                            event.preventDefault();
                                                            application.mobileDevice.openUrl(HelpPageUrl);
                                                        }
                                                    }, children: "Options" }), ". Use ", _jsx("code", { children: "[]" }), " to escape formatting."] })] }))] })] })] }) }));
};
export default NewNoteDefaults;
