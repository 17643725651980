import { jsx as _jsx } from "react/jsx-runtime";
import { IconNameToSvgMapping } from './IconNameToSvgMapping';
import { classNames } from '@standardnotes/utils';
import { LexicalIconNameToSvgMapping } from './LexicalIcons';
const ContainerDimensions = {
    small: 'w-3.5 h-3.5',
    medium: 'w-4 h-4',
    normal: 'w-5 h-5',
    large: 'w-6 h-6',
    custom: '',
};
const EmojiContainerDimensions = {
    small: 'w-4 h-4',
    medium: 'w-5 h-5',
    normal: 'w-5 h-5',
    large: 'w-7 h-6',
    custom: '',
};
const EmojiOffset = {
    small: '',
    medium: '',
    normal: '-mt-0.5',
    large: '',
    custom: '',
};
const EmojiSize = {
    small: 'text-xs',
    medium: 'text-sm',
    normal: 'text-base',
    large: 'text-lg',
    custom: '',
};
const getIconComponent = (type) => {
    return (IconNameToSvgMapping[type] ||
        LexicalIconNameToSvgMapping[type]);
};
export const isIconEmoji = (type) => {
    return getIconComponent(type) == undefined;
};
const Icon = ({ type, className = '', ariaLabel, size = 'normal', emojiSize }) => {
    const IconComponent = getIconComponent(type);
    if (!IconComponent) {
        return (_jsx("label", { className: classNames('fill-current', 'text-center', EmojiSize[emojiSize || size], EmojiContainerDimensions[emojiSize || size], EmojiOffset[emojiSize || size], className), children: type }));
    }
    return (_jsx(IconComponent, { className: `${ContainerDimensions[size]} fill-current ${className}`, role: "img", ...(ariaLabel ? { 'aria-label': ariaLabel } : { 'aria-hidden': true }) }));
};
export default Icon;
