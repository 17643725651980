import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Spinner from '../Spinner/Spinner';
import { useApplication } from '../ApplicationProvider';
import { useCallback, useEffect, useState } from 'react';
export const PreprocessingStep = ({ onContinue, setContinueEnabled, }) => {
    const application = useApplication();
    const [isProcessingSync, setIsProcessingSync] = useState(true);
    const [isProcessingMessages, setIsProcessingMessages] = useState(true);
    const [isProcessingInvites, setIsProcessingInvites] = useState(true);
    const [needsUserConfirmation, setNeedsUserConfirmation] = useState();
    const continueIfPossible = useCallback(() => {
        if (isProcessingMessages || isProcessingInvites || isProcessingSync) {
            setContinueEnabled(false);
            return;
        }
        if (needsUserConfirmation === 'yes') {
            setContinueEnabled(true);
            return;
        }
        onContinue();
    }, [
        isProcessingInvites,
        isProcessingMessages,
        isProcessingSync,
        needsUserConfirmation,
        onContinue,
        setContinueEnabled,
    ]);
    useEffect(() => {
        continueIfPossible();
    }, [isProcessingInvites, isProcessingMessages, isProcessingSync, continueIfPossible]);
    useEffect(() => {
        const processPendingSync = async () => {
            await application.sync.sync();
            setIsProcessingSync(false);
        };
        void processPendingSync();
    }, [application.sync]);
    useEffect(() => {
        const processPendingMessages = async () => {
            await application.asymmetric.downloadAndProcessInboundMessages();
            setIsProcessingMessages(false);
        };
        void processPendingMessages();
    }, [application.asymmetric]);
    useEffect(() => {
        const processPendingInvites = async () => {
            await application.vaultInvites.downloadInboundInvites();
            const hasPendingInvites = application.vaultInvites.getCachedPendingInviteRecords().length > 0;
            setNeedsUserConfirmation(hasPendingInvites ? 'yes' : 'no');
            setIsProcessingInvites(false);
        };
        void processPendingInvites();
    }, [application]);
    const isProcessing = isProcessingSync || isProcessingMessages || isProcessingInvites;
    if (isProcessing) {
        return (_jsxs("div", { className: "flex flex-row items-center gap-3", children: [_jsx(Spinner, { className: "h-3 w-3" }), _jsx("p", { className: "", children: "Checking for data conflicts..." })] }));
    }
    if (needsUserConfirmation === 'no') {
        return null;
    }
    return (_jsx("div", { className: "flex flex-col", children: _jsx("p", { children: "You have pending vault invites. Changing your password will delete these invites. It is recommended you accept or decline these invites before changing your password. If you choose to continue, these invites will be deleted." }) }));
};
