import { ApplicationEvent, } from '@standardnotes/snjs';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { AbstractViewController } from './Abstract/AbstractViewController';
export class SearchOptionsController extends AbstractViewController {
    constructor(protections, eventBus) {
        super(eventBus);
        this.protections = protections;
        this.includeProtectedContents = false;
        this.includeArchived = false;
        this.includeTrashed = false;
        this.toggleIncludeArchived = () => {
            this.includeArchived = !this.includeArchived;
        };
        this.toggleIncludeTrashed = () => {
            this.includeTrashed = !this.includeTrashed;
        };
        this.refreshIncludeProtectedContents = () => {
            this.includeProtectedContents = this.protections.hasUnprotectedAccessSession();
        };
        this.toggleIncludeProtectedContents = async () => {
            if (this.includeProtectedContents) {
                this.includeProtectedContents = false;
            }
            else {
                await this.protections.authorizeSearchingProtectedNotesText();
                runInAction(() => {
                    this.refreshIncludeProtectedContents();
                });
            }
        };
        makeObservable(this, {
            includeProtectedContents: observable,
            includeTrashed: observable,
            includeArchived: observable,
            toggleIncludeArchived: action,
            toggleIncludeTrashed: action,
            toggleIncludeProtectedContents: action,
            refreshIncludeProtectedContents: action,
        });
        eventBus.addEventHandler(this, ApplicationEvent.UnprotectedSessionBegan);
        eventBus.addEventHandler(this, ApplicationEvent.UnprotectedSessionExpired);
    }
    async handleEvent(event) {
        if (event.type === ApplicationEvent.UnprotectedSessionBegan) {
            this.refreshIncludeProtectedContents();
        }
        else if (event.type === ApplicationEvent.UnprotectedSessionExpired) {
            this.refreshIncludeProtectedContents();
        }
    }
}
