import { jsx as _jsx } from "react/jsx-runtime";
import IconButton from '@/Components/Button/IconButton';
import { CREATE_NEW_TAG_COMMAND, keyboardStringForShortcut } from '@standardnotes/ui-services';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useCommandService } from '../CommandProvider';
const TagsSectionAddButton = ({ tags }) => {
    const commandService = useCommandService();
    const shortcut = useMemo(() => keyboardStringForShortcut(commandService.keyboardShortcutForCommand(CREATE_NEW_TAG_COMMAND)), [commandService]);
    return (_jsx(IconButton, { focusable: true, icon: "add", title: `Create a new tag (${shortcut})`, className: "p-0 text-neutral", onClick: () => tags.createNewTemplate() }));
};
export default observer(TagsSectionAddButton);
