import { PrefDefaults, PrefKey } from '@standardnotes/models';
import { ContentType, PreferencesServiceEvent, UuidGenerator, } from '@standardnotes/snjs';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { AbstractViewController } from '@/Controllers/Abstract/AbstractViewController';
export class ImportModalController extends AbstractViewController {
    constructor(importer, navigationController, items, mutator, linkingController, preferences, eventBus) {
        super(eventBus);
        this.importer = importer;
        this.navigationController = navigationController;
        this.items = items;
        this.mutator = mutator;
        this.linkingController = linkingController;
        this.preferences = preferences;
        this.isVisible = false;
        this.addImportsToTag = false;
        this.shouldCreateTag = true;
        this.existingTagForImports = undefined;
        this.files = [];
        this.importTag = undefined;
        this.setIsVisible = (isVisible) => {
            this.isVisible = isVisible;
        };
        this.setAddImportsToTag = (addImportsToTag) => {
            this.preferences.setValue(PrefKey.AddImportsToTag, addImportsToTag).catch(console.error);
        };
        this.setShouldCreateTag = (shouldCreateTag) => {
            this.preferences.setValue(PrefKey.AlwaysCreateNewTagForImports, shouldCreateTag).catch(console.error);
        };
        this.setExistingTagForImports = (tag) => {
            this.preferences.setValue(PrefKey.ExistingTagForImports, tag === null || tag === void 0 ? void 0 : tag.uuid).catch(console.error);
        };
        this.getImportFromFile = (file, service) => {
            return {
                id: UuidGenerator.GenerateUuid(),
                file,
                service,
                status: 'pending',
            };
        };
        this.setFiles = (files, service) => {
            this.files = files.map((file) => this.getImportFromFile(file, service));
        };
        this.addFiles = (files, service) => {
            this.files = [...this.files, ...files.map((file) => this.getImportFromFile(file, service))];
        };
        this.updateFile = (file) => {
            this.files = this.files.map((f) => (f.id === file.id ? file : f));
        };
        this.removeFile = (id) => {
            this.files = this.files.filter((f) => f.id !== id);
        };
        this.setImportTag = (tag) => {
            this.importTag = tag;
        };
        this.close = () => {
            this.setIsVisible(false);
            if (this.importTag) {
                this.navigationController
                    .setSelectedTag(this.importTag, 'all', {
                    userTriggered: true,
                })
                    .catch(console.error);
            }
            this.setFiles([]);
            this.setImportTag(undefined);
        };
        this.parseAndImport = async () => {
            if (this.files.length === 0) {
                return;
            }
            const importedItems = [];
            for (const file of this.files) {
                if (!file.service) {
                    return;
                }
                this.updateFile({
                    ...file,
                    status: 'parsing',
                });
                try {
                    const { successful, errored } = await this.importer.importFromFile(file.file, file.service);
                    importedItems.push(...successful);
                    this.updateFile({
                        ...file,
                        status: 'finished',
                        successful,
                        errored,
                    });
                }
                catch (error) {
                    this.updateFile({
                        ...file,
                        status: 'error',
                        error: error instanceof Error ? error : new Error('Could not import file'),
                    });
                    console.error(error);
                }
            }
            if (!importedItems.length) {
                return;
            }
            if (this.addImportsToTag) {
                const currentDate = new Date();
                let importTag;
                if (this.shouldCreateTag) {
                    const importTagItem = this.items.createTemplateItem(ContentType.TYPES.Tag, {
                        title: `Imported on ${currentDate.toLocaleString()}`,
                        expanded: false,
                        iconString: '',
                        references: importedItems
                            .filter((payload) => payload.content_type === ContentType.TYPES.Note)
                            .map((payload) => ({
                            content_type: ContentType.TYPES.Note,
                            uuid: payload.uuid,
                        })),
                    });
                    importTag = await this.mutator.insertItem(importTagItem);
                }
                else if (this.existingTagForImports) {
                    try {
                        const latestExistingTag = this.items.findSureItem(this.existingTagForImports.uuid);
                        await Promise.all(importedItems
                            .filter((payload) => payload.content_type === ContentType.TYPES.Note)
                            .map(async (payload) => {
                            const note = this.items.findSureItem(payload.uuid);
                            await this.linkingController.addTagToItem(latestExistingTag, note, false);
                        }));
                        importTag = this.items.findSureItem(this.existingTagForImports.uuid);
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
                if (importTag) {
                    this.setImportTag(importTag);
                }
            }
        };
        makeObservable(this, {
            isVisible: observable,
            setIsVisible: action,
            addImportsToTag: observable,
            setAddImportsToTag: action,
            shouldCreateTag: observable,
            setShouldCreateTag: action,
            existingTagForImports: observable,
            setExistingTagForImports: action,
            files: observable,
            setFiles: action,
            addFiles: action,
            updateFile: action,
            removeFile: action,
            importTag: observable,
            setImportTag: action,
        });
        this.disposers.push(preferences.addEventObserver((event) => {
            if (event === PreferencesServiceEvent.PreferencesChanged) {
                runInAction(() => {
                    this.addImportsToTag = preferences.getValue(PrefKey.AddImportsToTag, PrefDefaults[PrefKey.AddImportsToTag]);
                    this.shouldCreateTag = preferences.getValue(PrefKey.AlwaysCreateNewTagForImports, PrefDefaults[PrefKey.AlwaysCreateNewTagForImports]);
                    const existingTagUuid = preferences.getValue(PrefKey.ExistingTagForImports);
                    this.existingTagForImports = existingTagUuid ? this.items.findItem(existingTagUuid) : undefined;
                });
            }
        }));
    }
}
