import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, memo } from 'react';
import { observer } from 'mobx-react-lite';
const CommandServiceContext = createContext(undefined);
export const useCommandService = () => {
    const value = useContext(CommandServiceContext);
    if (!value) {
        throw new Error('Component must be a child of <CommandServiceProvider />');
    }
    return value;
};
const MemoizedChildren = memo(({ children }) => _jsx(_Fragment, { children: children }));
const CommandServiceProvider = ({ service, children }) => {
    return (_jsx(CommandServiceContext.Provider, { value: service, children: _jsx(MemoizedChildren, { children: children }) }));
};
export default observer(CommandServiceProvider);
