import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useApplication } from '@/Components/ApplicationProvider';
import Button from '@/Components/Button/Button';
import { SmallText, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import { ContentType } from '@standardnotes/snjs';
import { useCallback } from 'react';
const PluginRowView = ({ plugin }) => {
    const application = useApplication();
    const install = useCallback(async () => {
        const result = await application.pluginsService.installPlugin(plugin);
        if (!result) {
            void application.alerts.alertV2({ text: 'Failed to install plugin' });
        }
        else {
            void application.alerts.alertV2({ text: `${result.name} has been successfully installed.` });
        }
    }, [application, plugin]);
    const pluginType = plugin.content_type === ContentType.TYPES.Theme ? 'theme' : 'note type';
    const hasSubscription = application.hasValidFirstPartySubscription();
    return (_jsxs("div", { className: "align-center my-2.5 flex items-center justify-between md:items-center", children: [_jsxs("div", { className: "mr-5", children: [_jsx(Subtitle, { className: "mb-0 text-info", children: plugin.name }), _jsxs(SmallText, { className: "mb-1", children: ["A ", _jsx("strong", { children: pluginType }), " by ", plugin.publisher] }), plugin.description && _jsx(SmallText, { className: "text-neutral", children: plugin.description })] }), _jsx(Button, { disabled: !hasSubscription, small: true, className: "cursor-pointer", onClick: install, children: "Install" })] }));
};
export default PluginRowView;
