import { useApplication } from '@/Components/ApplicationProvider';
import { ContentType, } from '@standardnotes/snjs';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useStateRef } from './useStateRef';
export const useItemVaultInfo = (item) => {
    const application = useApplication();
    const [vault, setVault] = useState();
    const vaultRef = useStateRef(vault);
    const [lastEditedByContact, setLastEditedByContact] = useState();
    const [sharedByContact, setSharedByContact] = useState();
    const updateInfo = useCallback(() => {
        if (!application.featuresController.isVaultsEnabled()) {
            return;
        }
        setVault(application.vaultDisplayService.getItemVault(item));
        setLastEditedByContact((lastEditedBy) => application.sharedVaults.getItemLastEditedBy(item) || lastEditedBy);
        setSharedByContact(application.sharedVaults.getItemSharedBy(item));
    }, [application.featuresController, application.sharedVaults, application.vaultDisplayService, item]);
    useLayoutEffect(() => {
        updateInfo();
    }, [updateInfo]);
    useEffect(() => {
        return application.items.streamItems(ContentType.TYPES.VaultListing, ({ changed, inserted }) => {
            const matchingItem = changed.concat(inserted).find((vault) => { var _a; return vault.uuid === ((_a = vaultRef.current) === null || _a === void 0 ? void 0 : _a.uuid); });
            if (matchingItem) {
                setVault(matchingItem);
            }
        });
    }, [application.items, vaultRef]);
    useEffect(() => {
        return application.items.streamItems(ContentType.TYPES.Note, ({ changed }) => {
            const matchingItem = changed.find((note) => note.uuid === item.uuid);
            if (matchingItem) {
                updateInfo();
            }
        });
    }, [application.items, item.uuid, updateInfo]);
    return {
        vault,
        lastEditedByContact,
        sharedByContact,
    };
};
