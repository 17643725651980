import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getFileIconComponent } from '../FilePreview/getFileIconComponent';
import ListItemConflictIndicator from './ListItemConflictIndicator';
import ListItemTags from './ListItemTags';
import ListItemMetadata from './ListItemMetadata';
import { useResponsiveAppPane } from '../Panes/ResponsivePaneProvider';
import { useContextMenuEvent } from '@/Hooks/useContextMenuEvent';
import { classNames } from '@standardnotes/utils';
import { getIconForFileType } from '@/Utils/Items/Icons/getIconForFileType';
import { useApplication } from '../ApplicationProvider';
import { PaneLayout } from '@/Controllers/PaneController/PaneLayout';
import ListItemFlagIcons from './ListItemFlagIcons';
import ListItemVaultInfo from './ListItemVaultInfo';
const FileListItemCard = ({ filesController, hideDate, hideIcon, hideTags, item: file, onSelect, selected, sortBy, tags, }) => {
    const { setPaneLayout } = useResponsiveAppPane();
    const application = useApplication();
    const [backupInfo, setBackupInfo] = useState(undefined);
    useEffect(() => {
        var _a;
        void ((_a = application.fileBackups) === null || _a === void 0 ? void 0 : _a.getFileBackupInfo(file).then(setBackupInfo));
    }, [application, file]);
    const listItemRef = useRef(null);
    const openFileContextMenu = useCallback((posX, posY) => {
        filesController.setShowFileContextMenu(false);
        filesController.setFileContextMenuLocation({
            x: posX,
            y: posY,
        });
        filesController.setShowFileContextMenu(true);
    }, [filesController]);
    const openContextMenu = useCallback(async (posX, posY) => {
        let shouldOpenContextMenu = selected;
        if (!selected) {
            const { didSelect } = await onSelect(file);
            if (didSelect) {
                shouldOpenContextMenu = true;
            }
        }
        if (shouldOpenContextMenu) {
            openFileContextMenu(posX, posY);
        }
    }, [selected, onSelect, file, openFileContextMenu]);
    const onClick = useCallback(async () => {
        const { didSelect } = await onSelect(file, true);
        if (didSelect) {
            setPaneLayout(PaneLayout.Editing);
        }
    }, [file, onSelect, setPaneLayout]);
    const IconComponent = () => getFileIconComponent(getIconForFileType(file.mimeType), 'w-5 h-5 flex-shrink-0');
    useContextMenuEvent(listItemRef, openContextMenu);
    return (_jsxs("div", { ref: listItemRef, role: "button", className: classNames('content-list-item flex w-full cursor-pointer items-stretch text-text', selected && 'selected border-l-2px border-solid border-info'), id: file.uuid, onClick: onClick, children: [!hideIcon ? (_jsx("div", { className: "mr-0 flex flex-col items-center justify-between p-4.5 pr-3", children: _jsx(IconComponent, {}) })) : (_jsx("div", { className: "pr-4" })), _jsxs("div", { className: "min-w-0 flex-grow border-b border-solid border-border px-0 py-4", children: [_jsx("div", { className: "flex items-start justify-between overflow-hidden text-base font-semibold leading-[1.3]", children: _jsx("div", { className: "break-word mr-2", children: file.title }) }), _jsx(ListItemMetadata, { item: file, hideDate: hideDate, sortBy: sortBy }), _jsx(ListItemTags, { hideTags: hideTags, tags: tags }), _jsx(ListItemConflictIndicator, { item: file }), _jsx(ListItemVaultInfo, { item: file, className: "mt-1.5" })] }), _jsx(ListItemFlagIcons, { className: "p-4", item: file, isFileBackedUp: !!backupInfo })] }));
};
export default observer(FileListItemCard);
