"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncludesPredicate = void 0;
class IncludesPredicate {
    constructor(keypath, predicate) {
        this.keypath = keypath;
        this.predicate = predicate;
    }
    matchesItem(item) {
        const keyPathComponents = this.keypath.split('.');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const valueAtKeyPath = keyPathComponents.reduce((previous, current) => {
            return previous && previous[current];
        }, item);
        if (!Array.isArray(valueAtKeyPath)) {
            return false;
        }
        return valueAtKeyPath.some((subItem) => this.predicate.matchesItem(subItem));
    }
    keypathIncludesString(verb) {
        return this.keypath.includes(verb);
    }
    toJson() {
        return {
            keypath: this.keypath,
            operator: 'includes',
            value: this.predicate.toJson(),
        };
    }
}
exports.IncludesPredicate = IncludesPredicate;
