import { ELEMENT_TRANSFORMERS, TEXT_FORMAT_TRANSFORMERS, TEXT_MATCH_TRANSFORMERS, } from '@lexical/markdown';
function indexBy(list, callback) {
    const index = {};
    for (const item of list) {
        const key = callback(item);
        if (index[key]) {
            index[key].push(item);
        }
        else {
            index[key] = [item];
        }
    }
    return index;
}
export function transformersByType(transformers) {
    const byType = indexBy(transformers, (t) => t.type);
    return {
        element: (byType.element || []),
        textFormat: (byType['text-format'] || []),
        textMatch: (byType['text-match'] || []),
    };
}
export const TRANSFORMERS = [
    ...ELEMENT_TRANSFORMERS,
    ...TEXT_FORMAT_TRANSFORMERS,
    ...TEXT_MATCH_TRANSFORMERS,
];
