"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNTag = exports.isTag = exports.DefaultTagIconName = exports.TagFolderDelimitter = void 0;
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const Functions_1 = require("../../Abstract/Reference/Functions");
const domain_core_1 = require("@standardnotes/domain-core");
exports.TagFolderDelimitter = '.';
exports.DefaultTagIconName = 'hashtag';
const isTag = (x) => x.content_type === domain_core_1.ContentType.TYPES.Tag;
exports.isTag = isTag;
class SNTag extends DecryptedItem_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.title = this.payload.content.title || '';
        this.expanded = this.payload.content.expanded != undefined ? this.payload.content.expanded : true;
        this.iconString = this.payload.content.iconString || exports.DefaultTagIconName;
        this.preferences = this.payload.content.preferences;
    }
    get isDailyEntry() {
        var _a;
        return ((_a = this.preferences) === null || _a === void 0 ? void 0 : _a.entryMode) === 'daily';
    }
    get noteReferences() {
        const references = this.payload.references;
        return references.filter((ref) => ref.content_type === domain_core_1.ContentType.TYPES.Note);
    }
    get noteCount() {
        return this.noteReferences.length;
    }
    get parentId() {
        const reference = this.references.find(Functions_1.isTagToParentTagReference);
        return reference === null || reference === void 0 ? void 0 : reference.uuid;
    }
    static arrayToDisplayString(tags) {
        return tags
            .sort((a, b) => {
            return a.title > b.title ? 1 : -1;
        })
            .map((tag) => {
            return '#' + tag.title;
        })
            .join(' ');
    }
}
exports.SNTag = SNTag;
