import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Icon from '../Icon/Icon';
const EditingDisabledBanner = ({ onClick, noteLocked }) => {
    const [showDisabledCopy, setShowDisabledCopy] = useState(() => noteLocked);
    const background = showDisabledCopy ? 'bg-warning-faded' : 'bg-info-faded';
    const iconColor = showDisabledCopy ? 'text-accessory-tint-3' : 'text-accessory-tint-1';
    const textColor = showDisabledCopy ? 'text-warning' : 'text-accessory-tint-1';
    const text = showDisabledCopy ? 'Note editing disabled.' : 'Enable editing';
    return (_jsxs("div", { className: `relative flex items-center ${background} cursor-pointer px-3.5 py-2 text-sm`, onMouseLeave: () => {
            setShowDisabledCopy(true);
        }, onMouseOver: () => {
            setShowDisabledCopy(false);
        }, onClick: onClick, children: [showDisabledCopy ? (_jsx(Icon, { type: "pencil-off", className: `${iconColor} mr-3 flex fill-current` })) : (_jsx(Icon, { type: "pencil", className: `${iconColor} mr-3 flex fill-current` })), _jsx("span", { className: textColor, children: text })] }));
};
export default EditingDisabledBanner;
