export var LocalPrefKey;
(function (LocalPrefKey) {
    LocalPrefKey["ActiveThemes"] = "activeThemes";
    LocalPrefKey["UseSystemColorScheme"] = "useSystemColorScheme";
    LocalPrefKey["UseTranslucentUI"] = "useTranslucentUI";
    LocalPrefKey["AutoLightThemeIdentifier"] = "autoLightThemeIdentifier";
    LocalPrefKey["AutoDarkThemeIdentifier"] = "autoDarkThemeIdentifier";
    LocalPrefKey["EditorMonospaceEnabled"] = "monospaceFont";
    LocalPrefKey["EditorLineHeight"] = "editorLineHeight";
    LocalPrefKey["EditorLineWidth"] = "editorLineWidth";
    LocalPrefKey["EditorFontSize"] = "editorFontSize";
})(LocalPrefKey || (LocalPrefKey = {}));
