import { useCallback, useMemo, useRef } from 'react';
// According to https://reactnative.dev/docs/touchablewithoutfeedback#onlongpress
const ReactNativeLongpressDelay = 370;
export const useLongPressEvent = (elementRef, listener, clearOnPointerMove = false, delay = ReactNativeLongpressDelay) => {
    const longPressTimeout = useRef();
    const pointerPosition = useRef();
    const clearLongPressTimeout = useCallback(() => {
        if (longPressTimeout.current) {
            clearTimeout(longPressTimeout.current);
        }
    }, []);
    const createLongPressTimeout = useCallback((event) => {
        clearLongPressTimeout();
        pointerPosition.current = { x: event.clientX, y: event.clientY };
        longPressTimeout.current = window.setTimeout(() => {
            var _a;
            (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('mousedown', (event) => {
                event.preventDefault();
                event.stopPropagation();
            }, { once: true, capture: true });
            const x = event.clientX;
            const y = event.clientY;
            listener(x, y);
        }, delay);
    }, [clearLongPressTimeout, delay, elementRef, listener]);
    const clearLongPressTimeoutIfMoved = useCallback((event) => {
        if (pointerPosition.current &&
            (event.clientX !== pointerPosition.current.x || event.clientY !== pointerPosition.current.y)) {
            clearLongPressTimeout();
        }
    }, [clearLongPressTimeout]);
    const attachEvents = useCallback(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.addEventListener('pointerdown', createLongPressTimeout);
        if (clearOnPointerMove) {
            elementRef.current.addEventListener('pointermove', clearLongPressTimeoutIfMoved);
        }
        elementRef.current.addEventListener('pointercancel', clearLongPressTimeout);
        elementRef.current.addEventListener('pointerup', clearLongPressTimeout);
    }, [clearLongPressTimeout, clearLongPressTimeoutIfMoved, clearOnPointerMove, createLongPressTimeout, elementRef]);
    const cleanupEvents = useCallback(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.removeEventListener('pointerdown', createLongPressTimeout);
        if (clearOnPointerMove) {
            elementRef.current.removeEventListener('pointermove', clearLongPressTimeoutIfMoved);
        }
        elementRef.current.removeEventListener('pointercancel', clearLongPressTimeout);
        elementRef.current.removeEventListener('pointerup', clearLongPressTimeout);
    }, [clearLongPressTimeout, clearLongPressTimeoutIfMoved, clearOnPointerMove, createLongPressTimeout, elementRef]);
    const memoizedReturn = useMemo(() => ({
        attachEvents,
        cleanupEvents,
    }), [attachEvents, cleanupEvents]);
    return memoizedReturn;
};
