import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TAG_FOLDERS_FEATURE_NAME, TAG_FOLDERS_FEATURE_TOOLTIP } from '@/Constants/Constants';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
const TagsSectionTitle = ({ features, hasMigration, onClickMigration }) => {
    const entitledToFolders = features.hasFolders;
    const modal = usePremiumModal();
    const showPremiumAlert = useCallback(() => {
        modal.activate(TAG_FOLDERS_FEATURE_NAME);
    }, [modal]);
    if (entitledToFolders) {
        return (_jsx(_Fragment, { children: _jsxs("div", { className: "title text-base md:text-sm", children: [_jsx("span", { className: "font-bold", children: "Folders" }), hasMigration && (_jsx("label", { className: "ml-1 cursor-pointer font-bold text-info", onClick: onClickMigration, children: "Migration Available" }))] }) }));
    }
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "title text-base md:text-sm", children: [_jsx("span", { className: "font-bold", children: "Tags" }), _jsx(StyledTooltip, { label: TAG_FOLDERS_FEATURE_TOOLTIP, children: _jsx("label", { className: "ml-1 cursor-pointer font-bold text-passive-2", onClick: showPremiumAlert, children: "Folders" }) })] }) }));
};
export default observer(TagsSectionTitle);
