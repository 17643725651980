import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useApplication } from '@/Components/ApplicationProvider';
import Icon from '@/Components/Icon/Icon';
import Button from '@/Components/Button/Button';
export const VaultModalInvites = ({ invites, onChange, isAdmin, }) => {
    const application = useApplication();
    const deleteInvite = useCallback(async (invite) => {
        await application.vaultInvites.deleteInvite(invite);
        onChange();
    }, [application.vaultInvites, onChange]);
    return (_jsxs("div", { children: [_jsx("div", { className: "mb-3 text-lg", children: "Pending Invites" }), _jsx("div", { className: "space-y-3.5", children: invites.map((invite) => {
                    const contact = application.contacts.findContactForInvite(invite);
                    const permission = application.vaultUsers.getFormattedMemberPermission(invite.permission);
                    return (_jsxs("div", { className: "grid grid-cols-[auto,1fr] gap-x-[0.65rem] gap-y-0.5 text-base font-medium md:text-sm", children: [_jsx(Icon, { type: "user", className: "col-start-1 col-end-2 place-self-center" }), _jsxs("div", { className: "flex items-center gap-2 overflow-hidden text-ellipsis text-base font-bold", children: [_jsx("span", { children: (contact === null || contact === void 0 ? void 0 : contact.name) || invite.user_uuid }), contact ? (_jsxs("div", { className: "flex items-center gap-1 rounded bg-success px-1 py-0.5 text-xs text-success-contrast", children: [_jsx(Icon, { type: "check-circle", size: "small" }), "Trusted"] })) : (_jsxs("div", { className: "flex items-center gap-1 rounded bg-danger px-1 py-0.5 pr-1.5 text-xs text-danger-contrast", children: [_jsx(Icon, { type: "clear-circle-filled", size: "small" }), "Untrusted"] }))] }), _jsx("div", { className: "col-start-2 row-start-2", children: permission }), isAdmin && (_jsx(Button, { label: "Cancel Invite", className: "col-start-2 row-start-3 mt-1", onClick: () => deleteInvite(invite), small: true }))] }, invite.uuid));
                }) })] }));
};
