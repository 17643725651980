import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FileMenuOptions from './FileMenuOptions';
import Popover from '../Popover/Popover';
import RoundIconButton from '../Button/RoundIconButton';
import Menu from '../Menu/Menu';
const FilesOptionsPanel = ({ itemListController }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const toggleMenu = useCallback(() => setIsOpen((isOpen) => !isOpen), []);
    return (_jsxs(_Fragment, { children: [_jsx(RoundIconButton, { label: "File options menu", onClick: toggleMenu, ref: buttonRef, icon: "more" }), _jsx(Popover, { title: "File options", togglePopover: toggleMenu, anchorElement: buttonRef, open: isOpen, className: "md:pb-2", children: _jsx(Menu, { a11yLabel: "File options panel", children: _jsx(FileMenuOptions, { selectedFiles: itemListController.selectedFiles, closeMenu: () => {
                            setIsOpen(false);
                        }, shouldShowAttachOption: false, shouldShowRenameOption: false }) }) })] }));
};
export default observer(FilesOptionsPanel);
