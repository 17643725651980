import { SNTag } from '@standardnotes/snjs';
export function getTitleForLinkedTag(item, application) {
    const isTag = item instanceof SNTag;
    if (!isTag) {
        return;
    }
    const titlePrefix = application.items.getTagPrefixTitle(item);
    const longTitle = application.items.getTagLongTitle(item);
    return {
        titlePrefix,
        longTitle,
    };
}
