import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useApplication } from '@/Components/ApplicationProvider';
import LinkedItemBubble from '@/Components/LinkedItems/LinkedItemBubble';
import { createLinkFromItem } from '@/Utils/Items/Search/createLinkFromItem';
import { useLinkingController } from '@/Controllers/LinkingControllerProvider';
import { useResponsiveAppPane } from '@/Components/Panes/ResponsivePaneProvider';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
export function BubbleComponent({ itemUuid, node }) {
    const application = useApplication();
    const [editor] = useLexicalComposerContext();
    const linkingController = useLinkingController();
    const item = useMemo(() => application.items.findItem(itemUuid), [application, itemUuid]);
    const { toggleAppPane } = useResponsiveAppPane();
    const activateItemAndTogglePane = useCallback(async (item) => {
        const paneId = await linkingController.activateItem(item);
        if (paneId) {
            toggleAppPane(paneId);
        }
    }, [toggleAppPane, linkingController]);
    const unlinkPressed = useCallback(async (itemToUnlink) => {
        linkingController.unlinkItemFromSelectedItem(itemToUnlink).catch(console.error);
        editor.update(() => {
            node.remove();
        });
    }, [linkingController, node, editor]);
    if (!item) {
        return _jsxs("div", { children: ["Unable to find item ", itemUuid] });
    }
    const link = createLinkFromItem(item, 'linked');
    return (_jsx(LinkedItemBubble, { className: "mx-0.5", link: link, activateItem: activateItemAndTogglePane, unlinkItem: unlinkPressed, isBidirectional: false, inlineFlex: true, wrappable: true }, link.id));
}
