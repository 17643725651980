"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDeprecatedFeatures = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const PermissionName_1 = require("../Permission/PermissionName");
const NativeFeatureIdentifier_1 = require("../Feature/NativeFeatureIdentifier");
const NoteType_1 = require("../Component/NoteType");
const FillEditorComponentDefaults_1 = require("./Utilities/FillEditorComponentDefaults");
const ComponentAction_1 = require("../Component/ComponentAction");
const ComponentArea_1 = require("../Component/ComponentArea");
function GetDeprecatedFeatures() {
    const code = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Code',
        spellcheckControl: true,
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedCodeEditor,
        permission_name: PermissionName_1.PermissionName.DeprecatedCodeEditor,
        note_type: NoteType_1.NoteType.Code,
        file_type: 'txt',
        interchangeable: true,
        deprecated: true,
        index_path: 'index.html',
        description: 'Syntax highlighting and convenient keyboard shortcuts for over 120 programming' +
            ' languages. Ideal for code snippets and procedures.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/code.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const plus = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Rich Text',
        note_type: NoteType_1.NoteType.RichText,
        file_type: 'html',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedPlusEditor,
        permission_name: PermissionName_1.PermissionName.DeprecatedPlusEditor,
        spellcheckControl: true,
        deprecated: true,
        description: 'From highlighting to custom font sizes and colors, to tables and lists, this editor is perfect for crafting any document.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/plus-editor.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdown = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Markdown',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedMarkdownProEditor,
        note_type: NoteType_1.NoteType.Markdown,
        file_type: 'md',
        permission_name: PermissionName_1.PermissionName.DeprecatedMarkdownProEditor,
        spellcheckControl: true,
        deprecated: true,
        description: 'A fully featured Markdown editor that supports live preview, a styling toolbar, and split pane support.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/adv-markdown.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const task = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Checklist',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedTaskEditor,
        note_type: NoteType_1.NoteType.Task,
        spellcheckControl: true,
        file_type: 'md',
        interchangeable: false,
        deprecated: true,
        permission_name: PermissionName_1.PermissionName.DeprecatedTaskEditor,
        description: 'A great way to manage short-term and long-term to-do"s. You can mark tasks as completed, change their order, and edit the text naturally in place.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/task-editor.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const bold = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Alternative Rich Text',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedBoldEditor,
        note_type: NoteType_1.NoteType.RichText,
        file_type: 'html',
        component_permissions: [
            {
                name: ComponentAction_1.ComponentAction.StreamContextItem,
                content_types: [domain_core_1.ContentType.TYPES.Note],
            },
            {
                name: ComponentAction_1.ComponentAction.StreamItems,
                content_types: [
                    domain_core_1.ContentType.TYPES.FilesafeCredentials,
                    domain_core_1.ContentType.TYPES.FilesafeFileMetadata,
                    domain_core_1.ContentType.TYPES.FilesafeIntegration,
                ],
            },
        ],
        spellcheckControl: true,
        deprecated: true,
        permission_name: PermissionName_1.PermissionName.BoldEditor,
        description: 'A simple and peaceful rich editor that helps you write and think clearly.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/bold.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownBasic = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Basic Markdown',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedMarkdownBasicEditor,
        note_type: NoteType_1.NoteType.Markdown,
        spellcheckControl: true,
        file_type: 'md',
        deprecated: true,
        permission_name: PermissionName_1.PermissionName.MarkdownBasicEditor,
        description: 'A Markdown editor with dynamic split-pane preview.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/simple-markdown.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownAlt = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Markdown Alternative',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedMarkdownVisualEditor,
        note_type: NoteType_1.NoteType.Markdown,
        file_type: 'md',
        deprecated: true,
        permission_name: PermissionName_1.PermissionName.MarkdownVisualEditor,
        spellcheckControl: true,
        description: 'A WYSIWYG-style Markdown editor that renders Markdown in preview-mode while you type without displaying any syntax.',
        index_path: 'build/index.html',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownMinimist = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Minimal Markdown',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedMarkdownMinimistEditor,
        note_type: NoteType_1.NoteType.Markdown,
        file_type: 'md',
        index_path: 'index.html',
        permission_name: PermissionName_1.PermissionName.MarkdownMinimistEditor,
        spellcheckControl: true,
        deprecated: true,
        description: 'A minimal Markdown editor with live rendering and in-text search via Ctrl/Cmd + F',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/min-markdown.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownMath = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Markdown with Math',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedMarkdownMathEditor,
        spellcheckControl: true,
        permission_name: PermissionName_1.PermissionName.MarkdownMathEditor,
        note_type: NoteType_1.NoteType.Markdown,
        file_type: 'md',
        deprecated: true,
        index_path: 'index.html',
        description: 'A beautiful split-pane Markdown editor with synced-scroll, LaTeX support, and colorful syntax.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/fancy-markdown.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const filesafe = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'FileSafe',
        identifier: NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DeprecatedFileSafe,
        component_permissions: [
            {
                name: ComponentAction_1.ComponentAction.StreamContextItem,
                content_types: [domain_core_1.ContentType.TYPES.Note],
            },
            {
                name: ComponentAction_1.ComponentAction.StreamItems,
                content_types: [
                    domain_core_1.ContentType.TYPES.FilesafeCredentials,
                    domain_core_1.ContentType.TYPES.FilesafeFileMetadata,
                    domain_core_1.ContentType.TYPES.FilesafeIntegration,
                ],
            },
        ],
        permission_name: PermissionName_1.PermissionName.ComponentFilesafe,
        area: ComponentArea_1.ComponentArea.EditorStack,
        deprecated: true,
        description: 'Encrypted attachments for your notes using your Dropbox, Google Drive, or WebDAV server. Limited to 50MB per file.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/FileSafe-banner.png',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    return [code, plus, markdown, task, bold, markdownBasic, markdownMinimist, markdownMath, markdownAlt, filesafe];
}
exports.GetDeprecatedFeatures = GetDeprecatedFeatures;
