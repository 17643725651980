import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PurchaseFlowPane } from '@/Controllers/PurchaseFlow/PurchaseFlowPane';
import { observer } from 'mobx-react-lite';
import CreateAccount from './Panes/CreateAccount';
import SignIn from './Panes/SignIn';
import { SNLogoFull } from '@standardnotes/icons';
import Icon from '../Icon/Icon';
const PurchaseFlowPaneSelector = ({ currentPane, application }) => {
    switch (currentPane) {
        case PurchaseFlowPane.CreateAccount:
            return _jsx(CreateAccount, { application: application });
        case PurchaseFlowPane.SignIn:
            return _jsx(SignIn, { application: application });
    }
};
const PurchaseFlowView = ({ application }) => {
    const { currentPane } = application.purchaseFlowController;
    return (_jsx("div", { className: "absolute left-0 top-0 z-purchase-flow flex h-full w-full items-center justify-center overflow-hidden bg-passive-super-light", children: _jsxs("div", { className: "relative w-fit", children: [_jsxs("div", { className: "rounded-0 relative mb-4 w-full border border-solid border-border bg-default px-8 py-8 md:rounded md:p-12", children: [_jsx("button", { className: "absolute right-4 top-4 rounded-full p-1 hover:bg-info-backdrop", onClick: () => {
                                application.purchaseFlowController.closePurchaseFlow();
                            }, children: _jsx(Icon, { type: "close", className: "text-neutral" }) }), _jsx(SNLogoFull, { className: "mb-5 h-7" }), _jsx(PurchaseFlowPaneSelector, { currentPane: currentPane, application: application })] }), _jsxs("div", { className: "flex justify-end px-4 md:px-0", children: [_jsx("a", { className: "mr-3 font-medium text-passive-1", href: "https://standardnotes.com/privacy", target: "_blank", rel: "noopener noreferrer", children: "Privacy" }), _jsx("a", { className: "font-medium text-passive-1", href: "https://standardnotes.com/help", target: "_blank", rel: "noopener noreferrer", children: "Help" })] })] }) }));
};
export default observer(PurchaseFlowView);
