import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractComponent } from '@/Components/Abstract/PureComponent';
class SyncResolutionMenu extends AbstractComponent {
    constructor(props) {
        super(props, props.application);
        this.close = () => {
            this.props.close();
        };
    }
    render() {
        return (_jsx("div", { className: "sn-component", children: _jsxs("div", { id: "sync-resolution-menu", className: "absolute bottom-[40px] left-[inherit] right-0 z-footer-bar-item-panel mt-4 flex max-h-[85vh] min-w-[300px] flex-col border border-solid border-border bg-default shadow-main", children: [_jsxs("div", { className: "flex flex-shrink-0 items-center justify-between border-b border-solid border-border bg-contrast px-6 py-3 text-text", children: [_jsx("div", { className: "text-base font-medium", children: "Out of Sync" }), _jsx("a", { onClick: this.close, className: "text-sm font-bold text-info", children: "Close" })] }), _jsx("div", { className: "h-full flex-grow overflow-scroll overflow-x-auto overflow-y-auto p-6 text-sm leading-tight", children: _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "mb-3.5", children: "We've detected that the data in the current application session may not match the data on the server. An attempt was made to auto-resolve the issue, but it was unable to reconcile the differences." }), _jsx("div", { className: "sk-p sk-panel-row mb-3.5", children: _jsxs("div", { className: "sk-panel-column", children: [_jsx("div", { className: "mb-2 font-bold", children: "Option 1 \u2014 Restart Application:" }), _jsx("div", { children: "Quit the application and re-open it. Sometimes, this may resolve the issue." })] }) }), _jsx("div", { className: "sk-p sk-panel-row", children: _jsxs("div", { className: "sk-panel-column", children: [_jsx("div", { className: "mb-2 font-bold", children: "Option 2 \u2014 Sign Out and Back In:" }), _jsx("div", { children: "Sign out of your account, then sign back in. This will ensure your data is consistent with the server. Be sure to download a backup of your data before doing so." })] }) })] }) })] }) }));
    }
}
export default SyncResolutionMenu;
