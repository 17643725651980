import { ContentType, Result } from '@standardnotes/domain-core';
export class GetItemTags {
    constructor(items) {
        this.items = items;
    }
    execute(item) {
        return Result.ok(this.items.itemsReferencingItem(item).filter((ref) => {
            return ref.content_type === ContentType.TYPES.Tag;
        }));
    }
}
