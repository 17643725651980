import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import LegacyHistoryList from './LegacyHistoryList';
import RemoteHistoryList from './RemoteHistoryList';
import { RevisionType } from './RevisionType';
import SessionHistoryList from './SessionHistoryList';
const HistoryListContainer = ({ features, noteHistoryController, selectMobileModalTab }) => {
    const { legacyHistory, currentTab, selectTab } = noteHistoryController;
    const TabButton = ({ type }) => {
        const isSelected = currentTab === type;
        return (_jsx("button", { className: `relative cursor-pointer border-0 bg-default px-3 py-2.5 text-sm focus:shadow-inner ${isSelected ? 'font-medium text-info shadow-bottom' : 'text-text'}`, onClick: () => {
                selectTab(type);
            }, children: type }));
    };
    const onSelectRevision = useCallback(() => {
        selectMobileModalTab('Content');
    }, [selectMobileModalTab]);
    const CurrentTabList = () => {
        switch (currentTab) {
            case RevisionType.Remote:
                return (_jsx(RemoteHistoryList, { onSelectRevision: onSelectRevision, features: features, noteHistoryController: noteHistoryController }));
            case RevisionType.Session:
                return _jsx(SessionHistoryList, { onSelectRevision: onSelectRevision, noteHistoryController: noteHistoryController });
            case RevisionType.Legacy:
                return (_jsx(LegacyHistoryList, { onSelectRevision: onSelectRevision, legacyHistory: legacyHistory, noteHistoryController: noteHistoryController }));
        }
    };
    return (_jsxs("div", { className: 'flex h-full flex-grow flex-col overflow-auto border-r border-solid border-border', children: [_jsxs("div", { className: "flex border-b border-solid border-border", children: [_jsx(TabButton, { type: RevisionType.Remote }), _jsx(TabButton, { type: RevisionType.Session }), legacyHistory && legacyHistory.length > 0 && _jsx(TabButton, { type: RevisionType.Legacy })] }), _jsx("div", { className: 'h-full min-h-0 overflow-auto py-1.5', children: _jsx(CurrentTabList, {}) })] }));
};
export default observer(HistoryListContainer);
