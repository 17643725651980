import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import Icon from '@/Components/Icon/Icon';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
import { classNames } from '@standardnotes/snjs';
import EditContactModal from './EditContactModal';
import { useCallback, useState } from 'react';
import { useApplication } from '@/Components/ApplicationProvider';
import { VisuallyHidden } from '@ariakit/react';
import StyledTooltip from '@/Components/StyledTooltip/StyledTooltip';
const ContactItem = ({ contact }) => {
    const application = useApplication();
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const closeContactModal = () => setIsContactModalOpen(false);
    const collaborationID = application.contacts.getCollaborationIDForTrustedContact(contact);
    const deleteContact = useCallback(async () => {
        if (await application.alerts.confirm('Are you sure you want to delete this contact?')) {
            const result = await application.contacts.deleteContact(contact);
            if (result.isFailed()) {
                application.alerts
                    .alertV2({
                    text: result.getError(),
                })
                    .catch(console.error);
            }
        }
    }, [application.alerts, application.contacts, contact]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalOverlay, { isOpen: isContactModalOpen, close: closeContactModal, children: _jsx(EditContactModal, { editContactUuid: contact.contactUuid, onCloseDialog: closeContactModal }) }), _jsxs("div", { className: "flex items-start gap-3.5 rounded-lg border border-border px-3.5 py-2.5 shadow-sm", children: [_jsxs("div", { className: "grid grid-cols-[1fr,auto] grid-rows-2 place-items-center gap-x-3.5 gap-y-1 overflow-hidden", children: [_jsx(Icon, { type: "user", size: "custom", className: "h-5 w-5 flex-shrink-0" }), _jsx("span", { className: classNames('w-full overflow-hidden text-ellipsis text-base font-bold', contact.isMe ? 'text-info' : ''), children: contact.name }), _jsx("span", { className: "col-start-2 w-full overflow-hidden text-ellipsis text-sm brightness-75", children: collaborationID })] }), _jsxs("div", { className: "flex gap-3", children: [_jsx(StyledTooltip, { label: "Edit contact", children: _jsxs(Button, { className: "!px-2 py-2", onClick: () => setIsContactModalOpen(true), children: [_jsx(VisuallyHidden, { children: "Edit" }), _jsx(Icon, { type: "pencil-filled", size: "medium" })] }) }), !contact.isMe && (_jsx(StyledTooltip, { label: "Delete contact", children: _jsxs(Button, { className: "!px-2 py-2", onClick: deleteContact, children: [_jsx(VisuallyHidden, { children: "Delete" }), _jsx(Icon, { type: "trash-filled", className: "text-danger", size: "medium" })] }) }))] })] })] }));
};
export default ContactItem;
