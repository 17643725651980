"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conflictParamsHasOnlyUnsavedItem = exports.conflictParamsHasOnlyServerItem = exports.conflictParamsHasServerItemAndUnsavedItem = void 0;
function conflictParamsHasServerItemAndUnsavedItem(params) {
    return params.server_item !== undefined && params.unsaved_item !== undefined;
}
exports.conflictParamsHasServerItemAndUnsavedItem = conflictParamsHasServerItemAndUnsavedItem;
function conflictParamsHasOnlyServerItem(params) {
    return params.server_item !== undefined;
}
exports.conflictParamsHasOnlyServerItem = conflictParamsHasOnlyServerItem;
function conflictParamsHasOnlyUnsavedItem(params) {
    return params.unsaved_item !== undefined;
}
exports.conflictParamsHasOnlyUnsavedItem = conflictParamsHasOnlyUnsavedItem;
