"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paths = void 0;
const AuthenticatorPaths = {
    listAuthenticators: '/v1/authenticators',
    deleteAuthenticator: (authenticatorId) => `/v1/authenticators/${authenticatorId}`,
    generateRegistrationOptions: '/v1/authenticators/generate-registration-options',
    verifyRegistrationResponse: '/v1/authenticators/verify-registration',
    generateAuthenticationOptions: '/v1/authenticators/generate-authentication-options',
};
exports.Paths = {
    v1: Object.assign({}, AuthenticatorPaths),
};
