import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_NORMAL } from 'lexical';
import { useCallback, useEffect, useState } from 'react';
import { INSERT_REMOTE_IMAGE_COMMAND } from '../Commands';
import { $createRemoteImageNode, RemoteImageNode } from './RemoteImageNode';
import { mergeRegister, $wrapNodeInElement } from '@lexical/utils';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import Button from '@/Components/Button/Button';
import { isMobileScreen } from '../../../../Utils';
export function InsertRemoteImageDialog({ onClose }) {
    const [url, setURL] = useState('');
    const [editor] = useLexicalComposerContext();
    const onClick = () => {
        if (url.length < 1) {
            return;
        }
        editor.dispatchCommand(INSERT_REMOTE_IMAGE_COMMAND, url);
        onClose();
    };
    const focusOnMount = useCallback((element) => {
        if (element) {
            setTimeout(() => element.focus());
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("label", { className: "flex flex-col gap-1.5", children: ["URL:", _jsx(DecoratedInput, { value: url, onChange: setURL, ref: focusOnMount })] }), _jsx("div", { className: "mt-2.5 flex justify-end", children: _jsx(Button, { onClick: onClick, disabled: !url, small: isMobileScreen(), children: "Confirm" }) })] }));
}
export default function RemoteImagePlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return mergeRegister(editor.registerCommand(INSERT_REMOTE_IMAGE_COMMAND, (payload) => {
            const imageNode = $createRemoteImageNode(payload);
            $insertNodes([imageNode]);
            if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
            }
            const newLineNode = $createParagraphNode();
            imageNode.getParentOrThrow().insertAfter(newLineNode);
            return true;
        }, COMMAND_PRIORITY_NORMAL), editor.registerNodeTransform(RemoteImageNode, (node) => {
            /**
             * When adding the node we wrap it with a paragraph to avoid insertion errors,
             * however that causes issues with selection. We unwrap the node to fix that.
             */
            const parent = node.getParent();
            if (!parent) {
                return;
            }
            if (parent.getChildrenSize() === 1) {
                parent.insertBefore(node);
                parent.remove();
            }
        }));
    }, [editor]);
    return null;
}
