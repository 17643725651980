import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { BlockPickerOption } from '../BlockPickerPlugin/BlockPickerOption';
export const DividerBlock = {
    name: 'Divider',
    iconName: 'horizontal-rule',
    keywords: ['horizontal rule', 'divider', 'hr'],
    onSelect: (editor) => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined),
};
export function GetDividerBlockOption(editor) {
    return new BlockPickerOption(DividerBlock.name, {
        iconName: DividerBlock.iconName,
        keywords: DividerBlock.keywords,
        onSelect: () => DividerBlock.onSelect(editor),
    });
}
