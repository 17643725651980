"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentTypesUsingRootKeyEncryption = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
function ContentTypesUsingRootKeyEncryption() {
    return [
        domain_core_1.ContentType.TYPES.RootKey,
        domain_core_1.ContentType.TYPES.ItemsKey,
        domain_core_1.ContentType.TYPES.EncryptedStorage,
        domain_core_1.ContentType.TYPES.TrustedContact,
        domain_core_1.ContentType.TYPES.KeySystemRootKey,
    ];
}
exports.ContentTypesUsingRootKeyEncryption = ContentTypesUsingRootKeyEncryption;
