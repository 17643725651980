import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentType } from '@standardnotes/snjs';
import Button from '@/Components/Button/Button';
import { Fragment } from 'react';
import { Title, Text, Subtitle } from '@/Components/Preferences/PreferencesComponents/Content';
import PreferencesSegment from '../../../PreferencesComponents/PreferencesSegment';
const ConfirmCustomPlugin = ({ plugin, callback }) => {
    let contentTypeDisplayName = null;
    const contentTypeOrError = ContentType.create(plugin.content_type);
    if (!contentTypeOrError.isFailed()) {
        contentTypeDisplayName = contentTypeOrError.getValue().getDisplayName();
    }
    const fields = [
        {
            label: 'Name',
            value: plugin.name,
        },
        {
            label: 'Description',
            value: plugin.description,
        },
        {
            label: 'Version',
            value: plugin.version,
        },
        {
            label: 'Hosted URL',
            value: plugin.url,
        },
        {
            label: 'Download URL',
            value: plugin.download_url,
        },
        {
            label: 'Extension Type',
            value: contentTypeDisplayName,
        },
    ];
    return (_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Confirm Extension" }), fields.map((field) => {
                if (!field.value) {
                    return undefined;
                }
                return (_jsxs(Fragment, { children: [_jsx(Subtitle, { children: field.label }), _jsx(Text, { className: "wrap mb-2", children: field.value })] }, field.value));
            }), _jsxs("div", { className: "mt-3 flex flex-row", children: [_jsx(Button, { className: "min-w-20", label: "Cancel", onClick: () => callback(false) }), _jsx("div", { className: "min-w-3" }), _jsx(Button, { className: "min-w-20", label: "Install", onClick: () => callback(true) })] })] }));
};
export default ConfirmCustomPlugin;
