import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PrefKey, Platform } from '@standardnotes/snjs';
import { Subtitle, Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { useState } from 'react';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Switch from '@/Components/Switch/Switch';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import usePreference from '@/Hooks/usePreference';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
export const AndroidConfirmBeforeExitKey = 'ConfirmBeforeExit';
const Defaults = ({ application }) => {
    const [androidConfirmBeforeExit, setAndroidConfirmBeforeExit] = useState(() => { var _a; return (_a = application.getValue(AndroidConfirmBeforeExitKey)) !== null && _a !== void 0 ? _a : true; });
    const isMobile = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const spellcheck = usePreference(PrefKey.EditorSpellcheck);
    const addNoteToParentFolders = usePreference(PrefKey.NoteAddToParentFolders);
    const alwaysShowSuperToolbar = usePreference(PrefKey.AlwaysShowSuperToolbar);
    const toggleSpellcheck = () => {
        application.toggleGlobalSpellcheck().catch(console.error);
    };
    const toggleAndroidConfirmBeforeExit = () => {
        const newValue = !androidConfirmBeforeExit;
        setAndroidConfirmBeforeExit(newValue);
        application.setValue(AndroidConfirmBeforeExitKey, newValue);
    };
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Defaults" }), application.platform === Platform.Android && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Always ask before closing app (Android)" }), _jsx(Text, { children: "Whether a confirmation dialog should be shown before closing the app." })] }), _jsx(Switch, { onChange: toggleAndroidConfirmBeforeExit, checked: androidConfirmBeforeExit })] }), _jsx(HorizontalSeparator, { classes: "my-4" })] })), _jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Spellcheck" }), _jsx(Text, { children: "The default spellcheck value for new notes. Spellcheck can be configured per note from the note context menu. Spellcheck may degrade overall typing performance with long notes." })] }), _jsx(Switch, { onChange: toggleSpellcheck, checked: spellcheck })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Add all parent tags when adding a nested tag to a note" }), _jsx(Text, { children: "When enabled, adding a nested tag to a note will automatically add all associated parent tags." })] }), _jsx(Switch, { onChange: () => {
                                application.setPreference(PrefKey.NoteAddToParentFolders, !addNoteToParentFolders).catch(console.error);
                            }, checked: addNoteToParentFolders })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), !isMobile && (_jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Use always-visible toolbar in Super notes" }), _jsx(Text, { children: "When enabled, the Super toolbar will always be shown at the top of the note. It can be temporarily toggled using Cmd/Ctrl+Shift+K. When disabled, the Super toolbar will only be shown as a floating toolbar when text is selected." })] }), _jsx(Switch, { onChange: () => {
                                application.setPreference(PrefKey.AlwaysShowSuperToolbar, !alwaysShowSuperToolbar).catch(console.error);
                            }, checked: alwaysShowSuperToolbar })] }))] }) }));
};
export default Defaults;
