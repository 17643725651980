import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@/Components/Button/Button';
import Icon from '../Icon/Icon';
import AlertDialog from '../AlertDialog/AlertDialog';
const ConfirmOtherSessionsSignOut = observer(({ application }) => {
    const cancelRef = useRef(null);
    const closeDialog = useCallback(() => {
        application.accountMenuController.setOtherSessionsSignOut(false);
    }, [application]);
    const confirm = useCallback(() => {
        application.revokeAllOtherSessions().catch(console.error);
        closeDialog();
        application.alerts
            .alert('You have successfully revoked your sessions from other devices.', undefined, 'Finish')
            .catch(console.error);
    }, [application, closeDialog]);
    return (_jsxs(AlertDialog, { closeDialog: closeDialog, children: [_jsxs("div", { className: "flex items-center justify-between text-lg font-bold capitalize", children: ["End all other sessions?", _jsx("button", { className: "rounded p-1 font-bold hover:bg-contrast", onClick: closeDialog, children: _jsx(Icon, { type: "close" }) })] }), _jsx("div", { className: "sk-panel-row", children: _jsx("p", { className: "text-base text-foreground lg:text-sm", children: "This action will sign out all other devices signed into your account, and remove your data from those devices when they next regain connection to the internet. You may sign back in on those devices at any time." }) }), _jsxs("div", { className: "mt-4 flex justify-end gap-2", children: [_jsx(Button, { ref: cancelRef, onClick: closeDialog, children: "Cancel" }), _jsx(Button, { primary: true, colorStyle: "danger", onClick: confirm, children: "End Sessions" })] })] }));
});
ConfirmOtherSessionsSignOut.displayName = 'ConfirmOtherSessionsSignOut';
const OtherSessionsSignOutContainer = (props) => {
    if (!props.application.accountMenuController.otherSessionsSignOut) {
        return null;
    }
    return _jsx(ConfirmOtherSessionsSignOut, { ...props });
};
export default observer(OtherSessionsSignOutContainer);
