import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { PremiumFeatureIconClass, PremiumFeatureIconName } from '../Icon/PremiumFeatureIcon';
const NoSubscriptionBanner = ({ application, title, message, className, }) => {
    const onClick = () => {
        if (application.isNativeIOS()) {
            application.showPremiumModal();
        }
        else {
            void application.openPurchaseFlow();
        }
    };
    return (_jsxs("div", { className: classNames('grid grid-cols-1 rounded-md border border-border p-4', className), children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { className: classNames('-ml-1 mr-1 h-5 w-5', PremiumFeatureIconClass), type: PremiumFeatureIconName }), _jsx("h1", { className: "sk-h3 m-0 text-sm font-semibold", children: title })] }), _jsx("p", { className: "col-start-1 col-end-3 m-0 mt-1 text-sm", children: message }), _jsx(Button, { primary: true, small: true, className: "col-start-1 col-end-3 mt-3 justify-self-start uppercase", onClick: onClick, children: "Upgrade Features" })] }));
};
export default NoSubscriptionBanner;
