import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import HistoryModalDialogContent from './HistoryModalDialogContent';
import HistoryModalDialog from './HistoryModalDialog';
import { useAndroidBackHandler } from '@/NativeMobileWeb/useAndroidBackHandler';
import { useModalAnimation } from '../Modal/useModalAnimation';
import { useMediaQuery, MutuallyExclusiveMediaQueryBreakpoints } from '@/Hooks/useMediaQuery';
const RevisionHistoryModal = ({ application }) => {
    const addAndroidBackHandler = useAndroidBackHandler();
    const isOpen = Boolean(application.historyModalController.note &&
        application.isAuthorizedToRenderItem(application.historyModalController.note));
    useEffect(() => {
        let removeListener;
        if (isOpen) {
            removeListener = addAndroidBackHandler(() => {
                application.historyModalController.dismissModal();
                return true;
            });
        }
        return () => {
            if (removeListener) {
                removeListener();
            }
        };
    }, [addAndroidBackHandler, application, isOpen]);
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const [isMounted, setElement] = useModalAnimation(isOpen, isMobileScreen);
    if (!isMounted) {
        return null;
    }
    return (_jsx(HistoryModalDialog, { onDismiss: application.historyModalController.dismissModal, ref: setElement, children: !!application.historyModalController.note && (_jsx(HistoryModalDialogContent, { application: application, dismissModal: application.historyModalController.dismissModal, note: application.historyModalController.note })) }));
};
export default observer(RevisionHistoryModal);
