"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiddenContentCriteriaValidator = void 0;
class HiddenContentCriteriaValidator {
    constructor(hiddenContentTypes, element) {
        this.hiddenContentTypes = hiddenContentTypes;
        this.element = element;
    }
    passes() {
        return !this.hiddenContentTypes.includes(this.element.content_type);
    }
}
exports.HiddenContentCriteriaValidator = HiddenContentCriteriaValidator;
