import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useApplication } from '@/Components/ApplicationProvider';
import Modal from '@/Components/Modal/Modal';
import Spinner from '@/Components/Spinner/Spinner';
import { useCallback, useMemo, useState } from 'react';
const DesignateSurvivorModal = ({ vault, members, closeModal, }) => {
    const application = useApplication();
    const [selectedSurvivor, setSelectedSurvivor] = useState(null);
    const [isDesignating, setIsDesignating] = useState(false);
    const designateSelectedSurvivor = useCallback(async () => {
        if (!selectedSurvivor) {
            return;
        }
        if (!vault.isSharedVaultListing()) {
            return;
        }
        try {
            setIsDesignating(true);
            const result = await application.vaultUsers.designateSurvivor(vault, selectedSurvivor.user_uuid);
            if (result.isFailed()) {
                throw new Error(result.getError());
            }
            await application.sync.sync();
            closeModal();
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsDesignating(false);
        }
    }, [application.sync, application.vaultUsers, closeModal, selectedSurvivor, vault]);
    const modalActions = useMemo(() => [
        {
            label: isDesignating ? _jsx(Spinner, { className: "h-5 w-5 border-info-contrast" }) : 'Designate survivor',
            onClick: designateSelectedSurvivor,
            type: 'primary',
            mobileSlot: 'right',
            disabled: !selectedSurvivor || isDesignating,
            hidden: members.length === 0,
        },
        {
            label: 'Cancel',
            onClick: closeModal,
            type: 'cancel',
            mobileSlot: 'left',
        },
    ], [closeModal, designateSelectedSurvivor, isDesignating, members.length, selectedSurvivor]);
    return (_jsx(Modal, { title: "Designate survivor", close: closeModal, actions: modalActions, className: "px-4.5 py-4", children: _jsx("div", { className: "flex flex-col gap-3", children: members.map((member) => {
                const isSelected = (selectedSurvivor === null || selectedSurvivor === void 0 ? void 0 : selectedSurvivor.uuid) === member.uuid;
                const contact = application.contacts.findContactForServerUser(member);
                if (!contact) {
                    return null;
                }
                const isOwner = application.vaultUsers.isVaultUserOwner(member);
                if (isOwner) {
                    return null;
                }
                return (_jsxs("label", { className: "grid grid-cols-[auto_1fr] gap-x-3 gap-y-0.5", children: [_jsx("input", { className: "h-4 w-4 self-center accent-info", type: "radio", name: "survivor", checked: isSelected, onClick: () => setSelectedSurvivor(member) }), _jsx("div", { className: "col-start-2 text-sm font-semibold", children: contact.name }), _jsx("div", { className: "col-start-2 opacity-90", children: contact.contactUuid })] }, member.uuid));
            }) }) }));
};
export default DesignateSurvivorModal;
