import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
export function ChangeContentCallbackPlugin({ providerCallback, }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        const changeContents = (jsonContent) => {
            editor.update(() => {
                const editorState = editor.parseEditorState(jsonContent);
                editor.setEditorState(editorState);
            });
        };
        providerCallback(changeContents);
    }, [editor, providerCallback]);
    return null;
}
