import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { concatenateUint8Arrays } from '@/Utils';
import { ApplicationEvent, fileProgressToHumanReadableString, } from '@standardnotes/snjs';
import { useEffect, useMemo, useState } from 'react';
import Spinner from '@/Components/Spinner/Spinner';
import FilePreviewError from './FilePreviewError';
import { isFileTypePreviewable } from './isFilePreviewable';
import PreviewComponent from './PreviewComponent';
import Button from '../Button/Button';
import { ProtectedIllustration } from '@standardnotes/icons';
const FilePreview = ({ file, application, isEmbeddedInSuper = false, imageZoomLevel, setImageZoomLevel }) => {
    const [isAuthorized, setIsAuthorized] = useState(application.isAuthorizedToRenderItem(file));
    const isFilePreviewable = useMemo(() => {
        return isFileTypePreviewable(file.mimeType);
    }, [file.mimeType]);
    const [isDownloading, setIsDownloading] = useState(true);
    const [downloadProgress, setDownloadProgress] = useState();
    const [downloadedBytes, setDownloadedBytes] = useState();
    useEffect(() => {
        setIsAuthorized(application.isAuthorizedToRenderItem(file));
    }, [file.protected, application, file]);
    useEffect(() => {
        const disposer = application.addEventObserver(async (event) => {
            if (event === ApplicationEvent.UnprotectedSessionBegan) {
                setIsAuthorized(true);
            }
            else if (event === ApplicationEvent.UnprotectedSessionExpired) {
                setIsAuthorized(application.isAuthorizedToRenderItem(file));
            }
        });
        return disposer;
    }, [application, file]);
    useEffect(() => {
        if (!isFilePreviewable || !isAuthorized) {
            setIsDownloading(false);
            setDownloadProgress(undefined);
            setDownloadedBytes(undefined);
            return;
        }
        const downloadFileForPreview = async () => {
            if (downloadedBytes) {
                return;
            }
            setIsDownloading(true);
            try {
                const chunks = [];
                setDownloadProgress(undefined);
                const error = await application.files.downloadFile(file, async (decryptedChunk, progress) => {
                    chunks.push(decryptedChunk);
                    if (progress) {
                        setDownloadProgress(progress);
                    }
                });
                if (!error) {
                    const finalDecryptedBytes = concatenateUint8Arrays(chunks);
                    setDownloadedBytes(finalDecryptedBytes);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setIsDownloading(false);
            }
        };
        void downloadFileForPreview();
    }, [application.files, downloadedBytes, file, isFilePreviewable, isAuthorized]);
    if (!isAuthorized) {
        const hasProtectionSources = application.hasProtectionSources();
        return (_jsxs("div", { className: "flex flex-grow flex-col items-center justify-center", children: [_jsx(ProtectedIllustration, { className: "mb-4 h-30 w-30" }), _jsx("div", { className: "mb-2 text-base font-bold", children: "This file is protected." }), _jsx("p", { className: "max-w-[35ch] text-center text-sm text-passive-0", children: hasProtectionSources
                        ? 'Authenticate to view this file.'
                        : 'Add a passcode or create an account to require authentication to view this file.' }), _jsxs("div", { className: "mt-3 flex gap-3", children: [!hasProtectionSources && (_jsx(Button, { primary: true, small: true, onClick: () => application.showAccountMenu(), children: "Open account menu" })), _jsx(Button, { primary: true, onClick: () => application.protections.authorizeItemAccess(file), children: hasProtectionSources ? 'Authenticate' : 'View file' })] })] }));
    }
    return isDownloading ? (_jsxs("div", { className: "flex flex-grow flex-col items-center justify-center", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Spinner, { className: "mr-3 h-5 w-5" }), downloadProgress && (_jsxs("div", { className: "text-base font-semibold", children: [Math.floor(downloadProgress.percentComplete), "%"] }))] }), downloadProgress ? (_jsx("span", { className: "mt-3", children: fileProgressToHumanReadableString(downloadProgress, file.name, { showPercent: false }) })) : (_jsx("span", { className: "mt-3", children: "Loading..." }))] })) : downloadedBytes ? (_jsx(PreviewComponent, { application: application, file: file, bytes: downloadedBytes, isEmbeddedInSuper: isEmbeddedInSuper, imageZoomLevel: imageZoomLevel, setImageZoomLevel: setImageZoomLevel })) : (_jsx(FilePreviewError, { file: file, filesController: application.filesController, tryAgainCallback: () => {
            setDownloadedBytes(undefined);
        }, isFilePreviewable: isFilePreviewable }));
};
export default FilePreview;
