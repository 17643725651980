import { parseFileName } from '@standardnotes/utils';
export class SuperConverter {
    constructor(converterService) {
        this.converterService = converterService;
        this.convert = async (file, { insertNote, readFileAsText }) => {
            const content = await readFileAsText(file);
            const result = {
                successful: [],
                errored: [],
            };
            if (!this.converterService.isValidSuperString(content)) {
                throw new Error('Content is not valid Super JSON');
            }
            const { name } = parseFileName(file.name);
            const createdAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
            const updatedAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
            const note = await insertNote({
                createdAt: createdAtDate,
                updatedAt: updatedAtDate,
                title: name,
                text: content,
                useSuperIfPossible: true,
            });
            result.successful.push(note);
            return result;
        };
    }
    getImportType() {
        return 'super';
    }
    getSupportedFileTypes() {
        return ['application/json'];
    }
    isContentValid(content) {
        return this.converterService.isValidSuperString(content);
    }
}
