"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDeprecatedEditors = exports.GetDarkThemeFeature = exports.GetNativeThemes = exports.GetPlainNoteFeature = exports.GetSuperNoteFeature = exports.GetIframeEditors = exports.GetIframeAndNativeEditors = exports.FindNativeTheme = exports.FindNativeFeature = exports.GetFeatures = void 0;
const NativeFeatureIdentifier_1 = require("./NativeFeatureIdentifier");
const ServerFeatures_1 = require("../Lists/ServerFeatures");
const ClientFeatures_1 = require("../Lists/ClientFeatures");
const DeprecatedFeatures_1 = require("../Lists/DeprecatedFeatures");
const ExperimentalFeatures_1 = require("../Lists/ExperimentalFeatures");
const IframeEditors_1 = require("../Lists/IframeEditors");
const Themes_1 = require("../Lists/Themes");
const NativeEditors_1 = require("../Lists/NativeEditors");
const ComponentArea_1 = require("../Component/ComponentArea");
function GetFeatures() {
    return [
        ...(0, ServerFeatures_1.serverFeatures)(),
        ...(0, ClientFeatures_1.clientFeatures)(),
        ...(0, Themes_1.themes)(),
        ...(0, NativeEditors_1.nativeEditors)(),
        ...(0, IframeEditors_1.IframeEditors)(),
        ...(0, ExperimentalFeatures_1.experimentalFeatures)(),
        ...(0, DeprecatedFeatures_1.GetDeprecatedFeatures)(),
    ];
}
exports.GetFeatures = GetFeatures;
function FindNativeFeature(identifier) {
    return GetFeatures().find((f) => f.identifier === identifier);
}
exports.FindNativeFeature = FindNativeFeature;
function FindNativeTheme(identifier) {
    return (0, Themes_1.themes)().find((t) => t.identifier === identifier);
}
exports.FindNativeTheme = FindNativeTheme;
function GetIframeAndNativeEditors() {
    return [...(0, IframeEditors_1.IframeEditors)(), ...(0, NativeEditors_1.nativeEditors)()];
}
exports.GetIframeAndNativeEditors = GetIframeAndNativeEditors;
function GetIframeEditors() {
    return (0, IframeEditors_1.IframeEditors)();
}
exports.GetIframeEditors = GetIframeEditors;
function GetSuperNoteFeature() {
    return FindNativeFeature(NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.SuperEditor);
}
exports.GetSuperNoteFeature = GetSuperNoteFeature;
function GetPlainNoteFeature() {
    return FindNativeFeature(NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.PlainEditor);
}
exports.GetPlainNoteFeature = GetPlainNoteFeature;
function GetNativeThemes() {
    return (0, Themes_1.themes)();
}
exports.GetNativeThemes = GetNativeThemes;
function GetDarkThemeFeature() {
    return (0, Themes_1.themes)().find((t) => t.identifier === NativeFeatureIdentifier_1.NativeFeatureIdentifier.TYPES.DarkTheme);
}
exports.GetDarkThemeFeature = GetDarkThemeFeature;
function GetDeprecatedEditors() {
    return (0, DeprecatedFeatures_1.GetDeprecatedFeatures)().filter((f) => f.area === ComponentArea_1.ComponentArea.Editor);
}
exports.GetDeprecatedEditors = GetDeprecatedEditors;
