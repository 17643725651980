import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import SaveSecretKey from './SaveSecretKey';
import ScanQRCode from './ScanQRCode';
import Verification from './Verification';
import TwoFactorSuccess from './TwoFactorSuccess';
const TwoFactorActivationView = ({ activation: act }) => {
    switch (act.activationStep) {
        case 'scan-qr-code':
            return _jsx(ScanQRCode, { activation: act });
        case 'save-secret-key':
            return _jsx(SaveSecretKey, { activation: act });
        case 'verification':
            return _jsx(Verification, { activation: act });
        case 'success':
            return _jsx(TwoFactorSuccess, { activation: act });
    }
};
export default observer(TwoFactorActivationView);
