import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { PremiumFeatureModalType } from './PremiumFeatureModalType';
import { SuccessPrompt } from './Subviews/SuccessPrompt';
import { UpgradePrompt } from './Subviews/UpgradePrompt';
import Modal from '../Modal/Modal';
import SuperDemo from './Subviews/SuperDemo';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
const PremiumFeaturesModal = ({ application, featureName, hasSubscription, onClose, type = PremiumFeatureModalType.UpgradePrompt, }) => {
    const ctaButtonRef = useRef(null);
    const isShowingSuperDemo = type === PremiumFeatureModalType.SuperDemo;
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    return (_jsxs(Modal, { close: onClose, title: isShowingSuperDemo ? 'Try out Super' : 'Upgrade', className: isShowingSuperDemo ? '' : 'px-6 py-5', customHeader: isShowingSuperDemo ? undefined : _jsx(_Fragment, {}), actions: isShowingSuperDemo
            ? [
                {
                    label: 'Done',
                    type: 'primary',
                    onClick: onClose,
                    hidden: !isMobileScreen,
                    mobileSlot: 'right',
                },
            ]
            : undefined, children: [type === PremiumFeatureModalType.UpgradePrompt && (_jsx(UpgradePrompt, { featureName: featureName, ctaRef: ctaButtonRef, application: application, hasSubscription: hasSubscription, onClose: onClose })), type === PremiumFeatureModalType.UpgradeSuccess && _jsx(SuccessPrompt, { ctaRef: ctaButtonRef, onClose: onClose }), type === PremiumFeatureModalType.SuperDemo && _jsx(SuperDemo, { hasSubscription: hasSubscription, onClose: onClose })] }));
};
export default PremiumFeaturesModal;
