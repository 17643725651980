import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
export class ItemOption extends MenuOption {
    constructor(item, label, options) {
        super(label || '');
        this.item = item;
        this.label = label;
        this.options = options;
        this.key = (item === null || item === void 0 ? void 0 : item.uuid) || label;
    }
}
