"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApiOperations = void 0;
var UserApiOperations;
(function (UserApiOperations) {
    UserApiOperations[UserApiOperations["Registering"] = 0] = "Registering";
    UserApiOperations[UserApiOperations["SubmittingRequest"] = 1] = "SubmittingRequest";
    UserApiOperations[UserApiOperations["DeletingAccount"] = 2] = "DeletingAccount";
    UserApiOperations[UserApiOperations["UpdatingUser"] = 3] = "UpdatingUser";
})(UserApiOperations || (exports.UserApiOperations = UserApiOperations = {}));
