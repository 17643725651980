import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Subtitle, Title, Text } from '@/Components/Preferences/PreferencesComponents/Content';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { useMemo } from 'react';
import BackupsDropZone from './BackupsDropZone';
import FileBackupsDesktop from './FileBackupsDesktop';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
const FileBackupsCrossPlatform = ({ application }) => {
    const fileBackupsService = useMemo(() => application.fileBackups, [application]);
    return fileBackupsService ? (_jsx(FileBackupsDesktop, { backupsService: fileBackupsService })) : (_jsx(_Fragment, { children: _jsxs(PreferencesGroup, { children: [_jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Automatic file backups" }), _jsx(Subtitle, { children: "Automatically save encrypted backups of your files." }), _jsx(Text, { className: "mt-3", children: "To enable file backups, use the Standard Notes desktop application." })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(PreferencesSegment, { children: _jsx(BackupsDropZone, { application: application }) })] }) }));
};
export default FileBackupsCrossPlatform;
