import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { LinkButton, Text } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
const NoSubscription = ({ application }) => {
    const [isLoadingPurchaseFlow, setIsLoadingPurchaseFlow] = useState(false);
    const [purchaseFlowError, setPurchaseFlowError] = useState(undefined);
    const onPurchaseClick = async () => {
        const errorMessage = 'There was an error when attempting to redirect you to the subscription page.';
        setIsLoadingPurchaseFlow(true);
        try {
            if (application.isNativeIOS()) {
                application.showPremiumModal();
            }
            else {
                void application.openPurchaseFlow();
            }
        }
        catch (e) {
            setPurchaseFlowError(errorMessage);
        }
        finally {
            setIsLoadingPurchaseFlow(false);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Text, { children: "You don't have a Standard Notes subscription yet." }), isLoadingPurchaseFlow && _jsx(Text, { children: "Redirecting you to the subscription page..." }), purchaseFlowError && _jsx(Text, { className: "text-danger", children: purchaseFlowError }), _jsxs("div", { className: "flex", children: [!application.hideOutboundSubscriptionLinks && (_jsx(LinkButton, { className: "mr-3 mt-3 min-w-20", label: "Learn More", link: window.plansUrl })), application.hasAccount() && (_jsx(Button, { className: "mt-3 min-w-20", primary: true, label: "Subscribe", onClick: onPurchaseClick }))] })] }));
};
export default NoSubscription;
