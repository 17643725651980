"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SureFindItem = exports.FindItem = void 0;
function FindItem(items, uuid) {
    return items.find((item) => item.uuid === uuid);
}
exports.FindItem = FindItem;
function SureFindItem(items, uuid) {
    return FindItem(items, uuid);
}
exports.SureFindItem = SureFindItem;
