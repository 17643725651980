"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewNoteTitleFormat = void 0;
var NewNoteTitleFormat;
(function (NewNoteTitleFormat) {
    NewNoteTitleFormat["CurrentDateAndTime"] = "CurrentDateAndTime";
    NewNoteTitleFormat["CurrentNoteCount"] = "CurrentNoteCount";
    NewNoteTitleFormat["CustomFormat"] = "CustomFormat";
    NewNoteTitleFormat["Empty"] = "Empty";
})(NewNoteTitleFormat || (exports.NewNoteTitleFormat = NewNoteTitleFormat = {}));
