"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isComponentOrTheme = exports.isTheme = exports.isComponent = void 0;
const Item_1 = require("../../Abstract/Item");
const domain_core_1 = require("@standardnotes/domain-core");
function isComponent(x) {
    if (!(0, Item_1.isDecryptedItem)(x)) {
        return false;
    }
    return x.content_type === domain_core_1.ContentType.TYPES.Component;
}
exports.isComponent = isComponent;
function isTheme(x) {
    if (!(0, Item_1.isDecryptedItem)(x)) {
        return false;
    }
    return x.content_type === domain_core_1.ContentType.TYPES.Theme;
}
exports.isTheme = isTheme;
function isComponentOrTheme(x) {
    if (!(0, Item_1.isDecryptedItem)(x)) {
        return false;
    }
    return x.content_type === domain_core_1.ContentType.TYPES.Component || x.content_type === domain_core_1.ContentType.TYPES.Theme;
}
exports.isComponentOrTheme = isComponentOrTheme;
