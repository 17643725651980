"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteHistoryEntry = void 0;
const utils_1 = require("@standardnotes/utils");
const HistoryEntry_1 = require("./HistoryEntry");
class NoteHistoryEntry extends HistoryEntry_1.HistoryEntry {
    previewTitle() {
        if (this.payload.updated_at.getTime() > 0) {
            return this.payload.updated_at.toLocaleString();
        }
        else {
            return this.payload.created_at.toLocaleString();
        }
    }
    previewSubTitle() {
        if (!this.hasPreviousEntry) {
            return `${this.textCharDiffLength} characters loaded`;
        }
        else if (this.textCharDiffLength < 0) {
            return `${this.textCharDiffLength * -1} characters removed`;
        }
        else if (this.textCharDiffLength > 0) {
            return `${this.textCharDiffLength} characters added`;
        }
        else {
            return 'Title or metadata changed';
        }
    }
    isDiscardable() {
        return (0, utils_1.isEmpty)(this.payload.content.text);
    }
}
exports.NoteHistoryEntry = NoteHistoryEntry;
