"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureRepoMutator = void 0;
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
class FeatureRepoMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    set migratedToUserSetting(migratedToUserSetting) {
        this.mutableContent.migratedToUserSetting = migratedToUserSetting;
    }
    set migratedToOfflineEntitlements(migratedToOfflineEntitlements) {
        this.mutableContent.migratedToOfflineEntitlements = migratedToOfflineEntitlements;
    }
    set offlineFeaturesUrl(offlineFeaturesUrl) {
        this.mutableContent.offlineFeaturesUrl = offlineFeaturesUrl;
    }
    set offlineKey(offlineKey) {
        this.mutableContent.offlineKey = offlineKey;
    }
}
exports.FeatureRepoMutator = FeatureRepoMutator;
