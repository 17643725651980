"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptedPayload = void 0;
const utils_1 = require("@standardnotes/utils");
const ItemContent_1 = require("../../Content/ItemContent");
const PayloadSource_1 = require("../Types/PayloadSource");
const PurePayload_1 = require("./PurePayload");
class DecryptedPayload extends PurePayload_1.PurePayload {
    constructor(rawPayload, source = PayloadSource_1.PayloadSource.Constructor) {
        super(rawPayload, source);
        this.content = (0, utils_1.Copy)((0, ItemContent_1.FillItemContent)(rawPayload.content));
        this.deleted = false;
    }
    get references() {
        return this.content.references || [];
    }
    getReference(uuid) {
        const result = this.references.find((ref) => ref.uuid === uuid);
        if (!result) {
            throw new Error('Reference not found');
        }
        return result;
    }
    ejected() {
        return Object.assign(Object.assign({}, super.ejected()), { content: this.content, deleted: this.deleted });
    }
    copy(override, source = this.source) {
        const result = new DecryptedPayload(Object.assign(Object.assign({}, this.ejected()), override), source);
        return result;
    }
    copyAsSyncResolved(override, source = this.source) {
        const result = new DecryptedPayload(Object.assign(Object.assign({}, this.ejected()), override), source);
        return result;
    }
}
exports.DecryptedPayload = DecryptedPayload;
