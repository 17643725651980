import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@standardnotes/snjs';
import { getColorsForPrimaryVariant } from '../Button/Button';
import Icon from '../Icon/Icon';
const PropertiesRequiredForFixedPositioningToWorkOnIOSSafari = {
    transform: 'translate3d(0, 0, 0)',
};
const FloatingAddButton = ({ label, style, onClick }) => {
    const buttonClasses = getColorsForPrimaryVariant(style);
    return (_jsx("button", { className: classNames('fixed bottom-6 right-6 z-editor-title-bar ml-3 flex h-15 w-15 cursor-pointer items-center', `justify-center rounded-full border border-solid border-transparent ${buttonClasses}`, 'hover:brightness-125'), title: label, "aria-label": label, onClick: onClick, style: PropertiesRequiredForFixedPositioningToWorkOnIOSSafari, children: _jsx(Icon, { type: "add", size: "custom", className: "h-8 w-8" }) }));
};
export default FloatingAddButton;
