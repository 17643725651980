"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorLineWidth = void 0;
var EditorLineWidth;
(function (EditorLineWidth) {
    EditorLineWidth["Narrow"] = "Narrow";
    EditorLineWidth["Wide"] = "Wide";
    EditorLineWidth["Dynamic"] = "Dynamic";
    EditorLineWidth["FullWidth"] = "FullWidth";
})(EditorLineWidth || (exports.EditorLineWidth = EditorLineWidth = {}));
