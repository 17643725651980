import { ApplicationEvent, ApplicationStage, Challenge, ChallengePrompt, ChallengeReason, ChallengeStrings, ChallengeValidation, StorageKey, VaultLockServiceEvent, } from '@standardnotes/services';
import { VaultDisplayOptions, } from '@standardnotes/models';
import { VaultDisplayServiceEvent } from './VaultDisplayServiceEvent';
import { AbstractUIService } from '../Abstract/AbstractUIService';
import { action, makeObservable, observable } from 'mobx';
import { ContentType } from '@standardnotes/domain-core';
export class VaultDisplayService extends AbstractUIService {
    constructor(application, internalEventBus) {
        super(application, internalEventBus);
        this.exclusivelyShownVault = undefined;
        this.isVaultExplicitlyExcluded = (vault) => {
            var _a;
            return (_a = this.options.isVaultExplicitlyExcluded(vault)) !== null && _a !== void 0 ? _a : false;
        };
        this.isVaultExclusivelyShown = (vault) => {
            return this.options.isVaultExclusivelyShown(vault);
        };
        this.hideVault = (vault) => {
            const lockedVaults = this.application.vaultLocks.getLockedvaults();
            const newOptions = this.options.newOptionsByExcludingVault(vault, lockedVaults);
            this.setVaultSelectionOptions(newOptions);
        };
        this.unhideVault = async (vault) => {
            if (this.application.vaultLocks.isVaultLocked(vault)) {
                const unlocked = await this.unlockVault(vault);
                if (!unlocked) {
                    return;
                }
            }
            const lockedVaults = this.application.vaultLocks.getLockedvaults();
            const newOptions = this.options.newOptionsByUnexcludingVault(vault, lockedVaults);
            this.setVaultSelectionOptions(newOptions);
        };
        this.showOnlyVault = async (vault) => {
            if (this.application.vaultLocks.isVaultLocked(vault)) {
                const unlocked = await this.unlockVault(vault);
                if (!unlocked) {
                    return;
                }
            }
            const newOptions = new VaultDisplayOptions({ exclusive: vault.systemIdentifier });
            this.setVaultSelectionOptions(newOptions);
        };
        this.setVaultSelectionOptions = (options) => {
            const previousOptions = this.options;
            this.options = options;
            const changingFromMultipleToExclusive = !previousOptions.isInExclusiveDisplayMode() && options.isInExclusiveDisplayMode();
            if (changingFromMultipleToExclusive) {
                this.previousMultipleSelectionOptions = previousOptions;
            }
            if (this.isInExclusiveDisplayMode()) {
                const vaultOrError = this.application.vaults.getVault({
                    keySystemIdentifier: this.options.getExclusivelyShownVault(),
                });
                this.exclusivelyShownVault = vaultOrError.isFailed() ? undefined : vaultOrError.getValue();
            }
            else {
                this.exclusivelyShownVault = undefined;
            }
            this.application.items.setVaultDisplayOptions(options);
            void this.notifyEvent(VaultDisplayServiceEvent.VaultDisplayOptionsChanged, options);
            if (this.application.isLaunched()) {
                this.application.setValue(StorageKey.VaultSelectionOptions, options.getPersistableValue());
            }
        };
        this.loadVaultSelectionOptionsFromDisk = () => {
            const raw = this.application.getValue(StorageKey.VaultSelectionOptions);
            if (!raw) {
                return;
            }
            const options = VaultDisplayOptions.FromPersistableValue(raw);
            this.options = options;
            void this.notifyEvent(VaultDisplayServiceEvent.VaultDisplayOptionsChanged, options);
        };
        this.options = new VaultDisplayOptions({ exclude: [], locked: [] });
        makeObservable(this, {
            options: observable,
            isVaultExplicitlyExcluded: observable,
            isVaultExclusivelyShown: observable,
            exclusivelyShownVault: observable,
            hideVault: action,
            unhideVault: action,
            showOnlyVault: action,
        });
        internalEventBus.addEventHandler(this, VaultLockServiceEvent.VaultLocked);
        internalEventBus.addEventHandler(this, VaultLockServiceEvent.VaultUnlocked);
        internalEventBus.addEventHandler(this, ApplicationEvent.ApplicationStageChanged);
        this.addObserver(application.items.streamItems(ContentType.TYPES.VaultListing, ({ removed }) => {
            if (removed.some((vault) => { var _a; return vault.uuid === ((_a = this.exclusivelyShownVault) === null || _a === void 0 ? void 0 : _a.uuid); })) {
                this.changeToMultipleVaultDisplayMode();
            }
        }));
    }
    async handleEvent(event) {
        if (event.type === VaultLockServiceEvent.VaultLocked || event.type === VaultLockServiceEvent.VaultUnlocked) {
            this.handleVaultLockingStatusChanged();
        }
        else if (event.type === ApplicationEvent.ApplicationStageChanged) {
            const stage = event.payload.stage;
            if (stage === ApplicationStage.StorageDecrypted_09) {
                void this.loadVaultSelectionOptionsFromDisk();
            }
        }
    }
    handleVaultLockingStatusChanged() {
        const lockedVaults = this.application.vaultLocks.getLockedvaults();
        const options = this.options.newOptionsByIntakingLockedVaults(lockedVaults);
        this.setVaultSelectionOptions(options);
    }
    getOptions() {
        return this.options;
    }
    isVaultDisabledOrLocked(vault) {
        return this.options.isVaultDisabledOrLocked(vault);
    }
    isInExclusiveDisplayMode() {
        return this.options.isInExclusiveDisplayMode();
    }
    getItemVault(item) {
        if (this.application.items.isTemplateItem(item)) {
            if (this.exclusivelyShownVault) {
                return this.exclusivelyShownVault;
            }
            return undefined;
        }
        const vault = this.application.vaults.getItemVault(item);
        return vault;
    }
    changeToMultipleVaultDisplayMode() {
        const vaults = this.application.vaults.getVaults();
        const lockedVaults = this.application.vaultLocks.getLockedvaults();
        const exclude = this.previousMultipleSelectionOptions
            ? this.previousMultipleSelectionOptions.getOptions().exclude
            : vaults
                .map((vault) => vault.systemIdentifier)
                .filter((identifier) => { var _a; return identifier !== ((_a = this.exclusivelyShownVault) === null || _a === void 0 ? void 0 : _a.systemIdentifier); });
        const newOptions = new VaultDisplayOptions({
            exclude,
            locked: lockedVaults.map((vault) => vault.systemIdentifier),
        });
        this.setVaultSelectionOptions(newOptions);
    }
    async unlockVault(vault) {
        if (!this.application.vaultLocks.isVaultLocked(vault)) {
            throw new Error('Attempting to unlock a vault that is not locked.');
        }
        const challenge = new Challenge([new ChallengePrompt(ChallengeValidation.None, undefined, 'Password')], ChallengeReason.Custom, true, ChallengeStrings.UnlockVault(vault.name), ChallengeStrings.EnterVaultPassword);
        return new Promise((resolve) => {
            this.application.challenges.addChallengeObserver(challenge, {
                onCancel() {
                    resolve(false);
                },
                onNonvalidatedSubmit: async (challengeResponse) => {
                    const value = challengeResponse.getDefaultValue();
                    if (!value) {
                        this.application.challenges.completeChallenge(challenge);
                        resolve(false);
                        return;
                    }
                    const password = value.value;
                    const unlocked = await this.application.vaultLocks.unlockNonPersistentVault(vault, password);
                    if (!unlocked) {
                        this.application.challenges.setValidationStatusForChallenge(challenge, value, false);
                        resolve(false);
                        return;
                    }
                    this.application.challenges.completeChallenge(challenge);
                    resolve(true);
                },
            });
            void this.application.challenges.promptForChallengeResponse(challenge);
        });
    }
    deinit() {
        ;
        this.options = undefined;
        super.deinit();
    }
}
