"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtocolVersionFromEncryptedString = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const ProtocolVersion_1 = require("./ProtocolVersion");
function ProtocolVersionFromEncryptedString(string) {
    try {
        const version = string.substring(0, ProtocolVersion_1.ProtocolVersionLength);
        if (Object.values(ProtocolVersion_1.ProtocolVersion).includes(version)) {
            return domain_core_1.Result.ok(version);
        }
    }
    catch (error) {
        return domain_core_1.Result.fail(JSON.stringify(error));
    }
    return domain_core_1.Result.fail(`Invalid encrypted string ${string}`);
}
exports.ProtocolVersionFromEncryptedString = ProtocolVersionFromEncryptedString;
