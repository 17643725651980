import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { STRING_EDIT_LOCKED_ATTEMPT } from '@/Constants/Strings';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { createEditorMenuGroups } from '@/Utils/createEditorMenuGroups';
import { NoteType, NativeFeatureIdentifier, } from '@standardnotes/snjs';
import { useCallback, useMemo, useState } from 'react';
import Icon from '../Icon/Icon';
import { PremiumFeatureIconName, PremiumFeatureIconClass } from '../Icon/PremiumFeatureIcon';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';
import { SuperNoteImporter } from '../SuperEditor/SuperNoteImporter';
import { Pill } from '../Preferences/PreferencesComponents/Content';
import ModalOverlay from '../Modal/ModalOverlay';
import MenuSection from '../Menu/MenuSection';
const getGroupId = (group) => group.title.toLowerCase().replace(/\s/, '-');
const ChangeEditorMultipleMenu = ({ application, notes, setDisableClickOutside }) => {
    const premiumModal = usePremiumModal();
    const [itemToBeSelected, setItemToBeSelected] = useState();
    const [confirmationQueue, setConfirmationQueue] = useState([]);
    const hasSelectedLockedNotes = useMemo(() => notes.some((note) => note.locked), [notes]);
    const groups = useMemo(() => createEditorMenuGroups(application), [application]);
    const selectComponent = useCallback(async (uiFeature, note) => {
        if (uiFeature.isComponent && uiFeature.asComponent.conflictOf) {
            void application.changeAndSaveItem.execute(uiFeature.asComponent, (mutator) => {
                mutator.conflictOf = undefined;
            });
        }
        await application.changeAndSaveItem.execute(note, (mutator) => {
            const noteMutator = mutator;
            noteMutator.noteType = uiFeature.noteType;
            noteMutator.editorIdentifier = uiFeature.featureIdentifier;
        });
    }, [application]);
    const handleMenuSelection = useCallback(async (itemToBeSelected) => {
        if (!itemToBeSelected.isEntitled) {
            if (itemToBeSelected.uiFeature.featureIdentifier === NativeFeatureIdentifier.TYPES.SuperEditor) {
                premiumModal.showSuperDemo();
                return;
            }
            premiumModal.activate(itemToBeSelected.uiFeature.displayName);
            return;
        }
        if (hasSelectedLockedNotes) {
            void application.alerts.alert(STRING_EDIT_LOCKED_ATTEMPT);
            return;
        }
        if (itemToBeSelected.uiFeature.noteType === NoteType.Super) {
            setDisableClickOutside(true);
            setItemToBeSelected(itemToBeSelected);
            setConfirmationQueue(notes);
            return;
        }
        const changeRequiresConfirmation = notes.some((note) => {
            const editorForNote = application.componentManager.editorForNote(note);
            return application.componentManager.doesEditorChangeRequireAlert(editorForNote, itemToBeSelected.uiFeature);
        });
        if (changeRequiresConfirmation) {
            const canChange = await application.componentManager.showEditorChangeAlert();
            if (!canChange) {
                return;
            }
        }
        for (const note of notes) {
            void selectComponent(itemToBeSelected.uiFeature, note);
        }
    }, [
        application.alerts,
        application.componentManager,
        hasSelectedLockedNotes,
        notes,
        premiumModal,
        selectComponent,
        setDisableClickOutside,
    ]);
    const groupsWithItems = groups.filter((group) => group.items && group.items.length);
    const showSuperImporter = (itemToBeSelected === null || itemToBeSelected === void 0 ? void 0 : itemToBeSelected.uiFeature.noteType) === NoteType.Super && confirmationQueue.length > 0;
    const closeCurrentSuperNoteImporter = useCallback(() => {
        const remainingNotes = confirmationQueue.slice(1);
        if (remainingNotes.length === 0) {
            setItemToBeSelected(undefined);
            setConfirmationQueue([]);
            setDisableClickOutside(false);
            return;
        }
        setConfirmationQueue(remainingNotes);
    }, [confirmationQueue, setDisableClickOutside]);
    const handleSuperNoteConversionCompletion = useCallback(() => {
        if (!itemToBeSelected) {
            return;
        }
        void selectComponent(itemToBeSelected.uiFeature, confirmationQueue[0]);
        closeCurrentSuperNoteImporter();
    }, [closeCurrentSuperNoteImporter, confirmationQueue, itemToBeSelected, selectComponent]);
    return (_jsxs(_Fragment, { children: [_jsx(Menu, { a11yLabel: "Change note type", children: groupsWithItems.map((group) => (_jsx(MenuSection, { children: group.items.map((item) => {
                        const onClickEditorItem = () => {
                            handleMenuSelection(item).catch(console.error);
                        };
                        return (_jsx(MenuItem, { onClick: onClickEditorItem, className: 'flex-row-reversed py-2', children: _jsxs("div", { className: "flex flex-grow items-center justify-between", children: [_jsxs("div", { className: 'flex items-center', children: [group.icon && _jsx(Icon, { type: group.icon, className: `mr-2 ${group.iconClassName}` }), item.uiFeature.displayName, item.isLabs && (_jsx(Pill, { className: "px-1.5 py-0.5", style: "success", children: "Labs" }))] }), !item.isEntitled && _jsx(Icon, { type: PremiumFeatureIconName, className: PremiumFeatureIconClass })] }) }, item.uiFeature.uniqueIdentifier.value));
                    }) }, getGroupId(group)))) }), _jsx(ModalOverlay, { isOpen: showSuperImporter, close: closeCurrentSuperNoteImporter, children: confirmationQueue[0] && (_jsx(SuperNoteImporter, { note: confirmationQueue[0], application: application, onComplete: handleSuperNoteConversionCompletion, closeDialog: closeCurrentSuperNoteImporter })) })] }));
};
export default ChangeEditorMultipleMenu;
