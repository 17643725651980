"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultInvitesPaths = void 0;
exports.SharedVaultInvitesPaths = {
    createInvite: (sharedVaultUuid) => `/v1/shared-vaults/${sharedVaultUuid}/invites`,
    updateInvite: (sharedVaultUuid, inviteUuid) => `/v1/shared-vaults/${sharedVaultUuid}/invites/${inviteUuid}`,
    acceptInvite: (sharedVaultUuid, inviteUuid) => `/v1/shared-vaults/${sharedVaultUuid}/invites/${inviteUuid}/accept`,
    declineInvite: (sharedVaultUuid, inviteUuid) => `/v1/shared-vaults/${sharedVaultUuid}/invites/${inviteUuid}/decline`,
    getInboundUserInvites: () => '/v1/shared-vaults/invites',
    getOutboundUserInvites: () => '/v1/shared-vaults/invites/outbound',
    getSharedVaultInvites: (sharedVaultUuid) => `/v1/shared-vaults/${sharedVaultUuid}/invites`,
    deleteInvite: (sharedVaultUuid, inviteUuid) => `/v1/shared-vaults/${sharedVaultUuid}/invites/${inviteUuid}`,
    deleteAllSharedVaultInvites: (sharedVaultUuid) => `/v1/shared-vaults/${sharedVaultUuid}/invites`,
    deleteAllInboundInvites: '/v1/shared-vaults/invites/inbound',
    deleteAllOutboundInvites: '/v1/shared-vaults/invites/outbound',
};
