"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmartViewMutator = void 0;
const Tag_1 = require("../Tag");
class SmartViewMutator extends Tag_1.TagMutator {
    constructor(item, type) {
        super(item, type);
    }
    set predicate(predicate) {
        this.mutableContent.predicate = predicate;
    }
}
exports.SmartViewMutator = SmartViewMutator;
