import { INDENT_CONTENT_COMMAND, OUTDENT_CONTENT_COMMAND } from 'lexical';
import { BlockPickerOption } from '../BlockPickerPlugin/BlockPickerOption';
export const IndentBlock = {
    name: 'Indent',
    iconName: 'indent',
    keywords: ['indent'],
    onSelect: (editor) => editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined),
};
export const OutdentBlock = {
    name: 'Outdent',
    iconName: 'outdent',
    keywords: ['outdent'],
    onSelect: (editor) => editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined),
};
export function GetIndentBlockOption(editor) {
    return new BlockPickerOption(IndentBlock.name, {
        iconName: IndentBlock.iconName,
        keywords: IndentBlock.keywords,
        onSelect: () => IndentBlock.onSelect(editor),
    });
}
export function GetOutdentBlockOption(editor) {
    return new BlockPickerOption(OutdentBlock.name, {
        iconName: OutdentBlock.iconName,
        keywords: OutdentBlock.keywords,
        onSelect: () => OutdentBlock.onSelect(editor),
    });
}
