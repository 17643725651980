import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FeatureStatus, NativeFeatureIdentifier } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import NoProSubscription from '../NoProSubscription';
import InvitationsList from './InvitationsList';
import Invite from './Invite/Invite';
import Button from '@/Components/Button/Button';
import SharingStatusText from './SharingStatusText';
import ModalOverlay from '@/Components/Modal/ModalOverlay';
const SubscriptionSharing = ({ application }) => {
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const subscriptionState = application.subscriptionController;
    const isReadOnlySession = application.sessions.isCurrentSessionReadOnly();
    const isSubscriptionSharingFeatureAvailable = application.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.SubscriptionSharing).getValue()) === FeatureStatus.Entitled && !isReadOnlySession;
    const closeInviteDialog = () => setIsInviteDialogOpen(false);
    return (_jsx(PreferencesGroup, { children: _jsx(PreferencesSegment, { children: _jsx("div", { className: "flex flex-row items-center", children: _jsxs("div", { className: "flex flex-grow flex-col", children: [_jsx(Title, { className: "mb-2", children: "Subscription sharing" }), isSubscriptionSharingFeatureAvailable ? (_jsxs("div", { children: [_jsx(SharingStatusText, { subscriptionState: subscriptionState }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(InvitationsList, { subscriptionState: subscriptionState, application: application }), !subscriptionState.allInvitationsUsed && (_jsx(Button, { className: "min-w-20", label: "Invite", onClick: () => setIsInviteDialogOpen(true) })), _jsx(ModalOverlay, { isOpen: isInviteDialogOpen, close: closeInviteDialog, children: _jsx(Invite, { onCloseDialog: closeInviteDialog, application: application, subscriptionState: subscriptionState }) })] })) : (_jsx(NoProSubscription, { application: application, text: _jsxs("span", { children: ["Subscription sharing is available only on the ", _jsx("span", { className: "font-bold", children: "Professional" }), " plan. Please upgrade in order to share your subscription."] }) }))] }) }) }) }));
};
export default observer(SubscriptionSharing);
