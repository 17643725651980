"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentReferenceType = void 0;
var ContentReferenceType;
(function (ContentReferenceType) {
    ContentReferenceType["TagToParentTag"] = "TagToParentTag";
    ContentReferenceType["TagToFile"] = "TagToFile";
    ContentReferenceType["FileToNote"] = "FileToNote";
    ContentReferenceType["FileToFile"] = "FileToFile";
    ContentReferenceType["NoteToNote"] = "NoteToNote";
})(ContentReferenceType || (exports.ContentReferenceType = ContentReferenceType = {}));
