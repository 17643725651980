"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorLineHeightValues = exports.EditorLineHeight = void 0;
var EditorLineHeight;
(function (EditorLineHeight) {
    EditorLineHeight["None"] = "None";
    EditorLineHeight["Tight"] = "Tight";
    EditorLineHeight["Snug"] = "Snug";
    EditorLineHeight["Normal"] = "Normal";
    EditorLineHeight["Relaxed"] = "Relaxed";
    EditorLineHeight["Loose"] = "Loose";
})(EditorLineHeight || (exports.EditorLineHeight = EditorLineHeight = {}));
// https://tailwindcss.com/docs/line-height
exports.EditorLineHeightValues = {
    None: 1,
    Tight: 1.25,
    Snug: 1.375,
    Normal: 1.5,
    Relaxed: 1.625,
    Loose: 2,
};
