import { addCalendarMonths, numberOfMonthsBetweenDates } from '@/Utils/DateUtils';
export function dateToDateOnlyString(date) {
    return date.toLocaleDateString();
}
export function createActivityRecord(activities) {
    const map = {};
    for (const activity of activities) {
        const string = dateToDateOnlyString(activity.date);
        if (!map[string]) {
            map[string] = [];
        }
        map[string].push(activity);
    }
    return map;
}
export function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
export function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return startDate === 0 ? 7 : startDate;
}
/**
 * Modifies months array in-place.
 */
export function insertMonths(months, location, number) {
    const earlierMonth = months[0].date;
    const laterMonth = months[months.length - 1].date;
    for (let i = 1; i <= number; i++) {
        if (location === 'front') {
            const minusNFromFirstMonth = addCalendarMonths(earlierMonth, -i);
            months.unshift({
                date: minusNFromFirstMonth,
            });
        }
        else {
            const plusNFromLastMonth = addCalendarMonths(laterMonth, i);
            months.push({
                date: plusNFromLastMonth,
            });
        }
    }
    return months;
}
/**
 * Modifies months array in-place.
 */
export function insertMonthsWithTarget(months, targetMonth) {
    const firstMonth = months[0].date;
    const lastMonth = months[months.length - 1].date;
    const isBeforeFirstMonth = targetMonth.getTime() < firstMonth.getTime();
    const numMonthsToAdd = Math.abs(isBeforeFirstMonth
        ? numberOfMonthsBetweenDates(firstMonth, targetMonth)
        : numberOfMonthsBetweenDates(lastMonth, targetMonth));
    if (isBeforeFirstMonth) {
        return insertMonths(months, 'front', numMonthsToAdd);
    }
    else {
        return insertMonths(months, 'end', numMonthsToAdd);
    }
}
/**
 * Modifies months array in-place.
 */
export function removeMonths(months, location, number) {
    if (location === 'front') {
        months.splice(0, number);
    }
    else {
        months.splice(months.length - number - 1, number);
    }
}
