import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDropdownItemsForAllEditors } from '@/Utils/DropdownItemsForEditors';
import { NoteType } from '@standardnotes/snjs';
import { useApplication } from '../ApplicationProvider';
import { PredicateKeypathTypes } from './PredicateKeypaths';
const PredicateValue = ({ keypath, value, setValue }) => {
    const application = useApplication();
    const type = PredicateKeypathTypes[keypath];
    const editorItems = getDropdownItemsForAllEditors(application);
    return type === 'noteType' ? (_jsx("select", { className: "flex-grow rounded border border-border bg-default px-2 py-1.5 focus:outline focus:outline-1 focus:outline-info", value: value, onChange: (event) => {
            setValue(event.target.value);
        }, children: Object.entries(NoteType).map(([key, value]) => (_jsx("option", { value: value, children: key }, key))) })) : type === 'editorIdentifier' ? (_jsx("select", { className: "flex-grow rounded border border-border bg-default px-2 py-1.5 focus:outline focus:outline-1 focus:outline-info", value: value, onChange: (event) => {
            setValue(event.target.value);
        }, children: editorItems.map((editor) => (_jsx("option", { value: editor.value, children: editor.label }, editor.value))) })) : type === 'string' || type === 'date' ? (_jsx("input", { className: "flex-grow rounded border border-border bg-default px-2 py-1.5", value: value, onChange: (event) => {
            setValue(event.target.value);
        } })) : type === 'boolean' ? (_jsxs("select", { className: "flex-grow rounded border border-border bg-default px-2 py-1.5 focus:outline focus:outline-1 focus:outline-info", value: value, onChange: (event) => {
            setValue(event.target.value);
        }, children: [_jsx("option", { value: "true", children: "True" }), _jsx("option", { value: "false", children: "False" })] })) : type === 'number' ? (_jsx("input", { type: "number", className: "flex-grow rounded border border-border bg-default px-2 py-1.5", value: value, onChange: (event) => {
            setValue(event.target.value);
        } })) : null;
};
export default PredicateValue;
