import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useRef, useState } from 'react';
import Icon from '@/Components/Icon/Icon';
import { KeyboardKey } from '@standardnotes/ui-services';
import Popover from '../Popover/Popover';
import { classNames } from '@standardnotes/snjs';
import { useApplication } from '../ApplicationProvider';
import MenuItem from '../Menu/MenuItem';
import Menu from '../Menu/Menu';
const VaultMenu = observer(({ items }) => {
    const application = useApplication();
    const vaults = application.vaults.getVaults();
    const addItemsToVault = useCallback(async (vault) => {
        if (application.vaultLocks.isVaultLocked(vault)) {
            const unlocked = await application.vaultDisplayService.unlockVault(vault);
            if (!unlocked) {
                return;
            }
        }
        for (const item of items) {
            const result = await application.vaults.moveItemToVault(vault, item);
            if (result.isFailed()) {
                console.error(result.getError());
            }
        }
    }, [application, items]);
    const removeItemsFromVault = useCallback(async () => {
        for (const item of items) {
            const vault = application.vaults.getItemVault(item);
            if (!vault) {
                continue;
            }
            if (application.vaultLocks.isVaultLocked(vault)) {
                const unlocked = await application.vaultDisplayService.unlockVault(vault);
                if (!unlocked) {
                    return;
                }
            }
            await application.vaults.removeItemFromVault(item);
        }
    }, [application, items]);
    const doesVaultContainItems = (vault) => {
        return items.every((item) => item.key_system_identifier === vault.systemIdentifier);
    };
    const doSomeItemsBelongToVault = items.some((item) => application.vaults.isItemInVault(item));
    const singleItemVault = items.length === 1 ? application.vaults.getItemVault(items[0]) : undefined;
    return (_jsxs(Menu, { a11yLabel: "Vault selection menu", children: [doSomeItemsBelongToVault && (_jsxs(MenuItem, { onClick: () => {
                    void removeItemsFromVault();
                }, children: [_jsx(Icon, { type: "close", className: "mr-2 text-neutral" }), "Move out of ", singleItemVault ? singleItemVault.name : 'vaults'] })), !vaults.length && _jsx("div", { className: "flex flex-col items-center justify-center py-1", children: "No vaults found" }), vaults.map((vault) => {
                if (singleItemVault) {
                    return null;
                }
                return (_jsxs(MenuItem, { onClick: () => {
                        doesVaultContainItems(vault) ? void removeItemsFromVault() : void addItemsToVault(vault);
                    }, className: doesVaultContainItems(vault) ? 'font-bold' : '', disabled: vault.isSharedVaultListing() && application.vaultUsers.isCurrentUserReadonlyVaultMember(vault), children: [_jsx(Icon, { type: vault.iconString, size: "custom", className: classNames('mr-2 h-6 w-6 text-neutral md:h-5 md:w-5', doesVaultContainItems(vault) ? 'text-info' : '') }), _jsxs("div", { className: "flex w-full items-center", children: [vault.name, application.vaultLocks.isVaultLocked(vault) && _jsx(Icon, { className: "ml-1", type: "lock", size: 'small' })] })] }, vault.uuid));
            })] }));
});
const AddToVaultMenuOption = ({ iconClassName, items, disabled, }) => {
    const application = useApplication();
    const buttonRef = useRef(null);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const toggleSubMenu = useCallback(() => {
        setIsSubMenuOpen((isOpen) => !isOpen);
    }, []);
    if (!application.featuresController.isVaultsEnabled()) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs(MenuItem, { className: "justify-between", onClick: toggleSubMenu, onKeyDown: (event) => {
                    if (event.key === KeyboardKey.Escape) {
                        setIsSubMenuOpen(false);
                    }
                }, ref: buttonRef, disabled: disabled, children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { type: "safe-square", className: iconClassName }), "Move to vault"] }), _jsx(Icon, { type: "chevron-right", className: "text-neutral" })] }), _jsx(Popover, { title: "Move to vault", togglePopover: toggleSubMenu, anchorElement: buttonRef, open: isSubMenuOpen, side: "right", align: "start", className: "py-2", overrideZIndex: "z-modal", children: _jsx(VaultMenu, { items: items }) })] }));
};
export default observer(AddToVaultMenuOption);
