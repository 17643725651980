"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaRemoteSaved = void 0;
const DeletedPayload_1 = require("./../../Abstract/Payload/Implementations/DeletedPayload");
const PayloadSource_1 = require("../../Abstract/Payload/Types/PayloadSource");
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
const Payload_1 = require("../../Abstract/Payload");
const ApplyDirtyState_1 = require("./Utilities/ApplyDirtyState");
const SyncResolvedPayload_1 = require("./Utilities/SyncResolvedPayload");
const DirtyCounter_1 = require("../DirtyCounter/DirtyCounter");
class DeltaRemoteSaved {
    constructor(baseCollection, applyContextualPayloads) {
        this.baseCollection = baseCollection;
        this.applyContextualPayloads = applyContextualPayloads;
    }
    result() {
        const processed = [];
        for (const apply of this.applyContextualPayloads) {
            const base = this.baseCollection.find(apply.uuid);
            if (!base) {
                const discarded = new DeletedPayload_1.DeletedPayload(Object.assign(Object.assign(Object.assign({}, apply), { deleted: true, content: undefined }), (0, SyncResolvedPayload_1.BuildSyncResolvedParams)({
                    dirty: false,
                    lastSyncEnd: new Date(),
                })), PayloadSource_1.PayloadSource.RemoteSaved);
                processed.push(discarded);
                continue;
            }
            /**
             * If we save an item, but while in transit it is deleted locally, we want to keep
             * local deletion status, and not old (false) deleted value that was sent to server.
             */
            if ((0, TypeCheck_1.isDeletedPayload)(base)) {
                const baseWasDeletedAfterThisRequest = !apply.deleted;
                const regularDeletedPayload = apply.deleted;
                if (baseWasDeletedAfterThisRequest) {
                    const result = new DeletedPayload_1.DeletedPayload(Object.assign(Object.assign(Object.assign({}, apply), { deleted: true, content: undefined, dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)() }), (0, SyncResolvedPayload_1.BuildSyncResolvedParams)({
                        dirty: true,
                        lastSyncEnd: new Date(),
                    })), PayloadSource_1.PayloadSource.RemoteSaved);
                    processed.push(result);
                }
                else if (regularDeletedPayload) {
                    const discarded = base.copy(Object.assign(Object.assign(Object.assign({}, apply), { deleted: true }), (0, SyncResolvedPayload_1.BuildSyncResolvedParams)({
                        dirty: false,
                        lastSyncEnd: new Date(),
                    })), PayloadSource_1.PayloadSource.RemoteSaved);
                    processed.push(discarded);
                }
            }
            else {
                const result = (0, ApplyDirtyState_1.payloadByFinalizingSyncState)(base.copy(Object.assign(Object.assign({}, apply), { deleted: false }), PayloadSource_1.PayloadSource.RemoteSaved), this.baseCollection);
                processed.push(result);
            }
        }
        return {
            emits: processed,
            source: Payload_1.PayloadEmitSource.RemoteSaved,
        };
    }
}
exports.DeltaRemoteSaved = DeltaRemoteSaved;
