export class ByteChunker {
    constructor(minimumChunkSize, onChunk) {
        this.minimumChunkSize = minimumChunkSize;
        this.onChunk = onChunk;
        this.loggingEnabled = false;
        this.bytes = new Uint8Array();
        this.index = 1;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log(...args) {
        if (!this.loggingEnabled) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log(args);
    }
    async addBytes(bytes, isLast) {
        this.bytes = new Uint8Array([...this.bytes, ...bytes]);
        this.log(`Chunker adding ${bytes.length}, total size ${this.bytes.length}`);
        if (this.bytes.length >= this.minimumChunkSize || isLast) {
            await this.popBytes(isLast);
        }
    }
    async popBytes(isLast) {
        const maxIndex = Math.max(this.minimumChunkSize, this.bytes.length);
        const chunk = this.bytes.slice(0, maxIndex);
        this.bytes = new Uint8Array([...this.bytes.slice(maxIndex)]);
        this.log(`Chunker popping ${chunk.length}, total size in queue ${this.bytes.length}`);
        await this.onChunk({ data: chunk, index: this.index++, isLast });
    }
}
