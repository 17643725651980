import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import PurchaseFlowView from './PurchaseFlowView';
const PurchaseFlowWrapper = ({ application }) => {
    if (!application.purchaseFlowController.isOpen) {
        return null;
    }
    return _jsx(PurchaseFlowView, { application: application });
};
export default observer(PurchaseFlowWrapper);
