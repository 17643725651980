import { sanitizeHtmlString } from '@standardnotes/utils';
import { SKAlert } from '@standardnotes/styles';
/** @returns a promise resolving to true if the user confirmed, false if they canceled */
export function confirmDialog({ text, title, confirmButtonText = 'Confirm', cancelButtonText = 'Cancel', confirmButtonStyle = 'info', }) {
    return new Promise((resolve) => {
        const alert = new SKAlert({
            title: title && sanitizeHtmlString(title),
            text: sanitizeHtmlString(text),
            buttons: [
                {
                    text: cancelButtonText,
                    style: 'default',
                    action() {
                        resolve(false);
                    },
                },
                {
                    text: confirmButtonText,
                    style: confirmButtonStyle,
                    primary: true,
                    action() {
                        resolve(true);
                    },
                },
            ],
        });
        alert.present();
    });
}
export function alertDialog({ title, text, closeButtonText = 'OK', }) {
    return new Promise((resolve) => {
        const alert = new SKAlert({
            title: title && sanitizeHtmlString(title),
            text: sanitizeHtmlString(text),
            buttons: [
                {
                    text: closeButtonText,
                    style: 'default',
                    action: resolve,
                },
            ],
        });
        alert.present();
    });
}
