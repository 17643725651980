import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useDeferredValue, useEffect, useRef, } from 'react';
import { observer } from 'mobx-react-lite';
import { classNames } from '@standardnotes/utils';
import { ElementIds } from '@/Constants/ElementIDs';
import { getLinkingSearchResults } from '@/Utils/Items/Search/getSearchResults';
import { useApplication } from '../ApplicationProvider';
import { SNNote } from '@standardnotes/snjs';
import { Combobox, ComboboxItem, ComboboxPopover, useComboboxStore, VisuallyHidden } from '@ariakit/react';
import LinkedItemMeta from './LinkedItemMeta';
import { LinkedItemSearchResultsAddTagOption } from './LinkedItemSearchResultsAddTagOption';
import { Slot } from '@radix-ui/react-slot';
import Icon from '../Icon/Icon';
import { PremiumFeatureIconName } from '../Icon/PremiumFeatureIcon';
import { KeyboardKey } from '@standardnotes/ui-services';
import { mergeRefs } from '@/Hooks/mergeRefs';
const ItemLinkAutocompleteInput = forwardRef(({ linkingController, focusPreviousItem, focusedId, setFocusedId, hoverLabel, item }, forwardedRef) => {
    const application = useApplication();
    const { getLinkedTagsForItem, linkItems, createAndAddNewTag, isEntitledToNoteLinking } = linkingController;
    const tagsLinkedToItem = getLinkedTagsForItem(item) || [];
    const combobox = useComboboxStore();
    const value = combobox.useState('value');
    const searchQuery = useDeferredValue(value);
    const { unlinkedItems, shouldShowCreateTag } = getLinkingSearchResults(searchQuery, application, item);
    const inputRef = useRef(null);
    const onFormSubmit = async (event) => {
        event.preventDefault();
        if (searchQuery !== '') {
            await createAndAddNewTag(searchQuery);
            combobox.setValue('');
        }
    };
    const handleFocus = () => {
        if (focusedId !== ElementIds.ItemLinkAutocompleteInput) {
            setFocusedId(ElementIds.ItemLinkAutocompleteInput);
        }
    };
    const onKeyDown = (event) => {
        switch (event.key) {
            case KeyboardKey.Left:
                if (searchQuery.length === 0) {
                    focusPreviousItem();
                }
                break;
        }
    };
    useEffect(() => {
        var _a;
        if (focusedId === ElementIds.ItemLinkAutocompleteInput) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [focusedId]);
    return (_jsx("div", { children: _jsxs("form", { onSubmit: onFormSubmit, children: [_jsxs("label", { children: [_jsx(VisuallyHidden, { children: "Link tags, notes or files" }), _jsx(Combobox, { store: combobox, placeholder: "Link tags, notes, files...", className: classNames(`${tagsLinkedToItem.length > 0 ? 'w-80' : 'mr-10 w-70'}`, 'h-7 w-70 bg-transparent text-sm text-text focus:border-b-2 focus:border-info focus:shadow-none focus:outline-none lg:text-xs'), title: hoverLabel, id: ElementIds.ItemLinkAutocompleteInput, ref: mergeRefs([inputRef, forwardedRef]), onFocus: handleFocus, onKeyDown: onKeyDown })] }), _jsxs(ComboboxPopover, { store: combobox, className: classNames('z-dropdown-menu max-h-[var(--popover-available-height)] w-[var(--popover-anchor-width)] overflow-y-auto rounded bg-default py-2 shadow-main', unlinkedItems.length === 0 && !shouldShowCreateTag && 'hidden'), children: [unlinkedItems.map((result) => {
                            const cannotLinkItem = !isEntitledToNoteLinking && result instanceof SNNote;
                            return (_jsxs(ComboboxItem, { className: "flex w-full cursor-pointer items-center justify-between gap-4 overflow-hidden px-3 py-2 hover:bg-contrast hover:text-foreground [&[data-active-item]]:bg-info-backdrop", hideOnClick: true, onClick: () => {
                                    linkItems(item, result).catch(console.error);
                                    combobox.setValue('');
                                }, children: [_jsx(LinkedItemMeta, { item: result, searchQuery: searchQuery }), cannotLinkItem && _jsx(Icon, { type: PremiumFeatureIconName, className: "ml-auto flex-shrink-0 text-info" })] }, result.uuid));
                        }), shouldShowCreateTag && (_jsx(ComboboxItem, { hideOnClick: true, render: _jsx(Slot, {}), onClick: () => {
                                void createAndAddNewTag(searchQuery);
                                combobox.setValue('');
                            }, children: _jsx(LinkedItemSearchResultsAddTagOption, { searchQuery: searchQuery }) }))] })] }) }));
});
export default observer(ItemLinkAutocompleteInput);
