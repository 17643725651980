"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environment = void 0;
var Environment;
(function (Environment) {
    Environment[Environment["Web"] = 1] = "Web";
    Environment[Environment["Desktop"] = 2] = "Desktop";
    Environment[Environment["Mobile"] = 3] = "Mobile";
    Environment[Environment["Clipper"] = 4] = "Clipper";
})(Environment || (exports.Environment = Environment = {}));
