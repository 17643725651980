import { destroyAllObjectProperties } from '@/Utils';
import { ApplicationEvent, InvitationStatus, SubscriptionManagerEvent, } from '@standardnotes/snjs';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { AbstractViewController } from '../Abstract/AbstractViewController';
export class SubscriptionController extends AbstractViewController {
    constructor(subscriptions, sessions, features, eventBus) {
        super(eventBus);
        this.subscriptions = subscriptions;
        this.sessions = sessions;
        this.features = features;
        this.ALLOWED_SUBSCRIPTION_INVITATIONS = 5;
        this.subscriptionInvitations = undefined;
        this.onlineSubscription = undefined;
        this.hasAccount = sessions.isSignedIn();
        makeObservable(this, {
            subscriptionInvitations: observable,
            hasAccount: observable,
            onlineSubscription: observable,
            usedInvitationsCount: computed,
            allowedInvitationsCount: computed,
            allInvitationsUsed: computed,
        });
        eventBus.addEventHandler(this, ApplicationEvent.Launched);
        eventBus.addEventHandler(this, ApplicationEvent.SignedIn);
        eventBus.addEventHandler(this, ApplicationEvent.UserRolesChanged);
        eventBus.addEventHandler(this, SubscriptionManagerEvent.DidFetchSubscription);
    }
    deinit() {
        super.deinit();
        this.subscriptionInvitations = undefined;
        destroyAllObjectProperties(this);
    }
    async handleEvent(event) {
        switch (event.type) {
            case ApplicationEvent.Launched: {
                if (this.sessions.isSignedIn()) {
                    this.reloadSubscriptionInvitations().catch(console.error);
                }
                runInAction(() => {
                    this.hasAccount = this.sessions.isSignedIn();
                });
                break;
            }
            case ApplicationEvent.SignedIn: {
                this.reloadSubscriptionInvitations().catch(console.error);
                runInAction(() => {
                    this.hasAccount = this.sessions.isSignedIn();
                });
                break;
            }
            case SubscriptionManagerEvent.DidFetchSubscription: {
                runInAction(() => {
                    this.onlineSubscription = this.subscriptions.getOnlineSubscription();
                });
                break;
            }
            case ApplicationEvent.UserRolesChanged: {
                this.reloadSubscriptionInvitations().catch(console.error);
                break;
            }
        }
    }
    hasFirstPartyOnlineOrOfflineSubscription() {
        const offline = this.features.hasFirstPartyOfflineSubscription();
        if (!this.sessions.isSignedIn() || !this.sessions.isSignedIntoFirstPartyServer()) {
            return offline;
        }
        return !!this.subscriptions.getOnlineSubscription() || offline;
    }
    get usedInvitationsCount() {
        var _a, _b;
        return ((_b = (_a = this.subscriptionInvitations) === null || _a === void 0 ? void 0 : _a.filter((invitation) => [InvitationStatus.Accepted, InvitationStatus.Sent].includes(invitation.status)).length) !== null && _b !== void 0 ? _b : 0);
    }
    get allowedInvitationsCount() {
        return this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    get allInvitationsUsed() {
        return this.usedInvitationsCount === this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    async sendSubscriptionInvitation(inviteeEmail) {
        const success = await this.subscriptions.inviteToSubscription(inviteeEmail);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async cancelSubscriptionInvitation(invitationUuid) {
        const success = await this.subscriptions.cancelInvitation(invitationUuid);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async reloadSubscriptionInvitations() {
        this.subscriptionInvitations = await this.subscriptions.listSubscriptionInvitations();
    }
}
