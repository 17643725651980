"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitationStatus = void 0;
var InvitationStatus;
(function (InvitationStatus) {
    InvitationStatus["Sent"] = "sent";
    InvitationStatus["Canceled"] = "canceled";
    InvitationStatus["Accepted"] = "accepted";
    InvitationStatus["Declined"] = "declined";
})(InvitationStatus || (exports.InvitationStatus = InvitationStatus = {}));
