"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricMessagesPaths = void 0;
exports.AsymmetricMessagesPaths = {
    createMessage: '/v1/messages',
    getMessages: '/v1/messages',
    updateMessage: (messageUuid) => `/v1/messages/${messageUuid}`,
    getInboundUserMessages: () => '/v1/messages',
    getOutboundUserMessages: () => '/v1/messages/outbound',
    deleteMessage: (messageUuid) => `/v1/messages/${messageUuid}`,
    deleteAllInboundMessages: '/v1/messages/inbound',
};
