import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames } from '@standardnotes/utils';
import Icon from '@/Components/Icon/Icon';
import { ElementIds } from '@/Constants/ElementIDs';
import { useApplication } from '@/Components/ApplicationProvider';
import { useEffect, useState } from 'react';
const StatusIndicator = ({ status, className, homeServerService }) => {
    const application = useApplication();
    const [signInStatusMessage, setSignInStatusMessage] = useState('');
    const [signInStatusIcon, setSignInStatusIcon] = useState('');
    const [signInStatusClassName, setSignInStatusClassName] = useState('');
    let statusClassName;
    let icon;
    switch (status === null || status === void 0 ? void 0 : status.state) {
        case 'online':
            statusClassName = 'bg-success text-success-contrast';
            icon = 'check';
            break;
        case 'error':
            statusClassName = 'bg-danger text-danger-contrast';
            icon = 'warning';
            break;
        default:
            statusClassName = 'bg-contrast';
            icon = 'sync';
            break;
    }
    useEffect(() => {
        async function updateSignedInStatus() {
            const signedInUser = application.sessions.getUser();
            if (signedInUser) {
                const isUsingHomeServer = await application.isUsingHomeServer();
                if (isUsingHomeServer) {
                    setSignInStatusMessage(`You are currently signed into your home server under ${signedInUser.email}`);
                    setSignInStatusClassName('bg-success text-success-contrast');
                    setSignInStatusIcon('check');
                }
                else {
                    setSignInStatusMessage(`You are not currently signed into your home server. To use your home server, sign out of ${signedInUser.email}, then sign in or register using ${await homeServerService.getHomeServerUrl()}.`);
                    setSignInStatusClassName('bg-warning text-warning-contrast');
                    setSignInStatusIcon('warning');
                }
            }
            else {
                setSignInStatusMessage(`You are not currently signed into your home server. To use your home server, sign in or register using ${await homeServerService.getHomeServerUrl()}`);
                setSignInStatusClassName('bg-warning text-warning-contrast');
                setSignInStatusIcon('warning');
            }
        }
        void updateSignedInStatus();
    }, [application, homeServerService, setSignInStatusMessage]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mt-2.5 flex flex-row items-center", children: [_jsx("div", { className: "note-status-tooltip-container relative", children: _jsx("div", { className: classNames('peer flex h-5 w-5 items-center justify-center rounded-full', statusClassName, className), "aria-describedby": ElementIds.NoteStatusTooltip, children: _jsx(Icon, { className: (status === null || status === void 0 ? void 0 : status.state) === 'restarting' ? 'animate-spin' : '', type: icon, size: "small" }) }) }), _jsxs("div", { children: [_jsx("div", { className: 'mr-3 font-bold', children: status === null || status === void 0 ? void 0 : status.message }), _jsx("div", { className: 'mr-3', children: status === null || status === void 0 ? void 0 : status.description })] })] }), (status === null || status === void 0 ? void 0 : status.state) !== 'restarting' && (_jsxs("div", { className: "mt-2.5 flex flex-row items-center", children: [_jsx("div", { className: "note-status-tooltip-container relative", children: _jsx("div", { className: classNames('peer flex h-5 w-5 items-center justify-center rounded-full', signInStatusClassName, className), "aria-describedby": ElementIds.NoteStatusTooltip, children: _jsx(Icon, { type: signInStatusIcon, size: "small" }) }) }), _jsx("div", { children: _jsx("div", { className: 'mr-3', children: signInStatusMessage }) })] }))] }));
};
export default StatusIndicator;
