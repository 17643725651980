import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ElementIds } from '@/Constants/ElementIDs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import FileOptionsPanel from '@/Components/FileContextMenu/FileOptionsPanel';
import FilePreview from '@/Components/FilePreview/FilePreview';
import MobileItemsListButton from '../NoteGroupView/MobileItemsListButton';
import LinkedItemsButton from '../LinkedItems/LinkedItemsButton';
import LinkedItemBubblesContainer from '../LinkedItems/LinkedItemBubblesContainer';
import Popover from '../Popover/Popover';
import FilePreviewInfoPanel from '../FilePreview/FilePreviewInfoPanel';
import { useFileDragNDrop } from '../FileDragNDropProvider';
import RoundIconButton from '../Button/RoundIconButton';
import { useItemVaultInfo } from '@/Hooks/useItemVaultInfo';
import Icon from '../Icon/Icon';
import { VaultUserServiceEvent } from '@standardnotes/snjs';
const SyncTimeoutNoDebounceMs = 100;
const SyncTimeoutDebounceMs = 350;
const FileViewWithoutProtection = ({ application, file }) => {
    const { vault } = useItemVaultInfo(file);
    const [isReadonly, setIsReadonly] = useState(false);
    useEffect(() => {
        if (!vault) {
            return;
        }
        setIsReadonly(application.vaultUsers.isCurrentUserReadonlyVaultMember(vault));
    }, [application.vaultUsers, vault]);
    useEffect(() => {
        return application.vaultUsers.addEventObserver((event, data) => {
            var _a;
            if (event === VaultUserServiceEvent.InvalidatedUserCacheForVault) {
                if (data !== ((_a = vault === null || vault === void 0 ? void 0 : vault.sharing) === null || _a === void 0 ? void 0 : _a.sharedVaultUuid)) {
                    return;
                }
                setIsReadonly(vault ? application.vaultUsers.isCurrentUserReadonlyVaultMember(vault) : false);
            }
        });
    }, [application.vaultUsers, vault]);
    const syncTimeoutRef = useRef();
    const fileInfoButtonRef = useRef(null);
    const [isFileInfoPanelOpen, setIsFileInfoPanelOpen] = useState(false);
    const toggleFileInfoPanel = () => {
        setIsFileInfoPanelOpen((show) => !show);
    };
    const onTitleChange = useCallback(async (event) => {
        if (syncTimeoutRef.current) {
            clearTimeout(syncTimeoutRef.current);
        }
        const shouldNotDebounce = application.sessions.isSignedOut();
        const syncDebounceMs = shouldNotDebounce ? SyncTimeoutNoDebounceMs : SyncTimeoutDebounceMs;
        syncTimeoutRef.current = window.setTimeout(async () => {
            await application.mutator.renameFile(file, event.target.value);
            void application.sync.sync();
        }, syncDebounceMs);
    }, [application, file]);
    const fileDragTargetRef = useRef(null);
    const { addDragTarget, removeDragTarget } = useFileDragNDrop();
    useEffect(() => {
        const target = fileDragTargetRef.current;
        if (target) {
            addDragTarget(target, {
                tooltipText: 'Drop your files to upload and link them to the current file',
                async callback(uploadedFile) {
                    await application.linkingController.linkItems(uploadedFile, file);
                },
            });
        }
        return () => {
            if (target) {
                removeDragTarget(target);
            }
        };
    }, [addDragTarget, file, removeDragTarget, application.linkingController]);
    return (_jsxs("div", { className: "sn-component section editor", "aria-label": "File", ref: fileDragTargetRef, children: [_jsxs("div", { className: "flex flex-col", children: [isReadonly && (_jsxs("div", { className: "bg-warning-faded relative flex items-center px-3.5 py-2 text-sm text-accessory-tint-3", children: [_jsx(Icon, { type: "pencil-off", className: "mr-3" }), "This file is readonly"] })), _jsxs("div", { className: "content-title-bar section-title-bar section-title-bar z-editor-title-bar w-full", id: "file-title-bar", children: [_jsxs("div", { className: "flex h-8 items-center justify-between", children: [_jsxs("div", { className: "flex flex-grow items-center", children: [_jsx(MobileItemsListButton, {}), _jsx("div", { className: "title flex-grow overflow-auto", children: _jsx("input", { className: "input text-lg", id: ElementIds.FileTitleEditor, onChange: onTitleChange, onFocus: (event) => {
                                                        event.target.select();
                                                    }, spellCheck: false, defaultValue: file.name, autoComplete: "off", disabled: isReadonly }) })] }), _jsxs("div", { className: "flex items-center gap-3", children: [!isReadonly && _jsx(LinkedItemsButton, { linkingController: application.linkingController }), _jsx(RoundIconButton, { label: "File information panel", onClick: toggleFileInfoPanel, ref: fileInfoButtonRef, icon: "info" }), _jsx(Popover, { title: "Details", open: isFileInfoPanelOpen, togglePopover: toggleFileInfoPanel, anchorElement: fileInfoButtonRef, side: "bottom", align: "center", children: _jsx(FilePreviewInfoPanel, { file: file }) }), _jsx(FileOptionsPanel, { itemListController: application.itemListController })] })] }), _jsx("div", { className: "hidden md:flex", children: _jsx(LinkedItemBubblesContainer, { item: file, linkingController: application.linkingController, readonly: isReadonly }) })] })] }), _jsx("div", { className: "flex min-h-0 flex-grow flex-col", children: _jsx(FilePreview, { file: file, application: application }, file.uuid) })] }));
};
export default observer(FileViewWithoutProtection);
