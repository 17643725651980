"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SodiumConstant = void 0;
exports.SodiumConstant = {
    CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_STATEBYTES: 52,
    CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_ABYTES: 17,
    CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_HEADERBYTES: 24,
    CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_KEYBYTES: 32,
    CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_MESSAGEBYTES_MAX: 0x3fffffff80,
    crypto_box_SEEDBYTES: 32,
    crypto_sign_SEEDBYTES: 32,
    crypto_generichash_KEYBYTES: 32,
};
