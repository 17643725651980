/* istanbul ignore file */
export var StoragePersistencePolicies;
(function (StoragePersistencePolicies) {
    StoragePersistencePolicies[StoragePersistencePolicies["Default"] = 1] = "Default";
    StoragePersistencePolicies[StoragePersistencePolicies["Ephemeral"] = 2] = "Ephemeral";
})(StoragePersistencePolicies || (StoragePersistencePolicies = {}));
export var StorageValueModes;
(function (StorageValueModes) {
    /** Stored inside wrapped encrypted storage object */
    StorageValueModes[StorageValueModes["Default"] = 1] = "Default";
    /** Stored outside storage object, unencrypted */
    StorageValueModes[StorageValueModes["Nonwrapped"] = 2] = "Nonwrapped";
})(StorageValueModes || (StorageValueModes = {}));
export var ValueModesKeys;
(function (ValueModesKeys) {
    /* Is encrypted */
    ValueModesKeys["Wrapped"] = "wrapped";
    /* Is decrypted */
    ValueModesKeys["Unwrapped"] = "unwrapped";
    /* Lives outside of wrapped/unwrapped */
    ValueModesKeys["Nonwrapped"] = "nonwrapped";
})(ValueModesKeys || (ValueModesKeys = {}));
