import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, memo } from 'react';
import { observer } from 'mobx-react-lite';
const ApplicationContext = createContext(undefined);
export const useApplication = () => {
    const value = useContext(ApplicationContext);
    if (!value) {
        throw new Error('Component must be a child of <ApplicationProvider />');
    }
    return value;
};
const MemoizedChildren = memo(({ children }) => _jsx(_Fragment, { children: children }));
const ApplicationProvider = ({ application, children }) => {
    return (_jsx(ApplicationContext.Provider, { value: application, children: _jsx(MemoizedChildren, { children: children }) }));
};
export default observer(ApplicationProvider);
