import { jsx as _jsx } from "react/jsx-runtime";
import Switch from '@/Components/Switch/Switch';
import { observer } from 'mobx-react-lite';
import { is2FADisabled } from '../TwoFactorAuth';
import Spinner from '@/Components/Spinner/Spinner';
const TwoFactorSwitch = ({ auth, canDisable2FA }) => {
    if (!auth.isLoggedIn()) {
        return null;
    }
    if (auth.status === 'fetching') {
        return _jsx(Spinner, { className: "h-4 w-4" });
    }
    const shouldSwitchBeDisabled = auth.status === 'two-factor-enabled' && !canDisable2FA;
    return _jsx(Switch, { checked: !is2FADisabled(auth.status), onChange: auth.toggle2FA, disabled: shouldSwitchBeDisabled });
};
export default observer(TwoFactorSwitch);
