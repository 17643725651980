import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    static getDerivedStateFromError(error) {
        return { error };
    }
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }
    render() {
        if (this.state.error) {
            return (_jsxs("div", { className: "text-danger", children: [_jsx("span", { children: "Something went wrong rendering this component: " }), _jsx("span", { className: "font-bold", children: this.state.error.message })] }));
        }
        return this.props.children;
    }
}
