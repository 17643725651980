import { noteTypeForEditorIdentifier } from '@standardnotes/features';
import { PayloadEmitSource, PrefKey, PayloadVaultOverrides, } from '@standardnotes/models';
import { removeFromArray } from '@standardnotes/utils';
import { ContentType } from '@standardnotes/domain-core';
import { log, LoggingDomain } from '@/Logging';
import { NoteSyncController } from '../../../Controllers/NoteSyncController';
export class NoteViewController {
    constructor(item, items, mutator, sync, sessions, preferences, components, alerts, _isNativeMobileWeb, templateNoteOptions) {
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.sessions = sessions;
        this.preferences = preferences;
        this.components = components;
        this.alerts = alerts;
        this._isNativeMobileWeb = _isNativeMobileWeb;
        this.templateNoteOptions = templateNoteOptions;
        this.dealloced = false;
        this.isTemplateNote = false;
        this.runtimeId = `${Math.random()}`;
        this.needsInit = true;
        this.innerValueChangeObservers = [];
        this.disposers = [];
        if (item) {
            this.item = item;
        }
        if (templateNoteOptions) {
            this.defaultTagUuid = templateNoteOptions.tag;
        }
        if (this.defaultTagUuid) {
            this.defaultTag = this.items.findItem(this.defaultTagUuid);
        }
        this.syncController = new NoteSyncController(this.item, this.items, this.mutator, this.sessions, this.sync, this.alerts, this._isNativeMobileWeb);
    }
    deinit() {
        if (!this.syncController.savingLocallyPromise) {
            this.performDeinitSafely();
            return;
        }
        void this.syncController.savingLocallyPromise.promise.then(() => {
            this.performDeinitSafely();
        });
    }
    performDeinitSafely() {
        this.dealloced = true;
        for (const disposer of this.disposers) {
            disposer();
        }
        this.disposers.length = 0;
        this.innerValueChangeObservers.length = 0;
    }
    async initialize() {
        var _a, _b, _c;
        if (!this.needsInit) {
            throw Error('NoteViewController already initialized');
        }
        log(LoggingDomain.NoteView, 'Initializing NoteViewController');
        this.needsInit = false;
        const addTagHierarchy = this.preferences.getValue(PrefKey.NoteAddToParentFolders, true);
        if (!this.item) {
            log(LoggingDomain.NoteView, 'Initializing as template note');
            const editorIdentifier = this.components.getDefaultEditorIdentifier(this.defaultTag);
            const noteType = noteTypeForEditorIdentifier(editorIdentifier);
            const note = this.items.createTemplateItem(ContentType.TYPES.Note, {
                text: '',
                title: ((_a = this.templateNoteOptions) === null || _a === void 0 ? void 0 : _a.title) || '',
                noteType: noteType,
                editorIdentifier: editorIdentifier,
                references: [],
            }, {
                created_at: ((_b = this.templateNoteOptions) === null || _b === void 0 ? void 0 : _b.createdAt) || new Date(),
                ...PayloadVaultOverrides((_c = this.templateNoteOptions) === null || _c === void 0 ? void 0 : _c.vault),
            });
            this.isTemplateNote = true;
            this.item = note;
            this.syncController.setItem(this.item);
            if (this.defaultTagUuid) {
                const tag = this.items.findItem(this.defaultTagUuid);
                await this.mutator.addTagToNote(note, tag, addTagHierarchy);
            }
            this.notifyObservers(this.item, PayloadEmitSource.InitialObserverRegistrationPush);
        }
        this.streamItems();
    }
    notifyObservers(note, source) {
        for (const observer of this.innerValueChangeObservers) {
            observer(note, source);
        }
    }
    streamItems() {
        if (this.dealloced) {
            return;
        }
        this.disposers.push(this.items.streamItems(ContentType.TYPES.Note, ({ changed, inserted, source }) => {
            if (this.dealloced) {
                return;
            }
            const notes = changed.concat(inserted);
            const matchingNote = notes.find((item) => {
                return item.uuid === this.item.uuid;
            });
            if (matchingNote) {
                this.isTemplateNote = false;
                this.item = matchingNote;
                this.notifyObservers(matchingNote, source);
            }
        }));
    }
    insertTemplatedNote() {
        log(LoggingDomain.NoteView, 'Inserting template note');
        this.isTemplateNote = false;
        return this.mutator.insertItem(this.item);
    }
    /**
     * Register to be notified when the controller's note's inner values change
     * (and thus a new object reference is created)
     */
    addNoteInnerValueChangeObserver(callback) {
        this.innerValueChangeObservers.push(callback);
        if (this.item) {
            callback(this.item, PayloadEmitSource.InitialObserverRegistrationPush);
        }
        const thislessChangeObservers = this.innerValueChangeObservers;
        return () => {
            removeFromArray(thislessChangeObservers, callback);
        };
    }
    async saveAndAwaitLocalPropagation(params) {
        if (this.needsInit) {
            throw Error('NoteViewController not initialized');
        }
        log(LoggingDomain.NoteView, 'Saving note', params);
        const isTemplate = this.isTemplateNote;
        if (isTemplate) {
            await this.insertTemplatedNote();
        }
        await this.syncController.saveAndAwaitLocalPropagation(params);
    }
    get syncStatus() {
        return this.syncController.status;
    }
    showSavingStatus() {
        this.syncController.showSavingStatus();
    }
    showAllChangesSavedStatus() {
        this.syncController.showAllChangesSavedStatus();
    }
    showErrorSyncStatus(error) {
        this.syncController.showErrorStatus(error);
    }
    syncNow() {
        this.sync.sync().catch(console.error);
    }
    syncOnlyIfLargeNote() {
        this.syncController.syncOnlyIfLargeNote();
    }
}
