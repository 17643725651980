"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UuidGenerator = void 0;
/**
 * An abstract class with no instance methods. Used globally to generate uuids by any
 * consumer. Application must call SetGenerator before use.
 */
class UuidGenerator {
    /**
     * @param {function} syncImpl - A syncronous function that returns a UUID.
     */
    static SetGenerator(syncImpl) {
        this.syncUuidFunc = syncImpl;
    }
    /**
     * Generates a UUID string asyncronously.
     */
    static GenerateUuid() {
        return this.syncUuidFunc();
    }
}
exports.UuidGenerator = UuidGenerator;
