import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useAndroidBackHandler } from '@/NativeMobileWeb/useAndroidBackHandler';
import { useEffect, useMemo, createContext, useCallback, useContext, memo } from 'react';
import { AppPaneId } from './AppPaneMetadata';
import { observer } from 'mobx-react-lite';
import { PaneLayout } from '@/Controllers/PaneController/PaneLayout';
import { useStateRef } from '@/Hooks/useStateRef';
const ResponsivePaneContext = createContext(undefined);
export const useResponsiveAppPane = () => {
    const value = useContext(ResponsivePaneContext);
    if (!value) {
        throw new Error('Component must be a child of <ResponsivePaneProvider />');
    }
    return value;
};
const MemoizedChildren = memo(({ children }) => _jsx(_Fragment, { children: children }));
const ResponsivePaneProvider = ({ paneController, children }) => {
    const currentSelectedPane = paneController.currentPane;
    const currentSelectedPaneRef = useStateRef(currentSelectedPane);
    const toggleAppPane = useCallback((paneId) => {
        paneController.presentPane(paneId);
    }, [paneController]);
    const addAndroidBackHandler = useAndroidBackHandler();
    useEffect(() => {
        const removeListener = addAndroidBackHandler(() => {
            if (currentSelectedPaneRef.current === AppPaneId.Editor ||
                currentSelectedPaneRef.current === AppPaneId.Navigation) {
                paneController.setPaneLayout(PaneLayout.ItemSelection);
                return true;
            }
            else {
                return false;
            }
        });
        return () => {
            if (removeListener) {
                removeListener();
            }
        };
    }, [addAndroidBackHandler, currentSelectedPaneRef, paneController]);
    const contextValue = useMemo(() => ({
        selectedPane: currentSelectedPane,
        toggleAppPane,
        presentPane: paneController.presentPane,
        isListPaneCollapsed: paneController.isListPaneCollapsed,
        isNavigationPaneCollapsed: paneController.isNavigationPaneCollapsed,
        toggleListPane: paneController.toggleListPane,
        toggleNavigationPane: paneController.toggleNavigationPane,
        panes: paneController.panes,
        popToPane: paneController.popToPane,
        dismissLastPane: paneController.dismissLastPane,
        replacePanes: paneController.replacePanes,
        removePane: paneController.removePane,
        insertPaneAtIndex: paneController.insertPaneAtIndex,
        setPaneLayout: paneController.setPaneLayout,
        focusModeEnabled: paneController.focusModeEnabled,
    }), [
        currentSelectedPane,
        toggleAppPane,
        paneController.panes,
        paneController.isListPaneCollapsed,
        paneController.isNavigationPaneCollapsed,
        paneController.toggleListPane,
        paneController.toggleNavigationPane,
        paneController.presentPane,
        paneController.popToPane,
        paneController.dismissLastPane,
        paneController.replacePanes,
        paneController.removePane,
        paneController.insertPaneAtIndex,
        paneController.setPaneLayout,
        paneController.focusModeEnabled,
    ]);
    return (_jsx(ResponsivePaneContext.Provider, { value: contextValue, children: _jsx(MemoizedChildren, { children: children }) }));
};
export default observer(ResponsivePaneProvider);
