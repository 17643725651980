// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSimplenoteEntry = (entry) => entry.id && entry.content != undefined && entry.creationDate && entry.lastModified;
const splitAtFirst = (str, delim) => {
    const indexOfDelimiter = str.indexOf(delim);
    const hasDelimiter = indexOfDelimiter > -1;
    if (!hasDelimiter) {
        return [];
    }
    const before = str.slice(0, indexOfDelimiter);
    const after = str.slice(indexOfDelimiter + delim.length);
    return [before, after];
};
export class SimplenoteConverter {
    constructor() {
        this.convert = async (file, { insertNote: createNote, readFileAsText }) => {
            const content = await readFileAsText(file);
            const notes = await this.parse(content, createNote);
            if (!notes) {
                throw new Error('Could not parse notes');
            }
            return {
                successful: notes,
                errored: [],
            };
        };
    }
    getImportType() {
        return 'simplenote';
    }
    getSupportedFileTypes() {
        return ['application/json'];
    }
    isContentValid(content) {
        try {
            const json = JSON.parse(content);
            return ((json.activeNotes && json.activeNotes.every(isSimplenoteEntry)) ||
                (json.trashedNotes && json.trashedNotes.every(isSimplenoteEntry)));
        }
        catch (error) {
            console.error(error);
        }
        return false;
    }
    createNoteFromItem(item, trashed, createNote) {
        var _a, _b;
        const createdAtDate = new Date(item.creationDate);
        const updatedAtDate = new Date(item.lastModified);
        const splitContent = splitAtFirst(item.content, '\r\n');
        const title = (_a = splitContent[0]) !== null && _a !== void 0 ? _a : createdAtDate.toLocaleString();
        const text = (_b = splitContent[1]) !== null && _b !== void 0 ? _b : item.content;
        return createNote({
            createdAt: createdAtDate,
            updatedAt: updatedAtDate,
            title,
            text,
            trashed,
            useSuperIfPossible: true,
        });
    }
    async parse(data, createNote) {
        try {
            const parsed = JSON.parse(data);
            const activeNotes = await Promise.all(parsed.activeNotes.reverse().map((item) => this.createNoteFromItem(item, false, createNote)));
            const trashedNotes = await Promise.all(parsed.trashedNotes.reverse().map((item) => this.createNoteFromItem(item, true, createNote)));
            return [...activeNotes, ...trashedNotes];
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
}
