"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addYearsToDate = exports.addMonthsToDate = exports.addDaysToDate = exports.addHoursToDate = void 0;
function addHoursToDate(date, hours) {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
}
exports.addHoursToDate = addHoursToDate;
/** Negative numbers are supported as well */
function addDaysToDate(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
exports.addDaysToDate = addDaysToDate;
function addMonthsToDate(date, months) {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}
exports.addMonthsToDate = addMonthsToDate;
function addYearsToDate(date, years) {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
}
exports.addYearsToDate = addYearsToDate;
