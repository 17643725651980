import { FindNativeTheme, GetNativeThemes } from '@standardnotes/features';
import { UIFeature } from '@standardnotes/models';
export class GetAllThemesUseCase {
    constructor(items) {
        this.items = items;
    }
    execute(options) {
        const nativeThemes = GetNativeThemes().filter((feature) => (options.excludeLayerable ? !feature.layerable : true));
        const allThirdPartyThemes = this.items
            .getDisplayableComponents()
            .filter((component) => component.isTheme() && FindNativeTheme(component.identifier) === undefined);
        const filteredThirdPartyThemes = allThirdPartyThemes.filter((theme) => {
            return options.excludeLayerable ? !theme.layerableTheme : true;
        });
        return {
            thirdParty: filteredThirdPartyThemes.map((theme) => new UIFeature(theme)),
            native: nativeThemes.map((theme) => new UIFeature(theme)),
        };
    }
}
