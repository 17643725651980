"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCorruptTransferPayload = exports.isDeletedTransferPayload = exports.isErrorDecryptingTransferPayload = exports.isEncryptedTransferPayload = exports.isDecryptedTransferPayload = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const utils_1 = require("@standardnotes/utils");
function isDecryptedTransferPayload(payload) {
    return (0, utils_1.isObject)(payload.content);
}
exports.isDecryptedTransferPayload = isDecryptedTransferPayload;
function isEncryptedTransferPayload(payload) {
    return 'content' in payload && (0, utils_1.isString)(payload.content);
}
exports.isEncryptedTransferPayload = isEncryptedTransferPayload;
function isErrorDecryptingTransferPayload(payload) {
    return isEncryptedTransferPayload(payload) && payload.errorDecrypting === true;
}
exports.isErrorDecryptingTransferPayload = isErrorDecryptingTransferPayload;
function isDeletedTransferPayload(payload) {
    return 'deleted' in payload && payload.deleted === true;
}
exports.isDeletedTransferPayload = isDeletedTransferPayload;
function isCorruptTransferPayload(payload) {
    const invalidDeletedState = payload.deleted === true && payload.content != undefined;
    const contenTypeOrError = domain_core_1.ContentType.create(payload.content_type);
    return payload.uuid == undefined || invalidDeletedState || contenTypeOrError.isFailed();
}
exports.isCorruptTransferPayload = isCorruptTransferPayload;
