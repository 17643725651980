import { jsx as _jsx } from "react/jsx-runtime";
import { ContentType } from '@standardnotes/snjs';
import React from 'react';
import FileListItem from './FileListItem';
import NoteListItem from './NoteListItem';
import { doListItemPropsMeritRerender } from './Types/AbstractListItemProps';
const ContentListItem = (props) => {
    switch (props.item.content_type) {
        case ContentType.TYPES.Note:
            return _jsx(NoteListItem, { ...props, item: props.item });
        case ContentType.TYPES.File: {
            return _jsx(FileListItem, { ...props, item: props.item });
        }
        default:
            return null;
    }
};
export default React.memo(ContentListItem, (a, b) => !doListItemPropsMeritRerender(a, b));
