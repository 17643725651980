"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptedItem = void 0;
const GenericItem_1 = require("./GenericItem");
class EncryptedItem extends GenericItem_1.GenericItem {
    constructor(payload) {
        super(payload);
    }
    get version() {
        return this.payload.version;
    }
    payloadRepresentation(override) {
        return this.payload.copy(override);
    }
    get errorDecrypting() {
        return this.payload.errorDecrypting;
    }
    get waitingForKey() {
        return this.payload.waitingForKey;
    }
    get content() {
        return this.payload.content;
    }
    get auth_hash() {
        return this.payload.auth_hash;
    }
}
exports.EncryptedItem = EncryptedItem;
