"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateHistoryEntryForPayload = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const HistoryEntry_1 = require("./HistoryEntry");
const NoteHistoryEntry_1 = require("./NoteHistoryEntry");
function CreateHistoryEntryForPayload(payload, previousEntry) {
    const type = payload.content_type;
    const historyItemClass = historyClassForContentType(type);
    const entry = new historyItemClass(payload, previousEntry);
    return entry;
}
exports.CreateHistoryEntryForPayload = CreateHistoryEntryForPayload;
function historyClassForContentType(contentType) {
    switch (contentType) {
        case domain_core_1.ContentType.TYPES.Note:
            return NoteHistoryEntry_1.NoteHistoryEntry;
        default:
            return HistoryEntry_1.HistoryEntry;
    }
}
