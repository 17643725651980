import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import Button from '@/Components/Button/Button';
const NoAccountWarningContent = ({ accountMenuController, noAccountWarningController }) => {
    const showAccountMenu = useCallback((event) => {
        event.stopPropagation();
        accountMenuController.setShow(true);
    }, [accountMenuController]);
    const hideWarning = useCallback(() => {
        noAccountWarningController.hide();
    }, [noAccountWarningController]);
    return (_jsxs("div", { className: "mt-4 grid grid-cols-1 rounded-md border border-border p-4", children: [_jsx("h1", { className: "sk-h3 m-0 text-base font-semibold lg:text-sm", children: "Data not backed up" }), _jsx("p", { className: "col-start-1 col-end-3 m-0 mt-1 text-base lg:text-sm", children: "Sign in or register to sync your notes to your other devices with end-to-end encryption." }), _jsx(Button, { primary: true, small: true, className: "col-start-1 col-end-3 mt-3 justify-self-start uppercase", onClick: showAccountMenu, children: "Open Account menu" }), _jsx("button", { onClick: hideWarning, title: "Ignore warning", "aria-label": "Ignore warning", style: { height: '20px' }, className: "col-start-2 row-start-1 m-0 cursor-pointer rounded-md border-0 bg-transparent p-0 text-neutral hover:text-info", children: _jsx(Icon, { type: "close", className: "block" }) })] }));
};
export default observer(NoAccountWarningContent);
