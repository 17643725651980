"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildSmartViews = void 0;
const DecryptedPayload_1 = require("./../../Abstract/Payload/Implementations/DecryptedPayload");
const SmartView_1 = require("./SmartView");
const SystemViewId_1 = require("./SystemViewId");
const ItemContent_1 = require("../../Abstract/Content/ItemContent");
const Predicate_1 = require("../../Runtime/Predicate/Predicate");
const CompoundPredicate_1 = require("../../Runtime/Predicate/CompoundPredicate");
const Payload_1 = require("../../Abstract/Payload");
const domain_core_1 = require("@standardnotes/domain-core");
function BuildSmartViews(options) {
    const notes = new SmartView_1.SmartView(new DecryptedPayload_1.DecryptedPayload(Object.assign(Object.assign({ uuid: SystemViewId_1.SystemViewId.AllNotes, content_type: domain_core_1.ContentType.TYPES.SmartView }, (0, Payload_1.PayloadTimestampDefaults)()), { content: (0, ItemContent_1.FillItemContent)({
            title: 'Notes',
            predicate: allNotesPredicate(options).toJson(),
        }) })));
    const files = new SmartView_1.SmartView(new DecryptedPayload_1.DecryptedPayload(Object.assign(Object.assign({ uuid: SystemViewId_1.SystemViewId.Files, content_type: domain_core_1.ContentType.TYPES.SmartView }, (0, Payload_1.PayloadTimestampDefaults)()), { content: (0, ItemContent_1.FillItemContent)({
            title: 'Files',
            predicate: filesPredicate(options).toJson(),
        }) })));
    const archived = new SmartView_1.SmartView(new DecryptedPayload_1.DecryptedPayload(Object.assign(Object.assign({ uuid: SystemViewId_1.SystemViewId.ArchivedNotes, content_type: domain_core_1.ContentType.TYPES.SmartView }, (0, Payload_1.PayloadTimestampDefaults)()), { content: (0, ItemContent_1.FillItemContent)({
            title: 'Archived',
            predicate: archivedNotesPredicate(options).toJson(),
        }) })));
    const trash = new SmartView_1.SmartView(new DecryptedPayload_1.DecryptedPayload(Object.assign(Object.assign({ uuid: SystemViewId_1.SystemViewId.TrashedNotes, content_type: domain_core_1.ContentType.TYPES.SmartView }, (0, Payload_1.PayloadTimestampDefaults)()), { content: (0, ItemContent_1.FillItemContent)({
            title: 'Trash',
            predicate: trashedNotesPredicate(options).toJson(),
        }) })));
    const untagged = new SmartView_1.SmartView(new DecryptedPayload_1.DecryptedPayload(Object.assign(Object.assign({ uuid: SystemViewId_1.SystemViewId.UntaggedNotes, content_type: domain_core_1.ContentType.TYPES.SmartView }, (0, Payload_1.PayloadTimestampDefaults)()), { content: (0, ItemContent_1.FillItemContent)({
            title: 'Untagged',
            predicate: untaggedNotesPredicate(options).toJson(),
        }) })));
    const starred = new SmartView_1.SmartView(new DecryptedPayload_1.DecryptedPayload(Object.assign(Object.assign({ uuid: SystemViewId_1.SystemViewId.StarredNotes, content_type: domain_core_1.ContentType.TYPES.SmartView }, (0, Payload_1.PayloadTimestampDefaults)()), { content: (0, ItemContent_1.FillItemContent)({
            title: 'Starred',
            predicate: starredNotesPredicate(options).toJson(),
        }) })));
    const conflicts = new SmartView_1.SmartView(new DecryptedPayload_1.DecryptedPayload(Object.assign(Object.assign({ uuid: SystemViewId_1.SystemViewId.Conflicts, content_type: domain_core_1.ContentType.TYPES.SmartView }, (0, Payload_1.PayloadTimestampDefaults)()), { content: (0, ItemContent_1.FillItemContent)({
            title: 'Conflicts',
            predicate: conflictsPredicate(options).toJson(),
        }) })));
    return [notes, files, starred, archived, trash, untagged, conflicts];
}
exports.BuildSmartViews = BuildSmartViews;
function allNotesPredicate(options) {
    const subPredicates = [new Predicate_1.Predicate('content_type', '=', domain_core_1.ContentType.TYPES.Note)];
    if (options.includeTrashed === false) {
        subPredicates.push(new Predicate_1.Predicate('trashed', '=', false));
    }
    if (options.includeArchived === false) {
        subPredicates.push(new Predicate_1.Predicate('archived', '=', false));
    }
    if (options.includeProtected === false) {
        subPredicates.push(new Predicate_1.Predicate('protected', '=', false));
    }
    if (options.includePinned === false) {
        subPredicates.push(new Predicate_1.Predicate('pinned', '=', false));
    }
    const predicate = new CompoundPredicate_1.CompoundPredicate('and', subPredicates);
    return predicate;
}
function filesPredicate(options) {
    const subPredicates = [new Predicate_1.Predicate('content_type', '=', domain_core_1.ContentType.TYPES.File)];
    if (options.includeTrashed === false) {
        subPredicates.push(new Predicate_1.Predicate('trashed', '=', false));
    }
    if (options.includeArchived === false) {
        subPredicates.push(new Predicate_1.Predicate('archived', '=', false));
    }
    if (options.includeProtected === false) {
        subPredicates.push(new Predicate_1.Predicate('protected', '=', false));
    }
    if (options.includePinned === false) {
        subPredicates.push(new Predicate_1.Predicate('pinned', '=', false));
    }
    const predicate = new CompoundPredicate_1.CompoundPredicate('and', subPredicates);
    return predicate;
}
function archivedNotesPredicate(options) {
    const subPredicates = [
        new Predicate_1.Predicate('archived', '=', true),
        new Predicate_1.Predicate('content_type', '=', domain_core_1.ContentType.TYPES.Note),
    ];
    if (options.includeTrashed === false) {
        subPredicates.push(new Predicate_1.Predicate('trashed', '=', false));
    }
    if (options.includeProtected === false) {
        subPredicates.push(new Predicate_1.Predicate('protected', '=', false));
    }
    if (options.includePinned === false) {
        subPredicates.push(new Predicate_1.Predicate('pinned', '=', false));
    }
    const predicate = new CompoundPredicate_1.CompoundPredicate('and', subPredicates);
    return predicate;
}
function trashedNotesPredicate(options) {
    const subPredicates = [
        new Predicate_1.Predicate('trashed', '=', true),
        new Predicate_1.Predicate('content_type', '=', domain_core_1.ContentType.TYPES.Note),
    ];
    if (options.includeArchived === false) {
        subPredicates.push(new Predicate_1.Predicate('archived', '=', false));
    }
    if (options.includeProtected === false) {
        subPredicates.push(new Predicate_1.Predicate('protected', '=', false));
    }
    if (options.includePinned === false) {
        subPredicates.push(new Predicate_1.Predicate('pinned', '=', false));
    }
    const predicate = new CompoundPredicate_1.CompoundPredicate('and', subPredicates);
    return predicate;
}
function untaggedNotesPredicate(options) {
    const subPredicates = [
        new Predicate_1.Predicate('content_type', '=', domain_core_1.ContentType.TYPES.Note),
        new Predicate_1.Predicate('tagsCount', '=', 0),
    ];
    if (options.includeArchived === false) {
        subPredicates.push(new Predicate_1.Predicate('archived', '=', false));
    }
    if (options.includeProtected === false) {
        subPredicates.push(new Predicate_1.Predicate('protected', '=', false));
    }
    if (options.includePinned === false) {
        subPredicates.push(new Predicate_1.Predicate('pinned', '=', false));
    }
    const predicate = new CompoundPredicate_1.CompoundPredicate('and', subPredicates);
    return predicate;
}
function starredNotesPredicate(options) {
    const subPredicates = [
        new Predicate_1.Predicate('starred', '=', true),
        new Predicate_1.Predicate('content_type', '=', domain_core_1.ContentType.TYPES.Note),
    ];
    if (options.includeTrashed === false) {
        subPredicates.push(new Predicate_1.Predicate('trashed', '=', false));
    }
    if (options.includeProtected === false) {
        subPredicates.push(new Predicate_1.Predicate('protected', '=', false));
    }
    if (options.includePinned === false) {
        subPredicates.push(new Predicate_1.Predicate('pinned', '=', false));
    }
    const predicate = new CompoundPredicate_1.CompoundPredicate('and', subPredicates);
    return predicate;
}
function conflictsPredicate(options) {
    const subPredicates = [new Predicate_1.Predicate('content_type', '=', domain_core_1.ContentType.TYPES.Note)];
    if (options.includeTrashed === false) {
        subPredicates.push(new Predicate_1.Predicate('trashed', '=', false));
    }
    if (options.includeArchived === false) {
        subPredicates.push(new Predicate_1.Predicate('archived', '=', false));
    }
    if (options.includeProtected === false) {
        subPredicates.push(new Predicate_1.Predicate('protected', '=', false));
    }
    if (options.includePinned === false) {
        subPredicates.push(new Predicate_1.Predicate('pinned', '=', false));
    }
    const predicate = new CompoundPredicate_1.CompoundPredicate('and', subPredicates);
    return predicate;
}
