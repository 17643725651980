import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import { Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import Button from '@/Components/Button/Button';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
const DeleteAccount = ({ application }) => {
    if (!application.hasAccount()) {
        return null;
    }
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Delete account" }), _jsx(Text, { children: "This action is irreversible. After deletion completes, you will be signed out on all devices." }), _jsx("div", { className: "mt-3 flex flex-row flex-wrap gap-3", children: _jsx(Button, { colorStyle: "danger", label: "Delete my account", onClick: () => {
                            application.accountMenuController.setDeletingAccount(true);
                        } }) })] }) }));
};
export default observer(DeleteAccount);
