import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NoPreviewIllustration } from '@standardnotes/icons';
import { FileItemActionType } from '../AttachedFilesPopover/PopoverFileItemAction';
import Button from '../Button/Button';
const FilePreviewError = ({ file, filesController, isFilePreviewable, tryAgainCallback }) => {
    return (_jsxs("div", { className: "flex flex-grow flex-col items-center justify-center", children: [_jsx(NoPreviewIllustration, { className: "mb-4 h-30 w-30" }), _jsx("div", { className: "mb-2 text-base font-bold", children: "This file can't be previewed." }), isFilePreviewable ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-4 max-w-[35ch] text-center text-sm text-passive-0", children: "There was an error loading the file. Try again, or download the file and open it using another application." }), _jsxs("div", { className: "flex items-center", children: [_jsx(Button, { primary: true, className: "mr-3", onClick: () => {
                                    tryAgainCallback();
                                }, children: "Try again" }), _jsx(Button, { onClick: () => {
                                    filesController
                                        .handleFileAction({
                                        type: FileItemActionType.DownloadFile,
                                        payload: {
                                            file,
                                        },
                                    })
                                        .catch(console.error);
                                }, children: "Download" })] })] })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-4 max-w-[35ch] text-center text-sm text-passive-0", children: "To view this file, download it and open it using another application." }), _jsx(Button, { primary: true, onClick: () => {
                            filesController
                                .handleFileAction({
                                type: FileItemActionType.DownloadFile,
                                payload: { file },
                            })
                                .catch(console.error);
                        }, children: "Download" })] }))] }));
};
export default FilePreviewError;
