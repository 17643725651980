"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionsExtensionMutator = void 0;
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
class ActionsExtensionMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    set description(description) {
        this.mutableContent.description = description;
    }
    set supported_types(supported_types) {
        this.mutableContent.supported_types = supported_types;
    }
    set actions(actions) {
        this.mutableContent.actions = actions;
    }
    set deprecation(deprecation) {
        this.mutableContent.deprecation = deprecation;
    }
}
exports.ActionsExtensionMutator = ActionsExtensionMutator;
