"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteItemMutator = void 0;
const DeletedPayload_1 = require("./../../Payload/Implementations/DeletedPayload");
const ItemMutator_1 = require("./ItemMutator");
const MutationType_1 = require("../Types/MutationType");
const DirtyCounter_1 = require("../../../Runtime/DirtyCounter/DirtyCounter");
class DeleteItemMutator extends ItemMutator_1.ItemMutator {
    getDeletedResult() {
        const dirtying = this.type !== MutationType_1.MutationType.NonDirtying;
        const result = new DeletedPayload_1.DeletedPayload(Object.assign(Object.assign({}, this.immutablePayload.ejected()), { deleted: true, content: undefined, dirty: dirtying ? true : this.immutablePayload.dirty, dirtyIndex: dirtying ? (0, DirtyCounter_1.getIncrementedDirtyIndex)() : this.immutablePayload.dirtyIndex }), this.immutablePayload.source);
        return result;
    }
    getResult() {
        throw Error('Must use getDeletedResult');
    }
}
exports.DeleteItemMutator = DeleteItemMutator;
