import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import VaultNameBadge from '../Vaults/VaultNameBadge';
import LastEditedByBadge from '../Vaults/LastEditedByBadge';
import { useItemVaultInfo } from '@/Hooks/useItemVaultInfo';
const CollaborationInfoHUD = ({ item }) => {
    const { vault, lastEditedByContact } = useItemVaultInfo(item);
    if (!vault) {
        return null;
    }
    return (_jsxs("div", { className: "flex flex-wrap items-start gap-2", children: [_jsx(VaultNameBadge, { vault: vault }), lastEditedByContact && _jsx(LastEditedByBadge, { contact: lastEditedByContact })] }));
};
export default CollaborationInfoHUD;
