import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { splitQueryInString } from '@/Utils';
import { classNames } from '@standardnotes/utils';
import { getTitleForLinkedTag } from '@/Utils/Items/Display/getTitleForLinkedTag';
import { getIconForItem } from '@/Utils/Items/Icons/getIconForItem';
import { observer } from 'mobx-react-lite';
import { useApplication } from '../ApplicationProvider';
import Icon from '../Icon/Icon';
import { getItemTitleInContextOfLinkBubble } from '@/Utils/Items/Search/doesItemMatchSearchQuery';
const LinkedItemMeta = ({ item, searchQuery }) => {
    const application = useApplication();
    const [icon, className] = getIconForItem(item, application);
    const tagTitle = getTitleForLinkedTag(item, application);
    const title = getItemTitleInContextOfLinkBubble(item);
    return (_jsxs(_Fragment, { children: [_jsx(Icon, { type: icon, className: classNames('flex-shrink-0', className) }), _jsxs("div", { className: "min-w-0 flex-grow break-words text-left text-base lg:text-sm", children: [tagTitle && _jsx("span", { className: "text-passive-1", children: tagTitle.titlePrefix }), searchQuery
                        ? splitQueryInString(title, searchQuery).map((substring, index) => (_jsx("span", { className: `${substring.toLowerCase() === searchQuery.toLowerCase()
                                ? 'whitespace-pre-wrap font-bold'
                                : 'whitespace-pre-wrap '}`, children: substring }, index)))
                        : title] })] }));
};
export default observer(LinkedItemMeta);
