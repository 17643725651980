import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import HistoryListContainer from './HistoryListContainer';
import HistoryModalFooter from './HistoryModalFooter';
import HistoryModalContentPane from './HistoryModalContentPane';
import { NoteHistoryController } from '@/Controllers/NoteHistory/NoteHistoryController';
import Icon from '../Icon/Icon';
import { classNames } from '@standardnotes/utils';
import MobileModalAction from '../Modal/MobileModalAction';
import Popover from '../Popover/Popover';
import MobileModalHeader from '../Modal/MobileModalHeader';
import { useApplication } from '../ApplicationProvider';
import { useItemVaultInfo } from '@/Hooks/useItemVaultInfo';
const HistoryModalDialogContent = ({ dismissModal, note }) => {
    const application = useApplication();
    const [noteHistoryController] = useState(() => new NoteHistoryController(note, application.itemListController, application.features, application.items, application.mutator, application.sync, application.actions, application.history, application.alerts, application.getRevision, application.listRevisions, application.deleteRevision, application.changeAndSaveItem));
    const [selectedMobileTab, setSelectedMobileTab] = useState('List');
    const tabOptionRef = useRef(null);
    const [showTabMenu, setShowTabMenu] = useState(false);
    const toggleTabMenu = () => setShowTabMenu((show) => !show);
    const { vault } = useItemVaultInfo(note);
    const isReadonly = vault ? application.vaultUsers.isCurrentUserReadonlyVaultMember(vault) : false;
    return (_jsxs(_Fragment, { children: [_jsxs(MobileModalHeader, { className: "items-center border-b border-border px-2 py-1 md:hidden", children: [_jsx(MobileModalAction, { type: "secondary", action: toggleTabMenu, slot: "left", ref: tabOptionRef, children: _jsx("div", { className: "rounded-full border border-border p-0.5", children: _jsx(Icon, { type: "more" }) }) }), _jsxs(Popover, { title: "Advanced", open: showTabMenu, anchorElement: tabOptionRef, disableMobileFullscreenTakeover: true, togglePopover: toggleTabMenu, align: "start", portal: false, className: "w-1/2 !min-w-0 divide-y divide-border border border-border", children: [_jsx("button", { onClick: () => {
                                    setSelectedMobileTab('List');
                                    toggleTabMenu();
                                }, className: "p-1.5 text-base font-semibold hover:bg-contrast focus:bg-info-backdrop focus:shadow-none focus:outline-none", children: "List" }), _jsx("button", { onClick: () => {
                                    setSelectedMobileTab('Content');
                                    toggleTabMenu();
                                }, className: "p-1.5 text-base font-semibold hover:bg-contrast focus:bg-info-backdrop focus:shadow-none focus:outline-none", children: "Content" })] }), _jsx("div", { className: "text-center text-base font-semibold", children: "History" }), _jsx(MobileModalAction, { type: "primary", slot: "right", action: dismissModal, children: "Done" })] }), _jsxs("div", { className: "flex min-h-0 flex-grow", children: [_jsx("div", { className: classNames('w-full md:flex md:w-auto md:min-w-60', selectedMobileTab === 'List' ? 'flex' : 'hidden'), children: _jsx(HistoryListContainer, { features: application.features, noteHistoryController: noteHistoryController, selectMobileModalTab: setSelectedMobileTab }) }), _jsx("div", { className: classNames('relative flex-grow flex-col md:flex', selectedMobileTab === 'Content' ? 'flex' : 'hidden'), children: _jsx(HistoryModalContentPane, { noteHistoryController: noteHistoryController, note: note }) })] }), _jsx(HistoryModalFooter, { dismissModal: dismissModal, noteHistoryController: noteHistoryController, readonly: isReadonly })] }));
};
export default observer(HistoryModalDialogContent);
