import { NativeFeatureIdentifier, NoteType } from '@standardnotes/features';
const AegisEntryTypes = ['hotp', 'totp', 'steam', 'yandex'];
export class AegisToAuthenticatorConverter {
    constructor() {
        this.convert = async (file, { insertNote, readFileAsText }) => {
            const content = await readFileAsText(file);
            const entries = this.parseEntries(content);
            if (!entries) {
                throw new Error('Could not parse entries');
            }
            const createdAt = file.lastModified ? new Date(file.lastModified) : new Date();
            const updatedAt = file.lastModified ? new Date(file.lastModified) : new Date();
            const title = file.name.split('.')[0];
            const text = JSON.stringify(entries);
            const note = await insertNote({
                createdAt,
                updatedAt,
                title,
                text,
                noteType: NoteType.Authentication,
                editorIdentifier: NativeFeatureIdentifier.TYPES.TokenVaultEditor,
                useSuperIfPossible: false,
            });
            const successful = [note];
            return {
                successful,
                errored: [],
            };
        };
    }
    getImportType() {
        return 'aegis';
    }
    getSupportedFileTypes() {
        return ['application/json'];
    }
    isContentValid(content) {
        try {
            const json = JSON.parse(content);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return json.db && json.db.entries && json.db.entries.every((entry) => AegisEntryTypes.includes(entry.type));
        }
        catch (error) {
            console.error(error);
        }
        return false;
    }
    parseEntries(data) {
        try {
            const json = JSON.parse(data);
            const entries = json.db.entries.map((entry) => {
                return {
                    service: entry.issuer,
                    account: entry.name,
                    secret: entry.info.secret,
                    notes: entry.note,
                };
            });
            return entries;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
}
