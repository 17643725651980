import { confirmDialog, CREATE_NEW_TAG_COMMAND, VaultDisplayServiceEvent, } from '@standardnotes/ui-services';
import { STRING_DELETE_TAG } from '@/Constants/Strings';
import { SMART_TAGS_FEATURE_NAME } from '@/Constants/Constants';
import { ContentType, SmartView, SNTag, isSystemView, FindItem, SystemViewId, InternalEventPublishStrategy, isTag, PrefKey, } from '@standardnotes/snjs';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { debounce, destroyAllObjectProperties } from '@/Utils';
import { isValidFutureSiblings, rootTags, tagSiblings } from './Utils';
import { CrossControllerEvent } from '../CrossControllerEvent';
import { AbstractViewController } from '../Abstract/AbstractViewController';
import { PaneLayout } from '../PaneController/PaneLayout';
import { TagsCountsState } from './TagsCountsState';
export class NavigationController extends AbstractViewController {
    constructor(featuresController, vaultDisplayService, keyboardService, paneController, sync, mutator, items, preferences, alerts, _changeAndSaveItem, eventBus) {
        super(eventBus);
        this.featuresController = featuresController;
        this.vaultDisplayService = vaultDisplayService;
        this.keyboardService = keyboardService;
        this.paneController = paneController;
        this.sync = sync;
        this.mutator = mutator;
        this.items = items;
        this.preferences = preferences;
        this.alerts = alerts;
        this._changeAndSaveItem = _changeAndSaveItem;
        this.tags = [];
        this.smartViews = [];
        this.starredTags = [];
        this.allNotesCount_ = 0;
        this.allFilesCount_ = 0;
        this.selectedUuid = undefined;
        this.selected_ = undefined;
        this.selectedLocation = undefined;
        this.previouslySelected_ = undefined;
        this.editing_ = undefined;
        this.addingSubtagTo = undefined;
        this.contextMenuOpen = false;
        this.contextMenuClickLocation = { x: 0, y: 0 };
        this.contextMenuTag = undefined;
        this.contextMenuTagSection = undefined;
        this.searchQuery = '';
        this.findAndSetTag = (uuid) => {
            const tagToSelect = [...this.tags, ...this.smartViews].find((tag) => tag.uuid === uuid);
            if (tagToSelect) {
                void this.setSelectedTag(tagToSelect, isTag(tagToSelect) ? (tagToSelect.starred ? 'favorites' : 'all') : 'views');
            }
        };
        this.selectHydratedTagOrDefault = () => {
            if (this.selectedUuid && !this.selected_) {
                this.findAndSetTag(this.selectedUuid);
            }
            if (!this.selectedUuid) {
                void this.selectHomeNavigationView();
            }
        };
        this.getPersistableValue = () => {
            return {
                selectedTagUuid: this.selectedUuid ? this.selectedUuid : SystemViewId.AllNotes,
            };
        };
        this.hydrateFromPersistedValue = (state) => {
            const uuidsToPreventHydrationOf = [SystemViewId.Files];
            if (!state || uuidsToPreventHydrationOf.includes(state.selectedTagUuid)) {
                void this.selectHomeNavigationView();
                return;
            }
            if (state.selectedTagUuid) {
                this.selectedUuid = state.selectedTagUuid;
                this.selectHydratedTagOrDefault();
            }
        };
        this.setDisplayOptionsAndReloadTags = () => {
            this.items.setTagsAndViewsDisplayOptions({
                searchQuery: {
                    query: this.searchQuery,
                    includeProtectedNoteText: false,
                },
            });
            this.reloadTags();
        };
        this.setSearchQuery = (query) => {
            this.searchQuery = query;
            this.setDisplayOptionsAndReloadTags();
        };
        eventBus.addEventHandler(this, VaultDisplayServiceEvent.VaultDisplayOptionsChanged);
        this.tagsCountsState = new TagsCountsState(items);
        this.smartViews = items.getSmartViews();
        makeObservable(this, {
            tags: observable,
            starredTags: observable,
            smartViews: observable.ref,
            allNotesCount_: observable,
            allFilesCount_: observable,
            allNotesCount: computed,
            allFilesCount: computed,
            setAllNotesCount: action,
            setAllFilesCount: action,
            selected_: observable,
            selectedLocation: observable,
            previouslySelected_: observable.ref,
            previouslySelected: computed,
            editing_: observable.ref,
            selected: computed,
            selectedUuid: observable,
            editingTag: computed,
            addingSubtagTo: observable,
            setAddingSubtagTo: action,
            assignParent: action,
            rootTags: computed,
            tagsCount: computed,
            createNewTemplate: action,
            undoCreateNewTag: action,
            save: action,
            remove: action,
            contextMenuOpen: observable,
            contextMenuClickLocation: observable,
            setContextMenuOpen: action,
            setContextMenuClickLocation: action,
            contextMenuTag: observable,
            setContextMenuTag: action,
            isInFilesView: computed,
            hydrateFromPersistedValue: action,
            searchQuery: observable,
            setSearchQuery: action,
        });
        this.disposers.push(this.items.streamItems([ContentType.TYPES.Tag, ContentType.TYPES.SmartView], ({ changed, removed }) => {
            this.reloadTags();
            if (this.contextMenuTag && FindItem(removed, this.contextMenuTag.uuid)) {
                this.setContextMenuTag(undefined);
            }
            runInAction(() => {
                const currentSelectedTag = this.selected_;
                if (!currentSelectedTag) {
                    return;
                }
                const updatedReference = FindItem(changed, currentSelectedTag.uuid) || FindItem(this.smartViews, currentSelectedTag.uuid);
                if (updatedReference) {
                    this.setSelectedTagInstance(updatedReference);
                }
                if (isSystemView(currentSelectedTag)) {
                    return;
                }
                if (FindItem(removed, currentSelectedTag.uuid)) {
                    this.setSelectedTagInstance(this.smartViews[0]);
                }
            });
        }));
        this.disposers.push(this.items.addNoteCountChangeObserver((tagUuid) => {
            if (!tagUuid) {
                this.setAllNotesCount(this.items.allCountableNotesCount());
                this.setAllFilesCount(this.items.allCountableFilesCount());
            }
            else {
                const tag = this.items.findItem(tagUuid);
                if (tag) {
                    this.tagsCountsState.update([tag]);
                }
            }
        }));
        this.disposers.push(reaction(() => this.selectedUuid, () => {
            eventBus.publish({
                type: CrossControllerEvent.RequestValuePersistence,
                payload: undefined,
            });
        }));
        this.disposers.push(this.keyboardService.addCommandHandler({
            command: CREATE_NEW_TAG_COMMAND,
            category: 'General',
            description: 'Create new tag',
            onKeyDown: () => {
                this.createNewTemplate();
            },
        }));
        this.setDisplayOptionsAndReloadTags = debounce(this.setDisplayOptionsAndReloadTags, 50);
    }
    reloadTags() {
        runInAction(() => {
            this.tags = this.items.getDisplayableTags();
            this.starredTags = this.tags.filter((tag) => tag.starred);
            this.smartViews = this.items.getSmartViews().filter((view) => {
                if (!this.isSearching) {
                    return true;
                }
                return !isSystemView(view);
            });
        });
    }
    async handleEvent(event) {
        if (event.type === VaultDisplayServiceEvent.VaultDisplayOptionsChanged) {
            this.reloadTags();
            if (this.selectedUuid) {
                this.findAndSetTag(this.selectedUuid);
            }
            else {
                this.selectHomeNavigationView().catch(console.error);
            }
        }
    }
    deinit() {
        super.deinit();
        this.featuresController = undefined;
        this.tags = undefined;
        this.smartViews = undefined;
        this.selected_ = undefined;
        this.previouslySelected_ = undefined;
        this.editing_ = undefined;
        this.addingSubtagTo = undefined;
        this.featuresController = undefined;
        destroyAllObjectProperties(this);
    }
    async createSubtagAndAssignParent(parent, title) {
        const hasEmptyTitle = title.length === 0;
        if (hasEmptyTitle) {
            this.setAddingSubtagTo(undefined);
            return;
        }
        const createdTag = await this.mutator.createTagOrSmartView(title, this.vaultDisplayService.exclusivelyShownVault);
        const futureSiblings = this.items.getTagChildren(parent);
        if (!isValidFutureSiblings(this.alerts, futureSiblings, createdTag)) {
            this.setAddingSubtagTo(undefined);
            this.remove(createdTag, false).catch(console.error);
            return;
        }
        this.assignParent(createdTag.uuid, parent.uuid).catch(console.error);
        this.sync.sync().catch(console.error);
        runInAction(() => {
            void this.setSelectedTag(createdTag, 'all');
        });
        this.setAddingSubtagTo(undefined);
    }
    isInSmartView() {
        return this.selected instanceof SmartView;
    }
    isInHomeView() {
        return this.selected instanceof SmartView && this.selected.uuid === SystemViewId.AllNotes;
    }
    get isInFilesView() {
        return this.selectedUuid === SystemViewId.Files;
    }
    isTagFilesView(tag) {
        return tag.uuid === SystemViewId.Files;
    }
    tagUsesTableView(tag) {
        var _a;
        const isSystemView = tag instanceof SmartView && Object.values(SystemViewId).includes(tag.uuid);
        const useTableView = isSystemView
            ? (_a = this.preferences.getValue(PrefKey.SystemViewPreferences)) === null || _a === void 0 ? void 0 : _a[tag.uuid]
            : tag === null || tag === void 0 ? void 0 : tag.preferences;
        return Boolean(useTableView);
    }
    isInAnySystemView() {
        return (this.selected instanceof SmartView && Object.values(SystemViewId).includes(this.selected.uuid));
    }
    isInSystemView(id) {
        return this.selected instanceof SmartView && this.selected.uuid === id;
    }
    get selectedAsTag() {
        if (!this.selected || !isTag(this.selected)) {
            return undefined;
        }
        return this.selected;
    }
    setAddingSubtagTo(tag) {
        this.addingSubtagTo = tag;
    }
    setContextMenuOpen(open) {
        this.contextMenuOpen = open;
    }
    setContextMenuClickLocation(location) {
        this.contextMenuClickLocation = location;
    }
    setContextMenuTag(tag, section = 'all') {
        this.contextMenuTag = tag;
        this.contextMenuTagSection = section;
    }
    get allLocalRootTags() {
        if (this.editing_ instanceof SNTag && this.items.isTemplateItem(this.editing_)) {
            return [this.editing_, ...this.rootTags];
        }
        return this.rootTags;
    }
    getNotesCount(tag) {
        return this.tagsCountsState.counts[tag.uuid] || 0;
    }
    getChildren(tag) {
        if (this.items.isTemplateItem(tag)) {
            return [];
        }
        const children = this.items.getTagChildren(tag);
        const childrenUuids = children.map((childTag) => childTag.uuid);
        const childrenTags = this.isSearching ? children : this.tags.filter((tag) => childrenUuids.includes(tag.uuid));
        return childrenTags;
    }
    isValidTagParent(parent, tag) {
        return this.items.isValidTagParent(parent, tag);
    }
    hasParent(tagUuid) {
        const item = this.items.findItem(tagUuid);
        return !!item && !!item.parentId;
    }
    async assignParent(tagUuid, futureParentUuid) {
        const tag = this.items.findItem(tagUuid);
        const currentParent = this.items.getTagParent(tag);
        const currentParentUuid = currentParent === null || currentParent === void 0 ? void 0 : currentParent.uuid;
        if (currentParentUuid === futureParentUuid) {
            return;
        }
        const futureParent = futureParentUuid && this.items.findItem(futureParentUuid);
        if (!futureParent) {
            const futureSiblings = rootTags(this.items);
            if (!isValidFutureSiblings(this.alerts, futureSiblings, tag)) {
                return;
            }
            await this.mutator.unsetTagParent(tag);
        }
        else {
            const futureSiblings = this.items.getTagChildren(futureParent);
            if (!isValidFutureSiblings(this.alerts, futureSiblings, tag)) {
                return;
            }
            await this.mutator.setTagParent(futureParent, tag);
        }
        await this.sync.sync();
    }
    get rootTags() {
        return this.tags.filter((tag) => !this.items.getDisplayableTagParent(tag));
    }
    get tagsCount() {
        return this.tags.length;
    }
    setAllNotesCount(allNotesCount) {
        this.allNotesCount_ = allNotesCount;
    }
    setAllFilesCount(allFilesCount) {
        this.allFilesCount_ = allFilesCount;
    }
    get allFilesCount() {
        return this.allFilesCount_;
    }
    get allNotesCount() {
        return this.allNotesCount_;
    }
    get previouslySelected() {
        return this.previouslySelected_;
    }
    get selected() {
        return this.selected_;
    }
    async setPanelWidthForTag(tag, width) {
        await this._changeAndSaveItem.execute(tag, (mutator) => {
            mutator.preferences = {
                ...mutator.preferences,
                panelWidth: width,
            };
        });
    }
    async setSelectedTag(tag, location, { userTriggered } = { userTriggered: false }) {
        if (tag && tag.conflictOf) {
            this._changeAndSaveItem
                .execute(tag, (mutator) => {
                mutator.conflictOf = undefined;
            })
                .catch(console.error);
        }
        if (tag && (this.isTagFilesView(tag) || this.tagUsesTableView(tag))) {
            this.paneController.setPaneLayout(PaneLayout.TableView);
        }
        else if (userTriggered) {
            this.paneController.setPaneLayout(PaneLayout.ItemSelection);
        }
        this.previouslySelected_ = this.selected_;
        await runInAction(async () => {
            this.setSelectedTagInstance(tag);
            this.selectedLocation = location;
            if (tag && this.items.isTemplateItem(tag)) {
                return;
            }
            await this.eventBus.publishSync({
                type: CrossControllerEvent.TagChanged,
                payload: { tag, previousTag: this.previouslySelected_, userTriggered: userTriggered },
            }, InternalEventPublishStrategy.SEQUENCE);
        });
    }
    async selectHomeNavigationView() {
        await this.setSelectedTag(this.homeNavigationView, 'views');
    }
    async selectFilesView() {
        await this.setSelectedTag(this.filesNavigationView, 'views');
    }
    get homeNavigationView() {
        return this.smartViews[0];
    }
    get filesNavigationView() {
        return this.smartViews.find(this.isTagFilesView);
    }
    setSelectedTagInstance(tag) {
        runInAction(() => {
            this.selected_ = tag;
            this.selectedUuid = tag ? tag.uuid : undefined;
        });
    }
    setExpanded(tag, expanded) {
        if (tag.expanded === expanded) {
            return;
        }
        this._changeAndSaveItem
            .execute(tag, (mutator) => {
            mutator.expanded = expanded;
        })
            .catch(console.error);
    }
    async setFavorite(tag, favorite) {
        return this._changeAndSaveItem
            .execute(tag, (mutator) => {
            mutator.starred = favorite;
        })
            .catch(console.error);
    }
    setIcon(tag, icon) {
        this._changeAndSaveItem
            .execute(tag, (mutator) => {
            mutator.iconString = icon;
        })
            .catch(console.error);
    }
    get editingTag() {
        return this.editing_;
    }
    setEditingTag(editingTag) {
        runInAction(() => {
            this.editing_ = editingTag;
            if (this.selected !== editingTag) {
                void this.setSelectedTag(editingTag, this.selectedLocation || 'all');
            }
        });
    }
    createNewTemplate() {
        const isAlreadyEditingATemplate = this.editing_ && this.items.isTemplateItem(this.editing_);
        if (isAlreadyEditingATemplate) {
            return;
        }
        const newTag = this.items.createTemplateItem(ContentType.TYPES.Tag);
        runInAction(() => {
            this.selectedLocation = 'all';
            this.editing_ = newTag;
        });
    }
    undoCreateNewTag() {
        this.editing_ = undefined;
        const previousTag = this.previouslySelected_ || this.smartViews[0];
        void this.setSelectedTag(previousTag, this.selectedLocation || 'views');
    }
    async remove(tag, userTriggered) {
        let shouldDelete = !userTriggered;
        if (userTriggered) {
            shouldDelete = await confirmDialog({
                title: `Delete tag "${tag.title}"?`,
                text: STRING_DELETE_TAG,
                confirmButtonStyle: 'danger',
            });
        }
        if (shouldDelete) {
            this.mutator
                .deleteItem(tag)
                .then(() => this.sync.sync())
                .catch(console.error);
            await this.setSelectedTag(this.smartViews[0], 'views');
        }
    }
    async save(tag, newTitle) {
        const isTemplateChange = this.items.isTemplateItem(tag);
        const latestVersion = isTemplateChange ? tag : this.items.findSureItem(tag.uuid);
        const hasEmptyTitle = newTitle.length === 0;
        const hasNotChangedTitle = newTitle === latestVersion.title;
        const siblings = latestVersion instanceof SNTag ? tagSiblings(this.items, latestVersion) : [];
        const hasDuplicatedTitle = siblings.some((other) => other.title.toLowerCase() === newTitle.toLowerCase());
        runInAction(() => {
            this.editing_ = undefined;
        });
        if (hasEmptyTitle || hasNotChangedTitle) {
            if (isTemplateChange) {
                this.undoCreateNewTag();
            }
            return;
        }
        if (hasDuplicatedTitle) {
            if (isTemplateChange) {
                this.undoCreateNewTag();
            }
            this.alerts.alert('A tag with this name already exists.').catch(console.error);
            return;
        }
        if (isTemplateChange) {
            const isSmartViewTitle = this.items.isSmartViewTitle(newTitle);
            if (isSmartViewTitle) {
                if (!this.featuresController.hasSmartViews) {
                    await this.featuresController.showPremiumAlert(SMART_TAGS_FEATURE_NAME);
                    return;
                }
            }
            const insertedTag = await this.mutator.createTagOrSmartView(newTitle, this.vaultDisplayService.exclusivelyShownVault);
            this.sync.sync().catch(console.error);
            runInAction(() => {
                void this.setSelectedTag(insertedTag, this.selectedLocation || 'views');
            });
        }
        else {
            await this._changeAndSaveItem.execute(latestVersion, (mutator) => {
                mutator.title = newTitle;
            });
        }
    }
    get isSearching() {
        return this.searchQuery.length > 0;
    }
}
