"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SureValueNonObjectTypesAsStrings = exports.AllPredicateOperators = exports.AllNonCompoundPredicateOperators = exports.AllPredicateCompoundOperators = void 0;
exports.AllPredicateCompoundOperators = ['and', 'or'];
exports.AllNonCompoundPredicateOperators = [
    '!=',
    '=',
    '<',
    '>',
    '<=',
    '>=',
    'startsWith',
    'in',
    'matches',
    'not',
    'includes',
];
exports.AllPredicateOperators = [...exports.AllPredicateCompoundOperators, ...exports.AllNonCompoundPredicateOperators];
exports.SureValueNonObjectTypesAsStrings = ['number', 'string', 'boolean'];
