"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryEntry = void 0;
const utils_1 = require("@standardnotes/utils");
const ItemGenerator_1 = require("../../Utilities/Item/ItemGenerator");
class HistoryEntry {
    constructor(payload, previousEntry) {
        var _a;
        this.defaultContentKeyToDiffOn = 'text';
        this.payload = payload.copy();
        this.previousEntry = previousEntry;
        this.hasPreviousEntry = !(0, utils_1.isNullOrUndefined)(previousEntry);
        /** We'll try to compute the delta based on an assumed
         * content property of `text`, if it exists. */
        const propertyValue = this.payload.content[this.defaultContentKeyToDiffOn];
        if (propertyValue) {
            if (previousEntry) {
                const previousValue = ((_a = previousEntry.payload.content[this.defaultContentKeyToDiffOn]) === null || _a === void 0 ? void 0 : _a.length) || 0;
                this.textCharDiffLength = propertyValue.length - previousValue;
            }
            else {
                this.textCharDiffLength = propertyValue.length;
            }
        }
        else {
            this.textCharDiffLength = 0;
        }
    }
    itemFromPayload() {
        return (0, ItemGenerator_1.CreateDecryptedItemFromPayload)(this.payload);
    }
    isSameAsEntry(entry) {
        if (!entry) {
            return false;
        }
        const lhs = this.itemFromPayload();
        const rhs = entry.itemFromPayload();
        const datesEqual = lhs.userModifiedDate.getTime() === rhs.userModifiedDate.getTime();
        if (!datesEqual) {
            return false;
        }
        /** Dates are the same, but because JS is only accurate to milliseconds,
         * items can have different content but same dates */
        return lhs.isItemContentEqualWith(rhs);
    }
    isDiscardable() {
        return false;
    }
    operationVector() {
        /**
         * We'll try to use the value of `textCharDiffLength`
         * to help determine this, if it's set
         */
        if (this.textCharDiffLength !== undefined) {
            if (!this.hasPreviousEntry || this.textCharDiffLength === 0) {
                return 0;
            }
            else if (this.textCharDiffLength < 0) {
                return -1;
            }
            else {
                return 1;
            }
        }
        /** Otherwise use a default value of 1 */
        return 1;
    }
    deltaSize() {
        /**
         * Up to the subclass to determine how large the delta was,
         * i.e number of characters changed.
         * But this general class won't be able to determine which property it
         * should diff on, or even its format.
         */
        /**
         * We can return the `textCharDiffLength` if it's set,
         * otherwise, just return 1;
         */
        if (this.textCharDiffLength !== undefined) {
            return Math.abs(this.textCharDiffLength);
        }
        /**
         * Otherwise return 1 here to constitute a basic positive delta.
         * The value returned should always be positive. Override `operationVector`
         * to return the direction of the delta.
         */
        return 1;
    }
}
exports.HistoryEntry = HistoryEntry;
