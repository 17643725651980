import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import RoundIconButton from '@/Components/Button/RoundIconButton';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { PreferencesSessionController } from './Controller/PreferencesSessionController';
import PreferencesCanvas from './PreferencesCanvas';
import { useAndroidBackHandler } from '@/NativeMobileWeb/useAndroidBackHandler';
import Modal from '../Modal/Modal';
import { classNames } from '@standardnotes/snjs';
import { useAvailableSafeAreaPadding } from '@/Hooks/useSafeAreaPadding';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import Icon from '../Icon/Icon';
const PreferencesView = ({ application, closePreferences }) => {
    const menu = useMemo(() => new PreferencesSessionController(application, application.enableUnfinishedFeatures), [application]);
    useEffect(() => {
        menu.selectPane(application.preferencesController.currentPane);
    }, [menu, application.preferencesController.currentPane]);
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const addAndroidBackHandler = useAndroidBackHandler();
    useEffect(() => {
        const removeListener = addAndroidBackHandler(() => {
            closePreferences();
            return true;
        });
        return () => {
            if (removeListener) {
                removeListener();
            }
        };
    }, [addAndroidBackHandler, closePreferences]);
    const { hasTopInset } = useAvailableSafeAreaPadding();
    const modalActions = useMemo(() => [
        {
            label: (_jsxs("span", { className: "flex items-center", children: [_jsx(Icon, { type: "chevron-left", size: "large" }), "Back"] })),
            type: 'primary',
            mobileSlot: 'left',
            onClick: closePreferences,
        },
    ], [closePreferences]);
    return (_jsx(Modal, { close: closePreferences, title: "Preferences", className: "flex flex-col", customHeader: _jsxs("div", { className: classNames('flex w-full flex-row items-center justify-between border-b border-solid border-border bg-default px-3 pb-2 md:p-3', hasTopInset ? 'pt-safe-top' : 'pt-2'), "data-preferences-header": true, children: [_jsx("div", { className: "hidden h-8 w-8 md:block" }), _jsx("h1", { className: "text-base font-bold md:text-lg", children: "Your preferences for Standard Notes" }), _jsx(RoundIconButton, { onClick: () => {
                        closePreferences();
                    }, icon: "close", label: "Close preferences" })] }), disableCustomHeader: isMobileScreen, actions: modalActions, customFooter: _jsx(_Fragment, {}), children: _jsx(PreferencesCanvas, { menu: menu, application: application, closePreferences: closePreferences }) }));
};
export default observer(PreferencesView);
