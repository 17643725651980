import { isMacPlatform } from '@standardnotes/ui-services';
import { keyboardCharacterForModifier } from './keyboardCharacterForModifier';
function stringForCode(code = '') {
    return code.replace('Key', '').replace('Digit', '');
}
export function keyboardStringForShortcut(shortcut) {
    var _a;
    if (!shortcut) {
        return '';
    }
    const key = ((_a = shortcut.key) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || stringForCode(shortcut.code);
    if (!shortcut.modifiers || shortcut.modifiers.length === 0) {
        return key;
    }
    const modifierCharacters = shortcut.modifiers.map((modifier) => keyboardCharacterForModifier(modifier, shortcut.platform));
    if (isMacPlatform(shortcut.platform)) {
        return `${modifierCharacters.join('')}${key}`;
    }
    else {
        return `${modifierCharacters.join('+')}+${key}`;
    }
}
