import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { HistoryLockedIllustration } from '@standardnotes/icons';
import Button from '@/Components/Button/Button';
import { useApplication } from '../ApplicationProvider';
const getPlanHistoryDuration = (planName) => {
    switch (planName) {
        case 'Core':
            return '30 days';
        case 'Plus':
            return '365 days';
        default:
            return "the current session's changes";
    }
};
const getPremiumContentCopy = (planName) => {
    return `Version history is limited to ${getPlanHistoryDuration(planName)} in the ${planName} plan`;
};
const RevisionContentLocked = () => {
    const application = useApplication();
    let planName = 'free';
    if (application.subscriptions.hasOnlineSubscription()) {
        if (!application.subscriptions.isUserSubscriptionCanceled && !application.subscriptions.isUserSubscriptionExpired) {
            planName = application.subscriptions.userSubscriptionName;
        }
    }
    return (_jsx("div", { className: "flex h-full w-full items-center justify-center", children: _jsxs("div", { className: "max-w-40% flex flex-col items-center px-8 text-center", children: [_jsx(HistoryLockedIllustration, {}), _jsx("div", { className: "mb-1 mt-2 text-lg font-bold", children: "Can't access this version" }), _jsxs("div", { className: "leading-140% mb-4 text-passive-0", children: [getPremiumContentCopy(planName), ". Learn more about our other plans to upgrade your history capacity."] }), _jsx(Button, { primary: true, label: "Discover plans", onClick: () => {
                        if (window.plansUrl) {
                            window.location.assign(window.plansUrl);
                        }
                    } })] }) }));
};
export default observer(RevisionContentLocked);
