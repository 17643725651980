import { FillItemContentSpecialized, } from '@standardnotes/models';
import { ContentType } from '@standardnotes/domain-core';
import { FindNativeFeature, GetFeatures } from '@standardnotes/features';
import { API_MESSAGE_FAILED_DOWNLOADING_EXTENSION, } from '@standardnotes/services';
import { isString } from '@standardnotes/utils';
const PluginsUrl = 'https://raw.githubusercontent.com/standardnotes/plugins/main/cdn/dist/packages.json';
export class PluginsService {
    constructor(items, mutator, sync, api, alerts, crypto) {
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.api = api;
        this.alerts = alerts;
        this.crypto = crypto;
    }
    async performDownloadPlugins() {
        const response = await fetch(PluginsUrl);
        const changelog = await response.text();
        const parsedData = JSON.parse(changelog);
        return Object.values(parsedData);
    }
    async getInstallablePlugins() {
        if (this.originalPlugins) {
            return this.filterInstallablePlugins(this.originalPlugins);
        }
        this.originalPlugins = await this.performDownloadPlugins();
        return this.filterInstallablePlugins(this.originalPlugins);
    }
    filterInstallablePlugins(plugins) {
        const filtered = plugins.filter((plugin) => {
            if (!plugin.showInGallery) {
                return false;
            }
            const nativeFeature = FindNativeFeature(plugin.identifier);
            if (nativeFeature && !nativeFeature.deprecated) {
                return false;
            }
            const existingInstalled = this.items.getDisplayableComponents().find((component) => {
                return component.identifier === plugin.identifier;
            });
            return !existingInstalled;
        });
        return filtered.sort((a, b) => {
            if (a.name === b.name) {
                return 0;
            }
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });
    }
    async installPlugin(plugin) {
        var _a;
        const isValidContentType = [
            ContentType.TYPES.Component,
            ContentType.TYPES.Theme,
            ContentType.TYPES.ActionsExtension,
            ContentType.TYPES.ExtensionRepo,
        ].includes(plugin.content_type);
        if (!isValidContentType) {
            return;
        }
        const nativeFeature = FindNativeFeature(plugin.identifier);
        if (nativeFeature && !nativeFeature.deprecated) {
            void this.alerts.alert('Unable to install plugin due to a conflict with a native feature.');
            return;
        }
        if (plugin.url) {
            for (const nativeFeature of GetFeatures()) {
                if (plugin.url.includes(nativeFeature.identifier) && !nativeFeature.deprecated) {
                    void this.alerts.alert('Unable to install plugin due to a conflict with a native feature.');
                    return;
                }
            }
        }
        const content = FillItemContentSpecialized({
            area: plugin.area,
            name: (_a = plugin.name) !== null && _a !== void 0 ? _a : '',
            package_info: plugin,
            valid_until: new Date(plugin.expires_at || 0),
            hosted_url: plugin.url,
        });
        const component = this.items.createTemplateItem(plugin.content_type, content);
        await this.mutator.insertItem(component);
        void this.sync.sync();
        return component;
    }
    async getPluginDetailsFromUrl(urlOrCode) {
        var _a;
        let url = urlOrCode;
        try {
            url = this.crypto.base64Decode(urlOrCode);
        }
        catch (err) {
            void err;
        }
        const response = await this.api.downloadFeatureUrl(url);
        if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.error) {
            await this.alerts.alert(API_MESSAGE_FAILED_DOWNLOADING_EXTENSION);
            return undefined;
        }
        let rawFeature = response.data;
        if (isString(rawFeature)) {
            try {
                rawFeature = JSON.parse(rawFeature);
                // eslint-disable-next-line no-empty
            }
            catch (error) { }
        }
        if (!rawFeature.content_type) {
            return undefined;
        }
        return rawFeature;
    }
    async installExternalPlugin(plugin) {
        const nativeFeature = FindNativeFeature(plugin.identifier);
        if (nativeFeature) {
            await this.alerts.alert('Unable to install external plugin due to a conflict with a native feature.');
            return;
        }
        if (plugin.url) {
            for (const nativeFeature of GetFeatures()) {
                if (plugin.url.includes(nativeFeature.identifier)) {
                    await this.alerts.alert('Unable to install external plugin due to a conflict with a native feature.');
                    return;
                }
            }
        }
        return this.installPlugin(plugin);
    }
}
