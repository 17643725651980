import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FileItemActionType } from '../AttachedFilesPopover/PopoverFileItemAction';
import { FileContextMenuBackupOption } from '../FileContextMenu/FileContextMenuBackupOption';
import Icon from '../Icon/Icon';
import MenuItem from '../Menu/MenuItem';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
import HorizontalSeparator from '../Shared/HorizontalSeparator';
const LinkedFileMenuOptions = ({ file, closeMenu, handleFileAction, setIsRenamingFile }) => {
    const [isFileProtected, setIsFileProtected] = useState(file.protected);
    return (_jsxs(_Fragment, { children: [_jsxs(MenuItem, { onClick: () => {
                    void handleFileAction({
                        type: FileItemActionType.PreviewFile,
                        payload: {
                            file,
                            otherFiles: [],
                        },
                    });
                    closeMenu();
                }, children: [_jsx(Icon, { type: "file", className: "mr-2 text-neutral" }), "Preview file"] }), _jsx(HorizontalSeparator, { classes: "my-1" }), _jsxs(MenuSwitchButtonItem, { className: "justify-between", checked: isFileProtected, onChange: () => {
                    handleFileAction({
                        type: FileItemActionType.ToggleFileProtection,
                        payload: { file },
                        callback: (isProtected) => {
                            setIsFileProtected(isProtected);
                        },
                    }).catch(console.error);
                }, children: [_jsx(Icon, { type: "lock", className: "mr-2 text-neutral" }), "Password protect"] }), _jsx(HorizontalSeparator, { classes: "my-1" }), _jsxs(MenuItem, { onClick: () => {
                    handleFileAction({
                        type: FileItemActionType.DownloadFile,
                        payload: { file },
                    }).catch(console.error);
                    closeMenu();
                }, children: [_jsx(Icon, { type: "download", className: "mr-2 text-neutral" }), "Download"] }), _jsxs(MenuItem, { onClick: () => {
                    setIsRenamingFile(true);
                    closeMenu();
                }, children: [_jsx(Icon, { type: "pencil", className: "mr-2 text-neutral" }), "Rename"] }), _jsxs(MenuItem, { onClick: () => {
                    handleFileAction({
                        type: FileItemActionType.DeleteFile,
                        payload: { file },
                    }).catch(console.error);
                    closeMenu();
                }, children: [_jsx(Icon, { type: "trash", className: "mr-2 text-danger" }), _jsx("span", { className: "text-danger", children: "Delete permanently" })] }), _jsx(FileContextMenuBackupOption, { file: file })] }));
};
export default LinkedFileMenuOptions;
