import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import DecoratedPasswordInput from '../Input/DecoratedPasswordInput';
export const PasswordStep = ({ onCurrentPasswordChange, onNewPasswordChange, onNewPasswordConfirmationChange, }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const handleCurrentPasswordChange = (value) => {
        setCurrentPassword(value);
        onCurrentPasswordChange(value);
    };
    const handleNewPasswordChange = (value) => {
        setNewPassword(value);
        onNewPasswordChange(value);
    };
    const handleNewPasswordConfirmationChange = (value) => {
        setNewPasswordConfirmation(value);
        onNewPasswordConfirmationChange(value);
    };
    return (_jsx("div", { className: "flex flex-col pb-1.5", children: _jsxs("form", { children: [_jsx("label", { htmlFor: "password-wiz-current-password", className: "mb-1 block", children: "Current Password" }), _jsx(DecoratedPasswordInput, { autofocus: true, id: "password-wiz-current-password", value: currentPassword, onChange: handleCurrentPasswordChange, type: "password" }), _jsx("div", { className: "min-h-2" }), _jsx("label", { htmlFor: "password-wiz-new-password", className: "mb-1 block", children: "New Password" }), _jsx(DecoratedPasswordInput, { id: "password-wiz-new-password", value: newPassword, onChange: handleNewPasswordChange, type: "password" }), _jsx("div", { className: "min-h-2" }), _jsx("label", { htmlFor: "password-wiz-confirm-new-password", className: "mb-1 block", children: "Confirm New Password" }), _jsx(DecoratedPasswordInput, { id: "password-wiz-confirm-new-password", value: newPasswordConfirmation, onChange: handleNewPasswordConfirmationChange, type: "password" })] }) }));
};
