import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useImperativeHandle, useState, } from 'react';
import { KeyboardKey } from '@standardnotes/ui-services';
import { useListKeyboardNavigation } from '@/Hooks/useListKeyboardNavigation';
import { mergeRefs } from '@/Hooks/mergeRefs';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
const Menu = forwardRef(({ children, className = '', style, a11yLabel, closeMenu, initialFocus, onKeyDown, shouldAutoFocus = true, ...props }, forwardedRef) => {
    const [menuElement, setMenuElement] = useState(null);
    const handleKeyDown = useCallback((event) => {
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(event);
        if (event.key === KeyboardKey.Escape) {
            closeMenu === null || closeMenu === void 0 ? void 0 : closeMenu();
            return;
        }
    }, [closeMenu, onKeyDown]);
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const { setInitialFocus } = useListKeyboardNavigation(menuElement, {
        initialFocus,
        shouldAutoFocus: isMobileScreen ? false : shouldAutoFocus,
    });
    useImperativeHandle(forwardedRef, () => ({
        focus: () => {
            setInitialFocus();
        },
    }));
    return (_jsx("menu", { className: `m-0 list-none px-4 focus:shadow-none md:px-0 ${className}`, onKeyDown: handleKeyDown, ref: mergeRefs([setMenuElement, forwardedRef]), style: style, "aria-label": a11yLabel, ...props, children: children }));
});
export default Menu;
