import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
const RecoveryCodeBanner = ({ application }) => {
    const [recoveryCode, setRecoveryCode] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const onClickShow = async () => {
        const authorized = await application.challenges.promptForAccountPassword();
        if (!authorized) {
            return;
        }
        const recoveryCodeOrError = await application.getRecoveryCodes.execute();
        if (recoveryCodeOrError.isFailed()) {
            setErrorMessage(recoveryCodeOrError.getError());
            return;
        }
        setRecoveryCode(recoveryCodeOrError.getValue());
    };
    return (_jsxs("div", { className: "grid grid-cols-1 rounded-md border border-border p-4", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { className: "-ml-1 mr-1 h-5 w-5 text-info group-disabled:text-passive-2", type: "asterisk" }), _jsx("h1", { className: "sk-h3 m-0 text-sm font-semibold", children: "Save your recovery code" })] }), _jsx("p", { className: "col-start-1 col-end-3 m-0 mt-1 text-sm", children: "Your recovery code allows you access to your account in the event you lose your 2FA authenticating device or app. Save your recovery code in a safe place outside your account." }), errorMessage && _jsx("div", { children: errorMessage }), !recoveryCode && (_jsx(Button, { primary: true, small: true, className: "col-start-1 col-end-3 mt-3 justify-self-start uppercase", onClick: onClickShow, children: "Show Recovery Code" })), recoveryCode && (_jsxs("div", { className: "group relative mt-2 rounded border border-border px-3 py-2 text-sm font-semibold", children: [_jsx(StyledTooltip, { label: "Copy to clipboard", className: "!z-modal", children: _jsx("button", { className: "absolute right-2 top-2 flex rounded border border-border bg-default p-1 opacity-0 hover:bg-contrast focus:opacity-100 group-hover:opacity-100", onClick: () => {
                                void navigator.clipboard.writeText(recoveryCode);
                            }, children: _jsx(Icon, { type: "copy", size: "small" }) }) }), recoveryCode] }))] }));
};
export default RecoveryCodeBanner;
