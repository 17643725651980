import { isNote, isTag } from '@standardnotes/snjs';
export function getItemTitleInContextOfLinkBubble(item) {
    return item.title && item.title.length > 0 ? item.title : isNote(item) ? item.preview_plain : '';
}
function getItemSearchableString(item, application) {
    var _a;
    if (isNote(item)) {
        if (item.title.length > 0) {
            return item.title;
        }
        else if (!item.protected) {
            return item.preview_plain;
        }
    }
    else if (isTag(item)) {
        return application.items.getTagLongTitle(item);
    }
    return (_a = item.title) !== null && _a !== void 0 ? _a : '';
}
export function doesItemMatchSearchQuery(item, searchQuery, application) {
    const title = getItemSearchableString(item, application).toLowerCase();
    const matchesQuery = title.includes(searchQuery.toLowerCase());
    const isArchivedOrTrashed = item.archived || item.trashed;
    const isValidSearchResult = matchesQuery && !isArchivedOrTrashed;
    return isValidSearchResult;
}
