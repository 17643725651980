import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import QRCode from 'qrcode.react';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import AuthAppInfoTooltip from './AuthAppInfoPopup';
import CopyButton from './CopyButton';
import Bullet from './Bullet';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
const ScanQRCode = ({ activation: act }) => {
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    return (_jsxs("div", { className: "h-33 flex flex-col items-center gap-5 px-4 py-4 md:flex-row", children: [_jsx("div", { className: "flex items-center justify-center bg-info", children: _jsx(QRCode, { className: "border-2 border-solid border-neutral-contrast", value: act.qrCode, size: isMobileScreen ? 200 : 150 }) }), _jsxs("div", { className: "flex flex-grow flex-col gap-2", children: [_jsxs("div", { className: "flex flex-row items-center", children: [_jsx(Bullet, {}), _jsx("div", { className: "min-w-1" }), _jsxs("div", { className: "text-sm", children: ["Open your ", _jsx("b", { children: "authenticator app" }), "."] }), _jsx("div", { className: "min-w-2" }), _jsx(AuthAppInfoTooltip, {})] }), _jsxs("div", { className: "flex flex-row items-center", children: [_jsx(Bullet, { className: "mt-2 self-start" }), _jsx("div", { className: "min-w-1" }), _jsxs("div", { className: "flex-grow text-sm", children: [_jsx("b", { children: "Scan this QR code" }), " or ", _jsx("b", { children: "add this secret key" }), ":"] })] }), _jsx(DecoratedInput, { className: { container: 'w-92 ml-4' }, disabled: true, value: act.secretKey, right: [_jsx(CopyButton, { copyValue: act.secretKey })] })] })] }));
};
export default observer(ScanQRCode);
