"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UuidMap = void 0;
const Utils_1 = require("../Utils/Utils");
/**
 * Maps a UUID to an array of UUIDS to establish either direct or inverse
 * relationships between UUID strings (represantative of items or payloads).
 */
class UuidMap {
    constructor() {
        /** uuid to uuids that we have a relationship with */
        this.directMap = new Map();
        /** uuid to uuids that have a relationship with us */
        this.inverseMap = new Map();
    }
    get directMapSize() {
        return this.directMap.size;
    }
    get inverseMapSize() {
        return this.inverseMap.size;
    }
    makeCopy() {
        const copy = new UuidMap();
        copy.directMap = new Map(this.directMap);
        copy.inverseMap = new Map(this.inverseMap);
        return copy;
    }
    existsInDirectMap(uuid) {
        return this.directMap.has(uuid);
    }
    existsInInverseMap(uuid) {
        return this.inverseMap.has(uuid);
    }
    getDirectRelationships(uuid) {
        return this.directMap.get(uuid) || [];
    }
    getAllDirectKeys() {
        return Array.from(this.directMap.keys());
    }
    getInverseRelationships(uuid) {
        return this.inverseMap.get(uuid) || [];
    }
    establishRelationship(uuidA, uuidB) {
        this.establishDirectRelationship(uuidA, uuidB);
        this.establishInverseRelationship(uuidA, uuidB);
    }
    deestablishRelationship(uuidA, uuidB) {
        this.deestablishDirectRelationship(uuidA, uuidB);
        this.deestablishInverseRelationship(uuidA, uuidB);
    }
    setAllRelationships(uuid, relationships) {
        const previousDirect = this.directMap.get(uuid) || [];
        this.directMap.set(uuid, relationships);
        /** Remove all previous values in case relationships have changed
         * The updated references will be added afterwards.
         */
        for (const previousRelationship of previousDirect) {
            this.deestablishInverseRelationship(uuid, previousRelationship);
        }
        /** Now map current relationships */
        for (const newRelationship of relationships) {
            this.establishInverseRelationship(uuid, newRelationship);
        }
    }
    removeFromMap(uuid) {
        /** Items that we reference */
        const directReferences = this.directMap.get(uuid) || [];
        for (const directReference of directReferences) {
            (0, Utils_1.removeFromArray)(this.inverseMap.get(directReference) || [], uuid);
        }
        this.directMap.delete(uuid);
        /** Items that are referencing us */
        const inverseReferences = this.inverseMap.get(uuid) || [];
        for (const inverseReference of inverseReferences) {
            (0, Utils_1.removeFromArray)(this.directMap.get(inverseReference) || [], uuid);
        }
        this.inverseMap.delete(uuid);
    }
    establishDirectRelationship(uuidA, uuidB) {
        const index = this.directMap.get(uuidA) || [];
        (0, Utils_1.addIfUnique)(index, uuidB);
        this.directMap.set(uuidA, index);
    }
    establishInverseRelationship(uuidA, uuidB) {
        const inverseIndex = this.inverseMap.get(uuidB) || [];
        (0, Utils_1.addIfUnique)(inverseIndex, uuidA);
        this.inverseMap.set(uuidB, inverseIndex);
    }
    deestablishDirectRelationship(uuidA, uuidB) {
        const index = this.directMap.get(uuidA) || [];
        (0, Utils_1.removeFromArray)(index, uuidB);
        this.directMap.set(uuidA, index);
    }
    deestablishInverseRelationship(uuidA, uuidB) {
        const inverseIndex = this.inverseMap.get(uuidB) || [];
        (0, Utils_1.removeFromArray)(inverseIndex, uuidA);
        this.inverseMap.set(uuidB, inverseIndex);
    }
}
exports.UuidMap = UuidMap;
