import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { TabStateContext } from './useTabState';
const TabList = ({ state, children, ...props }) => {
    const providerValue = useMemo(() => ({
        state,
    }), [state]);
    return (_jsx(TabStateContext.Provider, { value: providerValue, children: _jsx("div", { role: "tablist", ...props, children: children }) }));
};
export default TabList;
