import { useApplication } from '@/Components/ApplicationProvider';
import { LiveItem } from '@standardnotes/snjs';
import { useEffect, useState } from 'react';
const useItem = (uuid) => {
    const application = useApplication();
    const [item, setItem] = useState();
    useEffect(() => {
        if (!uuid) {
            return;
        }
        const live = new LiveItem(uuid, application.items, (item) => {
            setItem(item);
        });
        return () => live.deinit();
    }, [uuid, application]);
    if (!uuid) {
        return undefined;
    }
    return item;
};
export default useItem;
