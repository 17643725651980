"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadTimestampDefaults = void 0;
function PayloadTimestampDefaults() {
    return {
        updated_at: new Date(0),
        created_at: new Date(),
        updated_at_timestamp: 0,
        created_at_timestamp: 0,
    };
}
exports.PayloadTimestampDefaults = PayloadTimestampDefaults;
