import { destroyAllObjectProperties } from '@/Utils';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApplicationEvent, ContentType, } from '@standardnotes/snjs';
import { AccountMenuPane } from '@/Components/AccountMenu/AccountMenuPane';
import { AbstractViewController } from '../Abstract/AbstractViewController';
export class AccountMenuController extends AbstractViewController {
    deinit() {
        super.deinit();
        this.notesAndTags = undefined;
        destroyAllObjectProperties(this);
    }
    constructor(items, _getHost, eventBus) {
        super(eventBus);
        this.items = items;
        this._getHost = _getHost;
        this.show = false;
        this.signingOut = false;
        this.otherSessionsSignOut = false;
        this.server = undefined;
        this.notesAndTags = [];
        this.isEncryptionEnabled = false;
        this.encryptionStatusString = '';
        this.isBackupEncrypted = false;
        this.showSignIn = false;
        this.deletingAccount = false;
        this.showRegister = false;
        this.currentPane = AccountMenuPane.GeneralMenu;
        this.setShow = (show) => {
            this.show = show;
            if (show) {
                this.setCurrentPane(AccountMenuPane.GeneralMenu);
            }
        };
        this.closeAccountMenu = () => {
            this.setShow(false);
        };
        this.setSigningOut = (signingOut) => {
            this.signingOut = signingOut;
        };
        this.setServer = (server) => {
            this.server = server;
        };
        this.setIsEncryptionEnabled = (isEncryptionEnabled) => {
            this.isEncryptionEnabled = isEncryptionEnabled;
        };
        this.setEncryptionStatusString = (encryptionStatusString) => {
            this.encryptionStatusString = encryptionStatusString;
        };
        this.setIsBackupEncrypted = (isBackupEncrypted) => {
            this.isBackupEncrypted = isBackupEncrypted;
        };
        this.setShowSignIn = (showSignIn) => {
            this.showSignIn = showSignIn;
        };
        this.setShowRegister = (showRegister) => {
            this.showRegister = showRegister;
        };
        this.toggleShow = () => {
            if (this.show) {
                this.closeAccountMenu();
            }
            else {
                this.setShow(true);
            }
        };
        this.setOtherSessionsSignOut = (otherSessionsSignOut) => {
            this.otherSessionsSignOut = otherSessionsSignOut;
        };
        this.setCurrentPane = (pane) => {
            this.currentPane = pane;
        };
        this.setDeletingAccount = (deletingAccount) => {
            this.deletingAccount = deletingAccount;
        };
        makeObservable(this, {
            show: observable,
            signingOut: observable,
            otherSessionsSignOut: observable,
            server: observable,
            notesAndTags: observable,
            isEncryptionEnabled: observable,
            encryptionStatusString: observable,
            isBackupEncrypted: observable,
            showSignIn: observable,
            deletingAccount: observable,
            showRegister: observable,
            currentPane: observable,
            setShow: action,
            toggleShow: action,
            setSigningOut: action,
            setIsEncryptionEnabled: action,
            setEncryptionStatusString: action,
            setIsBackupEncrypted: action,
            setOtherSessionsSignOut: action,
            setCurrentPane: action,
            setServer: action,
            setDeletingAccount: action,
            notesAndTagsCount: computed,
        });
        eventBus.addEventHandler(this, ApplicationEvent.Launched);
        this.disposers.push(this.items.streamItems([ContentType.TYPES.Note, ContentType.TYPES.Tag], () => {
            runInAction(() => {
                this.notesAndTags = this.items.getItems([ContentType.TYPES.Note, ContentType.TYPES.Tag]);
            });
        }));
    }
    async handleEvent(event) {
        switch (event.type) {
            case ApplicationEvent.Launched: {
                runInAction(() => {
                    this.setServer(this._getHost.execute().getValue());
                });
                break;
            }
        }
    }
    get notesAndTagsCount() {
        return this.notesAndTags.length;
    }
}
