"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEditorFeatureDescription = exports.isIframeComponentFeatureDescription = exports.isThemeFeatureDescription = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const ComponentArea_1 = require("../Component/ComponentArea");
function isThemeFeatureDescription(feature) {
    return 'content_type' in feature && feature.content_type === domain_core_1.ContentType.TYPES.Theme;
}
exports.isThemeFeatureDescription = isThemeFeatureDescription;
function isIframeComponentFeatureDescription(feature) {
    return ('content_type' in feature &&
        feature.content_type === domain_core_1.ContentType.TYPES.Component &&
        [ComponentArea_1.ComponentArea.Editor, ComponentArea_1.ComponentArea.EditorStack].includes(feature.area));
}
exports.isIframeComponentFeatureDescription = isIframeComponentFeatureDescription;
function isEditorFeatureDescription(feature) {
    return (feature.note_type != undefined ||
        feature.area === ComponentArea_1.ComponentArea.Editor);
}
exports.isEditorFeatureDescription = isEditorFeatureDescription;
