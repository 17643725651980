"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagMutator = void 0;
const Functions_1 = require("../../Abstract/Reference/Functions");
const ContenteReferenceType_1 = require("../../Abstract/Reference/ContenteReferenceType");
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
const domain_core_1 = require("@standardnotes/domain-core");
class TagMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    constructor(item, type) {
        super(item, type);
        this.mutablePreferences = this.mutableContent.preferences;
    }
    set title(title) {
        this.mutableContent.title = title;
    }
    set expanded(expanded) {
        this.mutableContent.expanded = expanded;
    }
    set iconString(iconString) {
        this.mutableContent.iconString = iconString;
    }
    get preferences() {
        if (!this.mutablePreferences) {
            this.mutableContent.preferences = {};
            this.mutablePreferences = this.mutableContent.preferences;
        }
        return this.mutablePreferences;
    }
    set preferences(preferences) {
        this.mutablePreferences = preferences;
        this.mutableContent.preferences = this.mutablePreferences;
    }
    makeChildOf(tag) {
        const references = this.immutableItem.references.filter((ref) => !(0, Functions_1.isTagToParentTagReference)(ref));
        const reference = {
            reference_type: ContenteReferenceType_1.ContentReferenceType.TagToParentTag,
            content_type: domain_core_1.ContentType.TYPES.Tag,
            uuid: tag.uuid,
        };
        references.push(reference);
        this.mutableContent.references = references;
    }
    unsetParent() {
        this.mutableContent.references = this.immutableItem.references.filter((ref) => !(0, Functions_1.isTagToParentTagReference)(ref));
    }
    addFile(file) {
        if (this.immutableItem.isReferencingItem(file)) {
            return;
        }
        const reference = {
            reference_type: ContenteReferenceType_1.ContentReferenceType.TagToFile,
            content_type: domain_core_1.ContentType.TYPES.File,
            uuid: file.uuid,
        };
        this.mutableContent.references.push(reference);
    }
    removeFile(file) {
        this.mutableContent.references = this.mutableContent.references.filter((r) => r.uuid !== file.uuid);
    }
    addNote(note) {
        if (this.immutableItem.isReferencingItem(note)) {
            return;
        }
        this.mutableContent.references.push({
            uuid: note.uuid,
            content_type: note.content_type,
        });
    }
    removeNote(note) {
        this.mutableContent.references = this.mutableContent.references.filter((r) => r.uuid !== note.uuid);
    }
}
exports.TagMutator = TagMutator;
