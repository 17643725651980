import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StyledRadioInput from '@/Components/Radio/StyledRadioInput';
import { useState } from 'react';
import { Title } from '../../PreferencesComponents/Content';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
export const ShouldPersistNoteStateKey = 'ShouldPersistNoteState';
const Persistence = ({ application }) => {
    const [shouldPersistNoteState, setShouldPersistNoteState] = useState(application.getValue(ShouldPersistNoteStateKey));
    const toggleStatePersistence = (shouldPersist) => {
        application.setValue(ShouldPersistNoteStateKey, shouldPersist);
        setShouldPersistNoteState(shouldPersist);
        if (shouldPersist) {
            application.persistence.persistCurrentState();
        }
        else {
            application.persistence.clearPersistedValues();
        }
    };
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { className: "mb-2", children: "When opening the app, show..." }), _jsxs("label", { className: "mb-2 flex items-center gap-2 text-base font-medium md:text-sm", children: [_jsx(StyledRadioInput, { name: "state-persistence", checked: !shouldPersistNoteState, onChange: (event) => {
                                toggleStatePersistence(!event.target.checked);
                            } }), "The first note in the list"] }), _jsxs("label", { className: "flex items-center gap-2 text-base font-medium md:text-sm", children: [_jsx(StyledRadioInput, { name: "state-persistence", checked: !!shouldPersistNoteState, onChange: (event) => {
                                toggleStatePersistence(event.target.checked);
                            } }), "The last viewed note"] })] }) }));
};
export default Persistence;
