import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isFile } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef } from 'react';
import Icon from '@/Components/Icon/Icon';
import ListItemConflictIndicator from './ListItemConflictIndicator';
import ListItemFlagIcons from './ListItemFlagIcons';
import ListItemTags from './ListItemTags';
import ListItemMetadata from './ListItemMetadata';
import { useContextMenuEvent } from '@/Hooks/useContextMenuEvent';
import ListItemNotePreviewText from './ListItemNotePreviewText';
import { ListItemTitle } from './ListItemTitle';
import { log, LoggingDomain } from '@/Logging';
import { classNames } from '@standardnotes/utils';
import { getIconAndTintForNoteType } from '@/Utils/Items/Icons/getIconAndTintForNoteType';
import ListItemVaultInfo from './ListItemVaultInfo';
import { NoteDragDataFormat } from '../Tags/DragNDrop';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import useItem from '@/Hooks/useItem';
import CheckIndicator from '../Checkbox/CheckIndicator';
const NoteListItem = ({ application, notesController, onSelect, hideDate, hideIcon, hideTags, hidePreview, item, selected, sortBy, tags, isPreviousItemTiled, isNextItemTiled, }) => {
    const listItemRef = useRef(null);
    const liveItem = useItem(item.uuid);
    const editor = liveItem ? application.componentManager.editorForNote(liveItem) : undefined;
    const noteType = (liveItem === null || liveItem === void 0 ? void 0 : liveItem.noteType) ? liveItem.noteType : editor ? editor.noteType : undefined;
    const [icon, tint] = getIconAndTintForNoteType(noteType);
    const hasFiles = application.items.itemsReferencingItem(item).filter(isFile).length > 0;
    const openNoteContextMenu = (posX, posY) => {
        notesController.setContextMenuClickLocation({
            x: posX,
            y: posY,
        });
        notesController.setContextMenuOpen(true);
    };
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const handleContextMenuEvent = async (posX, posY) => {
        if (isMobileScreen) {
            if (!application.itemListController.isMultipleSelectionMode) {
                application.itemListController.replaceSelection(item);
            }
            application.itemListController.enableMultipleSelectionMode();
            return;
        }
        let shouldOpenContextMenu = selected;
        if (!selected) {
            const { didSelect } = await onSelect(item);
            if (didSelect) {
                shouldOpenContextMenu = true;
            }
        }
        if (shouldOpenContextMenu) {
            openNoteContextMenu(posX, posY);
        }
    };
    const onClick = useCallback((event) => {
        const hasMultiSelectionModifierKey = !isMobileScreen && (event.ctrlKey || event.metaKey);
        if (hasMultiSelectionModifierKey && !application.itemListController.isMultipleSelectionMode) {
            application.itemListController.enableMultipleSelectionMode();
        }
        if (selected && !application.itemListController.isMultipleSelectionMode) {
            application.itemListController.openSingleSelectedItem({ userTriggered: true }).catch(console.error);
            return;
        }
        onSelect(item, true).catch(console.error);
    }, [application.itemListController, isMobileScreen, item, onSelect, selected]);
    useContextMenuEvent(listItemRef, handleContextMenuEvent);
    log(LoggingDomain.ItemsList, 'Rendering note list item', item.title);
    const hasOffsetBorder = !isNextItemTiled;
    const dragPreview = useRef();
    const createDragPreview = () => {
        if (!listItemRef.current) {
            throw new Error('List item ref is not set');
        }
        const element = listItemRef.current.cloneNode(true);
        // Only keep icon & title in drag preview
        Array.from(element.childNodes[1].childNodes).forEach((node, key) => {
            if (key !== 0) {
                node.remove();
            }
        });
        element.childNodes[2].remove();
        if (element instanceof HTMLDivElement) {
            element.style.width = `${listItemRef.current.clientWidth}px`;
            element.style.position = 'absolute';
            element.style.top = '0';
            element.style.left = '0';
            element.style.zIndex = '-100000';
            document.body.appendChild(element);
            dragPreview.current = element;
        }
        return element;
    };
    return (_jsxs("div", { ref: listItemRef, role: "button", className: classNames('content-list-item flex w-full cursor-pointer items-stretch border-l-2 text-text', selected
            ? `selected ${application.itemListController.isMultipleSelectionMode ? 'border-info' : `border-accessory-tint-${tint}`}`
            : 'border-transparent', isPreviousItemTiled && 'mt-3 border-t border-t-border', isNextItemTiled && 'mb-3 border-b border-b-border'), id: item.uuid, onClick: onClick, draggable: !isMobileScreen && !application.itemListController.isMultipleSelectionMode, onDragStart: (event) => {
            if (!listItemRef.current) {
                return;
            }
            const { dataTransfer } = event;
            const element = createDragPreview();
            dataTransfer.setDragImage(element, 0, 0);
            dataTransfer.setData(NoteDragDataFormat, item.uuid);
        }, onDragLeave: () => {
            if (dragPreview.current) {
                dragPreview.current.remove();
            }
        }, children: [application.itemListController.isMultipleSelectionMode ? (_jsx("div", { className: "mr-0 flex flex-col items-center justify-between gap-2 p-4 pr-4", children: _jsx(CheckIndicator, { className: "md:!h-5 md:!w-5", checked: selected }) })) : !hideIcon ? (_jsx("div", { className: "mr-0 flex flex-col items-center justify-between gap-2 p-4 pr-4", children: _jsx(Icon, { type: icon, className: `text-accessory-tint-${tint}` }) })) : (_jsx("div", { className: "pr-4" })), _jsxs("div", { className: `min-w-0 flex-grow ${hasOffsetBorder && 'border-b border-solid border-border'} px-0 py-4`, children: [_jsx(ListItemTitle, { item: item }), _jsx(ListItemNotePreviewText, { item: item, hidePreview: hidePreview }), _jsx(ListItemMetadata, { item: item, hideDate: hideDate, sortBy: sortBy }), _jsx(ListItemTags, { hideTags: hideTags, tags: tags }), _jsx(ListItemConflictIndicator, { item: item }), _jsx(ListItemVaultInfo, { item: item, className: "mt-1.5" })] }), _jsx(ListItemFlagIcons, { className: "p-4", item: item, hasFiles: hasFiles, hasBorder: hasOffsetBorder })] }));
};
export default observer(NoteListItem);
