import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import WorkspaceSwitcherMenu from '@/Components/AccountMenu/WorkspaceSwitcher/WorkspaceSwitcherMenu';
import Button from '@/Components/Button/Button';
import Icon from '@/Components/Icon/Icon';
import Popover from '../Popover/Popover';
const LockscreenWorkspaceSwitcher = ({ mainApplicationGroup }) => {
    const buttonRef = useRef(null);
    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);
    return (_jsxs("div", { ref: containerRef, children: [_jsxs(Button, { ref: buttonRef, onClick: toggleMenu, className: "mt-2 flex min-w-76 items-center justify-center", children: [_jsx(Icon, { type: "user-switch", className: "mr-2 text-neutral" }), "Switch workspace"] }), _jsx(Popover, { title: "Switch workspace", align: "center", anchorElement: buttonRef, open: isOpen, overrideZIndex: "z-modal", side: "right", togglePopover: toggleMenu, children: _jsx(WorkspaceSwitcherMenu, { mainApplicationGroup: mainApplicationGroup, hideWorkspaceOptions: true }) })] }));
};
export default LockscreenWorkspaceSwitcher;
