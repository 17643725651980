"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFilterCriteriaValidator = void 0;
class CustomFilterCriteriaValidator {
    constructor(customFilter, element) {
        this.customFilter = customFilter;
        this.element = element;
    }
    passes() {
        return this.customFilter(this.element);
    }
}
exports.CustomFilterCriteriaValidator = CustomFilterCriteriaValidator;
