"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaRemoteDataConflicts = void 0;
const Conflict_1 = require("./Conflict");
const Payload_1 = require("../../Abstract/Payload");
const DeltaEmit_1 = require("./Abstract/DeltaEmit");
const ApplyDirtyState_1 = require("./Utilities/ApplyDirtyState");
class DeltaRemoteDataConflicts {
    constructor(baseCollection, conflicts, historyMap) {
        this.baseCollection = baseCollection;
        this.conflicts = conflicts;
        this.historyMap = historyMap;
    }
    result() {
        const result = {
            emits: [],
            ignored: [],
            source: Payload_1.PayloadEmitSource.RemoteRetrieved,
        };
        for (const conflict of this.conflicts) {
            const base = this.baseCollection.find(conflict.server_item.uuid);
            const isBaseDeleted = base == undefined;
            if (isBaseDeleted) {
                result.emits.push((0, ApplyDirtyState_1.payloadByFinalizingSyncState)(conflict.server_item, this.baseCollection));
                continue;
            }
            const delta = new Conflict_1.ConflictDelta(this.baseCollection, base, conflict.server_item, this.historyMap);
            (0, DeltaEmit_1.extendSyncDelta)(result, delta.result());
        }
        return result;
    }
}
exports.DeltaRemoteDataConflicts = DeltaRemoteDataConflicts;
