import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ArrowDownCheckmarkIcon } from '@standardnotes/icons';
import { Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { classNames } from '@standardnotes/snjs';
const AccordionItem = ({ title, className = '', children, onClick }) => {
    const elementRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    return (_jsxs("div", { className: className, children: [_jsxs("div", { className: "relative flex cursor-pointer items-center justify-between hover:underline", onClick: () => {
                    setIsExpanded(!isExpanded);
                    if (onClick) {
                        onClick(!isExpanded);
                    }
                }, children: [_jsx(Title, { children: title }), _jsx(ArrowDownCheckmarkIcon, { className: classNames('h-5 w-5 text-info', isExpanded && 'rotate-180') })] }), _jsx("div", { className: 'accordion-contents-container cursor-auto', "data-is-expanded": isExpanded, ref: elementRef, children: children })] }));
};
export default AccordionItem;
