"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paths = void 0;
const SharingPaths = {
    invite: '/v1/subscription-invites',
    acceptInvite: (inviteUuid) => `/v1/subscription-invites/${inviteUuid}/accept`,
    declineInvite: (inviteUuid) => `/v1/subscription-invites/${inviteUuid}/decline`,
    cancelInvite: (inviteUuid) => `/v1/subscription-invites/${inviteUuid}`,
    listInvites: '/v1/subscription-invites',
};
const UserSubscriptionPaths = {
    subscription: (userUuid) => `/v1/users/${userUuid}/subscription`,
};
const ApplePaths = {
    confirmAppleIAP: '/v1/subscriptions/apple_iap_confirm',
};
const UnauthenticatedSubscriptionsPaths = {
    availableSubscriptions: '/v2/subscriptions',
};
exports.Paths = {
    v1: Object.assign(Object.assign(Object.assign(Object.assign({}, SharingPaths), ApplePaths), UserSubscriptionPaths), UnauthenticatedSubscriptionsPaths),
};
