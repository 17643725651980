"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateItemFromPayload = exports.CreateDecryptedItemFromPayload = exports.RegisterItemClass = exports.CreateDecryptedMutatorForItem = void 0;
const EncryptedItem_1 = require("../../Abstract/Item/Implementations/EncryptedItem");
const File_1 = require("../../Syncable/File/File");
const FeatureRepo_1 = require("../../Syncable/FeatureRepo/FeatureRepo");
const ActionsExtension_1 = require("../../Syncable/ActionsExtension/ActionsExtension");
const Component_1 = require("../../Syncable/Component/Component");
const Editor_1 = require("../../Syncable/Editor/Editor");
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const Note_1 = require("../../Syncable/Note/Note");
const SmartView_1 = require("../../Syncable/SmartView/SmartView");
const Tag_1 = require("../../Syncable/Tag/Tag");
const UserPrefs_1 = require("../../Syncable/UserPrefs/UserPrefs");
const FileMutator_1 = require("../../Syncable/File/FileMutator");
const UserPrefsMutator_1 = require("../../Syncable/UserPrefs/UserPrefsMutator");
const ActionsExtensionMutator_1 = require("../../Syncable/ActionsExtension/ActionsExtensionMutator");
const ComponentMutator_1 = require("../../Syncable/Component/ComponentMutator");
const TagMutator_1 = require("../../Syncable/Tag/TagMutator");
const NoteMutator_1 = require("../../Syncable/Note/NoteMutator");
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
const DeletedItem_1 = require("../../Abstract/Item/Implementations/DeletedItem");
const SmartView_2 = require("../../Syncable/SmartView");
const TrustedContact_1 = require("../../Syncable/TrustedContact/TrustedContact");
const TrustedContactMutator_1 = require("../../Syncable/TrustedContact/Mutator/TrustedContactMutator");
const KeySystemRootKey_1 = require("../../Syncable/KeySystemRootKey/KeySystemRootKey");
const KeySystemRootKeyMutator_1 = require("../../Syncable/KeySystemRootKey/KeySystemRootKeyMutator");
const VaultListing_1 = require("../../Syncable/VaultListing/VaultListing");
const VaultListingMutator_1 = require("../../Syncable/VaultListing/VaultListingMutator");
const Payload_1 = require("../../Abstract/Payload");
const domain_core_1 = require("@standardnotes/domain-core");
const ContentTypeClassMapping = {
    [domain_core_1.ContentType.TYPES.ActionsExtension]: {
        itemClass: ActionsExtension_1.SNActionsExtension,
        mutatorClass: ActionsExtensionMutator_1.ActionsExtensionMutator,
    },
    [domain_core_1.ContentType.TYPES.Component]: { itemClass: Component_1.ComponentItem, mutatorClass: ComponentMutator_1.ComponentMutator },
    [domain_core_1.ContentType.TYPES.KeySystemRootKey]: { itemClass: KeySystemRootKey_1.KeySystemRootKey, mutatorClass: KeySystemRootKeyMutator_1.KeySystemRootKeyMutator },
    [domain_core_1.ContentType.TYPES.TrustedContact]: { itemClass: TrustedContact_1.TrustedContact, mutatorClass: TrustedContactMutator_1.TrustedContactMutator },
    [domain_core_1.ContentType.TYPES.VaultListing]: { itemClass: VaultListing_1.VaultListing, mutatorClass: VaultListingMutator_1.VaultListingMutator },
    [domain_core_1.ContentType.TYPES.Editor]: { itemClass: Editor_1.SNEditor },
    [domain_core_1.ContentType.TYPES.ExtensionRepo]: { itemClass: FeatureRepo_1.SNFeatureRepo },
    [domain_core_1.ContentType.TYPES.File]: { itemClass: File_1.FileItem, mutatorClass: FileMutator_1.FileMutator },
    [domain_core_1.ContentType.TYPES.Note]: { itemClass: Note_1.SNNote, mutatorClass: NoteMutator_1.NoteMutator },
    [domain_core_1.ContentType.TYPES.SmartView]: { itemClass: SmartView_1.SmartView, mutatorClass: SmartView_2.SmartViewMutator },
    [domain_core_1.ContentType.TYPES.Tag]: { itemClass: Tag_1.SNTag, mutatorClass: TagMutator_1.TagMutator },
    [domain_core_1.ContentType.TYPES.Theme]: { itemClass: Component_1.ComponentItem, mutatorClass: ComponentMutator_1.ComponentMutator },
    [domain_core_1.ContentType.TYPES.UserPrefs]: { itemClass: UserPrefs_1.SNUserPrefs, mutatorClass: UserPrefsMutator_1.UserPrefsMutator },
};
function CreateDecryptedMutatorForItem(item, type) {
    var _a;
    const lookupValue = (_a = ContentTypeClassMapping[item.content_type]) === null || _a === void 0 ? void 0 : _a.mutatorClass;
    if (lookupValue) {
        return new lookupValue(item, type);
    }
    else {
        return new DecryptedItemMutator_1.DecryptedItemMutator(item, type);
    }
}
exports.CreateDecryptedMutatorForItem = CreateDecryptedMutatorForItem;
function RegisterItemClass(contentType, itemClass, mutatorClass) {
    const entry = {
        itemClass: itemClass,
        mutatorClass: mutatorClass,
    };
    ContentTypeClassMapping[contentType] = entry;
}
exports.RegisterItemClass = RegisterItemClass;
function CreateDecryptedItemFromPayload(payload) {
    const lookupClass = ContentTypeClassMapping[payload.content_type];
    const itemClass = lookupClass ? lookupClass.itemClass : DecryptedItem_1.DecryptedItem;
    const item = new itemClass(payload);
    return item;
}
exports.CreateDecryptedItemFromPayload = CreateDecryptedItemFromPayload;
function CreateItemFromPayload(payload) {
    if ((0, Payload_1.isDecryptedPayload)(payload)) {
        return CreateDecryptedItemFromPayload(payload);
    }
    else if ((0, Payload_1.isEncryptedPayload)(payload)) {
        return new EncryptedItem_1.EncryptedItem(payload);
    }
    else if ((0, Payload_1.isDeletedPayload)(payload)) {
        return new DeletedItem_1.DeletedItem(payload);
    }
    else {
        throw Error('Unhandled case in CreateItemFromPayload');
    }
}
exports.CreateItemFromPayload = CreateItemFromPayload;
