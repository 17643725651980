import { jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Text } from '@/Components/Preferences/PreferencesComponents/Content';
import { useApplication } from '@/Components/ApplicationProvider';
const SubscriptionStatusText = () => {
    const application = useApplication();
    const { userSubscriptionName, userSubscriptionExpirationDate, isUserSubscriptionExpired, isUserSubscriptionCanceled, } = application.subscriptions;
    const expirationDateString = userSubscriptionExpirationDate === null || userSubscriptionExpirationDate === void 0 ? void 0 : userSubscriptionExpirationDate.toLocaleString();
    if (isUserSubscriptionCanceled) {
        return (_jsxs(Text, { className: "mt-1", children: ["Your", ' ', _jsxs("span", { className: "font-bold", children: ["Standard Notes", userSubscriptionName ? ' ' : '', userSubscriptionName] }), ' ', "subscription has been canceled", ' ', isUserSubscriptionExpired ? (_jsxs("span", { className: "font-bold", children: ["and expired on ", expirationDateString] })) : (_jsxs("span", { className: "font-bold", children: ["but will remain valid until ", expirationDateString] })), ". You may resubscribe below if you wish."] }));
    }
    if (isUserSubscriptionExpired) {
        return (_jsxs(Text, { className: "mt-1", children: ["Your", ' ', _jsxs("span", { className: "font-bold", children: ["Standard Notes", userSubscriptionName ? ' ' : '', userSubscriptionName] }), ' ', "subscription ", _jsxs("span", { className: "font-bold", children: ["expired on ", expirationDateString] }), ". You may resubscribe below if you wish."] }));
    }
    return (_jsxs(Text, { className: "mt-1", children: ["Your", ' ', _jsxs("span", { className: "font-bold", children: ["Standard Notes", userSubscriptionName ? ' ' : '', userSubscriptionName] }), ' ', "subscription will be ", _jsxs("span", { className: "font-bold", children: ["renewed on ", expirationDateString] }), "."] }));
};
export default observer(SubscriptionStatusText);
