import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TOGGLE_LIST_PANE_KEYBOARD_COMMAND, TOGGLE_NAVIGATION_PANE_KEYBOARD_COMMAND } from '@standardnotes/ui-services';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useResponsiveAppPane } from '../Panes/ResponsivePaneProvider';
import { useCommandService } from '../CommandProvider';
import MenuSwitchButtonItem from '../Menu/MenuSwitchButtonItem';
const PanelSettingsSection = () => {
    const { isListPaneCollapsed, isNavigationPaneCollapsed, toggleListPane, toggleNavigationPane } = useResponsiveAppPane();
    const commandService = useCommandService();
    const navigationShortcut = useMemo(() => commandService.keyboardShortcutForCommand(TOGGLE_NAVIGATION_PANE_KEYBOARD_COMMAND), [commandService]);
    const listShortcut = useMemo(() => commandService.keyboardShortcutForCommand(TOGGLE_LIST_PANE_KEYBOARD_COMMAND), [commandService]);
    return (_jsxs("div", { className: "hidden md:block pointer-coarse:md-only:hidden pointer-coarse:lg-only:hidden", children: [_jsx(MenuSwitchButtonItem, { className: "items-center", checked: !isNavigationPaneCollapsed, onChange: toggleNavigationPane, shortcut: navigationShortcut, children: "Show Tags Panel" }), _jsx(MenuSwitchButtonItem, { className: "items-center", checked: !isListPaneCollapsed, onChange: toggleListPane, shortcut: listShortcut, children: "Show Notes Panel" })] }));
};
export default observer(PanelSettingsSection);
