"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeletedPayload = void 0;
const PayloadSource_1 = require("../Types/PayloadSource");
const PurePayload_1 = require("./PurePayload");
class DeletedPayload extends PurePayload_1.PurePayload {
    constructor(rawPayload, source = PayloadSource_1.PayloadSource.Constructor) {
        super(rawPayload, source);
        this.deleted = true;
        this.content = undefined;
    }
    get discardable() {
        return !this.dirty;
    }
    ejected() {
        return Object.assign(Object.assign({}, super.ejected()), { deleted: this.deleted, content: undefined });
    }
    copy(override, source = this.source) {
        const result = new DeletedPayload(Object.assign(Object.assign({}, this.ejected()), override), source);
        return result;
    }
    copyAsSyncResolved(override, source = this.source) {
        const result = new DeletedPayload(Object.assign(Object.assign({}, this.ejected()), override), source);
        return result;
    }
}
exports.DeletedPayload = DeletedPayload;
