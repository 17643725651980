import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@/Components/Button/Button';
import DecoratedInput from '@/Components/Input/DecoratedInput';
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ConfirmCustomPlugin from './ConfirmCustomPlugin';
import PreferencesSegment from '../../../PreferencesComponents/PreferencesSegment';
import { useApplication } from '@/Components/ApplicationProvider';
const InstallCustomPlugin = ({ className = '' }) => {
    const application = useApplication();
    const [customUrl, setCustomUrl] = useState('');
    const [confirmablePlugin, setConfirmablePlugin] = useState(undefined);
    const confirmableEnd = useRef(null);
    useEffect(() => {
        var _a;
        if (confirmablePlugin) {
            (_a = confirmableEnd.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
        }
    }, [confirmablePlugin, confirmableEnd]);
    const submitPluginUrl = async (url) => {
        const plugin = await application.pluginsService.getPluginDetailsFromUrl(url);
        if (plugin) {
            setConfirmablePlugin(plugin);
        }
    };
    const confirmPlugin = async (confirm) => {
        if (confirm && confirmablePlugin) {
            await application.pluginsService.installExternalPlugin(confirmablePlugin);
        }
        setConfirmablePlugin(undefined);
        setCustomUrl('');
    };
    return (_jsx("div", { className: className, children: _jsxs("div", { children: [!confirmablePlugin && (_jsxs(PreferencesSegment, { children: [_jsx("div", { children: _jsx(DecoratedInput, { placeholder: 'Enter Plugin URL', value: customUrl, onChange: (value) => {
                                    setCustomUrl(value);
                                } }) }), _jsx(Button, { hidden: customUrl.length === 0, disabled: customUrl.length === 0, className: "mt-4 min-w-20", primary: true, label: "Install", onClick: () => submitPluginUrl(customUrl) })] })), confirmablePlugin && (_jsxs(PreferencesSegment, { children: [_jsx(ConfirmCustomPlugin, { plugin: confirmablePlugin, callback: confirmPlugin }), _jsx("div", { ref: confirmableEnd })] }))] }) }));
};
export default observer(InstallCustomPlugin);
