"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPayloadSourceRetrieved = exports.isPayloadSourceInternalChange = exports.PayloadEmitSource = void 0;
var PayloadEmitSource;
(function (PayloadEmitSource) {
    /** When an observer registers to stream items, the items are pushed immediately to the observer */
    PayloadEmitSource[PayloadEmitSource["InitialObserverRegistrationPush"] = 1] = "InitialObserverRegistrationPush";
    /**
     * Payload when a client modifies item property then maps it to update UI.
     * This also indicates that the item was dirtied
     */
    PayloadEmitSource[PayloadEmitSource["LocalChanged"] = 2] = "LocalChanged";
    PayloadEmitSource[PayloadEmitSource["LocalInserted"] = 3] = "LocalInserted";
    PayloadEmitSource[PayloadEmitSource["LocalDatabaseLoaded"] = 4] = "LocalDatabaseLoaded";
    /** The payload returned by offline sync operation */
    PayloadEmitSource[PayloadEmitSource["OfflineSyncSaved"] = 5] = "OfflineSyncSaved";
    PayloadEmitSource[PayloadEmitSource["LocalRetrieved"] = 6] = "LocalRetrieved";
    PayloadEmitSource[PayloadEmitSource["FileImport"] = 7] = "FileImport";
    PayloadEmitSource[PayloadEmitSource["ComponentRetrieved"] = 8] = "ComponentRetrieved";
    /** Payloads received from an external component with the intention of creating a new item */
    PayloadEmitSource[PayloadEmitSource["ComponentCreated"] = 9] = "ComponentCreated";
    /**
     * When the payloads are about to sync, they are emitted by the sync service with updated
     * values of lastSyncBegan. Payloads emitted from this source indicate that these payloads
     * have been saved to disk, and are about to be synced
     */
    PayloadEmitSource[PayloadEmitSource["PreSyncSave"] = 10] = "PreSyncSave";
    PayloadEmitSource[PayloadEmitSource["RemoteRetrieved"] = 11] = "RemoteRetrieved";
    PayloadEmitSource[PayloadEmitSource["RemoteSaved"] = 12] = "RemoteSaved";
    PayloadEmitSource[PayloadEmitSource["DesktopComponentSync"] = 13] = "DesktopComponentSync";
})(PayloadEmitSource || (exports.PayloadEmitSource = PayloadEmitSource = {}));
/**
 * Whether the changed payload represents only an internal change that shouldn't
 * require a UI refresh
 */
function isPayloadSourceInternalChange(source) {
    return [PayloadEmitSource.RemoteSaved, PayloadEmitSource.PreSyncSave].includes(source);
}
exports.isPayloadSourceInternalChange = isPayloadSourceInternalChange;
function isPayloadSourceRetrieved(source) {
    return [PayloadEmitSource.RemoteRetrieved, PayloadEmitSource.ComponentRetrieved].includes(source);
}
exports.isPayloadSourceRetrieved = isPayloadSourceRetrieved;
